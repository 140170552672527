import React, { useState, useRef, useEffect } from "react";
import "./FamilyStyle.css";
import { Form } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { stateOptions } from "./FamilyData";
import { initialState } from "./FamilyData";
import { validationMessages } from "./FamilyData";
import { familyValuesOptions } from "./FamilyData";
import { familyTypeOptions } from "./FamilyData";
import { familyStatusOptions } from "./FamilyData";
import { numberOfBrothersOptions } from "./FamilyData";
import { noOfBrothersMarriedOptions } from "./FamilyData";
import { noOfSistersOptions } from "./FamilyData";
import { noOfSistersMarriedOptions } from "./FamilyData";
import { familyWealthOptions } from "./FamilyData";

const Family = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const showToastErrorMessage = () => {
    toast.error("Please fill all the fields", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const [data, setdata] = useState(initialState);
  const [error, setError] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [motherToungeSearchTerm, setMotherToungeSearchTerm] = useState("");
  const [selectedMotherTounge, setSelectedMotherTounge] = useState("");
  const motherToungeDropdownRef = useRef(null);
  const [isMotherToungeDropdownOpen, setIsMotherToungeDropdownOpen] =
    useState(false);

  const handleMotherToungeInputChange = (event) => {
    setMotherToungeSearchTerm(event.target.value);
  };

  const handleSelectChange = (value) => {
    setSelectedMotherTounge(value);
    setdata({ ...data, mother_tounge: value });
    setIsMotherToungeDropdownOpen(false);
  };

  const filteredMotherToungeOptions = stateOptions.filter((option) =>
    option.label.toLowerCase().includes(motherToungeSearchTerm.toLowerCase())
  );

  const toggleDropdown = () => {
    setIsMotherToungeDropdownOpen(!isMotherToungeDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (
      motherToungeDropdownRef.current &&
      !motherToungeDropdownRef.current.contains(event.target)
    ) {
      setIsMotherToungeDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [formError, setFormError] = useState({});

  const inputChangeHandler = (e) => {
    setdata({ ...data, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const errors = {};
    // Check each field and add errors if needed
    Object.keys(data).forEach((key) => {
      if (data[key] === "") {
        errors[key] = validationMessages[key] || "This field is required.";
      }
    });
    // Check if all fields are empty and show a general error message if necessary
    if (Object.keys(data).every((key) => data[key] === "")) {
      showToastErrorMessage(); // Handle this according to your requirements
    }
    setFormError(errors);
    return Object.keys(errors).length === 0;
  };

  const showData = async (e) => {
    e.preventDefault();
    try {
      if (validateForm()) {
        const response = await axios.post(
          "https://matrimonyadmin.hola9.com/api/Familydeatilsapi/Familydetails/",
          data
        );
        const id = response.data.id; // Assuming the response contains the ID of the newly created resource
        setdata({ ...data, id });
        console.log(response.data); // You can handle the response here
        setShowForm(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <section
        className="page-title"
        style={{ backgroundColor: "black", marginTop: "-40px" }}
      >
        <div className="auto-container-Fam">
          <h1
            style={{ color: "white", marginLeft: "0px" }}
            className="d-none-Fam d-lg-block-Fam d-xl-block-Fam d-md-block-Fam"
          >
            Family
          </h1>
        </div>
      </section>
      <button onClick={goBack} className="back-button-pay">
        <i class="ri-arrow-left-line"></i>
      </button>
      <section className="newsletter-section-Fam contact-page-section-Fam">
        <section className="newsletter-section-Fam contact-page-section-Fam">
          <div className="auto-container-Fam">
            <div className="alertmes-Fam"></div>
            <div className="row-Fam clearfix-Fam mt-2-Fam">
              {/* Form Column */}
              <div className="form-column-Fam col-lg-9-Fam col-md-10-Fam col-sm-10-Fam">
                <div className="envelope-image-Fam" />
                <div className="inner-column-Fam">
                  <div className="contact-form-Fam">
                    <Form id="contact-form-Fam">
                      <div className="row-Fam clearfix-Fam mt-2-Fam">
                        <div className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam">
                          <label className="label-Fam">Family Values</label>
                          <select
                            className="custom-select-box-Fam"
                            name="family_values"
                            onChange={inputChangeHandler}
                            value={data.family_values}
                            tabIndex={1}
                            fdprocessedid="0wryd"
                          >
                            {familyValuesOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          <span style={{ color: "red" }}>
                            {formError.family_values}
                          </span>
                        </div>
                        <div className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam">
                          <label className="label-Fam"> Family Type</label>

                          <select
                            className="custom-select-box-Fam"
                            name="family_type"
                            onChange={inputChangeHandler}
                            value={data.family_type}
                            tabIndex={3}
                            fdprocessedid="tytwo8"
                            placeholder="Select a option and change input text above"
                            allowClear
                          >
                            {familyTypeOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          <span style={{ color: "red" }}>
                            {formError.family_type}
                          </span>
                        </div>

                        <div
                          className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam"
                          id="emailerror"
                        >
                          <label className="label-Fam"> Family Status</label>

                          <select
                            className=" custom-select-box-Fam"
                            name="family_status"
                            onChange={inputChangeHandler}
                            value={data.family_status}
                            tabIndex={3}
                            fdprocessedid="zrv5m"
                          >
                            {familyStatusOptions.map((option) => (
                              <option
                                key={option.value}
                                value={option.value}
                                selected={option.selected}
                              >
                                {option.label}
                              </option>
                            ))}
                          </select>
                          <span style={{ color: "red" }}>
                            {formError.family_status}
                          </span>
                        </div>

                        <div className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam">
                          <label className="label-Fam">Mother Tongue</label>
                          <div
                            className="custom-dropdown-mot"
                            ref={motherToungeDropdownRef}
                          >
                            <div
                              className="selected-option-mot"
                              onClick={toggleDropdown}
                            >
                              {selectedMotherTounge
                                ? selectedMotherTounge
                                : "Select Mother Tongue"}
                            </div>
                            {isMotherToungeDropdownOpen && (
                              <div className="dropdown-menu-mot">
                                <input
                                  type="text"
                                  className="custom-select-box-moth"
                                  placeholder="Search Mother Tongue"
                                  value={motherToungeSearchTerm}
                                  onChange={handleMotherToungeInputChange}
                                  autoFocus
                                />
                                <div className="dropdown-options-mot">
                                  {filteredMotherToungeOptions.map(
                                    (option, index) => (
                                      <div
                                        key={index}
                                        className="dropdown-option-mot"
                                        onClick={() =>
                                          handleSelectChange(option.label)
                                        }
                                      >
                                        {option.label}
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                          <span style={{ color: "red" }}>
                            {formError.mother_tounge}
                          </span>
                        </div>
                        <div className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam">
                          <label className="label-Fam"> No. of Brothers</label>
                          <select
                            className="custom-select-box-Fam"
                            name="no_of_brothers"
                            onChange={inputChangeHandler}
                            value={data.no_of_brothers}
                            id="noofbrom"
                            tabIndex={5}
                            fdprocessedid="bfkuk"
                          >
                            {numberOfBrothersOptions.map((option) => (
                              <option
                                key={option.value}
                                value={option.value}
                                selected={option.selected}
                              >
                                {option.label}
                              </option>
                            ))}
                          </select>
                          <span style={{ color: "red" }}>
                            {formError.no_of_brothers}
                          </span>
                        </div>
                        <div className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam">
                          <label className="label-Fam">
                            No.of Brothers Married
                          </label>
                          <select
                            className="custom-select-box-Fam"
                            name="no_of_brothers_married"
                            onChange={inputChangeHandler}
                            value={data.no_of_brothers_married}
                            tabIndex={6}
                            fdprocessedid="pykgeh"
                          >
                            {noOfBrothersMarriedOptions.map((option) => (
                              <option
                                key={option.value}
                                value={option.value}
                                selected={option.selected}
                              >
                                {option.label}
                              </option>
                            ))}
                          </select>
                          <span style={{ color: "red" }}>
                            {formError.no_of_brothers_married}
                          </span>
                        </div>

                        <div className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam">
                          <label className="label-Fam">No. of Sisters</label>
                          <select
                            className="custom-select-box-Fam"
                            name="no_of_sisters"
                            onChange={inputChangeHandler}
                            value={data.no_of_sisters}
                            id="noofsism"
                            tabIndex={7}
                            fdprocessedid="khqro"
                          >
                            {noOfSistersOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          <span />
                          <span style={{ color: "red" }}>
                            {formError.no_of_sisters}
                          </span>
                        </div>
                        <div className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam">
                          <label className="label-Fam">
                            No. of Sisters Married
                          </label>
                          <select
                            className="custom-select-box-Fam"
                            name="no_of_sisters_married"
                            onChange={inputChangeHandler}
                            value={data.no_of_sisters_married}
                            tabIndex={8}
                            fdprocessedid="sy1mja"
                          >
                            {noOfSistersMarriedOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          <span />
                          <span style={{ color: "red" }}>
                            {formError.no_of_sisters_married}
                          </span>
                        </div>

                        <div className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam">
                          <label className="label-Fam">Father Name</label>
                          <input
                            type="text"
                            className="agile-ltext-Fam"
                            placeholder="Your Father Name"
                            maxLength={35}
                            name="father_name"
                            onChange={inputChangeHandler}
                            value={data.father_name}
                            required=""
                            tabIndex={9}
                            fdprocessedid="9f2uiv"
                          />
                          <span style={{ color: "red" }}>
                            {formError.father_name}
                          </span>
                        </div>

                        <div className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam">
                          <label className="label-Fam">Father Occupation</label>
                          <input
                            type="text"
                            className="agile-ltext-Fam"
                            placeholder="Father's Occuption"
                            maxLength={40}
                            name="father_occupation"
                            onChange={inputChangeHandler}
                            value={data.father_occupation}
                            tabIndex={10}
                            fdprocessedid="610prp"
                            rules={[
                              {
                                required: true,
                                type: "string",
                                message: "please input Your Name",
                              },
                              {
                                pattern: new RegExp(/^[A-Za-z\s]*$/),
                                message: "field does not accept numbers",
                              },
                            ]}
                          />
                          <span style={{ color: "red" }}>
                            {formError.father_occupation}
                          </span>
                        </div>
                        <div className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam">
                          <label className="label-Fam"> Mother Name</label>
                          <input
                            type="text"
                            className="agile-ltext-Fam"
                            placeholder="Your Mother Name"
                            required=""
                            defaultValue=""
                            maxLength={35}
                            name="mother_name"
                            onChange={inputChangeHandler}
                            value={data.mother_name}
                            tabIndex={11}
                            fdprocessedid="7h41z"
                          />
                          <span style={{ color: "red" }}>
                            {formError.mother_name}
                          </span>
                        </div>
                        <div className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam">
                          <label className="label-Fam">Mother Occupation</label>
                          <input
                            type="text"
                            className="agile-ltext-Fam"
                            placeholder="Mother's Occuption"
                            maxLength={40}
                            name="mother_occupation"
                            onChange={inputChangeHandler}
                            value={data.mother_occupation}
                            tabIndex={12}
                            fdprocessedid="oajm3g"
                          />
                          <span style={{ color: "red" }}>
                            {formError.mother_occupation}
                          </span>
                        </div>
                        <div className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam">
                          <label className="label-Fam">
                            Your Family Wealth
                          </label>
                          <select
                            id="family_wealth"
                            className="custom-select-box-Fam"
                            name="family_wealth"
                            onChange={inputChangeHandler}
                            value={data.family_wealth}
                            required=""
                            fdprocessedid="i3ugcu"
                          >
                            {familyWealthOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          <span style={{ color: "red" }}>
                            {formError.family_wealth}
                          </span>
                        </div>

                        <div className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam">
                          <label className="label-Fam">
                            Enter Few Lines About Your Family
                          </label>
                          <textarea
                            type="text"
                            name="about_family"
                            onChange={inputChangeHandler}
                            value={data.about_family}
                            className="agile-ltextarea-Fam"
                            rows={4}
                            placeholder="About Your Family"
                            maxLength={500}
                            tabIndex={18}
                          />
                          <div className="col-lg-10-Fam col-md-10-Fam col-sm-10-Fam">
                            <span style={{ fontSize: 15 }} className="abtx">
                              <a
                                href="#"
                                data-toggle="modal"
                                className="ml-1"
                                data-target="#myModal4"
                                data-id={1}
                              ></a>
                            </span>
                            <span style={{ color: "red" }}>
                              {formError.about_family}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-12-Fam col-md-12-Fam col-sm-10-Fam mb-2-Fam">
                          <input
                            className="radio-btn-fam"
                            type="radio"
                            style={{ verticalAlign: "middle" }}
                            name="options"
                            onChange={inputChangeHandler}
                            defaultChecked=""
                            value="My parents will stay with me after marriage"
                            tabIndex={13}
                          />
                          <span className="text-Fam">
                            My parents will stay with me after marriage
                          </span>
                          <span style={{ color: "red" }}>
                            {formError.options}
                          </span>
                        </div>
                        <div className="col-lg-12-Fam col-md-12-Fam col-sm-10-Fam mb-2-Fam">
                          <input
                            type="radio"
                            style={{ verticalAlign: "middle" }}
                            className="radio-btn-fam"
                            name="options"
                            defaultChecked=""
                            onChange={inputChangeHandler}
                            value="My parents will not stay with me after marriage"
                            tabIndex={14}
                          />
                          <span className="text-Fam">
                            My parents will not stay with me after marriage
                          </span>
                          <span style={{ color: "red" }}>
                            {formError.options}
                          </span>
                        </div>
                        <div className="col-lg-12-Fam col-md-12-Fam col-sm-10-Fam mb-2-Fam">
                          <input
                            type="radio"
                            style={{ verticalAlign: "middle" }}
                            className="radio-btn-fam"
                            name="options"
                            defaultChecked=""
                            onChange={inputChangeHandler}
                            value="Dont wish to specify"
                            tabIndex={15}
                          />
                          <span className="text-Fam">
                            Don't wish to specify
                          </span>
                          <span style={{ color: "red" }}>
                            {formError.options}
                          </span>
                        </div>
                        <br />
                        <div className="col-lg-10-Fam col-md-10-Fam col-sm-10-Fam sub_btn-Fam">
                          {!showForm && (
                            <div>
                              <button
                                className="theme-btn-Fam btn-Fam btn-style-one-Fam mt-4-Fam"
                                type="submit"
                                name="submit"
                                fdprocessedid="gw5352"
                                onClick={showData}
                              >
                                <span className="btn-title-Fam">Submit</span>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </Form>
                    <ToastContainer style={{ marginTop: "100px" }} />
                    {error ? (
                      <div
                        className="theme-text -style-one mt-4"
                        style={{ color: "red" }}
                      >
                        <h4>{error}</h4>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        </section>
      </section>
    </div>
  );
};
export default Family;
