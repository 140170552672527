import React, { useState, useEffect } from 'react';
import './Safematrimoney.css';
import { useNavigate } from 'react-router-dom';

const Safematrimony = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const [showFullText, setShowFullText] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <section className="page-title-safe">
      </section>
      <button onClick={goBack} className='back-button-pay'>
        <i className="ri-arrow-left-line"></i>
      </button>
      <section className="about-section_safematrimony">
        <div className="auto-container_safematrimony">
          <div className="row-safe" style={{ paddingLeft: "30px" }}>
            <div className="content-column-safe col-lg-12-safe col-md-12-safe col-sm-12-safe">
              <div className="inner-column-safe">
                <div className="sec-title_safematrimony">
                  <span className="title_safematrimony">Safe-Matrimony</span>
                  <p className='text_safematrimony'>
                    <span style={{ fontSize: 16 }}>
                      Today, millions operating online matrimony websites to search
                      for a life partner, and millions have successfully found their
                      life partner online matrimony.&nbsp;
                      <br />
                      Safe Matrimony is our initiative to guide you to a safe search.
                    </span>
                  </p>
                  <p className='text_safematrimony'>
                    <span style={{ fontSize: 20, color: "rgb(236, 22, 127)", fontWeight: '500' }}>How to be Safe</span>
                  </p>
                  <p className='text_safematrimony'>
                    <span style={{ fontSize: 16 }}>
                      <strong>Never reveal your account info</strong>
                      <br />
                      Keep your matrimony profile user name and password safe.
                      Remember, no one from matrimonysoftware.com will ever ask you
                      for the same.
                    </span>
                    {isSmallScreen && !showFullText && (
                      <span className="read-more-text" onClick={toggleFullText}> Read More</span>
                    )}
                  </p>
                  {showFullText && isSmallScreen ? (
                    <>
                      <p className='text_safematrimony'>
                        <span style={{ fontSize: 16 }}>
                          <strong>Do reference checks.</strong>
                          <br />
                          During your search for a life partner, ensure that you meet the
                          prospective partner, their family and do complete reference
                          checks before you proceed further.
                        </span>
                      </p>
                      <p className='text_safematrimony'>
                        <span style={{ fontSize: 16 }}>
                          <strong>Safety in public.</strong>
                          <br />
                          Visit your matches in safe public places only. Always, keep your
                          friends or family informed about your meeting.
                        </span>
                      </p>
                      <p className='text_safematrimony'>
                        <span style={{ fontSize: 16 }}>
                          Never ever give money to anyone.
                          <br />
                          Do not transfer any funds or offer financial help to any person
                          you meet while searching for a life partner.
                        </span>
                      </p>
                      <p className='text_safematrimony'>
                        <span style={{ fontSize: '20px', color: 'rgb(236, 22, 127)', fontWeight: '500' }}>Your Safety is very important</span>
                      </p>
                      <p className='text_safematrimony'>
                        <span style={{ fontSize: 16 }}>
                          <strong>Choose who views your photo</strong>
                          <br />
                          You can control who views your photos by showing them only to
                          select profiles or even hide them completely.
                        </span>
                      </p>
                      <p className='text_safematrimony'>
                        <span style={{ fontSize: 16 }}>
                          <strong>Choose who sees your number</strong>
                          <br />
                          You can show your number only to members who contacted you or
                          responded to your interest. Or reveal it only to Premium
                          profiles from your community.
                        </span>
                      </p>
                      <p className='text_safematrimony'>
                        <span style={{ fontSize: 16 }}>
                          <strong>Safety for women</strong>
                          <br />
                          Women can turn on the "Secure Connect" feature to get calls from
                          profiles without revealing their own phone numbers.
                        </span>
                        <span className="read-less-text" onClick={toggleFullText}> Read Less</span>
                      </p>
                    </>
                  ) : !isSmallScreen && (
                    <>
                      <p className='text_safematrimony'>
                        <span style={{ fontSize: 16 }}>
                          <strong>Do reference checks.</strong>
                          <br />
                          During your search for a life partner, ensure that you meet the
                          prospective partner, their family and do complete reference
                          checks before you proceed further.
                        </span>
                      </p>
                      <p className='text_safematrimony'>
                        <span style={{ fontSize: 16 }}>
                          <strong>Safety in public.</strong>
                          <br />
                          Visit your matches in safe public places only. Always, keep your
                          friends or family informed about your meeting.
                        </span>
                      </p>
                      <p className='text_safematrimony'>
                        <span style={{ fontSize: 16 }}>
                          Never ever give money to anyone.
                          <br />
                          Do not transfer any funds or offer financial help to any person
                          you meet while searching for a life partner.
                        </span>
                      </p>
                      <p className='text_safematrimony'>
                        <span style={{ fontSize: '20px', color: 'rgb(236, 22, 127)', fontWeight: '500' }}>Your Safety is very important</span>
                      </p>
                      <p className='text_safematrimony'>
                        <span style={{ fontSize: 16 }}>
                          <strong>Choose who views your photo</strong>
                          <br />
                          You can control who views your photos by showing them only to
                          select profiles or even hide them completely.
                        </span>
                      </p>
                      <p className='text_safematrimony'>
                        <span style={{ fontSize: 16 }}>
                          <strong>Choose who sees your number</strong>
                          <br />
                          You can show your number only to members who contacted you or
                          responded to your interest. Or reveal it only to Premium
                          profiles from your community.
                        </span>
                      </p>
                      <p className='text_safematrimony'>
                        <span style={{ fontSize: 16 }}>
                          <strong>Safety for women</strong>
                          <br />
                          Women can turn on the "Secure Connect" feature to get calls from
                          profiles without revealing their own phone numbers.
                        </span>
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Safematrimony;
