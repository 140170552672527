import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Successstyle.css"

const Success= () => {
  const [formData, setFormData] = useState({
    Title: '',
    Description: '',
    image: null,
    date: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    if (e.target.name === 'image') {
      setFormData({ ...formData, image: e.target.files[0] });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate form fields
    const errors = validateForm(formData);
    if (Object.keys(errors).length === 0) {
      try {
        const formDataForSubmit = new FormData();
        formDataForSubmit.append('Title', formData.Title);
        formDataForSubmit.append('Description', formData.Description);
        formDataForSubmit.append('image', formData.image);
        formDataForSubmit.append('date', formData.date);

        // Submit form data
        await axios.post('https://matrimonyadmin.hola9.com/api/Successtoriesapi/', formDataForSubmit);
        toast.success('Post created successfully');
        // Optionally, you can reset the form fields here
        setFormData({
          Title: '',
          Description: '',
          image: null,
          date: '',
        });
      } catch (error) {
        console.error('Error creating post:', error);
        toast.error('Failed to create post');
      }
    } else {
      setErrors(errors);
    }
  };

  const validateForm = (data) => {
    let errors = {};
    if (!data.Title.trim()) {
      errors.Title = 'Title is required';
    }
    if (!data.Description.trim()) {
      errors.Description = 'Description is required';
    }
    if (!data.image) {
      errors.image = 'Image is required';
    }
    if (!data.date.trim()) {
      errors.date = 'Date is required';
    }
    return errors;
  };

  return (
    <div className='success-container'>

      <ToastContainer />
       <h1 className='success-details'>Success Story</h1>
      <form className="success-form" onSubmit={handleSubmit}>
        <div className='Label'>
          <label className="success-title">Title:</label>
          <input className="success-input"
            type="text"
            name="Title"
            value={formData.Title}
            onChange={handleChange}
          />
          {errors.Title && <span className="error">{errors.Title}</span>}
        </div>
        <div className='Description'>
          <label className="success-title">Description:</label>
          <textarea className='success-textarea'
            name="Description"
            value={formData.Description}
            onChange={handleChange}
          />
          {errors.Description && (
            <span className="error">{errors.Description}</span>
          )}
        </div>
        <div className='Image'>
          <label className="success-title">Image:</label>
          <input className="success-input"
            type="file"
            name="image"
            accept="image/*"
            onChange={handleChange}
          />
          {errors.image && <span className="error">{errors.image}</span>}
        </div>
        <div className='Date'>
          <label className="success-title">Date:</label>
          <input className="success-input"
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
          />
          {errors.date && <span className="error">{errors.date}</span>}
        </div>
        <button className='success-button' type="submit">Submit</button>
      </form>
    </div>
  );
};

export default Success;