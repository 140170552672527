import React, { useState, useRef, useEffect } from "react";
import "./AdvanceSearchh.css";
import { useNavigate } from "react-router-dom";

import { casteOptions } from "./AdvanceSearchdata";

const AdvanceSearch = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const ages = Array.from({ length: 48 }, (_, index) => index + 18);

  const [casteSearchTerm, setCasteSearchTerm] = useState("");
  const [selectedCaste, setSelectedCaste] = useState("");
  const [isCasteDropdownOpen, setIsCasteDropdownOpen] = useState(false);
  const casteDropdownRef = useRef(null);
  const [formError, setFormError] = useState({ caste: "" });

  const handleCasteInputChange = (event) => {
    setCasteSearchTerm(event.target.value);
  };

  const handleSelectChange = (value) => {
    setSelectedCaste(value);
    setIsCasteDropdownOpen(false);
  };

  const filteredCasteOptions = casteOptions.filter((option) =>
    option.toLowerCase().includes(casteSearchTerm.toLowerCase())
  );

  const toggleDropdown = () => {
    setIsCasteDropdownOpen(!isCasteDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (
      casteDropdownRef.current &&
      !casteDropdownRef.current.contains(event.target)
    ) {
      setIsCasteDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div>
        <>
          <div
            className="Advanceserach-title"
            style={{ marginTop: "-40px", marginBottom: "50px" }}
          >
            <h1>Advance Search</h1>
          </div>
          <button onClick={goBack} className="back-button-pay">
            <i class="ri-arrow-left-line"></i>
          </button>
          <div>
            <div className="adv-row-search">
              <div className="Adv-search-inpts">
                <div className="Adv-search-sub-inpts">
                  <label className="adv-label">Select Marial Status</label>

                  <select style={{ marginTop: "5px" }}>
                    <option value="" selected="">
                      {" "}
                      Select Marital Status
                    </option>
                    <option value="Unmarried">Unmarried</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Widower ">Widower</option>
                  </select>
                  <span style={{ color: "red" }}></span>
                </div>
                const AgeFromDropdown = () => (
                <div className="Adv-search-sub-inpts">
                  <label className="adv-label">Age from</label>
                  <select
                    style={{ marginTop: "32px", marginLeft: "-190px" }}
                    className="custom-select-boxa valid"
                    autoFocus
                    name="Age_From"
                    required
                    tabIndex={5}
                  >
                    <option value="" selected>
                      Select Age From
                    </option>
                    {ages.map((age) => (
                      <option key={age} value={age}>
                        {age}
                      </option>
                    ))}
                  </select>
                  <span style={{ color: "red" }}></span>
                </div>
                );
                <div className="Adv-search-sub-inpts">
                  <label className="adv-label"> Age to </label>
                  <select
                    style={{ marginTop: "32px", marginLeft: "-170px" }}
                    className="custom-select-boxa"
                    name="Age_To"
                    required=""
                    tabIndex={6}
                  >
                    <option value="" selected="">
                      Select Age To
                    </option>
                    {Array.from({ length: 48 }, (_, index) => {
                      const age = index + 18; // Start from 18 up to 65
                      return (
                        <option key={age} value={age}>
                          {age}
                        </option>
                      );
                    })}
                  </select>
                  <span style={{ color: "red" }}></span>
                </div>
              </div>

              <div className="Adv-search-inpts">
                <div className="Adv-search-sub-inpts">
                  <label className="adv-label">Height from</label>
                  <select style={{ marginTop: "5px" }}>
                    <option value="" selected="">
                      Select Height From
                    </option>
                    {Array.from({ length: 37 }, (_, index) => {
                      const feet = Math.floor((index + 1) / 12) + 4; // Calculate feet starting from 4Ft
                      const inches = (index + 1) % 12; // Calculate inches
                      return (
                        <option key={index + 1} value={index + 1}>
                          {feet}Ft{inches > 0 ? ` ${inches} inch` : ""}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="Adv-search-sub-inpts">
                  <label className="adv-label">Height to</label>
                  <select style={{ marginTop: "5px" }}>
                    <option value="" selected="">
                      Select Height To
                    </option>
                    {Array.from({ length: 37 }, (_, index) => {
                      const feet = Math.floor((index + 1) / 12) + 4; // Calculate feet starting from 4Ft
                      const inches = (index + 1) % 12; // Calculate inches
                      return (
                        <option key={index + 1} value={index + 1}>
                          {feet}Ft{inches > 0 ? ` ${inches} inch` : ""}
                        </option>
                      );
                    })}
                  </select>
                  <span style={{ color: "red" }}></span>
                </div>

                <div className="Adv-search-sub-inpts">
                  <label className="adv-label">Religion</label>
                  <select style={{ marginTop: "5px" }}>
                    <option value="" selected="">
                      select religion
                    </option>
                    <option value="Christian">Christian</option>
                    <option value="Hindu">Hindu</option>
                    <option value="hindu Barahsaini">hindu Barahsaini</option>
                    <option value="Inter-Religion">Inter-Religion</option>
                    <option value="Jain">Jain</option>
                    <option value="Muslim">Muslim</option>
                    <option value="Sikh">Sikh</option>{" "}
                  </select>
                  <span style={{ color: "red" }}></span>
                </div>
              </div>

              <div className="Adv-search-inpts">
                <div className="Adv-search-sub-inpts">
                  <label className="adv-label">Caste</label>
                  <div className="custom-dropdown-ad" ref={casteDropdownRef}>
                    <div
                      className="selected-option-ad"
                      onClick={toggleDropdown}
                      style={{ marginTop: "5px" }}
                    >
                      {selectedCaste ? selectedCaste : "Select Caste"}
                    </div>
                    {isCasteDropdownOpen && (
                      <div className="dropdown-menu-ad">
                        <input
                          type="text"
                          className="custom-select-box-SP"
                          placeholder="Search Caste"
                          value={casteSearchTerm}
                          onChange={handleCasteInputChange}
                          autoFocus
                        />
                        <div className="dropdown-options-ad">
                          {filteredCasteOptions.map((option, index) => (
                            <div
                              key={index}
                              className="dropdown-option-ad"
                              onClick={() => handleSelectChange(option)}
                            >
                              {option}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <span style={{ color: "red" }}>{formError.caste}</span>
                </div>

                <div className="Adv-search-sub-inpts">
                  <label className="adv-label"> Complexion</label>
                  <select style={{ marginTop: "5px" }}>
                    <option value="" selected="">
                      {" "}
                      Select Complexion
                    </option>

                    <option value="Very Fair">Very Fair</option>
                    <option value="Fair">Fair</option>
                    <option value="Wheatish">Wheatish</option>
                    <option value="Wheatish Medium">Wheatish Medium</option>
                    <option value="Wheatish Brown">Wheatish Brown</option>
                    <option value="Dark">Dark</option>
                  </select>
                  <span style={{ color: "red" }}></span>
                </div>

                <div className="Adv-search-sub-inpts">
                  <label className="adv-label"> Residency Status</label>
                  <select style={{ marginTop: "5px" }}>
                    <option value="" selected="">
                      {" "}
                      Select Residency Status{" "}
                    </option>
                    <option value="Permanent Resident">
                      {" "}
                      Permanent Resident{" "}
                    </option>
                    <option value="Student Visa">Student Visa</option>
                    <option value="Temporary Visa">Temporary Visa</option>
                    <option value="Work Permit">Work Permit</option>{" "}
                  </select>
                  <span style={{ color: "red" }}></span>
                </div>
              </div>

              <div className="adv-search-btn">
                <button
                  style={{
                    background: "#eb2869",
                    color: "white",
                    width: "100px",
                    paddingTopTop: "5px",
                    borderRadius: "5px",
                  }}
                >
                  <span className="adv-btn">Search</span>
                </button>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default AdvanceSearch;

//  converted 700 line  code to 266 lines
