import React,{useState} from 'react';
import ProfileCards from '../Horoscopepage/ProfileCards';
import Complete_profile from './Complete_profile';
import Userdata from './UserProfilee/Userdata';
import ModalCard from './UserProfilee/ModalCard';
import {Link} from 'react-router-dom';
import Review from './Review';
import './Overview.css';


const Overview = () => {

  //  ---------------------------------------pop up------------------------------
  const [isOpen, setIsOpen] = useState(false);
// ------------------------------------------------------------------------------

  return (
      <div>
          <div style={{display:"flex",marginTop:"-110px"}}>
          {/* <Link to="/dashboard/Review" style={{color:"white",fontWeight:"600"}}> */}
          <div onClick={() => setIsOpen(!isOpen)} style={{width:"20px",height:"170px",display:"flex",justifyContent:"center",alignItems:"center", backgroundColor:"orangered",color:"white",fontWeight:"700",marginTop:"177px",padding:"13px",borderRadius:"50%/45%",cursor:"pointer"}}>
       
    R
    e
    v  <br/>
    i  <br/>
    e
    w 
   
    </div>
    {/* ----------------------------------------------Pop up------------------------------------- */}
{
  isOpen ? <div className='profile-card-pop1' onClick={() =>setIsOpen(!isOpen) }  >

 <div className='profilecard-popup-container1 ' onClick={(e) =>e.stopPropagation() } >

  <Review/>
      
 </div>

  </div>:null
}
    {/* </Link> */}
        <Userdata />
    
    </div>
        <ProfileCards />
        <ModalCard />
        <Complete_profile />

      </div>
      
     
  );
};

export default Overview;