import { Button, Form, Input } from 'antd';
import Header from '../../Homepage/Header';
import Footer from '../../Footer/Footer';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { localUrl } from '../../Env/Env';
import React from 'react';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
/* eslint-enable no-template-curly-in-string */

const SetPassword = () => {
  const { uid, token } = useParams(); // Get uid and token from the URL parameters
  const navigate = useNavigate();

  const onFinish = (values) => {
    console.log(values);
    var formdata = new FormData();
    formdata.append("password", values.user.password);
    formdata.append("password2", values.user.password2);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch(localUrl + `user/reset-password/${uid}/${token}/`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result);
        if (result.success) {
          // Navigate to the login page after successful password reset
          navigate('/Login');
        } else {
          console.error("Password reset failed");
        }
      })
      .catch(error => console.log('error', error));
  };

  return (
    <div>
      <Form
        {...layout}
        name="nest-messages"
        onFinish={onFinish}
        style={{
          maxWidth: 600,
          marginTop: "150px",
          marginLeft: "350px",
        }}
        validateMessages={validateMessages}
      >
        <p style={{ width: "100%", marginLeft: "280px", color: "#f20487", fontSize: "20px" }}>Reset Password Page</p>
        <p style={{ marginLeft: "250px", color: "#f20487", marginBottom: "6%", fontSize: "18px" }}>
          <strong>Please enter your Reset Password</strong>
        </p>
        <Form.Item
          label="Password"
          name={["user", "password"]}
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name={["user", "password2"]}
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <div className="col-lg-12 col-md-6 col-sm-12 mt-3">
          <div className="btn-box">
            <button
              className="theme-btn btn btn-style-one"
              type="submit"
              style={{ width: "20%", marginLeft: "300px", marginBottom: "20%" }}
            >
              <span className="btn-title">Submit</span>
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default SetPassword;
