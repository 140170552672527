import { borderRadius } from "@mui/system";
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import'./Reviewsinglecard.css'
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

const Reviewsinglecard = () => {

    const {id} = useParams();

    
  const [data,setData]=useState([]);

   const renderRatingStars = (rating) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
        if (i < rating) {
            stars.push(<StarIcon key={i} style={{color:"gold"}} />);
        } else {
            stars.push(<StarOutlineIcon key={i} style={{color:"gold"}} />);
        }
    }
    return stars;
};
 
 
  useEffect(()=>{

    const fetchData=async()=>{
     const response =await fetch(`https://matrimonyadmin.hola9.com/api/crud/reviewapi/${id}`);
     const result =await response.json();
     setData(result)
    }
    fetchData();

  })
  return (
    <>
<div className="review-single-card-page">

      <div style={{width:'60%',height:'300px',display:'flex',margin:'auto',flexDirection:'row',marginTop:'130px',gap:'7px',boxShadow:'1.5px 0.5px 8px 2px gray'}}>
        
            <div style={{width:'35%',background:'whitesmoke',borderRadius:'10px 0px 0px 10px'}}>
            <img src={`https://matrimonyadmin.hola9.com/${data.profile_image}`} style={{width:'90%',height:'90%',margin:'auto',display:'flex',marginTop:'15px',borderRadius:'5px'}}/>
           </div>

           <div style={{width:'65%',background:'whitesmoke',borderRadius:'0px 10px 10px 0px '}}>

           <div style={{height:"30%",display:'flex',flexDirection:'column',alignItems:"center"}}>
           <p style={{fontWeight:'600',fontSize:'20px'}}>{data.name}</p>
           <p style={{fontWeight:'600',fontSize:'15px'}}>{data.email}</p>
          

           <div style={{ height: '10%', display: 'flex', }}>
                        {renderRatingStars(data.rating)}
                    </div>
           </div>

           <div style={{height:'60%',display:'flex',alignItems:'center',justifyContent:"center",textAlign:"center",marginTop:'15px'}}>
           <p style={{fontSize:'15px',fontWeight:'500'}} >{data.descriptions}</p> 
           </div>
        </div>
      </div>

      </div>      
    </>
  )
}

export default Reviewsinglecard
