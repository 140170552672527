

import { Collapse } from 'antd';
import './FACs.css';
import { useNavigate } from 'react-router-dom';

const { Panel } = Collapse;

const  FACs = () => {

  const onChange = (key) => {
    console.log(key);
  };

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div> 


    <button onClick={goBack} className='back-button-pay'><i class="ri-arrow-left-line"></i></button>

    <div className='topp-FAQ'>
    <h1 className='name-FAQ' style={{marginTop:'-30px'}}>FAQ's</h1>
    <div className='container-FAQ' >
    <center>
    <div className="content-column-FAQ col-lg-5-FAQ col-md-8-FAQ col-sm-10-FAQ cont-FAQ"> 
    <Collapse defaultActiveKey={['1']} onChange={onChange} className='bck-FAQ'>
      <Panel className='fam-FAQ' header=" How can I register on readymatrimonial.in ?" key="1">
        <p className='fam-FAQ'> Registering in our matrimony site is a simple process, you can
                register by filling the online registration that runs for 3
                pages or use the Quick registration form, a shorter and simpler
                process available</p>
      </Panel>
      <Panel className='fam-FAQ' header="  I did my registration, but my profile does not show up online ?" key="2">
        <p className='fam-FAQ'> Every new profile will be validated by our ADMIN (Backend Team)
                and upon activation, your profile will be visible to all !
                Verification of profiles is done manually. Our support team
                checks each and every profile carefully for any invalid or
                incorrect information and also candidates are contacted over the
                phone for confirmation of authority. You will get a notification
                once the profile is active !</p>
      </Panel>
      <Panel className='fam-FAQ' header=" Can I upload my photograph?" key="3">
        <p className='fam-FAQ'> You have the option of uploading your photograph on My Profile
                Page. You can upload a maximum of ten photographs.</p>
      </Panel>
      <Panel className='fam-FAQ' header=" How do I upload Horoscope ?" key="4">
        <p className='fam-FAQ'>     We have an exclusive interface to key in your horoscope details.
                Login to your Matrimony account and click Manage Horoscope.</p>
      </Panel>
      <Panel className='fam' header=" Can I edit all my details ?" key="5">
        <p className='fam'>  At any time, you can update your profile by clicking Modify My
                Profile button .</p>
      </Panel>
      <Panel className='fam-FAQ' header=" I see a tab called MY MATCHES, What’s the use of it ?" key="6">
        <p className='fam-FAQ'>   My Matches fetches the profiles matching your partner
                preferences that you keyed in while registering your profile.
                Its dynamically updated. </p>
      </Panel>
   
      <Panel className='fam-FAQ' header="  Can I shortlist/bookmark a Profile ?" key="6">
        <p className='fam-FAQ'> Yes, you can ! Its an useful feature to make a note of the
                interested profiles. You need to be logged in to use the
                shortlist feature. </p>
      </Panel>
      <Panel className='fam-FAQ' header=" How do I delete Shortlisted profiles?" key="7">
        <p className='fam-FAQ'>         Login using your matrimonial "User ID" and "Password". Click on
                the "Shortlisted Profiles". You could view and delete the
                Bookmarked members!</p>
      </Panel>
      <Panel className='fam-FAQ' header=" How do I change my password?" key="8">
        <p className='fam-FAQ'>  After logging into your account, click on the change password
                link. The system will ask for your old password and then the new
                one then login with your new password.</p>
      </Panel>
      <Panel className='fam-FAQ' header="What are the benefits of a membership?" key="9">
        <p className='fam-FAQ'>1)Most trusted Matrimonial service
                <br />
                2)100% verified Matrimonial profiles.
                <br />
                3)Managed by Complete Professionals.
                <br />
                4)Dedicated Customer Care Service.
                <br />
                5)Post your personal profile !<br />
                6)Add more information about yourself and your family
                <br />
                7)Upload/add multiple photographs to your profile
                <br />
                8)Display your contact details to paid members
                <br />
                9)Express interest in other members
                <br />
                10)Why should I choose your paid membership package?
                <br />
                11)A paid membership have various packages and options to help
                you access advanced features of readymatrimonial.in.
                <br />
                12)Search suitable profile through matrimonial Website
                <br />
                13)Contact suitable matches via contact number, personalized
                messages, and customer service.
                <br />
                14)Send and receive personalized messages.
                <br />
                15)Customer care support.
                <br />
                16)Paid Matrimonial Members get top services.
                <br />
                17)Paid Matrimonial Members can express interest and write
                messages to other members of readymatrimonial.in.
                <br />
                18)Is my personal information safe? </p>
      </Panel>
      <Panel className='fam-FAQ' header="  How can I upgrade my paid membership ?" key="11">
        <p className='fam-FAQ'>  We provide various options for upgrading your membership. You
                can login to your matrimony account page and click upgrade
                button. Choose the right package for you, which will lead you to
                the payment page. You will be provided with various options for
                payment.</p>
      </Panel>
      <Panel className='fam-FAQ' header=" How do I contact customer care?" key="12">
        <p className='fam-FAQ'>  readymatrimonial.in is eager to help you find your partner at
                the earliest. Customer Support is top priority to us. You can
                contact our customer care team in any of the following ways
                listed here.</p>
      </Panel>

    </Collapse>
    </div></center>
    </div>
    </div>

    </div>
  );
};
export default FACs;


