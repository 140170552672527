import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './BlogsDetails.css'

const BlogView = () => {
  const { id } = useParams(); 
  const [blogPost, setBlogPost] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`https://matrimonyadmin.hola9.com/api/profileapi/blog-posts/${id}`)
      .then(response => response.json())
      .then(data => {
        setBlogPost(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching blog post:', error);
        setLoading(false);
      });
  }, [id]);

  return (
    <div className="blog-view-container">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="blog-post-details">
          <h2>{blogPost.title}</h2>
          <p>Date of Publish: {blogPost.date_of_publish}</p>
          <p>{blogPost.description}</p>
          <p> "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor 
          incididunt ut labore et dolore magna aliqua".</p>
         <div className="blog-details-author">
         <img src='https://totallyhistory.com/wp-content/uploads/2013/07/Salman-rushdie.jpg' className='author-image-view' alt="Author" />    
          <p className='view-author'>Author: {blogPost.author_name}</p> {/* Display author's name */}
          <p className='view-email'>Email: {blogPost.author_email}</p> {/* Display author's email */}
          {/* Display other details as needed */}
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogView;
