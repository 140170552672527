// import React, { useState, useRef ,useEffect } from 'react';
// import './UserdataStyle.css';
// import Drawer from '@mui/material/Drawer';
// import Slide from '@mui/material/Slide';
// import Button from '@mui/material/Button';
// import CircularProgress from '@mui/material/CircularProgress';
// import axios from 'axios';

// const Userdata = () => {
//   const [profilePicture, setProfilePicture] = useState(""
//     // 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREB7FC1k3nxOMlqRHPWG-j6mXUcysRIgm1GA&usqp=CAU'
//   );
//   const [loading, setLoading] = useState(true);

//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [progress, setProgress] = useState(60); // State for the percentage of progress

//   const fileInputRef = useRef(null);

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];

//     const reader = new FileReader();
//     reader.onloadend = () => {
//       setProfilePicture(reader.result);
//     };

//     if (file) {
//       reader.readAsDataURL(file);
//     }
//   };

//   const handlePhotoIconClick = () => {
//     if (fileInputRef.current) {
//       fileInputRef.current.click();
//     }
//   };

//   const handleViewProfileClick = () => {
//     setDrawerOpen(true);
//   };

//   const handleDrawerClose = () => {
//     setDrawerOpen(false);
//   };
//   useEffect(() => {
//     const fetchProfilePicture = async () => {
//       try {
//         const response = await axios.get('https://matrimonyadmin.hola9.com/api/crud/holaapi/');
//         const imageData = response.data;
//         setProfilePicture(imageData.profile_pic); // Update profilePicture state with the URL
//         setLoading(false);
       
//       } catch (error) {
//         console.error('Error fetching profile picture:', error);
//         setLoading(false);
//       }
//     };
  
//     fetchProfilePicture();
//   }, []);
  
  

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div className="main-container">
//       <div className="main-profile">
//         <div id="header-profile">
//           <div className="Image-profile" style={{ position: 'relative' }}>
//             <div onClick={handlePhotoIconClick} style={{ cursor: 'pointer' }}>
//               <div style={{ position: 'relative' }}>
//                 <img
//                   id="profile_pic"
//                   src={profilePicture}
//                   alt="User Display Picture"
//                   style={{ zIndex: 1 }}
//                 />
//                <div className="progress-container">
//                 <CircularProgress
//                   variant="determinate"
//                   value={progress}
//                   size={100}
//                   thickness={4}
//                   style={{
//                     color: '#2196f3', 
//                     position: 'absolute',
//                     top: '50%',
//                     left: '50%',
//                     marginTop: '-30px', 
//                     marginLeft: '300px', 
//                     zIndex: 0,
//                   }}
//                 />
//                 {/* Percentage Text */}
//                 <text className='update-user'
//                   x="50%"
//                   y="50%"
//                   textAnchor="middle"
//                   dominantBaseline="middle"
//                   fontSize="16"
//                   fill="#000"
//                   //  style={{marginLeft: '350px', }}
//                 >

                  
//                   {`${Math.round(progress)}%`}
//                 </text>
//                 </div>
//               </div>
//             </div>
//             <div className="Name-employe">
//               <div id="user-name">
//                 <b>Krishna Murthy</b>
//               </div>
//               <div id="user-email">krishna090@gmail.com</div>
//               <div id="user-profession">Web Developer</div>
//             </div>
//           </div>

//           <input
//             type="file"
//             accept="image/*"
//             className="file_upload_button"
//             onChange={handleFileChange}
//             ref={fileInputRef}
//             style={{ display: 'none' }}
//           />

//           <div id="profile-link">
//             <a href="#" id="view-profile-button" onClick={handleViewProfileClick}>
//               View Profile
//             </a>
//           </div>
//           <div  className='complete_profile-main-user' >
//         {/* <h2 className="com-text1" style={{width:'fit-content'}}>Complete Your Profile</h2> */}
//         <span ><p >Profile completeness Score &nbsp;   </p><input  type="range" min={0} max={100} style={{width:'60px',height:'10px', marginLeft:'30px',cursor:'pointer'}} /></span>
//     </div>
//         </div>
//       </div>

    


// <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
//   <div className="drawer-content">
//     <div>
//       <img
//         id="user-picture-drawer"
//         src={profilePicture}
//         alt="User Display Picture"
//       />
//     </div>
//     <div>
//       <b>Krishna Murthy</b>
//     </div>
//     <div className="info-container">
//       <div className="label-value">
//         <div className="label">Mail:</div>
//         <div className="value">krishna090@gmail.com</div>
//       </div>
//       <div className="label-value">
//         <div className="label">Profession:</div>
//         <div className="value">Web Developer</div>
//       </div>
//       <div className="label-value">
//         <div className="label">Qualification:</div>
//         <div className="value">B.tech</div>
//       </div>
//       <div className="label-value">
//         <div className="label">City:</div>
//         <div className="value">Pune</div>
//       </div>
//       <div className="label-value">
//         <div className="label">Blood group:</div>
//         <div className="value">AB+</div>
//       </div>
//       <div className="label-value">
//         <div className="label">Gender:</div>
//         <div className="value">Male</div>
//       </div>
//       <div className="label-value">
//         <div className="label">Religion:</div>
//         <div className="value">Hindu</div>
//       </div>
//       <div className="label-value">
//         <div className="label">Caste:</div>
//         <div className="value">BC-D</div>
//       </div>
      
//     </div>
//   </div>
// </Drawer>


//     </div>
//   );
// };

// export default Userdata;
import React, { useState, useRef ,useEffect } from 'react';
import './UserdataStyle.css';
import Drawer from '@mui/material/Drawer';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
 
const Userdata = () => {
  const [profilePicture, setProfilePicture] = useState("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREB7FC1k3nxOMlqRHPWG-j6mXUcysRIgm1GA&usqp=CAU");
  const [loading, setLoading] = useState(true);
 
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [progress, setProgress] = useState(60); // State for the percentage of progress
 
  const fileInputRef = useRef(null);
 
  const handleFileChange = (event) => {
    const file = event.target.files[0];
 
    const reader = new FileReader();
    reader.onloadend = () => {
      setProfilePicture(reader.result);
    };
 
    if (file) {
      reader.readAsDataURL(file);
    }
  };
 
  const handlePhotoIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
 
  const handleViewProfileClick = () => {
    setDrawerOpen(true);
  };
 
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    const fetchProfilePicture = async () => {
      try {
        const response = await axios.get('https://matrimonyadmin.hola9.com/api/crud/holaapi/');
        const imageData = response.data[0]; // Assuming the response is an array with one object
        const profilePicUrl = `https://matrimonyadmin.hola9.com${imageData.profile_pic}`;
        setProfilePicture(profilePicUrl);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching profile picture:', error);
        setLoading(false);
      }
    };
  
    fetchProfilePicture();
  }, []);

  const handleImageError = () => {
    <img src="https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg?auto=compress&cs=tinysrgb&w=600" alt="photo" width={100} height={100} />;
  };
  
 
 
  if (loading) {
    return <div>Loading...</div>;
  }
 
  return (
    <div className="main-container">
      <div className="main-profile">
        <div id="header-profile">
          <div className="Image-profile" style={{ position: 'relative' }}>
            <div onClick={handlePhotoIconClick} style={{ cursor: 'pointer' }}>
            {/* <div style={{ cursor: 'pointer' }}> */}
              <div style={{ position: 'relative' }}>
                <img
                  id="profile_pic"
                  src={profilePicture}
                  alt="User Display Picture"
                  onError={handleImageError} 
                  style={{ zIndex: 1 }}
                />
               <div className="progress-container">
                <CircularProgress
                  variant="determinate"
                  value={progress}
                  size={100}
                  thickness={4}
                  style={{
                    color: '#2196f3',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-30px',
                    marginLeft: '300px',
                    zIndex: 0,
                  }}
                />
                {/* Percentage Text */}
                <text className='update-user'
                  x="50%"
                  y="50%"
                  textAnchor="middle"
                  dominantBaseline="middle"
                  fontSize="16"
                  fill="#000"
                  //  style={{marginLeft: '350px', }}
                >
 
                 
                  {`${Math.round(progress)}%`}
                </text>
                </div>
              </div>
            </div>
            <div className="Name-employe">
              <div id="user-name">
                <b>Krishna Murthy</b>
              </div>
              <div id="user-email">krishna090@gmail.com</div>
              <div id="user-profession">Web Developer</div>
            </div>
          </div>
 
          <input
            type="file"
            accept="image/*"
            className="file_upload_button"
            onChange={handleFileChange}
            ref={fileInputRef}
            style={{ display: 'none' }}
          />
 
          <div id="profile-link">
            <a href="#" id="view-profile-button" onClick={handleViewProfileClick}>
              View Profile
            </a>
          </div>
          <div  className='complete_profile-main-user' >
        {/* <h2 className="com-text1" style={{width:'fit-content'}}>Complete Your Profile</h2> */}
        <span ><p >Profile completeness Score &nbsp;   </p><input  type="range" min={0} max={100} style={{width:'60px',height:'10px', marginLeft:'30px',cursor:'pointer'}} /></span>
    </div>
        </div>
      </div>
 
   
 
 
<Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
  <div className="drawer-content">
    <div>
      <img
        id="user-picture-drawer"
        src={profilePicture}
        alt="User Display Picture"
      />
    </div>
    <div>
      <b>Krishna Murthy</b>
    </div>
    <div className="info-container">
      <div className="label-value">
        <div className="label">Mail:</div>
        <div className="value">krishna090@gmail.com</div>
      </div>
      <div className="label-value">
        <div className="label">Profession:</div>
        <div className="value">Web Developer</div>
      </div>
      <div className="label-value">
        <div className="label">Qualification:</div>
        <div className="value">B.tech</div>
      </div>
      <div className="label-value">
        <div className="label">City:</div>
        <div className="value">Pune</div>
      </div>
      <div className="label-value">
        <div className="label">Blood group:</div>
        <div className="value">AB+</div>
      </div>
      <div className="label-value">
        <div className="label">Gender:</div>
        <div className="value">Male</div>
      </div>
      <div className="label-value">
        <div className="label">Religion:</div>
        <div className="value">Hindu</div>
      </div>
      <div className="label-value">
        <div className="label">Caste:</div>
        <div className="value">BC-D</div>
      </div>
     
    </div>
  </div>
</Drawer>
 
 
    </div>
  );
};
 
export default Userdata;

