
import React from 'react'
import "./AboutStyle.css"
import { useNavigate } from 'react-router-dom';

const About = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <> 
    {/* <img src="https://www.thesecondshaadi.com/images/s1.jpg" alt="Top floating image" className="floating-image-top"/> */}
    <section className="page-title-aboutus">
   
    </section>
    
    <div className="container__base aem-GridColumn aem-GridColumn--default--12">
      <div className="cmp-container--moduleab">
        <div id="container-5ca89434d4" className="cmp-containerab" style={{ backgroundColor: "#E1DFDD" }}> 
          <button onClick={goBack} className='back-button-pay'><i class="ri-arrow-left-line"></i></button>
          <center>
            <div className="text-about">
              <div className="text__containerab" data-nc="Text">
                <h1 className='text-abouts' style={{color: 'black'}}><center> ABOUT US</center></h1>
              </div>
            </div>
            <div className="columncontrol">
              <section>
                <div className="aem-Grid aem-Grid--12 aem-Grid--default--12">
                  <div className="aem-GridColumn aem-GridColumn--phone--12 aem-GridColumn--tablet--6 aem-GridColumn--default--4">
                    <div className="text-about section-about">
                      <div className="text__containerab" data-nc="Text">
                        <div className='content-container'>
                          <p  className='content-matrimony'>
                            <div>
                       <img src='https://images.squarespace-cdn.com/content/v1/530286f8e4b0d1c4964d040e/1437595712682-DDAGTD79H1GPU7U2O5FZ/image-asset.jpeg?format=1000w' alt="Top floating image" className="floating-image-top"/>
                       </div>
                      
                           Ready Matrimonial is one of the professional and highly
                            experienced Matrimonial Website Development Companies in India.
                             As one of the leading matrimonial software development
                               organizations in India, we have maintained a strong team of PHP
                              Developers with us, who work in a synchronized manner to provide
                               you the perfect Matrimony Software at a reasonable cost.
                              So if you are planning to start a matrimonial website with
                               excellent features, then Ready Matrimony is a perfect platform
                               for you. Ready Matrimonial offers a great service in the design,
                               development, programming,and marketing of your website. 
                               <div><img src="https://images.pexels.com/photos/265791/pexels-photo-265791.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Bottom floating image" width="300px" height="200px" className="floating-image-bottom"/>
                       </div>  
                                We are
                               one of the professional and popular matrimony software
                               development company in India since 2011. With the help of our
                               matrimonial script application, many potential brides and grooms
                               can build their profiles on it and find a suitable partner.

                          

                               Ready Matrimonial is one of the professional and highly
                            experienced Matrimonial Website Development Companies in India.
                             As one of the leading matrimonial software development
                               organizations in India, we have maintained a strong team of PHP
                              Developers with us, who work in a synchronized manner to provide
                               you the perfect Matrimony Software at a reasonable cost.
                             

                             
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                </div>
              </section>
            </div>
            <div className="columncontrol">
            </div>
          </center>
        </div>
      </div>
    </div>
    {/* <img src="https://www.thesecondshaadi.com/images/s1.jpg" alt="Bottom floating image" className="floating-image-bottom"/> */}
    </>
  )
}

export default About


