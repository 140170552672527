import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import './MutalMatches.css' 

const HeroineCardMutual = ({ imageSrc, name, id, height, qualification }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (

    <div className="heroine-card-latest-mutual">
      <img src={imageSrc} alt={name} />
      <div className="details-latest-mutual">
        <h2>{name}</h2>
        <p>Id: {id}</p>
        <p>Height: {height}</p>
        <p>Qualification: {qualification}</p>

       
        {/* <button className="view-profile-btn-latest">send interest</button> */}
        <button class="button-86-mutual" role="button">Send Interest</button>

       

      </div>
    </div>
  );
};

const MutualMatches = () => {
  const [filter, setFilter] = useState(""); 
  const [qualificationFilter, setQualificationFilter] = useState(""); 
  const [heightFilter, setHeightFilter] = useState(""); 
  const [showMore, setShowMore] = useState(false);

  
  const [basicDetailsOpen, setBasicDetailsOpen] = useState(false);
  const [physicalStatusOpen, setPhysicalStatusOpen] = useState(false);
  
  
  const [dhosamOpen, setDhosamOpen] = useState(false);
  const [smokeOpen, setSmokeOpen] = useState(false);
  const [showprofileOpen, setShowprofileOpen] = useState(false);
  
  const [dontshowprofileOpen, setDontshowprofileOpen] = useState(false);
  const [drinkOpen, setDrinkOpen] = useState(false);
  const [religionOpen, setReligionOpen] = useState(false);
  const [searchReligion, setSearchReligion] = useState("");
  const [religions, setReligions] = useState(["Hindu", "Muslim", "Christian","sikh"]);
  const [selectedReligion, setSelectedReligion] = useState("");
 

  const [minAge, setMinAge] = useState(18);
  const [maxAge, setMaxAge] = useState(35);
  const [minHeight, setMinHeight] = useState(5);
  const [maxHeight, setMaxHeight] = useState(6);

  const [foodHabitOpen, setFoodHabitOpen] = useState(false);
  const [searchFoodHabit, setSearchFoodHabit] = useState("");
  const [foodHabits, setFoodHabits] = useState(["Vegetarian", "Egvegetarian","Non-Vegetarian"]);
  const [selectedFoodHabit, setSelectedFoodHabit] = useState("");

  const [occupationOpen, setOccupationOpen] = useState(false);
  const [searchOccupation, setSearchOccupation] = useState("");
  const [occupations, setOccupations] = useState(["Engineer", "Doctor", "Teacher", "Farmer","Software Developer"]);

  const [educationOpen, setEducationOpen] = useState(false);
  const [searchEducation, setSearchEducation] = useState("");
  const [educations, setEducations] = useState(["10th","12th","B.com","B.sc","B.arch","B.ed","B.el.ed"," B.Lib.Sc ","B.P.Ed.","B.Plan","Bachelor of Fashion Technology","BCA "," BE B.Tech"," BFA"," BHM ","BL/LLB/BGL ","  BSW (Social Work)","B.Pharm (Pharmacy)"," B.V.Sc. (Veterinary Science)","  BDS (Dental Surgery)"," BHMS (Homeopathy)"," CA (Chartered Accountant)","CFA (Chartered Financial Analyst)"," CS (Company Secretary)","ICWA","Integrated PG","Engineering","Fashion/ Design"," Pilot Licenses"," M.Arch. (Architecture)","  M.Ed. (Education)"," M.Lib.Sc. (Library Sciences)","  M.Plan. (Planning)"]);

  const [casteOpen, setCasteOpen] = useState(false);
  const [searchCaste, setSearchCaste] = useState("");
  const [castes,  setCastes] = useState(["96K Kokanastha","Ad Dharmi","Adi Andhra","Adi Dravida","Adi Karnataka","Agarwal","Agnikula Kshatriya","Agri",

  "Ahom" ,"Ambalavasi" ,"Amil Sindhi","Anglo Indian","Araya","Arekatica","Arora","Arunthathiyar", "Arya Vysya","Aryasamaj","Ayyaraka" ,"Badaga","Bahi" ,"Banjara","Barai","Bari","Bengali" ,"Bhumihar Brahmin" ,"Bishnoi/Vishnoi" ,
  "Boyer","Brahmbatt","Bunt (Shetty)","Chandraseniya Kayastha Prab", "Chandravanshi Kahar", "Chasa" ,"Chattada Sri Vaishnava","Chaudary","Chaurasia","Chettiar","Chhapru Sindhi" ,"Chhetri","Chippolu (Mera)","CKP","Coorgi","Dadu Sindhi","Dawoodi Bhora","Devadiga" ,"Devandra Kula Vellalar","Devang Koshthi","Devanga","Devar/Thevar/Mukkulathor","Devendra Kula Vellalar" , "Dhaneshawat Vaish","Dhangar" ,"Ezhuthachan" ,"Gabit","Ganda","Gandla" ,"Ganigashetty","Kalinga Vysya","Kalita","Kamboj","Kannada Mogaveera","Patil Leva" ,"Patnaick" ,"Patnaick/Sistakaranam","Patra","Prajapati" ,"Punjabi" ,"Raigar" ,"Rajastani","Rajbonshi" ,"Rajput Rohella/Tank" ,"Ravidasia","Sadgope","Sahiti Sindhi","Sahu","Sakkhar Sindhi","Scheduled Caste", "Sehwani Sindhi" , "Senguntha Mudaliyar" ,"Settibalija" ,"Tamboli" ,"Tantubai","Telaga" ,"Telugu" , "Thakkar" ,"Udayar" ,"Uppara","Vada Balija","Vaish" ,"Vannia Kula Kshatriyar","Veera Saivam", "Viswabrahmin", "Vokkaliga","Yadav","Yellapu"
  ]);

  const [motherTongueOpen, setMotherTongueOpen] = useState(false);
  const [searchMotherTongue, setSearchMotherTongue] = useState("");
  const [motherTongues, setMotherTongues] = useState(["Assamese","Bengali","Bodo","Dogri","Gujarati","Hindi","kashmiri","Kanada","Konkani","Maithili","Malayalam","Manipuri","Malayalam","Manipuri","Marathi","Nepali","Odia","Punjabi","Sanskrit","Santali","Sindhi","Tamil","Telugu","Urdu"]);


  const [raasiOpen, setRaasiOpen] = useState(false);
  const [searchRaasi, setSearchRaasi] = useState("");
  const [raasis, setRaasis] = useState(["Aries", " Vrishabh (Taurus)","Mithun (Gemini)","  Karka (Cancer)","Tula"," Vrischika (Scorpio)"," Makar (Capricorn)","  Kumbha (Aquarious)","Meen (Pisces)","   Does not matter", "Taurus", "Gemini", "Cancer", "Leo", "Virgo", "Libra", "Scorpio", "Sagittarius", "Capricorn", "Aquarius", "Pisces"]);
  
  const [starOpen, setStarOpen] = useState(false);
  const [searchStar, setSearchStar] = useState("");
  const [stars, setStars] = useState(["Aries", "Taurus", "Gemini", "Cancer", "Leo", "Virgo", "Libra", "Scorpio", "Sagittarius", "Capricorn", "Aquarius", "Pisces"]);
  

  const [physicalStatus, setPhysicalStatus] = useState("");
  const [selectedPhysicalStatus, setSelectedPhysicalStatus] = useState("");
  const [selectedEducation, setSelectedEducation] = useState("");
  const [selectedCaste, setSelectedCaste] = useState("");
  const [selectedMotherTongue, setSelectedMotherTongue] = useState("");


  const toggleBasicDetails = () => {
    setBasicDetailsOpen(!basicDetailsOpen);
    setPhysicalStatusOpen(false);
    setReligionOpen(false);
    setEducationOpen(false);
    setCasteOpen(false);
    setMotherTongueOpen(false);
    setOccupationOpen(false);
    setRaasiOpen(false);
    setFoodHabitOpen(false);

  };
  const toggleOccupation = () => {
    setOccupationOpen(!occupationOpen);
    setBasicDetailsOpen(false);
    setPhysicalStatusOpen(false);
    setReligionOpen(false);
    setEducationOpen(false);
    setCasteOpen(false);
    setMotherTongueOpen(false);
    setRaasiOpen(false);
  };

  const togglePhysicalStatus = () => {
    setPhysicalStatusOpen(!physicalStatusOpen);
    setBasicDetailsOpen(false);
    setReligionOpen(false);
    setEducationOpen(false);
    setCasteOpen(false);
    setMotherTongueOpen(false);
    setOccupationOpen(false);
    setRaasiOpen(false);

  };
  const toggledhosam= () => {
    setDhosamOpen(!dhosamOpen);
    setBasicDetailsOpen(false);
    setReligionOpen(false);
    setEducationOpen(false);
    setCasteOpen(false);
    setMotherTongueOpen(false);
    setOccupationOpen(false);
    setRaasiOpen(false);

  };
   const togglesmoke= () => {
    setSmokeOpen(!smokeOpen);
    setBasicDetailsOpen(false);
    setReligionOpen(false);
    setEducationOpen(false);
    setCasteOpen(false);
    setMotherTongueOpen(false);
    setOccupationOpen(false);
    setRaasiOpen(false);
};

const toggleshowprofile= () => {
  setShowprofileOpen(!showprofileOpen);
  setBasicDetailsOpen(false);
  setReligionOpen(false);
  setEducationOpen(false);
  setCasteOpen(false);
  setMotherTongueOpen(false);
  setOccupationOpen(false);
  setRaasiOpen(false);
};

const toggledontshowprofile= () => {
  setDontshowprofileOpen(!dontshowprofileOpen);
  setBasicDetailsOpen(false);
  setReligionOpen(false);
  setEducationOpen(false);
  setCasteOpen(false);
  setMotherTongueOpen(false);
  setOccupationOpen(false);
  setRaasiOpen(false);
};
   const toggledrink= () => {
    setDrinkOpen(!drinkOpen);
    setBasicDetailsOpen(false);
    setReligionOpen(false);
    setEducationOpen(false);
    setCasteOpen(false);
    setMotherTongueOpen(false);
    setOccupationOpen(false);
    setRaasiOpen(false);

  };

  const toggleReligion = () => {
    setReligionOpen(!religionOpen);
    setBasicDetailsOpen(false);
    setPhysicalStatusOpen(false);
    setEducationOpen(false);
    setCasteOpen(false);
    setMotherTongueOpen(false);
    setOccupationOpen(false);
    setRaasiOpen(false);
  };
  
  const toggleCaste = () => {
    setCasteOpen(!casteOpen);
    setBasicDetailsOpen(false);
    setPhysicalStatusOpen(false);
    setReligionOpen(false);
    setEducationOpen(false);
    setMotherTongueOpen(false);
    setOccupationOpen(false);
    setRaasiOpen(false);
  };

  const toggleMotherTongue = () => {
    setMotherTongueOpen(!motherTongueOpen);
    setBasicDetailsOpen(false);
    setPhysicalStatusOpen(false);
    setReligionOpen(false);
    setEducationOpen(false);
    setCasteOpen(false);
    setOccupationOpen(false);
    setRaasiOpen(false);
  };
  const toggleEducation = () => {
    setEducationOpen(!educationOpen);
    setBasicDetailsOpen(false);
    setPhysicalStatusOpen(false);
    setReligionOpen(false);
    setCasteOpen(false);
    setMotherTongueOpen(false);
    setOccupationOpen(false);
    setRaasiOpen(false);
  };


  const toggleRaasi = () => {
    setRaasiOpen(!raasiOpen);
    setBasicDetailsOpen(false);
    setPhysicalStatusOpen(false);
    setReligionOpen(false);
    setEducationOpen(false);
    setCasteOpen(false);
    setMotherTongueOpen(false);
    setOccupationOpen(false);
  };

  const toggleFoodHabit = () => {
    setFoodHabitOpen(!foodHabitOpen);
    setBasicDetailsOpen(false);
    setPhysicalStatusOpen(false);
    setReligionOpen(false);
    setEducationOpen(false);
    setCasteOpen(false);
    setMotherTongueOpen(false);
    setOccupationOpen(false);
    setRaasiOpen(false);
  };

  const handleFoodHabitSearch = (e) => {
    setSearchFoodHabit(e.target.value);
  };
  const handleFoodHabitSelect = (foodHabit) => {
    setSelectedFoodHabit(foodHabit);
  };

  const filteredFoodHabits = foodHabits.filter((foodHabit) =>
    foodHabit.toLowerCase().includes(searchFoodHabit.toLowerCase())
  );

  const handleRaasiSearch = (e) => {
    setSearchRaasi(e.target.value);
  };

  const filteredRaasis = raasis.filter((raasi) =>
    raasi.toLowerCase().includes(searchRaasi.toLowerCase())
  );

  const handleReligionSelect = (religion) => {
    setSelectedReligion(religion);
  };

  const handleEducationSelect = (education) => {
    setSelectedEducation(education);
  };

  const handleMotherTongueSelect = (motherTongue) => {
    setSelectedMotherTongue(motherTongue);
  };

  const handleCasteSelect = (caste) => {
    setSelectedCaste(caste);
  };
  
  
  const toggleStar = () => {
    setRaasiOpen(!raasiOpen);
    setBasicDetailsOpen(false);
    setPhysicalStatusOpen(false);
    setReligionOpen(false);
    setEducationOpen(false);
    setCasteOpen(false);
    setMotherTongueOpen(false);
    setOccupationOpen(false);
  };

  const handleStarSearch = (e) => {
    setSearchRaasi(e.target.value);
  };

  

  const filteredStars = stars.filter((star) =>
    star.toLowerCase().includes(searchStar.toLowerCase())
  );

  const handlePhysicalStatusSelect = (status) => {
    setSelectedPhysicalStatus(status);
  };



  const handleAll = () => {
    
  };

  const handleClear = () => {
    
  };

  const handleApply = () => {
   
  };

  
  

 

  const handleEducationSearch = (e) => {
    setSearchEducation(e.target.value);
  };

  const handleCasteSearch = (e) => {
    setSearchCaste(e.target.value);
  };

  const handleMotherTongueSearch = (e) => {
    setSearchMotherTongue(e.target.value);
  };

  const handleReligionSearch = (e) => {
    setSearchReligion(e.target.value);
  };

  const filteredReligions = religions.filter((religion) =>
    religion.toLowerCase().includes(searchReligion.toLowerCase())
  );
  const filteredEducations = educations.filter((education) =>
    education.toLowerCase().includes(searchEducation.toLowerCase())
  );

  const filteredCastes = castes.filter((caste) =>
    caste.toLowerCase().includes(searchCaste.toLowerCase())
  );

  const filteredMotherTongues = motherTongues.filter((motherTongue) =>
    motherTongue.toLowerCase().includes(searchMotherTongue.toLowerCase())
  );

  const handleOccupationSearch = (e) => {
    setSearchOccupation(e.target.value);
  };

  const filteredOccupations = occupations.filter((occupation) =>
    occupation.toLowerCase().includes(searchOccupation.toLowerCase())
  );

  
  const [searchSmoke, setSearchSmoke] = useState("");
  const [smokes, setSmokes] = useState(["Yes", "No"]);
  const [selectedSmoke, setSelectedSmoke] = useState("");

  const [selectedDrink, setSelectedDrink] = useState("");

  const toggleSmoke = () => {
    setSmokeOpen(!smokeOpen);
    setBasicDetailsOpen(false);
    setPhysicalStatusOpen(false);
    setReligionOpen(false);
    setEducationOpen(false);
    setCasteOpen(false);
    setMotherTongueOpen(false);
    setOccupationOpen(false);
    setRaasiOpen(false);
    setFoodHabitOpen(false);
  };

  const handleSmokeSearch = (e) => {
    setSearchSmoke(e.target.value);
  };

  const handleSmokeSelect = (smoke) => {
    setSelectedSmoke(smoke);
  };

  const handleDrinkSelect = (drink) => {
    setSelectedDrink(drink);
  };

  const filteredSmokes = smokes.filter((smoke) =>
    smoke.toLowerCase().includes(searchSmoke.toLowerCase())
  );
  // Example data for heroine cards
  const heroineData = [

    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2rxUClTpphQ0zzYCEwgRqVgMSdt8jaSNgeFPp0mDKwg&s",
      name: "anethesia",
      id: 5,
      height: "155cm",
      qualification: "mba",
      age: 30,

     

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2rxUClTpphQ0zzYCEwgRqVgMSdt8jaSNgeFPp0mDKwg&s",
      name: "devil",
      id: 5,
      height: "155cm",
      qualification: "mba",
      age: 30,

     
    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2rxUClTpphQ0zzYCEwgRqVgMSdt8jaSNgeFPp0mDKwg&s",
      name: "anethesia",
      id: 5,
      height: "155cm",
      qualification: "mba",
      age: 30,

     
    },
    
    
    
  ];
  const filteredHeroines = heroineData.filter((heroine) =>
    heroine.name.toLowerCase().includes(filter.toLowerCase()) &&
    heroine.qualification.toLowerCase().includes(qualificationFilter.toLowerCase()) &&
    heroine.height.toLowerCase().includes(heightFilter.toLowerCase()) 
   
  );

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };


  return (
    <div>

      <section className="page-title-latest-mutual" style={{ backgroundColor: "black" }}>
        <div className="auto-container-latest-mutual">
          <h1 className="d-noneb d-lg-blockb d-xl-blockb d-md-bloc-latest-mutual">MUTUAL MATCHES</h1>
          <ul className="bread-crumbb clearfix-latest-mutual">
            <li>
              <a href="index_dashboardb">Dashboard</a>
            </li>
            <li>Mutual Matches</li>
          </ul>
        </div>
      </section>

      <div className="latest-matches-container-mutual">
      <div className="filter-options-container">
        <div className="filter-option">
          <button onClick={toggleBasicDetails} className="filter-title">
            Basic Details     
          </button>
          {basicDetailsOpen && (
            <div className="filter-options">
              <label  >Age</label>
              <div>
                <span>{minAge} years</span> - <span>{maxAge} years</span>
              </div>
              <input
                type="range"
                min={18}
                max={35}
                value={minAge}
                onChange={(e) => setMinAge(parseInt(e.target.value))}
              />
             
             
              <label>Height (in feet)</label>
              <div>
                <span>{minHeight} feet</span> - <span>{maxHeight} feet</span>
              </div>
              <input
                type="range"
                min={5}
                max={6}
                step={0.1}
                value={minHeight}
                onChange={(e) => setMinHeight(parseFloat(e.target.value))}
              />
             
              
            </div>
          )}
        </div>
        <div className="filter-option">
          <button onClick={togglePhysicalStatus} className="filter-title">
            Physical Status
          </button>
          {physicalStatusOpen && (
            
            <div className="filter-options">
              <ul className="physical-status-list">
                {["Normal", "Physically Challenged"].map((status, index) => (
                  <li
                    key={index}
                    onClick={() => handlePhysicalStatusSelect(status)}
                    className={selectedPhysicalStatus === status ? "selected" : ""}
                  >
                    {status} {selectedPhysicalStatus === status && <span>&#10003;</span>}
                  </li>
                ))}
              </ul>
            </div>

            
          )}
           {physicalStatusOpen && (
            <div className="filter-options-search">
              <button className="filter-options-search-1" onClick={handleAll}>All</button>
              <button  className="filter-options-search-2"onClick={handleClear}>Clear</button>
              <button className="filter-options-search-3"onClick={handleApply}>Apply</button>
            </div>
          )}
        </div>
        <div className="filter-option">
          <button onClick={toggleReligion} className="filter-title">
            Religion
          </button>
          
           {religionOpen && (
            <div className="filter-options">
              <input
                type="text"
                placeholder="Search religion..."
                value={searchReligion}
                onChange={handleReligionSearch}
              />
            
               <ul className="religion-list">
                {filteredReligions.map((religion, index) => (
                  <li
                    key={index}
                    onClick={() => handleReligionSelect(religion)}
                    className={selectedReligion === religion ? "selected" : ""}
                  >
                    {religion} {selectedReligion === religion && <span>&#10003;</span>}
                  </li>
                ))}
              </ul>
              {religionOpen && (
            <div className="filter-options-search">
              <button className="filter-options-search-1" onClick={handleAll}>All</button>
              <button  className="filter-options-search-2"onClick={handleClear}>Clear</button>
              <button className="filter-options-search-3"onClick={handleApply}>Apply</button>
            </div>
          )}
              
            </div>
          )}
          
          
        </div>
        
      <div className="filter-option">
        <button onClick={toggleEducation} className="filter-title">
          Education
         {/* {educationOpen ? "▼" : "▶"} */}
        </button>
        {educationOpen && (
          <div className="filter-options">
            <input
              type="text"
              placeholder="Search education..."
              value={searchEducation}
              onChange={handleEducationSearch}
            />
            <ul className="education-list">
              {filteredEducations.map((education, index) => (
                // <li key={index}>{education}</li>
                <li
                    key={index}
                    onClick={() => handleEducationSelect(education)}
                    className={selectedEducation === education ? "selected" : ""}
                  >
                    {education} {selectedEducation === education && <span>&#10003;</span>}
                  </li>
              ))}
            </ul>
            {educationOpen && (
            <div className="filter-options-search">
              <button className="filter-options-search-1" onClick={handleAll}>All</button>
              <button  className="filter-options-search-2"onClick={handleClear}>Clear</button>
              <button className="filter-options-search-3"onClick={handleApply}>Apply</button>
            </div>
          )}
          </div>
        )}

      </div>

      <div className="filter-option">
        <button onClick={toggleCaste} className="filter-title">
          Caste
        </button>
        {casteOpen && (
          <div className="filter-options">
            <input
              type="text"
              placeholder="Search caste..."
              value={searchCaste}
              onChange={handleCasteSearch}
            />
            <ul className="caste-list">
              {filteredCastes.map((caste, index) => (
                // <li key={index}>{caste}</li>
                <li
                key={index}
                onClick={() => handleCasteSelect(caste)}
                className={selectedCaste === caste ? "selected" : ""}
              >
                {caste} {selectedCaste === caste && <span>&#10003;</span>}
              </li>
              ))}
            </ul>
            {casteOpen && (
            <div className="filter-options-search">
              <button className="filter-options-search-1" onClick={handleAll}>All</button>
              <button  className="filter-options-search-2"onClick={handleClear}>Clear</button>
              <button className="filter-options-search-3"onClick={handleApply}>Apply</button>
            </div>
          )}
          </div>
        )}
      </div>

      <div className="filter-option">
        <button onClick={toggleMotherTongue} className="filter-title">
          Mother Tongue
        </button>
        {motherTongueOpen && (
          <div className="filter-options">
            <input
              type="text"
              placeholder="Search mother tongue..."
              value={searchMotherTongue}
              onChange={handleMotherTongueSearch}
            />
            <ul className="mother-tongue-list">
              {filteredMotherTongues.map((motherTongue, index) => (
                // <li key={index}>{motherTongue}</li>

                <li
                key={index}
                onClick={() => handleMotherTongueSelect(motherTongue)}
                className={selectedMotherTongue === motherTongue ? "selected" : ""}
              >
                {motherTongue} {selectedMotherTongue === motherTongue && <span>&#10003;</span>}
              </li>
              ))}
            </ul>
            {motherTongueOpen && (
            <div className="filter-options-search">
              <button className="filter-options-search-1" onClick={handleAll}>All</button>
              <button  className="filter-options-search-2"onClick={handleClear}>Clear</button>
              <button className="filter-options-search-3"onClick={handleApply}>Apply</button>
            </div>
          )}
          </div>
        )}
      </div>

      <div className="filter-option">
          <button onClick={toggleOccupation} className="filter-title">
            Occupation
          </button>
          {occupationOpen && (
            <div className="filter-options">
              <input
                type="text"
                placeholder="Search occupation..."
                value={searchOccupation}
                onChange={handleOccupationSearch}
              />
              <ul className="occupation-list">
                {filteredOccupations.map((occupation, index) => (
                  <li key={index}>{occupation}</li>
                ))}
              </ul>
              {occupationOpen && (
            <div className="filter-options-search">
              <button className="filter-options-search-1" onClick={handleAll}>All</button>
              <button  className="filter-options-search-2"onClick={handleClear}>Clear</button>
              <button className="filter-options-search-3"onClick={handleApply}>Apply</button>
            </div>
          )}
            </div>
          )}
        </div>

        <div className="filter-option">
          <button onClick={toggleRaasi} className={`filter-title ${raasiOpen ? 'open' : ''}`}>
            Raasi
          </button>
          {raasiOpen && (
            <div className="filter-options">
              <input
                type="text"
                placeholder="Search Raasi..."
                value={searchRaasi}
                onChange={handleRaasiSearch}
              />
              <ul className="raasi-list">
                {filteredRaasis.map((raasi, index) => (
                  <li key={index}>{raasi}</li>
                 
                ))}
              </ul>
              {raasiOpen && (
            <div className="filter-options-search">
              <button className="filter-options-search-1" onClick={handleAll}>All</button>
              <button  className="filter-options-search-2"onClick={handleClear}>Clear</button>
              <button className="filter-options-search-3"onClick={handleApply}>Apply</button>
            </div>
          )}
            </div>
          )}
        </div>
        <div className="filter-option">
          <button onClick={toggleStar} className={`filter-title ${starOpen ? 'open' : ''}`}>
            star
          </button>
          {starOpen && (
            <div className="filter-options">
              <input
                type="text"
                placeholder="Search Star..."
                value={searchStar}
                onChange={handleStarSearch}
              />
              <ul className="star-list">
                {filteredRaasis.map((star, index) => (
                  <li key={index}>{star}</li>
                
                ))}
              </ul>
              {starOpen && (
            <div className="filter-options-search">
              <button className="filter-options-search-1" onClick={handleAll}>All</button>
              <button  className="filter-options-search-2"onClick={handleClear}>Clear</button>
              <button className="filter-options-search-3"onClick={handleApply}>Apply</button>
            </div>
          )}
            </div>
          )}

        </div>
        

        <div className="filter-option">
          <button onClick={toggleFoodHabit} className={`filter-title ${foodHabitOpen ? 'open' : ''}`}>
            Food Habit
          </button>
          {foodHabitOpen && (
            <div className="filter-options">
              <input
                type="text"
                placeholder="Search food habit..."
                value={searchFoodHabit}
                onChange={handleFoodHabitSearch}
              />
              <ul className="food-habit-list">
                {filteredFoodHabits.map((foodHabit, index) => (
                  // <li key={index}>{foodHabit}</li>
                  <li
                  key={index}
                  onClick={() => handleFoodHabitSelect(foodHabit)}
                  className={selectedFoodHabit === foodHabit ? "selected" : ""}
                >
                  {foodHabit} {selectedFoodHabit === foodHabit && <span>&#10003;</span>}
                </li>
                ))}
                
              </ul>
              {foodHabitOpen && (
            <div className="filter-options-search">
              <button className="filter-options-search-1" onClick={handleAll}>All</button>
              <button  className="filter-options-search-2"onClick={handleClear}>Clear</button>
              <button className="filter-options-search-3"onClick={handleApply}>Apply</button>
            </div>
          )}
            </div>
          )}
        </div>
        <div className="filter-option">
          <button onClick={toggledhosam} className="filter-title">
           Dhosam
          </button>
          {dhosamOpen && (
            <ul className="filter-options">
              <li>yes</li>
              <li>No</li>
              <li>Don't Know</li>
              
            </ul>
          )}
           {dhosamOpen && (
            <div className="filter-options-search">
              <button className="filter-options-search-1" onClick={handleAll}>All</button>
              <button  className="filter-options-search-2"onClick={handleClear}>Clear</button>
              <button className="filter-options-search-3"onClick={handleApply}>Apply</button>
            </div>
          )}
        </div>
        <div className="filter-option">
          <button onClick={toggledrink} className="filter-title">
           Drink
          </button>
          {drinkOpen && (
             
            <ul className="filter-options">
              <li>yes</li>
              <li>No</li>
              <li>occasionally</li>
              
             
            </ul>
          )}
           {drinkOpen && (
            <div className="filter-options-search">
              <button className="filter-options-search-1" onClick={handleAll}>All</button>
              <button  className="filter-options-search-2"onClick={handleClear}>Clear</button>
              <button className="filter-options-search-3"onClick={handleApply}>Apply</button>
            </div>
          )}
        </div>
        <div className="filter-option">
        <button onClick={toggleSmoke} className={`filter-title ${smokeOpen ? 'open' : ''}`}>
          Smoke
        </button>
        {smokeOpen && (
          <div className="filter-options">
            <input
              type="text"
              placeholder="Search smoke..."
              value={searchSmoke}
              onChange={handleSmokeSearch}
            />
            <ul className="smoke-list">
              {filteredSmokes.map((smoke, index) => (
                <li
                  key={index}
                  onClick={() => handleSmokeSelect(smoke)}
                  className={selectedSmoke === smoke ? "selected" : ""}
                >
                  {smoke} {selectedSmoke === smoke && <span>&#10003;</span>}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
           

        <div className="filter-option">
          <button onClick={toggleshowprofile} className="filter-title">
           Show profile
          </button>
          {showprofileOpen && (
            <ul className="filter-options">
              <li>with photo</li>
              <li>with horoscope</li>
              
              
            </ul>
          )}
           {showprofileOpen && (
            <div className="filter-options-search">
              <button className="filter-options-search-1" onClick={handleAll}>All</button>
              <button  className="filter-options-search-2"onClick={handleClear}>Clear</button>
              <button className="filter-options-search-3"onClick={handleApply}>Apply</button>
            </div>
          )}
        </div>

        <div className="filter-option">
          <button onClick={toggledontshowprofile} className="filter-title">
            Dont Show profile
          </button>
          {dontshowprofileOpen && (
            <ul className="filter-options">
              <li>Rejected</li>
              <li>Already contracted</li>
              <li>View profiles</li>
              <li>Selected profiles</li>
              
              
            </ul>
          )}
           {dontshowprofileOpen && (
            <div className="filter-options-search">
              <button className="filter-options-search-1" onClick={handleAll}>All</button>
              <button  className="filter-options-search-2"onClick={handleClear}>Clear</button>
              <button className="filter-options-search-3"onClick={handleApply}>Apply</button>
            </div>
          )}
        </div>
        </div>
      <div className="cards-section-mutual">
     
        {filteredHeroines.map((heroine, index) => (
          <HeroineCardMutual
            key={index}
            imageSrc={heroine.imageSrc}
            name={heroine.name}
            id={heroine.id}
            height={heroine.height}
            qualification={heroine.qualification}
            age={heroine.age}

            
            
          />
        ))}
     
      </div>
      </div>
    </div>
  );
};

export default MutualMatches;
