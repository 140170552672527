// import React from 'react'
// import { Link } from 'react-router-dom'
// import Footer from '../../Footer/Footer'
// import Header from '../../Homepage/Header'

// const ResetPassword = () => {
//   return (
//     <div>
//     <Header/>
//           <section className="page-title" style={{ backgroundColor: "black",marginTop:"93px"}}>
//         <div className="auto-container">
//           <h1 className="d-none d-lg-block d-xl-block d-md-block">ResetPassword </h1>
//           <ul className="bread-crumb clearfix">
//             <li>
//               <a href="/">Home</a>
//             </li>
//             <li>ResetPassword</li>
//           </ul>
//         </div>
//       </section>
//       <div className="auto-container">
//   {/*Subscribe Form*/}
//   <div className="envelope-image " />
//   <div className="contact-form ">
//     <div className="form-inner">
//       <div className="upper-box">
//         <div className="sec-title text-center">
//          <br/>
        
 
//           <div className="text">
//             Please enter your Password address to search for your account..
            
//           </div>
//         </div>
//       </div>
//       <center>
//       <form
//         method="post"
//         action="forgot_password_submit"
//         id="contact-form"
//         noValidate="novalidate"
//       >
       
           
//             <div className="row clearfix">
//           <div className="col-lg-2 col-md-4 col-sm-4"></div>
//           <div
//             className="col-lg-8 col-md-5 col-sm-8 form-group"
//             id="contact-form"
//           >
          
//             <input
//               type="Password"
//               autofocus=""
//               name="user"
//               defaultValue=""
//               style={{ width: "59%", marginLeft:"-300px",marginRight:"200px" }}
//               placeholder="Set New Password"
//               tabIndex={1}
//               required=""
//             />
           
//             <br />
//             <div className="row clearfix">
//           <div className="col-lg-2 col-md-4 col-sm-4"></div>
//           <div
//             className="col-lg-8 col-md-5 col-sm-8 form-group"
//             id="contact-form"
//           >
//             <input
//               type="password"
//               autofocus=""
//               name="user"
//               defaultValue=""
//               style={{ width: "92%",marginLeft:"-450px",marginRight:"310px" }}
//               placeholder="Please Confirm  The Password"
//               tabIndex={1}
//               required=""
//             />
//              <br />
          

//             <div className="btn-box">
//               <button
//                 className="theme-btn btn btn-style-one mb-3"
//                 type="submit"
//                 name="submit"
//                 style={{ width: "25%",marginLeft:"-800px" }}
//               >
//                 <span className="btn-title">
              
//                 <Link to='/Login'>Submit</Link>
//                 </span>
//               </button>
              
//             </div>
            
//           </div>
//         </div>
//         </div>
//         </div>
       
      
//       </form>
//       </center>
//     </div>
//   </div>
// </div>
//       <Footer/>
//     </div>
//   )
// }

// export default ResetPassword



import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../Footer/Footer';
import Header from '../../Homepage/Header';
import './ResetPassword.css'; // Import CSS file for styling

const ResetPassword = () => {
  return (
    <div>
      <Header />
      <section className="page-title" style={{ backgroundColor: "black", marginTop: "93px" }}>
        <div className="auto-container">
          <h1 className="d-none d-lg-block d-xl-block d-md-block">Reset Password</h1>
          <ul className="bread-crumb clearfix">
            
            <li>
              <Link to="/" >Home</Link>
            </li>
            <li >Reset Password</li>
          </ul>
        </div>
        
      </section>
      <div className="auto-container-1">
        {/* Subscribe Form */}
        <div className="envelope-image" />
        <div className="contact-form">
          <div className="form-inner">
            <div className="upper-box">
              <div className="sec-title text-center">
                <br />
                <div className="text">
                  Please enter your Password address to search for your account.
                </div>
              </div>
            </div>
            <form
              method="post"
              action="forgot_password_submit"
              id="contact-form"
              noValidate="novalidate"
            >
              <div className="form-group">
                <input
                  type="password"
                  autoFocus
                  name="password"
                  style={{ width: "100%" }}
                  placeholder="Set New Password"
                  tabIndex={1}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  autoFocus
                  name="confirmPassword"
                  style={{ width: "100%" }}
                  placeholder="Confirm Password"
                  tabIndex={2}
                  required
                />
              </div>
              <div className="btn-box text-center">
                <div className='submit-btn'>
                <button
                  className="btn-submit"
                  type="submit"
                  name="submit"
                  style={{ width: "50%" }}
                >
                  <span className="btn-title">
                    <Link to='/Login'>Submit</Link>
                  </span>
                </button>
              </div>
            </div>

            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ResetPassword;
