export const BasicDetails = [ 
    {
        i : 'Name :',
        b : 'Hemanth'
    },
    {
        i : 'Surname :',
        b : 'Anantharajulu'
    },
    {
         i : 'Email:',         
        b : 'jana@gmail.com'
    },
    {
         i : 'Mobile Number:',
        b : '8686811429'
    },
    {
         i : 'Date of Birth :',
        b : '19-08-1985'
    },
    {
         i : 'Age :',
        b : '28'
    },
    {
         i : 'Height :',
        b : '5.10 ft'
    },
    {
         i : 'Blood Group :',
        b : 'A+'
    },
    {
         i : 'Gender :',
        b : '-'
    },
    
    {
         i : 'Religion : ',
        b : 'Hindu'
    },
    {
         i : 'Caste :',
        b : 'Balija'
    },
    {
         i : 'Sub caste :',
        b : '-'
    },
    {
         i : 'Languages :',
        b : 'Telugu,English'
    },
{
    i : 'Marial Status :    ',
   b : 'Single'
},
{
    i : 'Lives in :',
   b : 'Mumbai'
},
{
    i : 'Citizenship :',
   b : 'indian Citizen'
},

]

export const EduProf = [ 
    {
        i : 'Qualification : ',
        b : 'M.B.A'
    },
    {
        i : 'Occupation :',
        b : 'S/w Engineer'
    },
    {
        i : 'Occupation Details :',
        b : '-'
    },
    {
        i : 'Anual income : ',
        b : '6 LPA'
    },
    {
        i : 'Employed In : ',
        b : 'Hola9'
    },
    {
        i : 'Working Location :',
        b : 'banglore'
    },
]

export const PartnerPref = [ 
    {
        i : 'Looking For :',
        b : 'Un Married'
    },
    {
        i : 'Age From :',
        b : '24'
    },
    {
        i : 'Age to : ',
        b : '28'
    },
    {
        i : 'Height From : ',
        b : '5.8 ft'
    },
    {
        i : 'Height to :',
        b : '5.11 ft'
    },
    {
        i : 'Religion : ',
        b : 'Hindu',
    },
    {
        i : 'Caste: ',
        b : 'OC'
    },
    {
        i : 'Complexion :',
        b : 'Brown'
    },
    {
        i : 'Residency Status: ',
        b : 'Own House'
    },
    {
        i : 'Country : ',
        b : 'India'
    },
    {
        i : 'State :',
        b : 'Own House'
    },
    {
        i : 'Education :',
        b : 'M.B.A'
    },
    {
        i : 'Occupation :',
        b : 'S/w Engineer'
    },
    {
        i : 'Partner Expectations:',
        b : '-'
    },
]

export const HoroscopeDetails = [ 
    {
        i : 'Date of Birth :',
        b : '19 August 1985'
    },
    {
        i : 'Place of Birth :',
        b : 'Kurnool'
    },
    {
        i : 'Place of Country :',
        b : 'India'
    },
    {
        i : 'Time of Birth : ',
        b : ' 6:55 AM'
    },
    {
        i : 'Moon sign :',
        b : '-'
    },
    {
        i : 'Rasi :',
        b : 'Tula'
    },
    {
        i : 'Star :',
        b : 'Ashwini'
    },
    {
        i : 'Gotra :',
        b : 'kasi'
    },
    {
        i : 'Shani :        ',
        b : '-'
    },
]


export const FamilyDetails = [ 
    {
        i : 'Father Name :',
        b : 'Surya Naryana'
    },
    {
        i : 'Father Occupation :',
        b : 'Police'
    },
    {
        i : 'Mother Name : ',
        b : 'Minakshi'
    },
    {
        i : 'Mother Occupation : ',
        b : 'House Wife'
    },
    {
        i : 'Family Type:        ',
        b : '-'
    },
    {
        i : 'Family Status :',
        b : 'Middle class'
    },
    {
        i : 'Mother Tounge :',
        b : 'Telugu'
    },
    {
        i : 'NO.of Sisters :        ',
        b : '06'
    },
    {
        i : 'No.of Brothers :',
        b : '07'
    },
]

export const intrestlink = [ 
    {
        i : 'Sports :',
        b : 'Yes'
    },
    {
        i : 'Movie',
        b : 'Yes'
    },
    {
        i : 'Books',
        b : 'Yes'
    },
    {
        i : 'Travel',
        b : 'Yes'
    },
    {
        i : 'Volunteer WORK:',
        b : 'Yes'
    },
    
]
export const profilehab= [
    {
        i : 'Smoking :',
        b : 'NO'
    },
    {
        i : 'Driking :',
        b : 'yes'
    },
    {
        i : 'Execrise:',
        b : 'No'
    },
    {
        i : 'Eating :',
        b : 'Non-Vegetraian'
    },
    {
        i : 'Sleeping',
        b : 'Night-owl'
    },
]

export const personhobbies =[
{
    i :'Cooking :',
    b :'yes'
},
{
    i :'Music :',
    b :'Yes'
},
{
    i :'Writing :',
    b :'No'
},
{
    i :'Gaming :',
    b :'Yes'
},
{
    i :'Gardening :',
    b :'No :'
},
]