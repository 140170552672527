import React, { useState } from "react";
import "./HeaderStyle.css";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import BookIcon from "@material-ui/icons/Book";
import LockIcon from "@material-ui/icons/Lock";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RateReviewIcon from "@material-ui/icons/RateReview";
import {
  faAddressCard,
  faAsterisk,
  faCircleQuestion,
  faShieldHalved,
  faTent,
  faUserSlash,
  faBlog,
  faInfo,
  faCameraRetro,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
const Header = () => {
  const username = localStorage.getItem("name");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isBlogSubMenuOpen, setBlogIsSubMenuOpen] = useState(false);
  const handleAboutClick = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };
  const handleBlogClick = () => {
    setBlogIsSubMenuOpen(!isBlogSubMenuOpen);
  };
  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };
  return (
    <div>
      <header className="main-header-nav header-style-two-nav">
        <div className="main-box-nav">
          <div className="auto-container-nav clearfix-nav">
            <div className="logo-box-nav">
              <div className="logo-nav">
                <Link to="/">
                  <img
                    className="logo-navimg"
                    style={{ borderRadius: "50px" }}
                    src="https://cdn.cdnparenting.com/articles/2021/09/14191221/08938f86cbffaa4d3027c4c45067a2a8.webp"
                    alt=""
                    title=""
                    height={100}
                    width={70}
                  />
                </Link>
              </div>
            </div>
            <div className="nav-outer-nav clearfix-nav">
              <nav className="main-menu-nav navbar-expand-md-nav navbar-light-nav">
                <div className="navbar-header-nav">
                  <button
                    className="navbar-toggler-nav"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="icon flaticon-menu-button" />
                  </button>
                </div>
                <div
                  className="collapse-nav navbar-collapse-nav clearfix-nav"
                  id="navbarSupportedContent"
                >
                  <ul className="navigation-nav clearfix-nav">
                    <li className="current-nav dropdown-nav">
                      <Link
                        to="/"
                        style={{
                          color: "white",
                          fontSize: "18px",
                          fontWeight: "700px",
                        }}
                      >
                        Home
                      </Link>
                    </li>
                    <li className="dropdown-nav">
                      <Link
                        to="/About"
                        style={{
                          color: "white",
                          fontSize: "18px",
                          fontWeight: "700px",
                        }}
                      >
                        About
                      </Link>
                      <ul>
                        <li>
                          <Link to="/About">
                            <span>
                              <FontAwesomeIcon
                                icon={faAddressCard}
                                style={{ marginRight: "10px" }}
                              />{" "}
                              About Us
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/terms-conditions">
                            <span>
                              <FontAwesomeIcon
                                icon={faAsterisk}
                                style={{ marginRight: "10px" }}
                              />
                              Terms &amp; Condition
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/faqs">
                            <span></span>
                            <FontAwesomeIcon
                              icon={faCircleQuestion}
                              style={{ marginRight: "10px" }}
                            />
                            FAQ's
                          </Link>
                        </li>
                        <li>
                          <Link to="/privacy-policy">
                            <span></span>
                            <FontAwesomeIcon
                              icon={faShieldHalved}
                              style={{ marginRight: "10px" }}
                            />
                            Privacy Policy
                          </Link>
                        </li>
                        <li>
                          <Link to="/returns-and-cancellation">
                            <span>
                              <FontAwesomeIcon
                                icon={faTent}
                                style={{ marginRight: "10px" }}
                              />
                              Refund Policy
                            </span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/disclaimer">
                            <FontAwesomeIcon
                              icon={faUserSlash}
                              style={{ marginRight: "10px" }}
                            />
                            Disclaimer
                          </Link>
                        </li>
                        <li>
                          <Link to="/safematrimony">
                            <span></span>
                            <FontAwesomeIcon
                              icon={faCameraRetro}
                              style={{ marginRight: "10px" }}
                            />
                            Safe Matrimony
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown-nav">
                      <Link
                        to="/Payment"
                        style={{
                          color: "white",
                          fontSize: "18px",
                          fontWeight: "700px",
                        }}
                      >
                        Membership
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/Contact"
                        style={{
                          color: "white",
                          fontSize: "18px",
                          fontWeight: "700px",
                        }}
                      >
                        Contact
                      </Link>
                    </li>
                    <li className="dropdown-nav">
                      <Link
                        to="/BlogpostForm"
                        target="_top"
                        style={{
                          color: "white",
                          fontSize: "18px",
                          fontWeight: "700px",
                        }}
                      >
                        Blog
                      </Link>
                      <ul>
                        <li>
                          <Link to="/BlogpostForm">
                            <span style={{ marginLeft: "20px" }}></span>
                            <FontAwesomeIcon
                              icon={faBlog}
                              style={{ marginRight: "10px" }}
                            />
                            BlogPost Form
                          </Link>
                        </li>
                        <li>
                          <Link to="/BlogDetails">
                            <span style={{ marginLeft: "25px" }}></span>
                            <FontAwesomeIcon
                              icon={faInfo}
                              style={{ marginRight: "10px" }}
                            />
                            BlogDetails
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </nav>
              <div className="outer-box-nav">
                {/* Button Box */}
                <div className="btn-boxL-nav">
                  <Link
                    to="/Login"
                    className="theme-btnL-nav btnL-nav btn-style-oneL-nav"
                  >
                    <span className="btn-titleL-nav">Login</span>
                  </Link>
                </div>
                <div className="btn-boxL-nav">
                  <Link
                    to="/Signup"
                    className="theme-btnL-nav btnL-nav btn-style-oneL-nav"
                  >
                    <span className="btn-titleL-nav">SignUp</span>
                  </Link>
                </div>
              </div>
              {/* Hamburger Menu Button */}
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerOpen}
                className="menu-icon-button"
              >
                <MenuIcon
                  style={{
                    fontSize: "3rem",
                    height: "50px",
                    width: "50px",
                    backgroundColor: "white",
                  }}
                />
              </IconButton>
              {/* Responsive Drawer */}
              <Drawer
                anchor="left"
                open={isDrawerOpen}
                onClose={handleDrawerClose}
              >
                <div style={{ backgroundColor: "black" }}>
                  <List>
                    <ListItem button onClick={handleDrawerClose}>
                      <ListItemIcon>
                        <img
                          className="logo-navimg"
                          style={{ borderRadius: "50px" }}
                          src="https://cdn.cdnparenting.com/articles/2021/09/14191221/08938f86cbffaa4d3027c4c45067a2a8.webp"
                          alt=""
                          title=""
                          height={100}
                          width={70}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          variant="h6"
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "1.2rem",
                          }}
                        >
                          <Link
                            to="/"
                            style={{ textDecoration: "none", color: "yellow" }}
                          ></Link>
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem button onClick={handleDrawerClose}>
                      <ListItemIcon>
                        <HomeIcon style={{ color: "yellow" }} />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          variant="h6"
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "1.2rem",
                          }}
                        >
                          <Link
                            to="/"
                            style={{ textDecoration: "none", color: "yellow" }}
                          >
                            Home
                          </Link>
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <hr style={{ color: "white" }} />
                    <ListItem button onClick={handleAboutClick}>
                      <ListItemIcon>
                        <InfoIcon style={{ color: "yellow" }} />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          variant="h6"
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "1.2rem",
                          }}
                        >
                          <Link
                            to="#"
                            style={{ textDecoration: "none", color: "yellow" }}
                          >
                            About
                          </Link>
                        </Typography>
                      </ListItemText>
                      <FontAwesomeIcon
                        icon={faAngleLeft}
                        rotation={270}
                        size="2xl"
                        style={{ color: "#f1f2f3" }}
                      />
                    </ListItem>
                    {isSubMenuOpen && (
                      <ListItem button onClick={handleDrawerClose}>
                        <ul>
                          <li
                            style={{ marginBottom: "10px" }}
                            className="dropdown-nav"
                          >
                            <Link
                              to="/About"
                              style={{
                                textDecoration: "none",
                                color: "white",
                                fontSize: "20px",
                                fontWeight: 500,
                              }}
                            >
                              <FontAwesomeIcon icon={faAddressCard} />
                              <span style={{ marginLeft: "20px" }}>
                                About Us
                              </span>{" "}
                            </Link>
                          </li>
                          <hr style={{ color: "white" }} />
                          <li style={{ marginBottom: "10px" }}>
                            <Link
                              to="/terms-conditions"
                              style={{
                                textDecoration: "none",
                                color: "white",
                                fontSize: "20px",
                                fontWeight: 500,
                              }}
                            >
                              <FontAwesomeIcon icon={faAsterisk} />
                              <span style={{ marginLeft: "20px" }}>
                                Terms &amp; Condition
                              </span>
                            </Link>
                          </li>
                          <hr style={{ color: "white" }} />
                          <li style={{ marginBottom: "10px" }}>
                            <Link
                              to="/faqs"
                              style={{
                                textDecoration: "none",
                                color: "white",
                                fontSize: "20px",
                                fontWeight: 500,
                              }}
                            >
                              <FontAwesomeIcon icon={faCircleQuestion} />{" "}
                              <span style={{ marginLeft: "20px" }}></span>FAQ
                            </Link>
                          </li>
                          <hr style={{ color: "white" }} />
                          <li style={{ marginBottom: "10px" }}>
                            <Link
                              to="/privacy-policy"
                              style={{
                                textDecoration: "none",
                                color: "white",
                                fontSize: "20px",
                                fontWeight: 500,
                              }}
                            >
                              <FontAwesomeIcon icon={faShieldHalved} />{" "}
                              <span style={{ marginLeft: "20px" }}></span>
                              Privacy Policy
                            </Link>
                          </li>
                          <hr style={{ color: "white" }} />
                          <li style={{ marginBottom: "10px" }}>
                            <Link
                              to="/returns-and-cancellation"
                              style={{
                                textDecoration: "none",
                                color: "white",
                                fontSize: "20px",
                                fontWeight: 500,
                              }}
                            >
                              <FontAwesomeIcon icon={faTent} />
                              <span style={{ marginLeft: "20px" }}>
                                Refund Policy
                              </span>{" "}
                            </Link>
                          </li>
                          <hr style={{ color: "white" }} />
                          <li style={{ marginBottom: "10px" }}>
                            <Link
                              to="/disclaimer"
                              style={{
                                textDecoration: "none",
                                color: "white",
                                fontSize: "20px",
                                fontWeight: 500,
                              }}
                            >
                              <FontAwesomeIcon icon={faUserSlash} />{" "}
                              <span style={{ marginLeft: "20px" }}></span>{" "}
                              disclaimer
                            </Link>
                          </li>
                          <hr style={{ color: "white" }} />
                          <li style={{ marginBottom: "10px" }}>
                            <Link
                              to="/safematrimony"
                              style={{
                                textDecoration: "none",
                                color: "white",
                                fontSize: "20px",
                                fontWeight: 500,
                              }}
                            >
                              <FontAwesomeIcon icon={faCameraRetro} />{" "}
                              <span style={{ marginLeft: "20px" }}></span> Safe
                              Matrimony{" "}
                            </Link>
                          </li>
                        </ul>
                      </ListItem>
                    )}
                    <hr style={{ color: "white" }} />
                    <ListItem button onClick={handleDrawerClose}>
                      <ListItemIcon>
                        <AccountBalanceIcon style={{ color: "yellow" }} />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          variant="h6"
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "1.2rem",
                          }}
                        >
                          <Link
                            to="/Payment"
                            style={{ textDecoration: "none", color: "yellow" }}
                          >
                            Membership
                          </Link>
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <hr style={{ color: "white" }} />
                    <ListItem button onClick={handleDrawerClose}>
                      <ListItemIcon>
                        <ContactMailIcon style={{ color: "yellow" }} />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          variant="h6"
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "1.2rem",
                          }}
                        >
                          <Link
                            to="/Contact"
                            style={{ textDecoration: "none", color: "yellow" }}
                          >
                            Contact
                          </Link>
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <hr style={{ color: "white" }} />
                    <ListItem button onClick={handleBlogClick}>
                      <ListItemIcon>
                        <BookIcon style={{ color: "yellow" }} />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          variant="h6"
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "1.2rem",
                          }}
                        >
                          <Link
                            to="#"
                            style={{ textDecoration: "none", color: "yellow" }}
                          >
                            Blog
                          </Link>
                        </Typography>
                      </ListItemText>
                      <FontAwesomeIcon
                        icon={faAngleLeft}
                        rotation={270}
                        size="2xl"
                        style={{ color: "#f1f2f3" }}
                      />
                    </ListItem>
                    <hr style={{ color: "white" }} />

                    {isBlogSubMenuOpen && (
                      <ListItem button onClick={handleDrawerClose}>
                        <ul>
                          <li style={{ marginBottom: "10px" }}>
                            <Link
                              to="/BlogpostForm"
                              style={{
                                textDecoration: "none",
                                color: "white",
                                fontSize: "20px",
                                fontWeight: 500,
                              }}
                            >
                              <FontAwesomeIcon icon={faBlog} />
                              <span style={{ marginLeft: "20px" }}></span> Blog
                              Post Form
                            </Link>
                          </li>
                          <hr style={{ color: "white" }} />
                          <li style={{ marginBottom: "10px" }}>
                            <Link
                              to="/BlogDetails"
                              style={{
                                textDecoration: "none",
                                color: "white",
                                fontSize: "20px",
                                fontWeight: 500,
                              }}
                            >
                              <FontAwesomeIcon icon={faInfo} />{" "}
                              <span style={{ marginLeft: "25px" }}></span> Blog
                              Details
                            </Link>
                          </li>
                        </ul>
                      </ListItem>
                    )}
                    <ListItem button onClick={handleDrawerClose}>
                      <ListItemIcon>
                        <RateReviewIcon style={{ color: "yellow" }} />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          variant="h6"
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "1.2rem",
                          }}
                        >
                          <Link
                            to="/dashboard"
                            style={{
                              textDecoration: "none",
                              color: "yellow",
                            }}
                          >
                            Dashboard
                          </Link>
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <hr style={{ color: "white" }} />
                    <ListItem button onClick={handleDrawerClose}>
                      <ListItemIcon>
                        <LockIcon style={{ color: "yellow" }} />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          variant="h6"
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "1.2rem",
                          }}
                        >
                          <Link
                            to="/Login"
                            style={{ textDecoration: "none", color: "yellow" }}
                          >
                            Login
                          </Link>
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <hr style={{ color: "white" }} />
                    <ListItem button onClick={handleDrawerClose}>
                      <ListItemIcon>
                        <PersonAddIcon style={{ color: "yellow" }} />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          variant="h6"
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "1.2rem",
                          }}
                        >
                          <Link
                            to="/Signup"
                            style={{ textDecoration: "none", color: "yellow" }}
                          >
                            SignUp
                          </Link>
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </div>
              </Drawer>
              {/* Main Menu */}
              <nav className="main-menu-nav navbar-expand-md-nav navbar-light-nav"></nav>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
export default Header;
