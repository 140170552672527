import React, { useState } from "react";
import './CompatibulityMatches.css'

const HeroineCard = ({ imageSrc, name, id, height, qualification, age, profileCreatedBy, maritalStatus, moonSign, star, gotra, mangalik, shani, occupation, annualincome, workingcity, familytype, familystatus, familyvalues, mothertounge, lookingfor, agefrom, ageto, heightfrom, heightto }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
       <div class="heroine-card-container">
    <div className="heroine-card-latest-comp">
      <img src={imageSrc} alt={name} />
      <div className="details-latest-comp">
        <h2>{name}</h2>
        <p>Id: {id}</p>
        <p>Height: {height}</p>
        <p>Qualification: {qualification}</p>

       
        {/* <button className="view-profile-btn-latest">send interest</button> */}
        <button class="button-86-comp" role="button">Send Interest</button>

       

      </div>
    </div>
    </div>
  );
};

const CompatibuilityMatches = () => {
  const [filter, setFilter] = useState(""); 
  const [qualificationFilter, setQualificationFilter] = useState(""); 
  const [heightFilter, setHeightFilter] = useState(""); 
  const [showMore, setShowMore] = useState(false);
  const [moonSignFilter, setMoonSignFilter] = useState(""); 
  const [starFilter, setStarFilter] = useState(""); 
  const [gotraFilter, setGotraFilter] = useState(""); 
  const [mangalikFilter, setMangalikFilter] = useState(""); 
  const [shaniFilter, setShaniFilter] = useState("");
  const [occupationFilter, setOccupationFilter] = useState("");
  const [employedInFilter, setEmployedInFilter] = useState("");
  const [workingcityFilter, setworkingcityFilter] = useState("");
  const [annualIncomeFilter, setAnnualIncomeFilter] = useState("");
  const [familytypeFilter, setFamilyTypeFilter] = useState("");
  const [familyvaluesFilter, setFamilyValuesFilter] = useState("");
  const [familystatusFilter, setFamilyStatusFilter] = useState("");
  const [mothertoungeFilter, setMotherToungeFilter] = useState("");
  const [lookingforFilter, setLookingForFilter] = useState("");
  const [agefromFilter, setAgeFromFilter] = useState("");
  const [agetoFilter, setAgeToFilter] = useState("");
  const [heightfromFilter, setHeightFromFilter] = useState("");
  const [heighttoFilter, setHeightToFilter] = useState("");
  // Example data for heroine cards
  const heroineData = [

    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2rxUClTpphQ0zzYCEwgRqVgMSdt8jaSNgeFPp0mDKwg&s",
      name: "anethesia",
      id: 5,
      height: "155cm",
      qualification: "mba",
      age: 30,

      maritalStatus: "Single",
      moonSign: "mesh",
      star: "bharani",
      gotra: "pydipala",
      mangalik: "yes",
      shani: "yes",
      occupation: "engineer",
      annualincome: "5LPA",
      employedin: "state government",
      workingcity: "kolkata",
      familystatus: "poor",
      familytype: "nuclear family",
      familyvalues: "good",
      mothertounge: "telugu",
      lookingfor: "unmarried",

    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2rxUClTpphQ0zzYCEwgRqVgMSdt8jaSNgeFPp0mDKwg&s",
      name: "devil",
      id: 5,
      height: "155cm",
      qualification: "mba",
      age: 30,

      maritalStatus: "Single",
      moonSign: "mesh",
      star: "bharani",
      gotra: "pydipala",
      mangalik: "yes",
      shani: "yes",
      occupation: "engineer",
      annualincome: "5LPA",
      employedin: "state government",
      workingcity: "kolkata",
      familystatus: "poor",
      familytype: "nuclear family",
      familyvalues: "good",
      mothertounge: "telugu"
    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2rxUClTpphQ0zzYCEwgRqVgMSdt8jaSNgeFPp0mDKwg&s",
      name: "anethesia",
      id: 5,
      height: "155cm",
      qualification: "mba",
      age: 30,

      maritalStatus: "Single",
      moonSign: "mesh",
      star: "bharani",
      gotra: "pydipala",
      mangalik: "yes",
      shani: "yes",
      occupation: "engineer",
      annualincome: "5LPA",
      employedin: "state government",
      workingcity: "kolkata",
      familystatus: "poor",
      familytype: "nuclear family",
      familyvalues: "good",
      mothertounge: "telugu"
    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2rxUClTpphQ0zzYCEwgRqVgMSdt8jaSNgeFPp0mDKwg&s",
      name: "anethesia",
      id: 5,
      height: "155cm",
      qualification: "mba",
      age: 30,

      maritalStatus: "Single",
      moonSign: "mesh",
      star: "bharani",
      gotra: "pydipala",
      mangalik: "yes",
      shani: "yes",
      occupation: "engineer",
      annualincome: "5LPA",
      employedin: "state government",
      workingcity: "kolkata",
      familystatus: "poor",
      familytype: "nuclear family",
      familyvalues: "good",
      mothertounge: "telugu"
    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2rxUClTpphQ0zzYCEwgRqVgMSdt8jaSNgeFPp0mDKwg&s",
      name: "anethesia",
      id: 5,
      height: "155cm",
      qualification: "mba",
      age: 30,

      maritalStatus: "Single",
      moonSign: "mesh",
      star: "bharani",
      gotra: "pydipala",
      mangalik: "yes",
      shani: "yes",
      occupation: "engineer",
      annualincome: "5LPA",
      employedin: "state government",
      workingcity: "kolkata",
      familystatus: "poor",
      familytype: "nuclear family",
      familyvalues: "good",
      mothertounge: "telugu"
    },
    {
      imageSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2rxUClTpphQ0zzYCEwgRqVgMSdt8jaSNgeFPp0mDKwg&s",
      name: "anethesia",
      id: 5,
      height: "155cm",
      qualification: "mba",
      age: 30,

      maritalStatus: "Single",
      moonSign: "mesh",
      star: "bharani",
      gotra: "pydipala",
      mangalik: "yes",
      shani: "yes",
      occupation: "engineer",
      annualincome: "5LPA",
      employedin: "state government",
      workingcity: "kolkata",
      familystatus: "poor",
      familytype: "nuclear family",
      familyvalues: "good",
      mothertounge: "telugu"
    },
    
    
  ];
  const filteredHeroines = heroineData.filter((heroine) =>
    heroine.name.toLowerCase().includes(filter.toLowerCase()) &&
    heroine.qualification.toLowerCase().includes(qualificationFilter.toLowerCase()) &&
    heroine.height.toLowerCase().includes(heightFilter.toLowerCase()) &&
    heroine.moonSign.toLowerCase().includes(moonSignFilter.toLowerCase()) &&
    heroine.star.toLowerCase().includes(gotraFilter.toLowerCase()) &&
    heroine.gotra.toLowerCase().includes(moonSignFilter.toLowerCase()) &&
    heroine.mangalik.toLowerCase().includes(mangalikFilter.toLowerCase()) &&
    heroine.shani.toLowerCase().includes(shaniFilter.toLowerCase()) &&
    heroine.occupation.toLowerCase().includes(occupationFilter.toLowerCase()) &&
    heroine.annualincome.toLowerCase().includes(annualIncomeFilter.toLowerCase()) &&
    heroine.workingcity.toLowerCase().includes(workingcityFilter.toLowerCase()) &&
    heroine.employedin.toLowerCase().includes(employedInFilter.toLowerCase()) &&
    heroine.familyvalues.toLowerCase().includes(familyvaluesFilter.toLowerCase()) &&
    heroine.familytype.toLowerCase().includes(familytypeFilter.toLowerCase()) &&
    heroine.familystatus.toLowerCase().includes(familystatusFilter.toLowerCase()) &&
    heroine.mothertounge.toLowerCase().includes(mothertoungeFilter.toLowerCase())
  );

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };


  return (
    <div>

      <section className="page-title-latest" style={{ backgroundColor: "black" }}>
        <div className="auto-container-latest-comp">
          <h1 className="d-noneb d-lg-blockb d-xl-blockb d-md-bloc"kb>COMPATIBILITY MATCHES</h1>
          <ul className="bread-crumbb- clearfix-latest">
            <li>
              <a href="index_dashboardb">Dashboard</a>
            </li>
            <li>Compatibilty Matches</li>
          </ul>
        </div>
      </section>

      <div className="latest-matches-container-comp">
      <div className="cards-section-comp">
     
        {filteredHeroines.map((heroine, index) => (
          <HeroineCard
            key={index}
            imageSrc={heroine.imageSrc}
            name={heroine.name}
            id={heroine.id}
            height={heroine.height}
            qualification={heroine.qualification}
            age={heroine.age}

            maritalStatus={heroine.maritalStatus}
            moonSign={heroine.moonSign}
            star={heroine.star}
            gotra={heroine.gotra}
            mangalik={heroine.mangalik}
            shani={heroine.shani}
            occupation={heroine.occupation}
            annualincome={heroine.annualincome}
            workingcity={heroine.workingcity}
            familytype={heroine.familytype}
            familystatus={heroine.familystatus}
            familyvalues={heroine.familyvalues}
            mothertounge={heroine.mothertounge}
            lookingfor={heroine.lookingfor}
            agefrom={heroine.agefrom}
            ageto={heroine.ageto}
            heightfrom={heroine.heightfrom}
            heightto={heroine.heightto}
          />
        ))}
     
      </div>
      </div>
      
    </div>
  );
};

export default CompatibuilityMatches;