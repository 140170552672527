export const profiles = [
  {
    name: "John Doe",
    age: 28,
    height: 5.9,
    image:
      "https://www.mockofun.com/wp-content/uploads/2019/12/circle-profile-pic.jpg",
  },
  {
    name: "Jane Smith",
    age: 32,
    height: 5.5,
    image:
      "https://www.mockofun.com/wp-content/uploads/2019/12/circle-profile-pic.jpg",
  },
  {
    name: "Ali Khan",
    age: 30,
    height: 5.8,
    image:
      "https://www.mockofun.com/wp-content/uploads/2019/12/circle-profile-pic.jpg",
  },
  {
    name: "Achal Mohite",
    age: 25,
    height: 5.4,
    image:
      "https://www.mockofun.com/wp-content/uploads/2019/12/circle-profile-pic.jpg",
  },
  {
    name: "Monika Kohli",
    age: 28,
    height: 5.2,
    image:
      "https://www.mockofun.com/wp-content/uploads/2019/12/circle-profile-pic.jpg",
  },
  {
    name: "Kamini Varma",
    age: 24,
    height: 5.6,
    image:
      "https://www.mockofun.com/wp-content/uploads/2019/12/circle-profile-pic.jpg",
  },
];
export const profileOptions = [
  { value: "all", label: "All" },
  { value: "withPhoto", label: "With Photo" },
  { value: "withoutPhoto", label: "Without Photo" },
];
export const registrationOptions = [
  { value: "today", label: "Today" },
  { value: "last3days", label: "Last 3 Days" },
  { value: "oneWeek", label: "One Week" },
  { value: "oneMonth", label: "One Month" },
];
export const initailData = {
  profileType: "",
  profileRegistered: "",
};
