import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './Successview.css';

const Successview = () => {
  const [story, setStory] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchStory = async () => {
      try {
        const response = await fetch(`https://matrimonyadmin.hola9.com/api/Successtoriesapi/${id}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setStory(data);
      } catch (error) {
        console.error('Error fetching success story:', error);
      }
    };

    fetchStory();
  }, [id]);

  if (!story) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="success-title">
      <h1>{story.Title}</h1>
      </div>
      <img src={story.image} alt={story.Title} />
      <p>{story.Description}</p>
    </div>
  );
};

export default Successview;
