import React, { useState, useEffect } from 'react';
import {
  BsFillPencilFill,

} from "react-icons/bs";
import { BarLoader } from 'react-spinners';

const Newinterest = () => {
  const [datainterest, setDataInterest] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [sports, setSports] = useState(false);
  const [sports_details, setSports_details] = useState("");
  const [movie, setMovie] = useState(false);
  const [movie_details, setMovie_details] = useState("");
  const [books, setBooks] = useState(false);
  const [books_details, setBooks_details] = useState("");
  const [travel, setTravel] = useState(false);
  const [travel_details, setTravel_details] = useState("");
  const [volunteer_work, setVolunteer_work] = useState(false);
  const [volunteer_work_details, setVolunteer_work_details] = useState("");
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://matrimonyadmin.hola9.com/api/profileapi/interests/`);
        const jsonData = await response.json();
        setDataInterest(jsonData);
        setIsLoading(false);
        if (jsonData.length > 0) {
          const latestData = jsonData[jsonData.length - 1];
          setSelectedId(latestData.id);
          setSports(latestData.sports);
          setSports_details(latestData.sports_details);
          setMovie(latestData.movie);
          setMovie_details(latestData.movie_details);
          setBooks(latestData.books);
          setBooks_details(latestData.books_details);
          setTravel(latestData.travel);
          setTravel_details(latestData.travel_details);
          setVolunteer_work(latestData.volunteer_work);
          setVolunteer_work_details(latestData.volunteer_work_details);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://matrimonyadmin.hola9.com/api/profileapi/interests/${selectedId}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: selectedId,
          sports,
          sports_details,
          movie,
          movie_details,
          books,
          books_details,
          travel,
          travel_details,
          volunteer_work,
          volunteer_work_details
        }),
      });
      if (response.ok) {
        setDataInterest(prevData => {
          const newData = [...prevData];
          const index = newData.findIndex(item => item.id === selectedId);
          if (index !== -1) {
            newData[index] = {
              id: selectedId,
              sports,
              sports_details,
              movie,
              movie_details,
              books,
              books_details,
              travel,
              travel_details,
              volunteer_work,
              volunteer_work_details
            };
          }
          return newData;
        });
        closeModal();
      } else {
        console.error('Failed to update data:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <BarLoader color={'#123abc'} loading={isLoading} /> {/* Display BarLoader while data is being fetched */}
      </div>
    );
  }
  return (
    <div>
      {/* <div>NewInterest</div> */}
      <div
        className="feature-block-twop col-lg-4p col-md-6p col-sm-6p wow fadeInUp animated"
        style={{ visibility: "visible", animationName: "fadeInUp" }}
      >
        {datainterest.length > 0 && (
          <div className="inner-boxp">
            <div className="icon-boxp">
              <img
                src="https://www.matrimonysoftware.in/icon/education.png"
                alt=""
              />
            </div>
            <h4>
              <a href="education">
                NewInterest{" "}
                <i style={{ fontSize: 24 }} className="fa">
                  
                </i>
              </a>
            </h4>
            <BsFillPencilFill className="editpro"   onClick={openModal}/>
            <div className="textpro"><span>Sports:</span> {sports ? 'Yes' : 'No'}</div>
            {sports && <div className="textpro"><span>sports_details:</span> {sports_details}</div>}
            <div className="textpro"><span>Movie:</span> {movie ? 'Yes' : 'No'}</div>
            {movie && <div className="textpro"><span>movie_details: </span>{movie_details}</div>}
            <div className="textpro"><span>Books:</span> {books ? 'Yes' : 'No'}</div>
            {books && <div className="textpro"><span>book_details:</span> {books_details}</div>}
            <div className="textpro"><span>Travel:</span> {travel ? 'Yes' : 'No'}</div>
            {travel && <div className="textpro"><span>travel_details:</span> {travel_details}</div>}
            <div className="textpro"><span>Volunteer_work:</span> {volunteer_work ? 'Yes' : 'No'}</div>
            {volunteer_work && <div className="textpro"><span>volunteer_work_details:</span> {volunteer_work_details}</div>}

            {/* <button className="btn btn-primary" onClick={openModal}>Edit</button> */}
          </div>
        )}
      </div>

      <div className={`modal ${isModalOpen ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: isModalOpen ? 'block' : 'none' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Details</h5>
              <button type="button" className="close" onClick={closeModal}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="hobby-item">
                  <label className='hobby-label' style={{fontSize:"20px",color:"white"}}><h10>Sports:</h10></label>
                  <div className="radio-group" style={{width:"90%",height:"60px",marginTop:"2%"}}>
                    <label className='hobby-label'>
                      <input
                        className='radio-btn'
                        type="radio"
                        name="sports"
                        checked={sports}
                        onChange={() => setSports(true)}
                      />
                      Yes
                      </label>
                    <label className='hobby-label'>
                      <input
                        className='radio-btn'
                        type="radio"
                        name="sports"
                        checked={!sports}
                        onChange={() => setSports(false)}
                      />
                      No
                    </label>
                  </div>
                  {sports && <input className='hobby-text' type="text" placeholder="Enter your cooking details" name="sports_details" value={sports_details} onChange={(e) => setSports_details(e.target.value)} />}
                </div>

                <div className="hobby-item">
                  <label className='hobby-label' style={{fontSize:"20px",color:"white"}}><h10>Movie:</h10></label>
                  <div className="radio-group" style={{width:"90%",height:"60px",marginTop:"2%"}}>
                    <label className='hobby-label'>
                      <input
                        className='radio-btn'
                        type="radio"
                        name="movie"
                        checked={movie}
                        onChange={() => setMovie(true)}
                      />
                      Yes
                    </label>
                    <label className='hobby-label'>
                      <input
                        className='radio-btn'
                        type="radio"
                        name="movie"
                        checked={!movie}
                        onChange={() => setMovie(false)}
                      />
                      No
                    </label>
                  </div>
                  {movie && <input className='hobby-text' type="text" placeholder="Enter your music details" name="movie_details" value={movie_details} onChange={(e) => setMovie_details(e.target.value)} />}
                </div>

                <div className="hobby-item">
                  <label className='hobby-label' style={{fontSize:"20px",color:"white"}}><h10>Books:</h10></label>
                  <div className="radio-group" style={{width:"90%",height:"60px",marginTop:"2%"}}>
                    <label className='hobby-label'>
                      <input
                        className='radio-btn'
                        type="radio"
                        name="books"
                        checked={books}
                        onChange={() => setBooks(true)}
                      />
                      Yes
                    </label>
                    <label className='hobby-label'>
                      <input
                        className='radio-btn'
                        type="radio"
                        name="books"
                        checked={!books}
                        onChange={() => setBooks(false)}
                      />
                      No
                    </label>
                  </div>
                  {books && <input className='hobby-text' type="text" placeholder="Enter your writing details" name="books_details" value={books_details} onChange={(e) => setBooks_details(e.target.value)} />}
                </div>

                <div className="hobby-item">
                  <label className='hobby-label' style={{fontSize:"20px",color:"white"}}><h10>Travel:</h10></label>
                  <div className="radio-group" style={{width:"90%",height:"60px",marginTop:"2%"}}>
                    <label className='hobby-label'>
                      <input
                        className='radio-btn'
                        type="radio"
                        name="travel"
                        checked={travel}
                        onChange={() => setTravel(true)}
                      />
                      Yes
                    </label>
                    <label className='hobby-label'>
                      <input
                        className='radio-btn'
                        type="radio"
                        name="travel"
                        checked={!travel}
                        onChange={() => setTravel(false)}
                      />
                      No
                    </label>
                  </div>
                  {travel && <input className='hobby-text' type="text" placeholder="Enter your gaming details" name="travel_details" value={travel_details} onChange={(e) => setTravel_details(e.target.value)} />}
                </div>

                <div className="hobby-item">
                  <label className='hobby-label' style={{fontSize:"20px",color:"white"}}><h10>Volunteer_work:</h10></label>
                  <div className="radio-group" style={{width:"90%",height:"60px",marginTop:"2%"}}>
                    <label className='hobby-label'>
                      <input
                        className='radio-btn'
                        type="radio"
                        name="volunteer_work"
                        checked={volunteer_work}
                        onChange={() => setVolunteer_work(true)}
                      />
                      Yes
                    </label>
                    <label className='hobby-label'>
                      <input
                        className='radio-btn'
                        type="radio"
                        name="volunteer_work"
                        checked={!volunteer_work}
                        onChange={() => setVolunteer_work(false)}
                      />
                      No
                    </label>
                  </div>
                  {volunteer_work && <input className='hobby-text' type="text" placeholder="Enter your gardening details" name="volunteer_work_details" value={volunteer_work_details} onChange={(e) => setVolunteer_work_details(e.target.value)} />}
                </div>

                <div className="form-group">

                <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newinterest;
