import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import './BlogpostForm.css';

const BlogpostForm = () => {
  const navigate = useNavigate();
  const currentDate = new Date().toISOString().split('T')[0];
  const initialFormData = {
    title: '',
    date_of_publish: currentDate,
    description: '',
    image: null,
    author_name: '',
    author_email: ''
  };
  const [formData, setFormData] = useState(initialFormData);

  const goBack = () => {
    navigate(-1);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({ ...formData, [name]: files ? files[0] : value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.title || !formData.date_of_publish || !formData.description || !formData.image || !formData.author_name || !formData.author_email) {
      toast.error('Please fill in all fields');
      return;
    }

    if (!isValidEmail(formData.author_email)) {
      toast.error('Please enter a valid email address');
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('title', formData.title);
    formDataToSend.append('date_of_publish', formData.date_of_publish);
    formDataToSend.append('description', formData.description);
    formDataToSend.append('image', formData.image);
    formDataToSend.append('author_name', formData.author_name);
    formDataToSend.append('author_email', formData.author_email);

    try {
      const response = await axios.post('https://matrimonyadmin.hola9.com/api/profileapi/blog-posts/', formDataToSend);
      console.log(response.data);
      toast.success('Post created successfully');
      setFormData(initialFormData);
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to create post. Please try again.');
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <>
      <div className='create-blog-post' style={{ marginTop: '100px',marginLeft: "20px"}}>
        <button onClick={goBack} className='back-button-pay'>
          <i className="ri-arrow-left-line"></i>
        </button>

        <div className='blogpost-img'>
          <img
            src="https://img.freepik.com/free-vector/blogging-fun-content-creation-online-streaming-video-blog-young-girl-making-selfie-social-network-sharing-feedback-self-promotion-strategy_335657-2386.jpg?t=st=1715927943~exp=1715931543~hmac=81b81515eea4ce9c160e0eb72ee064fa407fe6964de2a5ff4526c56e7b5e7054&w=740"
            alt="Blogging Fun"
            className='blog-image'
          />

          <form className="blogpost-form" encType="multipart/form-data" onSubmit={handleSubmit}>
            <div style={{ textAlign: "center" }}>
              <h1 className='blog-heading'>Blog Details</h1>
            </div>
            <div className='form-details-blog'>
              <label className='form-labelb'>Title:</label>
              <input
                type="text"
                name="title"
                placeholder='enter blog title'
                value={formData.title}
                onChange={handleChange}
                className="form-input-blog"
              />
            </div>

            <div className='form-details-blog'>
              <label className='form-labelb'>Date_Of_Publish:</label>
              <input className="form-input-blog" type="date" name="date_of_publish" value={formData.date_of_publish} onChange={handleChange} />
            </div>

            <div className='form-details-blog'>
              <label className='form-labelb'>Description:</label>
              <textarea className='blog-textarea' name="description" placeholder="Description" value={formData.description} onChange={handleChange}></textarea>
            </div>

            <div className='form-details-blog'>
              <label className='form-labelb'>Image:</label>
              <input className="form-input-blog" type="file" name="image" onChange={handleChange} />
            </div>

            <div className='form-details-blog'>
              <label className='form-labelb'>Author Name:</label>
              <input className="form-input-blog" type="text" name="author_name" placeholder="Author Name" value={formData.author_name} onChange={handleChange} />
            </div>

            <div className='form-details-blog'>
              <label className='form-labelb'>Author Email:</label>
              <input className="form-input-blog" type="email"name="author_email"placeholder="Author Email"value={formData.author_email}onChange={handleChange}/>
</div>

<button className="blog-button" type="submit"><span className='blog-btn-text'>Submit</span></button>
</form>
<ToastContainer /> {/* Container for displaying toast messages */}
</div>
</div>
</>
);
};

export default BlogpostForm;