import React, { useState, useRef } from 'react';
import axios from 'axios';
import "./Addcouplesimage.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone, faUser } from '@fortawesome/free-solid-svg-icons';


const Addcouplesimage = () => {
    const [photos, setPhotos] = useState(Array.from({ length: 6 }, () => null));
    const fileInputRefs = useRef(Array.from({ length: 6 }, () => React.createRef()));

    const handlePhotoChange = (index, event) => {
        const file = event.target.files[0];
        const newPhotos = [...photos];
        newPhotos[index] = file;
        setPhotos(newPhotos);
    };

    const handleSubmit = async () => {
        try {
            const formData = new FormData();
            photos.forEach((photo, index) => {
                if (photo) {
                    formData.append(`pic${index + 1}`, photo);
                }
            });
            const response = await axios.post('https://matrimonyadmin.hola9.com/api/crud/siximages/', formData);
            console.log('Response:', response.data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleIconClick = (index) => {
        fileInputRefs.current[index].current.click();
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (index, event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        const newPhotos = [...photos];
        newPhotos[index] = file;
        setPhotos(newPhotos);
    };

    return (
        <>


            <div>
                <div className='title-name'>
                    <h1>Image Upload</h1>
                </div>
                <div className='image-outer-box'>
                    {[...Array(6)].map((_, index) => (
                        <div key={index} className="image-container-add" onDrop={(event) => handleDrop(index, event)} onDragOver={(event) => handleDragOver(event)}>
                            {photos[index] && (
                                <img src={URL.createObjectURL(photos[index])} alt={`Image ${index + 1}`} className="uploaded-image" />
                            )}
                            {!photos[index] && (
                                <div className="add-img"  onClick={() => handleIconClick(index)}>
                                    <p>Drag and drop images here or select images from here</p>
                                    <FontAwesomeIcon icon={faClone} />
                                </div> 
                            )}
                            <input
                                ref={fileInputRefs.current[index]}
                                type="file"
                                accept=".jpg, .jpeg, .png, .pdf"
                                style={{ display: 'none' }}
                                onChange={(event) => handlePhotoChange(index, event)}
                                name={`pic${index + 1}`}
                            />
                        </div>
                    ))}
                </div>

                <div className='btn-image-submit'>
                    <button type="button" className='button-images' onClick={handleSubmit}>
                        Submit
                    </button>
                </div>
            </div>
        </>
    );
};

export default Addcouplesimage;
