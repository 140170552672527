import React, { useState } from 'react';
import './Verifyprofile.css'

const VerifyProfile = () => {
  const [formData, setFormData] = useState({
    salary: '',
    education: '',
    id: '',
    selectedDocument: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleDocumentSelection = (document) => {
    setFormData(prevState => ({
      ...prevState,
      selectedDocument: document
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData); 
  };

  return (
    <div className="container-verify">

      <div className="verification-pending-header">
        {/* <img src="https://media.istockphoto.com/id/1263990620/vector/quality-check-certified-badge-icon.jpg?s=612x612&w=0&k=20&c=SbbRgJJQZ8jAB4hU07vh5TZ-YeQjaF1_xAnQKoXCdh0=" alt="Verification Pending" className="verification-pending-image" /> */}
        <div className='verify'><h1 >Your Profile is pending verification</h1>
    
        <img src="https://media.istockphoto.com/id/1263990620/vector/quality-check-certified-badge-icon.jpg?s=612x612&w=0&k=20&c=SbbRgJJQZ8jAB4hU07vh5TZ-YeQjaF1_xAnQKoXCdh0=" alt="Verification Pending" className="verification-pending-image" />
      </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col">
            <div className="card-profile">
              <h2>Salary verification</h2>
              <div className="form-group-profile">
                <p>Annual income mentioned in your profile:1-3 lakhs.</p>
                <p>Upload your salary slip and help us verify your current salary. The salary slip uploaded by you will not be shown to other members.</p>
                <button className='verify-btn' >Upload salary slip</button>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card-profile">
              <h2>Education verification</h2>
              <div className="form-group-profile">
                <p>Education qualification mentioned in your profile: MCA</p>
                <p>Upload your Education certificate and help us verify your educational qualification. The certificate uploaded by you will not be shown to other members.</p>
                <button className='verify-btn' >Upload educational certificate</button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card-profile-verify">
              <h2>Photo verification</h2>
              <div className="form-group-profile">
                <p>We will need to verify your profile photo with a selfie.</p>
                <p>To complete your photo verification, download our mobile application.</p>
                <button className='verify-btn'>Download app</button>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card-profile">
              <h2>ID Verification</h2>
              <div className="form-group-profile">
                <p>Select one of the below documents to verify your identity information. The documents chosen by you will not be shown to other members.</p>

                <button className='verify-btn'>Driving licence</button>
                <button className='verify-btn'>Pan card</button>
                <button className='verify-btn'>Passport</button>
                <button className='verify-btn'> Voter id</button>
                <button className='verify-btn'>Aadhar </button>
                <label className="consent-label">
        <input type="checkbox" className="consent-checkbox" />
        By uploading my ID/Aadhar, I give my consent for the purpose of verifying my profile
      </label>
              
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default VerifyProfile;

