import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import "./Forgot Password Style.css"

const ForgotPassword = () => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    // Get the email from the URL parameters
    const params = new URLSearchParams(window.location.search);
    const emailFromURL = params.get('email');


    // Set the email in the component state
    if (emailFromURL) {
      setEmail(emailFromURL);
    }
  }, []);

  const handleForgotPassword = async (e) => {
    e.preventDefault();


    // Add logic for handling forgot password (send reset email)
    console.log(`Forgot password request for email: ${email}`);
    // Add API call
    const apiUrl = "https://madmin.hola9.com/api/user/send-reset-password-email/";
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Cookie': 'csrftoken=SaICScpFs727gHmISrHEROCdmTkxigWF',
        },
        body: `email=${email}`,
        redirect: 'follow',
      });
      console.log(response);
      
      if (!response.ok) {
        throw new Error('One forget password link has been sent to your registered email.');
      }
      setEmail('');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
 
    <section className="page-title" style={{ backgroundColor: "black",marginTop:"93px"}}>
        <div className="auto-container-forgot">
          <h1 className="forgot-title">ForgotPassword </h1>
          
        </div>
      </section>
      <div className="auto-container-forgot">
  {/*Subscribe Form*/}
  <div className="envelope-image-forgot" />
  <div className="contact-form-forgot">
    <div className="form-inner-forgot">
      <div className="upper-box-forgot">
        <div className="sec-title-forgot text-center-forgot">
         <br/>
      
          
          <div className="text-forgot">
            
            Please enter your email address to retain your password..
          </div>
        </div>
      </div>
      <center>
      <form
        method="post"
        action="forgot_password_submit"
        id="contact-form"
        noValidate="novalidate"
        onSubmit={handleForgotPassword}
      >
        <div className="row-forgot clearfix-forgot">
          <div className="col-lg-2-forgot col-md-4-forgot col-sm-4-forgot"></div>
          <div
            className="col-lg-8-forgot col-md-5-forgot col-sm-8-forgot form-group-forgot"
            id="contact-form"
          >
          <label className='forgot-email'>Email :</label>
            <input
            className='forgot-input'
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
              style={{ width: "38%"}}
              placeholder="Enter email address"
            />
            <br />
        

            <div className="btn-box-forgot">
              <button
                className="theme-btn-forgot btn-forgot btn-style-one-forgot mb-3-forgot"
                type="submit"
                name="submit"
                onClick={handleForgotPassword}
              >
                <span className="btn-title-forgot">
              
                <Link >Submit</Link>
                </span>
              </button>
              
            </div>
            
          </div>
        </div>
     
     
      </form>
      </center>
    </div>
  </div>
</div>

    </div>
  )
}

export default ForgotPassword;
