import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './Viewmore.css';

const Viewmore = () => {
  const { id } = useParams();
  const [feedback, setFeedback] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`https://matrimonyadmin.hola9.com/api/profileapi/feedback/${id}`)
      .then(response => response.json())
      .then(data => {
        setFeedback(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching individual feedback:', error);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <div className="loading">Loading............</div>;
  }

  if (!feedback) {
    return <div>Feedback not found</div>;
  }

  return (
    <div>
    
      <div className="feedback-details-p" >
        <p>Name: {feedback.name}</p>
        <p>Email: {feedback.email}</p>
        <p>Subject: {feedback.subject}</p>
        <p>Message: {feedback.message}</p>
      </div>
    </div>
  );
};

export default Viewmore;
