import React, { useState } from "react";
import "./Profiledetailstyle.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Profiledetailsdata from "./Profiledetailsdata";
import { states } from "./Profiledetailsdata";
import { educationOptions } from "./Profiledetailsdata";
import { castes } from "./Profiledetailsdata";

const Profiledetails = () => {
  const [formData, setFormData] = useState({
    image: null,
    name: "",
    email: "",
    phone_number: "",
    address: "",
    state: "",
    city: "",
    country: "",
    zipcode: "",
    birth_date: "",
    age: "",
    gender: "",
    religion: "",
    caste: "",
    height: "",
    education: "",
    occupation: "",
    income: "",
    ageOptions: [],
  });

  const [dropdownOpen, setDropdownOpen] = useState({
    state: false,
    caste: false,
    education: false,
  });

  const [searchTerm, setSearchTerm] = useState({
    state: "",
    caste: "",
    education: "",
  });

  const inputhandleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "gender") {
      let ageOptions = [];
      if (value === "Male") {
        for (let i = 21; i <= 45; i++) {
          ageOptions.push(i);
        }
      } else if (value === "Female") {
        for (let i = 18; i <= 45; i++) {
          ageOptions.push(i);
        }
      }
      setFormData((prevState) => ({
        ...prevState,
        age: "",
        ageOptions: ageOptions,
      }));
    }
  };

  const handleImageChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      image: e.target.files[0],
    }));
  };

  const handleSubmitInput = async (e) => {
    e.preventDefault();

    for (const key in formData) {
      if (!formData[key]) {
        toast.error(`${key} is required`);
        return;
      }
    }

    try {
      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }
      formDataToSend.append("image", formData.image);

      const response = await axios.post(
        "https://matrimonyadmin.hola9.com/api/user/profiles/",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("Form submitted successfully!");
      setFormData({
        image: null,
        name: "",
        email: "",
        phone_number: "",
        address: "",
        state: "",
        city: "",
        country: "",
        zipcode: "",
        birth_date: "",
        age: "",
        gender: "",
        religion: "",
        caste: "",
        height: "",
        education: "",
        occupation: "",
        income: "",
        ageOptions: [],
      });
    } catch (error) {
      toast.error("Error submitting form. Please try again later.");
    }
  };

  const toggleDropdown = (dropdown) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [dropdown]: !prevState[dropdown],
    }));
  };

  const handleSearchChange = (e, dropdown) => {
    const { value } = e.target;
    setSearchTerm((prevState) => ({
      ...prevState,
      [dropdown]: value,
    }));
  };

  const handleOptionSelect = (dropdown, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [dropdown]: value,
    }));
    toggleDropdown(dropdown);
  };

  return (
    <div>
      <Profiledetailsdata />
      <div className="profile-wrappers">
        <section
          className="profile-title"
          style={{ backgroundColor: "black", marginTop: " -50px " }}
        >
          <div className="auto-container_profile">
            <h1 className="d-none-profile d-lg-block-profile d-xl-block-profile d-md-block-profile">
              Profile Details
            </h1>
          </div>
        </section>

        <section className="newsletter-section-profile contact-page-section-profile">
          <div className="auto-container-profile">
            <div className="alertmes-profile"></div>
            <div className="row-profile clearfix-profile mt-3-profile">
              <div className="form-column-profile col-lg-9-profile col-md-12-profile col-sm-12-profile">
                <div className="envelope-image-profile" />
                <div className="form-inner-profile">
                  <div className="contact-form-profile">
                    <form
                      onSubmit={handleSubmitInput}
                      enctype="multipart/form-data"
                    >
                      <div className="row-profile clearfix-profile mt-2-profile">
                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> Image: </label>
                          <input
                            type="file"
                            className="agile-ltext-profiles"
                            id="image"
                            onChange={handleImageChange}
                          />
                        </div>

                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> Name: </label>
                          <input
                            type="text"
                            className="agile-ltext-profiles"
                            placeholder="Enter your Name"
                            name="name"
                            value={formData.name}
                            onChange={inputhandleChange}
                          />
                          <span style={{ color: "red" }}>
                            {/* {formError.name} */}
                          </span>
                        </div>

                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> Email: </label>
                          <input
                            type="email"
                            placeholder="Email"
                            className="agile-ltext-profiles"
                            name="email"
                            value={formData.email}
                            onChange={inputhandleChange}
                          />
                          <span style={{ color: "red" }}>
                            {/* {formError.email} */}
                          </span>
                        </div>

                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile">
                            {" "}
                            Phone Number:{" "}
                          </label>
                          <input
                            type="text"
                            name="phone_number"
                            placeholder="Phone Number"
                            className="agile-ltext-profiles"
                            maxLength={14}
                            value={formData.phone_number}
                            onChange={inputhandleChange}
                          />
                          <span style={{ color: "red" }}>
                            {/* {formError.phone_number} */}
                          </span>
                        </div>

                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> Address: </label>
                          <input
                            type="text"
                            className="agile-ltext-profiles"
                            placeholder="Enter your Address"
                            name="address"
                            value={formData.address}
                            onChange={inputhandleChange}
                          />
                          <span style={{ color: "red" }}>
                            {/* {formError.address} */}
                          </span>
                        </div>

                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> State: </label>
                          <div className="select-all">
                            <div className="custom-select-container">
                              <div
                                className="custom-select"
                                onClick={() => toggleDropdown("state")}
                              >
                                {formData.state || "Select State"}
                              </div>
                              {dropdownOpen.state && (
                                <div className="custom-select-dropdown">
                                  <input
                                    type="text"
                                    className="custom-select-search"
                                    placeholder="Search state..."
                                    value={searchTerm.state}
                                    onChange={(e) =>
                                      handleSearchChange(e, "state")
                                    }
                                  />
                                  <div className="custom-select-options">
                                    {states
                                      .filter((state) =>
                                        state
                                          .toLowerCase()
                                          .includes(
                                            searchTerm.state.toLowerCase()
                                          )
                                      )
                                      .map((state, index) => (
                                        <div
                                          key={index}
                                          className="custom-select-option"
                                          onClick={() =>
                                            handleOptionSelect("state", state)
                                          }
                                        >
                                          {state}
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> City :</label>
                          <input
                            type="text"
                            className="agile-ltext-profiles"
                            placeholder="Enter your city"
                            name="city"
                            value={formData.city}
                            onChange={inputhandleChange}
                            readOnly=""
                            fdprocessedid="j85x88"
                          />
                          <span style={{ color: "red" }}>
                            {/* {formError.city} */}
                          </span>
                        </div>
                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> Country : </label>
                          <select
                            type="text"
                            className="agile-ltext-profiless"
                            placeholder="country "
                            name="country"
                            value={formData.country}
                            onChange={inputhandleChange}
                            readOnly=""
                            fdprocessedid="bwswso"
                          >
                            <option value="" selected="">
                              Select country
                            </option>
                            <option value="India">India</option>
                            <option value="Not India">Not India</option>
                          </select>
                          <span style={{ color: "red" }}>
                            {/* {formError.country} */}
                          </span>
                        </div>
                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> Zipcode :</label>
                          <input
                            type="text"
                            className="agile-ltext-profiles"
                            placeholder="Enter your zipcode"
                            name="zipcode"
                            value={formData.zipcode}
                            onChange={inputhandleChange}
                            readOnly=""
                            fdprocessedid="j85x88"
                          />
                          <span style={{ color: "red" }}>
                            {/* {formError.zipcode} */}
                          </span>
                        </div>

                        <div className="col-lg-3-profile  col-md-5-profile  col-sm-6-profile  form-group-profile ">
                          <label className="label-profile ">
                            Date Of Birth :
                          </label>
                          <input
                            type="datetime-local"
                            className="agile-ltext-profiles"
                            placeholder="Date of birth"
                            name="birth_date"
                            value={formData.birth_date}
                            onChange={inputhandleChange}
                            readOnly=""
                            fdprocessedid="tgf31"
                          />
                          <span style={{ color: "red" }}>
                            {/* {formError.birth_date} */}
                          </span>
                        </div>

                        <div className="col-lg-3-profile  col-md-5-profile  col-sm-6-profile  form-group-profile ">
                          <label className="label-profile "> Gender :</label>
                          <select
                            type="text"
                            className="agile-ltext-profilesss"
                            placeholder="Gender "
                            name="gender"
                            value={formData.gender}
                            onChange={inputhandleChange}
                            required
                            readOnly=""
                            fdprocessedid="bwswso"
                          >
                            <option value="" disabled>
                              Select Your Gender
                            </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Others">Others</option>
                          </select>
                          <span style={{ color: "red" }}>
                            {/* {formError.gender} */}
                          </span>
                        </div>

                        <div className="col-lg-3-profile  col-md-5-profile  col-sm-6-profile  form-group-profile ">
                          <label className="label-profile"> Age :</label>

                          <select
                            type="number"
                            className="agile-ltext-profilesss "
                            // placeholder="Enter your age"
                            name="age"
                            value={formData.age}
                            onChange={inputhandleChange}
                            placeholder="Age"
                            required
                          >
                            <option value="" disabled>
                              Select your Age
                            </option>
                            {formData.ageOptions &&
                              formData.ageOptions.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            {[...Array(28)].map((_, index) => {
                              const age = index + 18;
                              return (
                                <option key={age} value={age}>
                                  {age}
                                </option>
                              );
                            })}
                          </select>
                          <span style={{ color: "red" }}>
                            {/* {formError.age} */}
                          </span>
                        </div>

                        <div className="col-lg-3-profile  col-md-5-profile  col-sm-6-profile  form-group-profile ">
                          <label className="label-profile">Religion : </label>
                          <select
                            type="text"
                            className="agile-ltext-profilesss"
                            placeholder="Religion "
                            name="religion"
                            value={formData.religion}
                            onChange={inputhandleChange}
                            readOnly=""
                            fdprocessedid="0eqdt9"
                          >
                            <option value="" selected="">
                              Select Religion
                            </option>
                            <option value="Christian">Any</option>
                            <option value="Christian">Christian</option>
                            <option value="Hindu">Hindu</option>
                            <option value="hindu Barahsaini">
                              hindu Barahsaini
                            </option>
                            <option value="Inter-Religion">
                              Inter-Religion
                            </option>
                            <option value="Jain">Jain</option>
                            <option value="Muslim">Muslim</option>
                            <option value="Sikh">Sikh</option>
                          </select>
                          <span style={{ color: "red" }}>
                            {/* {formError.religion} */}
                          </span>
                        </div>

                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> Caste: </label>
                          <div className="select-allc">
                            <div className="custom-select-container">
                              <div
                                className="custom-select"
                                onClick={() => toggleDropdown("caste")}
                              >
                                {formData.caste || "Select Caste"}
                              </div>
                              {dropdownOpen.caste && (
                                <div className="custom-select-dropdown">
                                  <input
                                    type="text"
                                    className="custom-select-search"
                                    placeholder="Search caste..."
                                    value={searchTerm.caste}
                                    onChange={(e) =>
                                      handleSearchChange(e, "caste")
                                    }
                                  />
                                  <div className="custom-select-options">
                                    {castes
                                      .filter((caste) =>
                                        caste
                                          .toLowerCase()
                                          .includes(
                                            searchTerm.caste.toLowerCase()
                                          )
                                      )
                                      .map((caste, index) => (
                                        <div
                                          key={index}
                                          className="custom-select-option"
                                          onClick={() =>
                                            handleOptionSelect("caste", caste)
                                          }
                                        >
                                          {caste}
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3-profile  col-md-5-profile  col-sm-6-profile  form-group-profile ">
                          <label className="label-profile"> Height : </label>
                          <select
                            type="text"
                            className="agile-ltext-profilesss"
                            placeholder="Select Height"
                            name="height"
                            value={formData.height}
                            onChange={inputhandleChange}
                            readOnly=""
                            fdprocessedid="jdvgst"
                          >
                            <option value="">Select Height</option>
                            {[4, 5, 6, 7].map((feet) => [
                              <option key={feet * 12} value={feet * 12}>
                                {feet}Ft
                              </option>,
                              ...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(
                                (inches) => (
                                  <option
                                    key={feet * 12 + inches}
                                    value={feet * 12 + inches}
                                  >
                                    {feet}Ft {inches} inch
                                  </option>
                                )
                              ),
                            ])}
                          </select>
                          <span style={{ color: "red" }}>
                            {/* {formError.height} */}
                          </span>
                        </div>

                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> Education: </label>
                          <div className="select-all">
                            <div className="custom-select-container">
                              <div
                                className="custom-select"
                                onClick={() => toggleDropdown("education")}
                              >
                                {formData.education || "Select Education"}
                              </div>
                              {dropdownOpen.education && (
                                <div className="custom-select-dropdown">
                                  <input
                                    type="text"
                                    className="custom-select-search"
                                    placeholder="Search education..."
                                    value={searchTerm.education}
                                    onChange={(e) =>
                                      handleSearchChange(e, "education")
                                    }
                                  />
                                  <div className="custom-select-options">
                                    {educationOptions
                                      .filter((education) =>
                                        education
                                          .toLowerCase()
                                          .includes(
                                            searchTerm.education.toLowerCase()
                                          )
                                      )
                                      .map((education, index) => (
                                        <div
                                          key={index}
                                          className="custom-select-option"
                                          onClick={() =>
                                            handleOptionSelect(
                                              "education",
                                              education
                                            )
                                          }
                                        >
                                          {education}
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> Occupation :</label>
                          <input
                            type="text"
                            className="agile-ltext-profiles"
                            placeholder="Enter  occupation"
                            name="occupation"
                            // style={{marginLeft:"10%"}}
                            value={formData.occupation}
                            onChange={inputhandleChange}
                            readOnly=""
                            fdprocessedid="j85x88"
                          />
                          <span style={{ color: "red" }}>
                            {/* {formError.occupation} */}
                          </span>
                        </div>

                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> Income :</label>
                          <input
                            type="number"
                            className="agile-ltext-profiles"
                            placeholder="Enter your income"
                            name="income"
                            value={formData.income}
                            onChange={inputhandleChange}
                            readOnly=""
                            fdprocessedid="j85x88"
                          />
                          <span style={{ color: "red" }}>
                            {/* {formError.income} */}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-10-profile  col-md-10-profile  col-sm-6-profile  form-group-profile ">
                        {/* <Link to="/"> */}
                        <button
                          type="submit"
                          className="theme-btn-profile  btn-profile  btn-style-one-profile mt-4-profile"
                          fdprocessedid="jqtd0p"
                          // onClick={showData}
                          // onClick={handleSubmitinput}
                        >
                          <span className="btn-title-profile" tabIndex={17}>
                            Submit
                          </span>
                        </button>
                        {/* </Link> */}
                      </div>
                    </form>
                    {/* </Form> */}
                    <ToastContainer style={{ marginTop: "100px" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Profiledetails;


//  CONVERTED 1200 LINE CODE TO 670