 export const stateOptions = [ 
    'Delhi',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jammu and Kashmir',
    'Kerala',
    'Lakshadweep',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Karnataka',
    'Nagaland',
    'Orissa',
    'Pondicherry',
    'Punjab',
    'Rajasthan',
    'Tamil Nadu',
    'Tripura',
    'Bihar',
    'West Bengal',
    'Sikkim',
    'Mizoram',
    'Arunachal Pradesh',
    'Daman and Diu',
    'Goa',
    'Madhya Pradesh',
    'Uttar Pradesh',
    'Chhattisgarh',
    'Jharkhand',
    'Uttaranchal',
    'Out of India',
    'Andhra Pradesh'
  ];
  
  
  export const occupationOptions = [  'Science',
    'Architecture & Design',
    'Artists',
    'Animators & Web Designers',
    'Banking',
  
    'Insurance & Financial Services',
    'Beauty',
    'Fashion & Jewellery Designers',
    'Business Owner / Entrepreneur',
    'Civil Services / Law Enforcement',
    'Construction',
    'Customer Service/ Call Centre/BPO',
    'Defence',
    'Education/ Training',
    'Electronics',
    'Export/ Import',
    'Finance and Accounts',
    'Government Employee',
    'Health Care',
    'Hotels/ Restaurants',
    'Human Resource',
    'IT',
    'Legal',
    'Management / Corporate Professionals',
    'Manufacturing/ Engineering/ R&D',
    'Marketing and Communications',
    'Merchant Navy',
    'Non Working',
    'Oil & Gas',
    'Others',
    'Pharmaceutical/ Biotechnology',
    'Purchase/ Logistics/ Supply chain',
    'Real Estate',
    'Retail Chains',
    'Sales/ Business Development',
    'Telecom/ ISP',
    'Travel/ Airlines'
];

 export const casteOptions = [    'Kayastha',
    'Agarwal',
    'Rajput',
    'Khatri',
    'Arora',
    'Scheduled Caste',
    'Yadav',
    'Punjabi',
    'Bengali',
    'Nair',
    'Kshatriya',
    'Gupta',
    'Jat',
    '96K Kokanastha',
    'Ad Dharmi',
    'Adi Andhra',
    'Adi Dravida',
    'Adi Karnataka',
    'Agnikula Kshatriya',
    'Agri',
    'Ahom',
    'Ambalavasi',
    'Amil Sindhi',
    'Anglo Indian',
    'Araya',
    'Arekatica',
    'Arunthathiyar',
    'Arya Vysya',
    'Aryasamaj',
    'Ayyaraka',
    'Badaga',
    'Bahi',
    'Baibhand Sindhi',
    'Baidya',
    'Baishnab',
    'Baishya',
    'Balija',
    'Banik',
    'Baniya',
    'Banjara',
    'Barai',
    'Bari',
    'Barujibi',
    'Besta',
    'Bhandari',
    'Bhanusali Sindhi',
    'Bhatia',
    'Bhatia Sindhi',
    'Bhatraju',
    'Bhavasar Kshatriya',
    'Bhavsar',
    'Bhovi',
    'Bhumihar Brahmin',
    'Billava',
    'Bishnoi/Vishnoi',
    'Boyer',
    'Brahmbatt',
    'Bunt',
    'Bunt (Shetty)',
    'Chambhar',
    'Chandraseniya Kayastha Prab',
    'Chandravanshi Kahar',
    'Chasa',
    'Chattada Sri Vaishnava',
    'Chaudary',
    'Chaurasia',
    'Chettiar',
    'Chhapru Sindhi',
    'Chhetri',
    'Chippolu (Mera)',
    'CKP',
    'Coorgi',
    'Dadu Sindhi',
    'Darji',
    'Dawoodi Bhora',
    'Devadiga',
    'Devandra Kula Vellalar',
    'Devang Koshthi',
    'Devanga',
    'Devar/Thevar/Mukkulathor',
    'Devendra Kula Vellalar',
    'Dhaneshawat Vaish',
    'Dhangar',
    'Dheevara',
    'Dhiman',
    'Dhoba',
    'Dhobi',
    'Dumal',
    'Dusadh (Paswan)',
    'Ediga',
    'Ezhava',
    'Ezhuthachan',
    'Gabit',
    'Ganda',
    'Gandla',
    'Ganiga',
    'Ganigashetty',
    'Garhwali',
    'Garhwali Rajput',
    'Gavali',
    'Gavara',
    'Gawali',
    'Ghisadi',
    'Ghumar',
    'Goala',
    'Goan',
    'Gomantak',
    'Gomantak Maratha',
    'Gondhali',
    'Goswami',
    'Goswami/Gosavi Brahmin',
    'Goud',
    'Gounder',
    'Gowda',
    'Gramani',
    'Gudia',
    'Gujarati',
    'Gujjar',
    'Guptan',
    'Gurav',
    'Gurjar',
    'Hegde',
    'Himachali',
    'Hindu-Others',
    'Hugar (Jeer)',
    'Hyderabadi Sindhi',
    'Intercaste',
    'Irani',
    'Iyengar',
    'Iyer',
    'Jaalari',
    'Jaiswal',
    'Jandra',
    'Jangam',
    'Jatav',
    'Jeer',
    'Jogi (Nath)',
    'Kachara',
    'Kadava Patel',
    'Kahar',
    'Kaibarta',
    'Kalal',
    'Kalar',
    'Kalinga',
    'Kalinga Vysya',
    'Kalita',
    'Kalwar',
    'Kamboj',
    'Kamma',
    'Kannada Mogaveera',
    'Kansari',
    'Kapu',
    'Kapu Naidu',
    'Karana',
    'Karmakar',
    'Karuneegar',
    'Karuneekar',
    'Kasar',
    'Kashyap',
    'Katiya',
    'Keralite',
    'Khandayat',
    'Khandelwal',
    'Kharwar',
    'Khatik',
    'Khukhrain',
    'Koiri',
    'Kokanastha Maratha',
    'Koli',
    'Koli Mahadev',
    'Kongu Vellala Gounder',
    'Konkani',
    'Kori',
    'Koshti',
    'Kudumbi',
    'Kulal',
    'Kulalar',
    'Kulita',
    'Kumaoni',
    'Kumaoni Rajput',
    'Kumawat',
    'Kumbara',
    'Kumbhakar',
    'Kumbhar',
    'Kumhar',
    'Kummari',
    'Kunbi',
    'Kuravan',
    'Kurmi',
    'Kurmi Kshatriya',
    'Kuruba',
    'Kuruhina Shetty',
    'Kurumbar',
    'Kushwaha',
    'Kushwaha (Koiri)',
    'Kutchi',
    'Lambadi',
    'Lambani',
    'Larai Sindhi',
    'Larkana Sindhi',
    'Leva patel',
    'Leva Patidar',
    'Leva patil',
    'Lingayath',
    'Lodhi Rajput',
    'Lohana',
    'Lohana Sindhi',
    'Lohar',
    'Lubana',
    'Madiga',
    'Mahajan',
    'Mahar',
    'Maharashtrian',
    'Mahendra',
    'Maheshwari',
    'Mahishya',
    'Mahisya',
    'Majabi',
    'Mala',
    'Malayalee',
    'Malayalee Namboodiri',
    'Mali',
    'Mallah',
    'Mangalorean',
    'Manipuri',
    'Mannuru Kapu',
    'Mapila',
    'Maruthuvar',
    'Marvar',
    'Marwari',
    'Matang',
    'Mathur',
    'Maurya',
    'Meena',
    'Meenavar',
    'Mehra',
    'Menon',
    'Mera',
    'Meru',
    'Meru Darji',
    'Mochi',
    'Modak',
    'Mogaveera',
    'Monchi',
    'Mudaliar',
    'Mudaliar - Senguntha',
    'Mudaliar Arcot',
    'Mudaliar Saiva',
    'Mudaliyar',
    'Mudiraj',
    'Mukkulathor',
    'Mukulathur',
    'Munnuru Kapu',
    'Muthuraja'  ,'Naagavamsam',
    'Nadar',
    'Nagaralu',
    'Nai',
    'Naicker',
    'Naidu',
    'Naik',
    'Naik/Nayaka',
    'Naika',
    'Nair Vaniya',
    'Nair Vilakkithala',
    'Namasudra',
    'Nambiar',
    'Namboodiri',
    'Namosudra',
    'Napit',
    'Nath',
    'Nayaka',
    'Neeli',
    'Nepali',
    'Nhavi',
    'OBC/Barber/Naayee',
    'Oriya',
    'Oswal',
    'Otari',
    'Padmasali',
    'Padmashali',
    'Pal',
    'Panchal',
    'Pandaram',
    'Panicker',
    'Parkava Kulam',
    'Parsi',
    'Partraj',
    'Pasi',
    'Paswan',
    'Patel',
    'Patel Desai',
    'Patel Dodia',
    'Patel Kadva',
    'Patel Leva',
    'Pathare Prabhu',
    'Patil',
    'Patil Leva',
    'Patnaick',
    'Patnaick/Sistakaranam',
    'Patra',
    'Perika',
    'Pillai',
    'Poosala',
    'Porwal',
    'Prajapati',
    'Raigar',
    'Rajaka',
    'Rajastani',
    'Rajbhar',
    'Rajbonshi',
    'Rajput Rohella/Tank',
    'Ramdasia',
    'Ramgariah',
    'Ravidasia',
    'Rawat',
    'Reddy',
    'Relli',
    'Rohiri Sindhi', 'Sadgope',
      'Saha',
      'Sahiti Sindhi',
      'Sahu',
      'Saini',
      'Sakkhar Sindhi',
      'Saliya',
      'Saurashtra',
      'Savji',
      'Scheduled Tribe',
      'Sehwani Sindhi',
      'Senai Thalaivar',
      'Senguntha Mudaliyar',
      'Sepahia',
      'Setti Balija',
      'Settibalija',
      'Shah',
      'Shetty',
      'Shikarpuri Sindhi',
      'Shimpi',
      'Sindhi',
      'Sindhi-Amil',
      'Sindhi-Baibhand',
      'Sindhi-Bhanusali',
      'Sindhi-Bhatia',
      'Sindhi-Chhapru',
      'Sindhi-Dadu',
      'Sindhi-Hyderabadi',
      'Sindhi-Larai',
      'Sindhi-Larkana',
      'Sindhi-Lohana',
      'Sindhi-Rohiri',
      'Sindhi-Sahiti',
      'Sindhi-Sakkhar',
      'Sindhi-Sehwani',
      'Sindhi-Shikarpuri',
      'Sindhi-Thatai',
      'SKP',
      'Somvanshi',
      'Sonar',
      'Soni',
      'Sourashtra',
      'Sozhiya Vellalar',
      'Srisayana',
      'Srisayani',
      'Sugali (Naika)',
      'Sunari',
      'Sundhi',
      'Sutar',
      'Suthar',
      'Swakula Sali',
      'Swarnakar', 'Tamboli',
      'Tamil',
      'Tamil Yadava',
      'Tanti',
      'Tantubai',
      'Tantuway',
      'Telaga',
      'Teli',
      'Telugu',
      'Thakkar',
      'Thakore',
      'Thakur',
      'Thatai Sindhi',
      'Thigala',
      'Thiyya',
      'Tili',
      'Togata',
      'Tonk Kshatriya',
      'Turupu Kapu',
      'Udayar',
      'Uppara',
      'Urali Gounder',
      'Urs',
      'Vada Balija',
      'Vadagalai',
      'Vaddera',
      'Vaish',
      'Vaishnav',
      'Vaishnav Bhatia',
      'Vaishnav Vania',
      'Vaishnav Vanik',
      'Vaishnava',
      'Vaishya',
      'Vaishya Vani',
      'Valluvan',
      'Valmiki',
      'Vania',
      'Vaniya',
      'Vanjara',
      'Vanjari',
      'Vankar',
      'Vannar',
      'Vannia Kula Kshatriyar',
      'Vanniyar',
      'Variar',
      'Varshney',
      'Veera Saivam',
      'Veerashaiva',
      'Velaan/Vellalar',
      'Velama',
      'Vellalar',
      'Vellalar Devandra Kula',
      'Veluthedathu Nair',
      'Vettuva Gounder',
      'Vilakkithala Nair',
      'Vishwakarma',
      'Viswabrahmin',
      'Vokkaliga',
      'Vysya',
      'Yellapu',
      'Sonam',
      'Maratha'
  ];
   