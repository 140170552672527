export const educationOptions = [
    { value: '', label: 'Select Education' },
    { value: '12th', label: '12th' },
    { value: 'B.A. (Arts)', label: 'B.A. (Arts)' },
    { value: 'B.Com (Commerce)', label: 'B.Com (Commerce)' },
    { value: 'B.Sc (Science)', label: 'B.Sc (Science)' },
    { value: 'B.Arch (Architecture)', label: 'B.Arch (Architecture)' },
    { value: 'B.Ed (Education)', label: 'B.Ed (Education)' },
    { value: 'B.El.Ed (Elementary Education)', label: 'B.El.Ed (Elementary Education)' },
    { value: 'B.Lib.Sc (Library Sciences)', label: 'B.Lib.Sc (Library Sciences)' },
    { value: 'B.P.Ed. (Physical Education)', label: 'B.P.Ed. (Physical Education)' },
    { value: 'B.Plan (Planning)', label: 'B.Plan (Planning)' },
    { value: 'Bachelor of Fashion Technology', label: 'Bachelor of Fashion Technology' },
    { value: 'BBA/BBM/BBS', label: 'BBA/BBM/BBS' },
    { value: 'BCA (Computer Application)', label: 'BCA (Computer Application)' },
    { value: 'BE B.Tech (Engineering)', label: 'BE B.Tech (Engineering)' },
    { value: 'BFA (Fine Arts)', label: 'BFA (Fine Arts)' },
    { value: 'BHM (Hotel Management)', label: 'BHM (Hotel Management)' },
    { value: 'BL/LLB/BGL (Law)', label: 'BL/LLB/BGL (Law)' },
    { value: 'BSW (Social Work)', label: 'BSW (Social Work)' },
    { value: 'B.Pharm (Pharmacy)', label: 'B.Pharm (Pharmacy)' },
    { value: 'B.V.Sc. (Veterinary Science)', label: 'B.V.Sc. (Veterinary Science)' },
    { value: 'BDS (Dental Surgery)', label: 'BDS (Dental Surgery)' },
    { value: 'BHMS (Homeopathy)', label: 'BHMS (Homeopathy)' },
    { value: 'CA (Chartered Accountant)', label: 'CA (Chartered Accountant)' },
    { value: 'CFA (Chartered Financial Analyst)', label: 'CFA (Chartered Financial Analyst)' },
    { value: 'CS (Company Secretary)', label: 'CS (Company Secretary)' },
    { value: 'ICWA', label: 'ICWA' },
    { value: 'Integrated PG', label: 'Integrated PG' },
    { value: 'Engineering', label: 'Engineering' },
    { value: 'Fashion/ Design', label: 'Fashion/ Design' },
    { value: 'Languages', label: 'Languages' },
    { value: 'Pilot Licenses', label: 'Pilot Licenses' },
    { value: 'M.Arch. (Architecture)', label: 'M.Arch. (Architecture)' },
    { value: 'M.Ed. (Education)', label: 'M.Ed. (Education)' },
    { value: 'M.Lib.Sc. (Library Sciences)', label: 'M.Lib.Sc. (Library Sciences)' },
    { value: 'M.Plan. (Planning)', label: 'M.Plan. (Planning)' },
    { value: 'Master of Fashion Technology', label: 'Master of Fashion Technology' },
    { value: 'Master of Health Administration', label: 'Master of Health Administration' },
    { value: 'Master of Hospital Administration', label: 'Master of Hospital Administration' },
    { value: 'MBA/PGDM', label: 'MBA/PGDM' },
    { value: 'MCA PGDCA part time', label: 'MCA PGDCA part time' },
    { value: 'MCA/PGDCA', label: 'MCA/PGDCA' },
    { value: 'ME/M.Tech/MS (Engg/Sciences)', label: 'ME/M.Tech/MS (Engg/Sciences)' },
    { value: 'MFA (Fine Arts)', label: 'MFA (Fine Arts)' },
    { value: 'ML/LLM (Law)', label: 'ML/LLM (Law)' },
    { value: 'MSW (Social Work)', label: 'MSW (Social Work)' },
    { value: 'PG Diploma', label: 'PG Diploma' },
    { value: 'M.Com. (Commerce)', label: 'M.Com. (Commerce)' },
    { value: 'M.Sc. (Science)', label: 'M.Sc. (Science)' },
    { value: 'MA (Arts)', label: 'MA (Arts)' },
    { value: 'M.Pharm. (Pharmacy)', label: 'M.Pharm. (Pharmacy)' },
    { value: 'M.V.Sc. (Veterinary Science)', label: 'M.V.Sc. (Veterinary Science)' },
    { value: 'MBBS', label: 'MBBS' },
    { value: 'MD/ MS (Medicine)', label: 'MD/ MS (Medicine)' },
    { value: 'MDS (Master of Dental Surgery)', label: 'MDS (Master of Dental Surgery)' },
    { value: 'BPT (Physiotherapy)', label: 'BPT (Physiotherapy)' },
    { value: 'MPT (Physiotherapy)', label: 'MPT (Physiotherapy)' },
    { value: 'M.Phil. (Philosophy)', label: 'M.Phil. (Philosophy)' },
    { value: 'Ph.D. (Doctorate)', label: 'Ph.D. (Doctorate)' },
    { value: 'Other Doctorate', label: 'Other Doctorate' },
    { value: 'Other Diploma', label: 'Other Diploma' },
    { value: 'Agriculture', label: 'Agriculture' },
    { value: '10th', label: '10th' },
  ];

  export const occupationOptions = [
    { value: '', label: 'Select Occupation' },
    { value: 'Science', label: 'Science' },
    { value: 'Architecture & Design', label: 'Architecture & Design' },
    { value: 'Artists', label: 'Artists' },
    { value: 'Animators & Web Designers', label: 'Animators & Web Designers' },
    { value: 'Banking', label: 'Banking' },
    { value: 'Insurance & Financial Services', label: 'Insurance & Financial Services' },
    { value: 'Beauty', label: 'Beauty' },
    { value: 'Fashion & Jewellery Designers', label: 'Fashion & Jewellery Designers' },
    { value: 'Business Owner / Entrepreneur', label: 'Business Owner / Entrepreneur' },
    { value: 'Civil Services / Law Enforcement', label: 'Civil Services / Law Enforcement' },
    { value: 'Construction', label: 'Construction' },
    { value: 'Customer Service/ Call Centre/BPO', label: 'Customer Service/ Call Centre/BPO' },
    { value: 'Defence', label: 'Defence' },
    { value: 'Education/ Training', label: 'Education/ Training' },
    { value: 'Electronics', label: 'Electronics' },
    { value: 'Export/ Import', label: 'Export/ Import' },
    { value: 'Finance and Accounts', label: 'Finance and Accounts' },
    { value: 'Government Employee', label: 'Government Employee' },
    { value: 'Health Care', label: 'Health Care' },
    { value: 'Hotels/ Restaurants', label: 'Hotels/ Restaurants' },
    { value: 'Human Resource', label: 'Human Resource' },
    { value: 'IT', label: 'IT' },
    { value: 'Legal', label: 'Legal' },
    { value: 'Management / Corporate Professionals', label: 'Management / Corporate Professionals' },
    { value: 'Manufacturing/ Engineering/ R&D', label: 'Manufacturing/ Engineering/ R&D' },
    { value: 'Marketing and Communications', label: 'Marketing and Communications' },
    { value: 'Merchant Navy', label: 'Merchant Navy' },
    { value: 'Non Working', label: 'Non Working' },
    { value: 'Oil & Gas', label: 'Oil & Gas' },
    { value: 'Others', label: 'Others' },
    { value: 'Pharmaceutical/ Biotechnology', label: 'Pharmaceutical/ Biotechnology' },
    { value: 'Purchase/ Logistics/ Supply chain', label: 'Purchase/ Logistics/ Supply chain' },
    { value: 'Real Estate', label: 'Real Estate' },
    { value: 'Retail Chains', label: 'Retail Chains' },
    { value: 'Sales/ Business Development', label: 'Sales/ Business Development' },
    { value: 'Telecom/ ISP', label: 'Telecom/ ISP' },
    { value: 'Travel/ Airlines', label: 'Travel/ Airlines' },
  ];