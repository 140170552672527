import React, { useState } from 'react';
import axios from 'axios'; // Import Axios
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import './Interestslink.css'

const Interestslink = () => {

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const [interest, setInterest] = useState({
    sports: false,
    sports_details: '',
    movie: false,
    movie_details: '',
    books: false,
    books_details: '',
    travel: false,
    travel_details: '',
    volunteer_work: false,
    volunteer_work_details: ''
  });

  const handleChangeint = (field, value) => {
    setInterest(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const handleSubmitint = () => {
  
    axios.post('https://matrimonyadmin.hola9.com/api/profileapi/interests/', interest)
      .then(response => {
        console.log(response.data);
        toast.success('Form submitted successfully');
      })
      .catch(error => {
        console.error('Error:', error);
        toast.error('Failed to submit form');
      });
  };


  return (
    <>

<button onClick={goBack} className='back-button-pay'><i class="ri-arrow-left-line"></i></button>
<div className="interestlink">
 
      <h2 className='interest-title'>INTEREST LINK</h2>

  
      <div className="interestlist">
        <label className='label-interest'><h10>Sports :</h10></label>
        <div className="radio-group">
          <label>
            <input
            className='radio-btn'
              type="radio"
              name="sports"
              checked={interest.sports}
              onChange={()=>handleChangeint('sports',true)}
            />
            Yes
          </label>
          <label >
            <input
             className='radio-btn'
              type="radio"
              name="sports"
              checked={!interest.sports}
              onChange={() =>handleChangeint('sports',false)}
            />
            No
            <div className='details-form'>
        {interest.sports && <input type="text" className='input-details-text' placeholder="Enter your sports details" name="sports_details" 
        value={interest.sports_details} onChange={(e) => handleChangeint('sports_details', e.target.value)} />}
        </div>
          </label>
          </div>
          
      </div>

       
      <div className="interestlist">
        <label className='label-interest'><h10>Movie :</h10></label>
       
        <div className="radio-group">
          <label >
            <input
             className='radio-btn'
              type="radio"
              name="movie"
              checked={interest.movie}
              onChange={() => handleChangeint('movie', true)}
            />
            Yes
          </label>
          <label>
            <input
             className='radio-btn'
              type="radio"
              name="movie"
              checked={!interest.movie}
              onChange={() => handleChangeint('movie', false)}
            />
            No
            <div className='details-form'>
            {interest.movie && <input className='input-details-text' type="text" placeholder="Enter your movie details"  
            name="movie_details" value={interest.movie_details} onChange={(e) => handleChangeint('movie_details', e.target.value)}/>}
            </div>
            </label>
          </div>
      </div>
   
 
      <div className="interestlist">
        <label className='label-interest'><h10>Books :</h10></label>
        <div className="radio-group">
          <label>
            <input
             className='radio-btn'
              type="radio"
              name="books"
              checked={interest.books}
              onChange={() => handleChangeint('books',true)}
            />
            Yes
          </label>
          <label>
            <input
             className='radio-btn'
              type="radio"
              name="books"
              checked={!interest.books}
              onChange={() => handleChangeint('books',false)}
            />
            No
            <div className='details-form'>
            {interest.books && <input type="text" className='input-details-text' placeholder="Enter your books details" name="books_details" 
            value={interest.books_details} onChange={(e) => handleChangeint('book_details', e.target.value)}/>}
            </div>
          </label>
        </div>
      </div>
 
      <div className="interestlist">
        <label className='label-interest'><h10>Travel :</h10></label>
        <div className="radio-group">
          <label>
            <input
             className='radio-btn'
              type="radio"
              name="travel"
              checked={interest.travel}
              onChange={() => handleChangeint('travel',true)}
            />
            Yes
          </label>
          <label>
            <input
             className='radio-btn'
              type="radio"
              name="travel"
              checked={!interest.travel}
              onChange={() => handleChangeint('travel',false)}
            />
            No
            <div className='details-form'>
        {interest.travel && <input type="text" className='input-details-text' placeholder="Enter your travel details" name="travel_details" 
        value={interest.interest_details} onChange={(e) => handleChangeint('travel_details', e.target.value)}/>}
        </div>
          </label>
        </div>
      </div>
 
      <div className="interestlist">
        <label className='label-interest'><h10>Volunteerwork :</h10></label>
        <div className="radio-group">
          <label>
            <input
             className='radio-btn'
              type="radio"
              name="volunteer_work"
              checked={interest.volunteer_work}
              onChange={() => handleChangeint('volunteer_work',true)}
            />
            Yes
          </label>
          <label>
            <input
             className='radio-btn'
              type="radio"
              name="volunteer_work"
              checked={!interest.volunteer_work}
              onChange={() => handleChangeint('volunteer_work',false)}
            />
            No
            <div className='details-form'>
        {interest.volunteer_work && <input type="text" className='input-details-text' placeholder="Enter your volunteerwork details" name="volunteer_work_details" 
        value={interest.volunteer_work_details} onChange={(e) => handleChangeint('volunteer_work_details', e.target.value)}/>}
        </div>
          </label>
        </div>
      </div>

      <button className='interest-btn' onClick={handleSubmitint}>Submit</button>
    </div>
<ToastContainer />
    </>
  )
}

export default Interestslink;