import React from 'react'
import "./ViewedContactList.css"

const ViewedContactList = () => {
  return (
    <div>

                 <section className="page-title" style={{ backgroundColor: "black",color:"white",fontSize:"20px", fontWeight:"700",marginTop:"-40px"}}>
        <div className="auto-container">
          {/* <h1 className="d-none d-lg-block d-xl-block d-md-block">ViewedContactList </h1> */}
          <ul className="bread-crumb clearfix">
           <li style={{textAlign:'center'}}> ViewedContactList</li>
          </ul>
        </div>


      </section>
      <div>
      <br/>
      <center>

<div className="viwed-contact-container">
      <h1 className="viwed-contact-heading">Who Viewed Contact List</h1>
      <div className="viwed-contact-input-container-view">
        <input type="text"  className="viwed-contact-input" placeholder="Name"  />
        <input type="text" className="viwed-contact-input" placeholder="Profile ID" />
        <input type="text" className="viwed-contact-input" placeholder="Viewed Date" />
      </div>
    </div>

    </center>
  </div>
    </div>
  )
}

export default ViewedContactList
