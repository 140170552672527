export const qualifications = [
  "12th",
  "B.A. (Arts)",
  "B.Com (Commerce)",
  "B.Sc (Science)",
  "B.Arch (Architecture)",
  "B.Ed (Education)",
  "B.El.Ed (Elementary Education)",
  "B.Lib.Sc (Library Sciences)",
  "B.P.Ed. (Physical Education)",
  "B.Plan (Planning)",
  "Bachelor of Fashion Technology",
  "BBA/BBM/BBS",
  "BCA (Computer Application)",
  "BE B.Tech (Engineering)",
  "BFA (Fine Arts)",
  "BHM (Hotel Management)",
  "BL/LLB/BGL (Law)",
  "BSW (Social Work)",
  "B.Pharm (Pharmacy)",
  "B.V.Sc. (Veterinary Science)",
  "BDS (Dental Surgery)",
  "BHMS (Homeopathy)",
  "CA (Chartered Accountant)",
  "CFA (Chartered Financial Analyst)",
  "CS (Company Secretary)",
  "ICWA",
  "Integrated PG",
  "Engineering",
  "Fashion/ Design",
  "Languages",
  "Pilot Licenses",
  "M.Arch. (Architecture)",
  "M.Ed. (Education)",
  "M.Lib.Sc. (Library Sciences)",
  "M.Plan. (Planning)",
  "Master of Fashion Technology",
  "Master of Health Administration",
  "Master of Hospital Administration",
  "MBA/PGDM",
  "MCA PGDCA part time",
  "MCA/PGDCA",
  "ME/M.Tech/MS (Engg/Sciences)",
  "MFA (Fine Arts)",
  "ML/LLM (Law)",
  "MSW (Social Work)",
  "PG Diploma",
  "M.Com. (Commerce)",
  "M.Sc. (Science)",
  "MA (Arts)",
  "M.Pharm. (Pharmacy)",
  "M.V.Sc. (Veterinary Science)",
  "MBBS",
  "MD/ MS (Medicine)",
  "MDS (Master of Dental Surgery)",
  "BPT (Physiotherapy)",
  "MPT (Physiotherapy)",
  "M.Phil. (Philosophy)",
  "Ph.D. (Doctorate)",
  "Other Doctorate",
  "Other Diploma",
  "Agriculture",
  "10th",
];
export const occupations = [
  "Architecture & Design",
  "Artists",
  "Animators & Web Designers",
  "Banking",
  "Insurance & Financial Services",
  "Beauty",
  "Fashion & Jewellery Designers",
  "Business Owner / Entrepreneur",
  "Civil Services / Law Enforcement",
  "Construction",
  "Customer Service/ Call Centre/BPO",
  "Defence",
  "Education/ Training",
  "Electronics",
  "Export/ Import",
  "Finance and Accounts",
  "Government Employee",
  "Health Care",
  "Hotels/ Restaurants",
  "Human Resource",
  "IT",
  "Legal",
  "Management / Corporate Professionals",
  "Manufacturing/ Engineering/ R&D",
  "Marketing and Communications",
  "Merchant Navy",
  "Non Working",
  "Oil & Gas",
  "Others",
  "Pharmaceutical/ Biotechnology",
  "Purchase/ Logistics/ Supply chain",
  "Real Estate",
  "Retail Chains",
  "Sales/ Business Development",
  "Science",
  "Telecom/ ISP",
  "Travel/ Airlines",
];
export const annualIncomeOptions = [
  { value: "", label: "Select Annual Income" },
  { value: "1l", label: "0-1 Lakh" },
  { value: "2l", label: "1-2 Lakhs" },
  { value: "3l", label: "2-3 Lakhs" },
  { value: "4l", label: "3-4 Lakhs" },
  { value: "5l", label: "4-5 Lakhs" },
  { value: "6l", label: "5-6 Lakhs" },
  { value: "7l", label: "6-7 Lakhs" },
  { value: "8l", label: "7-8 Lakhs" },
  { value: "9l", label: "8-9 Lakhs" },
  { value: "10l", label: "9-10 Lakhs" },
];
export const employmentOptions = [
  { value: "", label: "Employed In", disabled: true },
  { value: "Government", label: "Government" },
  { value: "Private", label: "Private" },
  { value: "Business", label: "Business" },
  { value: "Defence", label: "Defence" },
  { value: "Self Employed", label: "Self Employed" },
  { value: "Not working", label: "Not working" },
];
export const challengeOptions = [
  { value: "None", label: "None" },
  { value: "Physically Challenged", label: "Physically Challenged" },
  { value: "Mentally Challenged", label: "Mentally Challenged" },
  { value: "Not willing to disclose", label: "Not willing to disclose" },
];
export const initialData = {
  qualification: "",
  occupation: "",
  occupation_details: "",
  annual_income: "",
  employed_in: "",
  special_cases: "",
  working_location: "",
};

