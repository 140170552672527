import React from 'react'
import "./WhoViewedMyProfile.css"

const WhoViewedMyProfile = () => {
  return (
    <div>

      <section className="page-title who-viewed-my-profile-page-title">
        <div className="auto-container">
          <ul className="bread-crumb clearfix">
            <li style={{ textAlign: 'center' }}>WhoViewedMyProfile</li>
          </ul>
        </div>

      </section>
      <div>
        <br />
        <center className='who-viewed-my-profile-center-tag-container'>

          <div className="who-viewed-my-profile-container">
            <h1 className="who-viewed-my-profile-heading">Who Viewed My Profile</h1>
            <div className="who-viewed-my-profile-input-container">
              <input type="text" className="who-viewed-my-profile-input" placeholder="Name" style={{ backgroundcolor: 'white' }} />
              <input type="text" className="who-viewed-my-profile-input" placeholder="Profile ID" />
              <input type="text" className="who-viewed-my-profile-input" placeholder="Viewed Date" />
            </div>
          </div>

        </center>
      </div>
    </div>
  )
}

export default WhoViewedMyProfile
