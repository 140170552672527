import React, { useState } from "react";
import "./DescriptionStyle.css";
import Slider from "react-slick";
// import { url } from 'inspector';

const Description = () => {
  const [expanded, setExpanded] = useState(false);
  const [expandedTop, setExpandedTop] = useState(false);

  console.log(expanded);

  const settings = {
    // centerMode : true,
  };

  const data = [
    {
      img: "https://www.womansera.com/wp-content/uploads/2021/08/09-PRD_0113-Edit.jpg",
      h: "Most Trusted Matrimony Site",
      p1: "     Welcome to iMarriages, the first matrimonial site to offer a scientific approach to finding a compatible Life Partner (made4eachother). Exclusive to iMarriages, our personality matching system will help ensure that your marriage is lasting and fulfilling. Established in 2006 and with thousands of registered members.",
      p2: "Welcome to iMarriages, the first matrimonial site to offer a scientific approach to finding a compatible Life Partner (made4eachother). Exclusive to iMarriages, our personality matching system will help ensure that your marriage is lasting and fulfilling....",
    },
    {
      img: "https://imarriages.com/d/images/no-payment-matrimony.jpg",
      h: "Free Profile Creation",
      p1: `      That's right; there are no fees, charges, or payments required to create your profile on iMarriages. Other matrimonial companies charge you an entire month's salary before they allow you to contact their members.!    `,
      p2: ` That's right; there are no fees, charges, or payments required to
    create your profile on iMarriages. Other matrimonial companies charge
    you an entire month's salary before they allow you to contact their
    members; iMarriages allows you to create your shaadi profile and
    receive messages from other members for free....`,
    },
    {
      img: "https://imarriages.com/d/images/matrimony-privacy.jpg",
      h: "Maximum Privacy Details",
      p1: `We were the first marriage bureau in the world to implement full
    site-wide encryption. Although this came at a considerable cost to us,
    we are dedicated to ensuring your information is as safe as possible.
    Without encryption, criminals may eavesdrop on your conversations and
    potentially steal your identity. For more info.`,
      p2: `We were the first marriage bureau in the world to implement full
    site-wide encryption. Although this came at a considerable cost to us,
    we are  is as safe as possible. criminals may eavesdrop on your conversations and
    potentially steal your identity...`,
    },
    {
      img: "https://imarriages.com/d/images/matrimony-phone.jpg",
      h: "Verified Phone Numbers",
      p1: ` We were the first marriage bureau in the world to implement full
    site-wide encryption. Although this came at a considerable cost to us,
    we are dedicated to ensuring your information is as safe as possible.
    Without encryption, criminals may eavesdrop on your conversations and
    potentially steal your identity.`,
      p2: `A verified phone number is a requirement to use our Indian matchmaking
      services. This guarantees the quality of our profiles and ensures the
      safety of our members. ....`,
    },
    {
      img: "https://cdn0.weddingwire.in/article-vendor-o/5446/3_2/960/jpg/kerala-palakkad-wedding-photography-glareart-photography-15_15_135446_v1.jpeg",
      h: "Exclusive Matchmaking",
      p1: `Research has established that couples who are also matched on
    personality have more fulfilling relationships and are more
    successful. An individual's personality cannot be judged by their age,
    religion, or appearance. Even after a brief conversation.`,
      p2: `Research has established that couples who are also matched on
    personality have more fulfilling relationships and are more
    successful. An individual's personality cannot be judged by their age,
    religion, or appearance.  ....`,
    },
    {
      img: "https://imarriages.com/d/images/matrimony-advice.jpg",
      h: "Indian Relationship Advice",
      p1: `We are more than just an Indian matrimonial bureau; we are an Indian
    relationship bureau. The team at iMarriages includes medical
    psychologists, and for over a decade, we have provided relationship
    counseling to those in need, free of charge. In addition to one on one
    counseling.`,
      p2: ` We are more than just an Indian matrimonial bureau; we are an Indian
    relationship bureau. The team at iMarriages includes medical
    psychologists, and for over a decade, we have provided relationship
    counseling to those in need, free of charge......`,
    },
    {
      img: "https://thumbs.dreamstime.com/b/young-successful-couple-smiling-woman-embrace-man-indoors-boke-women-men-bokeh-95220178.jpg",
      h: "Success Stories Of Couples",
      p1: ` My name is Padma and my husband's name is Tavish Swaminathan. I am now
  Padma Swaminathan thanks to the team at iMarriages. I found Tavish on
  your matrimony app a month after I had created my profile. We began
  talking and organised a meeting with our families. We married here in
  Chennai three months later. God bless you and thank you very much
  iMarriages.`,
      p2: `My name is Padma and my husband's name is Tavish Swaminathan. I am now
  Padma Swaminathan thanks to the team at iMarriages. I found Tavish on
  your matrimony app a month after I had created my profile. We began
  talking and organised a meeting with our families.....`,
    },
  ];
  return (
    <>
      <div className="slider-container">
        {/* <> */}
        <div className="slide-con">
          <Slider
            {...settings}
            style={{
              backgroundImage:
                'url("https://images.pexels.com/photos/3255761/pexels-photo-3255761.jpeg?auto=compress&cs=tinysrgb&w=600")',
              width: "90%",
              height: "320px",
            }}
          >
            {data.map((dat) => (
              <div style={{ width: "90%" }}>
                <div className="inner-wrapper-l-l">
                  <img
                    src={dat.img}
                    alt="Free Matrimony Site"
                    style={{
                      borderRadius: "50%",
                      marginTop: "10px",
                      width: "30%",
                      height: "150px",
                      margin: "auto",
                    }}
                  />
                </div>

                <div>
                  <h5 className="inner-wrapper-title">{dat.h}</h5>

                  <div
                    style={{
                      textAlign: "center",
                      width: "90%",
                      margin: "auto",
                    }}
                  >
                    {expanded ? (
                      <p style={{ width: "90%", fontWeight: "500" }}>
                        {dat.p1}{" "}
                      </p>
                    ) : (
                      <p
                        style={{
                          width: "90%",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                      >
                        {" "}
                        {dat.p2}
                      </p>
                    )}
                  </div>
                </div>
                <div style={{ textAlign: "center" }}>
                  <a
                    style={{
                      color: "red",
                      textDecoration: expanded ? "underline" : "none", // Underline when expanded, none when not expanded
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setExpanded(!expanded);
                    }}
                  >
                    {expanded ? "Show Less" : "Show More"}
                  </a>
                </div>

                <div style={{ textAlign: "center" }}>
                  {expandedTop && <p>{dat.p1}</p>}
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <br />
      </div>
    </>
  );
};

export default Description;
