import React from 'react'
import UserProfiles from "./UserProfilee/UserProfiles";

const Edit = () => {
  return (
    <div>
        <UserProfiles />
    </div>
  )
}

export default Edit;