import React from 'react'
import { useParams } from 'react-router-dom'
import './Singleview.css'
import { BasicDetails, EduProf, FamilyDetails, HoroscopeDetails, PartnerPref, intrestlink, personhobbies, profilehab } from './SingleViewDummyData';
const Singleview = () => {
    const {id} = useParams();
    
    const data = [
        {
            "id": "01",
            "name": "Rashmika",
            "Img": "https://datatrained.com/dt-fun/wp-content/uploads/2023/02/Rashmika-Madanna-Age.jpg",
            "Age": "29",
            "Registerid": "07823",
           "City":"Mumbai",
           "Height":"5ft",
        }, 
        {
            "id": "02",
            "name": "SreeLela",
            "Img": "https://szcdn.ragalahari.com/oct2023/hd/sreeleela-at-bk-press-meet/sreeleela-at-bk-press-meetthumb.jpg",
            "Age": "27",
            "Registerid": "42578",
            "City":"Hyderabad",
            "Height":"5.6ft",
        },
         {
            "id": "03",
            "name": "keerthi Suresh",
            "Img": "https://assets.shortpedia.com/uploads/2021/04/15/1618470640.jpg",
            "Age": "39",
            "Registerid": "001823",
            "City":"kerala",
            "Height":"5.2ft",
        }, 
             {
            "id": "04",
            "name": "Pooja Hegde",
            "Img": "https://c4.wallpaperflare.com/wallpaper/260/892/975/pooja-hegde-women-actress-model-indian-hd-wallpaper-preview.jpg",
            "Age": "29",
            "Registerid": "72593",
            "City":"kolkata",
            "Height":"5.9ft",
        },
         {
            "id": "05",
            "name": "Kajal",
            "Img": "https://akm-img-a-in.tosshub.com/lingo/cosmo/images/2023-05/untitled_design_2023_05_15t133445.191_1.png",
            "Age": "27",
            "Registerid": "74581",
            "City":"Jaipur",
            "Height":"5.0ft",
        },
         {
            "id": "06",
            "name": "Anushka",
            "Img": "https://images.indianexpress.com/2014/09/anushka-shetty.jpg?w=414",
            "Age": "39",
            "Registerid": "34812",
            "City":"Mumbai",
            "Height":"5.9ft",
        },      
        {
            "id": "07",
            "name": "Kriti shetty",
            "Img": "https://m.media-amazon.com/images/M/MV5BZWRkMWZiOGUtYzI2OC00YmQ5LWI3MDMtNGE3ZWMzMDkzYjAwXkEyXkFqcGdeQXVyMTUzNjEwNjM2._V1_.jpg",
            "Age": "29",
            "Registerid": "86868",
            "City":"kerala",
            "Height":"5.3ft",
        }, 
        {
            "id": "08",
            "name": "disha patani",
            "Img": "https://images.indianexpress.com/2020/07/DishaPatani759.jpg?w=414",
            "Age": "27",
            "Registerid": "94940",
            "City":"Tirupati",
            "Height":"5.5ft",
        },
         {
            "id": "09",
            "name": "Samantha",
            "Img": "https://img.etimg.com/thumb/width-1200,height-900,imgsize-30466,resizemode-75,msid-95819079/news/new-updates/samantha-looks-to-ayurveda-to-cure-autoimmune-condition-myositis.jpg",
            "Age": "39",
            "Registerid": "24395",
            "City":"Agra",
            "Height":"5.6ft",
        },
        {
            "id": "10",
            "name": "Rakul preet",
            "Img": "https://img-cdn.thepublive.com/fit-in/640x430/filters:format(webp)/shethepeople/media/media_files/BNgxcPQDSSjVt2QBLCzv.jpeg",
            "Age": "39",
            "Registerid": "01058",
            "City":"gujarat",
            "Height":"5.10ft",
        },      
        {
            "id": "11",
            "name": "Lavanya Tripati",
            "Img": "https://e.telugurajyam.com/wp-content/uploads/2023/03/lavanya-tripathi-opens-up-about-her-marriage.jpg",
            "Age": "29",
            "Registerid": "94283",
            "City":"banglore",
            "Height":"5.4ft",
        }, 
        {
            "id": "12",
            "name": "Sai Pallavi",
            "Img": "https://images.news18.com/ibnlive/uploads/2023/08/untitled-design-2023-08-07t134903.254.jpg",
            "Age": "27",
            "Registerid": "76952",
            "City":"chennai",
            "Height":"5.5ft",
        },
         {
            "id": "13",
            "name": "Eesha Rebba",
            "Img": "https://d1vzdswwroofzl.cloudfront.net/wp-content/uploads/2023/09/Copy-of-Web-pic-1-2.png",
            "Age": "39",
            "Registerid": "94915",
            "City":"Pune",
            "Height":"5.8ft",
        },    
          {
            "id": "14",
            "name": "Ritu Varma",
            "Img": "https://eng.cinereporters.com/wp-content/uploads/2022/11/ritu.jpg",
            "Age": "29",
            "Registerid": "73826",
            "City":"Hyderabad",
            "Height":"5.3ft",
        }
    ];
    const detail = data.find(item => item.id === id);
 
  if (!detail) {
   return <div>Loading...</div>;
 }
  return (
    <>

{/* <div style={{width:'70%', margin:' 40px auto 0 ', height:'400px', backgroundImage:`linear-gradient(to right, #ffbb01, #ff0058, transparent 90% ), url(${detail.Img} )`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'right', display:'flex', alignItems:'center'}}>
    <h1 style={{marginLeft:'15%',color:'white', fontSize:'400%'}}>{detail.name}</h1>
</div> */}
  <div className="customDiv" style={{backgroundImage: `linear-gradient(to right, #ff0058, transparent 90%), url(${detail.Img})`, backgroundBlendMode:'revert-layer'}}>           <h1>{detail.name}</h1>
        </div>
<br/>
<div className='singleview-main-container'>
{/*--------------------------------------------------- Basic Details ---------------------------------------------------- */}
{<div className='singleview-card-one'>
    <h2 className='singleview-card-head' >Basic Details</h2>
    <br/>

    {BasicDetails.map((data) => (
                <div className='singleview-basic-details' >
                    <i className='singleview-content' >{data.i}</i>
                    <b className='singleview-values' >{data.b}</b>
                </div>
                ))}
    </div> }

{/* ------------------------------------------------- Basic Details ----------------------------------------------------- */}

{/* ----------------------------------------- Education & Professional Details ------------------------------------------ */}

    <div className='singleview-card-one' >
<h2  className='singleview-card-head'>Family Details</h2> 
             <br/>
             {FamilyDetails.map((data) => (
                <div className='singleview-basic-details' >
                    <i className='singleview-content' >{data.i}</i>
                    <b className='singleview-values' >{data.b}</b>
                </div>
                ))}
</div>
{/* ----------------------------------------- Education & Professional Details ------------------------------------------ */}

{/* ----------------------------------------- Horoscope Details --------------------------------------------------------- */}
    <div className='singleview-card-one' >
                <h2  className='singleview-card-head'>Horoscope Details</h2> 
                <br/>
                {HoroscopeDetails.map((data) => (
                <div className='singleview-basic-details' >
                    <i className='singleview-content' >{data.i}</i>
                    <b className='singleview-values' >{data.b}</b>
                </div>
                ))}
                
    </div>
{/* ----------------------------------------- Horoscope Details --------------------------------------------------------- */}

{/* ----------------------------------------- partner preference --------------------------------------------------------- */}
    <div className='singleview-card-one'>
<h2 className='singleview-card-head'>Partner Preference</h2>
                <br/> 
                {PartnerPref.map((data) => (
                <div className='singleview-basic-details' >
                    <i className='singleview-content' >{data.i}</i>
                    <b className='singleview-values' >{data.b}</b>
                </div>
                ))}
</div>
{/* -------------------------------------------partner preference ------------------------------------------------------- */}

{/* -------------------------------------------education details--------------------------------------------------------- */}
<div className='singleview-card-one' >
    <h2  className='singleview-card-head' >Education & Professional Details</h2>
    <br/>
    {EduProf.map((data) => (
                <div className='singleview-basic-details' >
                    <i className='singleview-content' >{data.i}</i>
                    <b className='singleview-values' >{data.b}</b>
                </div>
                ))}
    </div>
{/* ---------------------------------------------education details--------------------------------------------------------- */}

{/* ------------------------------------------------interest links--------------------------------------------------------- */}

<div className='singleview-card-one' >
<h2  className='singleview-card-head'>Interest links</h2> 
             <br/>
             {intrestlink.map((data) => (
                <div className='singleview-basic-details' >
                    <i className='singleview-content' >{data.i}</i>
                    <b className='singleview-values' >{data.b}</b>
                </div>
                ))}
</div>
{/* --------------------------------------------------interest links--------------------------------------------------------- */}

{/* ------------------------------------------------------Habits------------------------------------------------------------- */}
<div className='singleview-card-one' >
<h2  className='singleview-card-head'>Habits</h2> 
             <br/>
             {profilehab.map((data) => (
                <div className='singleview-basic-details' >
                    <i className='singleview-content' >{data.i}</i>
                    <b className='singleview-values' >{data.b}</b>
                </div>
                ))}
</div>

{/* ------------------------------------------------Habits--------------------------------------------------------------------- */}

{/* -----------------------------------------------Hobbies-------------------------------------------------------------------- */}
<div className='singleview-card-one' >
    <h2 className='singleview-card-head'>Hobbies</h2>
    {personhobbies.map((data)=>(
        <div className='singleview-basic-details'>
            <i className='singleview-content'>{data.i}</i>
            <b className='singleview-values'>{data.b}</b>
        </div>
    ))}
    <br/>
</div>

{/* -----------------------------------------------Hobbies---------------------------------------------------------------------- */}
</div>

</>
  )
}
export default Singleview
