import React, { useState } from 'react';
import './Feedbackstyle.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaMapMarkerAlt, FaPhone, FaTelegramPlane, FaGlobe } from 'react-icons/fa';

const Feedback = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://matrimonyadmin.hola9.com/api/profileapi/feedback/', formData);
      console.log(response.data); // Handle response data as needed
      toast.success('Message sent successfully!');
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to send message. Please try again later.');
    }
  };

  return (
    <>
      <div className='contact-card'>
        <div className="form-section-feed">
          <h1 className="h1-tag">Contact Us</h1>
        </div>
        {/* parent container for alinging form and map */}
        <div className="form-and-map-container">
          {/* form container */}
          <div className='form-container'>
            <form onSubmit={handleSubmit} className="form-color-feed">
              {/* name input */}
              <div className="form-group-feed">
                <label className="exampletInputText">Name</label>
                <input className="feedback-input"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Name"
                  required
                />
              </div>
              {/* email input */}
              <div className="form-group-feed">
                <label className="exampletInputText">Email</label>
                <input className="feedback-input"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                  required
                />
              </div>
              {/* Subject input */}
              <div className="form-group-feed">
                <label className="exampletInputText">Subject</label>
                <input className="feedback-input"
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  placeholder="Subject"
                  required
                />
              </div>
              {/* Message */}
              <div className="form-group-feed">
                <label className="exampletInputText">Message</label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Message"
                  required
                ></textarea>
              </div>
              {/* Submission Button */}
              <button onClick={handleSubmit} type="submit" className="btn-feed btn-primary-feed for-btn-centering">
                Submit
              </button>
            </form>
          </div>
          {/* Map Container */}
          <div className='image-container'>
            <iframe className='map-element' title='location-map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.5106349654257!2d77.62396937428325!3d12.93914338737327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15aa83004911%3A0xf04e6ab0127d1810!2sHOLA9%20CLASSIFIEDS%20INDIA%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1716186203632!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
        {/* contact info */}
        <div className='contact-info'>
          <div className='info-item'>
            <FaMapMarkerAlt className='info-icon' />
            <div>

              <p>Address: #12 Second Floor 3rd cross, </p>
              <p>Patel Narayana Reddy Layout</p>
              <p>6th Block Koramangala,</p>
              <p>Bangaluru - 560095.</p>

            </div>
          </div>
          <div className='info-item'>
            <FaPhone className='info-icon' />
            <div>

              <p>Phone: 08772232254</p>

            </div>
          </div>
          <div className='info-item'>
            <FaTelegramPlane className='info-icon' />
            <div>

              <p>Email: made4foreachother.</p>
              <p>hola9.@gmail.com</p>

            </div>
          </div>
          <div className='info-item'>
            <FaGlobe className='info-icon' />
            <div>

              <p>Website: made4foreachother.</p>
              <p>hola9.com</p>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Feedback;
