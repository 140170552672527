import React, { useState, useEffect } from 'react';
import "./Disclamier.css";
import { useNavigate } from 'react-router-dom';

const Disclaimer = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const [showFullText, setShowFullText] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <section className="page-title-disclaimer"></section>
      <button onClick={goBack} className='back-button-pay'><i className="ri-arrow-left-line"></i></button>
      <section className="about-section_disclaimer">
        <div className="auto-container_disclaimer">
          <div className="row-disclaimer" style={{ paddingLeft: "30px" }}>
            <div className="content-column-disclaimer col-lg-12-disclaimer col-md-12-disclaimer col-sm-12-disclaimer">
              <div className="inner-column-disclaimer">
                <div className="sec-title_disclaimer">
                  <span className="title_disclaimer">Disclaimer</span>
                  <div className="text_disclaimer">
                    {isSmallScreen && !showFullText ? (
                      <>
                        <p className="text_disclaimer_one">
                          readymatrimonial.in is not responsible for any incorrect or inaccurate Content posted on the Site or in connection with the readymatrimonial.in Service, whether caused by users visiting the Site, Members or by any of the equipment or programming associated with or utilized in the Service, nor for the conduct of any user and/or Member of the readymatrimonial.in Service whether online or offline.readymatrimonial.in assumes no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, 
                          or alteration of, user and/or Member communications.
                        </p>
                        <p className="text_disclaimer_one">
                          readymatrimonial.in is not responsible for any problems or technical malfunction of any telephone network or lines, computer on-line-systems, servers or providers, computer equipment, software, failure of email or players on account of technical problems or traffic congestion on the Internet or at any website or combination thereof, including injury or damage to users and/or Members or to any other person's computer related to or resulting from participating or downloading materials in connection with the readymatrimonial.in Site and/or in connection with the readymatrimonial.in Service.
                        </p>
                        <p>
                          <span className="read-more-text" onClick={toggleFullText}>
                            Read More
                          </span>
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="text_disclaimer_one">
                          readymatrimonial.in is not responsible for any incorrect or inaccurate Content posted on the Site or in connection with the readymatrimonial.in Service, whether caused by users visiting the Site, Members or by any of the equipment or programming associated with or utilized in the Service, nor for the conduct of any user and/or Member of the readymatrimonial.in Service whether online or offline.readymatrimonial.in assumes no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, 
                          or alteration of, user and/or Member communications.
                        </p>
                        <p className="text_disclaimer_one">
                          readymatrimonial.in is not responsible for any problems or technical malfunction of any telephone network or lines, computer on-line-systems, servers or providers, computer equipment, software, failure of email or players on account of technical problems or traffic congestion on the Internet or at any website or combination thereof, including injury or damage to users and/or Members or to any other person's computer related to or resulting from participating or downloading materials in connection with the readymatrimonial.in Site and/or in connection with the readymatrimonial.in Service.
                        </p>
                        <p className="text_disclaimer_one">
                          Under no circumstances will readymatrimonial.in be responsible for any loss or damage to any person resulting from anyone's use of the Site or the Service and/or any Content posted on the readymatrimonial.in Site or transmitted to readymatrimonial.in Members.
                        </p>
                        <p className="text_disclaimer_one">
                          The exchange of profile(s) through or by readymatrimonial.in should not in any way be construed as any offer and/or recommendation from/by readymatrimonial.in. readymatrimonial.in shall not be responsible for any loss or damage to any individual arising out of, or subsequent to, relations established pursuant to the use of readymatrimonial.in. The Site and the Service are provided "AS-IS AVAILABLE BASIS" and readymatrimonial.in expressly disclaims any warranty of fitness for a particular purpose or non-infringement. readymatrimonial.in cannot guarantee and does not promise any specific results from use of the Site and/or the readymatrimonial.in Service.
                        </p>
                        {isSmallScreen && (
                          <p>
                            <span className="read-more-text" onClick={toggleFullText}>
                              Read Less
                            </span>
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Disclaimer;
