import React from 'react'


const ProfileIHaveBlock = () => {
  return (
    <div>

    <section className="page-title" style={{ backgroundColor: "black",color:"white",fontSize:"20px", fontWeight:"700",marginTop:"-40px"}}>
        <div className="auto-container">
          {/* <h1 className="d-none d-lg-block d-xl-block d-md-block">Profile I Have Block </h1> */}
          <ul className="bread-crumb clearfix">
           <li style={{textAlign:'center'}}> Profile I Have Block</li>
          </ul>
        </div>
      </section>
      <div>
      <br/>
      <center>

        <h1 style={{backgroundcolor:" #ec167f",color:" #ec167f",marginTop:"100px"}}>OOP'S</h1>
    <h4>Sorry Result Not Found</h4>
    <p>You have not yet connected profiles.</p> 
    <a href="smart_search" className="theme-btn btn-style-three" style={{marginLeft:'40px'}}>
    <span className="btn-title">Search</span>
  </a>
      <div className="anim-icons full-width" style={{marginTop:"100px"}}>
  <span
    className="icon icon-circle-blue wow fadeIn animated"
    style={{ visibility: "visible", animationName: "fa-spin",marginTop:"300px ",marginLeft:"-790px" }}
  />
  <span
    className="icon icon-dots wow fadeInleft animated"
    style={{ visibility: "visible",marginTop:"290px ",marginLeft:"-390px" }}
  />
   <span
            className="icon icon-line-1 wow fadeIn animated"
            style={{ visibility: "visible", animationName: "fa-spin",marginTop:"320px ",marginLeft:"400px" }}
          />

  <span
    className="icon icon-circle-1 wow zoomIn animated"
    style={{ visibility: "visible", animationName: "zoomIn" ,marginTop:"-150px ",marginLeft:"400px" }}
  />
</div>
    </center>
  </div>
    </div>
  )
}

export default ProfileIHaveBlock
