import React from 'react'
import './My_plan.css'
import {Link} from 'react-router-dom'

const My_plan = () => {
  return (
    <>
    <div className='my-paln-head'>
        <h5 style={{fontSize:'20px',marginLeft:'10px',color:'white',fontSize:'30px'}}>My Plan</h5>
    </div>

    <div className='my-plan-table'>
       <table className='my-plan-table-content'>
        <tr className='my-plan-table-row'>
            <td className='my-plan-table-td'>Membership Type</td>
            <td  className='my-plan-table-td' style={{textAlign:'right',width:'100%'}}> <Link to='/Payment

'className='my-plan-link' > UPGRADE NOW </Link> </td>
        </tr>
        <tr>
            <td  className='my-plan-table-td'>Date of Payment</td>
            <td  className='my-plan-table-td'>---</td>
        </tr>
        <tr>
            <td  className='my-plan-table-td'>Date of Expiry </td>
            <td  className='my-plan-table-td'>---</td>
        </tr>
        <tr>
            <td  className='my-plan-table-td'>Validity</td>
            <td  className='my-plan-table-td'>0</td>
        </tr>
        <tr>
            <td  className='my-plan-table-td'>Days in Balance</td>
            <td  className='my-plan-table-td'>0</td>
        </tr>
        <tr>
            <td  className='my-plan-table-td'>Phone Numbers Total/ Balance</td>
            <td  className='my-plan-table-td'>0</td>
        </tr>
        <tr>
            <td  className='my-plan-table-td'>Astro Match Total/ Balance</td>
            <td  className='my-plan-table-td'>0</td>
        </tr>
        <tr>
            <td  className='my-plan-table-td'>SMS Pack Validity</td>
            <td  className='my-plan-table-td'>--</td>
        </tr>
       </table>
    </div>
    </>
  )
}

export default My_plan
