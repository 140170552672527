
import React from 'react';
import "./HompeImageStyle.css";
import Carousel from 'react-bootstrap/Carousel';

const HomeImg = () => {
  
 
  const slides = [
    {
      imageSrc: "https://img2.sangam.com/assets/home/HomePage_Tamil_pro.webp",
      captionTitle: "made4eachother",
      captionText: "...Because marriage is a festival in two families."
    },
    {
      imageSrc: "https://img2.sangam.com/assets/home/HomePage_Default.jpg",
      captionTitle: "made4eachother",
      captionText: "...Because marriage is a festival in two families."
    },
    {
      imageSrc: "https://img2.sangam.com/assets/home/HomePage_Sikh.jpg",
      captionTitle: "made4eachother",
      captionText: "...Because marriage is a festival in two families."
    }
  ];

  return (
    <div className='container-slide' style={{ marginTop: "2.5%", backgroundColor: "black" }}>
      <Carousel data-bs-theme="dark">
        
        {slides.map((slide, index) => (
          <Carousel.Item key={index}>
            <img style={{ marginTop: "0.5px", height: "600px", width: "100%" }}
              className="d-blockc w-100"
              src={slide.imageSrc}
              alt={`Slide ${index}`}
            />
            <Carousel.Caption>
              {/* <h5 style={{ color: "#e83e8c" }}></h5> */}
              <div class="wrapper1">
	<svg className='svg'>
		<text x="50%" y="50%" dy=".35em" text-anchor="middle" className='texting'>
		{slide.captionTitle}
		</text>
	</svg>
</div>
              <p style={{ fontSize: "30px", color: "white" }}>{slide.captionText}</p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default HomeImg;





