import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Gallery.css';

const Gallery = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get('https://matrimonyadmin.hola9.com/api/crud/siximages/');
        const allImages = response.data['All Images'];
allImages.forEach(imageData => {
  const userId = imageData.id;
  const username = imageData.user;
  console.log(`User ID: ${userId}, Username: ${username}`);
});

        const imageUrls = allImages.map(imageData => Object.values(imageData).slice(2)); // Extract URLs from each object
        const flattenedUrls = imageUrls.flat(); // Flatten the array of arrays
        setImages(flattenedUrls);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, []); 

  return (
    <>

      <div style={{ color: 'white', textAlign: 'center', width: '100%', height: '70px', fontSize: '30px', fontWeight: '500', marginTop: '10px', backgroundColor: 'black', justifyContent: 'center', alignItems: 'center', display: 'flex' }}> Gallery </div>

      <div className='body'>
        <div className='slider'>
          {images.map((imageUrl, index) => (
            <span key={index} style={{ '--i': index + 1 }}>
              <img src={`https://matrimonyadmin.hola9.com${imageUrl}`} alt={`Image ${index}`} />
            </span>
          ))}
        </div>
      </div>
    </>
  );
};

export default Gallery;
