


import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faClone } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import './Uploaddocument.css';
import { FileUploader } from "react-drag-drop-files";

const fileTypes = ["JPG", "PNG", "GIF", "PDF"];

const Uploaddocument = () => {
  const navigate = useNavigate();
  
  const [images1, setImages1] = useState([]);
  const [images2, setImages2] = useState([]);
  const [formData, setFormData] = useState({
    Uploaddocument: null,
    passport_size_photo: null
  });
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showPopupIndex, setShowPopupIndex] = useState(null);

  const goBack = () => {
    navigate(-1);
  };

  const handleFileChange = (file, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: file
    });
  };

  const handleDrop = (event, setImages) => {
    event.preventDefault();
    event.stopPropagation();

    const files = Array.from(event.dataTransfer.files);
    setImages(prevImages => [...prevImages, ...files]);
  };

  const handleSubmit = () => {
    if (!formData.Uploaddocument || !formData.passport_size_photo) {
      setError('Please upload both document and photo.');
      return;
    }

    setLoading(true);

    const formDataToSend = new FormData();
    formDataToSend.append('Uploaddocument', formData.Uploaddocument);
    formDataToSend.append('passport_size_photo', formData.passport_size_photo);

    axios.post('https://matrimonyadmin.hola9.com/api/crud/idproofapi/', formDataToSend)
      .then(response => {
        setLoading(false);
        setSubmitted(true);
        console.log('Form submitted successfully!');
        console.log('Response:', response.data);
      })
      .catch(error => {
        setLoading(false);
        setError('An error occurred while submitting the form. Please try again.');
        console.error('Error submitting form:', error);
      });
  };

  return (
    <div>
      <section className="page-title" style={{ backgroundColor: 'black', color: 'white', fontSize: '20px', fontWeight: '700', marginTop: '-40px' }}>
        <div className="auto-container-up">
          <h1 className="d-none d-lg-block d-xl-block d-md-block">Upload Document & Photo</h1>
          <ul className="bread-crumb clearfix">
            <li>Upload Document & Photo</li>
          </ul>
        </div>
      </section>

      <button onClick={goBack} className='back-button-pay'><i className="ri-arrow-left-line"></i></button>
      <div className="auto-container-up">
        {submitted ? (
          <div>
            <p>Form submitted successfully!</p>
          </div>
        ) : (
          <div className="out-box-up">
            <div className="upload-file-up">Upload Files</div>
            <div className='proof-new-up'>
              <div
                className="drop-zone-up"
                onDrop={(e) => {
                  e.preventDefault();
                  handleDrop(e, setImages1);
                }}
                onDragOver={(e) => e.preventDefault()}
                style={{ border: '2px dashed #ccc', padding: '20px', textAlign: 'center' }}
              >
                {formData.Uploaddocument ? (
                  <img
                    src={URL.createObjectURL(formData.Uploaddocument)}
                    alt="Document"
                    width={100}
                  />
                ) : (
                  <div>
                    <span className="supported-types-up">
                      (Only JPG, PNG, GIF, PDF files are supported)
                    </span>
                  </div>
                )}
                {formData.passport_size_photo ? (
                  <img
                    src={URL.createObjectURL(formData.passport_size_photo)}
                    alt="Passport Size Photo"
                    width={100}
                  />
                ) : (
                  <div>
                    <span className="supported-types-up">
                      {/* (Only JPG, PNG, GIF, PDF files are supported) */}
                    </span>
                  </div>
                )}
              </div>
              <div className="second-box-up">
                <form className="form-up">
                  <div>
                    <label htmlFor="Uploaddocument" className="label-up">
                      Click to Upload Document
                      <FileUploader
                        handleChange={(file) => handleFileChange(file, 'Uploaddocument')}
                        name="file"
                        id="Uploaddocument"
                        label="Upload files here"
                        types={fileTypes}
                        dropMessageStyle={{ backgroundColor: "red" }}
                      />
                    </label>
                  </div>
                </form>
                <div
                  className="add-id-img-1"
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(event) => handleDrop(event, setImages1)}
                >
                  <p className='drag-drop-1'>
                    <FontAwesomeIcon icon={faClone} />
                    Drag and drop images here / Select images from here
                  </p>
                  {images1.length > 0 && (
                    <div className='image-preview'>
                      {images1.map((image, index) => (
                        <div key={index} className='image-item'>
                          <img src={URL.createObjectURL(image)} alt={`Preview ${index}`} />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <form className="form-up">
                  <div>
                    <label htmlFor="passport_size_photo" className="label-up">
                      Click to Upload Photo
                      <FileUploader
                        handleChange={(file) => handleFileChange(file, 'passport_size_photo')}
                        name="file"
                        id="passport_size_photo"
                        label="Upload files here"
                        types={fileTypes}
                        dropMessageStyle={{ backgroundColor: "red" }}
                      />
                    </label>
                  </div>
                </form>
                <div
                  className="add-id-img-2"
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(event) => handleDrop(event, setImages2)}
                >
                  <p className='drag-drop-2'>
                    <FontAwesomeIcon icon={faClone} />
                    Drag and drop images here / Select images from here
                  </p>
                  {images2.length > 0 && (
                    <div className='image-preview'>
                      {images2.map((image, index) => (
                        <div key={index} className='image-item'>
                          <img src={URL.createObjectURL(image)} alt={`Preview ${index}`} />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <button type="button" className='btn-up' onClick={handleSubmit}>
                Submit
              </button>
            )}
            {error && <p className="error-message">{error}</p>}
          </div>
        )}
      </div>
    </div>
  );
};

export default Uploaddocument;

//  Already Sorted buyt we can change 
