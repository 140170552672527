import React from 'react'
import "./PriceMethodStyle.css"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoins, faHeart, faInstitution, faPlus, faWallet } from '@fortawesome/free-solid-svg-icons';
import { style } from '@mui/system'
import { FaCalendarPlus } from 'react-icons/fa'
const PriceMethod = () => {

  
 const [activeTab, setActiveTab] = useState('Credit Card')
  
 
  return (

<>
<br/><br/><br/><br/>
  <noscript>
    &lt;div style="display: flex; flex-direction: column; height: 100%"&gt;
    &lt;div style="flex: 1"&gt; &lt;h2&gt;You need to enable JavaScript to run
    this app.&lt;/h2&gt; &lt;/div&gt; &lt;/div&gt;
  </noscript>
  <div id="browserNotice" />
  <div id="portal-full-screen" />
  <div id="root">
    <div style={{ height: "100%" }}>
      <div className="sc-htpNat bcObJX">
        <div>
          <div className="sc-jqIZGH gcMSym">
            <div className="sc-fHxwqH fnPibn" id="payment_header">
              <div className="sc-cEvuZC iTkpGa">
                <div className="sc-kXeGPI iDBUis">

                </div>
                              </div>
            </div>
          </div>
        </div>
        <div className="sc-eilVRo fNIYhF">
          <div style={{backgroundColor:"#101130"}}>
            <div className="sc-gZMcBi kENsbf">
              <div data-topbadge="discountMessage" className="sc-gqjmRU dfsYEl">
                <span>
                  Upgrade to Premium and we guarantee you will find a Match!
                </span>
              </div>
            </div>
          </div>

          <div className="sc-emmjRN BlJEc">
            <div className="sc-cpmLhU fUKKrN">
              <div className="sc-eHgmQL hmXycG">
                <div
                  className=""
                  style={{
                    width: 177,
                    background:
                      'url("assets/list-bg-vertical.png") right top repeat-y rgb(247, 247, 247)',
                    padding: "0px 0px 61px"
                  }}
                >

{/* --------------------------------------------------------------------------------------------------------------------------------- */}
                  <div
                    role="button"
                    tabIndex={0}
                    className=""
                    style={{
                      background: activeTab === 'Credit Card' ? 
                        'url("assets/list-bg.png") left bottom repeat-x rgb(255, 255, 255)' : 'url("assets/list-bg.png") left bottom repeat-x rgb(247, 247, 247)',
                      borderRight: "1px solid rgb(255, 255, 255)",

                      borderLeft:activeTab === 'Credit Card' ?  "3px solid rgb(0, 188, 213)" : '',
                      
                      font: "500 14px Roboto, sans-serif",
                      padding: "0px 0px 0px 8px",
                      color: "rgb(81, 80, 93)",
                      transition: "all 300ms ease 0s",
                      outline: "none",
                      cursor: "pointer"
                    }}
                    onClick={() => setActiveTab('Credit Card')}
                    fdprocessedid="ar27dg"
                  >
                    {/* ------------------------------------------------------------------------------------------------------------------- */}
                    <div
                      className="creditCardTab sc-brqgnP bVZwEb"
                      id="creditCardTab"
                    >
                      <div className="sc-kgAjT kmzIQb">
                        <span className="sc-jAaTju dktKwX" />
                        Credit Card
                      </div>
                    </div>

                  </div>

{/* ------------------------------------------------------------------------------------------------------------------------------------------- */}
                  <div
                    role="button"
                    tabIndex={0}
                    className=""
                    style={{
                      background: activeTab === 'Debit Card' ? 
                        'url("assets/list-bg.png") left bottom repeat-x rgb(255, 255, 255)' : 'url("assets/list-bg.png") left bottom repeat-x rgb(247, 247, 247)',
                      borderRight: "1px solid rgb(223, 224, 227)",
                      borderLeft: activeTab === 'Debit Card' ? "3px solid rgb(0, 188, 213)" : '',
                      font: "300 14px Roboto, sans-serif",
                      padding: "0px 0px 0px 8px",
                      color: "rgb(114, 114, 125)",
                      transition: "all 300ms ease 0s",
                      outline: "none",
                      cursor: "pointer"
                    }}
                    onClick={() => setActiveTab('Debit Card')}
                    fdprocessedid="629lla"
                  >
                    <div
                      className="debitCardTab sc-brqgnP bVZwEb"
                      id="debitCardTab"
                    >
                      <div className="sc-kgAjT kmzIQb">
                        <span className="sc-jAaTju dktKwX" />
                        Debit Card
                      </div>
                    </div>
                  </div>


{/* ----------------------------------------------------------------------------------------------------------------------------------------- */}
                  <div
                    role="button"
                    tabIndex={0}
                    className=""
                    style={{
                      background: activeTab === 'Credit Card' ? 
                        'url("assets/list-bg.png") left bottom repeat-x rgb(255, 255, 255)' : 'url("assets/list-bg.png") left bottom repeat-x rgb(247, 247, 247)',
                      borderRight: "1px solid rgb(223, 224, 227)",
                      borderLeft: "3px solid transparent",
                      font: "300 14px Roboto, sans-serif",
                      padding: "0px 0px 0px 8px",
                      color: "rgb(114, 114, 125)",
                      transition: "all 300ms ease 0s",
                      outline: "none",
                      cursor: "pointer"
                    }}
                  />


                  <div
                    role="button"
                    tabIndex={0}
                    className=""
                    style={{
                      background: activeTab === 'Credit Card' ? 
                        'url("assets/list-bg.png") left bottom repeat-x rgb(255, 255, 255)' : 'url("assets/list-bg.png") left bottom repeat-x rgb(247, 247, 247)',
                      borderRight: "1px solid rgb(223, 224, 227)",
                      borderLeft: "3px solid transparent",
                      font: "300 14px Roboto, sans-serif",
                      padding: "0px 0px 0px 8px",
                      color: "rgb(114, 114, 125)",
                      transition: "all 300ms ease 0s",
                      outline: "none",
                      cursor: "pointer"
                    }}
                  />

{/* ------------------------------------------------------------------------------------------------------------------------------------------ */}
                  <div
                    role="button"
                    tabIndex={0}
                    className=""
                    style={{
                      background: activeTab === 'Net Banking' ? 
                        'url("assets/list-bg.png") left bottom repeat-x rgb(255, 255, 255)' : 'url("assets/list-bg.png") left bottom repeat-x rgb(247, 247, 247)',
                      borderRight: "1px solid rgb(223, 224, 227)",
                      borderLeft:  activeTab === 'Net Banking' ? "3px solid rgb(0, 188, 213)" : '',
                      font: "300 14px Roboto, sans-serif",
                      padding: "0px 0px 0px 8px",
                      color: "rgb(114, 114, 125)",
                      transition: "all 300ms ease 0s",
                      outline: "none",
                      cursor: "pointer"
                    }}
                    onClick={() => setActiveTab('Net Banking')}
                    fdprocessedid="a9aqw"
                  >
                    <div
                      className="netBankingTab sc-brqgnP bVZwEb"
                      id="netBankingTab"
                    >
                      <div className="sc-kgAjT kmzIQb">
                        <div className="sc-gPEVay kHPvxg" />
                        <span className="sc-caSCKo hpQWFC">
                          Net Banking
                          <br />
                          <span className="sc-gisBJw qDvlY">
                            (For Indian Banks Only)
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>

{/* ---------------------------------------------------------------------------------------------------------------------------------------- */}
                  <div
                    role="button"
                    tabIndex={0}
                    className=""
                    style={{
                      background: activeTab === 'UPI' ? 
                        'url("assets/list-bg.png") left bottom repeat-x rgb(255, 255, 255)' : 'url("assets/list-bg.png") left bottom repeat-x rgb(247, 247, 247)',
                      borderRight: "1px solid rgb(223, 224, 227)",
                      borderLeft:  activeTab === 'UPI' ? "3px solid rgb(0, 188, 213)" : '',
                      font: "300 14px Roboto, sans-serif",
                      padding: "0px 0px 0px 8px",
                      color: "rgb(114, 114, 125)",
                      transition: "all 300ms ease 0s",
                      outline: "none",
                      cursor: "pointer"
                    }}
                    onClick={() => setActiveTab('UPI')}
                    fdprocessedid="qlrcbd"
                  >
                    <div className="upiTab sc-brqgnP bVZwEb" id="upiTab">
                      <div className="sc-kgAjT kmzIQb">
                        <div className="upiIcon" />
                        <span className="sc-caSCKo hpQWFC">UPI </span>
                      </div>
                    </div>
                  </div>

{/* ------------------------------------------------------------------------------------------------------------------------------------------ */}
                  <div
                    role="button"
                    tabIndex={0}
                    className=""
                    style={{
                      background: activeTab === 'paytm' ? 
                      'url("assets/list-bg.png") left bottom repeat-x rgb(255, 255, 255)' : 'url("assets/list-bg.png") left bottom repeat-x rgb(247, 247, 247)',
                      borderRight: "1px solid rgb(223, 224, 227)",
                      borderLeft:  activeTab === 'paytm' ? "3px solid rgb(0, 188, 213)" : '',
                      font: "300 14px Roboto, sans-serif",
                      padding: "0px 0px 0px 8px",
                      color: "rgb(114, 114, 125)",
                      transition: "all 300ms ease 0s",
                      outline: "none",
                      cursor: "pointer"
                    }}
                    onClick={() => setActiveTab('paytm')}
                    fdprocessedid="zsfyun"
                  >
                    <div className="paytmTab sc-brqgnP bVZwEb" id="paytmTab">
                      <div className="sc-kgAjT kmzIQb">
                        <div className="paytmIcon" />
                        <div className="paytmDescWrapper">
                          <div className="paytmLogo" />
                        </div>
                      </div>
                    </div>
                  </div>


{/* --------------------------------------------------------------------------------------------------------------------------------------- */}
                  <div
                    role="button"
                    tabIndex={0}
                    className=""
                    style={{
                      background:
                        'url("assets/list-bg.png") left bottom repeat-x rgb(247, 247, 247)',
                      borderRight: "1px solid rgb(223, 224, 227)",
                      borderLeft: "3px solid transparent",
                      font: "300 14px Roboto, sans-serif",
                      padding: "0px 0px 0px 8px",
                      color: "rgb(114, 114, 125)",
                      transition: "all 300ms ease 0s",
                      outline: "none",
                      cursor: "pointer"
                    }}
                  />


                  <div
                    role="button"
                    tabIndex={0}
                    className=""
                    style={{
                      background:
                        'url("assets/list-bg.png") left bottom repeat-x rgb(247, 247, 247)',
                      borderRight: "1px solid rgb(223, 224, 227)",
                      borderLeft: "3px solid transparent",
                      font: "300 14px Roboto, sans-serif",
                      padding: "0px 0px 0px 8px",
                      color: "rgb(114, 114, 125)",
                      transition: "all 300ms ease 0s",
                      outline: "none",
                      cursor: "pointer"
                    }}
                  />


                  <div
                    role="button"
                    tabIndex={0}
                    className=""
                    style={{
                      background:
                        'url("assets/list-bg.png") left bottom repeat-x rgb(247, 247, 247)',
                      borderRight: "1px solid rgb(223, 224, 227)",
                      borderLeft: "3px solid transparent",
                      font: "300 14px Roboto, sans-serif",
                      padding: "0px 0px 0px 8px",
                      color: "rgb(114, 114, 125)",
                      transition: "all 300ms ease 0s",
                      outline: "none",
                      cursor: "pointer"
                    }}
                  />


                  <div
                    role="button"
                    tabIndex={0}
                    className=""
                    style={{
                      background:
                        'url("assets/list-bg.png") left bottom repeat-x rgb(247, 247, 247)',
                      borderRight: "1px solid rgb(223, 224, 227)",
                      borderLeft: "3px solid transparent",
                      font: "300 14px Roboto, sans-serif",
                      padding: "0px 0px 0px 8px",
                      color: "rgb(114, 114, 125)",
                      transition: "all 300ms ease 0s",
                      outline: "none",
                      cursor: "pointer"
                    }}
                  />
                </div>

{/* ----------------------------------------------------------------------------------------------------------------------------------------- */}
                <div>

                  <div className="sc-cMljjf eczwh" id="credit_debit_card">
{activeTab === 'Credit Card' ? 
                    <form className="juspay_inline_form" id="juspay_form">
                      <div className="sc-fYxtnH cJAYxD">
                        <div className="sc-iQNlJl jqlTtN">
                          <div className="sc-kjoXOD ciGct">Card Number</div>
                          <div className="sc-bsbRJL lbYRBc">
                            <div
                              className="card_number_div sc-hZSUBg dgIwMm"
                              id="iframe_card_number"
                            >
                              <iframe
                                src="https://api.juspay.in/iframe-element"
                                title="Credit Card Input Field"
                                frameBorder={0}
                                allowTransparency="true"
                                scrolling="no"
                                name="card_number_iframe_tvKUnmjuE83ukyPL"
                                className="card_number_iframe"
                                style={{
                                  border: "none",
                                  margin: 0,
                                  padding: 0,
                                  height: "100%",
                                  width: "100%",
                                  float: "left",
                                  overflow: "hidden",
                                  display: "block"
                                }}
                              />
                            </div>
                            <span className="sc-TOsTZ gjnmID">
                              Accepted Cards
                            </span>
                            <span className="sc-dVhcbM jTQKJY" />
                            <span className="sc-eqIVtm ipJjwY" />{" "}
                          </div>
                          <div
                            className="sc-bbmXgH jBdDqR"
                            id="credit_card_error"
                          >
                            <div className="sc-uJMKN ijhwRi" />
                          </div>
                        </div>

                        <div className="sc-iQNlJl jqlTtN">
                          <div className="sc-bsbRJL lbYRBc">
                            <div className="sc-iuJeZd fPUHwy">
                              Valid upto
                              <br />
                              <div className="sc-bsbRJL crgPQk">
                                <div
                                  className="card_exp_month_div sc-cMhqgX fyvnBc"
                                  id="iframe_card_exp_month"
                                >
                                  <iframe
                                    src="https://api.juspay.in/iframe-element"
                                    title="Expiry Month"
                                    frameBorder={0}
                                    allowTransparency="true"
                                    scrolling="no"
                                    name="card_exp_month_iframe_tvKUnmjuE83ukyPL"
                                    className="card_exp_month_iframe"
                                    style={{
                                      border: "none",
                                      margin: 0,
                                      padding: 0,
                                      height: "100%",
                                      width: "100%",
                                      float: "left",
                                      overflow: "hidden",
                                      display: "block"
                                    }}
                                  />
                                </div>
                                <div
                                  className="card_exp_year_div sc-cMhqgX fyvnBc"
                                  id="iframe_card_exp_year"
                                >
                                  <iframe
                                    src="https://api.juspay.in/iframe-element"
                                    title="Expiry year"
                                    frameBorder={0}
                                    allowTransparency="true"
                                    scrolling="no"
                                    name="card_exp_year_iframe_tvKUnmjuE83ukyPL"
                                    className="card_exp_year_iframe"
                                    style={{
                                      border: "none",
                                      margin: 0,
                                      padding: 0,
                                      height: "100%",
                                      width: "100%",
                                      float: "left",
                                      overflow: "hidden",
                                      display: "block"
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                className="sc-bbmXgH jBdDqR"
                                id="month_year_error"
                              >
                                <div className="sc-uJMKN ijhwRi" />
                              </div>
                            </div>
                            <div className="sc-iuJeZd diDDwV">
                              CVV
                              <br />
                              <div className="sc-bsbRJL lbYRBc">
                                <div
                                  className="security_code_div sc-cMhqgX irWuZh"
                                  id="iframe_security_code"
                                >
                                  <iframe
                                    src="https://api.juspay.in/iframe-element"
                                    title="Expiry  month"
                                    frameBorder={0}
                                    allowTransparency="true"
                                    scrolling="no"
                                    name="security_code_iframe_tvKUnmjuE83ukyPL"
                                    className="security_code_iframe"
                                    style={{
                                      border: "none",
                                      margin: 0,
                                      padding: 0,
                                      height: "100%",
                                      width: "100%",
                                      float: "left",
                                      overflow: "hidden",
                                      display: "block"
                                    }}
                                  />
                                </div>
                                <div className="sc-hqyNC hdxXlm">
                                  3 digit number printed on your back side of
                                  card
                                </div>
                              </div>
                              <div className="sc-bbmXgH jBdDqR" id="cvv_error">
                                <div className="sc-uJMKN ijhwRi" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="sc-iQNlJl jqlTtN">
                          <div className="sc-kjoXOD ciGct">Cardholder Name</div>
                          <div className="sc-bsbRJL lbYRBc">
                            <div
                              className="name_on_card_div sc-hZSUBg jrfvuD"
                              id="iframe_name_on_card"
                            >
                              <iframe
                                src="https://api.juspay.in/iframe-element"
                                title="Name on Card"
                                frameBorder={0}
                                allowTransparency="true"
                                scrolling="no"
                                name="name_on_card_iframe_tvKUnmjuE83ukyPL"
                                className="name_on_card_iframe"
                                style={{
                                  border: "none",
                                  margin: 0,
                                  padding: 0,
                                  height: "100%",
                                  width: "100%",
                                  float: "left",
                                  overflow: "hidden",
                                  display: "block"
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="sc-bbmXgH jBdDqR"
                            id="cardholder_error"
                          >
                            <div className="sc-uJMKN ijhwRi" />
                          </div>
                        </div>
                        <button
                          className="sc-esOvli isjDnd"
                          type="submit"
                          id="common_pay_btn"
                        />
                        <span>
                          <input
                            type="hidden"
                            className="sc-bMvGRv jKmwkI"
                            name="profile_booster"
                            defaultValue="false"
                          />
                          <span>
                            <input
                              type="hidden"
                              className="order_id sc-bMvGRv jKmwkI"
                              defaultValue=""
                            />
                            <input
                              type="hidden"
                              className="merchant_id sc-bMvGRv jKmwkI"
                              defaultValue="com_shaadi"
                            />
                            <input
                              type="hidden"
                              className="redirect sc-bMvGRv jKmwkI"
                              defaultValue="true"
                            />
                            <input
                              type="hidden"
                              className="should_create_mandate sc-bMvGRv jKmwkI"
                              defaultValue="false"
                            />
                          </span>
                          <input
                            type="hidden"
                            className="sc-bMvGRv jKmwkI"
                            name="cart_id"
                            defaultValue={168076423993467}
                          />
                          <input
                            type="hidden"
                            className="sc-bMvGRv jKmwkI"
                            name="mopid"
                            defaultValue={1}
                          />
                          <input
                            type="hidden"
                            className="sc-bMvGRv jKmwkI"
                            name="mode"
                            defaultValue="Credit Card Online"
                          />
                          <input
                            type="hidden"
                            className="sc-bMvGRv jKmwkI"
                            name="access_token"
                            defaultValue="1F3E771F399E5A4F3581B646B1A9A1A01680764155|ISH22979166|"
                          />
                          <input
                            type="hidden"
                            className="sc-bMvGRv jKmwkI"
                            name="error_url"
                            defaultValue="https://my.shaadi.com/cart"
                          />
                        </span>
                      </div>
                      <div className="sc-kIPQKe bOwaUF">
                        <button
                          type="button"
                          className="sc-dNLxif cdjJsO"
                          name="pay"
                          id="PayNowBtn"
                          fdprocessedid="jnwi4"
                        >
                          Next
                        </button>
                        <div className="sc-jqCOkK fOqjyz" />
                      </div>
                    </form>
                  : null }
{/* ------------------------------------------------------------------------------------------------------------ */}

{/* --------------------------------------------------------------------------------------------------------- */}

{activeTab === 'Debit Card' ? 
<form className="juspay_inline_form" id="juspay_form">
                      <div className="sc-fYxtnH cJAYxD">
                        <div className="sc-iQNlJl jqlTtN">
                          <div className="sc-kjoXOD ciGct">Card Number</div>
                          <div className="sc-bsbRJL lbYRBc">
                            <div
                              className="card_number_div sc-hZSUBg dgIwMm"
                              id="iframe_card_number"
                            >
                              <iframe
                                src="https://api.juspay.in/iframe-element"
                                title="Credit Card Input Field"
                                frameBorder={0}
                                allowTransparency="true"
                                scrolling="no"
                                name="card_number_iframe_tvKUnmjuE83ukyPL"
                                className="card_number_iframe"
                                style={{
                                  border: "none",
                                  margin: 0,
                                  padding: 0,
                                  height: "100%",
                                  width: "100%",
                                  float: "left",
                                  overflow: "hidden",
                                  display: "block"
                                }}
                              />
                            </div>
                            <span className="sc-TOsTZ gjnmID">
                              Accepted Cards
                            </span>
                            <span className="sc-dVhcbM jTQKJY" />
                            <span className="sc-eqIVtm ipJjwY" />{" "}
                          </div>
                          <div
                            className="sc-bbmXgH jBdDqR"
                            id="credit_card_error"
                          >
                            <div className="sc-uJMKN ijhwRi" />
                          </div>
                        </div>

                        <div className="sc-iQNlJl jqlTtN">
                          <div className="sc-bsbRJL lbYRBc">
                            <div className="sc-iuJeZd fPUHwy">
                              Valid upto
                              <br />
                              <div className="sc-bsbRJL crgPQk">
                                <div
                                  className="card_exp_month_div sc-cMhqgX fyvnBc"
                                  id="iframe_card_exp_month"
                                >
                                  <iframe
                                    src="https://api.juspay.in/iframe-element"
                                    title="Expiry Month"
                                    frameBorder={0}
                                    allowTransparency="true"
                                    scrolling="no"
                                    name="card_exp_month_iframe_tvKUnmjuE83ukyPL"
                                    className="card_exp_month_iframe"
                                    style={{
                                      border: "none",
                                      margin: 0,
                                      padding: 0,
                                      height: "100%",
                                      width: "100%",
                                      float: "left",
                                      overflow: "hidden",
                                      display: "block"
                                    }}
                                  />
                                </div>
                                <div
                                  className="card_exp_year_div sc-cMhqgX fyvnBc"
                                  id="iframe_card_exp_year"
                                >
                                  <iframe
                                    src="https://api.juspay.in/iframe-element"
                                    title="Expiry year"
                                    frameBorder={0}
                                    allowTransparency="true"
                                    scrolling="no"
                                    name="card_exp_year_iframe_tvKUnmjuE83ukyPL"
                                    className="card_exp_year_iframe"
                                    style={{
                                      border: "none",
                                      margin: 0,
                                      padding: 0,
                                      height: "100%",
                                      width: "100%",
                                      float: "left",
                                      overflow: "hidden",
                                      display: "block"
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                className="sc-bbmXgH jBdDqR"
                                id="month_year_error"
                              >
                                <div className="sc-uJMKN ijhwRi" />
                              </div>
                            </div>
                            <div className="sc-iuJeZd diDDwV">
                              CVV
                              <br />
                              <div className="sc-bsbRJL lbYRBc">
                                <div
                                  className="security_code_div sc-cMhqgX irWuZh"
                                  id="iframe_security_code"
                                >
                                  <iframe
                                    src="https://api.juspay.in/iframe-element"
                                    title="Expiry  month"
                                    frameBorder={0}
                                    allowTransparency="true"
                                    scrolling="no"
                                    name="security_code_iframe_tvKUnmjuE83ukyPL"
                                    className="security_code_iframe"
                                    style={{
                                      border: "none",
                                      margin: 0,
                                      padding: 0,
                                      height: "100%",
                                      width: "100%",
                                      float: "left",
                                      overflow: "hidden",
                                      display: "block"
                                    }}
                                  />
                                </div>
                                <div className="sc-hqyNC hdxXlm">
                                  3 digit number printed on your back side of
                                  card
                                </div>
                              </div>
                              <div className="sc-bbmXgH jBdDqR" id="cvv_error">
                                <div className="sc-uJMKN ijhwRi" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="sc-iQNlJl jqlTtN">
                          <div className="sc-kjoXOD ciGct">Cardholder Name</div>
                          <div className="sc-bsbRJL lbYRBc">
                            <div
                              className="name_on_card_div sc-hZSUBg jrfvuD"
                              id="iframe_name_on_card"
                            >
                              <iframe
                                src="https://api.juspay.in/iframe-element"
                                title="Name on Card"
                                frameBorder={0}
                                allowTransparency="true"
                                scrolling="no"
                                name="name_on_card_iframe_tvKUnmjuE83ukyPL"
                                className="name_on_card_iframe"
                                style={{
                                  border: "none",
                                  margin: 0,
                                  padding: 0,
                                  height: "100%",
                                  width: "100%",
                                  float: "left",
                                  overflow: "hidden",
                                  display: "block"
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="sc-bbmXgH jBdDqR"
                            id="cardholder_error"
                          >
                            <div className="sc-uJMKN ijhwRi" />
                          </div>
                        </div>
                        <button
                          className="sc-esOvli isjDnd"
                          type="submit"
                          id="common_pay_btn"
                        />
                        <span>
                          <input
                            type="hidden"
                            className="sc-bMvGRv jKmwkI"
                            name="profile_booster"
                            defaultValue="false"
                          />
                          <span>
                            <input
                              type="hidden"
                              className="order_id sc-bMvGRv jKmwkI"
                              defaultValue=""
                            />
                            <input
                              type="hidden"
                              className="merchant_id sc-bMvGRv jKmwkI"
                              defaultValue="com_shaadi"
                            />
                            <input
                              type="hidden"
                              className="redirect sc-bMvGRv jKmwkI"
                              defaultValue="true"
                            />
                            <input
                              type="hidden"
                              className="should_create_mandate sc-bMvGRv jKmwkI"
                              defaultValue="false"
                            />
                          </span>
                          <input
                            type="hidden"
                            className="sc-bMvGRv jKmwkI"
                            name="cart_id"
                            defaultValue={168076423993467}
                          />
                          <input
                            type="hidden"
                            className="sc-bMvGRv jKmwkI"
                            name="mopid"
                            defaultValue={1}
                          />
                          <input
                            type="hidden"
                            className="sc-bMvGRv jKmwkI"
                            name="mode"
                            defaultValue="Credit Card Online"
                          />
                          <input
                            type="hidden"
                            className="sc-bMvGRv jKmwkI"
                            name="access_token"
                            defaultValue="1F3E771F399E5A4F3581B646B1A9A1A01680764155|ISH22979166|"
                          />
                          <input
                            type="hidden"
                            className="sc-bMvGRv jKmwkI"
                            name="error_url"
                            defaultValue="https://my.shaadi.com/cart"
                          />
                        </span>
                      </div>
                      <div className="sc-kIPQKe bOwaUF">
                        <button
                          type="button"
                          className="sc-dNLxif cdjJsO"
                          name="pay"
                          id="PayNowBtn"
                          fdprocessedid="jnwi4"
                        >
                          Next
                        </button>
                        <div className="sc-jqCOkK fOqjyz" />
                      </div>
</form>
                     : null}
{/* ---------------------------------------------------------------------------------------------------------- */}
{activeTab === 'Net Banking' ? 
<form >
  <h4>Select Your Bank</h4>
  <h6 style={{color:'red'}}>Some Popular Banks</h6>
  <div style={{display:'flex',flexWrap:'wrap',justifyContent:'space-evenly',gap:'20px',marginBottom:'5px'}}>
    <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnJqb8Wi8o2GrthetdBaz2Agbe9VVdGKsC9XDKzVfwmg&s'
    style={{width:'90px',height:'40px',cursor:'pointer'}}/>
    <img src='https://www.punjabnewsexpress.com/images/article/article198482.jpg'style={{width:'90px',height:'40px',cursor:'pointer'}}/>
    <img src='https://www.indiablooms.com/finance_pic/2022/5cde0a7191fb1fd02ca41f11865e34e7.jpg'style={{width:'90px',height:'40px',cursor:'pointer'}}/>
    <img src='https://cdn.deccanchronicle.com/sites/default/files/Kotak%20Bank_1_0_0_0_0.jpg'style={{width:'90px',height:'40px',cursor:'pointer'}}/>
    <img src='https://1000logos.net/wp-content/uploads/2018/03/SBI-Logo.png'style={{width:'90px',height:'40px',cursor:'pointer'}}/>
  </div>

  <div>
    <h6>All Banks</h6>
    <input type='text'placeholder='Select Your Bank' style={{width:'90%',height:"30px",outline:'none',border:'solid 0.8px gray',borderRadius:'3px'}}/>
  </div>

  <div style={{display:'flex',justifyContent:'center',fontSize:'15px',fontWeight:'500'}}>
    <button style={{padding:'7px',width:'40%',borderRadius:'5px',border:'none',outline:'none',background:'rgb(0, 188, 213)',color:'white',marginTop:'10px'}}>Make Payment</button>
  </div>
     {/* <p style={{color:'red',}}>Cancel</p> */}
</form>
 : null}   
{/* ---------------------------------------------------------------------------------------------------------- */}
 {activeTab === 'UPI' ? 


<form style={{display:'flex',flexDirection:'column',gap:'18px'}}>
  <div>
  <img src='https://t3.ftcdn.net/jpg/05/60/50/16/360_F_560501607_x7crxqBWbmbgK2k8zOL0gICbIbK9hP6y.jpg'style={{width:'50px',height:'30px'}} />
  </div>

  <div style={{display:'flex',flexDirection:'column',fontSize:'15px',fontWeight:'500',}}>
  <label>UPI ID/VPA </label>
  <input type='text'style={{width:'85%',padding:'5px',background:'transparent',borderRadius:'3px',outline:'none',border:'solid 0.8px gray'}} />
  </div>

  <div style={{display:'flex',flexDirection:'column',fontSize:'15px',fontWeight:'500'}}>
  <label>Payee name</label>
  <input style={{width:'85%',padding:'5px',background:'transparent',borderRadius:'3px',outline:'none',border:'solid 0.8px gray'}} type='text'/>
  </div>

  <div style={{display:'flex',flexDirection:'column',fontSize:'15px',fontWeight:'500'}}>
  <label>Amount</label>
  <input style={{width:'85%',padding:'5px',background:'transparent',borderRadius:'3px',outline:'none',border:'solid 0.8px gray'}} type='number'/>
  </div>

  <div style={{display:'flex',justifyContent:'center',fontSize:'15px',fontWeight:'500'}}>
    <button style={{padding:'7px',width:'40%',borderRadius:'5px',border:'none',outline:'none',background:'rgb(0, 188, 213)',color:'white'}}>Pay Now</button>
  </div>
  
</form>
 : null} 
{/* ---------------------------------------------------------------------------------------------------------- */}
 {activeTab === 'paytm' ? 
 <form>
  <div>
    <img src='https://upload.wikimedia.org/wikipedia/commons/4/42/Paytm_logo.png' style={{width:'80px',height:'30px'}}/>
  </div>

  <div style={{display:'flex',flexDirection:'column'}}>
    <h5 style={{}}>Select Option to pay</h5>
    <div style={{display:'flex',flexDirection:"row",gap:"10px"}}>
      <input type='checkbox' style={{marginTop:'10px'}} />
      <p style={{display:'flex',textAlign:'center',marginTop:'8px',fontSize:'15px',fontWeight:'500'}}>Paytm Bank</p>
            < FontAwesomeIcon icon={faInstitution} style={{display:'flex',margin:'10px 0px 0px 250px'}} />  
    </div>
      <p>Balance:500</p>

    <div style={{display:'flex',flexDirection:"row",gap:'10px'}}>
      <input type='checkbox'style={{marginTop:'10px'}} />
      <p style={{display:'flex',textAlign:'center',marginTop:'8px',fontSize:'15px',fontWeight:'500'}}>Paytm Wallet</p>
      < FontAwesomeIcon icon={faWallet} style={{display:'flex',margin:'10px 0px 0px 240px'}} />  
    </div>

    <div style={{display:'flex',flexDirection:"row",gap:'10px'}}>
      <input type='checkbox' style={{marginTop:'10px'}}/>
      <p style={{display:'flex',textAlign:'center',marginTop:'8px',fontSize:'15px',fontWeight:'500'}}>Paytm postpaid</p>
      < FontAwesomeIcon icon={faCoins} style={{display:'flex',margin:'10px 0px 0px 225px'}} />  
    </div>

    <div style={{display:'flex',justifyContent:'center',fontSize:'15px',fontWeight:'500'}}>
    <button style={{padding:'7px',width:'40%',borderRadius:'5px',border:'none',outline:'none',background:'rgb(0, 188, 213)',color:'white'}}>Pay Now</button>
  </div>

  </div>
 </form> 
 : null}                
{/* -------------------------------------------------------------------------------------------------------------- */}
                  </div>
                </div>
              </div>


              <div className="sc-krDsej fxWEPO">
                <span className="sc-bHwgHz BnmjI" />
              </div>
            </div>


            <div className="sc-cpmLhU hgzgVh">
              <div className="sc-dTdPqK frWMHv">
                <div className="sc-itybZL icDnOi">
                  <div className="sc-gPWkxV dNydxh">ORDER SUMMARY</div>
                  <div className="sc-kUaPvJ dppBpF">
                    <div className="sc-fONwsr hdbkPH" id="product_name">
                      Diamond
                      <br />
                      <div className="sc-kZmsYB jglwSZ">(6 months)</div>
                    </div>
                    <div className="sc-cqCuEk gTHJvb" id="product_price">
                      <span id="data_test__">₹1780</span>
                    </div>
                  </div>
                  <div className="sc-kUaPvJ dppBpF">
                    <div className="sc-jeCdPy kCKEoZ">
                      matrimony.org Contribution
                    </div>
                    <div className="sc-cqCuEk gTHJvb">
                      <span id="data_test__">₹20</span>
                    </div>
                  </div>
                </div>
                <div className="sc-fzsDOv gLbDjK">
                  <div className="sc-kUaPvJ dppBpF">
                    <div className="sc-ipXKqB gdAvER" id="total_amount">
                      Total Amount
                    </div>
                    <div className="sc-hmXxxW dtRYQg" id="total_price">
                      <span id="data_test__">₹1800</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sc-gFaPwZ fpigcE">
                <div className="sc-dymIpo iQlaBc" />
                <div className="sc-bnXvFD hlPiFu">
                  <span className="sc-fhYwyz jUbPgw" />
                  <span className="sc-gJWqzi cfQlpx">
                    If you do not find a match within 30 days, we will refund
                    your fee
                    <span id="match_guarantee_tooltip">
                      <span className="sc-eNQAEJ cRFfri" />
                    </span>
                  </span>
                  <span className="sc-jzgbtB iAWbmT" />
                </div>
              </div>
            </div>
          </div>
          <div className="sc-rBLzX eNHslR">
            By clicking on Pay Now, you authorize Shaadi.com to charge your card
            (details mentioned above). IP Address of this transaction is
            27.7.118.26
          </div>
        </div>
      </div>
    </div>
   
  </div>
  <iframe
    src="https://api.juspay.in/payjs-iframe-sender"
    title='Payment Frame'
    id="juspay-hidden-form1680765088449"
    style={{ display: "none" }}
  />
</>
  )
}

export default PriceMethod