import React, { useState, useEffect } from 'react';
import {
  BsFillPencilFill,

} from "react-icons/bs";

const Newcontact = () => {
  const [dataContact, setDataContact] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [Email, setEmail] = useState("");
  const [Mobile_Number, setMobile_Number] = useState("");
  const [Alternative_Mobile_Number, setAlternative_Mobile_Number] = useState("");
  const [Convinient_Time_To_Call, setConvinient_Time_To_Call] = useState("");
  const [Show_Working_Address, setShow_Working_Address] = useState("");
  const [Show_Permanent_Address, setShow_Permanent_Address] = useState("");
  const [selectedId, setSelectedId] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://matrimonyadmin.hola9.com/api/Contackdetailsapi/Contactdetails/`);
        const jsonData = await response.json();
        setDataContact(jsonData);
        setIsLoading(false);
        if (jsonData.length > 0) {
          const latestData = jsonData[jsonData.length - 1];
          setSelectedId(latestData.id);
          setEmail(latestData.Email);
          setMobile_Number(latestData.Mobile_Number);
          setAlternative_Mobile_Number(latestData.Alternative_Mobile_Number);
          setConvinient_Time_To_Call(latestData.Convinient_Time_To_Call);
          setShow_Working_Address(latestData.Show_Working_Address);
          setShow_Permanent_Address(latestData.Show_Permanent_Address);

        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://matrimonyadmin.hola9.com/api/Contackdetailsapi/Contact/${selectedId}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: selectedId,
          Email,
          Mobile_Number,
          Alternative_Mobile_Number,
          Convinient_Time_To_Call,
          Show_Working_Address,
          Show_Permanent_Address
        }),
      });
      if (response.ok) {
        setDataContact(prevData => {
          const newData = [...prevData];
          const index = newData.findIndex(item => item.id === selectedId);
          if (index !== -1) {
            newData[index] = {
              id: selectedId,
              Email,
              Mobile_Number,
              Alternative_Mobile_Number,
              Convinient_Time_To_Call,
              Show_Working_Address,
              Show_Permanent_Address
            };
          }
          return newData;
        });
        closeModal();
      } else {
        console.error('Failed to update data:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {/* <div>Contact Details</div> */}
      <div
        className="feature-block-twop col-lg-4p col-md-6p col-sm-6p wow fadeInUp animated"
        style={{ visibility: "visible", animationName: "fadeInUp" }}
      >
        {dataContact.length > 0 && (
          <div className="inner-boxp">
            <div className="icon-boxp">
              <img
                src="https://www.matrimonysoftware.in/icon/education.png"
                alt=""
              />
            </div>
            <h4>
              <a href="education">
                Contact Details{" "}
                <i style={{ fontSize: 24 }} className="fa">
                  
                </i>
              </a>
            </h4>
            <BsFillPencilFill className="editpro" onClick={openModal} />
            <div className="textpro"><span>Email:</span> {Email}</div>
            <div className="textpro"><span>Mobile_Number:</span> {Mobile_Number}</div>
            <div className="textpro"><span>Alternative_Mobile_Number:</span> {Alternative_Mobile_Number}</div>
            <div className="textpro"><span>Convinient_Time_To_Call:</span> {Convinient_Time_To_Call}</div>
            <div className="textpro"><span>Show_Working_Address:</span> {Show_Working_Address}</div>
            <div className="textpro"><span>Show_Permanent_Address:</span> {Show_Permanent_Address}</div>


            {/* <button className="btn btn-primary" onClick={openModal}>Edit</button> */}
          </div>
        )}
      </div>

      <div className={`modal ${isModalOpen ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: isModalOpen ? 'block' : 'none' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Details</h5>
              <button type="button" className="close" onClick={closeModal}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="qualification" style={{ color: "white" }}>Email:</label>
                  <input type="email" className="form-control" id="Email" value={Email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="occupation" style={{ color: "white" }}>Mobile_Number:</label>
                  <input type="tel" className="form-control" id="Mobile_Number" value={Mobile_Number} onChange={(e) => setMobile_Number(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="occupation_details" style={{ color: "white" }}>Alternative_Mobile_Number:</label>
                  <input type="text" className="form-control" id="Alternative_Mobile_Number" value={Alternative_Mobile_Number} onChange={(e) => setAlternative_Mobile_Number(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="annual_income" style={{ color: "white" }}>Convenient Time To Call:</label>
                  <input type="text" className="form-control" id="Convenient_Time_To_Call" value={Convinient_Time_To_Call} onChange={(e) => setConvinient_Time_To_Call(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="employed_in" style={{ color: "white" }}>Show Working Address:</label>
                  <input type="text" className="form-control" id="Show_Working_Address" value={Show_Working_Address} onChange={(e) => setShow_Working_Address(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="working_location" style={{ color: "white" }}>Show Permanent Address:</label>
                  <input type="text" className="form-control" id="Show_Permanent_Address" value={Show_Permanent_Address} onChange={(e) => setShow_Permanent_Address(e.target.value)} />
                </div>
                <div className="form-group">
                  <button type="submit" >Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newcontact;
