import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./SignupStyle.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



const Signup = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setNumber] = useState("");
  const [tc, setTc] = useState(false);
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [error, setError] = useState("");
  const [verifyOtp, setVerifyOtp] = useState("");

  const [tempPassword, setTempPassword] = useState("");
  const [password, setNewPassword] = useState("");
  const [password2, setConfirmPassword] = useState("");
  const [ch, setCh] = useState("");
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false)
  const [hidePassword, sethidePassword] = useState(false);
  const [openPassword, setopenPassword] = useState(false);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const emailRegex = (email) => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email);
  };

  // const toggleShowPassword = (e) => {
  //   e.preventDefault();
  //   setShowPassword(!showPassword);
  // };

  const showModal = () => {
    setOpen(true);
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email);
  };

  const validatePhoneNumber = (number) => {
    // Regular expression for phone number validation
    let regex = /^\d+$/;
    return regex.test(number);
  };

  const validatePassword = (password) => {
    // Validate password length or any other criteria
    return password.length >= 6; // Example: Password should be at least 6 characters long
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Clear previous error messages
    setError("");
    setErrorMsg("");

    // Validate email
    if (!email || !emailRegex(email)) {
      console.log("Invalid email format");
      setErrorMsg("Invalid email format"); // Setting error message in state
      toast.error("Invalid email format"); // Displaying error message using toast
      return;
    }

    // Validate name
    if (!name) {
      console.log("Please enter your name");
      setError("Please enter your name"); // Setting error message in state
      toast.error("Please enter your name"); // Displaying error message using toast
      return;
    }

    // Validate phone number
    if (!phoneNumber || !/^\d{10}$/.test(phoneNumber)) {
      console.log("Please enter a valid 10-digit phone number");
      setError("Please enter a valid 10-digit phone number"); // Setting error message in state
      toast.error("Please enter a valid 10-digit phone number"); // Displaying error message using toast
      return;
    }

    // Validate password
    if (!password || password.length < 6) {
      console.log("Password must be at least 6 characters long");
      setError("Password must be at least 6 characters long"); // Setting error message in state
      toast.error("Password must be at least 6 characters long"); // Displaying error message using toast
      return;
    }

    // Validate confirm password
    if (password !== password2) {
      console.log("Passwords do not match");
      setError("Passwords do not match"); // Setting error message in state
      toast.error("Passwords do not match"); // Displaying error message using toast
      return;
    }

    // Validate terms and conditions
    if (!tc) {
      console.log("Please accept the terms and conditions");
      setError("Please accept the terms and conditions"); // Setting error message in state
      toast.error("Please accept the terms and conditions"); // Displaying error message using toast
      return;
    }

    // If all validations pass, proceed with form submission
    let data = { email, name, phoneNumber, password, password2, tc };
    console.log(data);

    axios
      .post("https://madmin.hola9.com/api/user/register/", data, {
        name,
      })
      .then((res) => {
        console.log(res.data);
        if (res.status === 201) {
          console.log(res.data);
          localStorage.setItem("name", name);
          toast.success(res.data.msg); // Display success message using toast
          navigate("/Login");
        } else {
          toast.error("Failed to complete the process");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to complete the process");
      });
  };


  return (
    <>
      <ToastContainer />
      <div>

      </div>


      <section className="newsletter-sections" style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1707380659093-97e45913a9ea?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)', backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%' }} >

        <div className="auto-containers" style={{ marginTop: '80px' }} >

          <div className="inner-column">
            <form method="post" action="#" id="contact-forms" name="matri" noValidate="novalidate" className="contact-forms  glassmob">
              <div className="auto-containers texts" style={{ textAlign: "center", color: "black" }}>
                <h1 className="d-nones d-lg-blocks d-xl-blocks d-md-blocks" style={{ color: '#ec167f' }}>Signup</h1>

                {/* <ul className="bread-crumbs clearfixs" style={{display:'flex', justifyContent:'center'}}>
            <li><Link to="/"style={{color:'white'}} >Home</Link></li>
            <li>&nbsp; | &nbsp;</li>
            <li>Signup</li>
          </ul> */}
              </div>
              <div className="texts" style={{ textAlign: "center", color: "white", fontSize: '15px' }}><h2 className="titleup" >Matches Within Your Community</h2>Verified Profile | Safe and Secured | Entire Profile Description.</div>
              <br />
              <div className=" form-groups mt-2 " id="emailerror">
                <input className="agile-ltextSU" style={{ borderRadius: 5, color: 'white' }} type="email" name="email" tabIndex={3} placeholder="Enter Your E-mail" defaultValue="" onChange={(e) => setEmail(e.target.value)} value={email} required />{" "}
              </div>

              <div className="form-groups mt-2 " id="emailerror">
                <input className="agile-ltextSU" style={{ borderRadius: 5 }} type="text" name="name" tabIndex={3} placeholder="Enter Your Name" defaultValue="" onChange={(e) => setName(e.target.value)} value={name} required />{" "}
              </div>

              <div className="form-groups mt-2 " id="emailerror">
                <input className="agile-ltextSU" style={{ borderRadius: 5, }} type="text" name="phoneNumber" tabIndex={3} placeholder="Enter Your Phone Number" defaultValue="" onChange={(e) => setNumber(e.target.value)} value={phoneNumber} required />{" "}
              </div>

              <div className="form-groups mt-2 " id="emailerror">
                <input className="agile-ltextSU" style={{ borderRadius: 5, }} type={hidePassword ? 'text' : 'password'} name="password" tabIndex={4} placeholder="Enter Your Password" defaultValue="" onChange={(e) => setNewPassword(e.target.value)} value={password} required />
                <FontAwesomeIcon
                  icon={hidePassword ? faEye : faEyeSlash}
                  onClick={() => sethidePassword(!hidePassword)}
                  style={{ position: 'absolute', marginTop: '-10%', right: '5%', cursor: 'pointer', color: "white" }}
                />
              </div>

              <div className="form-groups mt-2 " id="emailerror">
                <input className="agile-ltextSU" style={{ borderRadius: 5 }} type={openPassword ? 'text' : 'password'} name="password2" tabIndex={5} placeholder="Enter Your Confirm Password" defaultValue="" onChange={(e) => setConfirmPassword(e.target.value)} value={password2} required />{" "}
                <FontAwesomeIcon
                  icon={openPassword ? faEye : faEyeSlash}
                  onClick={() => setopenPassword(!openPassword)}
                  style={{ position: 'absolute', marginTop: '-10%', right: '5%', cursor: 'pointer', color: "white" }}
                />
              </div>

              {error && <span style={{ color: "red" }}>{error}</span>}


              <div className="" style={{ color: "black", width: '95%', margin: 'auto' }}>
                <div style={{ display: 'flex', fontSize: '80%' }}>
                  <input type="checkbox" defaultChecked={ch ? "checked" : ""} checked={tc} tabIndex={10} name="tc" onChange={(e) => { setTc(!tc); }} />
                  I have read and agree to the{" "}
                  <Link to="terms-conditions" style={{ color: "red" }} target="_blank"><u>terms, conditions</u></Link>{" "}
                  and{" "}
                  <Link to="privacy-policy" style={{ color: "red" }} target="_blank" ><u> privacy policy.</u>{" "}</Link>
                </div>

                <a><Link to=""><button className="theme-btns btns btn-style-ones mt-4s mb-4s" type="submit" name="submit" onClick={handleSubmit}><span tabIndex={11} className="btn-title">Submit</span></button></Link></a>
              </div>

            </form>

          </div>

        </div>
      </section>



    </>
  );
};

export default Signup;
