import React, {useState}from 'react'
import './CompatabilityStyle.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Compatability = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const [How_often_do_you_go_out, setHow_often_do_you_go_out]=useState('');
  const[How_would_you_describe_your_clothes,setHow_would_you_describe_your_clothes]=useState('');
  const[How_do_you_spend_your_free_time,setHow_do_you_spend_your_free_time]=useState('');
  const[How_many_times_do_you_visit_salon_parlour,setHow_many_times_do_you_visit_salon_parlour]=useState('');
  const[How_many_times_do_you_go_out_drinking_in_a_pub,setHow_many_times_do_you_go_out_drinking_in_a_pub]=useState('');
  const[What_would_you_choose_for_a_romantic_date_with_your_partner,setWhat_would_you_choose_for_a_romantic_date_with_your_partner]=useState('');
  const[Which_social_platform_do_you_use_Most,setWhich_social_platform_do_you_use_Most]=useState('');
  const[Do_you_like_shopping,setDo_you_like_shopping]=useState('');
  const[Preferences_while_traveling,setPreferences_while_traveling]=useState('');
  const[Which_personality_are_you,setWhich_personality_are_you]=useState('');

    // Event handlers to update values
    const handleOften = (event) => {
      setHow_often_do_you_go_out(event.target.value);
    };

    const handleClothes = (event) => {
      setHow_would_you_describe_your_clothes(event.target.value);
    };

    const handleTime = (event) => {
      setHow_do_you_spend_your_free_time(event.target.value);
    };

    const handleParlour = (event) => {
      setHow_many_times_do_you_visit_salon_parlour(event.target.value);
    };

    const handlePub = (event) => {
      setHow_many_times_do_you_go_out_drinking_in_a_pub(event.target.value);
    };

    const handleDate = (event) => {
      setWhat_would_you_choose_for_a_romantic_date_with_your_partner(event.target.value);
    };

    const handleSocial = (event) => {
      setWhich_social_platform_do_you_use_Most(event.target.value);
    };

    const handleShooping = (event) => {
      setDo_you_like_shopping(event.target.value);
    };

    const handleTravelling = (event) => {
      setPreferences_while_traveling(event.target.value);
    };

    const handlePersonality = (event) => {
      setWhich_personality_are_you(event.target.value);
    };
    
    const submit = () => {
      const Data = {
        How_often_do_you_go_out,
        How_would_you_describe_your_clothes,
        How_do_you_spend_your_free_time,
        How_many_times_do_you_visit_salon_parlour,
        How_many_times_do_you_go_out_drinking_in_a_pub,
        What_would_you_choose_for_a_romantic_date_with_your_partner,
        Which_social_platform_do_you_use_Most,
        Do_you_like_shopping,
        Preferences_while_traveling,
        Which_personality_are_you
      };
  
      console.log('Submitting:', Data); // Debugging: Log submitted data
  
      axios.post('https://matrimonyadmin.hola9.com/api/crud/compatibilityapi/', Data)
        .then(response => {
          console.log('compatibility submitted successfully:', response.data);

        })
        .catch(error => {
          console.error('Error submitting habits:', error);
          // Handle errors here
        });
    };

  return (
    <div className='container-compatibility'>

  <button onClick={goBack} className='back-button-pay'><i class="ri-arrow-left-line"></i></button>

      <div style={{width:'100%',height:'60px',display:'flex',justifyContent:'center',color:'#ec167f'}}>
      <h1 className="title-compatability" >Compatability Details</h1>
      </div>

      
  
      <section className="">
  <div className="auto-container-compatability mt-5-compatability">
    <div className="auto-container-compatability">
      <div className="schedule-timeline-compatability">
        <div className="col-md-12-compatability">
          <div className="compact-compatability">
            <form action="#" method="post" className='form-compatability'>
              <div className="row-compatability">
                <div className="col-md-4-compatability">
                  <div className="text-compatability">
                    <i className="fas-compatability fa-forward-compatability sqrt-compatability" />
                    <label className='i1-compatability'>How often do you go
                    out </label> <br />
                    <input
                      type="radio"
                      required=""
                      value="Twice a week or more than that"
                      checked={How_often_do_you_go_out==='Twice a week or more than that'}
                      onChange={handleOften}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'>Twice a week or more than that</label>
                    <br />
                    <input
                      type="radio"
                      value="Once a week"
                      checked={How_often_do_you_go_out==='Once a week'}
                      onChange={handleOften}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'>Once a week</label>
                    <br />
                    <input
                      type="radio"
                      value="Twice in a month"
                      checked={How_often_do_you_go_out==='Twice in a month'}
                      onChange={handleOften}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> Twice in a month</label>
                    <br />
                    <input
                      type="radio"
                      value="Once a month or less"
                      checked={How_often_do_you_go_out==='Once a month or less'}
                      onChange={handleOften}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'>Once a month or less</label>
                    <br />
                    <br />
                  </div>
                </div>
                <div className="col-md-4-compatability">
                  <div className="text-compatability">
                    <i className="fas-compatability fa-forward-compatability sqrt-compatability" /> <label className='i1-compatability'>How would you describe
                    your clothes?</label> <br />
                    <input
                      type="radio"
                      value="Only of foreign or big brands"
                      checked={How_would_you_describe_your_clothes==='Only of foreign or big brands'}
                      onChange={handleClothes}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'>Only of foreign or big brands</label>
                    <br />
                    <input
                      type="radio"
                      value="Mostly all foreign brands"
                      checked={How_would_you_describe_your_clothes==='Mostly all foreign briefs'}
                      onChange={handleClothes}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> Mostly all foreign brands</label>
                    <br />
                    <input
                      type="radio"
                      value="Mostly all of local brands"
                      checked= {How_would_you_describe_your_clothes === 'Mostly all of local brands'}
                      onChange={handleClothes}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> Mostly all of local brands</label>
                    <br />
                    <input
                      type="radio"
                      value="All of local brands"
                      checked={How_would_you_describe_your_clothes==='All of local brands'}
                      onChange={handleClothes}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'>All of local brands</label>
                    <br />
                    <br />
                  </div>
                </div>
                <div className="col-md-4-compatability">
                  <div className="text-compatability">
                    <i className="fas-compatability fa-forward-compatability sqrt-compatability" /> 
                    <label className='i1-compatability'>How do you spend your
                    free time </label> <br />
                    <input
                      type="radio"
                      value="Meditation, Satsang etc"
                      checked={How_do_you_spend_your_free_time==='Meditation, Satsang etc.'}
                      onChange={handleTime}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> Meditation, Satsang etc</label>
                    <br />
                    <input
                      type="radio"
                      value="Family"
                      checked={How_do_you_spend_your_free_time==='Family'}
                      onChange={handleTime}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> Family</label>
                    <br />
                    <input
                      type="radio"
                      value="Hobbies/ recreational activities"
                      checked={How_do_you_spend_your_free_time==='Hobbies/ recreational activities'}
                      onChange={handleTime}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> Hobbies/ recreational activities</label>
                    <br />
                    <input
                      type="radio"
                      value="Friends"
                      checked={How_do_you_spend_your_free_time==='Friends'}
                      onChange={handleTime}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> Friends</label>
                    <br />
                    <br />
                  </div>
                </div>
                <div className="col-md-4-compatability">
                  <div className="text-compatability">
                    <i className="fas-compatability fa-forward-compatability sqrt-compatability" /> 
                    <label className='i1-compatability'>How many times do you visit salon/beauty parlour?</label> <br />
                    <input
                      type="radio"
                      value="Weekly once"
                      checked={How_many_times_do_you_visit_salon_parlour==="Weekly once"}
                      onChange={handleParlour}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> Weekly once</label>
                    <br />
                    <input
                      type="radio"
                      value="Twice in a month"
                      checked={How_many_times_do_you_visit_salon_parlour==="Twice in a month"}
                      onChange={handleParlour}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> Twice in a month</label>
                    <br />
                    <input
                      type="radio"
                      value="Once in a month"
                      checked={How_many_times_do_you_visit_salon_parlour==="Once in a month"}
                      onChange={handleParlour}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> Once in a month</label>
                    <br />
                    <input
                      type="radio"
                      value="Once in a whiles"
                      checked={How_many_times_do_you_visit_salon_parlour==="Once in a whiles"}
                      onChange={handleParlour}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> Once in a whiles</label>
                    <br />
                    <br />
                  </div>
                </div>
                <div className="col-md-4-compatability">
                  <div className="text-compatability">
                    <i className="fas-compatability fa-forward-compatability sqrt-compatability" /> <label className='i1-compatability'>How many times do you
                    go out drinking/ in a pub</label> <br />
                    <input
                      type="radio"
                      required=""
                      value="Once a week or more"
                      checked={How_many_times_do_you_go_out_drinking_in_a_pub==='Once a week or more'}
                      onChange={handlePub}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> Once a week or more</label>
                    <br />
                    <input
                      type="radio"
                      value="Once/twice in a month"
                      checked={How_many_times_do_you_go_out_drinking_in_a_pub==="Once/twice in a month"}
                      onChange={handlePub}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> Once/twice in a month</label>
                    <br />
                    <input
                      type="radio"
                      value="Rarely"
                      checked={How_many_times_do_you_go_out_drinking_in_a_pub==="Rarely"}
                      onChange={handlePub}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> Rarely</label>
                    <br />
                    <input
                      type="radio"
                      value="Never"
                      checked={How_many_times_do_you_go_out_drinking_in_a_pub==="Never"}
                      onChange={handlePub}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> Never</label>
                    <br />
                    <br />
                  </div>
                </div>
                <div className="col-md-4-compatability">
                  <div className="text-compatability">
                    <i className="fas-compatability fa-forward-compatability sqrt-compatability" /> 
                    <label className='i1-compatability'>What would you choose
                    for a romantic date with your partner?</label> <br />
                    <input
                      type="radio"
                      value="Candle light dinner at home"
                      checked={What_would_you_choose_for_a_romantic_date_with_your_partner==='Candle light dinner at home'}
                      onChange={handleDate}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> Candle light dinner at home</label>
                    <br />
                    <input
                      type="radio"
                      value="Lunch/dinner in a deluxe hotel"
                      checked={What_would_you_choose_for_a_romantic_date_with_your_partner==='Lunch/dinner in a deluxe hotel'}
                      onChange={handleDate}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> Lunch/dinner in a deluxe hotel</label>
                    <br />
                    <input
                      type="radio"
                      value="Long drive"
                      checked={What_would_you_choose_for_a_romantic_date_with_your_partner==='Long drive'}
                      onChange={handleDate}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> Long drive</label>
                    <br />
                    <input
                      type="radio"
                      value="Tea and snacks at a street vendor"
                      checked={What_would_you_choose_for_a_romantic_date_with_your_partner==="Tea and snacks at a street vendor"}
                      onChange={handleDate}
                      className="radiocss-compatability"
                    />
                    <label className="content-compatability">
                      Tea and snacks at a street vendor
                    </label>
                    <br />
                    <br />
                  </div>
                </div>
                <div className="col-md-4-compatability">
                  <div className="text-compatability">
                    <i className="fas-compatability fa-forward-compatability sqrt-compatability" /> 
                    <label className='i1-compatability'>Which social platform
                    do you use Most </label><br />
                    <input
                      type="radio"
                      value="Facebook"
                      checked={Which_social_platform_do_you_use_Most==="Facebook"}
                      onChange={handleSocial}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> Facebook</label>
                    <br />
                    <input
                      type="radio"
                      value="What'sapp"
                      checked={Which_social_platform_do_you_use_Most==="What'sapp"}
                      onChange={handleSocial}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> What'sapp</label>
                    <br />
                    <input
                      type="radio"
                      value="Instagram"
                      checked={Which_social_platform_do_you_use_Most==="Instagram"}
                      onChange={handleSocial}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> Instagram</label>
                    <br />
                    <input
                      type="radio"
                      value="Twitter"
                      checked={Which_social_platform_do_you_use_Most==="Twitter"}
                      onChange={handleSocial}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> Twitter</label>
                    <br />
                    <br />
                  </div>
                </div>
                <div className="col-md-4-compatability">
                  <div className="text-compatability">
                    <i className="fas-compatability fa-forward-compatability sqrt-compatability" />
                     <label className='i1-compatability'>Do you like shopping?</label>{" "}
                    <br />
                    <input
                      type="radio"
                      value="true"
                      checked={Do_you_like_shopping==="true"}
                      onChange={handleShooping}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> true</label>
                    <br />
                    <input
                      type="radio"
                      value="Sometimes"
                      checked={Do_you_like_shopping==="Sometimes"}
                      onChange={handleShooping}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> Sometimes</label>
                    <br />
                    <input
                      type="radio"
                      value="Only when its needed"
                      checked={Do_you_like_shopping==="Only when its needed"}
                      onChange={handleShooping}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> Only when its needed</label>
                    <br />
                    <input
                      type="radio"
                      value="No"
                      checked={Do_you_like_shopping==="No"}
                      onChange={handleShooping}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> No</label>
                    <br />
                    <br />
                  </div>
                </div>
                <div className="col-md-4-compatability">
                  <div className="text-compatability">
                    <i className="fas-compatability fa-forward-compatability sqrt-compatability" /> 
                    <label className='i1-compatability'>Preferences while
                    traveling?</label>
                    <br />
                    <input
                      type="radio"
                      value="No problem wherever or whenever"
                      checked={Preferences_while_traveling==="No problem wherever or whenever"}
                      onChange={handleTravelling}
                      className="radiocss-compatability"
                    />
                    <label className="content-compatability">
                      No problem wherever or whenever
                    </label>
                    <br />
                    <input
                      type="radio"
                      value="Trekking/ adventurous activities"
                      checked={Preferences_while_traveling==="Trekking/ adventurous activities"}
                      onChange={handleTravelling}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> Trekking/ adventurous activities</label>
                    <br />
                    <input
                      type="radio"
                      value="Serene, places close to nature"
                      checked={Preferences_while_traveling==="Serene, places close to nature"}
                      onChange={handleTravelling}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> Serene, places close to nature</label>
                    <br />
                    <input
                      type="radio"
                      value="Don’t like to travel"
                      checked={Preferences_while_traveling==="Don’t like to travel"}
                      onChange={handleTravelling}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'> Don’t like to travel</label>
                    <br />
                    <br />
                  </div>
                </div>

                <div className="col-md-4-compatability">
                  <div className="text1-compatability">
                    <i className="fas-compatability fa-forward-compatability sqrt-compatability" />
                     <label className='i1-compatability'>Which personality are
                    you?</label> <br/>
                    <input
                      type="radio"
                     value="I like to spend a lot,on luxury products"
                     checked={Which_personality_are_you==="I like to spend a lot,on luxury products"}
                     onChange={handlePersonality}
                      className="radiocss-compatability"
                    />
                    <label className="content-compatability">
                      I like to spend a lot,on luxury products
                    </label>
                    <br />
                    <input
                      type="radio"
                      value="I like luxury products sometimes"
                     checked={Which_personality_are_you==="I like luxury products sometimes"}
                     onChange={handlePersonality}
                      className="radiocss-compatability"
                    />
                    <label className='content-compatability'>I like luxury products sometimes</label>
                    <br />
                    <input
                      type="radio"
                      value="Not for luxury, but prefer to spend for"
                     checked={Which_personality_are_you==="Not for luxury, but prefer to spend for"}
                     onChange={handlePersonality}
                      className="radiocss-compatability"
                    />
                    <label className="content-compatability">
                      {" "}
                      Not for luxury, but prefer to spend for
                    </label>
                    <br />
                    <input
                      type="radio"
                      value="Tendency for low cost and economical"
                     checked={Which_personality_are_you==="Tendency for low cost and economical"}
                     onChange={handlePersonality}
                      className="radiocss-compatability"
                    />
                    <label className="content-compatability">
                      Tendency for low cost and economical {" "}
                    </label>
                    <br />
                    <br />
                  </div>
                </div>
                <div className="col-lg-12-compatability col-md-12-compatability col-sm-12-compatability mt-4-compatability">
                 
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<button style={{border:'none',outline:'none',width:'50%',display:'flex',justifyContent:'center',margin:'auto',padding:'8px 0',marginBottom:'10px',background:'#ec167f',color:'white',cursor:'pointer',borderRadius:'10px',marginTop:'20px'}}  onClick={submit}>Submit</button>

{/* <Footer/> */}
    </div>
  )
}

export default Compatability