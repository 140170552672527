import React, { useState, useEffect } from 'react';
import './ModalCard.css';

const CardModal = ({ onClose }) => {
  return (
  
    <div className="card-modal">
      <div className="close-icon" onClick={onClose}>
        &#10006;
      </div>

      <div className="modal-card">
        <img className='modal-img' src='https://cdn3.iconfinder.com/data/icons/space-vol-1-3/64/Artboard_1_copy_5-1024.png'/>
        <div className='modal-details'>
          <h2>Horoscope</h2>
          <p>Upload your birth chart to find suitable matches faster.</p>
        </div>
      </div>

      <div className="modal-card">
        <img className='modal-img' src='https://th.bing.com/th/id/OIP.AUDpYxKwx5r5ha5hnMHpQQHaF3?rs=1&pid=ImgDetMain'/>
        <div className='modal-details'>
          <h2>Photo</h2>
          <p>Add your photo to instantly capture the attention of prospective partners.</p>
        </div>
      </div>

      <div className="modal-card">
        <img className='modal-img' src='https://static.vecteezy.com/system/resources/previews/002/110/613/original/gold-medal-for-winner-tournament-free-vector.jpg'/>
        <div className='modal-details'> 
          <h2>Trust Certificate</h2>
          <p>Build greater trust by uploading a copy of a government-issued ID card.</p>
        </div>
      </div>

      <button className="complete-profile-btn">Complete Your Profile Now</button>
    </div>

  );
};


const ModalCard = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const closeModal = () => {
    setShowModal(false);
  };

  
  return (
    <div>
   
      {showModal && <CardModal onClose={closeModal} />}
     
    </div>

  );
};

export default ModalCard;
