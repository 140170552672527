import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Term.css';

const Term = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  // State variables to manage visibility
  const [showFullText, setShowFullText] = useState(false);

  // Function to toggle visibility
  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };

  return (
    <div>
      <section className="page-title-TC"></section>
      <button onClick={goBack} className="back-button-pay">
        <i className="ri-arrow-left-line"></i>
      </button>
      <section className="about-section-TC">
        <div className="auto-container-TC">
          <div className="row-TC" style={{ paddingLeft: "30px" }}>
            {/* Content Column */}
            <div className="content-column-TC col-lg-12-TC col-md-12-TC col-sm-12-TC">
              <div className="inner-column-TC">
                <div className="sec-title-TC">
                  <span className="title-TC">Terms & Conditions</span>
                  {/* Content before "Read More" */}
                  <div className={`text-content ${showFullText ? 'full-text' : 'partial-text'}`}>
                    <p>
                      Matrimonial Service Provider&nbsp;
                      <strong>readymatrimonial.in</strong>&nbsp;reserves the right
                      in its sole discretion to review the activity &amp; status
                      of each account &amp; block the account of a Member based
                      on such review.
                    </p>
                    <p>
                      Members/Users are solely responsible for their
                      interactions with other members.&nbsp;
                      <strong>readymatrimonial.in</strong>
                      &nbsp;reserves the right, but has no obligation, to
                      monitor disputes between you and other Members. In its
                      sole discretion&nbsp;readymatrimonial.in, can take
                      appropriate action against any members but not obliged
                      to share such action with other members under any
                      circumstances.
                    </p>
                    <p>
                      Member cannot use any automated processes/program/scripts to view
                      contents or perform any activities on&nbsp;
                      <strong>readymatrimonial.in</strong>
                    </p>
                    <p>
                      Creation of multiple profiles for the same person is not allowed.
                      Service Provider&nbsp;readymatrimonial.in&nbsp;reserves the right
                      to deactivate such profiles without any prior notice/intimation
                      and refund of subscription fees to users in its sole discretion.
                    </p>
                    <p>
                      readymatrimonial.in&nbsp;reserves the rights at any time to modify
                      or discontinue, temporarily or permanently,
                      the&nbsp;readymatrimonial.in&nbsp;with or without notice. Member
                      agrees that&nbsp;readymatrimonial.in&nbsp;shall not be liable to
                      you or to any third party for any modification, suspension or
                      discontinuance of the Service.
                    </p>
                    <p>
                      When communicating with our customer care representatives,
                      Member/User undertakes to present their good behavior. The bad
                      behavior shall mean abusive language, obscene acts, profane
                      conduct, offensive tenor, sexist remark, threatening, harassing,
                      racially offensive, or otherwise inappropriate behavior (decided
                      on case to case basis).
                    </p>
                    <p>
                      For quality assurance purposes telephone calls between Member and
                      our customer care representatives may be recorded. If we feel that
                      your behavior towards any of our customer care representatives or
                      other employees at any time (online, on call, in person) is bad
                      behavior,&nbsp;readymatrimonial.in&nbsp;shall in such case reserve
                      the right to immediately terminate membership without any refund
                      of unused membership fees.
                    </p>
                    <p>
                      In order to protect the integrity of the
                      Service,&nbsp;readymatrimonial.in&nbsp;reserves the right at any
                      time in its sole discretion to block Member/s from certain IP
                      addresses from accessing this matrimonial Website...
                    </p>
                    <p>
                      Any action regarded as inappropriate or whose conduct is unlawful
                      or illegal&nbsp;readymatrimonial.in&nbsp;reserves the right to
                      investigate and terminate any membership without refunding any
                      membership fee.
                    </p>
                    <p>
                      User / Member is not allowed to impersonate, stalk or harass any
                      person, group, community or entity.
                    </p>
                    <p>
                      User / Member is not allowed to post, email or otherwise transmit
                      any material that contains software viruses or any other computer
                      code, files or programs designed to interrupt, destroy or limit
                      the functionality of any computer software or hardware or
                      telecommunications equipment.
                    </p>
                    <p>
                      User / Member is not allowed to engage in commercial activities
                      and/or sales without our prior written consent.
                    </p>
                    <p>
                      User / Member is not allowed to Transmits "junk mail", "chain
                      letters," or "spamming".
                    </p>
                    <p>
                      User / Member is not allowed to Promotes information that is
                      false, misleading or promotes illegal activities or conduct that
                      is abusive, threatening, obscene, defamatory.
                    </p>
                    <p>
                      User / Member is not allowed to provides material that exploits
                      minors in a sexual or violent manner, or solicits personal
                      information from minor.
                    </p>
                    <p>
                      readymatrimonial.in&nbsp;is an matrimonial adult site and not to
                      be used by minors.
                    </p>
                    <p>
                      User/Member undertakes and acknowledges that while
                      accessing&nbsp;readymatrimonial.in&nbsp;they shall follow, all
                      applicable laws and regulations existing in their respective
                      country and the country where the&nbsp;readymatrimonial.in&nbsp;is
                      operating.
                    </p>
                    <p>
                      readymatrimonial.in&nbsp;reserves the right to investigate and
                      take appropriate legal action in its sole discretion against
                      anyone who violates this provision, including without limitation,
                      removing the offending communication from the Service and
                      terminating the membership of such violator, filing of police
                      complaints, institution of civil suit for injunction and damages.
                    </p>
                    
                  </div>
                  {/* Render "Read More" link */}
                  <div className="toggle-button">
                    {showFullText ? (
                      <span className="read-less-text" onClick={toggleFullText}>Read Less</span>
                    ) : (
                      <span className="read-more-text" onClick={toggleFullText}>Read More</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Term;