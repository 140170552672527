import React, { useState, useEffect } from 'react';
import {
  BsFillPencilFill,

} from "react-icons/bs";


const Newfamily= () => {
  const [datafam, setDatafam] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [family_values, setFamily_values] = useState("");
  const [family_type, setFamily_type] = useState("");
  const [family_status, setFamily_status] = useState("");
  const [mother_tounge, setMother_tounge] = useState("");
  const [no_of_brothers, setNo_of_brothers] = useState("");
  const [no_of_brothers_married, setNo_of_brothers_married] = useState("");
  const [no_of_sisters, setNo_of_sisters] = useState("");
  const [no_of_sisters_married, setNo_of_sisters_married] = useState("");
  const [father_name, setFather_name] = useState("");
  const [father_occupation, setFather_occupation] = useState();
  const [mother_name, setMother_name] = useState();
  const [mother_occupation, setMother_occupation] = useState();
  const [family_wealth, setFamily_wealth] = useState();
  const [about_family, setAbout_family] = useState();
  const [options, setOptions] = useState();
  const [selectedId, setSelectedId] = useState(null);


  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://matrimonyadmin.hola9.com/api/Familydeatilsapi/Familydetails/`);
        const jsonData = await response.json();
        setDatafam(jsonData);
        setIsLoading(false);
        if (jsonData.length > 0) {
          const latestData = jsonData[jsonData.length - 1];
          setSelectedId(latestData.id);
          setFamily_values(latestData.family_values);
          setFamily_type(latestData.family_type);
          setFamily_status(latestData.family_status);
          setMother_tounge(latestData.mother_tounge);
          setNo_of_brothers(latestData.no_of_brothers);
          setNo_of_brothers_married(latestData.no_of_brothers_married);
          setNo_of_sisters(latestData.no_of_sisters);
          setNo_of_sisters_married(latestData.no_of_sisters_married);
          setFather_name(latestData.father_name);
          setFather_occupation(latestData.father_occupation);
          setMother_name(latestData.mother_name);
          setMother_occupation(latestData.mother_occupation);
          setFamily_wealth(latestData.family_wealth);
          setAbout_family(latestData.about_family);
          setOptions(latestData.options);
        
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://matrimonyadmin.hola9.com/api/Familydeatilsapi/Familydetails/${selectedId}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: selectedId,
          family_values,
    family_type,
    family_status,
    mother_tounge,
    no_of_brothers,
    no_of_brothers_married,
    no_of_sisters,
    no_of_sisters_married,
    father_name,
    father_occupation,
    mother_name,
    mother_occupation,
    family_wealth,
    about_family,
    options
        }),
      });
      if (response.ok) {
        setDatafam(prevData => {
          const newData = [...prevData];
          const index = newData.findIndex(item => item.id === selectedId);
          if (index !== -1) {
            newData[index] = {
              id: selectedId,
              family_values,
              family_type,
              family_status,
              mother_tounge,
              no_of_brothers,
              no_of_brothers_married,
              no_of_sisters,
              no_of_sisters_married,
              father_name,
              father_occupation,
              mother_name,
              mother_occupation,
              family_wealth,
              about_family,
              options
            };
          }
          return newData;
        });
        closeModal();
      } else {
        console.error('Failed to update data:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div >
      {/* <div>Family Details</div> */}
      <div
        className="feature-block-twop col-lg-4p col-md-6p col-sm-6p wow fadeInUp animated"
        style={{ visibility: "visible", animationName: "fadeInUp" }}
      >
        {datafam.length > 0 && (
          <div className="inner-boxp">
            <div className="icon-boxp">
              <img
                src="https://www.matrimonysoftware.in/icon/family.png"
                alt=""
              />
            </div>
            <h4>
              <a href="education">
                Family Details{" "}
                <i style={{ fontSize: 24 }} className="fa">
                  
                </i>
              </a>
            </h4>
            <BsFillPencilFill className="editpro"   onClick={openModal}/>
            <div className="textpro"><span>Family_Values: </span>{family_values}</div>
            <div className="textpro"><span>Family_Type: </span>{family_type}</div>
            <div className="textpro"><span>Family_Status: </span>{family_status}</div>
            <div className="textpro"><span>Mother_Tounge: </span>{mother_tounge}</div>
            <div className="textpro"><span>no_of_brothers:</span>{no_of_brothers}</div>
            <div className="textpro"><span>no_of_brothers_married: </span>{no_of_brothers_married}</div>
            <div className="textpro"><span>no_of_sisters: </span>{no_of_sisters}</div>
            <div className="textpro"><span>no_of_sisters_married:</span> {no_of_sisters_married}</div>
            <div className="textpro"><span>Father_name:</span>{father_name}</div>
            <div className="textpro"><span>Father_occupation:</span> {father_occupation}</div>
            <div className="textpro"><span>Mother_name:</span>{mother_name}</div>
            <div className="textpro"><span>mother_occupation:</span>{mother_occupation}</div>
            <div className="textpro"><span>family_wealth:</span>{family_wealth}</div>
            <div className="textpro"><span>about_family:</span>{about_family}</div>
            <div className="textpro"><span>options:</span>{options}</div>

            {/* <button className="btn btn-primary" onClick={openModal}>Edit</button> */}
          </div>
        )}
      </div>

      <div className={`modal ${isModalOpen ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: isModalOpen ? 'block' : 'none' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Details</h5>
              <button type="button" className="close" onClick={closeModal}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="qualification"  style={{color:"white"}}>family_values:</label>
                  <input type="text" className="form-control" id="family_values" value={family_values} onChange={(e) => setFamily_values(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="occupation"  style={{color:"white"}}> family_type:</label>
                  <input type="text" className="form-control" id=" family_type" value={family_type} onChange={(e) => setFamily_type(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="occupation_details"  style={{color:"white"}}>family_status:</label>
                  <input type="text" className="form-control" id="family_status" value={family_status} onChange={(e) => setFamily_status(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="annual_income"  style={{color:"white"}}>mother_tounge:</label>
                  <input type="text" className="form-control" id="mother_tounge" value={mother_tounge} onChange={(e) => setMother_tounge(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="employed_in"  style={{color:"white"}}>no_of_brothers:</label>
                  <input type="text" className="form-control" id="no_of_brothers" value={no_of_brothers} onChange={(e) => setNo_of_brothers(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="working_location"  style={{color:"white"}}>no_of_brothers_married:</label>
                  <input type="text" className="form-control" id="no_of_brothers_married" value={no_of_brothers_married} onChange={(e) => setNo_of_brothers_married(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="special_cases"  style={{color:"white"}}>no_of_sisters:</label>
                  <input type="text" className="form-control" id="no_of_sisters" value={no_of_sisters} onChange={(e) => setNo_of_sisters(e.target.value)} />
                </div>

                <div className="form-group">
                  <label htmlFor="special_cases"  style={{color:"white"}}>no_of_sisters_married:</label>
                  <input type="text" className="form-control" id="no_of_sisters_married" value={no_of_sisters_married} onChange={(e) => setNo_of_sisters_married(e.target.value)} />
                 
                </div>

                <div className="form-group">
                  <label htmlFor="special_cases"  style={{color:"white"}}>father_name:</label>
                  <input type="text" className="form-control" id="father_name" value={father_name} onChange={(e) => setFather_name(e.target.value)} />
                 
                </div>

                <div className="form-group">
                  <label htmlFor="special_cases"  style={{color:"white"}}>father_occupation:</label>
                  <input type="text" className="form-control" id="father_occupation" value={father_occupation} onChange={(e) => setFather_occupation(e.target.value)} />
                 
                </div>

                <div className="form-group">
                  <label htmlFor="special_cases"  style={{color:"white"}}>mother_name:</label>
                  <input type="text" className="form-control" id="mother_name" value={mother_name} onChange={(e) => setMother_name(e.target.value)} />
                 
                </div>

                <div className="form-group">
                  <label htmlFor="special_cases"  style={{color:"white"}}>mother_occupation:</label>
                  <input type="text" className="form-control" id="mother_occupation" value={mother_occupation} onChange={(e) => setMother_occupation(e.target.value)} />
                 
                </div>

              

                <div className="form-group">
                  <label htmlFor="special_cases"  style={{color:"white"}}>family_wealth:</label>
                  <input type="text" className="form-control" id="family_wealth" value={family_wealth} onChange={(e) => setFamily_wealth(e.target.value)} />
                 
                </div>

                <div className="form-group">
                  <label htmlFor="special_cases"  style={{color:"white"}}>about_family:</label>
                  <input type="text" className="form-control" id="about_family" value={about_family} onChange={(e) => setAbout_family(e.target.value)} />
                 
                </div>

                <div className="form-group">
                  <label htmlFor="special_cases"  style={{color:"white"}}>options:</label>
                  <input type="text" className="form-control" id="options" value={options} onChange={(e) => setOptions(e.target.value)} />
                 
                </div>
                <div className="form-group">
                <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default Newfamily;
