import React from 'react'

  export const occupationOptions = [
    { "value": "", "label": "Select Occupation" },
    { "value": "Science", "label": "Science" },
    { "value": "Architecture & Design", "label": "Architecture & Design" },
    { "value": "Artists", "label": "Artists" },
    { "value": "Animators & Web Designers", "label": "Animators & Web Designers" },
    { "value": "Banking", "label": "Banking" },
    { "value": "Insurance & Financial Services", "label": "Insurance & Financial Services" },
    { "value": "Beauty", "label": "Beauty" },
    { "value": "Fashion & Jewellery Designers", "label": "Fashion & Jewellery Designers" },
    { "value": "Business Owner / Entrepreneur", "label": "Business Owner / Entrepreneur" },
    { "value": "Civil Services / Law Enforcement", "label": "Civil Services / Law Enforcement" },
    { "value": "Construction", "label": "Construction" },
    { "value": "Customer Service/ Call Centre/BPO", "label": "Customer Service/ Call Centre/BPO" },
    { "value": "Defence", "label": "Defence" },
    { "value": "Education/ Training", "label": "Education/ Training" },
    { "value": "Electronics", "label": "Electronics" },
    { "value": "Export/ Import", "label": "Export/ Import" },
    { "value": "Finance and Accounts", "label": "Finance and Accounts" },
    { "value": "Government Employee", "label": "Government Employee" },
    { "value": "Health Care", "label": "Health Care" },
    { "value": "Hotels/ Restaurants", "label": "Hotels/ Restaurants" },
    { "value": "Human Resource", "label": "Human Resource" },
    { "value": "IT", "label": "IT" },
    { "value": "Legal", "label": "Legal" },
    { "value": "Management / Corporate Professionals", "label": "Management / Corporate Professionals" },
    { "value": "Manufacturing/ Engineering/ R&D", "label": "Manufacturing/ Engineering/ R&D" },
    { "value": "Marketing and Communications", "label": "Marketing and Communications" },
    { "value": "Merchant Navy", "label": "Merchant Navy" },
    { "value": "Non Working", "label": "Non Working" },
    { "value": "Oil & Gas", "label": "Oil & Gas" },
    { "value": "Others", "label": "Others" },
    { "value": "Pharmaceutical/ Biotechnology", "label": "Pharmaceutical/ Biotechnology" },
    { "value": "Purchase/ Logistics/ Supply chain", "label": "Purchase/ Logistics/ Supply chain" },
    { "value": "Real Estate", "label": "Real Estate" },
    { "value": "Retail Chains", "label": "Retail Chains" },
    { "value": "Sales/ Business Development", "label": "Sales/ Business Development" },
    { "value": "Telecom/ ISP", "label": "Telecom/ ISP" },
    { "value": "Travel/ Airlines", "label": "Travel/ Airlines" }
  ];

  export const educationOptions = [
    { "value": "", "label": "Select Education" },
  { "value": "12th", "label": "12th" },
  { "value": "B.A. (Arts)", "label": "B.A. (Arts)" },
  { "value": "B.Com (Commerce)", "label": "B.Com (Commerce)" },
  { "value": "B.Sc (Science)", "label": "B.Sc (Science)" },
  { "value": "B.Arch (Architecture)", "label": "B.Arch (Architecture)" },
  { "value": "B.Ed (Education)", "label": "B.Ed (Education)" },
  { "value": "B.El.Ed (Elementary Education)", "label": "B.El.Ed (Elementary Education)" },
  { "value": "B.Lib.Sc (Library Sciences)", "label": "B.Lib.Sc (Library Sciences)" },
  { "value": "B.P.Ed. (Physical Education)", "label": "B.P.Ed. (Physical Education)" },
  { "value": "B.Plan (Planning)", "label": "B.Plan (Planning)" },
  { "value": "Bachelor of Fashion Technology", "label": "Bachelor of Fashion Technology" },
  { "value": "BBA/BBM/BBS", "label": "BBA/BBM/BBS" },
  { "value": "BCA (Computer Application)", "label": "BCA (Computer Application)" },
  { "value": "BE B.Tech (Engineering)", "label": "BE B.Tech (Engineering)" },
  { "value": "BFA (Fine Arts)", "label": "BFA (Fine Arts)" },
  { "value": "BHM (Hotel Management)", "label": "BHM (Hotel Management)" },
  { "value": "BL/LLB/BGL (Law)", "label": "BL/LLB/BGL (Law)" },
  { "value": "BSW (Social Work)", "label": "BSW (Social Work)" },
  { "value": "B.Pharm (Pharmacy)", "label": "B.Pharm (Pharmacy)" },
  { "value": "B.V.Sc. (Veterinary Science)", "label": "B.V.Sc. (Veterinary Science)" },
  { "value": "BDS (Dental Surgery)", "label": "BDS (Dental Surgery)" },
  { "value": "BHMS (Homeopathy)", "label": "BHMS (Homeopathy)" },
  { "value": "CA (Chartered Accountant)", "label": "CA (Chartered Accountant)" },
  { "value": "CFA (Chartered Financial Analyst)", "label": "CFA (Chartered Financial Analyst)" },
  { "value": "CS (Company Secretary)", "label": "CS (Company Secretary)" },
  { "value": "ICWA", "label": "ICWA" },
  { "value": "Integrated PG", "label": "Integrated PG" },
  { "value": "Engineering", "label": "Engineering" },
  { "value": "Fashion/ Design", "label": "Fashion/ Design" },
  { "value": "Languages", "label": "Languages" },
  { "value": "Pilot Licenses", "label": "Pilot Licenses" },
  { "value": "M.Arch. (Architecture)", "label": "M.Arch. (Architecture)" },
  { "value": "M.Ed. (Education)", "label": "M.Ed. (Education)" },
  { "value": "M.Lib.Sc. (Library Sciences)", "label": "M.Lib.Sc. (Library Sciences)" },
  { "value": "M.Plan. (Planning)", "label": "M.Plan. (Planning)" },
  { "value": "Master of Fashion Technology", "label": "Master of Fashion Technology" },
  { "value": "Master of Health Administration", "label": "Master of Health Administration" },
  { "value": "Master of Hospital Administration", "label": "Master of Hospital Administration" },
  { "value": "MBA/PGDM", "label": "MBA/PGDM" },
  { "value": "MCA PGDCA part time", "label": "MCA PGDCA part time" },
  { "value": "MCA/PGDCA", "label": "MCA/PGDCA" },
  { "value": "ME/M.Tech/MS (Engg/Sciences)", "label": "ME/M.Tech/MS (Engg/Sciences)" },
  { "value": "MFA (Fine Arts)", "label": "MFA (Fine Arts)" },
  { "value": "ML/LLM (Law)", "label": "ML/LLM (Law)" },
  { "value": "MSW (Social Work)", "label": "MSW (Social Work)" },
  { "value": "PG Diploma", "label": "PG Diploma" },
  { "value": "M.Com. (Commerce)", "label": "M.Com. (Commerce)" },
  { "value": "M.Sc. (Science)", "label": "M.Sc. (Science)" },
  { "value": "MA (Arts)", "label": "MA (Arts)" },
  { "value": "M.Pharm. (Pharmacy)", "label": "M.Pharm. (Pharmacy)" },
  { "value": "M.V.Sc. (Veterinary Science)", "label": "M.V.Sc. (Veterinary Science)" },
  { "value": "MBBS", "label": "MBBS" },
  { "value": "MD/ MS (Medicine)", "label": "MD/ MS (Medicine)" },
  { "value": "MDS (Master of Dental Surgery)", "label": "MDS (Master of Dental Surgery)" },
  { "value": "BPT (Physiotherapy)", "label": "BPT (Physiotherapy)" },
  { "value": "MPT (Physiotherapy)", "label": "MPT (Physiotherapy)" },
  { "value": "M.Phil. (Philosophy)", "label": "M.Phil. (Philosophy)" },
  { "value": "Ph.D. (Doctorate)", "label": "Ph.D. (Doctorate)" },
  { "value": "Other Doctorate", "label": "Other Doctorate" },
  { "value": "Other Diploma", "label": "Other Diploma" },
  { "value": "Agriculture", "label": "Agriculture" }
  ];

  export const casteOptions= [
    { "value": "Kayastha", "label": "Kayastha" },
    { "value": "Agarwal", "label": "Agarwal" },
    { "value": "Rajput", "label": "Rajput" },
    { "value": "Khatri", "label": "Khatri" },
    { "value": "Arora", "label": "Arora" },
    { "value": "Scheduled Caste", "label": "Scheduled Caste" },
    { "value": "Yadav", "label": "Yadav" },
    { "value": "Punjabi", "label": "Punjabi" },
    { "value": "Bengali", "label": "Bengali" },
    { "value": "Nair", "label": "Nair" },
    { "value": "Kshatriya", "label": "Kshatriya" },
    { "value": "Gupta", "label": "Gupta" },
    { "value": "Jat", "label": "Jat" },
    { "value": "96K Kokanastha", "label": "96K Kokanastha" },
    { "value": "Ad Dharmi", "label": "Ad Dharmi" },
    { "value": "Adi Andhra", "label": "Adi Andhra" },
    { "value": "Adi Dravida", "label": "Adi Dravida" },
    { "value": "Adi Karnataka", "label": "Adi Karnataka" },
    { "value": "Agnikula Kshatriya", "label": "Agnikula Kshatriya" },
    { "value": "Agri", "label": "Agri" },
    { "value": "Ahom", "label": "Ahom" },
    { "value": "Ambalavasi", "label": "Ambalavasi" },
    { "value": "Amil Sindhi", "label": "Amil Sindhi" },
    { "value": "Anglo Indian", "label": "Anglo Indian" },
    { "value": "Araya", "label": "Araya" },
    { "value": "Arekatica", "label": "Arekatica" },
    { "value": "Arunthathiyar", "label": "Arunthathiyar" },
    { "value": "Arya Vysya", "label": "Arya Vysya" },
    { "value": "Aryasamaj", "label": "Aryasamaj" },
    { "value": "Ayyaraka", "label": "Ayyaraka" },
    { "value": "Badaga", "label": "Badaga" },
    { "value": "Bahi", "label": "Bahi" },
    { "value": "Baibhand Sindhi", "label": "Baibhand Sindhi" },
    { "value": "Baidya", "label": "Baidya" },
    { "value": "Baishnab", "label": "Baishnab" },
    { "value": "Baishya", "label": "Baishya" },
    { "value": "Balija", "label": "Balija" },
    { "value": "Banik", "label": "Banik" },
    { "value": "Baniya", "label": "Baniya" },
    { "value": "Banjara", "label": "Banjara" },
    { "value": "Barai", "label": "Barai" },
    { "value": "Bari", "label": "Bari" },
    { "value": "Barujibi", "label": "Barujibi" },
    { "value": "Besta", "label": "Besta" },
    { "value": "Bhandari", "label": "Bhandari" },
    { "value": "Bhanusali Sindhi", "label": "Bhanusali Sindhi" },
    { "value": "Bhatia", "label": "Bhatia" },
    { "value": "Bhatia Sindhi", "label": "Bhatia Sindhi" },
    { "value": "Bhatraju", "label": "Bhatraju" },
    { "value": "Bhavasar Kshatriya", "label": "Bhavasar Kshatriya" },
    { "value": "Bhavsar", "label": "Bhavsar" },
    { "value": "Bhovi", "label": "Bhovi" },
    { "value": "Bhumihar Brahmin", "label": "Bhumihar Brahmin" },
    { "value": "Billava", "label": "Billava" },
    { "value": "Bishnoi/Vishnoi", "label": "Bishnoi/Vishnoi" },
    { "value": "Boyer", "label": "Boyer" },
    { "value": "Brahmbatt", "label": "Brahmbatt" },
    { "value": "Bunt", "label": "Bunt" },
    { "value": "Bunt (Shetty)", "label": "Bunt (Shetty)" },
    { "value": "Chambhar", "label": "Chambhar" },
  { "value": "Chandraseniya Kayastha Prab", "label": "Chandraseniya Kayastha Prab" },
  { "value": "Chandravanshi Kahar", "label": "Chandravanshi Kahar" },
  { "value": "Chasa", "label": "Chasa" },
  { "value": "Chattada Sri Vaishnava", "label": "Chattada Sri Vaishnava" },
  { "value": "Chaudary", "label": "Chaudary" },
  { "value": "Chaurasia", "label": "Chaurasia" },
  { "value": "Chettiar", "label": "Chettiar" },
  { "value": "Chhapru Sindhi", "label": "Chhapru Sindhi" },
  { "value": "Chhetri", "label": "Chhetri" },
  { "value": "Chippolu (Mera)", "label": "Chippolu (Mera)" },
  { "value": "CKP", "label": "CKP" },
  { "value": "Coorgi", "label": "Coorgi" },
  { "value": "Dadu Sindhi", "label": "Dadu Sindhi" },
  { "value": "Darji", "label": "Darji" },
  { "value": "Dawoodi Bhora", "label": "Dawoodi Bhora" },
  { "value": "Devadiga", "label": "Devadiga" },
  { "value": "Devandra Kula Vellalar", "label": "Devandra Kula Vellalar" },
  { "value": "Devang Koshthi", "label": "Devang Koshthi" },
  { "value": "Devanga", "label": "Devanga" },
  { "value": "Devar/Thevar/Mukkulathor", "label": "Devar/Thevar/Mukkulathor" },
  { "value": "Devendra Kula Vellalar", "label": "Devendra Kula Vellalar" },
  { "value": "Dhaneshawat Vaish", "label": "Dhaneshawat Vaish" },
  { "value": "Dhangar", "label": "Dhangar" },
  { "value": "Dheevara", "label": "Dheevara" },
  { "value": "Dhiman", "label": "Dhiman" },
  { "value": "Dhoba", "label": "Dhoba" },
  { "value": "Dhobi", "label": "Dhobi" },
  { "value": "Dumal", "label": "Dumal" },
  { "value": "Dusadh (Paswan)", "label": "Dusadh (Paswan)" },
  { "value": "Ediga", "label": "Ediga" },
  { "value": "Ezhava", "label": "Ezhava" },
  { "value": "Ezhuthachan", "label": "Ezhuthachan" },
  { "value": "Gabit", "label": "Gabit" },
  { "value": "Ganda", "label": "Ganda" },
  { "value": "Gandla", "label": "Gandla" },
  { "value": "Ganiga", "label": "Ganiga" },
  { "value": "Ganigashetty", "label": "Ganigashetty" },
  { "value": "Garhwali", "label": "Garhwali" },
  { "value": "Garhwali Rajput", "label": "Garhwali Rajput" },
  { "value": "Gavali", "label": "Gavali" },
  { "value": "Gavara", "label": "Gavara" },
  { "value": "Gawali", "label": "Gawali" },
  { "value": "Ghisadi", "label": "Ghisadi" },
  { "value": "Ghumar", "label": "Ghumar" },
  { "value": "Goala", "label": "Goala" },
  { "value": "Goan", "label": "Goan" },
  { "value": "Gomantak", "label": "Gomantak" },
  { "value": "Gomantak Maratha", "label": "Gomantak Maratha" },
  { "value": "Gondhali", "label": "Gondhali" },
  { "value": "Goswami", "label": "Goswami" },
  { "value": "Goswami/Gosavi Brahmin", "label": "Goswami/Gosavi Brahmin" },
  { "value": "Goud", "label": "Goud" },
  { "value": "Gounder", "label": "Gounder" },
  { "value": "Gowda", "label": "Gowda" },
  { "value": "Gramani", "label": "Gramani" },
  { "value": "Gudia", "label": "Gudia" },
  { "value": "Gujarati", "label": "Gujarati" },
  { "value": "Gujjar", "label": "Gujjar" },
  { "value": "Guptan", "label": "Guptan" },
  { "value": "Gurav", "label": "Gurav" },
  { "value": "Gurjar", "label": "Gurjar" },
  { "value": "Hegde", "label": "Hegde" },
  { "value": "Himachali", "label": "Himachali" },
  { "value": "Hindu-Others", "label": "Hindu-Others" },
  { "value": "Hugar (Jeer)", "label": "Hugar (Jeer)" },
  { "value": "Hyderabadi Sindhi", "label": "Hyderabadi Sindhi" },
  { "value": "Intercaste", "label": "Intercaste" },
  { "value": "Irani", "label": "Irani" },
  { "value": "Iyengar", "label": "Iyengar" },
  { "value": "Iyer", "label": "Iyer" },
  { "value": "Jaalari", "label": "Jaalari" },
  { "value": "Jaiswal", "label": "Jaiswal" },
  { "value": "Jandra", "label": "Jandra" },
  { "value": "Jangam", "label": "Jangam" },
  { "value": "Jatav", "label": "Jatav" },
  { "value": "Jeer", "label": "Jeer" },
  { "value": "Jogi (Nath)", "label": "Jogi (Nath)" },
  { "value": "Kachara", "label": "Kachara" },
  { "value": "Kadava Patel", "label": "Kadava Patel" },
  { "value": "Kahar", "label": "Kahar" },
  { "value": "Kaibarta", "label": "Kaibarta" },
  { "value": "Kalal", "label": "Kalal" },
  { "value": "Kalar", "label": "Kalar" },
  { "value": "Kalinga", "label": "Kalinga" },
  { "value": "Kalinga Vysya", "label": "Kalinga Vysya" },
  { "value": "Kalita", "label": "Kalita" },
  { "value": "Kalwar", "label": "Kalwar" },
  { "value": "Kamboj", "label": "Kamboj" },
  { "value": "Kamma", "label": "Kamma" },
  { "value": "Kannada Mogaveera", "label": "Kannada Mogaveera" },
  { "value": "Kansari", "label": "Kansari" },
  { "value": "Kapu", "label": "Kapu" },
  { "value": "Kapu Naidu", "label": "Kapu Naidu" },
  { "value": "Karana", "label": "Karana" },
  { "value": "Karmakar", "label": "Karmakar" },
  { "value": "Karuneegar", "label": "Karuneegar" },
  { "value": "Karuneekar", "label": "Karuneekar" },
  { "value": "Kasar", "label": "Kasar" },
  { "value": "Kashyap", "label": "Kashyap" },
  { "value": "Katiya", "label": "Katiya" },
  { "value": "Keralite", "label": "Keralite" },
  { "value": "Khandayat", "label": "Khandayat" },
  { "value": "Khandelwal", "label": "Khandelwal" },
  { "value": "Kharwar", "label": "Kharwar" },
  { "value": "Khatik", "label": "Khatik" },
  { "value": "Khukhrain", "label": "Khukhrain" },
  { "value": "Koiri", "label": "Koiri" },
  { "value": "Kokanastha Maratha", "label": "Kokanastha Maratha" },
  { "value": "Koli", "label": "Koli" },
  { "value": "Koli Mahadev", "label": "Koli Mahadev" },
  { "value": "Kongu Vellala Gounder", "label": "Kongu Vellala Gounder" },
  { "value": "Konkani", "label": "Konkani" },
  { "value": "Kori", "label": "Kori" },
  { "value": "Koshti", "label": "Koshti" },
  { "value": "Kudumbi", "label": "Kudumbi" },
  { "value": "Kulal", "label": "Kulal" },
  { "value": "Kulalar", "label": "Kulalar" },
  { "value": "Kulita", "label": "Kulita" },
  { "value": "Kumaoni", "label": "Kumaoni" },
  { "value": "Kumaoni Rajput", "label": "Kumaoni Rajput" },
  { "value": "Kumawat", "label": "Kumawat" },
  { "value": "Kumbara", "label": "Kumbara" },
  { "value": "Kumbhakar", "label": "Kumbhakar" },
  { "value": "Kumbhar", "label": "Kumbhar" },
  { "value": "Kumhar", "label": "Kumhar" },
  { "value": "Kummari", "label": "Kummari" },
  { "value": "Kunbi", "label": "Kunbi" },
  { "value": "Kuravan", "label": "Kuravan" },
  { "value": "Kurmi", "label": "Kurmi" },
  { "value": "Kurmi Kshatriya", "label": "Kurmi Kshatriya" },
  { "value": "Kuruba", "label": "Kuruba" },
  { "value": "Kuruhina Shetty", "label": "Kuruhina Shetty" },
  { "value": "Kurumbar", "label": "Kurumbar" },
  { "value": "Kushwaha", "label": "Kushwaha" },
  { "value": "Kushwaha (Koiri)", "label": "Kushwaha (Koiri)" },
  { "value": "Kutchi", "label": "Kutchi" },
  { "value": "Lambadi", "label": "Lambadi" },
  { "value": "Lambani", "label": "Lambani" },
  { "value": "Larai Sindhi", "label": "Larai Sindhi" },
  { "value": "Larkana Sindhi", "label": "Larkana Sindhi" },
  { "value": "Leva patel", "label": "Leva patel" },
  { "value": "Leva Patidar", "label": "Leva Patidar" },
  { "value": "Leva patil", "label": "Leva patil" },
  { "value": "Lingayath", "label": "Lingayath" },
  { "value": "Lodhi Rajput", "label": "Lodhi Rajput" },
  { "value": "Lohana", "label": "Lohana" },
  { "value": "Lohana Sindhi", "label": "Lohana Sindhi" },
  { "value": "Lohar", "label": "Lohar" },
  { "value": "Lubana", "label": "Lubana" },
  { "value": "Madiga", "label": "Madiga" },
  { "value": "Mahajan", "label": "Mahajan" },
  { "value": "Mahar", "label": "Mahar" },
  { "value": "Maharashtrian", "label": "Maharashtrian" },
  { "value": "Mahendra", "label": "Mahendra" },
  { "value": "Maheshwari", "label": "Maheshwari" },
  { "value": "Mahishya", "label": "Mahishya" },
  { "value": "Mahisya", "label": "Mahisya" },
  { "value": "Majabi", "label": "Majabi" },
  { "value": "Mala", "label": "Mala" },
  { "value": "Malayalee", "label": "Malayalee" },
  { "value": "Malayalee Namboodiri", "label": "Malayalee Namboodiri" },
  { "value": "Mali", "label": "Mali" },
  { "value": "Mallah", "label": "Mallah" },
  { "value": "Mangalorean", "label": "Mangalorean" },
  { "value": "Manipuri", "label": "Manipuri" },
  { "value": "Mannuru Kapu", "label": "Mannuru Kapu" },
  { "value": "Mapila", "label": "Mapila" },
  { "value": "Maruthuvar", "label": "Maruthuvar" },
  { "value": "Marvar", "label": "Marvar" },
  { "value": "Marwari", "label": "Marwari" },
  { "value": "Matang", "label": "Matang" },
  { "value": "Mathur", "label": "Mathur" },
  { "value": "Maurya", "label": "Maurya" },
  { "value": "Meena", "label": "Meena" },
  { "value": "Meenavar", "label": "Meenavar" },
  { "value": "Mehra", "label": "Mehra" },
  { "value": "Menon", "label": "Menon" },
  { "value": "Mera", "label": "Mera" },
  { "value": "Meru", "label": "Meru" },
  { "value": "Meru Darji", "label": "Meru Darji" },
  { "value": "Mochi", "label": "Mochi" },
  { "value": "Modak", "label": "Modak" },
  { "value": "Mogaveera", "label": "Mogaveera" },
  { "value": "Monchi", "label": "Monchi" },
  { "value": "Mudaliar", "label": "Mudaliar" },
  { "value": "Mudaliar - Senguntha", "label": "Mudaliar - Senguntha" },
  { "value": "Mudaliar Arcot", "label": "Mudaliar Arcot" },
  { "value": "Mudaliar Saiva", "label": "Mudaliar Saiva" },
  { "value": "Mudaliyar", "label": "Mudaliyar" },
  { "value": "Mudiraj", "label": "Mudiraj" },
  { "value": "Mukkulathor", "label": "Mukkulathor" },
  { "value": "Mukulathur", "label": "Mukulathur" },
  { "value": "Munnuru Kapu", "label": "Munnuru Kapu" },
  { "value": "Muthuraja", "label": "Muthuraja" },
  { "value": "Naagavamsam", "label": "Naagavamsam" },
  { "value": "Nadar", "label": "Nadar" },
  { "value": "Nagaralu", "label": "Nagaralu" },
  { "value": "Nai", "label": "Nai" },
  { "value": "Naicker", "label": "Naicker" },
  { "value": "Naidu", "label": "Naidu" },
  { "value": "Naik", "label": "Naik" },
  { "value": "Naik/Nayaka", "label": "Naik/Nayaka" },
  { "value": "Naika", "label": "Naika" },
  { "value": "Nair Vaniya", "label": "Nair Vaniya" },
  { "value": "Nair Vilakkithala", "label": "Nair Vilakkithala" },
  { "value": "Namasudra", "label": "Namasudra" },
  { "value": "Nambiar", "label": "Nambiar" },
  { "value": "Namboodiri", "label": "Namboodiri" },
  { "value": "Namosudra", "label": "Namosudra" },
  { "value": "Napit", "label": "Napit" },
  { "value": "Nath", "label": "Nath" },
  { "value": "Nayaka", "label": "Nayaka" },
  { "value": "Neeli", "label": "Neeli" },
  { "value": "Nepali", "label": "Nepali" },
  { "value": "Nhavi", "label": "Nhavi" },
  { "value": "OBC/Barber/Naayee", "label": "OBC/Barber/Naayee" },
  { "value": "Oriya", "label": "Oriya" },
  { "value": "Oswal", "label": "Oswal" },
  { "value": "Otari", "label": "Otari" },
  { "value": "Padmasali", "label": "Padmasali" },
  { "value": "Padmashali", "label": "Padmashali" },
  { "value": "Pal", "label": "Pal" },
  { "value": "Panchal", "label": "Panchal" },
  { "value": "Pandaram", "label": "Pandaram" },
  { "value": "Panicker", "label": "Panicker" },
  { "value": "Parkava Kulam", "label": "Parkava Kulam" },
  { "value": "Parsi", "label": "Parsi" },
  { "value": "Partraj", "label": "Partraj" },
  { "value": "Pasi", "label": "Pasi" },
  { "value": "Paswan", "label": "Paswan" },
  { "value": "Patel", "label": "Patel" },
  { "value": "Patel Desai", "label": "Patel Desai" },
  { "value": "Patel Dodia", "label": "Patel Dodia" },
  { "value": "Patel Kadva", "label": "Patel Kadva" },
  { "value": "Patel Leva", "label": "Patel Leva" },
  { "value": "Pathare Prabhu", "label": "Pathare Prabhu" },
  { "value": "Patil", "label": "Patil" },
  { "value": "Patil Leva", "label": "Patil Leva" },
  { "value": "Patnaick", "label": "Patnaick" },
  { "value": "Patnaick/Sistakaranam", "label": "Patnaick/Sistakaranam" },
  { "value": "Patra", "label": "Patra" },
  { "value": "Perika", "label": "Perika" },
  { "value": "Pillai", "label": "Pillai" },
  { "value": "Poosala", "label": "Poosala" },
  { "value": "Porwal", "label": "Porwal" },
  { "value": "Prajapati", "label": "Prajapati" },
  { "value": "Raigar", "label": "Raigar" },
  { "value": "Rajaka", "label": "Rajaka" },
  { "value": "Rajastani", "label": "Rajastani" },
  { "value": "Rajbhar", "label": "Rajbhar" },
  { "value": "Rajbonshi", "label": "Rajbonshi" },
  { "value": "Rajput Rohella/Tank", "label": "Rajput Rohella/Tank" },
  { "value": "Ramdasia", "label": "Ramdasia" },
  { "value": "Ramgariah", "label": "Ramgariah" },
  { "value": "Ravidasia", "label": "Ravidasia" },
  { "value": "Rawat", "label": "Rawat" },
  { "value": "Reddy", "label": "Reddy" },
  { "value": "Relli", "label": "Relli" },
  { "value": "Rohiri Sindhi", "label": "Rohiri Sindhi" },
  { "value": "Sadgope", "label": "Sadgope" },
  { "value": "Saha", "label": "Saha" },
  { "value": "Sahiti Sindhi", "label": "Sahiti Sindhi" },
  { "value": "Sahu", "label": "Sahu" },
  { "value": "Saini", "label": "Saini" },
  { "value": "Sakkhar Sindhi", "label": "Sakkhar Sindhi" },
  { "value": "Saliya", "label": "Saliya" },
  { "value": "Saurashtra", "label": "Saurashtra" },
  { "value": "Savji", "label": "Savji" },
  { "value": "Scheduled Tribe", "label": "Scheduled Tribe" },
  { "value": "Sehwani Sindhi", "label": "Sehwani Sindhi" },
  { "value": "Senai Thalaivar", "label": "Senai Thalaivar" },
  { "value": "Senguntha Mudaliyar", "label": "Senguntha Mudaliyar" },
  { "value": "Sepahia", "label": "Sepahia" },
  { "value": "Setti Balija", "label": "Setti Balija" },
  { "value": "Settibalija", "label": "Settibalija" },
  { "value": "Shah", "label": "Shah" },
  { "value": "Shetty", "label": "Shetty" },
  { "value": "Shikarpuri Sindhi", "label": "Shikarpuri Sindhi" },
  { "value": "Shimpi", "label": "Shimpi" },
  { "value": "Sindhi", "label": "Sindhi" },
  { "value": "Sindhi-Amil", "label": "Sindhi-Amil" },
  { "value": "Sindhi-Baibhand", "label": "Sindhi-Baibhand" },
  { "value": "Sindhi-Bhanusali", "label": "Sindhi-Bhanusali" },
  { "value": "Sindhi-Bhatia", "label": "Sindhi-Bhatia" },
  { "value": "Sindhi-Chhapru", "label": "Sindhi-Chhapru" },
  { "value": "Sindhi-Dadu", "label": "Sindhi-Dadu" },
  { "value": "Sindhi-Hyderabadi", "label": "Sindhi-Hyderabadi" },
  { "value": "Sindhi-Larai", "label": "Sindhi-Larai" },
  { "value": "Sindhi-Larkana", "label": "Sindhi-Larkana" },
  { "value": "Sindhi-Lohana", "label": "Sindhi-Lohana" },
  { "value": "Sindhi-Rohiri", "label": "Sindhi-Rohiri" },
  { "value": "Sindhi-Sahiti", "label": "Sindhi-Sahiti" },
  { "value": "Sindhi-Sakkhar", "label": "Sindhi-Sakkhar" },
  { "value": "Sindhi-Sehwani", "label": "Sindhi-Sehwani" },
  { "value": "Sindhi-Shikarpuri", "label": "Sindhi-Shikarpuri" },
  { "value": "Sindhi-Thatai", "label": "Sindhi-Thatai" },
  { "value": "SKP", "label": "SKP" },
  { "value": "Somvanshi", "label": "Somvanshi" },
  { "value": "Sonar", "label": "Sonar" },
  { "value": "Soni", "label": "Soni" },
  { "value": "Sourashtra", "label": "Sourashtra" },
  { "value": "Sozhiya Vellalar", "label": "Sozhiya Vellalar" },
  { "value": "Srisayana", "label": "Srisayana" },
  { "value": "Srisayani", "label": "Srisayani" },
  { "value": "Sugali (Naika)", "label": "Sugali (Naika)" },
  { "value": "Sunari", "label": "Sunari" },
  { "value": "Sundhi", "label": "Sundhi" },
  { "value": "Sutar", "label": "Sutar" },
  { "value": "Suthar", "label": "Suthar" },
  { "value": "Swakula Sali", "label": "Swakula Sali" },
  { "value": "Swarnakar", "label": "Swarnakar" },
  { "value": "Tamboli", "label": "Tamboli" },
  { "value": "Tamil", "label": "Tamil" },
  { "value": "Tamil Yadava", "label": "Tamil Yadava" },
  { "value": "Tanti", "label": "Tanti" },
  { "value": "Tantubai", "label": "Tantubai" },
  { "value": "Tantuway", "label": "Tantuway" },
  { "value": "Telaga", "label": "Telaga" },
  { "value": "Teli", "label": "Teli" },
  { "value": "Telugu", "label": "Telugu" },
  { "value": "Thakkar", "label": "Thakkar" },
  { "value": "Thakore", "label": "Thakore" },
  { "value": "Thakur", "label": "Thakur" },
  { "value": "Thatai Sindhi", "label": "Thatai Sindhi" },
  { "value": "Thigala", "label": "Thigala" },
  { "value": "Thiyya", "label": "Thiyya" },
  { "value": "Tili", "label": "Tili" },
  { "value": "Togata", "label": "Togata" },
  { "value": "Tonk Kshatriya", "label": "Tonk Kshatriya" },
  { "value": "Turupu Kapu", "label": "Turupu Kapu" },
  { "value": "Udayar", "label": "Udayar" },
  { "value": "Uppara", "label": "Uppara" },
  { "value": "Urali Gounder", "label": "Urali Gounder" },
  { "value": "Urs", "label": "Urs" },
  { "value": "Vada Balija", "label": "Vada Balija" },
  { "value": "Vadagalai", "label": "Vadagalai" },
  { "value": "Vaddera", "label": "Vaddera" },
  { "value": "Vaish", "label": "Vaish" },
  { "value": "Vaishnav", "label": "Vaishnav" },
  { "value": "Vaishnav Bhatia", "label": "Vaishnav Bhatia" },
  { "value": "Vaishnav Vania", "label": "Vaishnav Vania" },
  { "value": "Vaishnav Vanik", "label": "Vaishnav Vanik" },
  { "value": "Vaishnava", "label": "Vaishnava" },
  { "value": "Vaishya", "label": "Vaishya" },
  { "value": "Vaishya Vani", "label": "Vaishya Vani" },
  { "value": "Valluvan", "label": "Valluvan" },
  { "value": "Valmiki", "label": "Valmiki" },
  { "value": "Vania", "label": "Vania" },
  { "value": "Vaniya", "label": "Vaniya" },
  { "value": "Vanjara", "label": "Vanjara" },
  { "value": "Vanjari", "label": "Vanjari" },
  { "value": "Vankar", "label": "Vankar" },
  { "value": "Vannar", "label": "Vannar" },
  { "value": "Vannia Kula Kshatriyar", "label": "Vannia Kula Kshatriyar" },
  { "value": "Vanniyar", "label": "Vanniyar" },
  { "value": "Variar", "label": "Variar" },
  { "value": "Varshney", "label": "Varshney" },
  { "value": "Veera Saivam", "label": "Veera Saivam" },
  { "value": "Veerashaiva", "label": "Veerashaiva" },
  { "value": "Velaan/Vellalar", "label": "Velaan/Vellalar" },
  { "value": "Velama", "label": "Velama" },
  { "value": "Vellalar", "label": "Vellalar" },
  { "value": "Vellalar Devandra Kula", "label": "Vellalar Devandra Kula" },
  { "value": "Veluthedathu Nair", "label": "Veluthedathu Nair" },
  { "value": "Vettuva Gounder", "label": "Vettuva Gounder" },
  { "value": "Vilakkithala Nair", "label": "Vilakkithala Nair" },
  { "value": "Vishwakarma", "label": "Vishwakarma" },
  { "value": "Viswabrahmin", "label": "Viswabrahmin" },
  { "value": "Vokkaliga", "label": "Vokkaliga" },
  { "value": "Vysya", "label": "Vysya" },
  { "value": "Yellapu", "label": "Yellapu" },
  { "value": "Sonam", "label": "Sonam" },
  { "value": "Maratha", "label": "Maratha" }
  ];



  
function Prefrencedata() {


  return (
    <div>
      
    </div>
  )
}

export default Prefrencedata


//  data shifted to this file imported to next 