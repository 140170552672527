import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import "./IdSearchh.css";

const IdSearch = () => {
  let [change, setChange] = useState('');
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  function handleChange(e) {
    setChange(e.target.value)
    console.log(change);
  }
  
  function handleSubmit(e) {
    if (change.length < 2) alert("Entert an ID")
  }

  return (
    <div className="id-search-container">

      <button onClick={goBack} className='back-button-pay'><i class="ri-arrow-left-line"></i></button>

      <div>
        <h1 className="id-search-title">ID Search</h1>
        <form action="#" className="id-search-from-wrapper">
          <input onChange={e => handleChange(e)} value={change ? change : ''} className="id-search-input" type="text" placeholder="Search ID" />
          <button onClick={e => handleSubmit(e)} className="id-search-button">Search</button>
        </form>
      </div>
    </div>
  );
};

export default IdSearch;
