import React from "react";
import { Link } from "react-router-dom";
import "./HomeAboutStyle.css";

const HomeAbout = () => {
  return (
    <>
      <section
        className="about-sectionha"
        style={{ marginTop: "-10px", width: "100%" }}
      >
        <div className="anim-icons full-width">
          <span
            className="icon icon-dots wow fadeInleft animated"
            style={{ visibility: "visible" }}
          />
          <span
            className="icon icon-circle-1 wow zoomIn animated"
            style={{ visibility: "visible", animationName: "zoomIn" }}
          />
        </div>
        <div
          className="auto-containerha"
          style={{ width: "100%", padding: "0" }}
        >
          <div
            className="rowha"
            style={{ width: "100%", padding: "0", display: "flex" }}
          >
            {/* Content Column */}
            <div
              className="content-columnha col-lg-6 col-md-6 col-sm-6"
              style={{ width: "100%", padding: "20px" }}
            >
              <div className="inner-columnha" style={{}}>
                <div
                  className="sec-titleha"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                >
                  <div
                    className="titleha"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                  >
                    ABOUT US
                  </div>
                  <h2
                    className="homeAbout-title"
                    style={{
                      width: "100%",
                      margin: "0px",
                      padding: "0",
                      fontSize: "25px",
                    }}
                  >
                    Welcome To The made4eachother.hola9.com
                  </h2>
                  <div
                    className="textha"
                    style={{
                      width: "100%",
                      marginBottom: "10px",
                      padding: "0",
                      fontWeight: "700",
                    }}
                  >
                    Ready Matrimonial provides excellent Matrimonial profile
                    matches in at a reasonable price. It is also available in
                    Android &amp; iOs versions.
                  </div>
                </div>
                <ul className="list-style-oneha" style={{ width: "100%" }}>
                  <li>Profile with fully updated details</li>
                  <li>Multiple &amp; easy way to contact</li>
                  <li>Automatic Matching System</li>
                  <li>Easy &amp; flexible navigations</li>
                </ul>

                <div className="btn-boxha">
                  <Link
                    to="signup"
                    className="theme-btnha btnha btn-style-threeha"
                    style={{ color: "white" }}
                  >
                    Register Now
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="image-column col-lg-6 col-md-6 col-sm-6 d-sm-block"
              style={{ padding: "10px", marginTop: "-120px" }}
            >
              <div className="image-box">
                <figure
                  className="image wow fadeIn animated"
                  style={{
                    visibility: "visible",
                    animationName: "fadeIn",
                    padding: "100px",
                    marginRight: "90px",
                  }}
                >
                  <img
                    className=" d-lg-block d-md-block"
                    src="https://www.partneralbum.com/success_story/1577353520-zPsfobb-I5HN15z.jpg"
                    alt=""
                    style={{
                      Width: "100%",
                      border: "20px solid orange",
                      marginRight: "-30px",
                      height: "460px",
                    }}
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default HomeAbout;
