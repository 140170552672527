import React from 'react'
import "./Newchangepassword.css";
import { useNavigate } from 'react-router-dom';


const Newchangepassword = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
<>

    <button onClick={goBack} className='back-button-pay'><i class="ri-arrow-left-line"></i></button>

    <div class="container-newpass">

    <div class="heading">
    <h3>Change Password</h3>
    </div>

    <div className="change-password-container">
      <form className="change-password-form">
        <div className="change-password-label">
          <label htmlFor="new-password">Change Password:</label>
          <input type="password" id="new-password" name="new-password" placeholder="New Password" className="password-input" required />
        </div>
        <div className="change-password-label">
          <label htmlFor="confirm-password">Confirm Password:</label>
          <input type="password" id="confirm-password" name="confirm-password" placeholder="Confirm Password" className="password-input" required />
        </div>
        <button type="submit" className="btn-change">Submit</button>
      </form>
    </div>

    </div>
    </> 
  )
}

export default Newchangepassword