import React, { useState, useEffect } from 'react';
import {
  BsFillPencilFill,

} from "react-icons/bs";

const NewEdu = () => {
  const [dataedu, setDataedu] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [qualification, setQualification] = useState("");
  const [occupation, setOccupation] = useState("");
  const [occupation_details, setOccupationDetails] = useState("");
  const [annual_income, setAnnualIncome] = useState("");
  const [employed_in, setEmployedIn] = useState("");
  const [special_cases, setSpecialCases] = useState("");
  const [working_location, setWorkingLocation] = useState("");
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://matrimonyadmin.hola9.com/api/EducationDetails/educationDetail/`);
        const jsonData = await response.json();
        setDataedu(jsonData);
        setIsLoading(false);
        if (jsonData.length > 0) {
          const latestData = jsonData[jsonData.length - 1];
          setSelectedId(latestData.id);
          setQualification(latestData.qualification);
          setOccupation(latestData.occupation);
          setOccupationDetails(latestData.occupation_details);
          setAnnualIncome(latestData.annual_income);
          setEmployedIn(latestData.employed_in);
          setSpecialCases(latestData.special_cases);
          setWorkingLocation(latestData.working_location);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://matrimonyadmin.hola9.com/api/EducationDetails/educationDetail/${selectedId}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: selectedId,
          qualification,
          occupation,
          occupation_details,
          annual_income,
          employed_in,
          special_cases,
          working_location
        }),
      });
      if (response.ok) {
        setDataedu(prevData => {
          const newData = [...prevData];
          const index = newData.findIndex(item => item.id === selectedId);
          if (index !== -1) {
            newData[index] = {
              id: selectedId,
              qualification,
              occupation,
              occupation_details,
              annual_income,
              employed_in,
              special_cases,
              working_location
            };
          }
          return newData;
        });
        closeModal();
      } else {
        console.error('Failed to update data:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {/* <div>NewEdu</div> */}
      <div
        className="feature-block-twop col-lg-4p col-md-6p col-sm-6p wow fadeInUp animated"
        style={{ visibility: "visible", animationName: "fadeInUp" }}
      >
        {dataedu.length > 0 && (
          <div className="inner-boxp">
            <div className="icon-boxp">
              <img
                src="https://www.matrimonysoftware.in/icon/education.png"
                alt=""
              />
            </div>
            <h4>
              <a href="education">
                Education Details{" "}
                <i style={{ fontSize: 24 }} className="fa">
                  
                </i>
              </a>
              <BsFillPencilFill className="editpro"   onClick={openModal}/>
            </h4>

            <div className="textpro"><span>Qualification:</span> {qualification}</div>
            <div className="textpro"><span>Occupation:</span> {occupation}</div>
            <div className="textpro"><span>Occupation Details:</span> {occupation_details}</div>
            <div className="textpro"><span>Annual Income:</span> {annual_income}</div>
            <div className="textpro"><span>Employed In:</span> {employed_in}</div>
            <div className="textpro"><span>Working Location:</span> {working_location}</div>
            <div className="textpro"><span>Special Cases:</span> {special_cases}</div>

            {/* <button className="btn btn-primary" onClick={openModal}>Edit</button> */}
            
          </div>
        )}
      </div>

      <div className={`modal ${isModalOpen ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: isModalOpen ? 'block' : 'none' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Details</h5>
              <button type="button" className="close" onClick={closeModal}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="qualification"  style={{color:"white"}}>Qualification:</label>
                  <input type="text" className="form-control" id="qualification" value={qualification} onChange={(e) => setQualification(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="occupation"  style={{color:"white"}}>Occupation:</label>
                  <input type="text" className="form-control" id="occupation" value={occupation} onChange={(e) => setOccupation(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="occupation_details"  style={{color:"white"}}>Occupation Details:</label>
                  <input type="text" className="form-control" id="occupation_details" value={occupation_details} onChange={(e) => setOccupationDetails(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="annual_income"  style={{color:"white"}}>Annual Income:</label>
                  <input type="text" className="form-control" id="annual_income" value={annual_income} onChange={(e) => setAnnualIncome(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="employed_in"  style={{color:"white"}}>Employed In:</label>
                  <input type="text" className="form-control" id="employed_in" value={employed_in} onChange={(e) => setEmployedIn(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="working_location"  style={{color:"white"}}>Working Location:</label>
                  <input type="text" className="form-control" id="working_location" value={working_location} onChange={(e) => setWorkingLocation(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="special_cases"style={{color:"white"}} >Special Cases:</label>
                  <input type="text" className="form-control" id="special_cases" value={special_cases} onChange={(e) => setSpecialCases(e.target.value)} />
                 
                </div>
                <div className="form-group">
                <button type="submit" >Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewEdu;
