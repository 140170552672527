export const initialdata = {
  moon_sign: "",
  star: "",
  gotra: "",
  manglik: "",
  shani: "",
  hororscope_match: "",
  place_of_birth: "",
  place_of_country: "",
  hours: "",
  minitues: "",
  seconds: "",
  am_pm: "",
};
export const casteOptions = [
  { value: "", label: "Select Star" },
  { value: "Ardra/Thiruvathira", label: "Ardra/Thiruvathira" },
  { value: "Ashlesha/Ayilyam", label: "Ashlesha/Ayilyam" },
  { value: "Ashwini/Ashwathi", label: "Ashwini/Ashwathi" },
  { value: "Bharani", label: "Bharani" },
  { value: "Chitra/Chitha", label: "Chitra/Chitha" },
  { value: "Dhanista/Avittam", label: "Dhanista/Avittam" },
  { value: "Hastha/Atham", label: "Hastha/Atham" },
  { value: "Jyesta / Kettai", label: "Jyesta / Kettai" },
  { value: "Krithika/Karthika", label: "Krithika/Karthika" },
  { value: "Makha/Magam", label: "Makha/Magam" },
  { value: "Moolam/Moola", label: "Moolam/Moola" },
  { value: "Mrigasira/Makayiram", label: "Mrigasira/Makayiram" },
  { value: "Poorvabadrapada/Puratathi", label: "Poorvabadrapada/Puratathi" },
  { value: "Poorvapalguni/Puram/Pubbhe", label: "Poorvapalguni/Puram/Pubbhe" },
  { value: "Poorvashada/Pooradam", label: "Poorvashada/Pooradam" },
  { value: "Punarvasu/Punarpusam", label: "Punarvasu/Punarpusam" },
  { value: "Pushya/Poosam/Pooyam", label: "Pushya/Poosam/Pooyam" },
  { value: "Revathi", label: "Revathi" },
  { value: "Rohini", label: "Rohini" },
  {
    value: "Shatataraka/Sadayam/Satabishek",
    label: "Shatataraka/Sadayam/Satabishek",
  },
  { value: "Shravan/Thiruvonam", label: "Shravan/Thiruvonam" },
  { value: "Swati/Chothi", label: "Swati/Chothi" },
  { value: "Uttarabadrapada/Uthratadhi", label: "Uttarabadrapada/Uthratadhi" },
  { value: "Uttarapalguni/Uthram", label: "Uttarapalguni/Uthram" },
  { value: "Uttarashada/Uthradam", label: "Uttarashada/Uthradam" },
  { value: "Vishaka/Vishakam", label: "Vishaka/Vishakam" },
  { value: "Does not matter", label: "Does not matter" },
];
export const moonsignOptions = [
  { value: "", label: "Select Moonsign" },
  { value: "Mesh (Aries)", label: "Mesh (Aries)" },
  { value: "Vrishabh (Taurus)", label: "Vrishabh (Taurus)" },
  { value: "Mithun (Gemini)", label: "Mithun (Gemini)" },
  { value: "Karka (Cancer)", label: "Karka (Cancer)" },
  { value: "Sinha (Leo)", label: "Sinha (Leo)" },
  { value: "Kanya (Virgo)", label: "Kanya (Virgo)" },
  { value: "Tula (Libra)", label: "Tula (Libra)" },
  { value: "Vrischika (Scorpio)", label: "Vrischika (Scorpio)" },
  { value: "Makar (Capricorn)", label: "Makar (Capricorn)" },
  { value: "Kumbha (Aquarious)", label: "Kumbha (Aquarious)" },
  { value: "Meen (Pisces)", label: "Meen (Pisces)" },
  { value: "Does not matter", label: "Does not matter" },
];
export const mangalikOptions = [
  { value: "", label: "Select Mangalik" },
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
  { value: "Not Applicable", label: "Not Applicable" },
];
export const shaniOptions = [
  { value: "", label: "Select Shani" },
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
  { value: "Not Applicable", label: "Not Applicable" },
];
export const hoursOptions = [
  { value: "", label: "Hours" },
  ...Array.from({ length: 12 }, (_, i) => ({ value: i + 1, label: i + 1 })),
];
export const minutesOptions = [
  { value: "", label: "Minutes" },
  ...Array.from({ length: 60 }, (_, i) => ({
    value: i === 0 ? "" : i,
    label: i === 0 ? "00" : i,
  })),
];
export const secondsOptions = [
  { value: "", label: "Seconds" },
  ...Array.from({ length: 60 }, (_, i) => ({
    value: i === 0 ? "" : i,
    label: i === 0 ? "00" : i,
  })),
];
