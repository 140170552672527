// import React, { useState } from 'react';
// import axios from 'axios';

// const VerifyEmail = () => {
//   const [email, setEmail] = useState('');
//   const [verificationResult, setVerificationResult] = useState(null);

//   const handleVerifyEmail = async () => {
//     try {
//       const response = await axios.post(
//         'https://api.emailverification.com/verify',
//         {
//           email,
//           apiKey: 'your-api-key',
//         }
//       );
//       setVerificationResult(response.data);
//     } catch (error) {
//       console.error('Error verifying email:', error);
//     }
//   };

//   return (
//     <div style={{marginTop:"100px"}}>
//       <input
//         type="email"
//         placeholder="Enter your email"
//         value={email}
//         onChange={(e) => setEmail(e.target.value)}
//       />
//       <button onClick={handleVerifyEmail}>Verify Email</button>
//       {verificationResult && (
//         <div>
//           <p>Email: {verificationResult.email}</p>
//           <p>Status: {verificationResult.status}</p>
//           {/* Display other relevant information */}
//         </div>
//       )}
//     </div>
//   );
// };

// export default VerifyEmail;


import React, { useState } from 'react';
import "./verifyEmail.css";
import axios from 'axios';

const VerifyEmail = () => {
  // const [emailId, setEmailId] =useState('');
    const [emailId, setEmailId] = useState('');
    const [otp, setOTP] = useState('');
    const [otpSent, setOTPSent] = useState(false);
    const [verificationStatus, setVerificationStatus] = useState('');
    const [showForm, setShowForm] = useState(true);

    const sendOTP = async () => {
        try {
            // const response = await axios.post('/send-otp', { phoneNumber });
            // console.log(response.data.otp); // For demonstration purposes
            setOTPSent(true);
        } catch (error) {
            console.error('Error sending OTP:', error);
        }
    };

    const verifyOTP = async (e) => {
        e.PreventDefault();
        try {
            const response = await axios.post('/verify-otp', { emailId, otp });
            if (response.data.success) {
                setVerificationStatus('OTP Verified Successfully!');
            } else {
                setVerificationStatus('Invalid OTP');
            }
        } catch (error) {
            console.error('Error verifying OTP:', error);
        }
    };

    const handleExit = () => {
        setShowForm(false); // Hide the form when exit button is clicked
    };

    return (
        <>
        <div style={{marginTop:"150px",height:"50vh",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
        <div>
            <h1 className='VerifyMob-text1'>Verify Your Email Id</h1>
            <p className='VerifyMob-para'>You will receive a 4 digit confirmation code via emailid  <b>matrimony</b></p>
        </div>
        <div>
            <input className='emailid' type="text" value={emailId} onChange={(e) => setEmailId(e.target.value)} placeholder="Enter Email Id" /> <br/><br/>
            <button className='sendOtp' onClick={sendOTP}>Send OTP</button>
          
            {otpSent && (
                <>
                    {/* <input type="text" value={otp} onChange={(e) => setOTP(e.target.value)} placeholder="Enter OTP" />
                    <button onClick={verifyOTP}>Verify OTP</button> */}
                    {showForm && (
                    <form class="otp-Form" onSubmit={verifyOTP}>
 
 <span class="mainHeading">Enter OTP</span>
 <p class="otpSubheading">We have sent a verification code to your Email Id</p>
 <div class="inputContainer">
  <input required="required" maxlength="1" type="text" class="otp-input" id="otp-input1" onChange={(e) => setOTP(e.target.value)}/>
  <input required="required" maxlength="1" type="text" class="otp-input" id="otp-input2" onChange={(e) => setOTP(e.target.value)}/>
  <input required="required" maxlength="1" type="text" class="otp-input" id="otp-input3" onChange={(e) => setOTP(e.target.value)}/>
  <input required="required" maxlength="1" type="text" class="otp-input" id="otp-input4" onChange={(e) => setOTP(e.target.value)}/> 
  
 </div>
  <button class="verifyButton" type="submit">Verify</button>
    <button class="exitBtn" onClick={handleExit}>×</button>
    <p class="resendNote">Didn't receive code yet? <a class="resendBtn">Resend Code</a></p>
    
</form>
)}
                </>
            )}
            
            </div>
            {verificationStatus && <p>{verificationStatus}</p>}
        </div>
        </>
    );
};

export default VerifyEmail;