import React, { useState, useEffect } from "react";
import './WelcomeModal.css';

const WelcomeModal = () => {
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  const handleCloseModal = () => {
    setShowWelcomeModal(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowWelcomeModal(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  // Function to handle refreshing the page and showing the modal again
  const handlePageRefresh = () => {
    setShowWelcomeModal(true);
  };

  return (
  
    <div className="mainWelcomecontainer">
      {/* Your other components */}
      
      {/* Modal */}
      {showWelcomeModal && (
        <div className="welcome-modal">
          <div className="welcome-content">
            <img className="welcome-img" src="https://images.pexels.com/photos/1456613/pexels-photo-1456613.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Welcome"/>
            <h2 className="welcome-text">Welcome to Made4EachOther</h2>
            <p className="welcome-para">
              Welcome to Made4EachOther matrimony, the biggest matrimony service for Indians! Made4EachOther Matrimony
              App has helped lakhs of Indians in India and worldwide find their life partners over 
              last 23 years. Made4EachOther App is the most trusted & No.1 choice for lakhs of 
              Indian brides and Indian grooms looking for a suitable Indian life partner.
            </p>
            <button onClick={handleCloseModal}>Let's Begin</button>
          </div>
        </div>
      )}
    </div>
  
  );
};

export default WelcomeModal;
