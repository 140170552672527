import React, { useState } from "react";
import "./LatestMatches.css";
import {profiles,profileOptions,registrationOptions} from "./LatestMatchesData";
import { initailData } from "./LatestMatchesData";
const ProfileCard = ({ name, age, height, image }) => (
  <div className="profile-card">
    <img src={image} alt={`${name}'s profile`} className="profile-image" />
    <h3>{name}</h3>
    <p>Age: {age}</p>
    <p>Height: {height} feet</p>
  </div>
);
const Filter = ({ label, value, onChange, options }) => (
  <div className="filter-section">
    <label>{label}</label>
    <select value={value} onChange={onChange}>
      <option value="">Select</option>
      {options.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  </div>
);
const LatestMatches = () => {
  const [filters, setFilters] = useState(initailData);
  const handleFilterChange = (e) =>
    setFilters({ ...filters, [e.target.name]: e.target.value });
  return (
    <div>
      <section className="page-title-latest">
        <div className="auto-container-latest">
          <h1 className="d-noneb d-lg-blockb d-xl-blockb d-md-blockb">
            Latest Matches
          </h1>
          <ul className="bread-crumbb clearfix-latest">
            <li>
              <a href="index_dashboardb">Dashboard</a>
            </li>
            <li>Latest Matches</li>
          </ul>
        </div>
      </section>
      <div className="latest-matches-container">
        <div className="filters">
          {[
            {
              label: "Profile Type",
              value: filters.profileType,
              options: profileOptions,
            },
            {
              label: "Profile Registered",
              value: filters.profileRegistered,
              options: registrationOptions,
            },
          ].map((filter, index) => (
            <Filter key={index} {...filter} onChange={handleFilterChange} />
          ))}
        </div>
        <div className="profile-cards-container-latest">
          {profiles.map((profile, index) => (
            <ProfileCard key={index} {...profile} />
          ))}
        </div>
      </div>
    </div>
  );
};
export default LatestMatches;
