import React, {useRef} from 'react';
import './Complete_ProfileStyle.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faImages,faCircleInfo,faUserGraduate,faPeopleRoof,faHand,faHandshake,faImage,faPeoplePulling,faMusic,faSmoking,faFilm,faIdCard,faFile  } from '@fortawesome/free-solid-svg-icons';

const Complete_profile = () => {
    const paragaraph = [
        {
            imgUrl :" https://img.freepik.com/free-vector/add-user-concept-illustration_114360-458.jpg?size=626&ext=jpg&ga=GA1.1.975600797.1700133715&semt=ais",
            text : 'Your photo is the first thing that your matches look at',
            buttonText :  'Add Photo',
            link : '/dashboard/addphoto',
            icon:<FontAwesomeIcon icon={faImages} style={{color: "#f6f7f9",}} />
        },

        {
            imgUrl :"https://img.freepik.com/free-vector/responsive-web-design-illustration_335657-4708.jpg?t=st=1710828192~exp=1710831792~hmac=af051a7fe155ccc77af775933e708d31d7a196ac19d8801947b00d533ae68877&w=740",
            text : 'Basic Information means Personal Data which identifies who they are. ',
            buttonText :  'Add Basic Details',
            link : '/dashboard/Basicdetails',
            icon:<FontAwesomeIcon icon={faCircleInfo} style={{color: "#f5f5f5",}} />
        },
        {
            imgUrl :"https://img.freepik.com/free-vector/focused-tiny-people-reading-books_74855-5836.jpg?t=st=1710830892~exp=1710834492~hmac=a5fe00e06323e42146d6d08b7d20522c65d35b9f445370c9a579d4596cd68cf9&w=826",
            text : 'The official confirmation, usually in the form of a document.',
            buttonText :  ' Add Education Details',
            link : '/dashboard/Education',
            icon:<FontAwesomeIcon icon={faUserGraduate} style={{color: "#f7f9fc",}} />
        },
        {
            imgUrl :"https://img.freepik.com/premium-vector/international-day-families-parent-scene-hand-drawn-flat-stylish-cartoon-sticker-icon-concept_730620-194551.jpg?w=740",
            text : 'Knowing what makes their family unique traditions, values, ways of relating.',
            buttonText :  'Add Family Details',
            link : '/dashboard/Family',
            icon:<FontAwesomeIcon icon={faPeopleRoof} style={{color: "#f4f5f6",}} />
        },
        {
            imgUrl :"https://img.freepik.com/free-vector/flat-zodiac-sign-set_23-2148997607.jpg?t=st=1710828469~exp=1710832069~hmac=bf5f3800770869a9214eb0e2d2f16434e5a2b051fafa336e5f04612f46e091ed&w=740",
            text : ' prediction a person future based on a comparison of the zodiacal data.',
            buttonText :  'Add Horoscope Details',
            link : '/dashboard/Horoscope',
            icon:<FontAwesomeIcon icon={faHand} style={{color: "#f0f2f5",}} />
        },
        {
            imgUrl :"https://img.freepik.com/premium-vector/male-female-icon-set-boy-girl-wc-symbols-man-woman-user-avatar-vector_418020-7.jpg?w=740",
            text : 'Expressing values and beliefs, achievements for partner preferences',
            buttonText :  'Add Partner Preference',
            link : '/dashboard/Prefrence',
            icon:<FontAwesomeIcon icon={faHandshake} style={{color: "#f0f2f5",}} />
        },

    ]

    const paragaraph2 = [
        {
            imgUrl : "https://img.freepik.com/free-vector/image-upload-concept-illustration_23-2148281796.jpg?size=626&ext=jpg&ga=GA1.1.975600797.1700133715&semt=ais",
            text : 'Your photo is the first thing that your matches look at',
            buttonText :  'Add More Photo',
            link : '/dashboard/addimages',
            icon:<FontAwesomeIcon icon={faImage} style={{color: "#f0f2f4",}} />
        },
        {
            imgUrl : "https://img.freepik.com/free-vector/hand-drawn-couple-silhouette-illustration_23-2149830383.jpg?t=st=1714394916~exp=1714398516~hmac=11e651ff53c15da6c9921d31b7b7e83dc18a234266a2d2fe1483882585663c05&w=740",
            text : 'Your photo is the first thing that your matches look at',
            buttonText :  'Add Couples Photo',
            link : '/Addcouplesimage',
            icon:<FontAwesomeIcon icon={faPeoplePulling} style={{color: "#f1f2f3",}} />
        },
        {
            imgUrl : "https://img.freepik.com/free-vector/drawn-about-me-concept-with-hobbies-interests_23-2148884232.jpg?w=740&t=st=1707820268~exp=1707820868~hmac=6394463a7007ada9de0caf1c45d604242b8a1c637b6473b29e7b2b0ce3a48d4a",
            text : 'Add your hobbies and interests to discover matches with similar.',
            buttonText :  'Add Hobbies',
            link : '/dashboard/HobbyDetails',
            icon:<FontAwesomeIcon icon={faMusic} style={{color: "#eff1f5",}} />
        },
        {
            imgUrl :"https://img.freepik.com/free-vector/healthy-lifestyle-stress-managing-circular-cartoon-composition-with-sitting-yoga-lotus-pose-music-meditation_1284-60042.jpg?t=st=1710828763~exp=1710832363~hmac=5501f49233a62692b56aadd0288902ed17a70fda3b0c54ca5cd1a5ee942f3c69&w=740",
            text : 'Habits are an essential part of our daily lives. They shape our behaviors, attitudes.',
            buttonText :  'Add Habits',
            link : '/dashboard/HabitsForm',
            icon:<FontAwesomeIcon icon={faSmoking} style={{color: "#eeeff2",}} />
        },
        {
            imgUrl :"https://img.freepik.com/free-vector/people-holding-connected-copy-space-circle-icons_53876-66230.jpg?t=st=1710828829~exp=1710832429~hmac=1e8f9ef3fa3e9107475b47c5805ba3186475d3d9db3beaa3a7f725939060c6bc&w=826",
            text : 'Interests are things you find desirable, enjoyable, fun, or pleasant.',
            buttonText :  'Add Interest links',
            link : '/dashboard/Interestslink',
            icon:<FontAwesomeIcon icon={faFilm} style={{color: "#f2f2f3",}} />
        },
        {
            imgUrl :"https://img.freepik.com/free-vector/resume-concept-illustration_114360-103.jpg?t=st=1710828962~exp=1710832562~hmac=9bac697f47153d385bd6a2fc991c2771fab057f4217deb251eba085d71393ea8&w=740",
            text : 'Upload the valid ID proof to complete your profile',
            buttonText :  'Upload ID proof',
            link : '/dashboard/IdProof',
            icon:<FontAwesomeIcon icon={faIdCard} style={{color: "#efeff1",}} />
        },
        {
            imgUrl :"https://img.freepik.com/free-vector/upload-concept-illustration_114360-1317.jpg?t=st=1710829031~exp=1710832631~hmac=c69fd4b8856fdb3be42ea32630f135fa131567a24866f185f9e42798ad83cc8d&w=740",
            text : 'Your files here or browse to Upload the documents',
            buttonText :  'Upload Documents',
            link : '/dashboard/Uploaddocument',
            icon:<FontAwesomeIcon icon={faFile} style={{color: "#f5f7f9",}} />
        },
    ]
    const scrollRef1 = useRef(null);
    const scrollRef2 = useRef(null);

    const scroll1 = (scrollOffset) => {
        scrollRef1.current.scrollBy({ left: scrollOffset, behavior: 'smooth' });
    };
    const scroll2 = (scrollOffset) => {
        scrollRef2.current.scrollBy({ left: scrollOffset, behavior: 'smooth' });
    };

  return (
    <div className='complete_profile-main'>
    <br/>
    <div  className='complete_profile-main1' >
        <h2 className="com-text1" style={{width:'fit-content', marginLeft:"25px"}}>Complete Your Profile</h2>
        <span style={{display:'flex', alignItems:'center', paddingLeft:'2%'}}><p style={{margin:'0',color:'black',fontSize:"20px",fontWeight:"500"}}>Profile completeness Score &nbsp;   </p><input  type="range" min={0} max={100} style={{width:'60px',height:'10px',cursor:'pointer'}} /></span>
    </div>
    <div className='complete_profile-main2' ref={scrollRef1}>

            {paragaraph.map((data) => (
                <div className="userProfile-ad-card" >
                <div className="userProfile-ad-card1" >
                <img className='complete-img' src={data.imgUrl}  alt="bck" />
               </div>
            <div className="userProfile-ad-card2" >
                <p style={{color:'black', width:'90%',fontSize:'12px',fontWeight:'600'}}> {data.text}</p>
                <button className="userProfile-ad-card-btn">{data.icon}<Link to={data.link} className='button-link'> {data.buttonText}</Link></button>
            </div>
            </div>
            ))}
            <button className="arrow-button-profile left" onClick={() => scroll1(-400)}>
                &#9664; 
            </button>
            <button className="arrow-button-profile right" onClick={() => scroll1(370)}>
                &#9654;
            </button>

    </div>

    <div className='complete_profile-main3'>

    <div className='complete_profile-main3' style={{width:'100%',height:'40px',background:'whitesmoke'}}>
    <h2 className="com-text2" style={{width:'fit-content', marginLeft:"25px"}}>Enrich Your Profile</h2>
    
    </div>

    <div className='complete_profile-main4' ref={scrollRef2} >

    {paragaraph2.map((data) => (
                <div className="userProfile-ad-card" >
                <div className="userProfile-ad-card1" >
                <img src={data.imgUrl} className='complete-img' alt="bck" />
            </div>
            <div className="userProfile-ad-card2" >
                <p style={{color:'black', width:'90%',fontSize:'12px',fontWeight:'600'}}> {data.text}</p>
                <button className="userProfile-ad-card-btn">{data.icon}<Link to={data.link} className='button-link'> {data.buttonText}</Link></button>
            </div>
            </div>
            ))}
            <button className="arrow-button-profile left" onClick={() => scroll2(-400)}>
                &#9664; 
            </button>
            <button className="arrow-button-profile right" onClick={() => scroll2(375)}>
                &#9654;
            </button>

    </div>
    </div>

</div>
  )
}

export default Complete_profile
