import React from 'react'
import "./WhoConnectedMe.css"

const WhoConnectedMe = () => {
  return (
    <div>

      <section className="page-title who-contacted-me-page-title">
        <div className="auto-container">
          {/* <h1 className="d-none d-lg-block d-xl-block d-md-block">WhoConnectedMe </h1> */}
          <ul className="bread-crumb clearfix">
            <li style={{ textAlign: 'center' }}> WhoConnectedMe</li>
          </ul>
        </div>

      </section>
      <div>
        <br />
        <center className='who-contacted-me-center-tag-container'>
          <div className="who-contacted-me-container">
            <h1 className="who-contacted-me-heading">Who Connected Me</h1>
            <div className="who-contacted-me-input-container">
              <input type="text" className="who-contacted-me-input" placeholder="Name" />
              <input type="text" className="who-contacted-me-input" placeholder="Profile ID" />
              <input type="text" className="who-contacted-me-input" placeholder="Viewed Date" />
            </div>
          </div>
        </center>
      </div>
    </div>
  )
}

export default WhoConnectedMe
