import React, { useState, useEffect } from 'react';
import {
  BsFillPencilFill,

} from "react-icons/bs";

const Newbasic = () => {
  const [databas, setDatabas] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [profile_created_By, setProfileCreatedBy] = useState("");
  const [marital_status, setMaritalstatus] = useState("");
  const [your_religion, setYourRegilion] = useState("");
  const [ religion, setReligion] = useState("");
  const [your_caste, setYourCaste] = useState("");
  const [sub_caste, setSubcaste] = useState("");
  const [about_yourself, setAboutyourself] = useState("");
  const [Name, setName] = useState("");
  const [Surname, setSurname] = useState("");
  const [Email, setEmail] = useState("");
  const [Gender, setGender] = useState("");
  const [Height, setHeight] = useState("");
  const [Blood_Group, setBlood_Group] = useState("");
  const [D_O_B, setD_O_B] = useState("");
  const [Mobile_Number, setMobile_Number] = useState("");
  const [Age, setAge] = useState("");
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://matrimonyadmin.hola9.com/api/BasicinformationPageapi/BasicInformationList/`);
        const jsonData = await response.json();
        setDatabas(jsonData);
        setIsLoading(false);
        if (jsonData.length > 0) {
          const latestData = jsonData[jsonData.length - 1];
          setSelectedId(latestData.id);
          setProfileCreatedBy(latestData.profile_created_By);
          setMaritalstatus(latestData.marital_status);
          setYourRegilion(latestData.your_religion);
          setReligion(latestData.religion);
          setYourCaste(latestData.your_caste);
          setSubcaste(latestData.sub_caste);
          setAboutyourself(latestData.about_yourself);
          setName(latestData.Name);
          setSurname(latestData.Surname);
          setEmail(latestData.Email);
          setGender(latestData.Gender);
          setHeight(latestData.Height);
          setBlood_Group(latestData.Blood_Group);
          setD_O_B(latestData.D_O_B);
          setMobile_Number(latestData.Mobile_Number);
          setAge(latestData.Age);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://matrimonyadmin.hola9.com/api/BasicinformationPageapi/BasicInformationList/${selectedId}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: selectedId,
          profile_created_By,
          marital_status,
          your_religion,
          religion,
          your_caste,
          sub_caste,
          about_yourself,
          Name,
          Surname,
          Email,
          Gender,
          Height,
          Blood_Group,
          D_O_B,
          Mobile_Number,
          Age,
         
        }),
      });
      if (response.ok) {
        setDatabas(prevData => {
          const newData = [...prevData];
          const index = newData.findIndex(item => item.id === selectedId);
          if (index !== -1) {
            newData[index] = {
              id: selectedId,
              profile_created_By,
              marital_status,
              your_religion,
              religion,
              your_caste,
              sub_caste,
              about_yourself,
              Name,
              Surname,
              Email,
              Gender,
              Height,
              Blood_Group,
              D_O_B,
              Mobile_Number,
              Age,
            };
          }
          return newData;
        });
        closeModal();
      } else {
        console.error('Failed to update data:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div>
    {/* <div>Newbasic</div> */}
    <div
        className="feature-block-twop col-lg-4p col-md-6p col-sm-6p wow fadeInUp animated"
        style={{ visibility: "visible", animationName: "fadeInUp" }}
      >
        {databas.length > 0 && (
          <div className="inner-boxp">
            <div className="icon-boxp">
              <img
                src="https://www.matrimonysoftware.in/icon/basic.png"
                alt=""
              />
            </div>
            <h4>
              <a href="Basicdetails">
                Basic Details{" "}
                <i style={{ fontSize: 24 }} className="fa">
                  
                </i>
              </a>
           
            </h4>
            <BsFillPencilFill className="editpro"   onClick={openModal}/>


        <div className="textpro">
          <span>Name:</span> {Name}</div>
        <div className="textpro"><span>Surname:</span> {Surname}</div>
        <div className="textpro"><span>Email:</span>{Email}</div>
        <div className="textpro"><span>Mobile_Number:</span> {Mobile_Number}</div>
        <div className="textpro"><span>Date of Birth: </span>{D_O_B}</div>
        <div className="textpro"><span>Age:</span> {Age}</div>
       <div className="textpro"><span>Height: </span>{Height}</div>
        <div className="textpro"><span>Blood_Group:</span> {Blood_Group}</div>
        <div className="textpro"><span>Gender:</span> {Gender}</div>
        <div className="textpro"><span>Religion:</span> {religion}</div>
        <div className="textpro"><span>your_religion: </span>{your_religion}</div>
       <div className="textpro"><span>profile_created_By: </span>{profile_created_By}</div>
       <div className="textpro"><span>Marital_status:</span> {marital_status}</div>
       <div className="textpro"><span>your_caste:</span> {your_caste}</div>
       <div className="textpro"><span>Sub_caste: </span>{sub_caste}</div>
       <div className="textpro"><span>About_yourself:</span> {about_yourself}</div>

            {/* <button className="btn btn-primary" onClick={openModal}>Edit</button> */}
          </div>
        )}
      </div>

      <div className={`modal ${isModalOpen ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: isModalOpen ? 'block' : 'none' }}>
        <div className="modal-dialog" role="document" >
          <div className="modal-content" >
            <div className="modal-header">
              <h5 className="modal-title">Edit Details</h5>
              <button type="button" className="close" onClick={closeModal}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group" >
                  <label htmlFor="Name" style={{color:"white"}}>Name:</label>
                  <input type="text" className="form-control" id="Name" value={Name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="Surname" style={{color:"white"}}>Surname:</label>
                  <input type="text" className="form-control" id="Surname" value={Surname} onChange={(e) => setSurname(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="Email" style={{color:"white"}}>Email:</label>
                  <input type="text" className="form-control" id="Email" value={Email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="Mobile_Number" style={{color:"white"}}>Mobile_Number:</label>
                  <input type="text" className="form-control" id="Mobile_Number" value={Mobile_Number} onChange={(e) => setMobile_Number(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="D_O_B" style={{color:"white"}}>Date of Birth:</label>
                  <input type="text" className="form-control" id="D_O_B" value={D_O_B} onChange={(e) => setD_O_B(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="Age" style={{color:"white"}}>Age:</label>
                  <input type="text" className="form-control" id="Age" value={Age} onChange={(e) => setAge(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="Height" style={{color:"white"}}>Height:</label>
                  <input type="text" className="form-control" id="Height" value={Height} onChange={(e) => setHeight(e.target.value)} />
                 
                </div>
                <div className="form-group">
                  <label htmlFor="Blood_Group" style={{color:"white"}}>Blood_Group:</label>
                  <input type="text" className="form-control" id="Blood_Group" value={Blood_Group} onChange={(e) => setBlood_Group(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="Gender" style={{color:"white"}}>Gender:</label>
                  <input type="text" className="form-control" id="Gender" value={Gender} onChange={(e) => setGender(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="religion" style={{color:"white"}}>Religion:</label>
                  <input type="text" className="form-control" id="religion" value={religion} onChange={(e) => setReligion(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="your_religion" style={{color:"white"}}>your_religion:</label>
                  <input type="text" className="form-control" id="your_religion" value={your_religion} onChange={(e) => setYourRegilion(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="profile_created_By" style={{color:"white"}}>profile_created_By:</label>
                  <input type="text" className="form-control" id="profile_created_By" value={profile_created_By} onChange={(e) => setProfileCreatedBy(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="marital_status" style={{color:"white"}}>Marital_status:</label>
                  <input type="text" className="form-control" id="marital_status" value={marital_status} onChange={(e) => setMaritalstatus(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="your_caste" style={{color:"white"}}>your_caste:</label>
                  <input type="text" className="form-control" id="your_caste" value={your_caste} onChange={(e) => setYourCaste(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="sub_caste" style={{color:"white"}}>Sub_caste:</label>
                  <input type="text" className="form-control" id="sub_caste" value={sub_caste} onChange={(e) => setSubcaste(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="about_yourself" style={{color:"white"}}>About_yourself:</label>
                  <input type="text" className="form-control" id="about_yourself" value={about_yourself} onChange={(e) => setAboutyourself(e.target.value)} />
                </div>
                <div className="form-group">
                <button type="submit"  >Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Newbasic