import React, { useState, useEffect } from 'react';
import { BsFillPencilFill } from "react-icons/bs";
import { BarLoader } from 'react-spinners';

const Newyourprofile = () => {
  const [dataprofile, setDataprofile] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedId, setSelectedId] = useState(null);

  const [formData, setFormData] = useState({
    image: null,
    name: "",
    email: "",
    phone_number: "",
    address: "",
    state: "",
    city: "",
    country: "",
    zipcode: "",
    birth_date: "",
    age: "",
    gender: "",
    religion: "",
    caste: "",
    height: "",
    education: "",
    occupation: "",
    income: ""
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://matrimonyadmin.hola9.com/api/user/profiles/`);
        const jsonData = await response.json();
        console.log('Fetched data:', jsonData); // Debug
        setDataprofile(jsonData);
        setIsLoading(false);
        if (jsonData.length > 0) {
          const latestData = jsonData[jsonData.length - 1];
          console.log('Latest data:', latestData); // Debug
          setSelectedId(latestData.id);

          setFormData({
            ...latestData,
            image: null // Resetting image to null to avoid displaying the existing image
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleImageChange = (e) => {
    setFormData({
      ...formData,
      image: e.target.files[0]
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataObj = new FormData();
      for (const key in formData) {
        formDataObj.append(key, formData[key]);
      }

      const response = await fetch(`https://matrimonyadmin.hola9.com/api/user/profiles/${selectedId}/`, {
        method: 'PUT',
        body: formDataObj,
      });

      if (response.ok) {
        const updatedData = await response.json();
        setDataprofile(prevData => {
          const newData = [...prevData];
          const index = newData.findIndex(item => item.id === selectedId);
          if (index !== -1) {
            newData[index] = updatedData;
          }
          return newData;
        });
        closeModal();
      } else {
        console.error('Failed to update data:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <BarLoader color={'#123abc'} loading={isLoading} /> {/* Display BarLoader while data is being fetched */}
      </div>
    );
  }

  return (
    <div>
      <div className="feature-block-twop col-lg-4p col-md-6p col-sm-6p wow fadeInUp animated">
        {dataprofile.length > 0 && (
          <div className="inner-boxp">
            <div className="icon-boxp">
              <img src="https://www.matrimonysoftware.in/icon/basic.png" alt="" />
            </div>
            <h4>
              <a href="Profiledetails">Profile Details</a>
            </h4>
            <BsFillPencilFill className="editpro" onClick={openModal} />
            <div className="textpro1" style={{height:"200px",width:"200px"}}>image: <img src={formData.image ? URL.createObjectURL(formData.image) : ""} alt="profile" height={"200px"} width={"200px"}/></div>
            <div className="textpro"><span>name:</span> {formData.name}</div>
            <div className="textpro"><span>email:</span> {formData.email}</div>
            <div className="textpro"><span>phone_number:</span> {formData.phone_number}</div>
            <div className="textpro"><span>address:</span> {formData.address}</div>
            <div className="textpro"><span>state:</span> {formData.state}</div>
            <div className="textpro"><span>city:</span> {formData.city}</div>
            <div className="textpro"><span>country:</span> {formData.country}</div>
            <div className="textpro"><span>zipcode:</span> {formData.zipcode}</div>
            <div className="textpro"><span>birth_date:</span> {formData.birth_date}</div>
            <div className="textpro"><span>age:</span> {formData.age}</div>
            <div className="textpro"><span>gender:</span> {formData.gender}</div>
            <div className="textpro"><span>religion:</span> {formData.religion}</div>
            <div className="textpro"><span>caste:</span> {formData.caste}</div>
            <div className="textpro"><span>height:</span> {formData.height}</div>
            <div className="textpro"><span>education:</span> {formData.education}</div>
            <div className="textpro"><span>occupation:</span> {formData.occupation}</div>
            <div className="textpro"><span>income:</span> {formData.income}</div>
          </div>
        )}
      </div>

      <div className={`modal ${isModalOpen ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: isModalOpen ? 'block' : 'none' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Details</h5>
              <button type="button" className="close" onClick={closeModal}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="form-group">
                  <label htmlFor="image"  style={{color:"white"}}>image:</label>
                  <input type="file" className="form-control" id="image" onChange={handleImageChange} />
                </div>
                <div className="form-group">
                  <label htmlFor="name"  style={{color:"white"}}>name:</label>
                  <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} />
                </div>
                <div className="form-group">
                  <label htmlFor="email"  style={{color:"white"}}>email:</label>
                  <input type="text" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} />
                </div>
               
                <div className="form-group">
                  <label htmlFor="phone_number"  style={{color:"white"}}>phone_number:</label>
                  <input type="text" className="form-control" id="phone_number" value={formData.phone_number} onChange={handleChange} />
                </div>
                <div className="form-group">
                  <label htmlFor="address"  style={{color:"white"}}>address:</label>
                  <input type="text" className="form-control" id="address" value={formData.address} onChange={handleChange} />
                </div>
                <div className="form-group">
                  <label htmlFor="state"  style={{color:"white"}}>state:</label>
                  <input type="text" className="form-control" id="state" value={formData.state} onChange={handleChange} />
                </div>
                <div className="form-group">
                  <label htmlFor="city"  style={{color:"white"}}>city:</label>
                  <input type="text" className="form-control" id="city" value={formData.city} onChange={handleChange} />
                 
                </div>
                <div className="form-group">
                  <label htmlFor="country"  style={{color:"white"}}>country:</label>
                  <input type="text" className="form-control" id="country" value={formData.country} onChange={handleChange} />
                 
                </div>
                <div className="form-group">
                  <label htmlFor="zipcode"  style={{color:"white"}}>zipcode:</label>
                  <input type="text" className="form-control" id="zipcode" value={formData.zipcode} onChange={handleChange} />
                </div>
                <div className="form-group">
                  <label htmlFor="birth_date"  style={{color:"white"}}>birth_date:</label>
                  <input type="text" className="form-control" id="birth_date" value={formData.birth_date} onChange={handleChange} />
                </div>
                <div className="form-group">
                  <label htmlFor="age"  style={{color:"white"}}>age:</label>
                  <input type="text" className="form-control" id="age" value={formData.age} onChange={handleChange} />
                </div>
                <div className="form-group">
                  <label htmlFor="gender"  style={{color:"white"}}>gender:</label>
                  <input type="text" className="form-control" id="gender" value={formData.gender} onChange={handleChange} />
                </div>
                <div className="form-group">
                  <label htmlFor="religion"  style={{color:"white"}}>religion:</label>
                  <input type="text" className="form-control" id="religion" value={formData.religion} onChange={handleChange} />
                </div>
                <div className="form-group">
                  <label htmlFor="caste"  style={{color:"white"}}>caste:</label>
                  <input type="text" className="form-control" id="caste" value={formData.caste} onChange={handleChange} />
                </div>
                <div className="form-group">
                  <label htmlFor="height"  style={{color:"white"}}>height:</label>
                  <input type="text" className="form-control" id="height" value={formData.height} onChange={handleChange} />
                </div>
                <div className="form-group">
                  <label htmlFor="education"  style={{color:"white"}}>education:</label>
                  <input type="text" className="form-control" id="education" value={formData.education} onChange={handleChange} />
                </div>
                <div className="form-group">
                  <label htmlFor="occupation"  style={{color:"white"}}>occupation:</label>
                  <input type="text" className="form-control" id="occupation" value={formData.occupation} onChange={handleChange} />
                </div>
                <div className="form-group">
                  <label htmlFor="income"  style={{color:"white"}}>income:</label>
                  <input type="text" className="form-control" id="income" value={formData.income} onChange={handleChange} />
                </div>
                <div className="form-group">
                <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Newyourprofile;
