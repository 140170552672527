import React from "react";
import "./App.css";
import HomePage from "./Components/Homepage/HomePage";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Signup from "./Components/SignUp/Signup";
import Login from "./Components/Login/Login";
import About from "./Components/AboutUs.jsx/About";
import Payment from "./Components/Payments/Payment";
import PriceMethod from "./Components/Payments/PriceMethod";
import FACs from "./Components/Faq/FACs";
import Term from "./Components/Terms & Cond/Term";
import Privacy from "./Components/Privacy policy/Privacy";
import Refund from "./Components/Refund policy/Refund";
import Safematrimony from "./Components/SafeMatrimony/Safematrimony";
import Disclamier from "./Components/DisClaimer/Disclamier";
import Description from "./Components/Descriptions/Description";
import UserdashBoard from "./Components/UserDashBoard/UserdashBoard";
import HappyStory from "./Components/Homepage/HappyStory";
import SetPassword from "./Components/Forgot Password/ResetPassword/SetPassword";
import ForgotPassword from "./Components/Forgot Password/ForgotPassword";
import ResetPassword from "./Components/Forgot Password/ResetPassword/ResetPassword";
import Specialsomeone from "./Components/Homepage/Specialsomeone";
import BlogpostForm from "./Components/Blog/BlogpostForm";
import BlogDetails from "./Components/Blog/BlogDetails";
import BlogDetailsCard from "./Components/Blog/BlogDetailsCard";
import NotFound from "./Components/NotFound";
import Addcouplesimage from "./Components/UserDashBoard/Addcouplesimage";
import Feedback from "./Components/Feedbacks/Feedback";
import BlogView from "./Components/Blog/BlogView";
import Reviewsinglecard from "./Components/UserDashBoard/Reviewsinglecard";
import Header from "./Components/Homepage/Header";
import Footer from "./Components/Footer/Footer";
import Viewmore from "./Components/Feedbacks/Viewmore";
import Successview from "./Components/Successstory/Successview";
import VerifyMobile from "./Components/Homepage/VerifyMobile";
import VerifyEmail from "./Components/Homepage/VerifyEmail";
import ProfileStepper from "./Components/SignUp/ProfileStepper";
import Stepper from "./Components/SignUp/Stepper";

function App() {
  return (
    <>
      <div>
        <BrowserRouter>
          <Header />
          <Header />
          <Routes>
            <Route path="/" exact element={<HomePage />} />
            <Route path="/BlogDetails" exact element={<BlogDetails />} />

            <Route path="/BlogDetails" exact element={<BlogDetails />} />
            <Route path="/Signup" exact element={<Signup />} />
            <Route path="/Login" exact element={<Login />} />
            <Route path="/HappyStory" exact element={<HappyStory />} />
            <Route path="/About" exact element={<About />} />
            <Route path="/faqs" exact element={<FACs />} />
            <Route path="/terms-conditions" exact element={<Term />} />
            <Route path="/Privacy-policy" exact element={<Privacy />} />
            <Route path="/returns-and-cancellation" exact element={<Refund />} />
            <Route
              path="/returns-and-cancellation"
              exact
              element={<Refund />}
            />
            <Route path="/disclaimer" exact element={<Disclamier />} />
            <Route path="/safematrimony" exact element={<Safematrimony />} />
            <Route path="/payment" exact element={<Payment />} />
            <Route path="/Description" exact element={<Description />} />
            <Route path="/PriceMethod" exact element={<PriceMethod />} />
            <Route path="/ForgetPassword" exact element={<ForgotPassword />} />
            <Route path="/ResetPassword" exact element={<ResetPassword />} />
            <Route path="/SetPassword" exact element={<SetPassword />} />
            <Route path="/dashboard/*" element={<UserdashBoard />} />
            <Route path="/BlogDetailsCard/:postId" element={<BlogDetailsCard />} />
            <Route path="/Specialsomeone" exact element={<Specialsomeone />} />
            <Route path="/BlogpostForm" exact element={<BlogpostForm />} />
            <Route
              path="/BlogDetailsCard/:postId"
              element={<BlogDetailsCard />}
            />
            <Route path="/Specialsomeone" exact element={<Specialsomeone />} />
            <Route path="/BlogpostForm" exact element={<BlogpostForm />} />
            <Route path="/BlogDetails" exact element={<BlogDetails />} />
            <Route path="/Contact" exact element={<Feedback />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/Addcouplesimage" exact element={<Addcouplesimage />} />
            <Route path="/Contact" exact element={<Feedback />} />
            <Route path="*" element={<NotFound />} />
            <Route
              path="/Addcouplesimage"
              exact
              element={<Addcouplesimage />}
            />
            <Route path="/blog/:id" Component={BlogView} />


            <Route path="/feedback/:id" exact element={<Viewmore />} />
            <Route path="/success/:id" exact element={<Successview />} />


            <Route path="/Reviewsinglecard/:id" exact element={<Reviewsinglecard />} />
            <Route path="/VerifyMobile" exact element={<VerifyMobile />} />
            <Route path="/VerifyEmail" exact element={<VerifyEmail />} />
            <Route path="/ProfileStepper" exact element={<ProfileStepper />} />
            <Route path="/Stepper" exact element={<Stepper />} />
            <Route path="/ResetPassword" exact element={<ResetPassword />} />
            <Route path="/feedback/:id" exact element={<Viewmore />} />
            <Route path="/success/:id" exact element={<Successview />} />
            <Route
              path="/Reviewsinglecard/:id"
              exact
              element={<Reviewsinglecard />}
            />
            <Route path="/VerifyMobile" exact element={<VerifyMobile />} />
            <Route path="/VerifyEmail" exact element={<VerifyEmail />} />
            <Route path="/ProfileStepper" exact element={<ProfileStepper />} />
            <Route path="/Stepper" exact element={<Stepper />} />
            <Route path="/ResetPassword" exact element={<ResetPassword />} />
          </Routes>
          <Footer />
 
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
