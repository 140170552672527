

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import { Button } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import './LoginStyle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [captchaText, setCaptchaText] = useState('');
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);

  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();

    // Validate email and password
    if (!email || !password) {
      toast.error('Please enter both email/phone and password.');
      return;
    }

    // Validate captcha
    if (!captchaVerified) {
      toast.error('Please verify the CAPTCHA first.');
      return;
    }

    // Perform login logic
    const data = { email, password };
    axios
      .post('https://madmin.hola9.com/api/user/login/', data)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.msg);
          navigate('/dashboard');
        } else {
          toast.error('Login failed. Please try again.');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Login failed. Please try again.');
      });
  };

  const generateCaptcha = (e) => {
    e.preventDefault();
    const captcha = uuidv4().substr(0, 6).toUpperCase();
    setCaptchaText(captcha);
  };

  const verifyCaptcha = (e) => {
    e.preventDefault();
    if (captchaText === inputValue) {
      toast.success('CAPTCHA verified successfully!');
      setCaptchaVerified(true);
    } else {
      toast.error('CAPTCHA verification failed. Please try again.');
      setCaptchaVerified(false);
    }
  };

  return (
    <div>

      <ToastContainer />

      {/* 
      this is the previous bg image
        backgroundImage: 'url(https://images.unsplash.com/photo-1707380659093-97e45913a9ea?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)',
      */}

      <section className="newsletter-sectionlog" style={{ backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%' }}>
        <div className="auto-containerlog">
          <div className="rowlog">
            <div className="form-column">
              <div className="login-containerL">
                <div className="contact-forml">

                  <form className="login-formL glassmob">

                    <h2 className='login-label' >Login</h2>

                    <div className="form-grouplog">
                      <label className="lasl" htmlFor="emailPhone">Email</label>
                      <input className='input-log' type="email" id="email" name="email" value={email} placeholder="email" required onChange={(e) => setEmail(e.target.value)} />
                    </div>

                    <div className="form-grouplog">
                      <label className="lasl" htmlFor="password">Password:</label>
                      <input
                        className='input-log'
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        name="password"
                        value={password}
                        placeholder="password"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <FontAwesomeIcon
                        icon={showPassword ? faEye : faEyeSlash}
                        onClick={() => setShowPassword(!showPassword)}
                        style={{ position: 'absolute', paddingTop: '13px', marginLeft: '-25px', cursor: 'pointer', color: "white" }}
                      />
                    </div>

                    <div className="form-groupcheck">
                      <input className='checkbox-log' type="checkbox" checked={termsAccepted} onChange={() => setTermsAccepted(!termsAccepted)} /> &nbsp;&nbsp;

                      <label htmlFor="termsAccepted" style={{ color: 'black', fontWeight: '400' }} > Accept &nbsp;
                        <Link to="/terms-conditions" target="_blank" tabIndex={3} style={{ color: 'black', fontWeight: '400' }}>Terms & Conditions</Link>
                        <Link to="/privacy-policy" target="_blank" tabIndex={3} style={{ color: 'black', fontWeight: '400' }}>  and Privacy policy</Link>
                      </label>
                    </div>

                    <div className="captcha-container">

                      <div className="captcha">
                        <button className='captcha-btn' onClick={generateCaptcha}>Generate CAPTCHA</button>
                      </div>
                      <div>
                        <h3 style={{ color: 'black', fontSize: '25px', display: 'flex', alignItems: 'center' }}>{captchaText}</h3>
                      </div>

                    </div>

                    <div>
                      <div className="captcha-input">
                        <input type="text" className="capInput" value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder="Enter CAPTCHA" />
                        <button className="btncap" onClick={verifyCaptcha}>Verify</button>
                      </div>

                    </div>

                    <div className="form-group4L">

                      <button type="submit" onClick={handleLogin} className="log-btn"><span className="btn-title4L">Log In</span></button>

                      <span className="forget_style" style={{ width: '100%', height: '50px', color: '#Ec167f', cursor: 'pointer', }}><Link to="/ForgetPassword" style={{ color: 'black' }} >Forget Password ?</Link></span>

                    </div>


                    <div className="social-icons-wrapper">
                      <div className="social-icons">

                        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} className="social-icon" /></a>
                        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} className="social-icon" /></a>
                        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} className="social-icon" /></a>

                      </div>
                    </div>

                    <center className="col-lg-12log col-md-6log col-sm-9log mt-3">
                      <span>New Candidate Register ?{' '}<Link to="/Signup" tabIndex={7} style={{ color: 'white' }} >SignUp</Link></span>
                    </center>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    </div>
  );
};

export default Login;
