import React, { useState,useEffect } from 'react';
// import Header from '../Homepage/Header'
import "./ProfileStyle.css"
// import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import {
  BsFillPencilFill,

} from "react-icons/bs";
import axios from 'axios'; // Import Axios
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Input} from "antd";






const Profile = () => {
  const navigate = useNavigate();
  //your details
  const [showModalyour, setShowModalyour] = useState(false);
  //education details
  const [showModaledu, setShowModaledu] = useState(false);
  //family details
  const [showModalfam, setShowModalfam] = useState(false);
  //horoscope
  const [showModalhoro, setShowModalhoro] = useState(false);
  //patner preference
  const [showModalpat, setShowModalpat] = useState(false);
  //basic details
  const [showModalbasic, setShowModalbasic] = useState(false);
  //interest link
  const [showModalint, setShowModalint] = useState(false);
  //hobby details
  const [showModal, setShowModal] = useState(false);

      //profile
  
        const [data, setData] = useState([]);
      
        useEffect(() => {
          const fetchData = async () => {
            try {
              const response = await fetch(`https://matrimonyadmin.hola9.com/api/user/profiles/`);
              const jsonData = await response.json();
              setData(jsonData);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
      
          fetchData();
        }, []);
      
        console.warn("result", data);

     //education details
        const [dataedu, setDataedu] = useState([]);
      
        useEffect(() => {
          const fetchData = async () => {
            try {
              const response = await fetch(`https://matrimonyadmin.hola9.com/api/EducationDetails/educationDetail/`);
              const jsonData = await response.json();
              setDataedu(jsonData);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
      
          fetchData();
        }, []);
      
        console.warn("result", dataedu);


   //family details
        const [datafam, setDatafam] = useState([]);
      
        useEffect(() => {
          const fetchData = async () => {
            try {
              const response = await fetch(`https://matrimonyadmin.hola9.com/api/Familydeatilsapi/Familydetails/`);
              const jsonData = await response.json();
              setDatafam(jsonData);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
      
          fetchData();
        }, []);
      
        console.warn("result", datafam);


    //horoscope
        const [datahoro, setDatahoro] = useState([]);
      
        useEffect(() => {
          const fetchData = async () => {
            try {
              const response = await fetch(`https://matrimonyadmin.hola9.com/api/Horoscopeapi/horoscopeList/`);
              const jsonData = await response.json();
              setDatahoro(jsonData);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
      
          fetchData();
        }, []);
      
        console.warn("result", datahoro);



    //partnerpreference
        const [datapat, setDatapat] = useState([]);
      
        useEffect(() => {
          const fetchData = async () => {
            try {
              const response = await fetch(`https://matrimonyadmin.hola9.com/api/partnerapi/partnerprefarence/`);
              const jsonData = await response.json();
              setDatapat(jsonData);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
      
          fetchData();
        }, []);
      
        console.warn("result", datapat);

    //basicinformation
        const [databasic, setDatabasic] = useState([]);
      
        useEffect(() => {
          const fetchData = async () => {
            try {
              const response = await fetch(`https://matrimonyadmin.hola9.com/api/BasicinformationPageapi/BasicInformationList/`);
              const jsonData = await response.json();
              setDatabasic(jsonData);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
      
          fetchData();
        }, []);
      
        console.warn("result", databasic);

    //interest link
        const [dataint, setDataint] = useState([]);
      
        useEffect(() => {
          const fetchData = async () => {
            try {
              const response = await fetch(`https://matrimonyadmin.hola9.com/api/profileapi/interests/`);
              const jsonData = await response.json();
              setDataint(jsonData);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
      
          fetchData();
        }, []);
      
        console.warn("result", dataint);

    //hobbies
        const [datahobby, setDatahobby] = useState([]);
      
        useEffect(() => {
          const fetchData = async () => {
            try {
              const response = await fetch(`https://matrimonyadmin.hola9.com/api/profileapi/hobbies/`);
              const jsonData = await response.json();
              setDatahobby(jsonData);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
      
          fetchData();
        }, []);
      
        console.warn("result", datahobby);


          const goBack = () => {
          navigate(-1);
    
  };
//your details

const [formData, setFormData] = useState({
  image: '',
  name: '',
  email: '',
  phone_number: '',
  address: '',
  state: '',
  city: '',
  country: '',
  zipcode: '',
  birth_date: '',
  age: '',
  gender: '',
  religion: '',
  caste: '',
  height: '',
  education: '',
  occupation: '',
  income: ''
});

const inputhandleChange = (e) => {
  const { name, value } = e.target;
  setFormData(prevState => ({
    ...prevState,
    [name]: value
  }));

  // Update age options based on gender
  if (name === "gender") {
    let a = [];
    if (value === "Male") {
      for (let i = 21; i <= 45; i++) {
        a.push(i);
      }
    } else if (value === "Female") {
      for (let i = 18; i <= 45; i++) {
       a.push(i);
      }
    }
    setFormData(prevState => ({
      ...prevState,
      age: '', // Reset age to force user selection
      ageOptions: a
    }));
  }
};
const handleSubmitinput = async (e) => {
  e.preventDefaultinput();

  // Here you can add your validation logic
  // For simplicity, I'm assuming all fields are required

  // Check if any field is empty
  for (const key in formData) {
    if (!formData[key]) {
      toast.errorinput(`${key} is required`);
      return;
    }
  }

  try {
    const response = await axios.post('https://matrimonyadmin.hola9.com/api/user/profiles/', formData);
    console.log(response.data); // Assuming your API returns some response data
    toast.success('Form submitted successfully!');
    // Clear form fields after successful submission
    setFormData({
      image: '',
      name: '',
      email: '',
      phone_number: '',
      address: '',
      state: '',
      city: '',
      country: '',
      zipcode: '',
      birth_date: '',
      age: '',
      gender: '',
      religion: '',
      caste: '',
      height: '',
      education: '',
      occupation: '',
      income: ''
    });
  } catch (errorinput) {
    console.errorinput('Error submitting form:', errorinput);
    toast.errorinput('Error submitting form. Please try again later.');
  }
};




//education details


const [ datae, setdatae] = useState({
  qualification: "",
  occupation: "",
  occupation_details: "",
  annual_income: "",
  employed_in: "",
  special_cases: "",
  working_location: ""
});

const [showForme, setShowForme] = useState(false);
const [isDataFetchede, setIsDataFetchede] = useState(false);      

  // Function to show success toast message   
const showToastMessageed = () => {
  toast.success("You Have Sucessfully filled Your Education Details", {
    position: toast.POSITION.TOP_CENTER,
  });

};

 // Function to show error toast message for incomplete fields
const showToastErrorMessageed = () => {
  toast.errored('please fill the All fields', {
    position: toast.POSITION.TOP_CENTER
  });
}

 // State to handle general errors
const [errored, setErrored] = useState(null);

// State to handle form validation errors
const [formErrored, setFormErrored] = useState({});


 // Function to handle form input changes
const ChangeHandlerinput = (e) => {
  setdatae({ ...datae, [e.target.name]: e.target.value });
};

const showDatae = async (e) => {
  e.preventDefaulted();
  try {
   
    const response = await axios.post("https://matrimonyadmin.hola9.com/api/EducationDetails/educationDetail/", datae);
    const id = response.datae.id; // Assuming the response contains the ID of the newly created resource
    
    setdatae({ ...datae, id });
    console.log(response.datae); // You can handle the response here
    setShowForme(true);
    }
  catch (errored) {
    console.errored(errored);
  }
};

const handleEdited = async (e) => {
  e.preventDefaulted();
  try {
    const response = await axios.get(`https://matrimonyadmin.hola9.com/api/EducationDetails/educationDetail/${datae.id}/`);
    const userData = response.datae;
    setdatae(userData);
    setIsDataFetchede(true);
  } catch (errored) {
    console.errored(errored);
  
  }
};

const handlePutSubmited = async (e) => {
  e.preventDefaulted();
  try {
    const response = await axios.put(`https://matrimonyadmin.hola9.com/api/EducationDetails/educationDetail/${datae.id}/`, datae);
    console.log(response.datae); // You can handle the response here
  } catch (errored) {
    console.errored(errored);
  }
};




  //family details

  const showToastMessagef = () => {
    toast.success("You Have Sucessfully filled Your Family Details", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const showToastErrorMessagef = () => {
    toast.errorf("please fill the All fields", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

const [Success, Setsuccess] = useState(null);
const [errorf, setErrorf] = useState(null);
const [dataf, setdataf] = useState({
family_values: "",
family_type: "",
family_status: "",
mother_tounge: "",
no_of_brothers: "",
no_of_brothers_married: "",
no_of_sisters: "",
no_of_sisters_married: "",
father_name: "",
father_occupation: "",
mother_name: "",
mother_occupation: "",
family_wealth: "",
about_family: "",
options: "",

});

const [showFormf, setShowFormf] = useState(false);
const [isDataFetchedf, setIsDataFetchedf] = useState(false);      

const [formErrorf, setFormErrorf] = useState({});

const inputChangeHandlerf = (e) => {
setdataf({ ...dataf, [e.target.name]: e.target.value });
};
const validateFormf = () => {
let err = {};
if (
  dataf.family_values === "" &&
  dataf.family_type === "" &&
  dataf.family_status === "" &&
  dataf.mother_tounge === "" &&
  dataf.no_of_brothers === "" &&
  dataf.no_of_brothers_married === "" &&
  dataf.no_of_sisters === "" &&
  dataf.no_of_sisters_married === "" &&
  dataf.father_name === "" &&
  dataf.father_occupation === "" &&
  dataf.mother_name === "" &&
  dataf.mother_occupation === "" &&
  dataf.family_wealth === "" &&
  dataf.options === "" &&
  dataf.about_family === ""
) {
  setFormErrorf(showToastErrorMessagef());
}
if (dataf.family_values === "") {
  err.family_values = "family_values!..";
}
if (dataf.family_type === "") {
  err.family_type = "family_type!..";
}
if (dataf.family_status === "") {
  err.family_status = "family_status!..";
}
if (dataf.mother_tounge === "") {
  err.mother_tounge = "mother_tounge!..";
}
if (dataf.no_of_brothers === "") {
  err.no_of_brothers = "no_of_brothers!..";
}
if (dataf.no_of_brothers_married === "") {
  err.no_of_brothers_married = "no_of_brothers_married !..";
}
if (dataf.no_of_sisters === "") {
  err.no_of_sisters = "no_of_sisters!..";
}
if (dataf.no_of_sisters_married === "") {
  err.no_of_sisters_married = "no_of_sisters_married!..";
}
if (dataf.father_name === "") {
  err.father_name = "father_name!..";
}

if (dataf.father_occupation === "") {
  err.father_occupation = "father_occupation!..";
}
if (dataf.mother_name === "") {
  err.mother_name = "mother_name!..";
}
if (dataf.mother_occupation === "") {
  err.mother_occupation = "mother_occupation!..";
}
if (dataf.family_wealth === "") {
  err.family_wealth = "family_wealth!..";
}
if (dataf.options === "") {
  err.options = "relative_info!..";
}
if (dataf.about_family === "") {
  err.about_family = "about_family!..";
}

setFormErrorf({ ...err });
return Object.keys(err).length < 1;
};


const showDataf = async (e) => {
e.preventDefaultf();
try {
  if(validateFormf){
  const response = await axios.post("https://matrimonyadmin.hola9.com/api/Familydeatilsapi/Familydetails/", dataf);
  const id = response.dataf.id; // Assuming the response contains the ID of the newly created resource
  
  setdataf({ ...dataf, id });
  console.log(response.dataf); // You can handle the response here
  setShowFormf(true);
  }
} catch (errorf) {
  console.errorf(errorf);
}
};

const handleEditf = async (e) => {
e.preventDefaultf();
try {
  const response = await axios.get(`https://matrimonyadmin.hola9.com/api/Familydeatilsapi/Familydetails/${dataf.id}/`);
  const userData = response.dataf;
  setdataf(userData);
  setIsDataFetchedf(true);
} catch (errorf) {
  console.errorf(errorf);

}
};

const handlePutSubmitf = async (e) => {
e.preventDefaultf();
try {
  const response = await axios.put(`https://matrimonyadmin.hola9.com/api/Familydeatilsapi/Familydetails/${dataf.id}/`, dataf);
  console.log(response.dataf); // You can handle the response here
} catch (errorf) {
  console.errorf(errorf);
}
};
  


  //horoscopic details

  const [ datah, setdatah] = useState({
    moon_sign:"",
    star:"",
    gotra:"",
    manglik:"",
    shani:"",
    hororscope_match:"",
    place_of_birth:"",
    place_of_country:"",
    hours:"",
    minitues:"",
    seconds:"",
    am_pm:"",
  });

  const [showFormh, setShowFormh] = useState(false);
  const [isDataFetchedh, setIsDataFetchedh] = useState(false); 

  
  const showToastMessageh = () => {
    toast.success("You Have Sucessfully filled Your Family Details", {
      position: toast.POSITION.TOP_CENTER,
    });

  };
  const showToastErrorMessageh = () => {
    toast.errorh('please fill the All fields', {
      position: toast.POSITION.TOP_CENTER
    });
  }

  const [errorh, setErrorh] = useState(null);

  const [formErrorh, setFormErrorh] = useState({});

   

  // const [Photograph , setPhotograph] = useState("");
  const inputChangeHandlerh = (e) => {
    setdatah({ ...datah, [e.target.name]: e.target.value });
  };

 
  const showDatah = async (e) => {
    e.preventDefaulth();
    try {
      const response = await axios.post("https://matrimonyadmin.hola9.com/api/Horoscopeapi/horoscopeList/",datah);
      const id = response.datah.id; 
      
      setdatah({ ...datah, id });
      console.log(response.datah); // You can handle the response here
      setShowFormh(true);
      }
    catch (errorh) {
      console.errorh(errorh);
    }
  };

  const handleEdith = async (e) => {
    e.preventDefaulth();
    try {
      const response = await axios.get(`https://matrimonyadmin.hola9.com/api/Horoscopeapi/horoscopeList/${datah.id}`);
      const userData = response.datah;
      setdatah(userData);
      setIsDataFetchedh(true);
    } catch (errorh) {
      console.errorh(errorh);
    
    
    }
  };

  const handlePutSubmith = async (e) => {
    e.preventDefaulth();
    try {
      const response = await axios.put(`https://matrimonyadmin.hola9.com/api/Horoscopeapi/horoscopeList/${datah.id}/`, datah);
      console.log(response.datah); // You can handle the response here
    } catch (errorh) {
      console.errorh(errorh);
    }
  };


  //patner preference
  
  
  const showToastErrorMessagepat = () => {
    toast.errorp('please fill the All fields', {
      position: toast.POSITION.TOP_CENTER
    });
  }
  const showToastMessage = () => {
    toast.success("You Have Sucessfully filled Your Partner Prefrence Details", {
      position: toast.POSITION.TOP_CENTER,
    });

  };
  
  const [datap, setPrefrenceInfo] = useState({
    looking_for: "",  
    age_from: "",
      age_to: "",
      height_from: "",
      height_to: "",
      relligion: "",
      state: "",
      caste: "",
      complexion: "",
      residency_status: "",
      country: "",
      education: "",
      occupation: "",
      partner_expectations: "",

  });
 
  const [showFormp, setShowFormp] = useState(false);
  const [isDataFetchedp, setIsDataFetchedp] = useState(false);      

  const [errorp, setErrorp] = useState(null);

  const [formErrorp, setFormErrorp] = useState({});

 
  const inputChangeHandler = (e) => {
    setPrefrenceInfo({ ...datap, [e.target.name]: e.target.value });
  };
  const validateFormp = () => {
    let err = {};
    if( 
     datap.looking_for === "" &&
     datap.age_from === "" &&
     datap.age_to==="" &&
     datap.height_from==="" &&
     datap.height_to==="" &&
     datap.relligion==="" &&
     datap.state==="" &&
     datap.caste==="" &&
     datap.complexion==="" &&
     datap.residency_status==="" &&
     datap.country==="" &&
     datap.education==="" &&
     datap.occupation === "" &&
     datap.partner_expectations==="" 
      ){
        setFormErrorp( showToastErrorMessagepat)
      }
    
    if (datap.age_from === "") {
      err.Age_1 = "Age_1 Required!..";
    }
    if (datap.age_to === "") {
      err.Age_2= "Age_2 Required!..";
    }
    if (datap.height_from === "") {
      err.Height_1 = "Height_1 Required!..";
    }
    if (datap.height_to=== "") {
      err.Height_2 = "Height_2 Required!..";
    }
    if (datap.relligion === "") {
      err.Relligion = "Relligion Required!..";
    }
    if (datap.state === "") {
      err.State = "State Required!..";
    }  
    if (datap.caste === "") {
      err.Caste = "Caste Required!..";
    }   
    if (datap.complexion === "") {
      err.Complexion = "Complexion Required!..";
    }   
    if (datap.residency_status === "") {
      err.Residency_Status = "Residency_Status Required!..";
    }
    if (datap.country === "") {
      err.Country = "Country name Required!..";
    }    
    if (datap.education === "") {
      err.Education = "Education Required!..";
    }    
    if (datap.occupation === "") {
      err.Occupation = "Occupation Required!..";
    }    
    if (datap.partner_expectations === "") {
      err.Partner_Expectations = "Partner_Expectations Required!..";
    }      
    if (datap.looking_for === "") {
      err.Looking_for = "Looking_for!..";
    } 
    setFormErrorp({ ...err });
    return Object.keys(err).length < 1;
  };
   const showDatap = async (e) => {
    e.preventDefault();
    try {
      if(validateFormp){
      const response = await axios.post("https://matrimonyadmin.hola9.com/api/partnerapi/partnerprefarence/",datap);
      const id = response.datap.id; // Assuming the response contains the ID of the newly created resource
      
      setPrefrenceInfo({ ...datap, id });
      console.log(response.datap); // You can handle the response here
      setShowFormp(true);
      }
    } catch (errorp) {
      console.errorp(errorp);
    }
  };

  const handleEditp = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`https://matrimonyadmin.hola9.com/api/partnerapi/partnerprefarence/${datap.id}/`);
      const userData = response.datap;
      setPrefrenceInfo(userData);
      setIsDataFetchedp(true);
    } catch (errorp) {
      console.errorp(errorp);
    
    }
  };

  const handlePutSubmitp = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`https://matrimonyadmin.hola9.com/api/partnerapi/partnerprefarence/${datap.id}/`,datap);
      console.log(response.datap); // You can handle the response here
    } catch (errorp) {
      console.errorp(errorp);
    }
  };


  //basic details

  
  const [datab, setdata] = useState({
    profile_created_By: "",
    marital_status: "",
    your_religion: "",
    religion: "",
    your_caste: "",
    sub_caste: "",
    about_yourself: "",
    Name: "",
    Surname: "",
    Email: "",
    Gender: "",
    Height: "",
    Blood_Group: "",
    D_O_B: "",
    Mobile_Number: "",
    Age: "",
  });


const [isSubmitted, setIsSubmitted] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);      

  // const [postId, setPostId] = useState(null);

  // const [isDataLoaded, setIsDataLoaded] = useState(false);

  // Function to show success toast message
  const showToastSuccesMessage = () => {
    toast.success("You Have Sucessfully filled Your basic Details", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  // Function to show error toast message
  const showToastErrorMessage = () => {
    toast.error("please fill the All fields", {
      position: toast.POSITION.TOP_CENTER,
    });
  };


  // State for handling errors
  const [error, setError] = useState(null);


  // State to manage form validation errors
  const [formError, setFormError] = useState({});

  const [Photograph, setPhotograph] = useState("");

  // Function to handle form field changes
  const ChangeHandler = (e) => {
    setdata({ ...datab, [
      e.target.name]: e.target.value });
  };

  // Function to validate the form
  const validateForm = (datab) => {
  let err = {};

  // Check if any required fields are empty
  if (
  datab.Name === "" &&
  datab.Surname === "" &&
  datab.Email === "" &&
  datab.Mobile_Number === "" &&
  datab.D_O_B === "" &&
  datab.Height === "" &&
  datab.Blood_Group === "" &&
  datab.Gender === "" &&
  datab.your_religion === "" &&
  datab.religion === "" &&
  datab.profile_created_By === "" &&
  datab.marital_status === "" &&
  datab.your_caste === "" &&
  datab.sub_caste === "" &&
  datab.Age === "" &&
  datab.about_yourself === ""
  ) {
  setError(showToastErrorMessage());
  }

  // Validate individual fields
  if (datab.Name === "") {
  err.Name = "Name Required!..";
  }
    if (datab.Surname === "") {
  err.Surname = "Surname!..";
  }
    if (datab.Email === "") {
  err.Email = "Email Required!..";
  }
    if (datab.Mobile_Number === "") {
  err.Mobile_Number = "Mobile_Number Required!..";
  }
   
  if(calculateAge(datab.D_O_B)<21){
    err.D_O_B='you must be at least 21 years old';
  }
    if (datab.Age === "") {
  err.Age = "Age!..";
  }
    if (datab.Height === "") {
  err.Height = "Height!..";
  }
    if (datab.Blood_Group === "") {
  err.Blood_Group = "Blood_Group!..";
  }
    if (datab.Gender === "") {
  err.Gender = "Gender!..";
  }
    if (datab.religion === "") {
  err.religion = "religion!..";
  }
    if (datab.your_religion === "") {
  err.your_religion = "Your_Religion!..";
  }
    if (datab.profile_created_By === "") {
  err.profile_created_By = "Profile_Created_By!..";
  }
    if (datab.marital_status === "") {
  err.marital_status = "Marital_Status!..";
  }
    if (datab.your_caste === "") {
  err.your_caste = "Your_Caste!..";
  }
    if (datab.sub_caste === "") {
  err.sub_caste = "Sub_Caste!..";
  }
    if (datab.about_yourself === "") {
  err.about_yourself = "About_Yourself!..";
  }

 

  // Set form errors
  setFormError({ ...err });

  // Return true if there are no errors
  return Object.keys(err).length < 1;
  };

  const calculateAge=(D_O_B)=>{
    const today=new Date();
    const birthDate=new Date(D_O_B);
    let age=today.getFullYear()-birthDate.getFullYear();
    const monthDiff=today.getMonth()-birthDate.getMonth();
    if(monthDiff<0 || (monthDiff===0 && today.getData()<birthDate.getDate())){
      age--;
    }
    return age;
  }

  const ageOptions = (Gender) => {
    if (Gender === 'Male') {
      return Array.from({ length: 26 }, (_, i) => i + 21);
    } else if (Gender === 'Female') {
      return Array.from({ length: 28 }, (_, i) => i + 18);
    } else {
      return [];
    }
  };


  const showData = async () => {
    try {
      if(validateForm){
      const response = await axios.post("https://matrimonyadmin.hola9.com/api/BasicinformationPageapi/BasicInformationList/", datab);
      const id = response.datab.id; // Assuming the response contains the ID of the newly created resource
      
      setdata({ ...datab, id });
      console.log(response.datab); // You can handle the response here
      setShowForm(true);
      setIsSubmitted(true); 
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = async () => {
    try {
      const response = await axios.get(`https://matrimonyadmin.hola9.com/api/BasicinformationPageapi/BasicInformationList/${datab.id}`);
      const userData = response.datab;
      setdata(userData);
      setIsDataFetched(true);
    } catch (error) {
      console.error(error);
    
    }
  };

  const handlePutSubmit = async () => {
    try {
      const response = await axios.put(`https://matrimonyadmin.hola9.com/api/BasicinformationPageapi/BasicInformationList/${datab.id}/`, datab);
      console.log(response.datab); // You can handle the response here
    } catch (error) {
      console.error(error);
    }
  };


  //interest link 

  const [interest, setInterest] = useState({
    sports: false,
    sports_details: '',
    movie: false,
    movie_details: '',
    books: false,
    books_details: '',
    travel: false,
    travel_details: '',
    volunteer_work: false,
    volunteer_work_details: ''
  });

  const handleChangeint = (field, value) => {
    setInterest(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const handleSubmitint = () => {
  
    axios.post('https://matrimonyadmin.hola9.com/api/profileapi/interests/', interest)
      .then(response => {
        console.log(response.data);
        toast.success('Form submitted successfully');
      })
      .catch(error => {
        console.error('Error:', error);
        toast.error('Failed to submit form');
      });
  };





//hobby details\

  const [hobbies, setHobbies] = useState({
    cooking: true,
    cooking_text: '',
    music: false,
    music_text: '',
    writing: false,
    writing_text: '',
    gaming: false,
    gaming_text: '',
    gardening: false,
    gardening_text: ''
  });

  const handleChange = (field, value) => {
    setHobbies(prevState => ({
      ...prevState,
      [field]: value
    }));
  };
  

  const handleSubmit = () => {
  
    axios.post('https://matrimonyadmin.hola9.com/api/profileapi/hobbies/', hobbies)
      .then(response => {
        console.log(response.data);
        toast.success('Form submitted successfully');
      })
      .catch(error => {
        console.error('Error:', error);
        toast.error('Failed to submit form');
      });
  };


 
  return (
    <div>

    <button onClick={goBack} className='back-button-paypro'>Go Back</button>
      <section className="about-section-two-indexpro mt-4pro mt-4pro d-lg-blockpro d-xl-blockpro d-md-blockpro" style={{padding:"100px"}}>
   
  <div className="anim-iconspro full-widthpro">
    <span
      className="icon icon-circle-1 wow zoomIn animated"
      style={{ visibility: "visible", animationName: "zoomIn" }}
    />
  </div>
  
  <div className="schedule-tabspro tabs-boxpro">
    <div className="btns-box">
      {/*Tabs Box*/}
      <ul className="tab-buttonspro clearfixpro">
        
        <li className=" active-btnpro spcespro">
          <span className="day">Mutual Matches</span>
          <a href='/Mutualmatches2'>
            <span className="datepro">0</span>
          </a>
        </li>
        

        {/* <a href="mutual_matches"></a> */}
       
        <li className="active-btnpro specspro" data-tab="#tab-1">
          <a href="/NewShortList">
            <span className="day">New Shortlists</span>
       
            <span className="datepro">0</span>
          </a>
        </li>
        
        
        <li className="active-btnpro specspro" data-tab="#tab-2">
          <span className="day">New Interest</span>
          <a href="/Newintrest">
            <span className="datepro">0</span>
          </a>
        </li>
        
        
        <li className="active-btnpro specspro" data-tab="#tab-3">
          <span className="day">New Viewed</span>
          <a href="/NewViewe">
            <span className="datepro">0</span>
          </a>
        </li>
        
        <li className="active-btnpro specspro" data-tab="#tab-4">
          <span className="day">Message</span>
          <a href="/Receivedmessage">
            <span className="datepro">0</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</section>


<div className="auto-containerpro" >
  <div className="rowpro">
    {/* Title Block */}
    <div className="feature-block-twopro 	col-lg-4pro col-md-6pro col-sm-12pro">
      <div className="col-lg-12pro order-md-1pro">
        <div
          className="gallery-item  wow fadeIn animated"
          style={{ visibility: "visible", animationName: "fadeIn" }}
        >
          <div className="image-boxpro ">
            <figure className="">
              <img
                src="https://images.unsplash.com/photo-1503023345310-bd7c1de61c7d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aHVtYW58ZW58MHx8MHx8fDA%3D&w=1000&q=80"
                alt=""
                className="img-thumbnail"
              />
            </figure>
            <div className="overlay-boxpro">
              <a
                href="photoprocess.php?image=gallary/2023_04_10_05_42_23IMG_20221030_133631_103.jpg&square=500"
                className="lightbox-image"
                data-fancybox="gallery"
              >
                <span className="icon fa fa-expand-arrows-alt" />
              </a>
            </div>
          </div>
        </div>
        <a href="upload_photo_gallary" className="theme-btnpro btnpro btn-style-onepro">
          <span className="btn-titlepro">Upload Photo</span>
        </a>
      </div>
    </div>
    {/* Feature Block */}

  
  
   
    <div
    
      className="feature-block-twopro col-lg-4pro col-md-6pro col-sm-6pro wow fadeInUp animated"
      style={{ visibility: "visible", animationName: "fadeInUp" }}
    >
       {/* {
         data.map((item) => */}
         {data.length > 0 && (
         
   
      <div className="inner-boxpro" >
        <div className="icon-boxpro">
          <img src="https://www.matrimonysoftware.in/icon/basic.png" alt="" />
        </div>
        <h4>
          <a href="contact">
             Your Details{" "}
            <i style={{ fontSize: 24,marginLeft:"10px" }} className="fa">
              
            </i>
          </a>{" "}
          
         <BsFillPencilFill className="editpro"  onClick={() => setShowModalyour(true)}/>
        </h4>
        <div className="textpro"><span>Image:</span> {data[data.length - 1].image}</div>
        <div className="textpro"><span>Name:</span> {data[data.length - 1].name}</div>
        <div className="textpro"><span>Email:</span> {data[data.length - 1].email}</div>
        <div className="textpro"><span>Phone_Number:</span> {data[data.length - 1].phone_number}</div>
        <div className="textpro"><span>Address:</span> {data[data.length - 1].address}</div>
        <div className="textpro"><span>State:</span> {data[data.length - 1].state}</div>
        <div className="textpro"><span>City:</span> {data[data.length - 1].city}</div>
        <div className="textpro"><span>Country:</span> {data[data.length - 1].country}</div>
        <div className="textpro"><span>Zipcode:</span> {data[data.length - 1].zipcode}</div>
        <div className="textpro"><span>Birth_date:</span> {data[data.length - 1].birth_date}</div>
        <div className="textpro"><span>Age:</span> {data[data.length - 1].age}</div>
        <div className="textpro"><span>Gender:</span> {data[data.length - 1].gender}</div>
        <div className="textpro"><span>Religion:</span> {data[data.length - 1].religion}</div>
        <div className="textpro"><span>Caste:</span> {data[data.length - 1].caste}</div>
        <div className="textpro"><span>Height:</span> {data[data.length - 1].height}</div>
        <div className="textpro"><span>Education:</span> {data[data.length - 1].education}</div>
        <div className="textpro"><span>Occupation:</span> {data[data.length - 1].occupation}</div>
        <div className="textpro"><span>Income:</span> {data[data.length - 1].income}</div>
        
      </div>

)}
{showModalyour && (
  <div className="modal fade show" id="exampleModalLong" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" style={{ display: 'block' }}>
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle" style={{marginLeft:"30%",color:"#ec167f"}}>Your Details</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowModalyour(false)}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
        
        <section className="newsletter-section contact-page-section"  >
            <div className="auto-container">
              <div className="alertmes-profile"></div>
              <div className="row-profile clearfix-profile mt-3-profile" style={{marginLeft:"10%"}} >
                <div className="form-column-profile col-lg-9-profile col-md-12-profile col-sm-12-profile" >
                  <div className="envelope-image-profile"  />
                  <div className="form-inner-profile"  >
                    <div className="contact-form-profile " >
                
                      <div className="row clearfix  mt-2 " style={{display:"flex",flexDirection:"column"}}> 
                      <div className='col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile'  >
                {/* <img className='profile-image'src='https://img.freepik.com/free-photo/portrait-young-beautiful-woman-with-smoky-eyes-makeup-pretty-young-adult-girl-posing-studio-closeup-attractive-female-face_186202-4439.jpg?size=626&ext=jpg&ga=GA1.1.1067376512.1700035937&semt=ais'></img>
                <h4 className='profile-text' >Image</h4> */}
                 <label className="label-profile"> Image : </label>
                <input
                 type='file'
                 name="image"
                 value={formData.image} 
                 onChange={inputhandleChange}
                 className="agile-ltext"
                 style={{marginLeft:"3%",width:"30vh"}}
                 />
                
                
            </div>
                      
                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> Name : </label>
                          <input
                            type="text"
                            className="agile-ltext"
                            placeholder="Enter your Name"
                            name="name"
                            value={formData.name} 
                            onChange={inputhandleChange}
                            readOnly=""
                            fdprocessedid="j85x88"
                           
                            tabIndex={5}
                            style={{marginLeft:"3%",width:"30vh"}}
                          />
                          <span style={{ color: "red" }}>
                            {/* {formError.name} */}
                            </span>
                        </div>
                       

                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile  form-group-profile ">
                          <label className="label-profile "> Email : </label>
                          <input
                            type="email"
                            placeholder="Email"
                            className="agile-ltext "
                            name="email"
                            value={formData.email} 
                            onChange={inputhandleChange}
                            
                           readOnly=""
                            fdprocessedid="j85x88" 
                            style={{marginLeft:"3%",width:"30vh"}}
                           
                          />
                          <span style={{ color: "red" }}>
                          {/* {formError.email} */}
                          </span>
                        </div>
                        <div className="col-lg-3-profile  col-md-5-profile  col-sm-6-profile  form-group-profile ">
                          <label className="label-profile"> PhoneNumber: </label>
                          <input
                            type="text"
                            name="phone_number"
                            placeholder="phone Number"
                            className="agile-ltext"
                            maxLength={14}
                            value={formData.phone_number} 
                            onChange={inputhandleChange}
                          
                            readOnly=""
                            fdprocessedid="hkxiz"
                            style={{marginLeft:"3%",width:"30vh"}}
                          />
                          <span style={{ color: "red" }}>
                          {/* {formError.phone_number} */}
                          </span>
                        </div>
                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> Address :</label>
                          <input
                            type="text"
                            className="agile-ltext"
                            placeholder="Enter your Address"
                            name="address"
                            value={formData.address} 
                            onChange={inputhandleChange}
                          
                            readOnly=""
                            fdprocessedid="j85x88"
                            style={{marginLeft:"3%",width:"30vh"}}
                          />
                          <span style={{ color: "red" }}>
                          {/* {formError.address} */}
                          </span>
                        </div>
                        <div className="col-lg-3-profile  col-md-5-profile  col-sm-6-profile  form-group-profile ">
                          <label className="label-profile "> State :</label>
                          <select
                            type="text"
                            className="agile-ltext"
                            placeholder="state "
                            name="state"
                            value={formData.state} 
                            onChange={inputhandleChange}
                            
                            readOnly=""
                            fdprocessedid="bwswso"
                            style={{marginLeft:"3%",width:"30vh"}}
                          >
                            <option value="" selected="">
                          Select State
                        </option>
                        <option value="Delhi">Delhi</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Pradesh">
                          Himachal Pradesh
                        </option>
                        <option value="Jammu and Kashmir">
                          Jammu and Kashmir
                        </option>
                        <option value="Kerala">Kerala</option>
                        <option value="Lakshadweep">Lakshadweep</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Orissa">Orissa</option>
                        <option value="Pondicherry">Pondicherry</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Bihar">Bihar</option>
                        <option value="West Bengal">West Bengal</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Arunachal Pradesh">
                          Arunachal Pradesh
                        </option>
                        <option value="Daman and Diu">Daman and Diu</option>
                        <option value="Goa">Goa</option>
                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Uttaranchal">Uttaranchal</option>
                        <option value="Out of India">Out of India</option>
                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                            
                          </select>
                          <span style={{ color: "red" }}>
                          {/* {formError.state} */}
                          </span>
                        </div>
                       
                    <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> City :</label>
                          <input
                            type="text"
                            className="agile-ltext"
                            placeholder="Enter your city"
                            name="city"
                            value={formData.city} 
                            onChange={inputhandleChange}
                          
                            readOnly=""
                            fdprocessedid="j85x88"
                            style={{marginLeft:"3%",width:"30vh"}}
                          />
                          <span style={{ color: "red" }}>
                          {/* {formError.city} */}
                          </span>
                        </div>
                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> Country : </label>
                          <select
                            type="text"
                            className="agile-ltext"
                            placeholder="country "
                            name="country"
                            value={formData.country} 
                            onChange={inputhandleChange}
                            
                            readOnly=""
                            fdprocessedid="bwswso"
                            style={{marginLeft:"3%",width:"30vh"}}
                          >
                            <option value="" selected="">
                              Select country
                            </option>
                            <option value="India">India</option>
                            <option value="Not India">Not India</option>
                            
                          </select>
                          <span style={{ color: "red" }}>
                          {/* {formError.country} */}
                          </span>
                        </div>
                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> Zipcode :</label>
                          <input
                            type="text"
                            className="agile-ltext"
                            placeholder="Enter your zipcode"
                            name="zipcode"
                            value={formData.zipcode} 
                            onChange={inputhandleChange}
                          
                            readOnly=""
                            fdprocessedid="j85x88"
                            style={{marginLeft:"3%",width:"30vh"}}
                          />
                          <span style={{ color: "red" }}>
                          {/* {formError.zipcode} */}
                          </span>
                        </div>

                        <div className="col-lg-3-profile  col-md-5-profile  col-sm-6-profile  form-group-profile ">
                          <label className="label-profile ">Date Of Birth :</label>
                          <input
                            type="datetime-local"
                            className="agile-ltext"
                            placeholder="Date of birth"
                            name="birth_date"
                            value={formData.birth_date} 
                            onChange={inputhandleChange}
                          
                            readOnly=""
                            fdprocessedid="tgf31"
                            style={{marginLeft:"3%",width:"30vh"}}
                          />
                          <span style={{ color: "red" }}>
                          {/* {formError.birth_date} */}
                          </span>
                        </div>
                       

                        
                        <div className="col-lg-3-profile  col-md-5-profile  col-sm-6-profile  form-group-profile ">
                          <label className="label-profile "> Gender :</label>
                          <select
                            type="text"
                            className="agile-ltext"
                            placeholder="Gender "
                            name="gender"
                            value={formData.gender} 
                            onChange={inputhandleChange}
                            required

                            readOnly=""
                            fdprocessedid="bwswso"
                            style={{marginLeft:"3%",width:"30vh"}}
                          >
                            
         
        
                         <option value="" disabled>Select Your Gender</option>
                         <option value="Male">Male</option>
                         <option value="Female">Female</option>
                         <option value="Others">Others</option>
                            
                          </select>
                          <span style={{ color: "red" }}>
                          {/* {formError.gender} */}
                          </span>
                        </div>

                        <div className="col-lg-3-profile  col-md-5-profile  col-sm-6-profile  form-group-profile ">
                          <label className="label-profile"> Age :</label>

                          <select
                            type="number"
                            className="agile-ltext "
                            // placeholder="Enter your age"
                            name="age"
                            value={formData.age} 
                            onChange={inputhandleChange}
                            placeholder="Age"
                            required
                            style={{marginLeft:"3%",width:"30vh"}}
          
                          >


                          <option value="" disabled>Select your Age</option>
                             {formData.ageOptions && formData.ageOptions.map(option => (
                            <option key={option} value={option}>{option}</option>
                             ))}
                            <option value={18}>18</option>
                            <option value={19}>19</option>
                            <option value={20}>20</option>
                            <option value={21}>21</option>
                            <option value={22}>22</option>
                            <option value={23}>23</option>
                            <option value={24}>24</option>
                            <option value={25}>25</option>
                            <option value={26}>26</option>
                            <option value={27}>27</option>
                            <option value={28}>28</option>
                            <option value={29}>29</option>
                            <option value={30}>30</option>
                            <option value={31}>31</option>
                            <option value={32}>32</option>
                            <option value={33}>33</option>
                            <option value={34}>34</option>
                            <option value={35}>35</option>
                            <option value={36}>36</option>
                            <option value={37}>37</option>
                            <option value={38}>38</option>
                            <option value={39}>39</option>
                            <option value={40}>40</option>
                            <option value={41}>41</option>
                            <option value={42}>42</option>
                            <option value={43}>43</option>
                            <option value={44}>44</option>
                            <option value={45}>45</option>
                          </select>
                          <span style={{ color: "red" }}>
                          {/* {formError.age} */}
                          </span>
                        </div>

                        <div className="col-lg-3-profile  col-md-5-profile  col-sm-6-profile  form-group-profile ">
                          <label className="label-profile">Religion : </label>
                          <select
                            type="text"
                            className="agile-ltext"
                            placeholder="Religion "
                            name="religion"
                            value={formData.religion} 
                            onChange={inputhandleChange}
                           
                            readOnly=""
                            fdprocessedid="0eqdt9"
                            style={{marginLeft:"3%",width:"30vh"}}
                          >
                            <option value="" selected="">
                              Select Religion
                            </option>
                            <option value="Christian">Any</option>
                            <option value="Christian">Christian</option>
                            <option value="Hindu">Hindu</option>
                            <option value="hindu Barahsaini">
                              hindu Barahsaini
                            </option>
                            <option value="Inter-Religion">
                              Inter-Religion
                            </option>
                            <option value="Jain">Jain</option>
                            <option value="Muslim">Muslim</option>
                            <option value="Sikh">Sikh</option>
                          </select>
                          <span style={{ color: "red" }}>
                          {/* {formError.religion} */}
                          </span>
                        </div>

                        
                        <div className="col-lg-3-profile  col-md-5-profile  col-sm-6-profile  form-group-profile ">
                          <label className="label-profile "> Caste :</label>
                          <select
                            type="text"
                            className="agile-ltext"
                            placeholder="your_caste"
                            name="caste"
                            value={formData.caste} 
                            onChange={inputhandleChange}
                            style={{marginLeft:"3%",width:"30vh"}}
                          >
                            {" "}
                            <option value="">Select your cast </option>
                            <option value="96K Kokanastha">
                              96K Kokanastha
                            </option>
                            <option value="Ad Dharmi">Ad Dharmi</option>
                            <option value="Adi Andhra">Adi Andhra</option>
                            <option value="Adi Dravida">Adi Dravida</option>
                            <option value="Adi Karnataka">Adi Karnataka</option>
                            <option value="Agarwal">Agarwal</option>
                            <option value="Agnikula Kshatriya">
                              Agnikula Kshatriya
                            </option>
                            <option value="Agri">Agri</option>
                            <option value="Ahom">Ahom</option>
                            <option value="Ambalavasi">Ambalavasi</option>
                            <option value="Amil Sindhi">Amil Sindhi</option>
                            <option value="Anglo Indian">Anglo Indian</option>
                            <option value="Araya">Araya</option>
                            <option value="Arekatica">Arekatica</option>
                            <option value="Arora">Arora</option>
                            <option value="Arunthathiyar">Arunthathiyar</option>
                            <option value="Arya Vysya">Arya Vysya</option>
                            <option value="Aryasamaj">Aryasamaj</option>
                            <option value="Ayyaraka">Ayyaraka</option>
                            <option value="Badaga">Badaga</option>
                            <option value="Bahi">Bahi</option>
                            <option value="Baibhand Sindhi">
                              Baibhand Sindhi
                            </option>
                            <option value="Baidya">Baidya</option>
                            <option value="Baishnab">Baishnab</option>
                            <option value="Baishya">Baishya</option>
                            <option value="Balija">Balija</option>
                            <option value="Banik">Banik</option>
                            <option value="Baniya">Baniya</option>
                            <option value="Banjara">Banjara</option>
                            <option value="Barai">Barai</option>
                            <option value="Bari">Bari</option>
                            <option value="Barujibi">Barujibi</option>
                            <option value="Bengali">Bengali</option>
                            <option value="Besta">Besta</option>
                            <option value="Bhandari">Bhandari</option>
                            <option value="Bhanusali Sindhi">
                              Bhanusali Sindhi
                            </option>
                            <option value="Bhatia">Bhatia</option>
                            <option value="Bhatia Sindhi">Bhatia Sindhi</option>
                            <option value="Bhatraju">Bhatraju</option>
                            <option value="Bhavasar Kshatriya">
                              Bhavasar Kshatriya
                            </option>
                            <option value="Bhavsar">Bhavsar</option>
                            <option value="Bhovi">Bhovi</option>
                            <option value="Bhumihar Brahmin">
                              Bhumihar Brahmin
                            </option>
                            <option value="Billava">Billava</option>
                            <option value="Bishnoi/Vishnoi">
                              Bishnoi/Vishnoi
                            </option>
                            <option value="Boyer">Boyer</option>
                            <option value="Brahmbatt">Brahmbatt</option>
                            <option value="Bunt">Bunt</option>
                            <option value="Bunt (Shetty)">Bunt (Shetty)</option>
                            <option value="Chambhar">Chambhar</option>
                            <option value="Chandraseniya Kayastha Prab">
                              Chandraseniya Kayastha Prab
                            </option>
                            <option value="Chandravanshi Kahar">
                              Chandravanshi Kahar
                            </option>
                            <option value="Chasa">Chasa</option>
                            <option value="Chattada Sri Vaishnava">
                              Chattada Sri Vaishnava
                            </option>
                            <option value="Chaudary">Chaudary</option>
                            <option value="Chaurasia">Chaurasia</option>
                            <option value="Chettiar">Chettiar</option>
                            <option value="Chhapru Sindhi">
                              Chhapru Sindhi
                            </option>
                            <option value="Chhetri">Chhetri</option>
                            <option value="Chippolu (Mera)">
                              Chippolu (Mera)
                            </option>
                            <option value="CKP">CKP</option>
                            <option value="Coorgi">Coorgi</option>
                            <option value="Dadu Sindhi">Dadu Sindhi</option>
                            <option value="Darji">Darji</option>
                            <option value="Dawoodi Bhora">Dawoodi Bhora</option>
                            <option value="Devadiga">Devadiga</option>
                            <option value="Devandra Kula Vellalar">
                              Devandra Kula Vellalar
                            </option>
                            <option value="Devang Koshthi">
                              Devang Koshthi
                            </option>
                            <option value="Devanga">Devanga</option>
                            <option value="Devar/Thevar/Mukkulathor">
                              Devar/Thevar/Mukkulathor
                            </option>
                            <option value="Devendra Kula Vellalar">
                              Devendra Kula Vellalar
                            </option>
                            <option value="Dhaneshawat Vaish">
                              Dhaneshawat Vaish
                            </option>
                            <option value="Dhangar">Dhangar</option>
                            <option value="Dheevara">Dheevara</option>
                            <option value="Dhiman">Dhiman</option>
                            <option value="Dhoba">Dhoba</option>
                            <option value="Dhobi">Dhobi</option>
                            <option value="Dumal">Dumal</option>
                            <option value="Dusadh (Paswan)">
                              Dusadh (Paswan)
                            </option>
                            <option value="Ediga">Ediga</option>
                            <option value="Ezhava">Ezhava</option>
                            <option value="Ezhuthachan">Ezhuthachan</option>
                            <option value="Gabit">Gabit</option>
                            <option value="Ganda">Ganda</option>
                            <option value="Gandla">Gandla</option>
                            <option value="Ganiga">Ganiga</option>
                            <option value="Ganigashetty">Ganigashetty</option>
                            <option value="Garhwali">Garhwali</option>
                            <option value="Garhwali Rajput">
                              Garhwali Rajput
                            </option>
                            <option value="Gavali">Gavali</option>
                            <option value="Gavara">Gavara</option>
                            <option value="Gawali">Gawali</option>
                            <option value="Ghisadi">Ghisadi</option>
                            <option value="Ghumar">Ghumar</option>
                            <option value="Goala">Goala</option>
                            <option value="Goan">Goan</option>
                            <option value="Gomantak">Gomantak</option>
                            <option value="Gomantak Maratha">
                              Gomantak Maratha
                            </option>
                            <option value="Gondhali">Gondhali</option>
                            <option value="Goswami">Goswami</option>
                            <option value="Goswami/Gosavi Brahmin">
                              Goswami/Gosavi Brahmin
                            </option>
                            <option value="Goud">Goud</option>
                            <option value="Gounder">Gounder</option>
                            <option value="Gowda">Gowda</option>
                            <option value="Gramani">Gramani</option>
                            <option value="Gudia">Gudia</option>
                            <option value="Gujarati">Gujarati</option>
                            <option value="Gujjar">Gujjar</option>
                            <option value="Gupta">Gupta</option>
                            <option value="Guptan">Guptan</option>
                            <option value="Gurav">Gurav</option>
                            <option value="Gurjar">Gurjar</option>
                            <option value="Hegde">Hegde</option>
                            <option value="Himachali">Himachali</option>
                            <option value="Hindu-Others">Hindu-Others</option>
                            <option value="Hugar (Jeer)">Hugar (Jeer)</option>
                            <option value="Hyderabadi Sindhi">
                              Hyderabadi Sindhi
                            </option>
                            <option value="Intercaste">Intercaste</option>
                            <option value="Irani">Irani</option>
                            <option value="Iyengar">Iyengar</option>
                            <option value="Iyer">Iyer</option>
                            <option value="Jaalari">Jaalari</option>
                            <option value="Jaiswal">Jaiswal</option>
                            <option value="Jandra">Jandra</option>
                            <option value="Jangam">Jangam</option>
                            <option value="Jat">Jat</option>
                            <option value="Jatav">Jatav</option>
                            <option value="Jeer">Jeer</option>
                            <option value="Jogi (Nath)">Jogi (Nath)</option>
                            <option value="Kachara">Kachara</option>
                            <option value="Kadava Patel">Kadava Patel</option>
                            <option value="Kahar">Kahar</option>
                            <option value="Kaibarta">Kaibarta</option>
                            <option value="Kalal">Kalal</option>
                            <option value="Kalar">Kalar</option>
                            <option value="Kalinga">Kalinga</option>
                            <option value="Kalinga Vysya">Kalinga Vysya</option>
                            <option value="Kalita">Kalita</option>
                            <option value="Kalwar">Kalwar</option>
                            <option value="Kamboj">Kamboj</option>
                            <option value="Kamma">Kamma</option>
                            <option value="Kannada Mogaveera">
                              Kannada Mogaveera
                            </option>
                            <option value="Kansari">Kansari</option>
                            <option value="Kapu">Kapu</option>
                            <option value="Kapu Naidu">Kapu Naidu</option>
                            <option value="Karana">Karana</option>
                            <option value="Karmakar">Karmakar</option>
                            <option value="Karuneegar">Karuneegar</option>
                            <option value="Karuneekar">Karuneekar</option>
                            <option value="Kasar">Kasar</option>
                            <option value="Kashyap">Kashyap</option>
                            <option value="Katiya">Katiya</option>
                            <option value="Kayastha">Kayastha</option>
                            <option value="Keralite">Keralite</option>
                            <option value="Khandayat">Khandayat</option>
                            <option value="Khandelwal">Khandelwal</option>
                            <option value="Kharwar">Kharwar</option>
                            <option value="Khatik">Khatik</option>
                            <option value="Khatri">Khatri</option>
                            <option value="Khukhrain">Khukhrain</option>
                            <option value="Koiri">Koiri</option>
                            <option value="Kokanastha Maratha">
                              Kokanastha Maratha
                            </option>
                            <option value="Koli">Koli</option>
                            <option value="Koli Mahadev">Koli Mahadev</option>
                            <option value="Kongu Vellala Gounder">
                              Kongu Vellala Gounder
                            </option>
                            <option value="Konkani">Konkani</option>
                            <option value="Kori">Kori</option>
                            <option value="Koshti">Koshti</option>
                            <option value="Kshatriya">Kshatriya</option>
                            <option value="Kudumbi">Kudumbi</option>
                            <option value="Kulal">Kulal</option>
                            <option value="Kulalar">Kulalar</option>
                            <option value="Kulita">Kulita</option>
                            <option value="Kumaoni">Kumaoni</option>
                            <option value="Kumaoni Rajput">
                              Kumaoni Rajput
                            </option>
                            <option value="Kumawat">Kumawat</option>
                            <option value="Kumbara">Kumbara</option>
                            <option value="Kumbhakar">Kumbhakar</option>
                            <option value="Kumbhar">Kumbhar</option>
                            <option value="Kumhar">Kumhar</option>
                            <option value="Kummari">Kummari</option>
                            <option value="Kunbi">Kunbi</option>
                            <option value="Kuravan">Kuravan</option>
                            <option value="Kurmi">Kurmi</option>
                            <option value="Kurmi Kshatriya">
                              Kurmi Kshatriya
                            </option>
                            <option value="Kuruba">Kuruba</option>
                            <option value="Kuruhina Shetty">
                              Kuruhina Shetty
                            </option>
                            <option value="Kurumbar">Kurumbar</option>
                            <option value="Kushwaha">Kushwaha</option>
                            <option value="Kushwaha (Koiri)">
                              Kushwaha (Koiri)
                            </option>
                            <option value="Kutchi">Kutchi</option>
                            <option value="Lambadi">Lambadi</option>
                            <option value="Lambani">Lambani</option>
                            <option value="Larai Sindhi">Larai Sindhi</option>
                            <option value="Larkana Sindhi">
                              Larkana Sindhi
                            </option>
                            <option value="Leva patel">Leva patel</option>
                            <option value="Leva Patidar">Leva Patidar</option>
                            <option value="Leva patil">Leva patil</option>
                            <option value="Lingayath">Lingayath</option>
                            <option value="Lodhi Rajput">Lodhi Rajput</option>
                            <option value="Lohana">Lohana</option>
                            <option value="Lohana Sindhi">Lohana Sindhi</option>
                            <option value="Lohar">Lohar</option>
                            <option value="Lubana">Lubana</option>
                            <option value="Madiga">Madiga</option>
                            <option value="Mahajan">Mahajan</option>
                            <option value="Mahar">Mahar</option>
                            <option value="Maharashtrian">Maharashtrian</option>
                            <option value="Mahendra">Mahendra</option>
                            <option value="Maheshwari">Maheshwari</option>
                            <option value="Mahishya">Mahishya</option>
                            <option value="Mahisya">Mahisya</option>
                            <option value="Majabi">Majabi</option>
                            <option value="Mala">Mala</option>
                            <option value="Malayalee">Malayalee</option>
                            <option value="Malayalee Namboodiri">
                              Malayalee Namboodiri
                            </option>
                            <option value="Mali">Mali</option>
                            <option value="Mallah">Mallah</option>
                            <option value="Mangalorean">Mangalorean</option>
                            <option value="Manipuri">Manipuri</option>
                            <option value="Mannuru Kapu">Mannuru Kapu</option>
                            <option value="Mapila">Mapila</option>
                            <option value="Maratha">Maratha</option>
                            <option value="Maruthuvar">Maruthuvar</option>
                            <option value="Marvar">Marvar</option>
                            <option value="Marwari">Marwari</option>
                            <option value="Matang">Matang</option>
                            <option value="Mathur">Mathur</option>
                            <option value="Maurya">Maurya</option>
                            <option value="Meena">Meena</option>
                            <option value="Meenavar">Meenavar</option>
                            <option value="Mehra">Mehra</option>
                            <option value="Menon">Menon</option>
                            <option value="Mera">Mera</option>
                            <option value="Meru">Meru</option>
                            <option value="Meru Darji">Meru Darji</option>
                            <option value="Mochi">Mochi</option>
                            <option value="Modak">Modak</option>
                            <option value="Mogaveera">Mogaveera</option>
                            <option value="Monchi">Monchi</option>
                            <option value="Mudaliar">Mudaliar</option>
                            <option value="Mudaliar - Senguntha">
                              Mudaliar - Senguntha
                            </option>
                            <option value="Mudaliar Arcot">
                              Mudaliar Arcot
                            </option>
                            <option value="Mudaliar Saiva">
                              Mudaliar Saiva
                            </option>
                            <option value="Mudaliyar">Mudaliyar</option>
                            <option value="Mudiraj">Mudiraj</option>
                            <option value="Mukkulathor">Mukkulathor</option>
                            <option value="Mukulathur">Mukulathur</option>
                            <option value="Munnuru Kapu">Munnuru Kapu</option>
                            <option value="Muthuraja">Muthuraja</option>
                            <option value="Naagavamsam">Naagavamsam</option>
                            <option value="Nadar">Nadar</option>
                            <option value="Nagaralu">Nagaralu</option>
                            <option value="Nai">Nai</option>
                            <option value="Naicker">Naicker</option>
                            <option value="Naidu">Naidu</option>
                            <option value="Naik">Naik</option>
                            <option value="Naik/Nayaka">Naik/Nayaka</option>
                            <option value="Naika">Naika</option>
                            <option value="Nair">Nair</option>
                            <option value="Nair Vaniya">Nair Vaniya</option>
                            <option value="Nair Vilakkithala">
                              Nair Vilakkithala
                            </option>
                            <option value="Namasudra">Namasudra</option>
                            <option value="Nambiar">Nambiar</option>
                            <option value="Namboodiri">Namboodiri</option>
                            <option value="Namosudra">Namosudra</option>
                            <option value="Napit">Napit</option>
                            <option value="Nath">Nath</option>
                            <option value="Nayaka">Nayaka</option>
                            <option value="Neeli">Neeli</option>
                            <option value="Nepali">Nepali</option>
                            <option value="Nhavi">Nhavi</option>
                            <option value="OBC/Barber/Naayee">
                              OBC/Barber/Naayee
                            </option>
                            <option value="Oriya">Oriya</option>
                            <option value="Oswal">Oswal</option>
                            <option value="Otari">Otari</option>
                            <option value="Padmasali">Padmasali</option>
                            <option value="Padmashali">Padmashali</option>
                            <option value="Pal">Pal</option>
                            <option value="Panchal">Panchal</option>
                            <option value="Pandaram">Pandaram</option>
                            <option value="Panicker">Panicker</option>
                            <option value="Parkava Kulam">Parkava Kulam</option>
                            <option value="Parsi">Parsi</option>
                            <option value="Partraj">Partraj</option>
                            <option value="Pasi">Pasi</option>
                            <option value="Paswan">Paswan</option>
                            <option value="Patel">Patel</option>
                            <option value="Patel Desai">Patel Desai</option>
                            <option value="Patel Dodia">Patel Dodia</option>
                            <option value="Patel Kadva">Patel Kadva</option>
                            <option value="Patel Leva">Patel Leva</option>
                            <option value="Pathare Prabhu">
                              Pathare Prabhu
                            </option>
                            <option value="Patil">Patil</option>
                            <option value="Patil Leva">Patil Leva</option>
                            <option value="Patnaick">Patnaick</option>
                            <option value="Patnaick/Sistakaranam">
                              Patnaick/Sistakaranam
                            </option>
                            <option value="Patra">Patra</option>
                            <option value="Perika">Perika</option>
                            <option value="Pillai">Pillai</option>
                            <option value="Poosala">Poosala</option>
                            <option value="Porwal">Porwal</option>
                            <option value="Prajapati">Prajapati</option>
                            <option value="Punjabi">Punjabi</option>
                            <option value="Raigar">Raigar</option>
                            <option value="Rajaka">Rajaka</option>
                            <option value="Rajastani">Rajastani</option>
                            <option value="Rajbhar">Rajbhar</option>
                            <option value="Rajbonshi">Rajbonshi</option>
                            <option value="Rajput">Rajput</option>
                            <option value="Rajput Rohella/Tank">
                              Rajput Rohella/Tank
                            </option>
                            <option value="Ramdasia">Ramdasia</option>
                            <option value="Ramgariah">Ramgariah</option>
                            <option value="Ravidasia">Ravidasia</option>
                            <option value="Rawat">Rawat</option>
                            <option value="Reddy">Reddy</option>
                            <option value="Relli">Relli</option>
                            <option value="Rohiri Sindhi">Rohiri Sindhi</option>
                            <option value="Sadgope">Sadgope</option>
                            <option value="Saha">Saha</option>
                            <option value="Sahiti Sindhi">Sahiti Sindhi</option>
                            <option value="Sahu">Sahu</option>
                            <option value="Saini">Saini</option>
                            <option value="Sakkhar Sindhi">
                              Sakkhar Sindhi
                            </option>
                            <option value="Saliya">Saliya</option>
                            <option value="Saurashtra">Saurashtra</option>
                            <option value="Savji">Savji</option>
                            <option value="Scheduled Caste">
                              Scheduled Caste
                            </option>
                            <option value="Scheduled Tribe">
                              Scheduled Tribe
                            </option>
                            <option value="Sehwani Sindhi">
                              Sehwani Sindhi
                            </option>
                            <option value="Senai Thalaivar">
                              Senai Thalaivar
                            </option>
                            <option value="Senguntha Mudaliyar">
                              Senguntha Mudaliyar
                            </option>
                            <option value="Sepahia">Sepahia</option>
                            <option value="Setti Balija">Setti Balija</option>
                            <option value="Settibalija">Settibalija</option>
                            <option value="Shah">Shah</option>
                            <option value="Shetty">Shetty</option>
                            <option value="Shikarpuri Sindhi">
                              Shikarpuri Sindhi
                            </option>
                            <option value="Shimpi">Shimpi</option>
                            <option value="Sindhi">Sindhi</option>
                            <option value="Sindhi-Amil">Sindhi-Amil</option>
                            <option value="Sindhi-Baibhand">
                              Sindhi-Baibhand
                            </option>
                            <option value="Sindhi-Bhanusali">
                              Sindhi-Bhanusali
                            </option>
                            <option value="Sindhi-Bhatia">Sindhi-Bhatia</option>
                            <option value="Sindhi-Chhapru">
                              Sindhi-Chhapru
                            </option>
                            <option value="Sindhi-Dadu">Sindhi-Dadu</option>
                            <option value="Sindhi-Hyderabadi">
                              Sindhi-Hyderabadi
                            </option>
                            <option value="Sindhi-Larai">Sindhi-Larai</option>
                            <option value="Sindhi-Larkana">
                              Sindhi-Larkana
                            </option>
                            <option value="Sindhi-Lohana">Sindhi-Lohana</option>
                            <option value="Sindhi-Rohiri">Sindhi-Rohiri</option>
                            <option value="Sindhi-Sahiti">Sindhi-Sahiti</option>
                            <option value="Sindhi-Sakkhar">
                              Sindhi-Sakkhar
                            </option>
                            <option value="Sindhi-Sehwani">
                              Sindhi-Sehwani
                            </option>
                            <option value="Sindhi-Shikarpuri">
                              Sindhi-Shikarpuri
                            </option>
                            <option value="Sindhi-Thatai">Sindhi-Thatai</option>
                            <option value="SKP">SKP</option>
                            <option value="Somvanshi">Somvanshi</option>
                            <option value="Sonam">Sonam</option>
                            <option value="Sonar">Sonar</option>
                            <option value="Soni">Soni</option>
                            <option value="Sourashtra">Sourashtra</option>
                            <option value="Sozhiya Vellalar">
                              Sozhiya Vellalar
                            </option>
                            <option value="Srisayana">Srisayana</option>
                            <option value="Srisayani">Srisayani</option>
                            <option value="Sugali (Naika)">
                              Sugali (Naika)
                            </option>
                            <option value="Sunari">Sunari</option>
                            <option value="Sundhi">Sundhi</option>
                            <option value="Sutar">Sutar</option>
                            <option value="Suthar">Suthar</option>
                            <option value="Swakula Sali">Swakula Sali</option>
                            <option value="Swarnakar">Swarnakar</option>
                            <option value="Tamboli">Tamboli</option>
                            <option value="Tamil">Tamil</option>
                            <option value="Tamil Yadava">Tamil Yadava</option>
                            <option value="Tanti">Tanti</option>
                            <option value="Tantubai">Tantubai</option>
                            <option value="Tantuway">Tantuway</option>
                            <option value="Telaga">Telaga</option>
                            <option value="Teli">Teli</option>
                            <option value="Telugu">Telugu</option>
                            <option value="Thakkar">Thakkar</option>
                            <option value="Thakore">Thakore</option>
                            <option value="Thakur">Thakur</option>
                            <option value="Thatai Sindhi">Thatai Sindhi</option>
                            <option value="Thigala">Thigala</option>
                            <option value="Thiyya">Thiyya</option>
                            <option value="Tili">Tili</option>
                            <option value="Togata">Togata</option>
                            <option value="Tonk Kshatriya">
                              Tonk Kshatriya
                            </option>
                            <option value="Turupu Kapu">Turupu Kapu</option>
                            <option value="Udayar">Udayar</option>
                            <option value="Uppara">Uppara</option>
                            <option value="Urali Gounder">Urali Gounder</option>
                            <option value="Urs">Urs</option>
                            <option value="Vada Balija">Vada Balija</option>
                            <option value="Vadagalai">Vadagalai</option>
                            <option value="Vaddera">Vaddera</option>
                            <option value="Vaish">Vaish</option>
                            <option value="Vaishnav">Vaishnav</option>
                            <option value="Vaishnav Bhatia">
                              Vaishnav Bhatia
                            </option>
                            <option value="Vaishnav Vania">
                              Vaishnav Vania
                            </option>
                            <option value="Vaishnav Vanik">
                              Vaishnav Vanik
                            </option>
                            <option value="Vaishnava">Vaishnava</option>
                            <option value="Vaishya">Vaishya</option>
                            <option value="Vaishya Vani">Vaishya Vani</option>
                            <option value="Valluvan">Valluvan</option>
                            <option value="Valmiki">Valmiki</option>
                            <option value="Vania">Vania</option>
                            <option value="Vaniya">Vaniya</option>
                            <option value="Vanjara">Vanjara</option>
                            <option value="Vanjari">Vanjari</option>
                            <option value="Vankar">Vankar</option>
                            <option value="Vannar">Vannar</option>
                            <option value="Vannia Kula Kshatriyar">
                              Vannia Kula Kshatriyar
                            </option>
                            <option value="Vanniyar">Vanniyar</option>
                            <option value="Variar">Variar</option>
                            <option value="Varshney">Varshney</option>
                            <option value="Veera Saivam">Veera Saivam</option>
                            <option value="Veerashaiva">Veerashaiva</option>
                            <option value="Velaan/Vellalar">
                              Velaan/Vellalar
                            </option>
                            <option value="Velama">Velama</option>
                            <option value="Vellalar">Vellalar</option>
                            <option value="Vellalar Devandra Kula">
                              Vellalar Devandra Kula
                            </option>
                            <option value="Veluthedathu Nair">
                              Veluthedathu Nair
                            </option>
                            <option value="Vettuva Gounder">
                              Vettuva Gounder
                            </option>
                            <option value="Vilakkithala Nair">
                              Vilakkithala Nair
                            </option>
                            <option value="Vishwakarma">Vishwakarma</option>
                            <option value="Viswabrahmin">Viswabrahmin</option>
                            <option value="Vokkaliga">Vokkaliga</option>
                            <option value="Vysya">Vysya</option>
                            <option value="Yadav">Yadav</option>
                            <option value="Yellapu">Yellapu</option>
                          </select>
                          <span style={{ color: "red" }}>
                          {/* {formError.caste} */}
                          </span>
                        </div>

                        <div className="col-lg-3-profile  col-md-5-profile  col-sm-6-profile  form-group-profile ">
                          <label className="label-profile"> Height : </label>
                          <select
                            type="text"
                            className="agile-ltext"
                            placeholder="Select Height"
                            name="height"
                            value={formData.height} 
                            onChange={inputhandleChange}
                            
                            readOnly=""
                            fdprocessedid="jdvgst"
                            style={{marginLeft:"3%",width:"30vh"}}
                          >
                            <option value="">Select Height</option>
                            <option value={1}>4Ft </option>
                            <option value={2}>4Ft 1 inch </option>
                            <option value={3}>4Ft 2 inch </option>
                            <option value={4}>4Ft 3 inch </option>
                            <option value={5}>4Ft 4 inch </option>
                            <option value={6}>4Ft 5 inch </option>
                            <option value={7}>4Ft 6 inch </option>
                            <option value={8}>4Ft 7 inch </option>
                            <option value={9}>4Ft 8 inch </option>
                            <option value={10}>4Ft 9 inch </option>
                            <option value={11}>4Ft 10 inch </option>
                            <option value={12}>4Ft 11 inch </option>
                            <option value={13}>5Ft </option>
                            <option value={14}>5Ft 1 inch </option>
                            <option value={15}>5Ft 2 inch </option>
                            <option value={16}>5Ft 3 inch </option>
                            <option value={17}>5Ft 4 inch </option>
                            <option value={18}>5Ft 5 inch </option>
                            <option value={19}>5Ft 6 inch </option>
                            <option value={20}>5Ft 7 inch </option>
                            <option value={21}>5Ft 8 inch </option>
                            <option value={22}>5Ft 9 inch </option>
                            <option value={23}>5Ft 10 inch </option>
                            <option value={24}>5Ft 11 inch </option>
                            <option value={25}>6Ft </option>
                            <option value={26}>6Ft 1 inch </option>
                            <option value={27}>6Ft 2 inch </option>
                            <option value={28}>6Ft 3 inch </option>
                            <option value={29}>6Ft 4 inch </option>
                            <option value={30}>6Ft 5 inch </option>
                            <option value={31}>6Ft 6 inch </option>
                            <option value={32}>6Ft 7 inch </option>
                            <option value={33}>6Ft 8 inch </option>
                            <option value={34}>6Ft 9 inch </option>
                            <option value={35}>6Ft 10 inch </option>
                            <option value={36}>6Ft 11 inch </option>
                            <option value={37}>7Ft </option>
                          </select>
                          <span style={{ color: "red" }}>
                          {/* {formError.height} */}
                          </span>
                        </div>

                        <div
                      className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile"
                      // style={{ paddingRight: "140px" }}
                    >
                      <label className="label-profile"> Education :</label>
                      {/* <span className="multiselect-native-select"> */}
                      <select
                        className="agile-ltext"
                        name="education"
                        value={formData.education} 
                            onChange={inputhandleChange}
                       
                        required=""
                        tabIndex={8}
                        style={{marginLeft:"3%",width:"30vh"}}
                      >
                        {/* <option value="MCA/PGDCA" selected="">
                        MCA/PGDCA
                      </option>{" "} */}
                        {/* <option value="Any">Any</option> */}
                        <option value="" selected="">
                          Select Education
                        </option>
                        <option value="12th">12th</option>
                        <option value="B.A. (Arts)		">B.A. (Arts) </option>
                        <option value="B.Com (Commerce)">
                          B.Com (Commerce)
                        </option>
                        <option value="B.Sc (Science)">B.Sc (Science)</option>
                        <option value="B.Arch (Architecture)	">
                          B.Arch (Architecture){" "}
                        </option>
                        <option value="B.Ed (Education)	">
                          B.Ed (Education){" "}
                        </option>
                        <option value="B.El.Ed (Elementary Education)	">
                          B.El.Ed (Elementary Education){" "}
                        </option>
                        <option value="B.Lib.Sc (Library Sciences)	">
                          B.Lib.Sc (Library Sciences){" "}
                        </option>
                        <option value="B.P.Ed. (Physical Education)">
                          B.P.Ed. (Physical Education)
                        </option>
                        <option value="B.Plan (Planning)	">
                          B.Plan (Planning){" "}
                        </option>
                        <option value="	Bachelor of Fashion Technology		">
                          {" "}
                          Bachelor of Fashion Technology{" "}
                        </option>
                        <option value="	BBA/BBM/BBS	"> BBA/BBM/BBS </option>
                        <option value="BCA (Computer Application)		">
                          BCA (Computer Application){" "}
                        </option>
                        <option value="BE B.Tech (Engineering)">
                          BE B.Tech (Engineering)
                        </option>
                        <option value="BFA (Fine Arts)">BFA (Fine Arts)</option>
                        <option value="BHM (Hotel Management)	">
                          BHM (Hotel Management){" "}
                        </option>
                        <option value="BL/LLB/BGL (Law)">
                          BL/LLB/BGL (Law)
                        </option>
                        <option value="BSW (Social Work)">
                          BSW (Social Work)
                        </option>
                        <option value="	B.Pharm (Pharmacy)		">
                          {" "}
                          B.Pharm (Pharmacy){" "}
                        </option>
                        <option value="	B.V.Sc. (Veterinary Science)">
                          {" "}
                          B.V.Sc. (Veterinary Science)
                        </option>
                        <option value="BDS (Dental Surgery)	">
                          BDS (Dental Surgery){" "}
                        </option>
                        <option value="BHMS (Homeopathy)">
                          BHMS (Homeopathy)
                        </option>
                        <option value="CA (Chartered Accountant)	">
                          CA (Chartered Accountant){" "}
                        </option>
                        <option value="CFA (Chartered Financial Analyst)">
                          CFA (Chartered Financial Analyst)
                        </option>
                        <option value="CS (Company Secretary)">
                          CS (Company Secretary)
                        </option>
                        <option value="ICWA">ICWA</option>
                        <option value="Integrated PG">Integrated PG</option>
                        <option value="Engineering">Engineering</option>
                        <option value="Fashion/ Design	">
                          Fashion/ Design{" "}
                        </option>
                        <option value="Languages	">Languages </option>
                        <option value="Pilot Licenses	">Pilot Licenses </option>
                        <option value="M.Arch. (Architecture)	">
                          M.Arch. (Architecture){" "}
                        </option>
                        <option value="M.Ed. (Education)">
                          M.Ed. (Education)
                        </option>
                        <option value="M.Lib.Sc. (Library Sciences)">
                          M.Lib.Sc. (Library Sciences)
                        </option>
                        <option value="M.Plan. (Planning)">
                          M.Plan. (Planning)
                        </option>
                        <option value="Master of Fashion Technology">
                          Master of Fashion Technology
                        </option>
                        <option value="Master of Health Administration	">
                          Master of Health Administration{" "}
                        </option>
                        <option value="Master of Hospital Administration">
                          Master of Hospital Administration
                        </option>
                        <option value="MBA/PGDM">MBA/PGDM</option>
                        <option value="MCA PGDCA part time	">
                          MCA PGDCA part time{" "}
                        </option>
                        <option value="MCA/PGDCA">MCA/PGDCA</option>
                        <option value="ME/M.Tech/MS (Engg/Sciences)">
                          ME/M.Tech/MS (Engg/Sciences)
                        </option>
                        <option value="MFA (Fine Arts)	">
                          MFA (Fine Arts){" "}
                        </option>
                        <option value="ML/LLM (Law)	">ML/LLM (Law) </option>
                        <option value="MSW (Social Work)	">
                          MSW (Social Work){" "}
                        </option>
                        <option value="PG Diploma">PG Diploma</option>
                        <option value="M.Com. (Commerce)">
                          M.Com. (Commerce)
                        </option>
                        <option value="M.Sc. (Science)">M.Sc. (Science)</option>
                        <option value="MA (Arts)">MA (Arts)</option>
                        <option value="M.Pharm. (Pharmacy)	">
                          M.Pharm. (Pharmacy){" "}
                        </option>
                        <option value="M.V.Sc. (Veterinary Science)">
                          M.V.Sc. (Veterinary Science)
                        </option>
                        <option value="MBBS">MBBS</option>
                        <option value="MD/ MS (Medicine)">
                          MD/ MS (Medicine)
                        </option>
                        <option value="MDS (Master of Dental Surgery)">
                          MDS (Master of Dental Surgery)
                        </option>
                        <option value="BPT (Physiotherapy">
                          BPT (Physiotherapy)
                        </option>
                        <option value="MPT (Physiotherapy)">
                          MPT (Physiotherapy)
                        </option>
                        <option value="M.Phil. (Philosophy)">
                          M.Phil. (Philosophy)
                        </option>
                        <option value="Ph.D. (Doctorate)	">
                          Ph.D. (Doctorate){" "}
                        </option>
                        <option value="Other Doctorate">Other Doctorate</option>
                        <option value="Other Diploma">Other Diploma</option>
                        <option value="Agriculture">Agriculture</option>
                        <option value="10th">10th</option>{" "}
                      </select>
                      <span style={{ color: "red" }}>
                      {/* {formError.education} */}
                      </span>
                      <div className="btn-group-profile" >
                        {/* <button
                        type="button"
                        className="multiselect dropdown-toggle custom-select"
                        data-toggle="dropdown"
                        title="MCA/PGDCA"
                        style={{
                          width: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis"
                        }}
                      >
                        <span className="multiselect-selected-text">
                          MCA/PGDCA
                        </span>
                      </button> */}
                      </div>
                      {/* </span> */}
                    </div>
                    
                    <div className='col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile'>
                          <label className="label-profile"  > Occupation :</label>
                          <input
                            type="text"
                            className="agile-ltext"
                            placeholder="Enter  occupation"
                            name="occupation"
                            // style={{marginLeft:"10%"}}
                            value={formData.occupation} 
                            onChange={inputhandleChange}
                          
                            readOnly=""
                            fdprocessedid="j85x88"
                            style={{marginLeft:"3%",width:"30vh"}}
                          />
                          <span style={{ color: "red" }}>
                          {/* {formError.occupation} */}
                          </span>
                        </div>

                        <div className='col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile'>
                          <label className="label-profile" > Income :</label>
                          <input
                            type="number"
                            className="agile-ltext"
                            placeholder="Enter your income"
                            name="income"
                          
                            value={formData.income} 
                            onChange={inputhandleChange}
                          
                            readOnly=""
                            fdprocessedid="j85x88"
                            style={{marginLeft:"3%",width:"30vh"}}
                          />
                          <span style={{ color: "red" }}>
                          {/* {formError.income} */}
                          </span>
                        </div>

                       
                        {/* <div className="col-lg-10 col-md-10 col-sm-6 form-group" style={{marginLeft:"100px"}}>
                            <button
                              className="theme-btn btn btn-style-one mt-4"
                              type="submit"
                              name="submit"
                              // style={{ width: "auto", marginLeft: "450px" }}
                              style={{ width: "60%", marginLeft: "140px" }}
                              fdprocessedid="9hzcb"
                            >
                              <span className="btn-title" tabIndex={14}>
                                Update
                              </span>
                            </button>
                          </div> */}
                       
                      </div>
                      <div className="col-lg-10-profile  col-md-10-profile  col-sm-6-profile  form-group-profile ">
                          {/* <Link to="/"> */}
                            <button
                            type="submit"
                              className="theme-btn  btn  btn-style-one mt-4"
                              fdprocessedid="jqtd0p"
                              // onClick={showData}
                              onClick={handleSubmitinput}
                              style={{width:'10%',marginLeft:"2%"}}
                            >
                              <span className="btn-title-profile" tabIndex={17}>
                                 Submit
                              </span>
                            </button>
                          {/* </Link> */}
                        </div>
                      {/* </Form> */}
                      <ToastContainer style={{ marginTop: "100px" }} />
                      {/* {error ? (
                        <div
                          className="theme-text -style-one mt-4"
                          style={{ color: "red" }}
                        >
                          <h4>{error}</h4>
                        </div>
                      ) : null} */}
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
          </section>


        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-dismiss="modal" style={{width:"20%",marginTop:"2%",marginRight:"40%"}}
          onClick={() => setShowModalyour(false)}>Close</button>
         
        </div>
      </div>
    </div>
  </div>
)}
</div>
    

    {/* Feature Block */}
    <div
      className="feature-block-twopro col-lg-4pro col-md-6pro col-sm-6pro wow fadeInUp animated"
      style={{ visibility: "visible", animationName: "fadeInUp" }}
    >
       {dataedu.length > 0 && (
      <div className="inner-boxpro">
        <div className="icon-boxpro">
          <img
            src="https://www.matrimonysoftware.in/icon/education.png"
            alt=""
          />
        </div>
        <h4>
          <a href="education">
            Education Details{" "}
            <i style={{ fontSize: 24 }} className="fa">
              
            </i>
          </a>
          <BsFillPencilFill className="editpro"  onClick={() => setShowModaledu(true)}/>
        </h4>

        <div className="textpro"><span>Quatificatin:</span> {dataedu[dataedu.length - 1].qualification}</div>
        <div className="textpro"><span>Occupation:</span> {dataedu[dataedu.length - 1].occupation}</div>
        <div className="textpro"><span>Occupation Details:</span> {dataedu[dataedu.length - 1].occupation_details}</div>
        <div className="textpro"><span>Annual Income:</span> {dataedu[dataedu.length - 1].annual_income}</div>
        <div className="textpro"><span>Employed In:</span> {dataedu[dataedu.length - 1].employed_in}</div>
        <div className="textpro"><span>Working Location:</span> {dataedu[dataedu.length - 1].working_location}</div>
        <div className="textpro"><span>Special Cases:</span> {dataedu[dataedu.length - 1].special_cases}</div>
        
      </div>
      )}
      {showModaledu && (
        <div className="modal fade show" id="exampleModalLong" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle" style={{marginLeft:"30%",color:"#ec167f"}}>Education Details</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowModaledu(false)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">

              <section className="newsletter-sectionEd contact-page-sectionEd">
          <div className="auto-containerEd">
            <div className="alertmesEd"></div>
            <div className="rowEd clearfixED mt-3Ed">
              {/* Form Column */}
              <div className="form-columnEd col-lg-9Ed col-md-10Ed col-sm-12Ed">
                <div className="envelope-imageEd" />
                <div className="form-innerEd">
                  <div className="contact-formEd">
                    <form>
                    
                      <div className="row clearfix" style={{display:"flex",flexDirection:"column"}}>
                        <div className="col-lg-3Ed col-md-5Ed col-sm-6Ed form-groupEd">
                          <label className="labelEd" style={{marginLeft:"3%"}}>Qualification</label>
                          <select
                            className="custom-select-box"
                            name="qualification"
                            id="Qualification"
                            tabIndex={1}
                            required
                            fdprocessedid="7ydrx"
                            onChange={ChangeHandlerinput}
                            value={datae.qualification}
                            style={{width:"32vh",marginLeft:"2%"}}
                          >
                            <option value="" selected="">
                            Qualification
                            </option>
                            <option value="12th">12th</option>
                            <option value="B.A. (Arts)		">B.A. (Arts) </option>
                            <option value="B.Com (Commerce)">
                              B.Com (Commerce)
                            </option>
                            <option value="B.Sc (Science)">
                              B.Sc (Science)
                            </option>
                            <option value="B.Arch (Architecture)	">
                              B.Arch (Architecture){" "}
                            </option>
                            <option value="B.Ed (Education)	">
                              B.Ed (Education){" "}
                            </option>
                            <option value="B.El.Ed (Elementary Education)	">
                              B.El.Ed (Elementary Education){" "}
                            </option>
                            <option value="B.Lib.Sc (Library Sciences)	">
                              B.Lib.Sc (Library Sciences){" "}
                            </option>
                            <option value="B.P.Ed. (Physical Education)">
                              B.P.Ed. (Physical Education)
                            </option>
                            <option value="B.Plan (Planning)	">
                              B.Plan (Planning){" "}
                            </option>
                            <option value="	Bachelor of Fashion Technology		">
                              {" "}
                              Bachelor of Fashion Technology{" "}
                            </option>
                            <option value="	BBA/BBM/BBS	"> BBA/BBM/BBS </option>
                            <option value="BCA (Computer Application)		">
                              BCA (Computer Application){" "}
                            </option>
                            <option value="BE B.Tech (Engineering)">
                              BE B.Tech (Engineering)
                            </option>
                            <option value="BFA (Fine Arts)">
                              BFA (Fine Arts)
                            </option>
                            <option value="BHM (Hotel Management)	">
                              BHM (Hotel Management){" "}
                            </option>
                            <option value="BL/LLB/BGL (Law)">
                              BL/LLB/BGL (Law)
                            </option>
                            <option value="BSW (Social Work)">
                              BSW (Social Work)
                            </option>
                            <option value="	B.Pharm (Pharmacy)		">
                              {" "}
                              B.Pharm (Pharmacy){" "}
                            </option>
                            <option value="	B.V.Sc. (Veterinary Science)">
                              {" "}
                              B.V.Sc. (Veterinary Science)
                            </option>
                            <option value="BDS (Dental Surgery)	">
                              BDS (Dental Surgery){" "}
                            </option>
                            <option value="BHMS (Homeopathy)">
                              BHMS (Homeopathy)
                            </option>
                            <option value="CA (Chartered Accountant)	">
                              CA (Chartered Accountant){" "}
                            </option>
                            <option value="CFA (Chartered Financial Analyst)">
                              CFA (Chartered Financial Analyst)
                            </option>
                            <option value="CS (Company Secretary)">
                              CS (Company Secretary)
                            </option>
                            <option value="ICWA">ICWA</option>
                            <option value="Integrated PG">Integrated PG</option>
                            <option value="Engineering">Engineering</option>
                            <option value="Fashion/ Design	">
                              Fashion/ Design{" "}
                            </option>
                            <option value="Languages	">Languages </option>
                            <option value="Pilot Licenses	">
                              Pilot Licenses{" "}
                            </option>
                            <option value="M.Arch. (Architecture)	">
                              M.Arch. (Architecture){" "}
                            </option>
                            <option value="M.Ed. (Education)">
                              M.Ed. (Education)
                            </option>
                            <option value="M.Lib.Sc. (Library Sciences)">
                              M.Lib.Sc. (Library Sciences)
                            </option>
                            <option value="M.Plan. (Planning)">
                              M.Plan. (Planning)
                            </option>
                            <option value="Master of Fashion Technology">
                              Master of Fashion Technology
                            </option>
                            <option value="Master of Health Administration	">
                              Master of Health Administration{" "}
                            </option>
                            <option value="Master of Hospital Administration">
                              Master of Hospital Administration
                            </option>
                            <option value="MBA/PGDM">MBA/PGDM</option>
                            <option value="MCA PGDCA part time	">
                              MCA PGDCA part time{" "}
                            </option>
                            <option value="MCA/PGDCA">MCA/PGDCA</option>
                            <option value="ME/M.Tech/MS (Engg/Sciences)">
                              ME/M.Tech/MS (Engg/Sciences)
                            </option>
                            <option value="MFA (Fine Arts)	">
                              MFA (Fine Arts){" "}
                            </option>
                            <option value="ML/LLM (Law)	">ML/LLM (Law) </option>
                            <option value="MSW (Social Work)	">
                              MSW (Social Work){" "}
                            </option>
                            <option value="PG Diploma">PG Diploma</option>
                            <option value="M.Com. (Commerce)">
                              M.Com. (Commerce)
                            </option>
                            <option value="M.Sc. (Science)">
                              M.Sc. (Science)
                            </option>
                            <option value="MA (Arts)">MA (Arts)</option>
                            <option value="M.Pharm. (Pharmacy)	">
                              M.Pharm. (Pharmacy){" "}
                            </option>
                            <option value="M.V.Sc. (Veterinary Science)">
                              M.V.Sc. (Veterinary Science)
                            </option>
                            <option value="MBBS">MBBS</option>
                            <option value="MD/ MS (Medicine)">
                              MD/ MS (Medicine)
                            </option>
                            <option value="MDS (Master of Dental Surgery)">
                              MDS (Master of Dental Surgery)
                            </option>
                            <option value="BPT (Physiotherapy">
                              BPT (Physiotherapy)
                            </option>
                            <option value="MPT (Physiotherapy)">
                              MPT (Physiotherapy)
                            </option>
                            <option value="M.Phil. (Philosophy)">
                              M.Phil. (Philosophy)
                            </option>
                            <option value="Ph.D. (Doctorate)	">
                              Ph.D. (Doctorate){" "}
                            </option>
                            <option value="Other Doctorate">
                              Other Doctorate
                            </option>
                            <option value="Other Diploma">Other Diploma</option>
                            <option value="Agriculture">Agriculture</option>
                            <option value="10th">10th</option>
                          </select>
                          <span style={{ color: "red" }}>
                            {formErrored.qualification}
                          </span>
                        </div>
                        <div className="col-lg-3Ed col-md-5Ed col-sm-6Ed form-groupEd">
                          <label className="labelEd" style={{marginLeft:"3%"}}>Occupation</label>
                          <select
                            className="custom-select-boxEd"
                            name="occupation"
                            id="Occupation"
                            tabIndex={2}
                            required
                            fdprocessedid="ty482f"
                            onChange={ChangeHandlerinput}
                            value={datae.occupation}
                            style={{width:"32vh",marginLeft:"2%"}}
                          >
                            <option value="" selected="">
                              Occupation
                            </option>
                            <option value="Architecture & Design">
                              Architecture &amp; Design
                            </option>
                            <option value="Artists">Artists</option>
                            <option value=" Animators & Web Designers">
                              {" "}
                              Animators &amp; Web Designers
                            </option>
                            <option value="Banking">Banking</option>
                            <option value=" Insurance & Financial Services">
                              {" "}
                              Insurance &amp; Financial Services
                            </option>
                            <option value="Beauty">Beauty</option>
                            <option value=" Fashion & Jewellery Designers">
                              {" "}
                              Fashion &amp; Jewellery Designers
                            </option>
                            <option value="Business Owner / Entrepreneur">
                              Business Owner / Entrepreneur
                            </option>
                            <option value="Civil Services / Law Enforcement">
                              Civil Services / Law Enforcement
                            </option>
                            <option value="Construction">Construction</option>
                            <option value="Customer Service/ Call Centre/BPO">
                              Customer Service/ Call Centre/BPO
                            </option>
                            <option value="Defence">Defence</option>
                            <option value="Education/ Training">
                              Education/ Training
                            </option>
                            <option value="Electronics">Electronics</option>
                            <option value="Export/ Import">
                              Export/ Import
                            </option>
                            <option value="Finance and Accounts">
                              Finance and Accounts
                            </option>
                            <option value="Government Employee">
                              Government Employee
                            </option>
                            <option value="Health Care">Health Care</option>
                            <option value="Hotels/ Restaurants">
                              Hotels/ Restaurants
                            </option>
                            <option value="Human Resource">
                              Human Resource
                            </option>
                            <option value="IT">IT</option>
                            <option value="Legal">Legal</option>
                            <option value="Management / Corporate Professionals">
                              Management / Corporate Professionals
                            </option>
                            <option value="Manufacturing/ Engineering/ R&D">
                              Manufacturing/ Engineering/ R&amp;D
                            </option>
                            <option value="Marketing and Communications">
                              Marketing and Communications
                            </option>
                            <option value="Merchant Navy">Merchant Navy</option>
                            <option value="Non Working">Non Working</option>
                            <option value="Oil & Gas">Oil &amp; Gas</option>
                            <option value="Others">Others</option>
                            <option value="Pharmaceutical/ Biotechnology">
                              Pharmaceutical/ Biotechnology
                            </option>
                            <option value="Purchase/ Logistics/ Supply chain">
                              Purchase/ Logistics/ Supply chain
                            </option>
                            <option value="Real Estate">Real Estate</option>
                            <option value="Retail Chains">Retail Chains</option>
                            <option value="Sales/ Business Development">
                              Sales/ Business Development
                            </option>
                            <option value="Science">Science</option>
                            <option value="Telecom/ ISP">Telecom/ ISP</option>
                            <option value="Travel/ Airlines">
                              Travel/ Airlines
                            </option>
                          </select>{" "}
                          <span style={{ color: "red" }}>
                            {formErrored.occupation}
                          </span>
                        </div>
                       
                        <div className="col-lg-3Ed col-md-5Ed col-sm-6Ed form-groupEd">
                          <label className="labelEd">Occupation Details</label>
                          <textarea
                            type="text"
                            rows={4}
                            name="occupation_details"
                            className="agile-ltextEd"
                            placeholder="Enter Occupation Details"
                            maxLength={100}
                            tabIndex={4}
                            onChange={ChangeHandlerinput}
                            required
                            value={datae.occupation_details}
                            style={{width:"32vh",marginLeft:"2%"}}
                          />
                          <span style={{ color: "red" }}>
                            {formErrored.occupation_details}
                          </span>
                        </div>
                        <div className="col-lg-3Ed col-md-5Ed col-sm-6Ed form-groupEd">
                          <label className="labelEd">Annual Income</label>
                          <input
                            type="text"
                            name="annual_income"
                            maxLength={6}
                            className="agile-ltextEd"
                            placeholder="Enter Annual Income"
                            tabIndex={5}
                            fdprocessedid="9ihjz"
                            onChange={ChangeHandlerinput}
                            required
                            value={datae.annual_income}
                            style={{width:"32vh",marginLeft:"2%"}}
                          />{" "}
                          <span style={{ color: "red" }}>
                            {formErrored.annual_income}
                          </span>
                        </div>
                       
                        <div className="col-lg-3Ed col-md-5Ed col-sm-6Ed form-groupEd">
                          <label className="labelEd" style={{marginLeft:"3%"}}> Employed In</label>
                          <select
                            className="custom-select-boxEd"
                            name="employed_in"
                            id="Employed_in"
                            tabIndex={7}
                            fdprocessedid="le42zf"
                            onChange={ChangeHandlerinput}
                            required
                            value={datae.employed_in}
                            style={{width:"32vh",marginLeft:"2%"}}
                          >
                            <option value="" selected="">
                              Employed In
                            </option>
                            <option value="Private Sector">
                              Private Sector
                            </option>
                            <option value="Public Sector">Public Sector</option>
                            <option value="State Government">
                              State Government
                            </option>
                            <option value="Central Government">
                              Central Government
                            </option>
                            <option value="Others">Others</option>
                            <option value="Not Working">Not Working</option>
                            <option value="MNC">MNC</option>
                          </select>{" "}
                          <span style={{ color: "red" }}>
                            {formErrored.employed_in}
                          </span>
                        </div>
                    
                     
                        <div className="col-lg-3Ed col-md-5Ed col-sm-6ED form-groupEd">
                          <label className="labelEd"> Working Location/City</label>
                          <input
                            type="text"
                            name="working_location"
                            className="agile-ltextEd"
                            maxLength={35}
                            id="workloc"
                            placeholder="Enter Working Location/City"
                            tabIndex={13}
                            fdprocessedid="ukurha"
                            onChange={ChangeHandlerinput}
                            required
                            value={datae.working_location}
                            style={{width:"32vh",marginLeft:"2%"}}
                          />{" "}
                          <span style={{ color: "red" }}>
                            {formErrored.working_location}
                          </span>
                        </div>
                     

                        <div
                          className="col-lg-3Ed col-md-5Ed col-sm-6Ed form-groupEd"
                          tabIndex={16}
                        >
                          <label className="labelEd">Special_Cases</label>
                          <textarea
                            type="text"
                            name="special_cases"
                            rows={3}
                            maxLength={400}
                            className="agile-ltext"
                            tabIndex={16}
                            placeholder="About Yourself "
                            onChange={ChangeHandlerinput}
                            required
                            value={datae.special_cases}
                            style={{width:"32vh",marginLeft:"2%"}}
                          />
                          <span style={{ color: "red" }}>
                            {formErrored.special_cases}
                          </span>
                        </div>

                        <div className="col-lg-10 col-md-10 col-sm-6"  >
                        {!showForme && (
                          <div style={{width:"30%"}}>
                            <button
                              className="theme-btn btn btn-style-one mt-4"
                              fdprocessedid="jqtd0p"
                              onClick={showDatae}
                              // style={{width:"200px"}}
                              style={{width:'200px',marginRight:"2%",marginBottom:'1%'}}
                              
                            >
                              Submit
                            </button>
                            </div>
                        )}

                            {showForme && (
                            <div>
                              <input
                                type="text"
                                name="id"
                                value={datae.id}
                                onChange={ChangeHandlerinput}
                                placeholder="Enter ID"
                              />

                            <button
                                className="theme-btnEd btnEd btn-style-oneEd mt-4Ed"
                                fdprocessedid="jqtd0p"
                                onClick={handleEdited}
                                // style={{ marginLeft: '-30px' }}
                              >
                                <span className="btn-title" tabIndex={17}>
                                  Get Data
                                </span>
                              </button>

                            
                              <button
                                className="theme-btnEd btnEd btn-style-oneEd mt-4Ed"
                                fdprocessedid="jqtd0p"
                                onClick={handlePutSubmited}
                                // style={{ marginLeft: '-30px' }}
                              >
                                <span className="btn-title" tabIndex={17}>
                                  Update Data
                                </span>
                              </button>

                            </div>
                          )}

                        </div>
                      </div>
                    </form>
                    <ToastContainer style={{ marginTop: "100px" }} />
                      {errored ? (
                        <div
                          className="theme-text -style-one mt-4"
                          style={{ color: "red" }}
                        >
                          <h4>{errored}</h4>
                        </div>
                      ) : null}
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </section>  


          
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" style={{marginTop:"1%",marginRight:"40%",width:"20%"}}
                onClick={() => setShowModaledu(false)}>Close</button>
               
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  
    <div
      className="feature-block-twopro col-lg-4pro col-md-6pro col-sm-6pro wow fadeInUp animated"
      data-wow-delay="800ms"
      style={{
        visibility: "visible",
        animationDelay: "800ms",
        animationName: "fadeInUp"
      }}
    >
      {datafam.length > 0 && (

      <div className="inner-boxpro">
        <div className="icon-boxpro">
          <img src="https://www.matrimonysoftware.in/icon/family.png" alt="" />
        </div>
        <h4>
          <a href="family?flag=1">
            Family Details{" "}
            <i style={{ fontSize: 24 }} className="fa">
              
            </i>
          </a>
          <BsFillPencilFill className="editpro"  onClick={() => setShowModalfam(true)}/>
        </h4>
        <div className="textpro"><span>Family Values:</span> {datafam[datafam.length - 1].family_values}</div>
        <div className="textpro"><span>Family Type:</span> {datafam[datafam.length - 1].family_type}</div>
        <div className="textpro"><span>Family Status:</span> {datafam[datafam.length - 1].family_status}</div>
        <div className="textpro"><span>Mother Tongue:</span> {datafam[datafam.length - 1].mother_tongue}</div>
        <div className="textpro"><span>No.of Brothers:</span> {datafam[datafam.length - 1].no_of_brothers}</div>
        <div className="textpro"><span>No.of Brothers Married:</span> {datafam[datafam.length - 1].no_of_brothers_married}</div>
        <div className="textpro"><span>No.of Sisters:</span> {datafam[datafam.length - 1].no_of_sisters}</div>
        <div className="textpro"><span>No.of Sisters Married:</span> {datafam[datafam.length - 1].no_of_sisters_married}</div>
        <div className="textpro"><span>Father Name :</span> {datafam[datafam.length - 1].father_name}</div>
        <div className="textpro"><span>Father Occupation:</span> {datafam[datafam.length - 1].father_occupation}</div>
        <div className="textpro"><span>Mother Name:</span> {datafam[datafam.length - 1].mother_name}</div>
        <div className="textpro"><span>Mother Occupation:</span> {datafam[datafam.length - 1].mother_occupation}</div>
        <div className="textpro"><span>Your Family Health:</span> {datafam[datafam.length - 1].family_wealth}</div>
        <div className="textpro"><span>Enter few lines About Your Family:</span> {datafam[datafam.length - 1].about_family}</div>
      </div>
      )}
      {showModalfam && (
        <div className="modal fade show" id="exampleModalLong" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle" style={{marginLeft:"30%",color:"#ec167f"}}>Family Details</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowModalfam(false)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
               
              <section className="newsletter-section-Fam contact-page-section-Fam">
          <div className="auto-container-Fam">
            <div className="alertmes-Fam"></div>
            <div className="row-Fam clearfix-Fam mt-2-Fam">
              {/* Form Column */}
              <div className="form-column col-lg-9 col-md-10 col-sm-10">
                <div className="envelope-image-Fam" />
                <div className="inner-column-Fam">
                  <div className="contact-form-Fam">
                    <Form
                    
                      id="contact-form-Fam"
                    >
                      <div className="row clearfix mt-2 " style={{display:"flex",flexDirection:"column"}}>
                        <div className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam">
                          <label className="label-Fam"  style={{color:"#ec167f"}}>Family Values</label>
                          <select
                            className="custom-select-box"
                            name="family_values"
                            onChange={inputChangeHandlerf}
                            value={dataf.family_values}
                            tabIndex={1}
                            fdprocessedid="0wryd"
                            style={{width:"35vh",marginLeft:"20%"}}
                            
                          >
                            <option value="Family values">Family_Values</option>
                            <option value="Traditional">Traditional</option>
                            <option value="Orthodox">Orthodox</option>
                            <option value="Liberal">Liberal</option>
                            <option value="Moderate">Moderate</option>
                          </select>
                          <span style={{ color: "red" }}>
                            {formErrorf.family_values}
                          </span>
                        </div>
                        <div className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam">
                          <label className="label-Fam" style={{color:"#ec167f"}}> Family Type</label>

                          <select
                            className="custom-select-box"
                            name="family_type"
                            onChange={inputChangeHandlerf}
                            value={dataf.family_type}
                            tabIndex={3}
                            fdprocessedid="tytwo8"
                            placeholder="Select a option and change input text above"
                            allowClear
                            style={{width:"35vh",marginLeft:"20%"}}
                          >
                            <option value="Family-Type">Family-Type</option>
                            <option value="Nuclear Family">
                              Nuclear Family
                            </option>
                            <option value="Joint Family">Joint Family</option>
                          </select>
                          <span style={{ color: "red" }}>
                            {formErrorf.family_type}
                          </span>
                        </div>

                        <div
                          className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam"
                          id="emailerror"
                        >
                          <label className="label-Fam" style={{color:"#ec167f"}}> Family Status</label>

                          <select
                            className=" custom-select-box"
                            name="family_status"
                            onChange={inputChangeHandlerf}
                            value={dataf.family_status}
                            tabIndex={3}
                            fdprocessedid="zrv5m"
                            style={{width:"35vh",marginLeft:"20%"}}
                          >
                            <option value="Family Status" selected="">
                              Family Status
                            </option>
                            <option value="Rich">Rich</option>
                            <option value="High Class">High Class</option>
                            <option value="Upper Middle Class">
                              Upper Middle Class
                            </option>
                            <option value="Middle Class">Middle Class</option>
                            <option value="Do not want to tell at this time">
                              Do not want to tell at this time
                            </option>
                          </select>
                          <span style={{ color: "red" }}>
                            {formErrorf.family_status}
                          </span>
                        </div>
                        <div
                          className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam"
                
                        >
                          <label className="label-Fam" style={{color:"#ec167f"}}>Mother Tounge </label>
                          <select
                            className="custom-select-box"
                            name="mother_tounge"
                            onChange={inputChangeHandlerf}
                            value={dataf.mother_tounge}
                          
                            tabIndex={4}
                            fdprocessedid="jz6s7a"
                            style={{width:"35vh",marginLeft:"20%"}}
                          >
                            <option value="" selected="">
                              Select Mother Tounge{" "}
                            </option>
                            <option value="Assamese">Assamese </option>
                            <option value="Bengali">Bengali</option>
                            <option value="Bodo">Bodo</option>
                            <option value="Dogri">Dogri</option>
                            <option value="Gujarati">Gujarati</option>
                            <option value="Hindi">Hindi</option>
                            <option value="Kannada">Kannada</option>
                            <option value="Kashmiri">Kashmiri</option>
                            <option value="Konkani">Konkani</option>
                            <option value="Maithili">Maithili</option>
                            <option value="Malayalam">Malayalam</option>
                            <option value="Manipuri">Manipuri</option>
                            <option value="Marathi">Marathi</option>
                            <option value="Nepali">Nepali</option>
                            <option value="Odia">Odia</option>
                            <option value="Punjabi">Punjabi</option>
                            <option value="Sanskrit">Sanskrit</option>
                            <option value="Santali">Santali</option>
                            <option value="Sindhi">Sindhi</option>
                            <option value="Tamil">Tamil</option>
                            <option value="Telugu">Telugu</option>
                            <option value="Urdu">Urdu</option>
                          </select>
                          <span style={{ color: "red" }}>
                            {formErrorf.mother_tounge}
                          </span>
                        </div>

                        <div className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam">
                          <label className="label-Fam" style={{color:"#ec167f"}}> No. of Brothers</label>
                          <select
                            className="custom-select-box"
                            name="no_of_brothers"
                            onChange={inputChangeHandlerf}
                            value={dataf.no_of_brothers}
                            id="noofbrom"
                            tabIndex={5}
                            fdprocessedid="bfkuk"
                            style={{width:"35vh",marginLeft:"20%"}}
                          >
                            <option value="" selected="">
                              Select No.of Brothers
                            </option>
                            <option value="No">No</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value="5+">5+</option>
                          </select>
                          <span style={{ color: "red" }}>
                            {formErrorf.no_of_brothers}
                          </span>
                        </div>
                        <div
                          className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam"
                       
                        >
                          <label className="label-Fam" style={{color:"#ec167f"}}> No.of Brothers Married</label>
                          <select
                            className="custom-select-box"
                            name="no_of_brothers_married"
                            onChange={inputChangeHandlerf}
                            value={dataf.no_of_brothers_married}
                            tabIndex={6}
                            fdprocessedid="pykgeh"
                            style={{width:"35vh",marginLeft:"20%"}}
                          >
                            <option value="">
                              Select No.of Brothers Married
                            </option>
                            <option value="No">No</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value="5+">5+</option>
                          </select>
                          <span style={{ color: "red" }}>
                            {formErrorf.no_of_brothers_married}
                          </span>
                        </div>

                        <div className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam">
                          <label className="label-Fam" style={{color:"#ec167f"}}>No. of Sisters</label>
                          <select
                            className="custom-select-box"
                            name="no_of_sisters"
                            onChange={inputChangeHandlerf}
                            value={dataf.no_of_sisters}
                            id="noofsism"
                            tabIndex={7}
                            fdprocessedid="khqro"
                            style={{width:"35vh",marginLeft:"20%"}}
                          >
                            <option value="">Select No. of Sisters</option>
                            <option value="No"> No</option>
                            <option value={1}> 1</option>
                            <option value={2}> 2</option>
                            <option value={3}> 3</option>
                            <option value={4}> 4</option>
                            <option value={5}> 5</option>
                            <option value="5+"> 5+</option>
                          </select>
                          <span />
                          <span style={{ color: "red" }}>
                            {formErrorf.no_of_sisters}
                          </span>
                        </div>
                        <div
                          className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam"
                       
                        >
                          <label className="label-Fam" style={{color:"#ec167f"}}> No. of Sisters Married</label>
                          <select
                            className="custom-select-box"
                            name="no_of_sisters_married"
                            onChange={inputChangeHandlerf}
                            value={dataf.no_of_sisters_married}
                            tabIndex={8}
                            fdprocessedid="sy1mja"
                            style={{width:"35vh",marginLeft:"20%"}}
                          >
                            <option value={""} selected="">
                              {" "}
                              No. of Sisters Married
                            </option>
                           
                            <option value="No">No</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value="5+">5+</option>
                          </select>
                          <span />
                          <span style={{ color: "red" }}>
                            {formErrorf.no_of_sisters_married}
                          </span>
                        </div>

                        <div className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam">
                          <label className="label-Fam"  style={{color:"#ec167f"}}>Father Name</label>
                          <input
                            type="text"
                            className="agile-ltext-Fam"
                            placeholder="Your Father Name"
                            maxLength={35}
                            name="father_name"
                            onChange={inputChangeHandlerf}
                            value={dataf.father_name}
                            // defaultValue=""
                            required=""
                            tabIndex={9}
                            fdprocessedid="9f2uiv"
                            style={{width:"35vh",marginLeft:"20%"}}
                          />
                          <span style={{ color: "red" }}>
                            {formErrorf.father_name}
                          </span>
                        </div>

                        <div className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam">
                          <label className="label-Fam"  style={{color:"#ec167f"}}> Father Occupation</label>
                          <input
                            type="text"
                            className="agile-ltext-Fam"
                            placeholder="Father's Occuption"
                            maxLength={40}
                            name="father_occupation"
                            onChange={inputChangeHandlerf}
                            value={dataf.father_occupation}
                            // defaultValue=""
                            tabIndex={10}
                            fdprocessedid="610prp"
                            rules={[
                              {
                                required: true,
                                type: "string",
                                message: "please input Your Name",
                              },
                              {
                                pattern: new RegExp(/^[A-Za-z\s]*$/),
                                message: "field does not accept numbers",
                              },
                            ]}
                            style={{width:"35vh",marginLeft:"20%"}}
                          />
                          <span style={{ color: "red" }}>
                            {formErrorf.father_occupation}
                          </span>
                        </div>

                        <div className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam">
                          <label className="label-Fam"  style={{color:"#ec167f"}}> Mother Name</label>
                          <input
                            type="text"
                            className="agile-ltext-Fam"
                            placeholder="Your Mother Name"
                            required=""
                            defaultValue=""
                            maxLength={35}
                            name="mother_name"
                            onChange={inputChangeHandlerf}
                            value={dataf.mother_name}
                            tabIndex={11}
                            fdprocessedid="7h41z"
                            style={{width:"35vh",marginLeft:"20%"}}
                          />
                          <span style={{ color: "red" }}>
                            {formErrorf.mother_name}
                          </span>
                        </div>
                        <div
                          className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam"
                    
                        >
                          <label className="label-Fam"  style={{color:"#ec167f"}}> Mother Occupation</label>
                          <input
                            type="text"
                            className="agile-ltext-Fam"
                            placeholder="Mother's Occuption"
                            maxLength={40}
                            name="mother_occupation"
                            onChange={inputChangeHandlerf}
                            value={dataf.mother_occupation}
                            // defaultValue=""
                            tabIndex={12}
                            fdprocessedid="oajm3g"
                            style={{width:"35vh",marginLeft:"20%"}}
                          />
                          <span style={{ color: "red" }}>
                            {formErrorf.mother_occupation}
                          </span>
                        </div>

                        <div className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam">
                          <label className="label-Fam" style={{color:"#ec167f"}}>Your Family Wealth</label>
                          
                            <select
                                 id="family_wealth"
                              className="custom-select-box"
                              name="family_wealth"
                              onChange={inputChangeHandlerf}
                              value={dataf.family_wealth}
                            
                              required=""
                              fdprocessedid="i3ugcu"
                              style={{width:"35vh",marginLeft:"20%"}}
                            >
                              <option value="Family_Wealth">
                                Family_Wealth
                              </option>
                              <option value="Bungalow">Bungalow</option>
                              <option value="House">House</option>
                              <option value="Flat">Flat</option>
                              <option value="Plot">Plot</option>
                              <option value="Agri Land">Agri Land</option>
                              <option value="Car">Car</option>
                            </select>
                            <span style={{ color: "red" }}>
                              {formErrorf.family_wealth}
                            </span>
                          
                        </div>
                        
                        <div
                          className="col-lg-3-Fam col-md-4-Fam col-sm-6-Fam form-group-Fam"
             
                        >
                          <label className="label-Fam"  style={{color:"#ec167f",marginLeft:"5%"}}>Enter Few Lines About Your Family</label>
                          <textarea
                            type="text"
                            name="about_family"
                            onChange={inputChangeHandlerf}
                            value={dataf.about_family}
                            className="agile-ltextarea-Fam"
                            rows={4}
                            placeholder="About Your Family"
                            maxLength={500}
                            tabIndex={18}
                            style={{width:"35vh",marginLeft:"20%"}}
                         
                          />

                          {/* <div className="col-lg-10-Fam col-md-10-Fam col-sm-10-Fam">
                            <span style={{ fontSize: 15,margin:"5%",color:"#ec167f" }} className="abtx">
                              {" "}
                              about your Family
                              <a
                                href="#"
                                data-toggle="modal"
                                className="ml-1"
                                data-target="#myModal4"
                                data-id={1}
                              >
                                {" "}
                                Examples{" "}
                              </a>
                            </span>
                            <span style={{ color: "red" }}>
                              {formErrorf.about_family}
                            </span>
                          </div> */}
                        </div>
                        <div className="col-lg-12-Fam col-md-12-Fam col-sm-10-Fam mb-2-Fam">
                          <input
                          className="radio-btn"
                            type="radio"
                            style={{ verticalAlign: "middle" }}
                            // className="abtxt-Fam"
                            name="options"
                            onChange={inputChangeHandlerf}
                            defaultChecked=""
                            value="My parents will stay with me after marriage"
                            tabIndex={13}
                            
                          />
                          <span>
                          
                            My parents will stay with me after marriage
                          </span>
                          <span style={{ color: "red" }}>
                            {formErrorf.options}
                          </span>
                        </div>
                        <div className="col-lg-12-Fam col-md-12-Fam col-sm-10-Fam mb-2-Fam">
                          <input
                            type="radio"
                            style={{ verticalAlign: "middle" }}
                            // className="abtxt-Fam"
                            className="radio-btn"
                            name="options"
                            defaultChecked=""
                            onChange={inputChangeHandlerf}
                            value="My parents will not stay with me after marriage"
                            tabIndex={14}
                          />
                          <span>
                            {" "}
                            My parents will not stay with me after marriage
                          </span>
                          <span style={{ color: "red" }}>
                            {formErrorf.options}
                          </span>
                        </div>
                        <div className="col-lg-12-Fam col-md-12-Fam col-sm-10-Fam mb-2-Fam">
                          <input
                            type="radio"
                            style={{ verticalAlign: "middle" }}
                            // className="abtxt-Fam"
                            className="radio-btn"
                            name="options"
                            defaultChecked=""
                            onChange={inputChangeHandlerf}
                            value="Dont wish to specify"
                            tabIndex={15}
                          />
                          <span> Don't wish to specify</span>
                          <span style={{ color: "red" }}>
                            {formErrorf.options}
                          </span>
                        </div>
                        <br />
                       
                       
                        <div className="col-lg-10-Fam col-md-10-Fam col-sm-10-Fam sub_btn-Fam">
                        {!showFormf && (
                          <div>
                            <button
                              className="theme-btn-Fam btn-Fam btn-style-one-Fam mt-4-Fam"
                              type="submit"
                              name="submit"
                              // style={{
                              //   width: "40%",
                              //   textAlign: "center",
                              //   marginLeft: "250px",
                              // }}
                              fdprocessedid="gw5352"
                              onClick={showDataf}
                              style={{width:'50%',marginLeft:"10%"}}
                            >
                              <span className="btn-title-Fam">
                                Submit
                              </span>
                            </button>
                            </div>
                            )}
                    
                    
                            {showFormf && (
                            <div>
                              <input
                                type="text"
                                name="id"
                                value={dataf.id}
                                onChange={inputChangeHandlerf}
                                placeholder="Enter ID"
                              />

                            <button
                                className="theme-btn-Fam btn-Fam btn-style-one-Fam mt-4-Fam"
                                fdprocessedid="jqtd0p"
                                onClick={handleEditf}
                                style={{ marginLeft: '-30px' }}
                              >
                                <span className="btn-title-Fam" tabIndex={17}>
                                  Get Data
                                </span>
                              </button>

                            
                              <button
                                className="theme-btn-Fam btn-Fam btn-style-one-Fam mt-4-Fam"
                                fdprocessedid="jqtd0p"
                                onClick={handlePutSubmitf}
                                style={{ marginLeft: '-30px' }}
                              >
                                <span className="btn-title-Fam" tabIndex={17}>
                                  Update Data
                                </span>
                              </button>

                            </div>
                          )}

                        </div>
                      </div>
                    </Form>
                    <ToastContainer style={{ marginTop: "100px" }} />
                    {errorf ? (
                      <div
                        className="theme-text -style-one mt-4"
                        style={{ color: "red" }}
                      >
                        <h4>{errorf}</h4>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <br />
            </div>
          </div>
        </section>




              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" style={{marginRight:"25%",width:"20%"}}
                onClick={() => setShowModalfam(false)}>Close</button>
               
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    {/* Feature Block */}



    <div
      className="feature-block-twopro col-lg-4pro col-md-6pro col-sm-6pro wow fadeInUp animated"
      style={{ visibility: "visible", animationName: "fadeInUp" }}
    >
      {datahoro.length > 0 && (
      <div className="inner-boxpro">
        <div className="icon-boxpro">
          <img
            src="https://www.matrimonysoftware.in/icon/horocope.png"
            alt=""
          />
        </div>
        <h4>
          <a href="horoscope">
            Horoscope Details{" "}
            <i style={{ fontSize: 24 }} className="fa">
              
            </i>
          </a>{" "}
          <BsFillPencilFill className="editpro"  onClick={() => setShowModalhoro(true)}/>
        </h4>
        <div className="textpro"><span>Moonsign:</span> {datahoro[datahoro.length - 1].moon_sign}</div>
        <div className="textpro"><span>Star:</span> {datahoro[datahoro.length - 1].star}</div>
        <div className="textpro"><span>Gotra:</span> {datahoro[datahoro.length - 1].gotra}</div>
        <div className="textpro"><span>Manglik:</span> {datahoro[datahoro.length - 1].manglik}</div>
        <div className="textpro"><span>Shani:</span> {datahoro[datahoro.length - 1].shani}</div>
        <div className="textpro"><span>Horoscope Match:</span> {datahoro[datahoro.length - 1].hororscope_match}</div>
       <div className="textpro"><span>Place of Birth:</span> {datahoro[datahoro.length - 1].place_of_birth}</div>
        <div className="textpro"><span>Place of Country:</span> {datahoro[datahoro.length - 1].place_of_country}</div>
        <div className="textpro"><span>Hours:</span> {datahoro[datahoro.length - 1].Hours}</div>
        <div className="textpro"><span>Minutes:</span> {datahoro[datahoro.length - 1].minitues}</div>
        <div className="textpro"><span>Secounds:</span> {datahoro[datahoro.length - 1].seconds}</div>
        <div className="textpro"><span>AM/PM:</span> {datahoro[datahoro.length - 1].am_pm}</div>
      </div>
      )}
      {showModalhoro && (
        <div className="modal fade show" id="exampleModalLong" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle" style={{marginLeft:"30%",color:"#ec167f"}}>Horoscope Details</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowModalhoro(false)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
             
              <section className="newsletter-section contact-page-section">
          <div className="auto-container-Horo" style={{marginLeft:"10%"}}>
            <div className="alertmes-Horo"> </div>
            <div className="row-Horo clearfix-Horo mt-3h-Horo" style={{marginLeft:"3%"}}>
              <div className="form-column col-lg-9 col-md-12 col-sm-12">
                <div className="envelope-image" />
                <div className="form-inner">
                  <div className="contact-form ">
                   
                      <div className="row clearfix"  style={{display:"flex",flexDirection:"column"}}>
                        <div className="col-lg-3-Horo col-md-5-Horo col-sm-6-Horo form-group-Horo">
                          <label className="labelCls1" style={{marginLeft:"3%"}}>Moon signs</label>
                          <select
                           type="text"
                            className="custom-select-box"
                            title="Select Moon_sign"
                            name="moon_sign"
                            id="Moon_Sign"
                            onChange={inputChangeHandlerh}
                            value={datah.moon_sign}
                            tabIndex={1}
                            fdprocessedid="res69a"
                            style={{width:"30vh"}}
                          >
                            <option value="">Select Moonsign</option>
                            <option value="Mesh (Aries)">Mesh (Aries)</option>
                            <option value="Vrishabh (Taurus)">
                              Vrishabh (Taurus)
                            </option>
                            <option value="Mithun (Gemini)">
                              Mithun (Gemini)
                            </option>
                            <option value="Karka (Cancer)">
                              Karka (Cancer)
                            </option>
                            <option value="Sinha (Leo)">Sinha (Leo)</option>
                            <option value="Kanya (Virgo)">Kanya (Virgo)</option>
                            <option value="Tula (Libra)">Tula (Libra)</option>
                            <option value="Vrischika (Scorpio)">
                              Vrischika (Scorpio)
                            </option>
                            <option value="Makar (Capricorn)">
                              Makar (Capricorn)
                            </option>
                            <option value="Kumbha (Aquarious)">
                              Kumbha (Aquarious)
                            </option>
                            <option value="Meen (Pisces)">Meen (Pisces)</option>
                            <option value="Does not matter">
                              Does not matter
                            </option>
                          </select>
                          <span style={{ color: "red" }}>
                            {formErrorh.moon_sign}
                          </span>
                        </div>
                        <div className="col-lg-3-Horo col-md-5-Horo col-sm-6-Horo form-group-Horo">
                          <label className="labelCls1" style={{marginLeft:"3%"}}>Star</label>
                          <select
                           type="text"
                            className="custom-select-box"
                            title="Select Nakshatra"
                            name="star"
                            // id="Star"
                            onChange={inputChangeHandlerh}
                            value={datah.star}
                            tabIndex={2}
                            fdprocessedid="9h1kq9"
                            style={{width:"30vh"}}
                          >
                            <option value="">Select Star</option>
                            <option value="Ardra/Thiruvathira">
                              Ardra/Thiruvathira
                            </option>
                            <option value="Ashlesha/Ayilyam">
                              Ashlesha/Ayilyam
                            </option>
                            <option value="Ashwini/Ashwathi">
                              Ashwini/Ashwathi
                            </option>
                            <option value="Bharani">Bharani</option>
                            <option value="Chitra/Chitha">Chitra/Chitha</option>
                            <option value="Dhanista/Avittam">
                              Dhanista/Avittam
                            </option>
                            <option value="Hastha/Atham">Hastha/Atham</option>
                            <option value="Jyesta / Kettai">
                              Jyesta / Kettai
                            </option>
                            <option value="Krithika/Karthika">
                              Krithika/Karthika
                            </option>
                            <option value="Makha/Magam">Makha/Magam</option>
                            <option value="Moolam/Moola">Moolam/Moola</option>
                            <option value="Mrigasira/Makayiram">
                              Mrigasira/Makayiram
                            </option>
                            <option value="Poorvabadrapada/Puratathi">
                              Poorvabadrapada/Puratathi
                            </option>
                            <option value="Poorvapalguni/Puram/Pubbhe">
                              Poorvapalguni/Puram/Pubbhe
                            </option>
                            <option value="Poorvashada/Pooradam">
                              Poorvashada/Pooradam
                            </option>
                            <option value="Punarvasu/Punarpusam">
                              Punarvasu/Punarpusam
                            </option>
                            <option value="Pushya/Poosam/Pooyam">
                              Pushya/Poosam/Pooyam
                            </option>
                            <option value="Revathi">Revathi</option>
                            <option value="Rohini">Rohini</option>
                            <option value="Shatataraka/Sadayam/Satabishek">
                              Shatataraka/Sadayam/Satabishek
                            </option>
                            <option value="Shravan/Thiruvonam">
                              Shravan/Thiruvonam
                            </option>
                            <option value="Swati/Chothi">Swati/Chothi</option>
                            <option value="Uttarabadrapada/Uthratadhi">
                              Uttarabadrapada/Uthratadhi
                            </option>
                            <option value="Uttarapalguni/Uthram">
                              Uttarapalguni/Uthram
                            </option>
                            <option value="Uttarashada/Uthradam">
                              Uttarashada/Uthradam
                            </option>
                            <option value="Vishaka/Vishakam">
                              Vishaka/Vishakam
                            </option>
                            <option value="Does not matter">
                              Does not matter
                            </option>
                          </select>
                          <span style={{ color: "red" }}>
                            {formErrorh.star}
                          </span>
                        </div>
                        <div className="col-lg-3-Horo col-md-5-Horo col-sm-6-Horo form-group-Horo">
                          <label className="labelCls1" style={{marginLeft:"3%"}}>Gotra</label>
                          <Input
                            type="text"
                            // className="agile-ltext-Horo"
                            className='custom-select-box'
                            maxLength={20}
                            placeholder="Gotra"
                            name="gotra"
                            // id="Gotra"
                            onChange={inputChangeHandlerh}
                            value={datah.gotra}
                            tabIndex={3}
                            defaultValue=""
                            // fdprocessedid="ji0au"
                            style={{width:"30vh"}} 
                          />
                           <span style={{ color: "red" }}>
                            {formErrorh.gotra}
                          </span>
                        </div>
                        <div className="col-lg-3-Horo col-md-5-Horo col-sm-6-Horo form-group-Horo">
                          <label className="labelCls1" style={{marginLeft:"3%"}}>Mangalik</label>
                          <select
                            className="custom-select-box"
                            title="Select Manglik"
                            name="manglik"
                            // id="Manglik"
                            style={{width:"30vh"}}
                            onChange={inputChangeHandlerh}
                            value={datah.manglik}
                            tabIndex={4}
                            fdprocessedid="9j9ynk"
                            rules={[
                              {
                                required: true,
                                type: "string",
                                message: "please input Your Manglik",
                              },
                            ]}
                          >
                            <option value="">Select Mangalik</option>
                            <option value="Yes">Yes</option>{" "}
                            <option value="No">No</option>{" "}
                            <option value="Not Applicable">
                              Not Applicable
                            </option>
                          </select>
                          <span style={{ color: "red" }}>
                            {formErrorh.manglik}
                          </span>
                        </div>
                        <div className="col-lg-3-Horo col-md-5-Horo col-sm-6-Horo form-group-Horo">
                          <label className="labelCls1" style={{marginLeft:"3%"}}>Shani</label>
                          <select
                            className="custom-select-box"
                            title="Select Shani"
                            name="shani"
                            onChange={inputChangeHandlerh}
                            value={datah.shani}
                            tabIndex={5}
                            style={{width:"30vh"}}
                            // fdprocessedid="g0b22"
                          >
                            <option value="">Select Shani</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                            <option value="Not Applicable">
                              Not Applicable
                            </option>
                          </select>
                          <span style={{ color: "red" }}>
                            {formErrorh.shani}
                          </span>
                        </div>
                        <div className="col-lg-3-Horo col-md-5-Horo col-sm-6-Horo form-group-Horo">
                          <label className="labelCls1" style={{marginLeft:"3%"}}>Horoscope Match</label>
                          <select
                            className="custom-select-box"
                            title="Select Horoscopematch"
                            name="hororscope_match"
                            onChange={inputChangeHandlerh}
                            value={datah.hororscope_match}
                            // id="caste"
                            tabIndex={6}
                            fdprocessedid="4it3iv"
                            style={{width:"30vh"}}
                          >
                            <option value="Prefered">Select Prefered</option>
                            <option value="Not Prefered">Not Prefered</option>
                            <option value="Does not matter">
                              Does not matter
                            </option>
                          </select>
                          <span style={{ color: "red" }}>
                            {formErrorh.hororscope_match}
                          </span>
                        </div>
                        <div className="col-lg-3-Horo col-md-5-Horo col-sm-6-Horo form-group-Horo">
                          <label className="labelCls1" style={{marginLeft:"3%"}}>Place of Birth</label>
                          <Input
                            classNames="InputCls-Horo"
                            type="text"
                            // className="agile-ltext-Horo"
                            className="custom-select-box"
                            tabIndex={11}
                            maxLength={30}
                            placeholder="Enter Place of Birth"
                            name="place_of_birth"
                            onChange={inputChangeHandlerh}
                            value={datah.place_of_birth}
                            defaultValue=""
                            fdprocessedid="7a8yg"
                            style={{width:"30vh"}}
                          />
                             <span style={{ color: "red" }}>
                            {formErrorh.place_of_birth}
                          </span>
                        </div>
                        <div className="col-lg-3-Horo col-md-5-Horo col-sm-6-Horo form-group-Horo">
                          <label className="labelCls1" style={{marginLeft:"3%"}}>Place of Country</label>
                          <Input
                            type="text"
                            // className="agile-ltext-Horo"
                            className="custom-select-box"
                            tabIndex={12}
                            maxLength={30}
                            placeholder="Enter Place of Country"
                            name="place_of_country"
                            onChange={inputChangeHandlerh}
                            value={datah.place_of_country}
                            // id="Place_Of_Country"
                            defaultValue=""
                            fdprocessedid="izvngv"
                            style={{width:"30vh"}}
                          />
                             <span style={{ color: "red" }}>
                            {formErrorh.place_of_country}
                          </span>
                        </div>
                        <div className="col-lg-3-Horo col-md-5-Horo col-sm-6-Horo form-group-Horo">
                          <label className="labelCls1" style={{marginLeft:"3%"}}>Hours</label>
                          <select
                            name="hours"
                            className="custom-select-box"
                            title="Hours"
                            onChange={inputChangeHandlerh}
                            value={datah.hours}
                            tabIndex={7}
                            // fdprocessedid="54785i"
                            style={{width:"30vh"}}
                          >
                            <option value="" selected="">
                              Hours
                            </option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                          </select>
                          <span style={{ color: "red" }}>
                            {formErrorh.hours}
                          </span>
                        </div>
                        <div className="col-lg-3-Horo col-md-5-Horo col-sm-6-Horo form-group-Horo">
                          <label className="labelCls1" style={{marginLeft:"3%"}}>Minutues</label>
                          <select
                            name="minitues"
                            className="custom-select-box"
                            title="Minitues"
                            onChange={inputChangeHandlerh}
                            value={datah.minitues}
                            tabIndex={8}
                            style={{width:"30vh"}}
                            // fdprocessedid="6tib0r"
                          >
                            <option value="" selected="">
                              Minutes
                            </option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                            <option value={13}>13</option>
                            <option value={14}>14</option>
                            <option value={15}>15</option>
                            <option value={16}>16</option>
                            <option value={17}>17</option>
                            <option value={18}>18</option>
                            <option value={19}>19</option>
                            <option value={20}>20</option>
                            <option value={21}>21</option>
                            <option value={22}>22</option>
                            <option value={23}>23</option>
                            <option value={24}>24</option>
                            <option value={25}>25</option>
                            <option value={26}>26</option>
                            <option value={27}>27</option>
                            <option value={28}>28</option>
                            <option value={29}>29</option>
                            <option value={30}>30</option>
                            <option value={31}>31</option>
                            <option value={32}>32</option>
                            <option value={33}>33</option>
                            <option value={34}>34</option>
                            <option value={35}>35</option>
                            <option value={36}>36</option>
                            <option value={37}>37</option>
                            <option value={38}>38</option>
                            <option value={39}>39</option>
                            <option value={40}>40</option>
                            <option value={41}>41</option>
                            <option value={42}>42</option>
                            <option value={43}>43</option>
                            <option value={44}>44</option>
                            <option value={45}>45</option>
                            <option value={46}>46</option>
                            <option value={47}>47</option>
                            <option value={48}>48</option>
                            <option value={49}>49</option>
                            <option value={50}>50</option>
                            <option value={51}>51</option>
                            <option value={52}>52</option>
                            <option value={53}>53</option>
                            <option value={54}>54</option>
                            <option value={55}>55</option>
                            <option value={56}>56</option>
                            <option value={57}>57</option>
                            <option value={58}>58</option>
                            <option value={59}>59</option>
                            <option value={""}>00</option>
                          </select>
                          <span style={{ color: "red" }}>
                            {formErrorh.minitues}
                          </span>
                        </div>
                        <div className="col-lg-3-Horo col-md-5-Horo col-sm-6-Horo form-group-Horo">
                          <label className="labelCls1" style={{marginLeft:"3%"}}>Seconds</label>
                          <select
                            name="seconds"
                            className="custom-select-box"
                            title="Seconds"
                            onChange={inputChangeHandlerh}
                            value={datah.seconds}
                            tabIndex={9}
                            style={{width:"30vh"}}
                            // fdprocessedid="5cm2dk"
                          >
                            <option value="" selected="">
                              Seconds
                            </option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                            <option value={12}>12</option>
                            <option value={13}>13</option>
                            <option value={14}>14</option>
                            <option value={15}>15</option>
                            <option value={16}>16</option>
                            <option value={17}>17</option>
                            <option value={18}>18</option>
                            <option value={19}>19</option>
                            <option value={20}>20</option>
                            <option value={21}>21</option>
                            <option value={22}>22</option>
                            <option value={23}>23</option>
                            <option value={24}>24</option>
                            <option value={25}>25</option>
                            <option value={26}>26</option>
                            <option value={27}>27</option>
                            <option value={28}>28</option>
                            <option value={29}>29</option>
                            <option value={30}>30</option>
                            <option value={31}>31</option>
                            <option value={32}>32</option>
                            <option value={33}>33</option>
                            <option value={34}>34</option>
                            <option value={35}>35</option>
                            <option value={36}>36</option>
                            <option value={37}>37</option>
                            <option value={38}>38</option>
                            <option value={39}>39</option>
                            <option value={40}>40</option>
                            <option value={41}>41</option>
                            <option value={42}>42</option>
                            <option value={43}>43</option>
                            <option value={44}>44</option>
                            <option value={45}>45</option>
                            <option value={46}>46</option>
                            <option value={47}>47</option>
                            <option value={48}>48</option>
                            <option value={49}>49</option>
                            <option value={50}>50</option>
                            <option value={51}>51</option>
                            <option value={52}>52</option>
                            <option value={53}>53</option>
                            <option value={54}>54</option>
                            <option value={55}>55</option>
                            <option value={56}>56</option>
                            <option value={57}>57</option>
                            <option value={58}>58</option>
                            <option value={59}>59</option>
                            <option value={""}>00</option>
                          </select>
                          <span style={{ color: "red" }}>
                            {formErrorh.seconds}
                          </span>
                        </div>
                        <div className="col-lg-3-Horo col-md-5-Horo col-sm-6-Horo form-group-Horo">
                          <label className="labelCls1" style={{marginLeft:"3%"}}>AM/PM</label>
                          <select
                            name="am_pm"
                            className="custom-select-box"
                            title="Am_Pm"
                            onChange={inputChangeHandlerh}
                            value={datah.am_pm}
                            tabIndex={10}
                            style={{width:"30vh"}}
                            // fdprocessedid="nmk97e"
                          >
                              <option value="" selected="">AM/PM</option>
                            <option value="AM">AM</option>
                            <option value="PM">PM</option>
                          </select>
                          <span style={{ color: "red" }}>
                            {formErrorh.am_pm}
                          </span>
                        </div>
                        
                        <div className="col-lg-10 col-md-10 col-sm-6 form-group">
                      
                        {!showFormh && (
                          <div>
                            <button
                              className="theme-btn btn btn-style-one"
                              fdprocessedid="jqtd0p"
                              onClick={showDatah}
                              style={{marginRight:"40%",width:"30%",marginTop:"5%"}}
                            >
                              <span className="btn-title" tabIndex={17}>
                              Submit
                              </span>
                            
                            </button>
                            </div>
                            )}

                            
                          {/* Input field for user to enter the ID */}

                          {showFormh && (
                            <div>
                              <input
                                type="text"
                                name="id"
                                value={datah.id}
                                onChange={inputChangeHandlerh}
                                placeholder="Enter ID"
                              />

                                <button
                                className="theme-btn-Horo btn-Horo btn-style-one-Horo"
                                fdprocessedid="jqtd0p"
                                onClick={handleEdith}
                                style={{ marginLeft: '-30px' }}
                              >
                                <span className="btn-title-Horo" tabIndex={17}>
                                  Get Data
                                </span>
                              </button>

                            
                              <button
                                className="theme-btn-Horo btn-Horo btn-style-one-Horo"
                                fdprocessedid="jqtd0p"
                                onClick={handlePutSubmith}
                                style={{ marginLeft: '-30px' }}
                              >
                                <span className="btn-title-Horo" tabIndex={17}>
                                  Update Data
                                </span>
                              </button>

                            </div>
                          )}
                       
                        </div>
                      </div>
                      <ToastContainer style={{ marginTop: "100px" }} />
                      {errorh ? (
                        <div
                          className="theme-text-style-one mt-4"
                          style={{ color: "red" }}
                        >
                          <h4>{errorh}</h4>
                        </div>
                      ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
       


              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" 
                onClick={() => setShowModalhoro(false)}  style={{marginRight:"30%",width:"20%",marginTop:"2%"}}>Close</button>
               
              </div>
            </div>
          </div>
        </div>
     
       )
      }
    </div>
    {/* Feature Block */}




    <div
      className="feature-block-twopro col-lg-4pro pro col-sm-6pro wow fadeInUp animated"
      data-wow-delay="1200ms"
      style={{
        visibility: "visible",
        animationDelay: "1200ms",
        animationName: "fadeInUp"
      }}
    >
      {datapat.length > 0 && (
      <div className="inner-boxpro">
        <div className="icon-boxpro">
          <img src="https://www.matrimonysoftware.in/icon/matches.png" alt="new" />
        </div>
        <h4>
          <a href="partner_prefrence">
            Partner Preference{" "}
            <i style={{ fontSize: 24 }} className="fa">
              
            </i>
          </a>
          <BsFillPencilFill className="editpro"  onClick={() => setShowModalpat(true)}/>
        </h4>
        <div className="textpro">
        <span> Looking For:</span> {datapat[datapat.length - 1].looking_for}
          <a href="a" className="search-box-btn6">
            {/* Read More */}
          </a>
        </div>
        <div className="textpro">
        <span> Age from:</span> {datapat[datapat.length - 1].age_from}
          <a href="a" className="search-box-btn6">
            {/* Read More */}
          </a>
        </div>
        <div className="textpro">
        <span> Age to:</span> {datapat[datapat.length - 1].age_to}
          <a href="a" className="search-box-btn6">
            {/* Read More */}
          </a>
        </div>
        <div className="textpro">
        <span> Height form:</span> {datapat[datapat.length - 1].height_from}
          <a href="a" className="search-box-btn6">
            {/* Read More */}
          </a>
        </div>
        
        <div className="textpro">
        <span>Height to:</span> {datapat[datapat.length - 1].height_to}
          <a href="a" className="search-box-btn6">
            {/* Read More */}
          </a>
        </div>
        <div className="textpro">
        <span> Religion:</span> {datapat[datapat.length - 1].relligion}
          <a href="#" className="search-box-btn6">
            {/* Read More */}
          </a>
        </div>
        <div className="textpro">
        <span> Caste:</span> {datapat[datapat.length - 1].caste}
          <a href="a" className="search-box-btn6">
            {/* Read More */}
          </a>
        </div>
        <div className="textpro">
        <span> Complexion:</span> {datapat[datapat.length - 1].complexion}
        </div>
        <div className="textpro">
        <span> Recidency Status:</span> {datapat[datapat.length - 1].residency_status}
          <a href="a" className="search-box-btn6">
            {/* Read More */}
          </a>
        </div>
        <div className="textpro">
        <span> Country:</span> {datapat[datapat.length - 1].country}
        </div>
        <div className="textpro">
        <span> State: </span>{datapat[datapat.length - 1].state}
        </div>
        <div className="textpro">
        <span>Education:</span> {datapat[datapat.length - 1].education}
        </div>
        <div className="textpro">
        <span> Occupation:</span> {datapat[datapat.length - 1].occupation}
        </div>
        {/* <div className="textpro">
         Partner Expectations: {datapat[datapat.length - 1].}
        </div> */}
       
      </div>

)}
{showModalpat && (
  <div className="modal fade show" id="exampleModalLong" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" style={{ display: 'block' }}>
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLongTitle" style={{marginLeft:"30%",color:"#ec167f"}}>Partner Preference</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowModalpat(false)}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
         
        <section className="newsletter-section contact-page-section">
        <div className="auto-container">
          <div className="alertmes-pre"></div>
          <div className="row clearfix mt-3">
            {/* Form Column */}
            <div className="form-column-pre col-lg-12-pre col-md-12-pre col-sm-12-pre">
              <div className="envelope-image" />
              <div className="inner-column">
                <div className="contact-form" style={{marginRight:"6%"}}>
                  <div className="row clearfix mt-3" style={{display:"flex",flexDirection:"column"}}>
                    <div className="col-lg-3-pre col-md-5-pre col-sm-6-pre form-group-pre">
                      <label className="labp" style={{color:"#ec167f",marginLeft:"3%"}}>Looking For</label>
                      <select
                        className="custom-select-box "
                        name="looking_for"
                        onChange={inputChangeHandler}
                        value={datap.looking_for}
                        required=""
                        tabIndex={8}
                        style={{width:"35vh",marginRight:"20%"}}
                      >
                        <option value="" selected="" >
                          
                          Looking For
                        </option>
                        <option value="Unmarried">Unmarried</option>
                        <option value="Divorced">Divorced</option>
                        <option value="Widower ">Widower</option>
                      </select>
                      <span style={{ color: "red" }}>
                        {formErrorp.looking_for}
                      </span>
                    </div>

                    <div
                      className="col-lg-3-pre col-md-5-pre form-group-pre Partner_Pref_form-pre"
                    >
                      <label className="labp" style={{color:"#ec167f",marginLeft:"3%"}} > Age from</label>
                      <select
                        className="custom-select-box"
                        autofocus=""
                        name="age_from"
                        onChange={inputChangeHandler}
                        value={datap.age_from}
                        required=""
                        tabIndex={5}
                        style={{width:"35vh",marginRight:"20%"}}
                      >
                        <option value="" selected="">
                          Select Age From
                        </option>
                        <option value={18}>18</option>
                        <option value={19}>19</option>
                        <option value={20}>20</option>
                        <option value={21}>21</option>
                        <option value={22}>22</option>
                        <option value={23}>23</option>
                        <option value={24}>24</option>
                        <option value={25}>25</option>
                        <option value={26}>26</option>
                        <option value={27}>27</option>
                        <option value={28}>28</option>
                        <option value={29}>29</option>
                        <option value={30}>30</option>
                        <option value={31}>31</option>
                        <option value={32}>32</option>
                        <option value={33}>33</option>
                        <option value={34}>34</option>
                        <option value={35}>35</option>
                        <option value={36}>36</option>
                        <option value={37}>37</option>
                        <option value={38}>38</option>
                        <option value={39}>39</option>
                        <option value={40}>40</option>
                        <option value={41}>41</option>
                        <option value={42}>42</option>
                        <option value={43}>43</option>
                        <option value={44}>44</option>
                        <option value={45}>45</option>
                        <option value={46}>46</option>
                        <option value={47}>47</option>
                        <option value={48}>48</option>
                        <option value={49}>49</option>
                        <option value={50}>50</option>
                        <option value={51}>51</option>
                        <option value={52}>52</option>
                        <option value={53}>53</option>
                        <option value={54}>54</option>
                        <option value={55}>55</option>
                        <option value={56}>56</option>
                        <option value={57}>57</option>
                        <option value={58}>58</option>
                        <option value={59}>59</option>
                        <option value={60}>60</option>
                        <option value={61}>61</option>
                        <option value={62}>62</option>
                        <option value={63}>63</option>
                        <option value={64}>64</option>
                        <option value={65}>65</option>
                      </select>
                      <span style={{ color: "red" }}>{formErrorp.age_from}</span>
                    </div>
                    <div className="col-lg-3-pre col-md-5-pre col-sm-6-pre form-group-pre">
                      <label className="labp" style={{color:"#ec167f",marginLeft:"3%"}}> Age to </label>
                      <select
                        className="custom-select-box"
                        name="age_to"
                        onChange={inputChangeHandler}
                        value={datap.age_to}
                        required=""
                        tabIndex={6}
                        style={{width:"35vh",marginRight:"20%"}}
                      >
                      
                        <option value="" selected="">
                          Select Age To
                        </option>
                        <option value={18}>18</option>
                        <option value={19}>19</option>
                        <option value={20}>20</option>
                        <option value={21}>21</option>
                        <option value={22}>22</option>
                        <option value={23}>23</option>
                        <option value={24}>24</option>
                        <option value={25}>25</option>
                        <option value={26}>26</option>
                        <option value={27}>27</option>
                        <option value={28}>28</option>
                        <option value={29}>29</option>
                        <option value={30}>30</option>
                        <option value={31}>31</option>
                        <option value={32}>32</option>
                        <option value={33}>33</option>
                        <option value={34}>34</option>
                        <option value={35}>35</option>
                        <option value={36}>36</option>
                        <option value={37}>37</option>
                        <option value={38}>38</option>
                        <option value={39}>39</option>
                        <option value={40}>40</option>
                        <option value={41}>41</option>
                        <option value={42}>42</option>
                        <option value={43}>43</option>
                        <option value={44}>44</option>
                        <option value={45}>45</option>
                        <option value={46}>46</option>
                        <option value={47}>47</option>
                        <option value={48}>48</option>
                        <option value={49}>49</option>
                        <option value={50}>50</option>
                        <option value={51}>51</option>
                        <option value={52}>52</option>
                        <option value={53}>53</option>
                        <option value={54}>54</option>
                        <option value={55}>55</option>
                        <option value={56}>56</option>
                        <option value={57}>57</option>
                        <option value={58}>58</option>
                        <option value={59}>59</option>
                        <option value={60}>60</option>
                        <option value={61}>61</option>
                        <option value={62}>62</option>
                        <option value={63}>63</option>
                        <option value={64}>64</option>
                        <option value={65}>65</option>
                      </select>
                      <span style={{ color: "red" }}>{formErrorp.age_to}</span>
                    </div>
                    <div
                      className="col-lg-3-pre col-md-5-pre col-sm-6-pre form-group-pre"
                     
                    >
                      <label className="labp" style={{color:"#ec167f",marginLeft:"3%"}}>Height form</label>
                      <select
                        className="custom-select-box"
                        name="height_from"
                        onChange={inputChangeHandler}
                        value={datap.height_from}
                        style={{width:"35vh",marginRight:"20%"}}
                      >
                     
                        <option value="" selected="">
                          Select Height From
                        </option>
                        <option value={1}>4Ft </option>
                        <option value={2}>4Ft 1 inch </option>
                        <option value={3}>4Ft 2 inch </option>
                        <option value={4}>4Ft 3 inch </option>
                        <option value={5}>4Ft 4 inch </option>
                        <option value={6}>4Ft 5 inch </option>
                        <option value={7}>4Ft 6 inch </option>
                        <option value={8}>4Ft 7 inch </option>
                        <option value={9}>4Ft 8 inch </option>
                        <option value={10}>4Ft 9 inch </option>
                        <option value={11}>4Ft 10 inch </option>
                        <option value={12}>4Ft 11 inch </option>
                        <option value={13}>5Ft </option>
                        <option value={14}>5Ft 1 inch </option>
                        <option value={15}>5Ft 2 inch </option>
                        <option value={16}>5Ft 3 inch </option>
                        <option value={17}>5Ft 4 inch </option>
                        <option value={18}>5Ft 5 inch </option>
                        <option value={19}>5Ft 6 inch </option>
                        <option value={20}>5Ft 7 inch </option>
                        <option value={21}>5Ft 8 inch </option>
                        <option value={22}>5Ft 9 inch </option>
                        <option value={23}>5Ft 10 inch </option>
                        <option value={24}>5Ft 11 inch </option>
                        <option value={25}>6Ft </option>
                        <option value={26}>6Ft 1 inch </option>
                        <option value={27}>6Ft 2 inch </option>
                        <option value={28}>6Ft 3 inch </option>
                        <option value={29}>6Ft 4 inch </option>
                        <option value={30}>6Ft 5 inch </option>
                        <option value={31}>6Ft 6 inch </option>
                        <option value={32}>6Ft 7 inch </option>
                        <option value={33}>6Ft 8 inch </option>
                        <option value={34}>6Ft 9 inch </option>
                        <option value={35}>6Ft 10 inch </option>
                        <option value={36}>6Ft 11 inch </option>
                        <option value={37}>7Ft </option>
                      </select>
                      <span style={{ color: "red" }}>
                        {formErrorp.height_from}
                      </span>
                    </div>
                    <div
                      className="col-lg-3-pre col-md-5-pre col-sm-6-pre form-group-pre"
                    >
                      <label className="labp" style={{color:"#ec167f",marginLeft:"3%"}}>Height to</label>
                      <select
                        className="custom-select-box"
                        name="height_to"
                        onChange={inputChangeHandler}
                        value={datap.height_to}
                        tabIndex={8}
                        style={{width:"35vh",marginRight:"20%"}}
                      >
                   
                        <option value="" selected="">
                          Select Height To
                        </option>
                        <option value={1}>4Ft </option>
                        <option value={2}>4Ft 1 inch </option>
                        <option value={3}>4Ft 2 inch </option>
                        <option value={4}>4Ft 3 inch </option>
                        <option value={5}>4Ft 4 inch </option>
                        <option value={6}>4Ft 5 inch </option>
                        <option value={7}>4Ft 6 inch </option>
                        <option value={8}>4Ft 7 inch </option>
                        <option value={9}>4Ft 8 inch </option>
                        <option value={10}>4Ft 9 inch </option>
                        <option value={11}>4Ft 10 inch </option>
                        <option value={12}>4Ft 11 inch </option>
                        <option value={13}>5Ft </option>
                        <option value={14}>5Ft 1 inch </option>
                        <option value={15}>5Ft 2 inch </option>
                        <option value={16}>5Ft 3 inch </option>
                        <option value={17}>5Ft 4 inch </option>
                        <option value={18}>5Ft 5 inch </option>
                        <option value={19}>5Ft 6 inch </option>
                        <option value={20}>5Ft 7 inch </option>
                        <option value={21}>5Ft 8 inch </option>
                        <option value={22}>5Ft 9 inch </option>
                        <option value={23}>5Ft 10 inch </option>
                        <option value={24}>5Ft 11 inch </option>
                        <option value={25}>6Ft </option>
                        <option value={26}>6Ft 1 inch </option>
                        <option value={27}>6Ft 2 inch </option>
                        <option value={28}>6Ft 3 inch </option>
                        <option value={29}>6Ft 4 inch </option>
                        <option value={30}>6Ft 5 inch </option>
                        <option value={31}>6Ft 6 inch </option>
                        <option value={32}>6Ft 7 inch </option>
                        <option value={33}>6Ft 8 inch </option>
                        <option value={34}>6Ft 9 inch </option>
                        <option value={35}>6Ft 10 inch </option>
                        <option value={36}>6Ft 11 inch </option>
                        <option value={37}>7Ft </option>
                      </select>
                      <span style={{ color: "red" }}>
                        {formErrorp.height_to}
                      </span>
                    </div>

                    <div className="col-lg-3-pre col-md-5-pre col-sm-6-pre form-group-pre">
                      <label className="labp" style={{color:"#ec167f",marginLeft:"3%"}}>Religion</label>
                      <select
                        className="custom-select-box"
                        name="relligion"
                        onChange={inputChangeHandler}
                        value={datap.relligion}
                        required=""
                        tabIndex={8}
                        style={{width:"35vh",marginRight:"20%"}}
                      >
                        <option value="" selected="">
                          select religion
                        </option>
                        <option value="Christian">Christian</option>
                        <option value="Hindu">Hindu</option>
                        <option value="hindu Barahsaini">
                          hindu Barahsaini
                        </option>
                        <option value="Inter-Religion">Inter-Religion</option>
                        <option value="Jain">Jain</option>
                        <option value="Muslim">Muslim</option>
                        <option value="Sikh">Sikh</option>{" "}
                      </select>
                      <span style={{ color: "red" }}>{formErrorp.relligion}</span>
                    </div>
                    <div className="col-lg-3-pre col-md-5-pre col-sm-6-pre form-group-pre">
                      <label className="labp" style={{color:"#ec167f",marginLeft:"3%"}}> Caste</label>
                      <select
                        className="custom-select-box"
                        name="caste"
                        onChange={inputChangeHandler}
                        value={datap.caste}
                        required=""
                        tabIndex={8}
                        style={{width:"35vh",marginRight:"20%"}}
                      >
                        <option value="" selected="">
                          Select Caste
                        </option>
                        <option value="Kayastha">Kayastha</option>
                        <option value="Agarwal">Agarwal</option>
                        <option value="Rajput">Rajput</option>
                        <option value="Khatri">Khatri</option>
                        <option value="Arora">Arora</option>
                        <option value="Scheduled Caste">Scheduled Caste</option>
                        <option value="Yadav">Yadav</option>
                        <option value="Punjabi">Punjabi</option>
                        <option value="Bengali">Bengali</option>
                        <option value="Nair">Nair</option>
                        <option value="Kshatriya">Kshatriya</option>
                        <option value="Gupta">Gupta</option>
                        <option value="Jat">Jat</option>
                        <option value="96K Kokanastha">96K Kokanastha</option>
                        <option value="Ad Dharmi">Ad Dharmi</option>
                        <option value="Adi Andhra">Adi Andhra</option>
                        <option value="Adi Dravida">Adi Dravida</option>
                        <option value="Adi Karnataka">Adi Karnataka</option>
                        <option value="Agnikula Kshatriya">
                          Agnikula Kshatriya
                        </option>
                        <option value="Agri">Agri</option>
                        <option value="Ahom">Ahom</option>
                        <option value="Ambalavasi">Ambalavasi</option>
                        <option value="Amil Sindhi">Amil Sindhi</option>
                        <option value="Anglo Indian">Anglo Indian</option>
                        <option value="Araya">Araya</option>
                        <option value="Arekatica">Arekatica</option>
                        <option value="Arunthathiyar">Arunthathiyar</option>
                        <option value="Arya Vysya">Arya Vysya</option>
                        <option value="Aryasamaj">Aryasamaj</option>
                        <option value="Ayyaraka">Ayyaraka</option>
                        <option value="Badaga">Badaga</option>
                        <option value="Bahi">Bahi</option>
                        <option value="Baibhand Sindhi">Baibhand Sindhi</option>
                        <option value="Baidya">Baidya</option>
                        <option value="Baishnab">Baishnab</option>
                        <option value="Baishya">Baishya</option>
                        <option value="Balija">Balija</option>
                        <option value="Banik">Banik</option>
                        <option value="Baniya">Baniya</option>
                        <option value="Banjara">Banjara</option>
                        <option value="Barai">Barai</option>
                        <option value="Bari">Bari</option>
                        <option value="Barujibi">Barujibi</option>
                        <option value="Besta">Besta</option>
                        <option value="Bhandari">Bhandari</option>
                        <option value="Bhanusali Sindhi">
                          Bhanusali Sindhi
                        </option>
                        <option value="Bhatia">Bhatia</option>
                        <option value="Bhatia Sindhi">Bhatia Sindhi</option>
                        <option value="Bhatraju">Bhatraju</option>
                        <option value="Bhavasar Kshatriya">
                          Bhavasar Kshatriya
                        </option>
                        <option value="Bhavsar">Bhavsar</option>
                        <option value="Bhovi">Bhovi</option>
                        <option value="Bhumihar Brahmin">
                          Bhumihar Brahmin
                        </option>
                        <option value="Billava">Billava</option>
                        <option value="Bishnoi/Vishnoi">Bishnoi/Vishnoi</option>
                        <option value="Boyer">Boyer</option>
                        <option value="Brahmbatt">Brahmbatt</option>
                        <option value="Bunt">Bunt</option>
                        <option value="Bunt (Shetty)">Bunt (Shetty)</option>
                        <option value="Chambhar">Chambhar</option>
                        <option value="Chandraseniya Kayastha Prab">
                          Chandraseniya Kayastha Prab
                        </option>
                        <option value="Chandravanshi Kahar">
                          Chandravanshi Kahar
                        </option>
                        <option value="Chasa">Chasa</option>
                        <option value="Chattada Sri Vaishnava">
                          Chattada Sri Vaishnava
                        </option>
                        <option value="Chaudary">Chaudary</option>
                        <option value="Chaurasia">Chaurasia</option>
                        <option value="Chettiar">Chettiar</option>
                        <option value="Chhapru Sindhi">Chhapru Sindhi</option>
                        <option value="Chhetri">Chhetri</option>
                        <option value="Chippolu (Mera)">Chippolu (Mera)</option>
                        <option value="CKP">CKP</option>
                        <option value="Coorgi">Coorgi</option>
                        <option value="Dadu Sindhi">Dadu Sindhi</option>
                        <option value="Darji">Darji</option>
                        <option value="Dawoodi Bhora">Dawoodi Bhora</option>
                        <option value="Devadiga">Devadiga</option>
                        <option value="Devandra Kula Vellalar">
                          Devandra Kula Vellalar
                        </option>
                        <option value="Devang Koshthi">Devang Koshthi</option>
                        <option value="Devanga">Devanga</option>
                        <option value="Devar/Thevar/Mukkulathor">
                          Devar/Thevar/Mukkulathor
                        </option>
                        <option value="Devendra Kula Vellalar">
                          Devendra Kula Vellalar
                        </option>
                        <option value="Dhaneshawat Vaish">
                          Dhaneshawat Vaish
                        </option>
                        <option value="Dhangar">Dhangar</option>
                        <option value="Dheevara">Dheevara</option>
                        <option value="Dhiman">Dhiman</option>
                        <option value="Dhoba">Dhoba</option>
                        <option value="Dhobi">Dhobi</option>
                        <option value="Dumal">Dumal</option>
                        <option value="Dusadh (Paswan)">Dusadh (Paswan)</option>
                        <option value="Ediga">Ediga</option>
                        <option value="Ezhava">Ezhava</option>
                        <option value="Ezhuthachan">Ezhuthachan</option>
                        <option value="Gabit">Gabit</option>
                        <option value="Ganda">Ganda</option>
                        <option value="Gandla">Gandla</option>
                        <option value="Ganiga">Ganiga</option>
                        <option value="Ganigashetty">Ganigashetty</option>
                        <option value="Garhwali">Garhwali</option>
                        <option value="Garhwali Rajput">Garhwali Rajput</option>
                        <option value="Gavali">Gavali</option>
                        <option value="Gavara">Gavara</option>
                        <option value="Gawali">Gawali</option>
                        <option value="Ghisadi">Ghisadi</option>
                        <option value="Ghumar">Ghumar</option>
                        <option value="Goala">Goala</option>
                        <option value="Goan">Goan</option>
                        <option value="Gomantak">Gomantak</option>
                        <option value="Gomantak Maratha">
                          Gomantak Maratha
                        </option>
                        <option value="Gondhali">Gondhali</option>
                        <option value="Goswami">Goswami</option>
                        <option value="Goswami/Gosavi Brahmin">
                          Goswami/Gosavi Brahmin
                        </option>
                        <option value="Goud">Goud</option>
                        <option value="Gounder">Gounder</option>
                        <option value="Gowda">Gowda</option>
                        <option value="Gramani">Gramani</option>
                        <option value="Gudia">Gudia</option>
                        <option value="Gujarati">Gujarati</option>
                        <option value="Gujjar">Gujjar</option>
                        <option value="Guptan">Guptan</option>
                        <option value="Gurav">Gurav</option>
                        <option value="Gurjar">Gurjar</option>
                        <option value="Hegde">Hegde</option>
                        <option value="Himachali">Himachali</option>
                        <option value="Hindu-Others">Hindu-Others</option>
                        <option value="Hugar (Jeer)">Hugar (Jeer)</option>
                        <option value="Hyderabadi Sindhi">
                          Hyderabadi Sindhi
                        </option>
                        <option value="Intercaste">Intercaste</option>
                        <option value="Irani">Irani</option>
                        <option value="Iyengar">Iyengar</option>
                        <option value="Iyer">Iyer</option>
                        <option value="Jaalari">Jaalari</option>
                        <option value="Jaiswal">Jaiswal</option>
                        <option value="Jandra">Jandra</option>
                        <option value="Jangam">Jangam</option>
                        <option value="Jatav">Jatav</option>
                        <option value="Jeer">Jeer</option>
                        <option value="Jogi (Nath)">Jogi (Nath)</option>
                        <option value="Kachara">Kachara</option>
                        <option value="Kadava Patel">Kadava Patel</option>
                        <option value="Kahar">Kahar</option>
                        <option value="Kaibarta">Kaibarta</option>
                        <option value="Kalal">Kalal</option>
                        <option value="Kalar">Kalar</option>
                        <option value="Kalinga">Kalinga</option>
                        <option value="Kalinga Vysya">Kalinga Vysya</option>
                        <option value="Kalita">Kalita</option>
                        <option value="Kalwar">Kalwar</option>
                        <option value="Kamboj">Kamboj</option>
                        <option value="Kamma">Kamma</option>
                        <option value="Kannada Mogaveera">
                          Kannada Mogaveera
                        </option>
                        <option value="Kansari">Kansari</option>
                        <option value="Kapu">Kapu</option>
                        <option value="Kapu Naidu">Kapu Naidu</option>
                        <option value="Karana">Karana</option>
                        <option value="Karmakar">Karmakar</option>
                        <option value="Karuneegar">Karuneegar</option>
                        <option value="Karuneekar">Karuneekar</option>
                        <option value="Kasar">Kasar</option>
                        <option value="Kashyap">Kashyap</option>
                        <option value="Katiya">Katiya</option>
                        <option value="Keralite">Keralite</option>
                        <option value="Khandayat">Khandayat</option>
                        <option value="Khandelwal">Khandelwal</option>
                        <option value="Kharwar">Kharwar</option>
                        <option value="Khatik">Khatik</option>
                        <option value="Khukhrain">Khukhrain</option>
                        <option value="Koiri">Koiri</option>
                        <option value="Kokanastha Maratha">
                          Kokanastha Maratha
                        </option>
                        <option value="Koli">Koli</option>
                        <option value="Koli Mahadev">Koli Mahadev</option>
                        <option value="Kongu Vellala Gounder">
                          Kongu Vellala Gounder
                        </option>
                        <option value="Konkani">Konkani</option>
                        <option value="Kori">Kori</option>
                        <option value="Koshti">Koshti</option>
                        <option value="Kudumbi">Kudumbi</option>
                        <option value="Kulal">Kulal</option>
                        <option value="Kulalar">Kulalar</option>
                        <option value="Kulita">Kulita</option>
                        <option value="Kumaoni">Kumaoni</option>
                        <option value="Kumaoni Rajput">Kumaoni Rajput</option>
                        <option value="Kumawat">Kumawat</option>
                        <option value="Kumbara">Kumbara</option>
                        <option value="Kumbhakar">Kumbhakar</option>
                        <option value="Kumbhar">Kumbhar</option>
                        <option value="Kumhar">Kumhar</option>
                        <option value="Kummari">Kummari</option>
                        <option value="Kunbi">Kunbi</option>
                        <option value="Kuravan">Kuravan</option>
                        <option value="Kurmi">Kurmi</option>
                        <option value="Kurmi Kshatriya">Kurmi Kshatriya</option>
                        <option value="Kuruba">Kuruba</option>
                        <option value="Kuruhina Shetty">Kuruhina Shetty</option>
                        <option value="Kurumbar">Kurumbar</option>
                        <option value="Kushwaha">Kushwaha</option>
                        <option value="Kushwaha (Koiri)">
                          Kushwaha (Koiri)
                        </option>
                        <option value="Kutchi">Kutchi</option>
                        <option value="Lambadi">Lambadi</option>
                        <option value="Lambani">Lambani</option>
                        <option value="Larai Sindhi">Larai Sindhi</option>
                        <option value="Larkana Sindhi">Larkana Sindhi</option>
                        <option value="Leva patel">Leva patel</option>
                        <option value="Leva Patidar">Leva Patidar</option>
                        <option value="Leva patil">Leva patil</option>
                        <option value="Lingayath">Lingayath</option>
                        <option value="Lodhi Rajput">Lodhi Rajput</option>
                        <option value="Lohana">Lohana</option>
                        <option value="Lohana Sindhi">Lohana Sindhi</option>
                        <option value="Lohar">Lohar</option>
                        <option value="Lubana">Lubana</option>
                        <option value="Madiga">Madiga</option>
                        <option value="Mahajan">Mahajan</option>
                        <option value="Mahar">Mahar</option>
                        <option value="Maharashtrian">Maharashtrian</option>
                        <option value="Mahendra">Mahendra</option>
                        <option value="Maheshwari">Maheshwari</option>
                        <option value="Mahishya">Mahishya</option>
                        <option value="Mahisya">Mahisya</option>
                        <option value="Majabi">Majabi</option>
                        <option value="Mala">Mala</option>
                        <option value="Malayalee">Malayalee</option>
                        <option value="Malayalee Namboodiri">
                          Malayalee Namboodiri
                        </option>
                        <option value="Mali">Mali</option>
                        <option value="Mallah">Mallah</option>
                        <option value="Mangalorean">Mangalorean</option>
                        <option value="Manipuri">Manipuri</option>
                        <option value="Mannuru Kapu">Mannuru Kapu</option>
                        <option value="Mapila">Mapila</option>
                        <option value="Maruthuvar">Maruthuvar</option>
                        <option value="Marvar">Marvar</option>
                        <option value="Marwari">Marwari</option>
                        <option value="Matang">Matang</option>
                        <option value="Mathur">Mathur</option>
                        <option value="Maurya">Maurya</option>
                        <option value="Meena">Meena</option>
                        <option value="Meenavar">Meenavar</option>
                        <option value="Mehra">Mehra</option>
                        <option value="Menon">Menon</option>
                        <option value="Mera">Mera</option>
                        <option value="Meru">Meru</option>
                        <option value="Meru Darji">Meru Darji</option>
                        <option value="Mochi">Mochi</option>
                        <option value="Modak">Modak</option>
                        <option value="Mogaveera">Mogaveera</option>
                        <option value="Monchi">Monchi</option>
                        <option value="Mudaliar">Mudaliar</option>
                        <option value="Mudaliar - Senguntha">
                          Mudaliar - Senguntha
                        </option>
                        <option value="Mudaliar Arcot">Mudaliar Arcot</option>
                        <option value="Mudaliar Saiva">Mudaliar Saiva</option>
                        <option value="Mudaliyar">Mudaliyar</option>
                        <option value="Mudiraj">Mudiraj</option>
                        <option value="Mukkulathor">Mukkulathor</option>
                        <option value="Mukulathur">Mukulathur</option>
                        <option value="Munnuru Kapu">Munnuru Kapu</option>
                        <option value="Muthuraja">Muthuraja</option>
                        <option value="Naagavamsam">Naagavamsam</option>
                        <option value="Nadar">Nadar</option>
                        <option value="Nagaralu">Nagaralu</option>
                        <option value="Nai">Nai</option>
                        <option value="Naicker">Naicker</option>
                        <option value="Naidu">Naidu</option>
                        <option value="Naik">Naik</option>
                        <option value="Naik/Nayaka">Naik/Nayaka</option>
                        <option value="Naika">Naika</option>
                        <option value="Nair Vaniya">Nair Vaniya</option>
                        <option value="Nair Vilakkithala">
                          Nair Vilakkithala
                        </option>
                        <option value="Namasudra">Namasudra</option>
                        <option value="Nambiar">Nambiar</option>
                        <option value="Namboodiri">Namboodiri</option>
                        <option value="Namosudra">Namosudra</option>
                        <option value="Napit">Napit</option>
                        <option value="Nath">Nath</option>
                        <option value="Nayaka">Nayaka</option>
                        <option value="Neeli">Neeli</option>
                        <option value="Nepali">Nepali</option>
                        <option value="Nhavi">Nhavi</option>
                        <option value="OBC/Barber/Naayee">
                          OBC/Barber/Naayee
                        </option>
                        <option value="Oriya">Oriya</option>
                        <option value="Oswal">Oswal</option>
                        <option value="Otari">Otari</option>
                        <option value="Padmasali">Padmasali</option>
                        <option value="Padmashali">Padmashali</option>
                        <option value="Pal">Pal</option>
                        <option value="Panchal">Panchal</option>
                        <option value="Pandaram">Pandaram</option>
                        <option value="Panicker">Panicker</option>
                        <option value="Parkava Kulam">Parkava Kulam</option>
                        <option value="Parsi">Parsi</option>
                        <option value="Partraj">Partraj</option>
                        <option value="Pasi">Pasi</option>
                        <option value="Paswan">Paswan</option>
                        <option value="Patel">Patel</option>
                        <option value="Patel Desai">Patel Desai</option>
                        <option value="Patel Dodia">Patel Dodia</option>
                        <option value="Patel Kadva">Patel Kadva</option>
                        <option value="Patel Leva">Patel Leva</option>
                        <option value="Pathare Prabhu">Pathare Prabhu</option>
                        <option value="Patil">Patil</option>
                        <option value="Patil Leva">Patil Leva</option>
                        <option value="Patnaick">Patnaick</option>
                        <option value="Patnaick/Sistakaranam">
                          Patnaick/Sistakaranam
                        </option>
                        <option value="Patra">Patra</option>
                        <option value="Perika">Perika</option>
                        <option value="Pillai">Pillai</option>
                        <option value="Poosala">Poosala</option>
                        <option value="Porwal">Porwal</option>
                        <option value="Prajapati">Prajapati</option>
                        <option value="Raigar">Raigar</option>
                        <option value="Rajaka">Rajaka</option>
                        <option value="Rajastani">Rajastani</option>
                        <option value="Rajbhar">Rajbhar</option>
                        <option value="Rajbonshi">Rajbonshi</option>
                        <option value="Rajput Rohella/Tank">
                          Rajput Rohella/Tank
                        </option>
                        <option value="Ramdasia">Ramdasia</option>
                        <option value="Ramgariah">Ramgariah</option>
                        <option value="Ravidasia">Ravidasia</option>
                        <option value="Rawat">Rawat</option>
                        <option value="Reddy">Reddy</option>
                        <option value="Relli">Relli</option>
                        <option value="Rohiri Sindhi">Rohiri Sindhi</option>
                        <option value="Sadgope">Sadgope</option>
                        <option value="Saha">Saha</option>
                        <option value="Sahiti Sindhi">Sahiti Sindhi</option>
                        <option value="Sahu">Sahu</option>
                        <option value="Saini">Saini</option>
                        <option value="Sakkhar Sindhi">Sakkhar Sindhi</option>
                        <option value="Saliya">Saliya</option>
                        <option value="Saurashtra">Saurashtra</option>
                        <option value="Savji">Savji</option>
                        <option value="Scheduled Tribe">Scheduled Tribe</option>
                        <option value="Sehwani Sindhi">Sehwani Sindhi</option>
                        <option value="Senai Thalaivar">Senai Thalaivar</option>
                        <option value="Senguntha Mudaliyar">
                          Senguntha Mudaliyar
                        </option>
                        <option value="Sepahia">Sepahia</option>
                        <option value="Setti Balija">Setti Balija</option>
                        <option value="Settibalija">Settibalija</option>
                        <option value="Shah">Shah</option>
                        <option value="Shetty">Shetty</option>
                        <option value="Shikarpuri Sindhi">
                          Shikarpuri Sindhi
                        </option>
                        <option value="Shimpi">Shimpi</option>
                        <option value="Sindhi">Sindhi</option>
                        <option value="Sindhi-Amil">Sindhi-Amil</option>
                        <option value="Sindhi-Baibhand">Sindhi-Baibhand</option>
                        <option value="Sindhi-Bhanusali">
                          Sindhi-Bhanusali
                        </option>
                        <option value="Sindhi-Bhatia">Sindhi-Bhatia</option>
                        <option value="Sindhi-Chhapru">Sindhi-Chhapru</option>
                        <option value="Sindhi-Dadu">Sindhi-Dadu</option>
                        <option value="Sindhi-Hyderabadi">
                          Sindhi-Hyderabadi
                        </option>
                        <option value="Sindhi-Larai">Sindhi-Larai</option>
                        <option value="Sindhi-Larkana">Sindhi-Larkana</option>
                        <option value="Sindhi-Lohana">Sindhi-Lohana</option>
                        <option value="Sindhi-Rohiri">Sindhi-Rohiri</option>
                        <option value="Sindhi-Sahiti">Sindhi-Sahiti</option>
                        <option value="Sindhi-Sakkhar">Sindhi-Sakkhar</option>
                        <option value="Sindhi-Sehwani">Sindhi-Sehwani</option>
                        <option value="Sindhi-Shikarpuri">
                          Sindhi-Shikarpuri
                        </option>
                        <option value="Sindhi-Thatai">Sindhi-Thatai</option>
                        <option value="SKP">SKP</option>
                        <option value="Somvanshi">Somvanshi</option>
                        <option value="Sonar">Sonar</option>
                        <option value="Soni">Soni</option>
                        <option value="Sourashtra">Sourashtra</option>
                        <option value="Sozhiya Vellalar">
                          Sozhiya Vellalar
                        </option>
                        <option value="Srisayana">Srisayana</option>
                        <option value="Srisayani">Srisayani</option>
                        <option value="Sugali (Naika)">Sugali (Naika)</option>
                        <option value="Sunari">Sunari</option>
                        <option value="Sundhi">Sundhi</option>
                        <option value="Sutar">Sutar</option>
                        <option value="Suthar">Suthar</option>
                        <option value="Swakula Sali">Swakula Sali</option>
                        <option value="Swarnakar">Swarnakar</option>
                        <option value="Tamboli">Tamboli</option>
                        <option value="Tamil">Tamil</option>
                        <option value="Tamil Yadava">Tamil Yadava</option>
                        <option value="Tanti">Tanti</option>
                        <option value="Tantubai">Tantubai</option>
                        <option value="Tantuway">Tantuway</option>
                        <option value="Telaga">Telaga</option>
                        <option value="Teli">Teli</option>
                        <option value="Telugu">Telugu</option>
                        <option value="Thakkar">Thakkar</option>
                        <option value="Thakore">Thakore</option>
                        <option value="Thakur">Thakur</option>
                        <option value="Thatai Sindhi">Thatai Sindhi</option>
                        <option value="Thigala">Thigala</option>
                        <option value="Thiyya">Thiyya</option>
                        <option value="Tili">Tili</option>
                        <option value="Togata">Togata</option>
                        <option value="Tonk Kshatriya">Tonk Kshatriya</option>
                        <option value="Turupu Kapu">Turupu Kapu</option>
                        <option value="Udayar">Udayar</option>
                        <option value="Uppara">Uppara</option>
                        <option value="Urali Gounder">Urali Gounder</option>
                        <option value="Urs">Urs</option>
                        <option value="Vada Balija">Vada Balija</option>
                        <option value="Vadagalai">Vadagalai</option>
                        <option value="Vaddera">Vaddera</option>
                        <option value="Vaish">Vaish</option>
                        <option value="Vaishnav">Vaishnav</option>
                        <option value="Vaishnav Bhatia">Vaishnav Bhatia</option>
                        <option value="Vaishnav Vania">Vaishnav Vania</option>
                        <option value="Vaishnav Vanik">Vaishnav Vanik</option>
                        <option value="Vaishnava">Vaishnava</option>
                        <option value="Vaishya">Vaishya</option>
                        <option value="Vaishya Vani">Vaishya Vani</option>
                        <option value="Valluvan">Valluvan</option>
                        <option value="Valmiki">Valmiki</option>
                        <option value="Vania">Vania</option>
                        <option value="Vaniya">Vaniya</option>
                        <option value="Vanjara">Vanjara</option>
                        <option value="Vanjari">Vanjari</option>
                        <option value="Vankar">Vankar</option>
                        <option value="Vannar">Vannar</option>
                        <option value="Vannia Kula Kshatriyar">
                          Vannia Kula Kshatriyar
                        </option>
                        <option value="Vanniyar">Vanniyar</option>
                        <option value="Variar">Variar</option>
                        <option value="Varshney">Varshney</option>
                        <option value="Veera Saivam">Veera Saivam</option>
                        <option value="Veerashaiva">Veerashaiva</option>
                        <option value="Velaan/Vellalar">Velaan/Vellalar</option>
                        <option value="Velama">Velama</option>
                        <option value="Vellalar">Vellalar</option>
                        <option value="Vellalar Devandra Kula">
                          Vellalar Devandra Kula
                        </option>
                        <option value="Veluthedathu Nair">
                          Veluthedathu Nair
                        </option>
                        <option value="Vettuva Gounder">Vettuva Gounder</option>
                        <option value="Vilakkithala Nair">
                          Vilakkithala Nair
                        </option>
                        <option value="Vishwakarma">Vishwakarma</option>
                        <option value="Viswabrahmin">Viswabrahmin</option>
                        <option value="Vokkaliga">Vokkaliga</option>
                        <option value="Vysya">Vysya</option>
                        <option value="Yellapu">Yellapu</option>
                        <option value="Sonam">Sonam</option>
                        <option value="Maratha">Maratha</option>
                      </select>
                      <span style={{ color: "red" }}>{formErrorp.caste}</span>
                      <div className="btn-group-pre" ></div>
                    </div>
                    <div className="col-lg-3-pre col-md-5-pre col-sm-6-pre form-group-pre ">
                      <label className="labp" style={{color:"#ec167f",marginLeft:"3%"}}> Complexion</label>
                      <select
                        className="custom-select-box"
                        name="complexion"
                        onChange={inputChangeHandler}
                        value={datap.complexion}
                        tabIndex={8}
                        style={{width:"35vh",marginRight:"20%"}}
                      >
                        <option value="" selected="">
                          Select Complexion
                        </option>

                        <option value="Very Fair">Very Fair</option>
                        <option value="Fair">Fair</option>
                        <option value="Wheatish">Wheatish</option>
                        <option value="Wheatish Medium">Wheatish Medium</option>
                        <option value="Wheatish Brown">Wheatish Brown</option>
                        <option value="Dark">Dark</option>
                      </select>
                      <span style={{ color: "red" }}>
                        {formErrorp.complexion}
                      </span>
                    </div>
                    <div className="col-lg-3-pre col-md-5-pre col-sm-6-pre form-group-pre ">
                      <label className="labp" style={{color:"#ec167f",marginLeft:"3%"}}> Residency Status</label>
                      <select
                        className="custom-select-box"
                        name="residency_status"
                        onChange={inputChangeHandler}
                        value={datap.residency_status}
                        required=""
                        tabIndex={8}
                        style={{width:"35vh",marginRight:"20%"}}
                      >
                        <option value="" selected="">
                          Select Residency Status
                        </option>
                        <option value="Permanent Resident">
                          Permanent Resident
                        </option>
                        <option value="Student Visa">Student Visa</option>
                        <option value="Temporary Visa">Temporary Visa</option>
                        <option value="Work Permit">Work Permit</option>{" "}
                      </select>
                      <span style={{ color: "red" }}>
                        {formErrorp.residency_status}
                      </span>
                    </div>
                    <div
                      className="col-lg-3-pre col-md-5-pre col-sm-6-pre form-group-pre"
                  
                    >
                      <label className="labp" style={{color:"#ec167f",marginLeft:"3%"}}> Country</label>
                     
                      <select
                        className="custom-select-box"
                        name="country"
                        onChange={inputChangeHandler}
                        value={datap.country}
                        required=""
                        tabIndex={8}
                        style={{width:"35vh",marginRight:"20%"}}
                      >
                        <option value="" selected="">
                          Select Country
                        </option>
                        <option value="India" selected="">
                          India
                        </option>
                       
                      </select>
                      <span style={{ color: "red" }}>{formErrorp.country}</span>
                      <div className="btn-group-pre" >
                      </div>
              
                    </div>
                    <div
                      className="col-lg-3-pre col-md-5-pre form-group-pre"
                    >
                      <label className="labp" style={{color:"#ec167f",marginLeft:"3%"}}>State</label>
                    
                      <select
                        className="custom-select-box"
                        name="state"
                        onChange={inputChangeHandler}
                        value={datap.state}
                        required=""
                        tabIndex={8}
                        style={{width:"35vh",marginRight:"20%"}}
                      >
                        {" "}
                        <option value="" selected="">
                          Select State
                        </option>
                        <option value="Delhi">Delhi</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Pradesh">
                          Himachal Pradesh
                        </option>
                        <option value="Jammu and Kashmir">
                          Jammu and Kashmir
                        </option>
                        <option value="Kerala">Kerala</option>
                        <option value="Lakshadweep">Lakshadweep</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Orissa">Orissa</option>
                        <option value="Pondicherry">Pondicherry</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Bihar">Bihar</option>
                        <option value="West Bengal">West Bengal</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Arunachal Pradesh">
                          Arunachal Pradesh
                        </option>
                        <option value="Daman and Diu">Daman and Diu</option>
                        <option value="Goa">Goa</option>
                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Uttaranchal">Uttaranchal</option>
                        <option value="Out of India">Out of India</option>
                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                      </select>
                      <span style={{ color: "red" }}>{formErrorp.state}</span>

                      <div className="btn-group-pre" >
                        

                        <div className="multiselect-container dropdown-menu" />
                      </div>
                    
                    </div>
                    <div
                      className="col-lg-3-pre col-md-5-pre col-sm-6-pre form-group-pre"
                      
                    >
                      <label className="labp" style={{color:"#ec167f",marginLeft:"3%"}}> Education</label>
                   
                      <select
                        className="custom-select-box"
                        name="education"
                        onChange={inputChangeHandler}
                        value={datap.education}
                        required=""
                        tabIndex={8}
                        style={{width:"35vh",marginRight:"20%"}}
                      >
                     
                        <option value="" selected="">
                          Select Education
                        </option>
                        <option value="12th">12th</option>
                        <option value="B.A. (Arts)		">B.A. (Arts) </option>
                        <option value="B.Com (Commerce)">
                          B.Com (Commerce)
                        </option>
                        <option value="B.Sc (Science)">B.Sc (Science)</option>
                        <option value="B.Arch (Architecture)	">
                          B.Arch (Architecture){" "}
                        </option>
                        <option value="B.Ed (Education)	">
                          B.Ed (Education){" "}
                        </option>
                        <option value="B.El.Ed (Elementary Education)	">
                          B.El.Ed (Elementary Education){" "}
                        </option>
                        <option value="B.Lib.Sc (Library Sciences)	">
                          B.Lib.Sc (Library Sciences){" "}
                        </option>
                        <option value="B.P.Ed. (Physical Education)">
                          B.P.Ed. (Physical Education)
                        </option>
                        <option value="B.Plan (Planning)	">
                          B.Plan (Planning){" "}
                        </option>
                        <option value="	Bachelor of Fashion Technology		">
                          {" "}
                          Bachelor of Fashion Technology{" "}
                        </option>
                        <option value="	BBA/BBM/BBS	"> BBA/BBM/BBS </option>
                        <option value="BCA (Computer Application)		">
                          BCA (Computer Application){" "}
                        </option>
                        <option value="BE B.Tech (Engineering)">
                          BE B.Tech (Engineering)
                        </option>
                        <option value="BFA (Fine Arts)">BFA (Fine Arts)</option>
                        <option value="BHM (Hotel Management)	">
                          BHM (Hotel Management){" "}
                        </option>
                        <option value="BL/LLB/BGL (Law)">
                          BL/LLB/BGL (Law)
                        </option>
                        <option value="BSW (Social Work)">
                          BSW (Social Work)
                        </option>
                        <option value="	B.Pharm (Pharmacy)		">
                          {" "}
                          B.Pharm (Pharmacy){" "}
                        </option>
                        <option value="	B.V.Sc. (Veterinary Science)">
                          {" "}
                          B.V.Sc. (Veterinary Science)
                        </option>
                        <option value="BDS (Dental Surgery)	">
                          BDS (Dental Surgery){" "}
                        </option>
                        <option value="BHMS (Homeopathy)">
                          BHMS (Homeopathy)
                        </option>
                        <option value="CA (Chartered Accountant)	">
                          CA (Chartered Accountant){" "}
                        </option>
                        <option value="CFA (Chartered Financial Analyst)">
                          CFA (Chartered Financial Analyst)
                        </option>
                        <option value="CS (Company Secretary)">
                          CS (Company Secretary)
                        </option>
                        <option value="ICWA">ICWA</option>
                        <option value="Integrated PG">Integrated PG</option>
                        <option value="Engineering">Engineering</option>
                        <option value="Fashion/ Design	">
                          Fashion/ Design{" "}
                        </option>
                        <option value="Languages	">Languages </option>
                        <option value="Pilot Licenses	">Pilot Licenses </option>
                        <option value="M.Arch. (Architecture)	">
                          M.Arch. (Architecture){" "}
                        </option>
                        <option value="M.Ed. (Education)">
                          M.Ed. (Education)
                        </option>
                        <option value="M.Lib.Sc. (Library Sciences)">
                          M.Lib.Sc. (Library Sciences)
                        </option>
                        <option value="M.Plan. (Planning)">
                          M.Plan. (Planning)
                        </option>
                        <option value="Master of Fashion Technology">
                          Master of Fashion Technology
                        </option>
                        <option value="Master of Health Administration	">
                          Master of Health Administration{" "}
                        </option>
                        <option value="Master of Hospital Administration">
                          Master of Hospital Administration
                        </option>
                        <option value="MBA/PGDM">MBA/PGDM</option>
                        <option value="MCA PGDCA part time	">
                          MCA PGDCA part time{" "}
                        </option>
                        <option value="MCA/PGDCA">MCA/PGDCA</option>
                        <option value="ME/M.Tech/MS (Engg/Sciences)">
                          ME/M.Tech/MS (Engg/Sciences)
                        </option>
                        <option value="MFA (Fine Arts)	">
                          MFA (Fine Arts){" "}
                        </option>
                        <option value="ML/LLM (Law)	">ML/LLM (Law) </option>
                        <option value="MSW (Social Work)	">
                          MSW (Social Work){" "}
                        </option>
                        <option value="PG Diploma">PG Diploma</option>
                        <option value="M.Com. (Commerce)">
                          M.Com. (Commerce)
                        </option>
                        <option value="M.Sc. (Science)">M.Sc. (Science)</option>
                        <option value="MA (Arts)">MA (Arts)</option>
                        <option value="M.Pharm. (Pharmacy)	">
                          M.Pharm. (Pharmacy){" "}
                        </option>
                        <option value="M.V.Sc. (Veterinary Science)">
                          M.V.Sc. (Veterinary Science)
                        </option>
                        <option value="MBBS">MBBS</option>
                        <option value="MD/ MS (Medicine)">
                          MD/ MS (Medicine)
                        </option>
                        <option value="MDS (Master of Dental Surgery)">
                          MDS (Master of Dental Surgery)
                        </option>
                        <option value="BPT (Physiotherapy">
                          BPT (Physiotherapy)
                        </option>
                        <option value="MPT (Physiotherapy)">
                          MPT (Physiotherapy)
                        </option>
                        <option value="M.Phil. (Philosophy)">
                          M.Phil. (Philosophy)
                        </option>
                        <option value="Ph.D. (Doctorate)	">
                          Ph.D. (Doctorate){" "}
                        </option>
                        <option value="Other Doctorate">Other Doctorate</option>
                        <option value="Other Diploma">Other Diploma</option>
                        <option value="Agriculture">Agriculture</option>
                        <option value="10th">10th</option>{" "}
                      </select>
                      <span style={{ color: "red" }}>
                        {formErrorp.education}
                      </span>
                      <div className="btn-group-pre" >
                       
                      </div>
                  
                    </div>
                    <div
                      className="col-lg-3-pre col-md-5-pre col-sm-6-pre form-group-pre"
                 
                    >
                      <label className="labp" style={{color:"#ec167f",marginLeft:"3%"}}> Occupation</label>
             
                      <select
                        className="custom-select-box"
                        name="occupation"
                        onChange={inputChangeHandler}
                        value={datap.occupation}
                        required=""
                        tabIndex={8}
                        style={{width:"35vh",marginRight:"20%"}}
                      >
                        <option value="" selected="">
                          Select Occupation
                        </option>
                        <option value="Science" selected="">
                          Science
                        </option>{" "}
                   
                        <option value="Architecture & Design">
                          Architecture &amp; Design
                        </option>
                        <option value="Artists">Artists</option>
                        <option value=" Animators & Web Designers">
                          {" "}
                          Animators &amp; Web Designers
                        </option>
                        <option value="Banking">Banking</option>
                        <option value=" Insurance & Financial Services">
                          {" "}
                          Insurance &amp; Financial Services
                        </option>
                        <option value="Beauty">Beauty</option>
                        <option value=" Fashion & Jewellery Designers">
                          {" "}
                          Fashion &amp; Jewellery Designers
                        </option>
                        <option value="Business Owner / Entrepreneur">
                          Business Owner / Entrepreneur
                        </option>
                        <option value="Civil Services / Law Enforcement">
                          Civil Services / Law Enforcement
                        </option>
                        <option value="Construction">Construction</option>
                        <option value="Customer Service/ Call Centre/BPO">
                          Customer Service/ Call Centre/BPO
                        </option>
                        <option value="Defence">Defence</option>
                        <option value="Education/ Training">
                          Education/ Training
                        </option>
                        <option value="Electronics">Electronics</option>
                        <option value="Export/ Import">Export/ Import</option>
                        <option value="Finance and Accounts">
                          Finance and Accounts
                        </option>
                        <option value="Government Employee">
                          Government Employee
                        </option>
                        <option value="Health Care">Health Care</option>
                        <option value="Hotels/ Restaurants">
                          Hotels/ Restaurants
                        </option>
                        <option value="Human Resource">Human Resource</option>
                        <option value="IT">IT</option>
                        <option value="Legal">Legal</option>
                        <option value="Management / Corporate Professionals">
                          Management / Corporate Professionals
                        </option>
                        <option value="Manufacturing/ Engineering/ R&D">
                          Manufacturing/ Engineering/ R&amp;D
                        </option>
                        <option value="Marketing and Communications">
                          Marketing and Communications
                        </option>
                        <option value="Merchant Navy">Merchant Navy</option>
                        <option value="Non Working">Non Working</option>
                        <option value="Oil & Gas">Oil &amp; Gas</option>
                        <option value="Others">Others</option>
                        <option value="Pharmaceutical/ Biotechnology">
                          Pharmaceutical/ Biotechnology
                        </option>
                        <option value="Purchase/ Logistics/ Supply chain">
                          Purchase/ Logistics/ Supply chain
                        </option>
                        <option value="Real Estate">Real Estate</option>
                        <option value="Retail Chains">Retail Chains</option>
                        <option value="Sales/ Business Development">
                          Sales/ Business Development
                        </option>
                        <option value="Science">Science</option>
                        <option value="Telecom/ ISP">Telecom/ ISP</option>
                        <option value="Travel/ Airlines">
                          Travel/ Airlines
                        </option>{" "}
                      </select>
                      <span style={{ color: "red" }}>
                        {formErrorp.occupation}
                      </span>
                      <div className="btn-group-pre" >
                    
                      </div>
                    </div>
                    <div
                      className="col-lg-8-pre col-md-5-pre col-sm-6-pre form-group-pre"
                    >
                      <label className="labp" style={{color:"#ec167f",marginLeft:"3%"}}>Partner Expectations</label>
                      <textarea
                        type="text"
                        name="partner_expectations"
                        onChange={inputChangeHandler}
                        value={datap.partner_expectations}
                        className="agile-ltext-pre"
                        placeholder="Enter Your Partener Expectation"
                        maxLength={600}
                        defaultvalue=""
                        tabIndex={25}
                        defaultValue={""}
                        style={{width:"35vh",marginRight:"20%"}}
                      />
                      {/* <div
                        className="col-lg-12-pre col-md-12-pre col-sm-6-pre "
                      >
                        <span style={{ color: "red" }}>
                          {formErrorp.partner_expectations}
                        </span>
                        <span style={{ fontSize: 15 }} className="abtp" >
                          {" "}
                          Enter few lines about Your Partner Expectation
                          <a
                            href="#"
                            data-toggle="modal"
                            className="ml-1"
                            data-target="#myModal5"
                            data-id={1}
                            style={{ color: "red" }}
                          >
                            {" "}
                            Examples{" "}
                          </a>
                        </span>
                      </div> */}
                    </div>
                    <div
                      className="col-lg-10-pre col-md-10-pre col-sm-6-pre"
                      style={{ marginTop: "50px" }}
                    >
                      <br></br>
                      <center style={{ paddingLeft: "20%" }}>
                      {!showFormp && (
                          <div>
                        <button
                          className="theme-btn-pre btn-pre btn-style-one-pre mt-4-pre"
                          type="submit"
                          name="submit"
                          style={{width:'50%',marginRight:"30%"}}
                          onClick={showDatap}
                        >
                          <span className="btn-title-pre" tabIndex={26}>
                            Submit
                          </span>
                        </button>
                        </div>
                            )}

                            {/* {showFormp && (
                            <div>
                              <input
                                type="text"
                                name="id"
                                value={datap.id}
                                onChange={inputChangeHandler}
                                placeholder="Enter ID"
                              />

                            <button
                                className="theme-btn-pre btn-pre btn-style-one-pre mt-4-pre"
                                fdprocessedid="jqtd0p"
                                onClick={handleEditp}
                                style={{ marginLeft: '-30px' }}
                              >
                                <span className="btn-title-pre" tabIndex={17}>
                                  Get Data
                                </span>
                              </button>

                            
                              <button
                                className="theme-btn-pre btn-pre btn-style-one-pre mt-4-pre"
                                fdprocessedid="jqtd0p"
                                onClick={handlePutSubmitp}
                                style={{ marginLeft: '-30px' }}
                              >
                                <span className="btn-title-pre" tabIndex={17}>
                                  Update Data
                                </span>
                              </button>

                            </div>
                          )} */}

                      </center>
                    </div>
                  </div>
                  <ToastContainer style={{ marginTop: "100px" }} />
                      {errorp ? (
                        <div
                          className="theme-text -style-one-pre mt-4-pre"
                          style={{ color: "red" }}
                        >
                          <h4>{errorp}</h4>
                        </div>
                      ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-dismiss="modal" style={{marginRight:"40%",marginTop:"2%"}} 
          onClick={() => setShowModalpat(false)}>Close</button>
          
        </div>
      </div>
    </div>
  </div>
       )
      }
    </div>

    <div
      className="feature-block-twopro col-lg-4pro col-md-6pro col-sm-6pro wow fadeInUp animated"
      style={{ visibility: "visible", animationName: "fadeInUp" }}
    >
      {databasic.length > 0 && (
      <div className="inner-boxpro">
        <div className="icon-boxpro">
          <img
            src="https://www.matrimonysoftware.in/icon/basic.png"
            alt=""
          />
        </div>
        <h4>
          <a href="horoscope">
            Basic Details{" "}
            <i style={{ fontSize: 24 }} className="fa">
              
            </i>
          </a>{" "}
          <BsFillPencilFill className="editpro" onClick={() => setShowModalbasic(true)}/>
        </h4>
        <div className="textpro"><span>Name:</span> {databasic[databasic.length - 1].Name}</div>
        <div className="textpro"><span>Surname:</span> {databasic[databasic.length - 1].Surname}</div>
        <div className="textpro"><span>Email:</span> {databasic[databasic.length - 1].Email}</div>
        <div className="textpro"><span>Mobile_Number:</span> {databasic[databasic.length - 1].Mobile_Number}</div>
        <div className="textpro"><span>Date of Birth:</span> {databasic[databasic.length - 1].D_O_B}</div>
        <div className="textpro"><span>Age:</span> {databasic[databasic.length - 1].Age}</div>
       <div className="textpro"><span>Height:</span> {databasic[databasic.length - 1].Height}</div>
        <div className="textpro"><span>Blood_Group:</span> {databasic[databasic.length - 1].Blood_Group}</div>
        <div className="textpro"><span>Gender:</span> {databasic[databasic.length - 1].Gender}</div>
        <div className="textpro"><span>Religion:</span> {databasic[databasic.length - 1].religion}</div>
        <div className="textpro"><span>your_religion:</span> {databasic[databasic.length - 1].your_religion}</div>
       <div className="textpro"><span>profile_created_By:</span> {databasic[databasic.length - 1].profile_created_By}</div>
       <div className="textpro"><span>Marital_status:</span> {databasic[databasic.length - 1].marital_status}</div>
       <div className="textpro"><span>your_caste:</span> {databasic[databasic.length - 1].your_caste}</div>
       <div className="textpro"><span>Sub_caste:</span> {databasic[databasic.length - 1].sub_caste}</div>
       <div className="textpro"><span>About_yourself:</span> {databasic[databasic.length - 1].about_yourself}</div>
      </div>
      )}
      {showModalbasic && (
        <div className="modal fade show" id="exampleModalLong" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle" style={{marginLeft:"30%",color:"#ec167f"}}>Basic Details</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowModalbasic(false)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">

              <section className="newsletter-section contact-page-section">
            <div className="auto-container">
              <div className="alertmesb"></div>
              <div className="row clearfix mt-3">
                <div className="form-column col-lg-9 col-md-12 col-sm-12">
                  <div className="envelope-imageb" />
                  <div className="form-innerb">
                    <div className="contact-formb ">

                      <div className="row clearfix  mt-2 " style={{display:"flex",flexDirection:"column"}}>

                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Name </label>
                          <input
                            type="text"
                            className="agile-ltextb"
                            placeholder="Enter your Name"
                            name="Name"
                            value={datab.Name}
                            onChange={ChangeHandler}
                            readOnly=""
                            // fdprocessedid="j85x88"
                          />
                          <span style={{ color: "red" }}>{formError.Name}</span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Surname </label>
                          <input
                            type="text"
                            className="agile-ltextb"
                            placeholder="Enter your Name"
                            name="Surname"
                            value={datab.Surname}
                            onChange={ChangeHandler}
                            readOnly=""
                            // fdprocessedid="j85x88"
                          />
                          <span style={{ color: "red" }}>
                            {formError.Surname}
                          </span>
                        </div>

                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Email </label>
                          <input
                            type="text"
                            placeholder="Email"
                            className="agile-ltextb"
                            name="Email"
                            value={datab.Email}
                            onChange={ChangeHandler}
                          />
                          <span style={{ color: "red" }}>
                            {formError.Email}
                          </span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Mobile Number </label>
                          <input
                            type="text"
                            name="Mobile_Number"
                            placeholder="Mobile Number"
                            className="agile-ltextb"
                            maxLength={14}
                            onChange={ChangeHandler}
                            value={datab.Mobile_Number}
                            readOnly=""
                            // fdprocessedid="hkxiz"
                          />
                          <span style={{ color: "red" }}>
                            {formError.Mobile_Number}
                          </span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb">Date Of Birth </label>
                          <input
                            type="datetime-local"
                            className="agile-ltextb"
                            placeholder="Date of birth"
                            name="D_O_B"
                            value={datab.D_O_B}
                            onChange={ChangeHandler}
                            readOnly=""
                            fdprocessedid="tgf31"
                          />
                          <span style={{ color: "red" }}>
                            {formError.D_O_B}
                          </span>
                        </div>

                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Age </label>

                          <select
                            type="text"
                            className="agile-ltextb"
                            placeholder="Enter your age"
                            name="Age"
                            onChange={ChangeHandler}
                            value={datab.Age}
                          >
                            <option value="" selected="">
                              Select your Age
                            </option>
                            {ageOptions(datab.Gender).map((age)=>(
                              <option key={age} value={age}>{age}</option>
                     
                            ))}
                           
                          </select>
                          <span style={{ color: "red" }}>{formError.Age}</span>
                        </div>

                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Height </label>
                          <select
                            type="text"
                            className="agile-ltextb"
                            placeholder="Select Height"
                            name="Height"
                            onChange={ChangeHandler}
                            value={datab.Height}
                            readOnly=""
                            fdprocessedid="jdvgst"
                          >
                            <option value="">Select Height</option>
                            <option value={1}>4Ft </option>
                            <option value={2}>4Ft 1 inch </option>
                            <option value={3}>4Ft 2 inch </option>
                            <option value={4}>4Ft 3 inch </option>
                            <option value={5}>4Ft 4 inch </option>
                            <option value={6}>4Ft 5 inch </option>
                            <option value={7}>4Ft 6 inch </option>
                            <option value={8}>4Ft 7 inch </option>
                            <option value={9}>4Ft 8 inch </option>
                            <option value={10}>4Ft 9 inch </option>
                            <option value={11}>4Ft 10 inch </option>
                            <option value={12}>4Ft 11 inch </option>
                            <option value={13}>5Ft </option>
                            <option value={14}>5Ft 1 inch </option>
                            <option value={15}>5Ft 2 inch </option>
                            <option value={16}>5Ft 3 inch </option>
                            <option value={17}>5Ft 4 inch </option>
                            <option value={18}>5Ft 5 inch </option>
                            <option value={19}>5Ft 6 inch </option>
                            <option value={20}>5Ft 7 inch </option>
                            <option value={21}>5Ft 8 inch </option>
                            <option value={22}>5Ft 9 inch </option>
                            <option value={23}>5Ft 10 inch </option>
                            <option value={24}>5Ft 11 inch </option>
                            <option value={25}>6Ft </option>
                            <option value={26}>6Ft 1 inch </option>
                            <option value={27}>6Ft 2 inch </option>
                            <option value={28}>6Ft 3 inch </option>
                            <option value={29}>6Ft 4 inch </option>
                            <option value={30}>6Ft 5 inch </option>
                            <option value={31}>6Ft 6 inch </option>
                            <option value={32}>6Ft 7 inch </option>
                            <option value={33}>6Ft 8 inch </option>
                            <option value={34}>6Ft 9 inch </option>
                            <option value={35}>6Ft 10 inch </option>
                            <option value={36}>6Ft 11 inch </option>
                            <option value={37}>7Ft </option>
                          </select>
                          <span style={{ color: "red" }}>
                            {formError.Height}
                          </span>
                        </div>

                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Blood_Group </label>
                          <select
                            type="text"
                            className="agile-ltextb"
                            placeholder="Blood_Group "
                            name="Blood_Group"
                            value={datab.Blood_Group}
                            onChange={ChangeHandler}
                            readOnly=""
                            fdprocessedid="bwswso"
                          >
                            <option value="" selected="">
                              Select Your Blood_Group
                            </option>
                            <option value="Male">A+</option>
                            <option value="Female">A-</option>
                            <option value="Others">B+</option>
                            <option value="Male">B-</option>
                            <option value="Female">AB+</option>
                            <option value="Others">AB-</option>
                            <option value="Male">O+</option>
                            <option value="Female">O-</option>
                            <option value="Others">I DONT KNOW</option>
                          </select>
                          <span style={{ color: "red" }}>
                            {formError.Gender}
                          </span>
                        </div>

                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Gender </label>
                          <select
                            type="text"
                            className="agile-ltextb"
                            placeholder="Gender "
                            name="Gender"
                            value={datab.Gender}
                            onChange={ChangeHandler}
                            readOnly=""
                            fdprocessedid="bwswso"
                          >
                            <option value="" selected="">
                              Select Your Gender
                            </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Others">Others</option>
                          </select>
                          <span style={{ color: "red" }}>
                            {formError.Gender}
                          </span>
                        </div>

                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb">Religion </label>
                          <select
                            type="text"
                            className="agile-ltextb"
                            placeholder="Religion "
                            name="religion"
                            value={datab.religion}
                            onChange={ChangeHandler}
                            readOnly=""
                            fdprocessedid="0eqdt9"
                          >
                            <option value="" selected="">
                              Select Religion
                            </option>
                            <option value="Christian">Any</option>
                            <option value="Christian">Christian</option>
                            <option value="Hindu">Hindu</option>
                            <option value="hindu Barahsaini">
                              hindu Barahsaini
                            </option>
                            <option value="Inter-Religion">
                              Inter-Religion
                            </option>
                            <option value="Jain">Jain</option>
                            <option value="Muslim">Muslim</option>
                            <option value="Sikh">Sikh</option>
                          </select>
                          <span style={{ color: "red" }}>
                            {formError.religion}
                          </span>
                        </div>

                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb">Your Religion </label>
                          <select
                            type="text"
                            className="agile-ltextb"
                            placeholder="Religion "
                            name="your_religion"
                            value={datab.your_religion}
                            onChange={ChangeHandler}
                            readOnly=""
                            fdprocessedid="0eqdt9"
                          >
                            <option value="" selected="">
                              Select Religion
                            </option>
                            <option value="Christian">Any</option>
                            <option value="Christian">Christian</option>
                            <option value="Hindu">Hindu</option>
                            <option value="hindu Barahsaini">
                              hindu Barahsaini
                            </option>
                            <option value="Inter-Religion">
                              Inter-Religion
                            </option>
                            <option value="Jain">Jain</option>
                            <option value="Muslim">Muslim</option>
                            <option value="Sikh">Sikh</option>
                          </select>
                          <span style={{ color: "red" }}>
                            {formError.your_religion}
                          </span>
                        </div>

                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb">Profile Created By </label>

                          <select
                            type="text"
                            className="agile-ltextb"
                            placeholder=" Profile Created By"
                            name="profile_created_By"
                            value={datab.profile_created_By}
                            onChange={ChangeHandler}
                            readOnly=""
                            fdprocessedid="tgf31"
                          >
                            <option value="">Profile Created by</option>
                            <option value="Self">Self</option>
                            <option value="Father">Father</option>
                            <option value="Mother">Mother</option>
                            <option value="Brother">Brother</option>
                            <option value="Sister">Sister</option>
                            <option value="Friend">Friend</option>
                            <option value="Son">Son</option>
                            <option value="Daughter">Daughter</option>
                            <option value="Others">Others</option>
                          </select>
                          <span style={{ color: "red" }}>
                            {formError.profile_created_By}
                          </span>
                        </div>

                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Marital_Status </label>

                          <select
                            type="text"
                            className="agile-ltextb"
                            placeholder="Enter your Name"
                            name="marital_status"
                            value={datab.marital_status}
                            onChange={ChangeHandler}
                            readOnly=""
                            fdprocessedid="j85x88"
                          >
                            <option value="">Marital Status</option>
                            <option value="Unmarried">Unmarried</option>
                            <option value="Divorced">Divorced</option>
                            <option value="Widower">Widower</option>
                            <option value="Widowed">Widowed</option>
                            <option value="Awaiting Divorce">
                              Awaiting Divorce
                            </option>
                            <option value="Seperated">Seperated</option>
                          </select>
                          <span style={{ color: "red" }}>
                            {formError.marital_status}
                          </span>
                        </div>

                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Caste </label>
                          <select
                            type="text"
                            className="agile-ltextb"
                            placeholder="your_caste"
                            name="your_caste"
                            value={datab.your_caste}
                            onChange={ChangeHandler}
                          >
                            {" "}
                            <option value="">Select your cast </option>
                            <option value="96K Kokanastha">
                              96K Kokanastha
                            </option>
                            <option value="Ad Dharmi">Ad Dharmi</option>
                            <option value="Adi Andhra">Adi Andhra</option>
                            <option value="Adi Dravida">Adi Dravida</option>
                            <option value="Adi Karnataka">Adi Karnataka</option>
                            <option value="Agarwal">Agarwal</option>
                            <option value="Agnikula Kshatriya">
                              Agnikula Kshatriya
                            </option>
                            <option value="Agri">Agri</option>
                            <option value="Ahom">Ahom</option>
                            <option value="Ambalavasi">Ambalavasi</option>
                            <option value="Amil Sindhi">Amil Sindhi</option>
                            <option value="Anglo Indian">Anglo Indian</option>
                            <option value="Araya">Araya</option>
                            <option value="Arekatica">Arekatica</option>
                            <option value="Arora">Arora</option>
                            <option value="Arunthathiyar">Arunthathiyar</option>
                            <option value="Arya Vysya">Arya Vysya</option>
                            <option value="Aryasamaj">Aryasamaj</option>
                            <option value="Ayyaraka">Ayyaraka</option>
                            <option value="Badaga">Badaga</option>
                            <option value="Bahi">Bahi</option>
                            <option value="Baibhand Sindhi">
                              Baibhand Sindhi
                            </option>
                            <option value="Baidya">Baidya</option>
                            <option value="Baishnab">Baishnab</option>
                            <option value="Baishya">Baishya</option>
                            <option value="Balija">Balija</option>
                            <option value="Banik">Banik</option>
                            <option value="Baniya">Baniya</option>
                            <option value="Banjara">Banjara</option>
                            <option value="Barai">Barai</option>
                            <option value="Bari">Bari</option>
                            <option value="Barujibi">Barujibi</option>
                            <option value="Bengali">Bengali</option>
                            <option value="Besta">Besta</option>
                            <option value="Bhandari">Bhandari</option>
                            <option value="Bhanusali Sindhi">
                              Bhanusali Sindhi
                            </option>
                            <option value="Bhatia">Bhatia</option>
                            <option value="Bhatia Sindhi">Bhatia Sindhi</option>
                            <option value="Bhatraju">Bhatraju</option>
                            <option value="Bhavasar Kshatriya">
                              Bhavasar Kshatriya
                            </option>
                            <option value="Bhavsar">Bhavsar</option>
                            <option value="Bhovi">Bhovi</option>
                            <option value="Bhumihar Brahmin">
                              Bhumihar Brahmin
                            </option>
                            <option value="Billava">Billava</option>
                            <option value="Bishnoi/Vishnoi">
                              Bishnoi/Vishnoi
                            </option>
                            <option value="Boyer">Boyer</option>
                            <option value="Brahmbatt">Brahmbatt</option>
                            <option value="Bunt">Bunt</option>
                            <option value="Bunt (Shetty)">Bunt (Shetty)</option>
                            <option value="Chambhar">Chambhar</option>
                            <option value="Chandraseniya Kayastha Prab">
                              Chandraseniya Kayastha Prab
                            </option>
                            <option value="Chandravanshi Kahar">
                              Chandravanshi Kahar
                            </option>
                            <option value="Chasa">Chasa</option>
                            <option value="Chattada Sri Vaishnava">
                              Chattada Sri Vaishnava
                            </option>
                            <option value="Chaudary">Chaudary</option>
                            <option value="Chaurasia">Chaurasia</option>
                            <option value="Chettiar">Chettiar</option>
                            <option value="Chhapru Sindhi">
                              Chhapru Sindhi
                            </option>
                            <option value="Chhetri">Chhetri</option>
                            <option value="Chippolu (Mera)">
                              Chippolu (Mera)
                            </option>
                            <option value="CKP">CKP</option>
                            <option value="Coorgi">Coorgi</option>
                            <option value="Dadu Sindhi">Dadu Sindhi</option>
                            <option value="Darji">Darji</option>
                            <option value="Dawoodi Bhora">Dawoodi Bhora</option>
                            <option value="Devadiga">Devadiga</option>
                            <option value="Devandra Kula Vellalar">
                              Devandra Kula Vellalar
                            </option>
                            <option value="Devang Koshthi">
                              Devang Koshthi
                            </option>
                            <option value="Devanga">Devanga</option>
                            <option value="Devar/Thevar/Mukkulathor">
                              Devar/Thevar/Mukkulathor
                            </option>
                            <option value="Devendra Kula Vellalar">
                              Devendra Kula Vellalar
                            </option>
                            <option value="Dhaneshawat Vaish">
                              Dhaneshawat Vaish
                            </option>
                            <option value="Dhangar">Dhangar</option>
                            <option value="Dheevara">Dheevara</option>
                            <option value="Dhiman">Dhiman</option>
                            <option value="Dhoba">Dhoba</option>
                            <option value="Dhobi">Dhobi</option>
                            <option value="Dumal">Dumal</option>
                            <option value="Dusadh (Paswan)">
                              Dusadh (Paswan)
                            </option>
                            <option value="Ediga">Ediga</option>
                            <option value="Ezhava">Ezhava</option>
                            <option value="Ezhuthachan">Ezhuthachan</option>
                            <option value="Gabit">Gabit</option>
                            <option value="Ganda">Ganda</option>
                            <option value="Gandla">Gandla</option>
                            <option value="Ganiga">Ganiga</option>
                            <option value="Ganigashetty">Ganigashetty</option>
                            <option value="Garhwali">Garhwali</option>
                            <option value="Garhwali Rajput">
                              Garhwali Rajput
                            </option>
                            <option value="Gavali">Gavali</option>
                            <option value="Gavara">Gavara</option>
                            <option value="Gawali">Gawali</option>
                            <option value="Ghisadi">Ghisadi</option>
                            <option value="Ghumar">Ghumar</option>
                            <option value="Goala">Goala</option>
                            <option value="Goan">Goan</option>
                            <option value="Gomantak">Gomantak</option>
                            <option value="Gomantak Maratha">
                              Gomantak Maratha
                            </option>
                            <option value="Gondhali">Gondhali</option>
                            <option value="Goswami">Goswami</option>
                            <option value="Goswami/Gosavi Brahmin">
                              Goswami/Gosavi Brahmin
                            </option>
                            <option value="Goud">Goud</option>
                            <option value="Gounder">Gounder</option>
                            <option value="Gowda">Gowda</option>
                            <option value="Gramani">Gramani</option>
                            <option value="Gudia">Gudia</option>
                            <option value="Gujarati">Gujarati</option>
                            <option value="Gujjar">Gujjar</option>
                            <option value="Gupta">Gupta</option>
                            <option value="Guptan">Guptan</option>
                            <option value="Gurav">Gurav</option>
                            <option value="Gurjar">Gurjar</option>
                            <option value="Hegde">Hegde</option>
                            <option value="Himachali">Himachali</option>
                            <option value="Hindu-Others">Hindu-Others</option>
                            <option value="Hugar (Jeer)">Hugar (Jeer)</option>
                            <option value="Hyderabadi Sindhi">
                              Hyderabadi Sindhi
                            </option>
                            <option value="Intercaste">Intercaste</option>
                            <option value="Irani">Irani</option>
                            <option value="Iyengar">Iyengar</option>
                            <option value="Iyer">Iyer</option>
                            <option value="Jaalari">Jaalari</option>
                            <option value="Jaiswal">Jaiswal</option>
                            <option value="Jandra">Jandra</option>
                            <option value="Jangam">Jangam</option>
                            <option value="Jat">Jat</option>
                            <option value="Jatav">Jatav</option>
                            <option value="Jeer">Jeer</option>
                            <option value="Jogi (Nath)">Jogi (Nath)</option>
                            <option value="Kachara">Kachara</option>
                            <option value="Kadava Patel">Kadava Patel</option>
                            <option value="Kahar">Kahar</option>
                            <option value="Kaibarta">Kaibarta</option>
                            <option value="Kalal">Kalal</option>
                            <option value="Kalar">Kalar</option>
                            <option value="Kalinga">Kalinga</option>
                            <option value="Kalinga Vysya">Kalinga Vysya</option>
                            <option value="Kalita">Kalita</option>
                            <option value="Kalwar">Kalwar</option>
                            <option value="Kamboj">Kamboj</option>
                            <option value="Kamma">Kamma</option>
                            <option value="Kannada Mogaveera">
                              Kannada Mogaveera
                            </option>
                            <option value="Kansari">Kansari</option>
                            <option value="Kapu">Kapu</option>
                            <option value="Kapu Naidu">Kapu Naidu</option>
                            <option value="Karana">Karana</option>
                            <option value="Karmakar">Karmakar</option>
                            <option value="Karuneegar">Karuneegar</option>
                            <option value="Karuneekar">Karuneekar</option>
                            <option value="Kasar">Kasar</option>
                            <option value="Kashyap">Kashyap</option>
                            <option value="Katiya">Katiya</option>
                            <option value="Kayastha">Kayastha</option>
                            <option value="Keralite">Keralite</option>
                            <option value="Khandayat">Khandayat</option>
                            <option value="Khandelwal">Khandelwal</option>
                            <option value="Kharwar">Kharwar</option>
                            <option value="Khatik">Khatik</option>
                            <option value="Khatri">Khatri</option>
                            <option value="Khukhrain">Khukhrain</option>
                            <option value="Koiri">Koiri</option>
                            <option value="Kokanastha Maratha">
                              Kokanastha Maratha
                            </option>
                            <option value="Koli">Koli</option>
                            <option value="Koli Mahadev">Koli Mahadev</option>
                            <option value="Kongu Vellala Gounder">
                              Kongu Vellala Gounder
                            </option>
                            <option value="Konkani">Konkani</option>
                            <option value="Kori">Kori</option>
                            <option value="Koshti">Koshti</option>
                            <option value="Kshatriya">Kshatriya</option>
                            <option value="Kudumbi">Kudumbi</option>
                            <option value="Kulal">Kulal</option>
                            <option value="Kulalar">Kulalar</option>
                            <option value="Kulita">Kulita</option>
                            <option value="Kumaoni">Kumaoni</option>
                            <option value="Kumaoni Rajput">
                              Kumaoni Rajput
                            </option>
                            <option value="Kumawat">Kumawat</option>
                            <option value="Kumbara">Kumbara</option>
                            <option value="Kumbhakar">Kumbhakar</option>
                            <option value="Kumbhar">Kumbhar</option>
                            <option value="Kumhar">Kumhar</option>
                            <option value="Kummari">Kummari</option>
                            <option value="Kunbi">Kunbi</option>
                            <option value="Kuravan">Kuravan</option>
                            <option value="Kurmi">Kurmi</option>
                            <option value="Kurmi Kshatriya">
                              Kurmi Kshatriya
                            </option>
                            <option value="Kuruba">Kuruba</option>
                            <option value="Kuruhina Shetty">
                              Kuruhina Shetty
                            </option>
                            <option value="Kurumbar">Kurumbar</option>
                            <option value="Kushwaha">Kushwaha</option>
                            <option value="Kushwaha (Koiri)">
                              Kushwaha (Koiri)
                            </option>
                            <option value="Kutchi">Kutchi</option>
                            <option value="Lambadi">Lambadi</option>
                            <option value="Lambani">Lambani</option>
                            <option value="Larai Sindhi">Larai Sindhi</option>
                            <option value="Larkana Sindhi">
                              Larkana Sindhi
                            </option>
                            <option value="Leva patel">Leva patel</option>
                            <option value="Leva Patidar">Leva Patidar</option>
                            <option value="Leva patil">Leva patil</option>
                            <option value="Lingayath">Lingayath</option>
                            <option value="Lodhi Rajput">Lodhi Rajput</option>
                            <option value="Lohana">Lohana</option>
                            <option value="Lohana Sindhi">Lohana Sindhi</option>
                            <option value="Lohar">Lohar</option>
                            <option value="Lubana">Lubana</option>
                            <option value="Madiga">Madiga</option>
                            <option value="Mahajan">Mahajan</option>
                            <option value="Mahar">Mahar</option>
                            <option value="Maharashtrian">Maharashtrian</option>
                            <option value="Mahendra">Mahendra</option>
                            <option value="Maheshwari">Maheshwari</option>
                            <option value="Mahishya">Mahishya</option>
                            <option value="Mahisya">Mahisya</option>
                            <option value="Majabi">Majabi</option>
                            <option value="Mala">Mala</option>
                            <option value="Malayalee">Malayalee</option>
                            <option value="Malayalee Namboodiri">
                              Malayalee Namboodiri
                            </option>
                            <option value="Mali">Mali</option>
                            <option value="Mallah">Mallah</option>
                            <option value="Mangalorean">Mangalorean</option>
                            <option value="Manipuri">Manipuri</option>
                            <option value="Mannuru Kapu">Mannuru Kapu</option>
                            <option value="Mapila">Mapila</option>
                            <option value="Maratha">Maratha</option>
                            <option value="Maruthuvar">Maruthuvar</option>
                            <option value="Marvar">Marvar</option>
                            <option value="Marwari">Marwari</option>
                            <option value="Matang">Matang</option>
                            <option value="Mathur">Mathur</option>
                            <option value="Maurya">Maurya</option>
                            <option value="Meena">Meena</option>
                            <option value="Meenavar">Meenavar</option>
                            <option value="Mehra">Mehra</option>
                            <option value="Menon">Menon</option>
                            <option value="Mera">Mera</option>
                            <option value="Meru">Meru</option>
                            <option value="Meru Darji">Meru Darji</option>
                            <option value="Mochi">Mochi</option>
                            <option value="Modak">Modak</option>
                            <option value="Mogaveera">Mogaveera</option>
                            <option value="Monchi">Monchi</option>
                            <option value="Mudaliar">Mudaliar</option>
                            <option value="Mudaliar - Senguntha">
                              Mudaliar - Senguntha
                            </option>
                            <option value="Mudaliar Arcot">
                              Mudaliar Arcot
                            </option>
                            <option value="Mudaliar Saiva">
                              Mudaliar Saiva
                            </option>
                            <option value="Mudaliyar">Mudaliyar</option>
                            <option value="Mudiraj">Mudiraj</option>
                            <option value="Mukkulathor">Mukkulathor</option>
                            <option value="Mukulathur">Mukulathur</option>
                            <option value="Munnuru Kapu">Munnuru Kapu</option>
                            <option value="Muthuraja">Muthuraja</option>
                            <option value="Naagavamsam">Naagavamsam</option>
                            <option value="Nadar">Nadar</option>
                            <option value="Nagaralu">Nagaralu</option>
                            <option value="Nai">Nai</option>
                            <option value="Naicker">Naicker</option>
                            <option value="Naidu">Naidu</option>
                            <option value="Naik">Naik</option>
                            <option value="Naik/Nayaka">Naik/Nayaka</option>
                            <option value="Naika">Naika</option>
                            <option value="Nair">Nair</option>
                            <option value="Nair Vaniya">Nair Vaniya</option>
                            <option value="Nair Vilakkithala">
                              Nair Vilakkithala
                            </option>
                            <option value="Namasudra">Namasudra</option>
                            <option value="Nambiar">Nambiar</option>
                            <option value="Namboodiri">Namboodiri</option>
                            <option value="Namosudra">Namosudra</option>
                            <option value="Napit">Napit</option>
                            <option value="Nath">Nath</option>
                            <option value="Nayaka">Nayaka</option>
                            <option value="Neeli">Neeli</option>
                            <option value="Nepali">Nepali</option>
                            <option value="Nhavi">Nhavi</option>
                            <option value="OBC/Barber/Naayee">
                              OBC/Barber/Naayee
                            </option>
                            <option value="Oriya">Oriya</option>
                            <option value="Oswal">Oswal</option>
                            <option value="Otari">Otari</option>
                            <option value="Padmasali">Padmasali</option>
                            <option value="Padmashali">Padmashali</option>
                            <option value="Pal">Pal</option>
                            <option value="Panchal">Panchal</option>
                            <option value="Pandaram">Pandaram</option>
                            <option value="Panicker">Panicker</option>
                            <option value="Parkava Kulam">Parkava Kulam</option>
                            <option value="Parsi">Parsi</option>
                            <option value="Partraj">Partraj</option>
                            <option value="Pasi">Pasi</option>
                            <option value="Paswan">Paswan</option>
                            <option value="Patel">Patel</option>
                            <option value="Patel Desai">Patel Desai</option>
                            <option value="Patel Dodia">Patel Dodia</option>
                            <option value="Patel Kadva">Patel Kadva</option>
                            <option value="Patel Leva">Patel Leva</option>
                            <option value="Pathare Prabhu">
                              Pathare Prabhu
                            </option>
                            <option value="Patil">Patil</option>
                            <option value="Patil Leva">Patil Leva</option>
                            <option value="Patnaick">Patnaick</option>
                            <option value="Patnaick/Sistakaranam">
                              Patnaick/Sistakaranam
                            </option>
                            <option value="Patra">Patra</option>
                            <option value="Perika">Perika</option>
                            <option value="Pillai">Pillai</option>
                            <option value="Poosala">Poosala</option>
                            <option value="Porwal">Porwal</option>
                            <option value="Prajapati">Prajapati</option>
                            <option value="Punjabi">Punjabi</option>
                            <option value="Raigar">Raigar</option>
                            <option value="Rajaka">Rajaka</option>
                            <option value="Rajastani">Rajastani</option>
                            <option value="Rajbhar">Rajbhar</option>
                            <option value="Rajbonshi">Rajbonshi</option>
                            <option value="Rajput">Rajput</option>
                            <option value="Rajput Rohella/Tank">
                              Rajput Rohella/Tank
                            </option>
                            <option value="Ramdasia">Ramdasia</option>
                            <option value="Ramgariah">Ramgariah</option>
                            <option value="Ravidasia">Ravidasia</option>
                            <option value="Rawat">Rawat</option>
                            <option value="Reddy">Reddy</option>
                            <option value="Relli">Relli</option>
                            <option value="Rohiri Sindhi">Rohiri Sindhi</option>
                            <option value="Sadgope">Sadgope</option>
                            <option value="Saha">Saha</option>
                            <option value="Sahiti Sindhi">Sahiti Sindhi</option>
                            <option value="Sahu">Sahu</option>
                            <option value="Saini">Saini</option>
                            <option value="Sakkhar Sindhi">
                              Sakkhar Sindhi
                            </option>
                            <option value="Saliya">Saliya</option>
                            <option value="Saurashtra">Saurashtra</option>
                            <option value="Savji">Savji</option>
                            <option value="Scheduled Caste">
                              Scheduled Caste
                            </option>
                            <option value="Scheduled Tribe">
                              Scheduled Tribe
                            </option>
                            <option value="Sehwani Sindhi">
                              Sehwani Sindhi
                            </option>
                            <option value="Senai Thalaivar">
                              Senai Thalaivar
                            </option>
                            <option value="Senguntha Mudaliyar">
                              Senguntha Mudaliyar
                            </option>
                            <option value="Sepahia">Sepahia</option>
                            <option value="Setti Balija">Setti Balija</option>
                            <option value="Settibalija">Settibalija</option>
                            <option value="Shah">Shah</option>
                            <option value="Shetty">Shetty</option>
                            <option value="Shikarpuri Sindhi">
                              Shikarpuri Sindhi
                            </option>
                            <option value="Shimpi">Shimpi</option>
                            <option value="Sindhi">Sindhi</option>
                            <option value="Sindhi-Amil">Sindhi-Amil</option>
                            <option value="Sindhi-Baibhand">
                              Sindhi-Baibhand
                            </option>
                            <option value="Sindhi-Bhanusali">
                              Sindhi-Bhanusali
                            </option>
                            <option value="Sindhi-Bhatia">Sindhi-Bhatia</option>
                            <option value="Sindhi-Chhapru">
                              Sindhi-Chhapru
                            </option>
                            <option value="Sindhi-Dadu">Sindhi-Dadu</option>
                            <option value="Sindhi-Hyderabadi">
                              Sindhi-Hyderabadi
                            </option>
                            <option value="Sindhi-Larai">Sindhi-Larai</option>
                            <option value="Sindhi-Larkana">
                              Sindhi-Larkana
                            </option>
                            <option value="Sindhi-Lohana">Sindhi-Lohana</option>
                            <option value="Sindhi-Rohiri">Sindhi-Rohiri</option>
                            <option value="Sindhi-Sahiti">Sindhi-Sahiti</option>
                            <option value="Sindhi-Sakkhar">
                              Sindhi-Sakkhar
                            </option>
                            <option value="Sindhi-Sehwani">
                              Sindhi-Sehwani
                            </option>
                            <option value="Sindhi-Shikarpuri">
                              Sindhi-Shikarpuri
                            </option>
                            <option value="Sindhi-Thatai">Sindhi-Thatai</option>
                            <option value="SKP">SKP</option>
                            <option value="Somvanshi">Somvanshi</option>
                            <option value="Sonam">Sonam</option>
                            <option value="Sonar">Sonar</option>
                            <option value="Soni">Soni</option>
                            <option value="Sourashtra">Sourashtra</option>
                            <option value="Sozhiya Vellalar">
                              Sozhiya Vellalar
                            </option>
                            <option value="Srisayana">Srisayana</option>
                            <option value="Srisayani">Srisayani</option>
                            <option value="Sugali (Naika)">
                              Sugali (Naika)
                            </option>
                            <option value="Sunari">Sunari</option>
                            <option value="Sundhi">Sundhi</option>
                            <option value="Sutar">Sutar</option>
                            <option value="Suthar">Suthar</option>
                            <option value="Swakula Sali">Swakula Sali</option>
                            <option value="Swarnakar">Swarnakar</option>
                            <option value="Tamboli">Tamboli</option>
                            <option value="Tamil">Tamil</option>
                            <option value="Tamil Yadava">Tamil Yadava</option>
                            <option value="Tanti">Tanti</option>
                            <option value="Tantubai">Tantubai</option>
                            <option value="Tantuway">Tantuway</option>
                            <option value="Telaga">Telaga</option>
                            <option value="Teli">Teli</option>
                            <option value="Telugu">Telugu</option>
                            <option value="Thakkar">Thakkar</option>
                            <option value="Thakore">Thakore</option>
                            <option value="Thakur">Thakur</option>
                            <option value="Thatai Sindhi">Thatai Sindhi</option>
                            <option value="Thigala">Thigala</option>
                            <option value="Thiyya">Thiyya</option>
                            <option value="Tili">Tili</option>
                            <option value="Togata">Togata</option>
                            <option value="Tonk Kshatriya">
                              Tonk Kshatriya
                            </option>
                            <option value="Turupu Kapu">Turupu Kapu</option>
                            <option value="Udayar">Udayar</option>
                            <option value="Uppara">Uppara</option>
                            <option value="Urali Gounder">Urali Gounder</option>
                            <option value="Urs">Urs</option>
                            <option value="Vada Balija">Vada Balija</option>
                            <option value="Vadagalai">Vadagalai</option>
                            <option value="Vaddera">Vaddera</option>
                            <option value="Vaish">Vaish</option>
                            <option value="Vaishnav">Vaishnav</option>
                            <option value="Vaishnav Bhatia">
                              Vaishnav Bhatia
                            </option>
                            <option value="Vaishnav Vania">
                              Vaishnav Vania
                            </option>
                            <option value="Vaishnav Vanik">
                              Vaishnav Vanik
                            </option>
                            <option value="Vaishnava">Vaishnava</option>
                            <option value="Vaishya">Vaishya</option>
                            <option value="Vaishya Vani">Vaishya Vani</option>
                            <option value="Valluvan">Valluvan</option>
                            <option value="Valmiki">Valmiki</option>
                            <option value="Vania">Vania</option>
                            <option value="Vaniya">Vaniya</option>
                            <option value="Vanjara">Vanjara</option>
                            <option value="Vanjari">Vanjari</option>
                            <option value="Vankar">Vankar</option>
                            <option value="Vannar">Vannar</option>
                            <option value="Vannia Kula Kshatriyar">
                              Vannia Kula Kshatriyar
                            </option>
                            <option value="Vanniyar">Vanniyar</option>
                            <option value="Variar">Variar</option>
                            <option value="Varshney">Varshney</option>
                            <option value="Veera Saivam">Veera Saivam</option>
                            <option value="Veerashaiva">Veerashaiva</option>
                            <option value="Velaan/Vellalar">
                              Velaan/Vellalar
                            </option>
                            <option value="Velama">Velama</option>
                            <option value="Vellalar">Vellalar</option>
                            <option value="Vellalar Devandra Kula">
                              Vellalar Devandra Kula
                            </option>
                            <option value="Veluthedathu Nair">
                              Veluthedathu Nair
                            </option>
                            <option value="Vettuva Gounder">
                              Vettuva Gounder
                            </option>
                            <option value="Vilakkithala Nair">
                              Vilakkithala Nair
                            </option>
                            <option value="Vishwakarma">Vishwakarma</option>
                            <option value="Viswabrahmin">Viswabrahmin</option>
                            <option value="Vokkaliga">Vokkaliga</option>
                            <option value="Vysya">Vysya</option>
                            <option value="Yadav">Yadav</option>
                            <option value="Yellapu">Yellapu</option>
                          </select>
                          <span style={{ color: "red" }}>
                            {formError.your_caste}
                          </span>
                        </div>

                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Subcaste </label>
                          <input
                            type="text"
                            className="agile-ltextb"
                            placeholder="Subcaste"
                            name="sub_caste"
                            value={datab.sub_caste}
                            onChange={ChangeHandler}
                            readOnly=""
                            fdprocessedid="gk0h8"
                          />
                          <span style={{ color: "red" }}>
                            {formError.sub_caste}
                          </span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> About Yourself</label>
                          <textarea
                            type="textarea"
                            className="agile-ltextba"
                            name="about_yourself"
                            value={datab.about_yourself}
                            onChange={ChangeHandler}
                            placeholder="Say Something About You"
                            rows={4}
                            maxLength={250}
                            tabIndex={10}
                            /* style={{ width:1200, height: 100 }}*/
                            defaultValue={""}
                          />
                          <span style={{ color: "red" }}>
                            {formError.about_yourself}
                          </span>
                        </div>

                        <div className="col-lg-10b col-md-10b col-sm-6b form-groupb">


                        {!showForm && (
  <div>
    {!isSubmitted && (
      <button
        className="theme-btnb btnb btn-style-oneb"
        fdprocessedid="jqtd0p"
        onClick={showData}
        // style={{ marginLeft: '-30px' }}
        style={{marginLeft:"40%",width:"40%"}}
      >
        <span className="btn-title" tabIndex={17} >
          Submit
        </span>
      </button>
    )}
  </div>
)}



                          {/* Input field for user to enter the ID */}

                          {showForm && (
                            <div>
                              <input
                                type="text"
                                name="id"
                                value={datab.id}
                                onChange={ChangeHandler}
                                placeholder="Enter ID"
                              />

                            <button
                                className="theme-btnb btnb btn-style-oneb mt-4b"
                                fdprocessedid="jqtd0p"
                                onClick={handleEdit}
                                style={{ marginLeft: '-30px' }}
                              >
                                <span className="btn-title" tabIndex={17}>
                                  Get Data
                                </span>
                              </button>

                            
                              <button
                                className="theme-btnb btnb btn-style-oneb mt-4b"
                                fdprocessedid="jqtd0p"
                                onClick={handlePutSubmit}
                                style={{ marginLeft: '-30px' }}
                              >
                                <span className="btn-title" tabIndex={17}>
                                  Update Data
                                </span>
                              </button>

                            </div>
                          )}

                        </div>



                      
                    </div>
                    {/* </Form> */}
                    <ToastContainer style={{ marginTop: "100px" }} />
                    {error ? (
                      <div
                        className="theme-text -style-one mt-4"
                        style={{ color: "red" }}
                      >
                        <h4>{error}</h4>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="" style={{ marginLeft: "" }}>

              </div>{" "}
            </div>
        </div>
      </section>


              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" 
                onClick={() => setShowModalbasic(false)} style={{marginRight:"40%",width:"20%"}}>Close</button>
                {/* {!showForm && (
  <div>
    {!isSubmitted && (
      <button
        className="theme-btnb btnb btn-style-oneb"
        fdprocessedid="jqtd0p"
        onClick={showData}
        // style={{ marginLeft: '-30px' }}
        style={{width:"20%",height:"10%"}}
      >
        <span className="btn-title" tabIndex={17} >
          Submit
        </span>
      </button>
    )}
  </div>
)} */}
              </div>
            </div>
          </div>
        </div>
       )
      }
    </div>

    <div
      className="feature-block-twopro col-lg-4pro col-md-6pro col-sm-6pro wow fadeInUp animated"
      style={{ visibility: "visible", animationName: "fadeInUp" }}
    >
      {dataint.length > 0 && (
      <div className="inner-boxpro">
        <div className="icon-boxpro">
          <img
            src="https://www.matrimonysoftware.in/icon/horocope.png"
            alt=""
          />
        </div>
        <h4>
          <a href="horoscope">
            Interest Link{" "}
            <i style={{ fontSize: 24,marginLeft:"15px" }} className="fa">
              
            </i>
          </a>{" "}
          <BsFillPencilFill className="editpro" onClick={() => setShowModalint(true)}/>
        </h4>
        <div className="textpro"><span>Sports:</span> {dataint[dataint.length - 1].sports_details}</div>
        <div className="textpro"><span>Movie:</span> {dataint[dataint.length - 1].movie_details}</div>
        <div className="textpro"><span>Books:</span> {dataint[dataint.length - 1].books_details}</div>
        <div className="textpro"><span>Travel:</span> {dataint[dataint.length - 1].travel_details}</div>
        <div className="textpro"><span>Volunteer work:</span> {dataint[dataint.length - 1].volunteer_work_details}</div>
        
      </div>
       )}
       {showModalint && (
         <div className="modal fade show" id="exampleModalLong" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" style={{ display: 'block' }}>
           <div className="modal-dialog" role="document">
             <div className="modal-content">
               <div className="modal-header">
                 <h5 className="modal-title" id="exampleModalLongTitle"  style={{marginLeft:"30%",color:"#ec167f"}}>Interest Link</h5>
                 <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowModalint(false)}>
                   <span aria-hidden="true">&times;</span>
                 </button>
               </div>
               <div className="modal-body">


               <div className="interestlink" style={{width:"100%"}}>
 
 
 <div className="interestlist">
   <label className='label-interest' style={{color:'black'}}><h10>Sports :</h10></label>
   <div className="radio-group">
     <label >
       <input
       className='radio-btn'
         type="radio"
         name="sports"
         checked={interest.sports}
         onChange={()=>handleChangeint('sports',true)}
       />
       Yes
     </label>
     <label >
       <input
        className='radio-btn'
         type="radio"
         name="sports"
         checked={!interest.sports}
         onChange={() =>handleChangeint('sports',false)}
       />
       No
     </label>
   </div>
   {interest.sports && <input type="text" placeholder="Enter your sports details" name="sports_details" 
   value={interest.sports_details} onChange={(e) => handleChangeint('sports_details', e.target.value)} />}
 </div>

 <div className="interestlist">
   <label className='label-interest'  style={{color:'black'}}><h10>Movie :</h10></label>
   <div className="radio-group">
     <label >
       <input
        className='radio-btn'
         type="radio"
         name="movie"
         checked={interest.movie}
         onChange={() => handleChangeint('movie', true)}
       />
       Yes
     </label>
     <label>
       <input
        className='radio-btn'
         type="radio"
         name="movie"
         checked={!interest.movie}
         onChange={() => handleChangeint('movie', false)}
       />
       No
     </label>
   </div>
   {interest.movie && <input type="text" placeholder="Enter your movie details"  name="movie_details" 
   value={interest.movie_details} onChange={(e) => handleChangeint('movie_details', e.target.value)}/>}
 </div>

 <div className="interestlist">
   <label className='label-interest'  style={{color:'black'}}><h10>Books :</h10></label>
   <div className="radio-group">
     <label>
       <input
        className='radio-btn'
         type="radio"
         name="books"
         checked={interest.books}
         onChange={() => handleChangeint('books',true)}
       />
       Yes
     </label>
     <label>
       <input
        className='radio-btn'
         type="radio"
         name="books"
         checked={!interest.books}
         onChange={() => handleChangeint('books',false)}
       />
       No
     </label>
   </div>
   {interest.books && <input type="text" placeholder="Enter your books details" name="books_details" 
   value={interest.books_details} onChange={(e) => handleChangeint('book_details', e.target.value)}/>}
 </div>

 <div className="interestlist">
   <label className='label-interest'  style={{color:'black'}}><h10>Travel :</h10></label>
   <div className="radio-group">
     <label>
       <input
        className='radio-btn'
         type="radio"
         name="travel"
         checked={interest.travel}
         onChange={() => handleChangeint('travel',true)}
       />
       Yes
     </label>
     <label>
       <input
        className='radio-btn'
         type="radio"
         name="travel"
         checked={!interest.travel}
         onChange={() => handleChangeint('travel',false)}
       />
       No
     </label>
   </div>
   {interest.travel && <input type="text" placeholder="Enter your travel details" name="travel_details" 
   value={interest.interest_details} onChange={(e) => handleChangeint('travel_details', e.target.value)}/>}
 </div>

 <div className="interestlist">
   <label className='label-interest' style={{color:'black'}}><h10>Volunteerwork :</h10></label>
   <div className="radio-group">
     <label>
       <input
        className='radio-btn'
         type="radio"
         name="volunteer_work"
         checked={interest.volunteer_work}
         onChange={() => handleChangeint('volunteer_work',true)}
       />
       Yes
     </label>
     <label>
       <input
        className='radio-btn'
         type="radio"
         name="volunteer_work"
         checked={!interest.volunteer_work}
         onChange={() => handleChangeint('volunteer_work',false)}
       />
       No
     </label>
   </div>
   {interest.volunteer_work && <input type="text" placeholder="Enter your volunteerwork details" 
   name="volunteer_work_details" value={interest.volunteer_work_details} onChange={(e) => handleChangeint('volunteer_work_details', e.target.value)}/>}
 </div>
 
</div>
                
               </div>
               <div className="modal-footer">
                 <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setShowModalint(false)}>Close</button>
                 <button className='interest-btn' onClick={handleSubmitint}>Submit</button>
               </div>
             </div>
          </div>
          </div>
        )
      } 
    </div>

    <div
      className="feature-block-twopro col-lg-4pro col-md-6pro col-sm-6pro wow fadeInUp animated"
      style={{ visibility: "visible", animationName: "fadeInUp" }}
      
    >
      {/* {datahabit.length > 0 && ( */}
    
      <div className="inner-boxpro">
        <div className="icon-boxpro">
          <img
            src="https://www.matrimonysoftware.in/icon/horocope.png"
            alt=""
          />
        </div>
        <h4>
          <a href="horoscope">
            HabitsForm{" "}
            <i style={{ fontSize: 24,marginLeft:"15px" }} className="fa">
              
            </i>
          </a>{" "}
          <BsFillPencilFill className="editpro"  />
        </h4>
        <div className="textpro"><span>Smoking habits:</span> </div>
        <div className="textpro"><span>Drinking habits:</span> </div>
        <div className="textpro"><span>Exercise habits:</span> </div>
        <div className="textpro"><span>Eating habits:</span> </div>
        <div className="textpro"><span>Sleeping habits:</span> </div>
        
      </div>
        {/* )
      }  */}
    </div>

    <div className="feature-block-twopro col-lg-4pro col-md-6pro col-sm-6pro wow fadeInUp animated"
  style={{ visibility: "visible", animationName: "fadeInUp" }}
>
  {datahobby.length > 0 && (
    <div className="inner-boxpro">
      <div className="icon-boxpro">
        <img
          src="https://www.matrimonysoftware.in/icon/horocope.png"
          alt=""
        />
      </div>
      <h4>
        <a href="horoscope">
          Hobby details{" "}
          <i style={{ fontSize: 24 }} className="fa">
            
          </i>
        </a>{" "}
        <BsFillPencilFill className="editpro" onClick={() => setShowModal(true)} />
      </h4>
      <div className="textpro">
        <span>Cooking:</span> {datahobby[datahobby.length - 1].cooking_text}
      </div>
      <div className="textpro">
        <span>Music:</span> {datahobby[datahobby.length - 1].music_text}
      </div>
      <div className="textpro">
        <span>writing:</span> {datahobby[datahobby.length - 1].writing_text}
      </div>
      <div className="textpro">
        <span>Gaming:</span> {datahobby[datahobby.length - 1].gaming_text}
      </div>
      <div className="textpro">
        <span>Gardening:</span> {datahobby[datahobby.length - 1].gardening_text}
      </div>
    </div>
  )}
  {showModal && (
    <div className="modal fade show" id="exampleModalLong" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" style={{ display: 'block' }}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle" style={{marginLeft:"30%",color:"#ec167f"}}>Hobby Details</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" >
          
        
        <div className="hobby-item"  style={{marginLeft:"2%"}}>
          <label className='hobby-lable'  style={{color:'black'}}><h10>Cooking:</h10></label>
          <div className="radio-group">
            <label className='hobby-lable'>
              <input
                className='radio-btn'
                type="radio"
                name="cooking"
                checked={hobbies.cooking}
                onChange={() => handleChange('cooking', true)}
              />
              Yes
            </label>
            <label className='hobby-lable'>
              <input
                className='radio-btn'
                type="radio"
                name="cooking"
                checked={!hobbies.cooking}
                onChange={() => handleChange('cooking', false)}
              />
              No
            </label>
          </div>
          {hobbies.cooking && <input className='hobby-text' type="text" placeholder="Enter your cooking details" name="cooking_text" value={hobbies.cooking_text} onChange={(e) => handleChange('cooking_text', e.target.value)} />}
        </div>

        <div className="hobby-item" style={{marginLeft:"2%"}}>
          <label className='hobby-lable'  style={{color:'black'}}><h10>Music:</h10></label>
          <div className="radio-group">
            <label className='hobby-lable'>
              <input
                className='radio-btn'
                type="radio"
                name="music"
                checked={hobbies.music}
                onChange={() => handleChange('music', true)}
              />
              Yes
            </label>
            <label className='hobby-lable'>
              <input
                className='radio-btn'
                type="radio"
                name="music"
                checked={!hobbies.music}
                onChange={() => handleChange('music', false)}
              />
              No
            </label>
          </div>
          {hobbies.music && <input className='hobby-text' type="text" placeholder="Enter your music details" name="music_text" value={hobbies.music_text} onChange={(e) => handleChange('music_text', e.target.value)} />}
        </div>

        <div className="hobby-item" style={{marginLeft:"2%"}}>
          <label className='hobby-lable'  style={{color:'black'}}><h10>Writing:</h10></label>
          <div className="radio-group">
            <label className='hobby-lable'>
              <input
                className='radio-btn'
                type="radio"
                name="writing"
                checked={hobbies.writing}
                onChange={() => handleChange('writing', true)}
              />
              Yes
            </label>
            <label className='hobby-lable'>
              <input
                className='radio-btn'
                type="radio"
                name="writing"
                checked={!hobbies.writing}
                onChange={() => handleChange('writing', false)}
              />
              No
            </label>
          </div>
          {hobbies.writing && <input className='hobby-text' type="text" placeholder="Enter your writing details" name="writing_text" value={hobbies.writing_text} onChange={(e) => handleChange('writing_text', e.target.value)} />}
        </div>

        <div className="hobby-item" style={{marginLeft:"2%"}}>
          <label className='hobby-lable'  style={{color:'black'}}><h10>Gaming:</h10></label>
          <div className="radio-group">
            <label className='hobby-lable'>
              <input
                className='radio-btn'
                type="radio"
                name="gaming"
                checked={hobbies.gaming}
                onChange={() => handleChange('gaming', true)}
              />
              Yes
            </label>
            <label className='hobby-lable'>
              <input
                className='radio-btn'
                type="radio"
                name="gaming"
                checked={!hobbies.gaming}
                onChange={() => handleChange('gaming', false)}
              />
              No
            </label>
          </div>
          {hobbies.gaming && <input className='hobby-text' type="text" placeholder="Enter your gaming details" name="gaming_text" value={hobbies.gaming_text} onChange={(e) => handleChange('gaming_text', e.target.value)} />}
        </div>

        <div className="hobby-item" style={{marginLeft:"2%"}}>
          <label className='hobby-lable'  style={{color:'black'}}><h10>Gardening:</h10></label>
          <div className="radio-group">
            <label className='hobby-lable'>
              <input
                className='radio-btn'
                type="radio"
                name="gardening"
                checked={hobbies.gardening}
                onChange={() => handleChange('gardening', true)}
              />
              Yes
            </label>
            <label className='hobby-lable'>
              <input
                className='radio-btn'
                type="radio"
                name="gardening"
                checked={!hobbies.gardening}
                onChange={() => handleChange('gardening', false)}
              />
              No
            </label>
          </div>
          {hobbies.gardening && <input className='hobby-text' type="text" 
          placeholder="Enter your gardening details" name="gardening_text" 
          value={hobbies.gardening_text} onChange={(e) => handleChange('gardening_text', e.target.value)} />}
        </div>

        
        
        
    
          </div>
          <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setShowModal(false)}>Close</button>
          <button className='hobby-btn' onClick={handleSubmit}>Submit</button>
          </div>
        </div>
      </div>
    </div>
  )}
</div>

  </div>
</div>



    </div>
  )
    
}

export default Profile
