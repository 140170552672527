import React, { useState, useRef, useEffect } from "react";
import "./HoroscopeStyle.css";
import { ToastContainer } from "react-toastify";
import { Input } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { casteOptions } from "./Horoscopedata";
import { initialdata } from "./Horoscopedata";
import { moonsignOptions } from "./Horoscopedata";
import { mangalikOptions } from "./Horoscopedata";
import { shaniOptions } from "./Horoscopedata";
import { hoursOptions } from "./Horoscopedata";
import { minutesOptions } from "./Horoscopedata";
import { secondsOptions } from "./Horoscopedata";
const Horoscope = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const [data, setdata] = useState(initialdata);
  const [starSearchTerm, setStarSearchTerm] = useState("");
  const [selectedStar, setSelectedStar] = useState("");
  const starDropdownRef = useRef(null);
  const [isStarDropdownOpen, setIsStarDropdownOpen] = useState(false);
  const [formError, setFormError] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [error, setError] = useState(null);
  const handleStarInputChange = (event) => {
    setStarSearchTerm(event.target.value);
  };
  const handleSelectChange = (value) => {
    setSelectedStar(value);
    setIsStarDropdownOpen(false);
  };
  const filteredStarOptions = casteOptions.filter((option) =>
    option.label.toLowerCase().includes(starSearchTerm.toLowerCase())
  );
  const toggleDropdown = () => {
    setIsStarDropdownOpen(!isStarDropdownOpen);
  };
  const handleClickOutside = (event) => {
    if (
      starDropdownRef.current &&
      !starDropdownRef.current.contains(event.target)
    ) {
      setIsStarDropdownOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const inputChangeHandler = (e) => {
    setdata({ ...data, [e.target.name]: e.target.value });
  };
  const showData = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://matrimonyadmin.hola9.com/api/Horoscopeapi/horoscopeList/",
        data
      );
      const id = response.data.id;
      setdata({ ...data, id });
      console.log(response.data);
      setShowForm(true);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <div className="page-wrapper-Horo">
        <section
          className="page-title-Horo"
          style={{ backgroundColor: "black" }}
        >
          <div className="auto-container-Horo">
            <h1 className="d-noneh d-lg-block d-xl-block d-md-block">
              Horoscope
            </h1>
            <ul className="bread-crumb-Horo clearfix-Horo">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Horoscope</li>
            </ul>
          </div>
        </section>
        <button onClick={goBack} className="back-button-pay">
          <i class="ri-arrow-left-line"></i>
        </button>
        <div className="row-Horo clearfix-Horo">
          <div className="col-lg-3-Horo col-md-5-Horo col-sm-6-Horo form-group-Horo">
            <label className="labelCls1">Moon signs</label>
            <select
              type="text"
              className="custom-select-box-Horo"
              title="Select Moon_sign"
              name="moon_sign"
              id="Moon_Sign"
              onChange={inputChangeHandler}
              value={data.moon_sign}
              tabIndex={1}
              fdprocessedid="res69a"
            >
              {moonsignOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <span style={{ color: "red" }}>{formError.moon_sign}</span>
          </div>
          <div className="col-lg-3-Horo col-md-5-Horo col-sm-6-Horo form-group-Horo">
            <label className="labelCls1">Star</label>
            <div className="custom-dropdown-global" ref={starDropdownRef}>
              <div
                className="selected-option-global"
                onClick={() => toggleDropdown("Star")}
              >
                {selectedStar ? selectedStar : "Select Star"}
              </div>
              {isStarDropdownOpen && (
                <div className="dropdown-menu-global">
                  <input
                    type="text"
                    className="custom-select-box-global"
                    placeholder="Search Star"
                    value={starSearchTerm}
                    onChange={handleStarInputChange}
                    autoFocus
                  />
                  <div className="dropdown-options-global">
                    {filteredStarOptions.map((option, index) => (
                      <div
                        key={index}
                        className="dropdown-option-global"
                        onClick={() => handleSelectChange(option.label, "Star")}
                      >
                        {option.label}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <span style={{ color: "red" }}>{formError.star}</span>
          </div>
          <div className="col-lg-3-Horo col-md-5-Horo col-sm-6-Horo form-group-Horo">
            <label className="labelCls1">Gotra</label>
            <Input
              type="text"
              // className="agile-ltext-Horo"
              className="custom-select-box-Horo"
              maxLength={20}
              placeholder="Gotra"
              name="gotra"
              // id="Gotra"
              onChange={inputChangeHandler}
              value={data.gotra}
              tabIndex={3}
              defaultValue=""
              // fdprocessedid="ji0au"
            />
            <span style={{ color: "red" }}>{formError.gotra}</span>
          </div>
          <div className="col-lg-3-Horo col-md-5-Horo col-sm-6-Horo form-group-Horo">
            <label className="labelCls1">Mangalik</label>
            <select
              className="custom-select-box-Horo"
              title="Select Manglik"
              name="manglik"
              // id="Manglik"
              onChange={inputChangeHandler}
              value={data.manglik}
              tabIndex={4}
              fdprocessedid="9j9ynk"
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "please input Your Manglik",
                },
              ]}
            >
              {mangalikOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <span style={{ color: "red" }}>{formError.manglik}</span>
          </div>
          <div className="col-lg-3-Horo col-md-5-Horo col-sm-6-Horo form-group-Horo">
            <label className="labelCls1">Shani</label>
            <select
              className="custom-select-box-Horo"
              title="Select Shani"
              name="shani"
              onChange={inputChangeHandler}
              value={data.shani}
              tabIndex={5}
              // fdprocessedid="g0b22"
            >
              {shaniOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <span style={{ color: "red" }}>{formError.shani}</span>
          </div>
          <div className="col-lg-3-Horo col-md-5-Horo col-sm-6-Horo form-group-Horo">
            <label className="labelCls1">Horoscope Match</label>
            <select
              className="custom-select-box-Horo"
              title="Select Horoscopematch"
              name="hororscope_match"
              onChange={inputChangeHandler}
              value={data.hororscope_match}
              // id="caste"
              tabIndex={6}
              fdprocessedid="4it3iv"
            >
              <option value="Prefered">Select Prefered</option>
              <option value="Not Prefered">Not Prefered</option>
              <option value="Does not matter">Does not matter</option>
            </select>
            <span style={{ color: "red" }}>{formError.hororscope_match}</span>
          </div>
          <div className="col-lg-3-Horo col-md-5-Horo col-sm-6-Horo form-group-Horo">
            <label className="labelCls1">Place of Birth</label>
            <Input
              classNames="InputCls-Horo"
              type="text"
              // className="agile-ltext-Horo"
              className="custom-select-box-Horo"
              tabIndex={11}
              maxLength={30}
              placeholder="Enter Place of Birth"
              name="place_of_birth"
              onChange={inputChangeHandler}
              value={data.place_of_birth}
              defaultValue=""
              fdprocessedid="7a8yg"
            />
            <span style={{ color: "red" }}>{formError.place_of_birth}</span>
          </div>
          <div className="col-lg-3-Horo col-md-5-Horo col-sm-6-Horo form-group-Horo">
            <label className="labelCls1">Place of Country</label>
            <Input
              type="text"
              // className="agile-ltext-Horo"
              className="custom-select-box-Horo"
              tabIndex={12}
              maxLength={30}
              placeholder="Enter Place of Country"
              name="place_of_country"
              onChange={inputChangeHandler}
              value={data.place_of_country}
              // id="Place_Of_Country"
              defaultValue=""
              fdprocessedid="izvngv"
            />
            <span style={{ color: "red" }}>{formError.place_of_country}</span>
          </div>
          <div className="col-lg-3-Horo col-md-5-Horo col-sm-6-Horo form-group-Horo">
            <label className="labelCls1">Hours</label>
            <select
              name="hours"
              className="custom-select-box-Horo"
              title="Hours"
              onChange={inputChangeHandler}
              value={data.hours}
              tabIndex={7}
              // fdprocessedid="54785i"
            >
              {hoursOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <span style={{ color: "red" }}>{formError.hours}</span>
          </div>
          <div className="col-lg-3-Horo col-md-5-Horo col-sm-6-Horo form-group-Horo">
            <label className="labelCls1">Minutues</label>
            <select
              name="minitues"
              className="custom-select-box-Horo"
              title="Minitues"
              onChange={inputChangeHandler}
              value={data.minitues}
              tabIndex={8}
              // fdprocessedid="6tib0r"
            >
              {minutesOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <span style={{ color: "red" }}>{formError.minitues}</span>
          </div>
          <div className="col-lg-3-Horo col-md-5-Horo col-sm-6-Horo form-group-Horo">
            <label className="labelCls1">Seconds</label>
            <select
              name="seconds"
              className="custom-select-box-Horo"
              title="Seconds"
              onChange={inputChangeHandler}
              value={data.seconds}
              tabIndex={9}
              // fdprocessedid="5cm2dk"
            >
              {secondsOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <span style={{ color: "red" }}>{formError.seconds}</span>
          </div>
          <div className="col-lg-3-Horo col-md-5-Horo col-sm-6-Horo form-group-Horo">
            <label className="labelCls1">AM/PM</label>
            <select
              name="am_pm"
              className="custom-select-box-Horo"
              title="Am_Pm"
              onChange={inputChangeHandler}
              value={data.am_pm}
              tabIndex={10}
              // fdprocessedid="nmk97e"
            >
              <option value="" selected="">
                AM/PM
              </option>
              <option value="AM">AM</option>
              <option value="PM">PM</option>
            </select>
            <span style={{ color: "red" }}>{formError.am_pm}</span>
          </div>
          {!showForm && (
            <div className="horo-submit-btn">
              <button
                className="theme-btn-Horo btn-Horo btn-style-one-Horo"
                fdprocessedid="jqtd0p"
                onClick={showData}
              >
                <span className="btn-title-Horo" tabIndex={17}>
                  Submit
                </span>
              </button>
            </div>
          )}
        </div>
        <ToastContainer style={{ marginTop: "100px" }} />
        {error ? (
          <div className="theme-text-style-one mt-4" style={{ color: "red" }}>
            <h4>{error}</h4>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default Horoscope;
