import React, { useState } from 'react';
import "./VerifyMobile.css"
import axios from 'axios';

const VerifyMobile = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOTP] = useState('');
    const [otpSent, setOTPSent] = useState(false);
    const [verificationStatus, setVerificationStatus] = useState('');
    const [showForm, setShowForm] = useState(true);

    const sendOTP = async () => {
        try {
            // const response = await axios.post('/send-otp', { phoneNumber });
            // console.log(response.data.otp); // For demonstration purposes
            setOTPSent(true);
        } catch (error) {
            console.error('Error sending OTP:', error);
        }
    };

    const verifyOTP = async (e) => {
        e.PreventDefault();
        try {
            const response = await axios.post('/verify-otp', { phoneNumber, otp });
            if (response.data.success) {
                setVerificationStatus('OTP Verified Successfully!');
            } else {
                setVerificationStatus('Invalid OTP');
            }
        } catch (error) {
            console.error('Error verifying OTP:', error);
        }
    };

    const handleExit = () => {
        setShowForm(false); // Hide the form when exit button is clicked
    };

    return (
        <>
        <div style={{marginTop:"120px",height:"50vh",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
        <div>
            <h1 className='VerifyMob-text'>Verify Your Mobile Number</h1>
            <p className='VerifyMob-para'>You will receive a 4 digit confirmation code via sms to <b>7894561230</b></p>
        </div>
        <div>
            <input className='phNO' type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} placeholder="Enter Phone Number" /> <br/><br/>
            <button className='sendOtp' onClick={sendOTP}>Send OTP</button>
          
            {otpSent && (
                <>
                    {/* <input type="text" value={otp} onChange={(e) => setOTP(e.target.value)} placeholder="Enter OTP" />
                    <button onClick={verifyOTP}>Verify OTP</button> */}
                    {showForm && (
                    <form class="otp-Form" onSubmit={verifyOTP}>
 
 <span class="mainHeading">Enter OTP</span>
 <p class="otpSubheading">We have sent a verification code to your mobile number</p>
 <div class="inputContainer">
  <input required="required" maxlength="1" type="text" class="otp-input" id="otp-input1" onChange={(e) => setOTP(e.target.value)}/>
  <input required="required" maxlength="1" type="text" class="otp-input" id="otp-input2" onChange={(e) => setOTP(e.target.value)}/>
  <input required="required" maxlength="1" type="text" class="otp-input" id="otp-input3" onChange={(e) => setOTP(e.target.value)}/>
  <input required="required" maxlength="1" type="text" class="otp-input" id="otp-input4" onChange={(e) => setOTP(e.target.value)}/> 
  
 </div>
  <button class="verifyButton" type="submit">Verify</button>
    <button class="exitBtn" onClick={handleExit}>×</button>
    <p class="resendNote">Didn't receive code yet? <a class="resendBtn">Resend Code</a></p>
    
</form>
)}
                </>
            )}
            
            </div>
            {verificationStatus && <p>{verificationStatus}</p>}
        </div>
        </>
    );
};

export default VerifyMobile;