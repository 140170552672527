import React, { useState, useEffect } from 'react';
import './chat.css';
import profileIcon from './profileimg.png';
import callIcon from './call-icon.png';
import videoCallIcon from './video-call-icon.png';
import { Document, Page } from 'react-pdf';
import io from 'socket.io-client';

const socket = io('http://localhost:3000'); // Replace with your server URL

const ChatApp = () => {
  const [messages, setMessages] = useState([]);
  const [firstMessageSent, setFirstMessageSent] = useState(false);

  useEffect(() => {
    socket.on('new_message', (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
      if (!firstMessageSent) {
        setFirstMessageSent(true);
        if (Notification.permission === 'granted') {
          new Notification('New Message', { body: message.text });
        } else if (Notification.permission !== 'denied') {
          Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
              new Notification('New Message', { body: message.text });
            }
          });
        }
      }
    });
  }, [firstMessageSent]);

  const handleMessageSubmit = (event) => {
    event.preventDefault();
    const message = {
      text: event.target.message.value,
      file: event.target.file.files[0],
    };
    setMessages((prevMessages) => [...prevMessages, message]);
    if (!firstMessageSent && message.text) {
      setFirstMessageSent(true);
      socket.emit('new_message', message);
    }
    event.target.reset();
  };

  return (
    <>

    <div className="chatApp-body">
    <div className="container-ChatApp">
      <div className="header-ChatApp">
        <div className="username">
          <div className="profile-icon" style={{ width: '55px', height: '55px', marginLeft: "-10px" , background: 'white', borderRadius: '50%' }}>
            <img src={profileIcon} alt="profile" />
          </div>
          Username
        </div>
        <div className="call-icons">
          <div className="call-icon">
            <img src={callIcon} alt="call" />
          </div>
          <div className="video-call-icon">
            <img src={videoCallIcon} alt="video-call" />
          </div>
        </div>
      </div>
      <div className="chat-container">
        {messages.map((message, index) => (
          <div className="message" key={index}>
            <div className="user-icon"></div>
            <div className="message-body">
              {message.text && <p>{message.text}</p>}
              {message.file && message.file.type === 'application/pdf' && (
                <Document file={message.file}>
                  <Page pageNumber={1} />
                </Document>
              )}
              {message.file && message.file.type !== 'application/pdf' && (
                <img src={URL.createObjectURL(message.file)} alt="file" />
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="footer-ChatApp">
        <form onSubmit={handleMessageSubmit}>
          <input type="file" name="file" id="file" className="input-file-ChatApp" />
          <input
            type="text"
            name="message"
            placeholder="Type a message..."
            className="input-text-ChatApp"
          /><button className="send-button">Send</button>
        </form>
      </div>
    </div>
    </div>
    </>
  );
};

export default ChatApp;



// import React, { useState, useEffect } from 'react';
// import './chat.css';
// import profileIcon from './profileimg.png';
// import callIcon from './call-icon.png';
// import videoCallIcon from './video-call-icon.png';
// import { Document, Page } from 'react-pdf';
// import io from 'socket.io-client';
// import { useParams } from 'react-router-dom';

// const socket = io('http://localhost:3000'); // Replace with your server URL

// const ChatApp = () => {
//   const { userId } = useParams();

//   const [messages, setMessages] = useState([]);
//   const [firstMessageSent, setFirstMessageSent] = useState(false);

//   useEffect(() => {
//     socket.on('new_message', (message) => {
//       setMessages((prevMessages) => [...prevMessages, message]);
//       if (!firstMessageSent) {
//         setFirstMessageSent(true);
//         if (Notification.permission === 'granted') {
//           new Notification('New Message', { body: message.text });
//         } else if (Notification.permission !== 'denied') {
//           Notification.requestPermission().then((permission) => {
//             if (permission === 'granted') {
//               new Notification('New Message', { body: message.text });
//             }
//           });
//         }
//       }
//     });
//   }, [firstMessageSent]);

//   const handleMessageSubmit = (event) => {
//     event.preventDefault();
//     const message = {
//       text: event.target.message.value,
//       file: event.target.file.files[0],
//       userId: userId // Adding userId to the message
//     };
//     setMessages((prevMessages) => [...prevMessages, message]);
//     if (!firstMessageSent && message.text) {
//       setFirstMessageSent(true);
//       socket.emit('new_message', message);
//     }
//     event.target.reset();
//   };

//   return (
//     <div className ="chatApp-body">
//       <div className="container-ChatApp">
//         <div className="header-ChatApp">
//           <div className="username">
//             <div className="profile-icon" style={{ width: '55px', height: '55px', marginLeft: "-10px" , background: 'white', borderRadius: '50%' }}>
//               <img src={profileIcon} alt="profile" />
//             </div>
//             Username
//           </div>
//           <div className="call-icons">
//             <div className="call-icon">
//               <img src={callIcon} alt="call" />
//             </div>
//             <div className="video-call-icon">
//               <img src={videoCallIcon} alt="video-call" />
//             </div>
//           </div>
//         </div>
//         <div className="chat-container">
//           {messages.map((message, index) => (
//             <div className="message" key={index}>
//               <div className="user-icon"></div>
//               <div className="message-body">
//                 {message.text && <p>{message.text}</p>}
//                 {message.file && message.file.type === 'application/pdf' && (
//                   <Document file={message.file}>
//                     <Page pageNumber={1} />
//                   </Document>
//                 )}
//                 {message.file && message.file.type !== 'application/pdf' && (
//                   <img src={URL.createObjectURL(message.file)} alt="file" />
//                 )}
//               </div>
//             </div>
//           ))}
//         </div>
//         <div className="footer-ChatApp">
//           <form onSubmit={handleMessageSubmit}>
//             <input type="file" name="file" id="file" className="input-file-ChatApp" />
//             <input
//               type="text"
//               name="message"
//               placeholder="Type a message..."
//               className="input-text-ChatApp"
//             />
//             <button className="send-button">Send</button>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ChatApp;
