import React, { useState } from "react";
import axios from "axios"; // Import axios for making HTTP requests
import "./Addphoto.css";

function Addphoto() {
  const [showInput, setShowInput] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false); // State for success popup

  const handleAddPhotoClick = () => {
    setShowInput(true);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("file", selectedFile); // Append the selected file to the formData

      // Make a POST request to the API
      const response = await axios.post(
        "https://matrimonyadmin.hola9.com/api/crud/holaapi/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle successful response
      console.log("Response:", response.data);

      // Show success popup
      setShowSuccessPopup(true);
    } catch (error) {
      // Handle error
      console.error("Error:", error);
    }
  };

  const handleClosePopup = () => {
    setShowSuccessPopup(false);
  };

  return (
    <div>

      <div className="photo-card">
        <div className="container-photo">
          <div className="call-to-action">
            <h1 className="photo-text">Add your photo and get much better responses!</h1>
            <div className="add-photo-now-btn">
              <button onClick={handleAddPhotoClick}>ADD PHOTO NOW</button>
              {showInput && (
  <div className="file-input">
    <input
      type="file"
      accept="image/*"
      id="file-upload"
      onChange={handleFileInputChange}
    />
  </div>
)}
            </div>

       
            <div className="user-profile">
            {selectedFile && (
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="Selected File"
                className="user-photo"
              />
            )}
            {!selectedFile && (
              <img
                src="https://i.pinimg.com/originals/8b/16/7a/8b167af653c2399dd93b952a48740620.jpg"
                alt="User Icon"
                className="user-photo"
              />
            )}
            {/* <span className="add-icon">+</span> */}
          </div>

            <div className="extra-detail">
              <p>• Within 15 MB of size</p>
              <p>• jpg/ gif/ png/ bmp</p>
            </div>
          </div>
          <div className="Add-submit-btn">
            <button onClick={handleSubmit}>Submit</button>
          </div>


          {/* Success Popup */}
          {showSuccessPopup && (
            <div className="popup-modal">
              <div className="success-popup">
                <p>Image submitted successfully!</p>
                <button onClick={handleClosePopup}>Close</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Addphoto;
