import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./RegisterStyle.css";

const Register = () => {
  const [data, setData] = useState({
    gender: "",
    fromAge: "",
    toAge: "",
    religion: "",
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const onChangeHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const submitHandler = (e) => {
    e.preventDefault();
    console.log(data);
  };
  return (
    <div style={{ margin: "auto", width: "90%" }}>
      <section className="coming-soon-sectionR" style={{ marginTop: "20px" }}>
        <div className="auto-containerR">
          {/* <div className="outer-boxR" >
            <div className="time-counterR"  >
              <div className="col-md-1"  > */}
          {/* <div className="rowR"  > */}
          <form
            action="front_search_result?page=1"
            className="form-inlineR"
            method="post"
            onSubmit={submitHandler}
          >
            {/* <div className="main-registerR"> */}

            <div className="mainRegisterFormContainer">
              <div className="col-lg-3R col-md-3R col-sm-3R form-groupR">
                <label className="labelR" htmlFor="lookingfor">
                  <span className="searchR">I'm looking for a</span>
                </label>
                <select
                  className="dropselectR"
                  name="gender"
                  selected=""
                  tabIndex={1}
                  onChange={onChangeHandler}
                >
                  <option value="Male">Groom</option>
                  <option value="Female" selected="">
                    Bride
                  </option>
                </select>
              </div>

              <div className="col-lg-3R col-md-3R col-sm-3R form-groupR">
                <label className="labelR" htmlFor="lookingfor">
                  <span className="searchR">From Age</span>
                </label>
                <select
                  className="dropselectR"
                  id="fromage"
                  name="fromAge"
                  tabIndex={2}
                  required=""
                  onChange={onChangeHandler}
                >
                  <option value="" selected="">
                    Select
                  </option>
                  <option value={18}>18</option>
                  <option value={19}>19</option>
                  <option value={20}>20</option>
                  <option value={21}>21</option>
                  <option value={22}>22</option>
                  <option value={23}>23</option>
                  <option value={24}>24</option>
                  <option value={25}>25</option>
                  <option value={26}>26</option>
                  <option value={27}>27</option>
                  <option value={28}>28</option>
                  <option value={29}>29</option>
                  <option value={30}>30</option>
                  <option value={31}>31</option>
                  <option value={32}>32</option>
                  <option value={33}>33</option>
                  <option value={34}>34</option>
                  <option value={35}>35</option>
                  <option value={36}>36</option>
                  <option value={37}>37</option>
                  <option value={38}>38</option>
                  <option value={39}>39</option>
                  <option value={40}>40</option>
                  <option value={41}>41</option>
                  <option value={42}>42</option>
                  <option value={43}>43</option>
                  <option value={44}>44</option>
                  <option value={45}>45</option>
                  <option value={46}>46</option>
                  <option value={47}>47</option>
                  <option value={48}>48</option>
                  <option value={49}>49</option>
                  <option value={50}>50</option>
                  <option value={51}>51</option>
                  <option value={52}>52</option>
                  <option value={53}>53</option>
                  <option value={54}>54</option>
                  <option value={55}>55</option>
                  <option value={56}>56</option>
                  <option value={57}>57</option>
                  <option value={58}>58</option>
                  <option value={59}>59</option>
                  <option value={60}>60</option>
                  <option value={61}>61</option>
                  <option value={62}>62</option>
                  <option value={63}>63</option>
                  <option value={64}>64</option>
                  <option value={65}>65</option>
                </select>
              </div>
              <div className="col-lg-3R col-md-3R col-sm-3R form-groupR">
                <label className="labelR" htmlFor="lookingfor">
                  <span className="searchR">To Age</span>
                </label>
                <select
                  className="dropselectR"
                  id="toage"
                  name="toAge"
                  tabIndex={3}
                  required=""
                  onChange={onChangeHandler}
                >
                  <option value="" selected="">
                    Select
                  </option>
                  <option value={18}>18</option>
                  <option value={19}>19</option>
                  <option value={20}>20</option>
                  <option value={21}>21</option>
                  <option value={22}>22</option>
                  <option value={23}>23</option>
                  <option value={24}>24</option>
                  <option value={25}>25</option>
                  <option value={26}>26</option>
                  <option value={27}>27</option>
                  <option value={28}>28</option>
                  <option value={29}>29</option>
                  <option value={30}>30</option>
                  <option value={31}>31</option>
                  <option value={32}>32</option>
                  <option value={33}>33</option>
                  <option value={34}>34</option>
                  <option value={35}>35</option>
                  <option value={36}>36</option>
                  <option value={37}>37</option>
                  <option value={38}>38</option>
                  <option value={39}>39</option>
                  <option value={40}>40</option>
                  <option value={41}>41</option>
                  <option value={42}>42</option>
                  <option value={43}>43</option>
                  <option value={44}>44</option>
                  <option value={45}>45</option>
                  <option value={46}>46</option>
                  <option value={47}>47</option>
                  <option value={48}>48</option>
                  <option value={49}>49</option>
                  <option value={50}>50</option>
                  <option value={51}>51</option>
                  <option value={52}>52</option>
                  <option value={53}>53</option>
                  <option value={54}>54</option>
                  <option value={55}>55</option>
                  <option value={56}>56</option>
                  <option value={57}>57</option>
                  <option value={58}>58</option>
                  <option value={59}>59</option>
                  <option value={60}>60</option>
                  <option value={61}>61</option>
                  <option value={62}>62</option>
                  <option value={63}>63</option>
                  <option value={64}>64</option>
                  <option value={65}>65</option>
                </select>
              </div>
              <div className="col-lg-3R col-md-3R col-sm-3R form-groupR">
                <label className="labelR" htmlFor="lookingfor">
                  <span className="searchR">Religion</span>
                </label>
                <select
                  className="dropselectR"
                  name="religion"
                  id="religion"
                  tabIndex={4}
                  required=""
                  onChange={onChangeHandler}
                >
                  <option value="Any" selected="">
                    Any
                  </option>
                  <option value="Christian">Christian</option>
                  <option value="Hindu">Hindu</option>
                  <option value="hindu Barahsaini">hindu Barahsaini</option>
                  <option value="Inter-Religion">Inter-Religion</option>
                  <option value="Jain">Jain</option>
                  <option value="Muslim">Muslim</option>
                  <option value="Sikh">Sikh</option>
                </select>
                <span />
              </div>
            </div>
            {/* <div style={{ width: "49%", display: "flex" }}>
              
            </div> */}

            {/* </div> */}

            <div
              className="btn-boxR col-md-2R mt-3R "
              style={{ width: "20%", display: "flex", margin: "auto" }}
            >
              <button
                type="submit"
                name="submit"
                value="Let's Begin"
                className="theme-btnR btnR btn-style-twoR btn-style-letsbeginR homeRegister"
              >
                <Link to="signup">
                  <span
                    className="btn-title"
                    style={{ color: "white", whiteSpace: "nowrap" }}
                  >
                    Let's Begin{" "}
                  </span>
                </Link>
              </button>
            </div>
          </form>
          {/* </div> */}
          {/* </div>
            </div>
          </div> */}
        </div>
      </section>
    </div>
  );
};


export default Register;
