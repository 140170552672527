import React,{useState} from 'react'
import "./ProfileCards.css"
import Slider from "react-slick"
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons';
{/* <link href="https://cdn.jsdelivr.net/npm/remixicon@4.1.0/fonts/remixicon.css"rel="stylesheet"/> */}

const ProfileCards = () => {
    

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      };
    const data = [
        {
            "id": "01",
            "name": "Rashmika",
            "Img": "https://1847884116.rsc.cdn77.org/tamil/home/rashmika180224_1.jpg",
            "Age": "29",
            "Registerid": "07823",
        }, 
        {
            "id": "02",
            "name": "SreeLela",
            "Img": "https://szcdn.ragalahari.com/oct2023/hd/sreeleela-at-bk-press-meet/sreeleela-at-bk-press-meetthumb.jpg",
            "Age": "27",
            "Registerid": "42578",
           
        },
         {
            "id": "03",
            "name": "keerthi Suresh",
            "Img": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ90j05soMKHq4pCE6hOcDd1ofnGnoGm96ZQ0605DBBH3AGtCCYNGmMDqCD4kzpwMQcjwA&usqp=CAU",
            "Age": "39",
            "Registerid": "001823",
        }, 
             {
            "id": "04",
            "name": "Pooja Hegde",
            "Img": "https://c4.wallpaperflare.com/wallpaper/260/892/975/pooja-hegde-women-actress-model-indian-hd-wallpaper-preview.jpg",
            "Age": "29",
            "Registerid": "2593",
        },
         {
            "id": "05",
            "name": "Kajal",
            "Img": "https://5.imimg.com/data5/SELLER/Default/2021/2/LN/PY/LZ/116295057/02f6b8cd-eb8c-453d-8c3d-8830f6c44475-500x500.jpg",
            "Age": "27",
            "Registerid": "1235",
        },
        {
            "id": "06",
            "name": "Anushka",
            "Img": "https://cdn.gulte.com/wp-content/uploads/2023/09/ANushka-1.jpg",
            "Age": "39",
            "Registerid": "1236",
        },      {
            "id": "07",
            "name": "Kriti shetty",
            "Img": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQE3-EV7LpwNvgr8FZ5tYYuhaAkQEBReSv2lRgXSHSzAg&s",
            "Age": "29",
            "Registerid": "1234",
        },
        {
            "id": "08",
            "name": "disha patani",
            "Img": "https://images.hindustantimes.com/rf/image_size_630x354/HT/p2/2018/04/01/Pictures/bollywood-interview-hindustan-hindustan-prom1otion-interview-bollywood_a966a46e-35ae-11e8-8c5f-3c6cc031651e.JPG",
            "Age": "27",
            "Registerid": "1235",
        },
        {
        
            "id": "09",
            "name": "Samantha",
            "Img": "https://english.janamtv.com/wp-content/uploads/2023/06/image_1681298596.webp",
            "Age": "39",
            "Registerid": "1236",
        },
        {
            "id": "10",
            "name": "Rakul preet",
            "Img": "https://resizing.flixster.com/-XZAfHZM39UwaGJIFWKAE8fS0ak=/v3/t/assets/782171_v9_ba.jpg",
            "Age": "39",
            "Registerid": "1236",
        },
        {
            "id": "11",
            "name": "Lavanya Tripati",
            "Img": "https://assets.thehansindia.com/h-upload/2023/06/01/1354435-lavanya-tripathi-wiki-age-family-husband-movies-biography.webp",
            "Age": "29",
            "Registerid": "1234",
        }, 
        {
            "id": "12",
            "name": "Sai Pallavi",
            "Img": "https://1847884116.rsc.cdn77.org/malayalam/gallery/actress/saipallavi7715/saipallavi11072022_089.jpg",
            "Age": "27",
            "Registerid": "1235",
        },
        {
            "id": "13",
            "name": "Eesha Rebba",
            "Img": "https://d1vzdswwroofzl.cloudfront.net/wp-content/uploads/2023/09/Copy-of-Web-pic-1-2.png",
            "Age": "39",
            "Registerid": "1236",
        },    
          {
            "id": "14",
            "name": "Ritu Varma",
            "Img": "https://pbs.twimg.com/media/F8sx2v6acAAY6Rg?format=jpg&name=large",
            "Age": "29",
            "Registerid": "1234",
        }
    ]

    const settingsStyle = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        autoplay: true,
        PauseOnHover: true,
        autoplaySpeed: 1000,
        touchMove: true,
        centerMode: true,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
        
      };



  const [likedItems, setLikedItems] = useState(new Array(data.length).fill(false));

  const handleLikeClick = (index) => {
    const updatedLikedItems = [...likedItems];
    updatedLikedItems[index] = !updatedLikedItems[index];
    setLikedItems(updatedLikedItems);
  };
  //  ---------------------------------------pop up------------------------------
  const [isOpen, setIsOpen] = useState(false);
// ------------------------------------------------------------------------------

    return (
        <>

         <h2 className='profilecard-title'>Profiles</h2>
         <br/>

         <Slider {...settingsStyle} className='single-card-profile-container'> 
{data.map((eachitem, index) => (
 <div className='single-card-profile-card' key={eachitem.id}> 
     
     <img src={eachitem.Img} onClick={() => setIsOpen(!isOpen)} className='single-card-profile-image'/>



     <div style={{display:'flex', justifyContent:'space-around', width:'100%', position:'relative', marginTop:'-35px'}}>
               <FontAwesomeIcon
                icon={faHeart}
                className={`single-card-profile-heart ${likedItems[index] ? 'red' : ''}`}
                onClick={() => handleLikeClick(index)}
              />
            <Link to='Chatapp'><i class="ri-chat-1-line single-card-profile-heart"/> </Link>
             </div>

     <h6 className='single-card-profile-name'>{eachitem.name} </h6>
     <div className='single-card-profile-head'>
         <span className='single-card-profile-icn'>
             <i style={{fontStyle:'normal'}}>Age :</i>
             <b>{eachitem.Age}</b>
         </span>
         <span className='single-card-profile-icn'>
             <i  style={{fontStyle:'normal'}}>#ID :</i>
             <b style={{color:'blue'}}>{eachitem.Registerid}</b>    
         </span>
     </div>
     <Link to={`Singleview/${eachitem.id}`}><button className='single-card-profile-btn'>View Profile</button></Link> 
 </div>
 ))}
</Slider>

{/* ----------------------------------------------Pop up------------------------------------- */}
{
  isOpen ? <div className='profile-card-pop'onClick={() =>setIsOpen(!isOpen) }  >

 <div className='profilecard-popup-container ' onClick={(e) =>e.stopPropagation() } >

  <div className='profilecard-popup-heading' >
    <h5 className='pop-up-head'>Upgrade Your Plan </h5>
    {/* <img src='https://cdn-icons-png.freepik.com/256/32/32178.png?ga=GA1.1.975600797.1700133715&'className='close-icon' 
    onClick={() =>setIsOpen(!isOpen) } /> */}
  </div>
  <div className='profilecard-popup-body' >
    <div className='profilecard-closeicon'>
      <img src='https://img.freepik.com/free-vector/red-exclamation-mark-symbol-attention-caution-sign-icon-alert-danger-problem_40876-3505.jpg?size=626&ext=jpg&ga=GA1.1.975600797.1700133715&semt=ais'
      className='error-image'/>
    </div>
    <p>If You Need to See the Profiles Upgrade Your plan !</p>
  </div>
  <div className='profilecard-popup-footer'>
  <button className='profilecard-upgrade-btn' > <Link to='/Payment'style={{color:'black'}} > Upgrade Now</Link> </button> 
  </div>
      
 </div>

  </div>:null
}
  
</>
    )
}

export default ProfileCards;