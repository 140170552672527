import React, { useState,useEffect } from 'react';
import './Review.css'; 
import { useNavigate } from 'react-router-dom';

const ReviewCards = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };


  const [data,setData]=useState([]);
 
 
    useEffect(()=>{
 
      const fetchData=async()=>{
       const response =await fetch('https://matrimonyadmin.hola9.com/api/crud/reviewapi/');
       const result =await response.json();
       setData(result)
      }
      fetchData();
 
    })

  return (
    <div>

      <button onClick={goBack} className='back-button-pay'>Go Back</button>
    <h1 className='customer-review'>Our Customer Review</h1>

    {/*---------------------------------------------------------------------------------------------------------------------------------  */}
    <div className="review-cards-container">
      {data.map((review, index) => (
        <div className="review-card" key={index} onClick={() => navigate(`/Reviewsinglecard/${review.id}`)}> 
          <div className="review-header">
            <img src={`https://matrimonyadmin.hola9.com/${review.profile_image}`} alt="User" className="user-image" />
          </div>
          <div className="review-body">
            <p>{review.descriptions}</p>
            <div className='border-user'>
            <div className="user-details">
              <h3 className='review-name'>{review.name}</h3>
              <p>{review.email}</p>
            </div>
           </div>
            <div className="rating">
              {Array.from({ length: 5 }).map((_, index) => (
                <span
                  key={index}
                  className={index < review.rating ? 'filled-star' : 'empty-star'}
                >
                    &#9733;
                  </span>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* --------------------------------------------------------------------------------------------------------------------------------------------- */}
    </div>
  );
};


export default ReviewCards;
