


import React, { useState } from 'react';
import "./Usersetting.css";

const Usersetting = () => {
  const [selectedTab, setSelectedTab] = useState('mobile');
  const [phonePrivacy, setPhonePrivacy] = useState('all'); 
  const [horoscopePrivacy, setHoroscopePrivacy] = useState('visibleToAll'); 
  const [profilePrivacy, setProfilePrivacy] = useState('showViewed');
  const [privacySettingsVisible, setPrivacySettingsVisible] = useState(false); // State for visibility of privacy settings
  const [profileSettingsVisible, setProfileSettingsVisible] = useState(false);
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const handleAddPhotoClick = () => {
    alert('Add Photo clicked!');
  };

  const handlePhonePrivacyChange = (option) => {
    setPhonePrivacy(option);
  };

  const saveSettings = () => {
    alert('Settings saved!');
  };

  const handleHoroscopePrivacyChange = (option) => {
    setHoroscopePrivacy(option);
  };

  const handleProfilePrivacyChange = (option) => {
    setProfilePrivacy(option);
  };

  const togglePrivacySettings = () => {
    setPrivacySettingsVisible(!privacySettingsVisible);
  };
  
  
  const toggleProfileSettings = () => {
    setProfileSettingsVisible(!profileSettingsVisible);
  };
  const handleEditClick = (field) => {
    // Placeholder logic for handling edit clicks
    console.log(`Editing ${field}`);
  };



  return (
    <div className="settings-container">

      <div className="my-settings-container">
      <div className="my-settings-container-pro">
      <div className='set-btn'>
        <h2 className='privacy-setting-pro'>Profile Settings  <button className='pri-btn' onClick={toggleProfileSettings}>
          {profileSettingsVisible ? '-' : '+'}
        </button></h2>
        </div>
      
        {profileSettingsVisible && (
             
         
          <div>
          <table className="profile-table">
            <tbody>
              <tr>
                <td className="info-label">Phone Number:</td>
                <td>4567890321</td>
                <td className="edit-cell" onClick={() => handleEditClick('phoneNumber')}>✏️edit</td>
              </tr>
              <tr>
                <td className="info-label">Profile Status:</td>
                <td>Open</td>
                <td className="edit-cell" onClick={() => handleEditClick('profileStatus')}>✏️edit</td>
              </tr>
              <tr>
                <td className="info-label">Email ID:</td>
                <td>ffusetkg@gmail.com</td>
                <td className="edit-cell" onClick={() => handleEditClick('email')}>✏️edit</td>
              </tr>
              <tr>
                <td className="info-label">Password:</td>
                <td>tydtdyr56</td>
                <td className="edit-cell" onClick={() => handleEditClick('password')}>✏️edit</td>
              </tr>
            </tbody>
          </table>
        </div>
        
               
              )}

           </div>  
           
           <div className="my-settings-container-pro">
        <div className='set-btn'>
        <h2 className='privacy-setting'>Privacy Settings  <button className='pri-btn' onClick={togglePrivacySettings}>
          {privacySettingsVisible ? '-' : '+'}
        </button></h2>

       
        </div>

        {/* Only render privacy settings if visibility state is true */}
        {privacySettingsVisible && (
          <div>
            <div className="buttons-container">
              <button onClick={() => handleTabClick('mobile')}>Mobile</button>
              <button onClick={() => handleTabClick('photo')}>Photo</button>
              <button onClick={() => handleTabClick('horoscope')}>Horoscope</button>
              <button onClick={() => handleTabClick('profile')}>Profile</button>
            </div>

            
            {selectedTab === 'mobile' && (
        <div className="content-container">
          <h3 className="photo-head1">Show Phone Numbers</h3>
       
        
<div className="phone-privacy-options">
  <input
  className='radio-setting-btn'
    type="radio"
    id="allPaidMembers"
    name="phonePrivacy"
    value="all"
    checked={phonePrivacy === 'all'}
    onChange={() => handlePhonePrivacyChange('all')}
  />
  <label className='label-setting' htmlFor="allPaidMembers">All Paid Members</label>

  <input
    className='radio-setting-btn'
    type="radio"
    id="communityMembers"
    name="phonePrivacy"
    value="community"
    checked={phonePrivacy === 'community'}
    onChange={() => handlePhonePrivacyChange('community')}
  />
  <label className='label-setting' htmlFor="communityMembers">Paid Members from My Community</label>

  <input
    className='radio-setting-btn'
    type="radio"
    id="contactedMembers"
    name="phonePrivacy"
    value="contacted"
    checked={phonePrivacy === 'contacted'}
    onChange={() => handlePhonePrivacyChange('contacted')}
  />
  <label className='label-setting'  htmlFor="contactedMembers">Paid Members Whom I Contacted/Responded To</label>

  <input
    className='radio-setting-btn'
    type="radio"
    id="hideNumbers"
    name="phonePrivacy"
    value="hide"
    checked={phonePrivacy === 'hide'}
    onChange={() => handlePhonePrivacyChange('hide')}
  />
  <label className='label-setting' htmlFor="hideNumbers">Don't Show Phone Numbers (Hide Contact Details)</label>
</div>
{/* <p className='save-par'>Control who can see your phone number. Choose an option :</p> */}

<button onClick={saveSettings} className="save-btn" 
             
            >Save Settings</button>

</div>
)}
       

      {selectedTab === 'photo' && (
        <div className="content-container">
         
          <h3 className="photo-head2">Photo Settings</h3>
      
          {/* <p className='photo-use' >
            Photos are the first thing matches look for in a profile. Adjust your photo settings below.
          </p> */}

          <button className="save-button" onClick={handleAddPhotoClick} 
             
            >Add Photos</button>
              {/* <p className='.photo-use' >
            Photos are the first thing matches look for in a profile. Adjust your photo settings below.
          </p> */}
        </div>
      )}

      {selectedTab === 'horoscope' && (
        <div className="content-container">
          <h3 className="photo-head3">who can view my Horoscope </h3>
          <div className="horoscope-privacy-options">
            <input
              className='radio-setting-btn'
              type="radio"
              id="visibleToAll"
              name="horoscopePrivacy"
              value="visibleToAll"
              checked={horoscopePrivacy === 'visibleToAll'}
              onChange={() => handleHoroscopePrivacyChange('visibleToAll')}
            />
            <label className='label-setting' htmlFor="visibleToAll">Visible to All</label>

            <input
              className='radio-setting-btn'
              type="radio"
              id="contactedMembers"
              name="horoscopePrivacy"
              value="contactedMembers"
              checked={horoscopePrivacy === 'contactedMembers'}
              onChange={() => handleHoroscopePrivacyChange('contactedMembers')}
            />
            <label className='label-setting' htmlFor="contactedMembers">Visible only to members whom I had contacted/responded</label>

            <input
              className='radio-setting-btn'
              type="radio"
              id="protectHoroscope"
              name="horoscopePrivacy"
              value="protectHoroscope"
              checked={horoscopePrivacy === 'protectHoroscope'}
              onChange={() => handleHoroscopePrivacyChange('protectHoroscope')}
            />
            <label className='label-setting' htmlFor="protectHoroscope">Protect Horoscope</label>
          </div>
          {/* <p className='save-par'>allow horoscope access to only members of your choice:</p> */}
          <button onClick={saveSettings} className="save-btn">Save Settings</button>
        </div>
        
      )}
        
      

      {selectedTab === 'profile' && (
        <div className="content-container">
          <h3 className="photo-head4">Profile Settings</h3>
          <div className="profile-privacy-options-user">
            <input
              className='radio-setting-btn'
              type="radio"
              id="showViewed"
              name="profilePrivacy"
              value="showViewed"
              checked={profilePrivacy === 'showViewed'}
              onChange={() => handleProfilePrivacyChange('showViewed')}
            />
            <label className='label-setting' htmlFor="showViewed">Show Phone Numbers for Viewed Profiles</label>

            <input
              className='radio-setting-btn'
              type="radio"
              id="hideViewed"
              name="profilePrivacy"
              value="hideViewed"
              checked={profilePrivacy === 'hideViewed'}
              onChange={() => handleProfilePrivacyChange('hideViewed')}
            />
            <label className='label-setting' htmlFor="hideViewed">Don't Show Phone Numbers for Viewed Profiles</label>
          </div>

          <button onClick={saveSettings} className="save-btn">Save Settings</button>
        </div>
      )}
          </div>
        )}
       

      </div>
      </div>
    </div>
  );
};

export default Usersetting;



