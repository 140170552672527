
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import './SuccessDetailstyle.css';;
// import NewDashboard from '../NewDashboard/NewDashboard';
// import Footer from '../Footer/Footer';
// import { Stack, Skeleton, Avatar } from '@mui/material';

const SuccessDetail = () => {
  const [stories, setStories] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStories = async () => {
      try {
        const response = await fetch('https://matrimonyadmin.hola9.com/api/Successtoriesapi/');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setStories(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching success stories:', error);
        setError('Error fetching success stories');
        setLoading(false);
      }
    };

    fetchStories();
  }, []);

  return (
    <div>
      
      <h1>Success Stories</h1>
      <div className='main-card1'>
        {stories.map(story => (
          <Link to={`/success/${story.id}`} key={story.id} className='main-card2'>
            <div className="card">
              <img src={story.image} height="250px" alt={story.Title}/>
              <div className="card__content">
                <p className="card__title">{story.Title}</p>
                <p className="card__description">{story.Description}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
      
     
     
      
    
    </div>
  );
};

export default SuccessDetail;



