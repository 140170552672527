import React, { useState, useRef, useEffect } from "react";
import "./GlobalSearch.css";
import { useNavigate } from "react-router-dom";
import { stateOptions } from "./GlobalSearchdata";
import { occupationOptions } from "./GlobalSearchdata";
import { casteOptions } from "./GlobalSearchdata";

const GlobalSearch = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const [occupationSearchTerm, setOccupationSearchTerm] = useState("");
  const [selectedOccupation, setSelectedOccupation] = useState("");
  const [casteSearchTerm, setCasteSearchTerm] = useState("");
  const [selectedCaste, setSelectedCaste] = useState("");
  const [stateSearchTerm, setStateSearchTerm] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [formError, setFormError] = useState({
    occupation: "",
    caste: "",
    state: "",
  });
  const occupationDropdownRef = useRef(null);
  const casteDropdownRef = useRef(null);
  const stateDropdownRef = useRef(null);
  const [isOccupationDropdownOpen, setIsOccupationDropdownOpen] =
    useState(false);
  const [isCasteDropdownOpen, setIsCasteDropdownOpen] = useState(false);
  const [isStateDropdownOpen, setIsStateDropdownOpen] = useState(false);

  const handleOccupationInputChange = (event) => {
    setOccupationSearchTerm(event.target.value);
  };

  const handleCasteInputChange = (event) => {
    setCasteSearchTerm(event.target.value);
  };

  const handleStateInputChange = (event) => {
    setStateSearchTerm(event.target.value);
  };

  const handleSelectChange = (value, type) => {
    if (type === "Occupation") {
      setSelectedOccupation(value);
      setIsOccupationDropdownOpen(false);
    } else if (type === "Caste") {
      setSelectedCaste(value);
      setIsCasteDropdownOpen(false);
    } else if (type === "State") {
      setSelectedState(value);
      setIsStateDropdownOpen(false);
    }
  };

  const filteredOccupationOptions = occupationOptions.filter((option) =>
    option.toLowerCase().includes(occupationSearchTerm.toLowerCase())
  );

  const filteredCasteOptions = casteOptions.filter((option) =>
    option.toLowerCase().includes(casteSearchTerm.toLowerCase())
  );

  const filteredStateOptions = stateOptions.filter((option) =>
    option.toLowerCase().includes(stateSearchTerm.toLowerCase())
  );

  const toggleDropdown = (type) => {
    switch (type) {
      case "Occupation":
        setIsOccupationDropdownOpen(!isOccupationDropdownOpen);
        break;
      case "Caste":
        setIsCasteDropdownOpen(!isCasteDropdownOpen);
        break;
      case "State":
        setIsStateDropdownOpen(!isStateDropdownOpen);
        break;
      default:
        break;
    }
  };

  const handleClickOutside = (event) => {
    if (
      occupationDropdownRef.current &&
      !occupationDropdownRef.current.contains(event.target)
    ) {
      setIsOccupationDropdownOpen(false);
    }
    if (
      casteDropdownRef.current &&
      !casteDropdownRef.current.contains(event.target)
    ) {
      setIsCasteDropdownOpen(false);
    }
    if (
      stateDropdownRef.current &&
      !stateDropdownRef.current.contains(event.target)
    ) {
      setIsStateDropdownOpen(false);
    }
  };

  const ages = Array.from({ length: 48 }, (_, index) => index + 18);

  const heights = [
    "4Ft",
    "4Ft 1 inch",
    "4Ft 2 inch",
    "4Ft 3 inch",
    "4Ft 4 inch",
    "4Ft 5 inch",
    "4Ft 6 inch",
    "4Ft 7 inch",
    "4Ft 8 inch",
    "4Ft 9 inch",
    "4Ft 10 inch",
    "4Ft 11 inch",
    "5Ft",
    "5Ft 1 inch",
    "5Ft 2 inch",
    "5Ft 3 inch",
    "5Ft 4 inch",
    "5Ft 5 inch",
    "5Ft 6 inch",
    "5Ft 7 inch",
    "5Ft 8 inch",
    "5Ft 9 inch",
    "5Ft 10 inch",
    "5Ft 11 inch",
    "6Ft",
    "6Ft 1 inch",
    "6Ft 2 inch",
    "6Ft 3 inch",
    "6Ft 4 inch",
    "6Ft 5 inch",
    "6Ft 6 inch",
    "6Ft 7 inch",
    "6Ft 8 inch",
    "6Ft 9 inch",
    "6Ft 10 inch",
    "6Ft 11 inch",
    "7Ft",
  ];

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <section
        className="page-title"
        style={{ backgroundColor: "black", marginTop: "-40px" }}
      >
        <div className="auto-containerG1">
          <h1
            style={{ color: "white", marginLeft: "-600px" }}
            className="d-noneg d-lg-blockg1 d-xl-blockG1 d-md-blockG1"
          >
            Global Search
          </h1>
        </div>
      </section>
      <button onClick={goBack} className="back-button-pay">
        <i class="ri-arrow-left-line"></i>
      </button>{" "}
      {/* ........................<Let's Begin Start>.......................... */}
      <section className="newsletter-sectionG1 contact-page-sectionG1">
        <div className="auto-containerG1">
          <div className="alertmesG1"></div>
          <div className="rowG1 clearfixG1 mt-3G1">
            {/* Form Column */}
            <div className="form-columnG1 col-lg-12G1 col-md-12G1 col-sm-12G1">
              <div className="envelope-image" />
              <div className="inner-columnG1">
                <div className="contact-formG1">
                  <div className="rowG1 clearfixG1 mt-3G1">
                    <div className="col-lg-3G1 col-md-5G1 col-sm-6G1 form-groupG1">
                      <label className="label-Global"> Select Gender</label>
                      <select
                        className="custom-select-boxG1"
                        name="Marital_Status"
                        required=""
                        tabIndex={8}
                      >
                        <option value="" selected="">
                          Select Gender
                        </option>
                        <option value="Unmarried">Male</option>
                        <option value="Divorced">Female</option>
                      </select>
                      <span style={{ color: "red" }}></span>
                    </div>

                    <div className="col-lg-3G1 col-md-5G1 col-sm-6G1 form-groupG1">
                      <label className="label-Global"> Age from</label>
                      <select
                        className="custom-select-boxG1"
                        autofocus=""
                        name="Age_From"
                        required=""
                        tabIndex={5}
                      >
                        <option value="" selected="">
                          Select Age From
                        </option>
                        <option value="" selected>
                          Select Age From
                        </option>
                        {ages.map((age) => (
                          <option key={age} value={age}>
                            {age}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}></span>
                    </div>
                    <div className="col-lg-3G1 col-md-5G1 col-sm-6G1 form-groupG1">
                      <label className="label-Global"> Age to </label>
                      <select
                        className="custom-select-boxG1"
                        name="Age_To"
                        required=""
                        tabIndex={6}
                      >
                        <option value="" selected="">
                          Select Age To
                        </option>
                        <option value="" selected>
                          Select Age From
                        </option>
                        {ages.map((age) => (
                          <option key={age} value={age}>
                            {age}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}></span>
                    </div>
                    <div className="col-lg-3G1 col-md-5G1 col-sm-6G1 form-groupG1">
                      <label className="label-Global">
                        Select Material Status
                      </label>
                      <select
                        className="custom-select-boxG1"
                        name="Height_From"
                      >
                        <option value="" selected="">
                          Select Material Status
                        </option>
                        <option value="Unmaried">Unmarried</option>
                        <option value="Divorced">Divorced</option>
                        <option value="widower">widower </option>
                      </select>
                      <span style={{ color: "red" }}></span>
                    </div>

                    <div className="col-lg-3G1 col-md-5G1 col-sm-6G1 form-groupG1">
                      <label className="label-Global">Religion</label>
                      <select
                        className="custom-select-boxG1"
                        name="Religion"
                        required=""
                        tabIndex={8}
                      >
                        <option value="" selected="">
                          select religion
                        </option>
                        <option value="Christian">Christian</option>
                        <option value="Hindu">Hindu</option>
                        <option value="hindu Barahsaini">
                          hindu Barahsaini
                        </option>
                        <option value="Inter-Religion">Inter-Religion</option>
                        <option value="Jain">Jain</option>
                        <option value="Muslim">Muslim</option>
                        <option value="Sikh">Sikh</option>{" "}
                      </select>
                      <span style={{ color: "red" }}></span>
                    </div>

                    <div className="col-lg-3G1 col-md-5G1 col-sm-6G1 form-groupG1">
                      <label className="label-Global"> Education</label>
                      <select
                        className="custom-select-boxG1"
                        name="Education"
                        required=""
                        tabIndex={8}
                      >
                        <option value="" selected="">
                          Select Education
                        </option>
                        <option value="12th">12th</option>
                        <option value="B.A. (Arts)		">B.A. (Arts) </option>
                        <option value="B.Com (Commerce)">
                          B.Com (Commerce)
                        </option>
                        <option value="B.Sc (Science)">B.Sc (Science)</option>
                        <option value="B.Arch (Architecture)	">
                          B.Arch (Architecture){" "}
                        </option>
                        <option value="B.Ed (Education)	">
                          B.Ed (Education){" "}
                        </option>
                        <option value="B.El.Ed (Elementary Education)	">
                          B.El.Ed (Elementary Education){" "}
                        </option>
                        <option value="B.Lib.Sc (Library Sciences)	">
                          B.Lib.Sc (Library Sciences){" "}
                        </option>
                        <option value="B.P.Ed. (Physical Education)">
                          B.P.Ed. (Physical Education)
                        </option>
                        <option value="B.Plan (Planning)	">
                          B.Plan (Planning){" "}
                        </option>
                        <option value="	Bachelor of Fashion Technology		">
                          {" "}
                          Bachelor of Fashion Technology{" "}
                        </option>
                        <option value="	BBA/BBM/BBS	"> BBA/BBM/BBS </option>
                        <option value="BCA (Computer Application)		">
                          BCA (Computer Application){" "}
                        </option>
                        <option value="BE B.Tech (Engineering)">
                          BE B.Tech (Engineering)
                        </option>
                        <option value="BFA (Fine Arts)">BFA (Fine Arts)</option>
                        <option value="BHM (Hotel Management)	">
                          BHM (Hotel Management){" "}
                        </option>
                        <option value="BL/LLB/BGL (Law)">
                          BL/LLB/BGL (Law)
                        </option>
                        <option value="BSW (Social Work)">
                          BSW (Social Work)
                        </option>
                        <option value="	B.Pharm (Pharmacy)		">
                          {" "}
                          B.Pharm (Pharmacy){" "}
                        </option>
                        <option value="	B.V.Sc. (Veterinary Science)">
                          {" "}
                          B.V.Sc. (Veterinary Science)
                        </option>
                        <option value="BDS (Dental Surgery)	">
                          BDS (Dental Surgery){" "}
                        </option>
                        <option value="BHMS (Homeopathy)">
                          BHMS (Homeopathy)
                        </option>
                        <option value="CA (Chartered Accountant)	">
                          CA (Chartered Accountant){" "}
                        </option>
                        <option value="CFA (Chartered Financial Analyst)">
                          CFA (Chartered Financial Analyst)
                        </option>
                        <option value="CS (Company Secretary)">
                          CS (Company Secretary)
                        </option>
                        <option value="ICWA">ICWA</option>
                        <option value="Integrated PG">Integrated PG</option>
                        <option value="Engineering">Engineering</option>
                        <option value="Fashion/ Design	">
                          Fashion/ Design{" "}
                        </option>
                        <option value="Languages	">Languages </option>
                        <option value="Pilot Licenses	">Pilot Licenses </option>
                        <option value="M.Arch. (Architecture)	">
                          M.Arch. (Architecture){" "}
                        </option>
                        <option value="M.Ed. (Education)">
                          M.Ed. (Education)
                        </option>
                        <option value="M.Lib.Sc. (Library Sciences)">
                          M.Lib.Sc. (Library Sciences)
                        </option>
                        <option value="M.Plan. (Planning)">
                          M.Plan. (Planning)
                        </option>
                        <option value="Master of Fashion Technology">
                          Master of Fashion Technology
                        </option>
                        <option value="Master of Health Administration	">
                          Master of Health Administration{" "}
                        </option>
                        <option value="Master of Hospital Administration">
                          Master of Hospital Administration
                        </option>
                        <option value="MBA/PGDM">MBA/PGDM</option>
                        <option value="MCA PGDCA part time	">
                          MCA PGDCA part time{" "}
                        </option>
                        <option value="MCA/PGDCA">MCA/PGDCA</option>
                        <option value="ME/M.Tech/MS (Engg/Sciences)">
                          ME/M.Tech/MS (Engg/Sciences)
                        </option>
                        <option value="MFA (Fine Arts)	">
                          MFA (Fine Arts){" "}
                        </option>
                        <option value="ML/LLM (Law)	">ML/LLM (Law) </option>
                        <option value="MSW (Social Work)	">
                          MSW (Social Work){" "}
                        </option>
                        <option value="PG Diploma">PG Diploma</option>
                        <option value="M.Com. (Commerce)">
                          M.Com. (Commerce)
                        </option>
                        <option value="M.Sc. (Science)">M.Sc. (Science)</option>
                        <option value="MA (Arts)">MA (Arts)</option>
                        <option value="M.Pharm. (Pharmacy)	">
                          M.Pharm. (Pharmacy){" "}
                        </option>
                        <option value="M.V.Sc. (Veterinary Science)">
                          M.V.Sc. (Veterinary Science)
                        </option>
                        <option value="MBBS">MBBS</option>
                        <option value="MD/ MS (Medicine)">
                          MD/ MS (Medicine)
                        </option>
                        <option value="MDS (Master of Dental Surgery)">
                          MDS (Master of Dental Surgery)
                        </option>
                        <option value="BPT (Physiotherapy">
                          BPT (Physiotherapy)
                        </option>
                        <option value="MPT (Physiotherapy)">
                          MPT (Physiotherapy)
                        </option>
                        <option value="M.Phil. (Philosophy)">
                          M.Phil. (Philosophy)
                        </option>
                        <option value="Ph.D. (Doctorate)	">
                          Ph.D. (Doctorate){" "}
                        </option>
                        <option value="Other Doctorate">Other Doctorate</option>
                        <option value="Other Diploma">Other Diploma</option>
                        <option value="Agriculture">Agriculture</option>
                        <option value="10th">10th</option>{" "}
                      </select>
                      <span style={{ color: "red" }}></span>
                      {/* <div className="btn-group" style={{ width: 345 }}>
                  
                    </div> */}
                      {/* </span> */}
                    </div>
                    <div className="col-lg-3G1 col-md-5G1 col-sm-6G1 form-groupG1">
                      <label className="label-Global">Occupation</label>
                      <div
                        className="custom-dropdown-global"
                        ref={occupationDropdownRef}
                      >
                        <div
                          className="selected-option-global"
                          onClick={() => toggleDropdown("Occupation")}
                        >
                          {selectedOccupation
                            ? selectedOccupation
                            : "Select Occupation"}
                        </div>
                        {isOccupationDropdownOpen && (
                          <div className="dropdown-menu-global">
                            <input
                              type="text"
                              className="custom-select-box-global"
                              placeholder="Search Occupation"
                              value={occupationSearchTerm}
                              onChange={handleOccupationInputChange}
                              autoFocus
                            />
                            <div className="dropdown-options-global">
                              {filteredOccupationOptions.map(
                                (option, index) => (
                                  <div
                                    key={index}
                                    className="dropdown-option-global"
                                    onClick={() =>
                                      handleSelectChange(option, "Occupation")
                                    }
                                  >
                                    {option}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      <span style={{ color: "red" }}>
                        {formError.occupation}
                      </span>
                    </div>

                    <div className="col-lg-3G1 col-md-5G1 col-sm-6G1 form-groupG1">
                      <label className="label-Global"> Photo Preference</label>
                      <select
                        className="custom-select-boxG1"
                        name="Photo_Preference"
                        required=""
                        tabIndex={8}
                      >
                        <option value="" selected="">
                          Any
                        </option>
                        <option value="Unmarried">With Photo</option>
                        <option value="Divorced">Without Photo</option>
                      </select>
                      <span style={{ color: "red" }}></span>
                    </div>

                    <div className="col-lg-3G1 col-md-5G1 col-sm-6G1 form-groupG1">
                      <label className="label-Global">
                        {" "}
                        Select Marital Status
                      </label>
                      <select
                        className="custom-select-boxG1"
                        name="Marital_Status"
                        required=""
                        tabIndex={8}
                      >
                        <option value="" selected="">
                          Select Marital Status
                        </option>
                        <option value="Unmarried">Unmarried</option>
                        <option value="Divorced">Divorced</option>
                        <option value="Widower ">Widower</option>
                      </select>
                      <span style={{ color: "red" }}></span>
                    </div>

                    <div className="col-lg-3G1 col-md-5G1 col-sm-6G1 form-groupG1">
                      <label className="label-Global">Height from</label>
                      <select
                        className="custom-select-boxG1"
                        name="Height_From"
                      >
                       
                        <option value="" selected="">
                          Select Height From
                        </option>
                        {heights.map((height, index) => (
                          <option key={index} value={index + 1}>
                            {height}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}></span>
                    </div>

                    <div className="col-lg-3G1 col-md-5G1 col-sm-6G1 form-groupG1">
                      <label className="label-Global">Height to</label>
                      <select
                        className="custom-select-boxG1"
                        name="Height_To"
                        tabIndex={8}
                      >
                        <option value="" selected="">
                          Select Height To
                        </option>
                        {heights.map((height, index) => (
                          <option key={index} value={index + 1}>
                            {height}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}></span>
                    </div>

                    <div className="col-lg-3G1 col-md-5G1 col-sm-6G1 form-groupG1">
                      <label className="label-Global">Religion</label>
                      <select
                        className="custom-select-boxG1"
                        name="Religion"
                        required=""
                        tabIndex={8}
                      >
                        <option value="" selected="">
                          select religion
                        </option>
                        <option value="Christian">Christian</option>
                        <option value="Hindu">Hindu</option>
                        <option value="hindu Barahsaini">
                          hindu Barahsaini
                        </option>
                        <option value="Inter-Religion">Inter-Religion</option>
                        <option value="Jain">Jain</option>
                        <option value="Muslim">Muslim</option>
                        <option value="Sikh">Sikh</option>{" "}
                      </select>
                      <span style={{ color: "red" }}></span>
                    </div>

                    <div className="col-lg-3G1 col-md-5G1 col-sm-6G1 form-groupG1">
                      <label className="label-Global">Caste</label>
                      <div
                        className="custom-dropdown-global"
                        ref={casteDropdownRef}
                      >
                        <div
                          className="selected-option-global"
                          onClick={() => toggleDropdown("Caste")}
                        >
                          {selectedCaste ? selectedCaste : "Select Caste"}
                        </div>
                        {isCasteDropdownOpen && (
                          <div className="dropdown-menu-global">
                            <input
                              type="text"
                              className="custom-select-box-global"
                              placeholder="Search Caste"
                              value={casteSearchTerm}
                              onChange={handleCasteInputChange}
                              autoFocus
                            />
                            <div className="dropdown-options-global">
                              {filteredCasteOptions.map((option, index) => (
                                <div
                                  key={index}
                                  className="dropdown-option-global"
                                  onClick={() =>
                                    handleSelectChange(option, "Caste")
                                  }
                                >
                                  {option}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                      <span style={{ color: "red" }}>{formError.caste}</span>
                    </div>

                    <div className="col-lg-3G1 col-md-5G1 col-sm-6G1 form-groupG1">
                      <label className="label-Global"> Complexion</label>
                      <select
                        className="custom-select-boxG1"
                        name="Complexion"
                        tabIndex={8}
                      >
                        <option value="" selected="">
                          Select Complexion
                        </option>

                        <option value="Very Fair">Very Fair</option>
                        <option value="Fair">Fair</option>
                        <option value="Wheatish">Wheatish</option>
                        <option value="Wheatish Medium">Wheatish Medium</option>
                        <option value="Wheatish Brown">Wheatish Brown</option>
                        <option value="Dark">Dark</option>
                      </select>
                      <span style={{ color: "red" }}></span>
                    </div>

                    <div className="col-lg-3G1 col-md-5G1 col-sm-6G1 form-groupG1">
                      <label className="label-Global"> Residency Status</label>
                      <select
                        className="custom-select-boxG1"
                        name="Residency_Status"
                        required=""
                        tabIndex={8}
                      >
                        <option value="" selected="">
                          Select Residency Status
                        </option>
                        <option value="Permanent Resident">
                          Permanent Resident
                        </option>
                        <option value="Student Visa">Student Visa</option>
                        <option value="Temporary Visa">Temporary Visa</option>
                        <option value="Work Permit">Work Permit</option>{" "}
                      </select>
                      <span style={{ color: "red" }}></span>
                    </div>

                    <div className="col-lg-3G1 col-md-5G1 col-sm-6G1 form-groupG1">
                      <label className="label-Global"> Country</label>
                      <select
                        className="custom-select-boxG1"
                        name="Country"
                        required=""
                        tabIndex={8}
                      >
                        <option value="" selected="">
                          Select Country
                        </option>
                        <option value="India" selected="">
                          India
                        </option>
                      </select>
                      <span style={{ color: "red" }}></span>
                    </div>

                    <div className="col-lg-3G1 col-md-5G1 col-sm-6G1 form-groupG1">
                      <label className="label-Global">State</label>
                      <div
                        className="custom-dropdown-global"
                        ref={stateDropdownRef}
                      >
                        <div
                          className="selected-option-global"
                          onClick={() => toggleDropdown("State")}
                        >
                          {selectedState ? selectedState : "Select State"}
                        </div>
                        {isStateDropdownOpen && (
                          <div className="dropdown-menu-global">
                            <input
                              type="text"
                              className="custom-select-box-global"
                              placeholder="Search State"
                              value={stateSearchTerm}
                              onChange={handleStateInputChange}
                              autoFocus
                            />
                            <div className="dropdown-options-global">
                              {filteredStateOptions.map((option, index) => (
                                <div
                                  key={index}
                                  className="dropdown-option-global"
                                  onClick={() =>
                                    handleSelectChange(option, "State")
                                  }
                                >
                                  {option}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                      <span style={{ color: "red" }}>{formError.state}</span>
                    </div>

                    <div
                      className="col-lg-10G1 col-md-10G1 col-sm-6G1"
                      // style={{ marginTop: "50px" }}
                    >
                      <br></br>
                      <center style={{ paddingLeft: "20%" }}>
                        <button
                          className="theme-btnG1 btn btn-style-oneG1 mt-4G1"
                          type="submit"
                          name="submit"
                          // style={{marginLeft:"110px"}}
                        >
                          <span className="btn-titleG1" tabIndex={26}>
                            Search
                          </span>
                        </button>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ........................<Update End>.......................... */}
    </div>
  );
};

export default GlobalSearch;

//  converted 2300 line code to 700 lines
