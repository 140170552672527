import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import './BlogsDetails.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';

const BlogDetails = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    fetch('https://matrimonyadmin.hola9.com/api/profileapi/blog-posts/')
      .then(response => response.json())
      .then(data => {
        setBlogPosts(data);
        setLoading(false); 
      })
      .catch(error => {
        console.error('Error fetching blog posts:', error);
        setLoading(false); 
      });
  }, []);

  return (
    <>
    
      <div className="blog-details-loader">
        {loading ? (
          <div className="skeleton-cards-container">
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
          </div>
        ) : (
          blogPosts.map(post => (
            <div className="blog-details-card" key={post.id}>
              <img className='blog-details-image' src={post.image} alt="blog-image" height="300px" width="300px" />
              <div className="blog-details-info">
                <h2 className="blog-details-title">{post.title}</h2>
                <p className="blog-details-date">Date of Publish: {post.date_of_publish}</p>
                <p className="blog-details-description">{post.description}</p>
                <p className='blog-details-description-p'>
                   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua".
                 </p>
                 <div className="blog-details-author">
                  <img src='https://totallyhistory.com/wp-content/uploads/2013/07/Salman-rushdie.jpg' className='author-image' alt="Author" />    
                  <p className="author-name">Author: {post.author_name}</p>
                    <p className="author-email">Email: {post.author_email}</p>
                    </div>
                <Link to={`/blog/${post.id}`} className="view-more-link">View More</Link> {/* Add Link with post ID */}
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

const SkeletonCard = () => {
  return (
    <Card className="blog-details-card">
      <Skeleton variant="rectangular" width={300} height={300} />
      <CardContent>
        <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
        <Skeleton animation="wave" height={10} width="80%" />
        <Skeleton animation="wave" height={10} width="60%" />
        <Skeleton animation="wave" height={10} width="40%" />
      </CardContent>
    </Card>
  );
};

export default BlogDetails;
