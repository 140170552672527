import React, { useState } from 'react';
import "./PaymentStyle.css";
import { Link } from "react-router-dom";
import { MdVerifiedUser } from 'react-icons/md';
import Footer from '../Footer/Footer';
import Header from '../Homepage/Header';
import { useNavigate } from 'react-router-dom';
import { Switch } from 'antd';
import { blue } from '@mui/material/colors';

// Define Payment component
const Payment = () => {
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState(null); // State to track selected plan
  const [paymentFrequency, setPaymentFrequency] = useState('monthly'); // State to track payment frequency
  const [toggle, setToggle] = useState(false);

  // Function to toggle payment frequency between 'monthly' and 'yearly'
  const toggleAmountDisplay = () => {
    setPaymentFrequency(prevFrequency => prevFrequency === 'monthly' ? 'yearly' : 'monthly');
  };

  // Function to navigate back
  const goBack = () => {
    navigate(-1);
  };

  // Function to handle selecting a plan
  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
  };

  // Define an array of objects containing card details
  const cards = [
    {
      title: "BASIC 1",
      priceMonthly: "40/mo",
      priceYearly: "120/yr",
      color: "#40cbb4",
      features: [
        "Profile Display",
        "Chating Option",
        "Express Interest",
        "Unlimited Profile Search",
        "Verified Phone Numbers",
        "Profile Up-gradation"
      ],
      contacts: "150"
    },
    {
      title: "STANDARD",
      priceMonthly: "399/mo",
      priceYearly: "789/yr",
      color: "#1d95d2",
      features: [
        "Profile Display",
        "Chating Option",
        "Express Interest",
        "Unlimited Profile Search",
        "Verified Phone Numbers",
        "Profile Up-gradation"
      ],
      contacts: "180"
    },
    {
      title: "PREMIUM",
      priceMonthly: "589/mo",
      priceYearly: "999/yr",
      color: "#ffc20b",
      features: [
        "Profile Display",
        "Chating Option",
        "Express Interest",
        "Unlimited Profile Search",
        "Verified Phone Numbers",
        "Profile Up-gradation"
      ],
      contacts: "270"
    }
    // Other card objects
  ];

  // Prevent default form submission
  const handleFormSubmit = (event) => {
    event.preventDefault();
    // Handle form submission
  };

  return (
    <>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
      />
      <Header /><br /><br /><br />
      <div className="page-wrapper">
        <div className="preloader" style={{ display: "none" }} />
        <section
          className="page-title-pay"
          style={{ backgroundImage: "url(images/background/5.jpg)" }}
        >
          <div className="auto-container-pay" style={{ backgroundColor: "black" }}>
            <h1 className='title-pay' >
              Membership Plan
            </h1>
          </div>
        </section>

        <button onClick={goBack} className='back-button-pay'><i className="ri-arrow-left-line"></i></button>

        {/* Toggle buttons */}
        <div className="tgl-btn">
        <div className="toggle-btn-btn">
        {paymentFrequency === 'monthly' ? <span>  Monthly   </span> : <span>  Monthly  </span>}
        <Switch onChange={toggleAmountDisplay} checked={paymentFrequency === 'yearly'}
           style={{ width: "20px",  }} />
          {paymentFrequency === 'yearly' ? <span>  Yearly   </span> : <span>  Yearly   </span>}
        </div>
        </div>

        <section className="pricing-section-pay">
          <div className="auto-container-pay">
            <div className="outer-box-pay">
              <div className="row-pay">
                {/* Map over the array of card objects */}
                {cards.map((card, index) => (
                  <div
                    key={index}
                    className="pricing-block-pay col-lg-4 col-md-4 col-sm-4 wow fadeInUp animated"
                    style={{ visibility: "visible", animationName: "fadeInUp" }}
                  >
                    <div className="inner-box-pay">
                      <div className="icon-box-pay">
                        <div className="icon-outer-pay">
                          <MdVerifiedUser fontSize={54} color={selectedPlan === index ? card.color : '#ccc'} className='mt-5' />
                        </div>
                      </div>
                      <div className="price-box-pay animate__animated animate__wobble">
                        <div className="title-1-pay">{card.title}</div>
                        <h4 className="price-pay">
                          $ : {paymentFrequency === 'monthly' ? card.priceMonthly : card.priceYearly} <i className="fa-pay fa-inr-pay" /> <br />
                          {card.days}{" "}
                        </h4>
                      </div>
                      <div className="btn-box-pay">
                        <button
                          type="button"
                          className={`theme-btn-apy ${selectedPlan === index ? "selected" : ""}`}
                          onClick={() => handleSelectPlan(index)}
                        >
                          Select Plan
                        </button>
                      </div>
                      <ul className="features-pay">
                        {card.features.map((feature, index) => (
                          <li key={index} className="true">{feature}</li>
                        ))}
                      </ul>
                      <p className="contacts-pay">
                        <span className="price-pay" style={{ fontSize: 15 }}>
                          Allowed Contacts/Address: {card.contacts}
                        </span>
                      </p>
                      <div className="btn-box-pay">
                        {selectedPlan === index && (
                          <button type="submit" className="theme-btn-pay">
                            <Link to="/PriceMethod">
                              <a children="a-pay">
                                <span className="btn-title-pay">Pay Now</span>
                              </a>
                            </Link>
                          </button>
                        )}
                      </div>
                      <input
                        name="txtplan"
                        type="hidden"
                        id="txtplan"
                        defaultValue="plan5"
                      />
                      <input
                        name="txtplanname"
                        type="hidden"
                        id="txtplanname"
                        defaultValue="Basic 1"
                      />
                      <input
                        name="txtoid"
                        type="hidden"
                        id="txtoid"
                        defaultValue="MP578069181"
                      />
                      <input type="hidden" name="id" defaultValue={1} />
                      <input
                        type="hidden"
                        name="pm"
                        defaultValue="op4"
                        className="formtext"
                        defaultChecked=""
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
      <div
        className="pricing-block-pay col-lg-12-pay col-md-12-pay col-sm-12-pay wow fadeInUp animated"
        style={{ visibility: "visible", animationName: "fadeInUp" }}
      >
        <div className="col-sm-12-pay">
          <div className="contact-grid1 text-center">
            <p> </p>
            <div>
              <span style={{ fontSize: 18 }}>
                <strong>Bank Details</strong>
              </span>
            </div>
            <div>&nbsp;</div>
            <div>
              <span style={{ fontSize: 16 }}>Bank Name: Union Bank Of India</span>
            </div>
            <div>
              <span style={{ fontSize: 16 }}>City: Sangli Maharashtra INDIA</span>
            </div>
            <div>
              <span style={{ fontSize: 16 }}>
                Current A/C Name: Infinity Systems
              </span>
            </div>
            <div>
              <span style={{ fontSize: 16 }}>

              
                Current A/C No:&nbsp;47070101028246
              </span>
            </div>
            <div>
              <span style={{ fontSize: 16 }}>IFSC Code:&nbsp;UBIN0547077</span>
            </div>
            <div>&nbsp;</div>
            <div>
              <span style={{ fontSize: 16 }}>
                <strong>
                  <span style={{ color: "#6699ff" }}>G-Pay: 7776938444</span>
                </strong>
              </span>
            </div>
            <div>
              <Link to="https://payu.in/invoice/8582BEDC6CC3BB6BD07AE654B08B200AB4E077BB1B5C9A3125CB81FCF45AECE4/254650D2457779EA22957CEDDCC3DA50">
                Online
              </Link>
            </div>
            <div>
              <br />
              &nbsp;
            </div>
            <div>&nbsp;</div>
            <p />
          </div>
        </div>
      </div>
    
    </>
  );
};
export default Payment;
