import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./EducationStyle.css";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { qualifications } from "./Educationdata";
import { occupations } from "./Educationdata";
import { annualIncomeOptions } from "./Educationdata";
import { employmentOptions } from "./Educationdata";
import { challengeOptions } from "./Educationdata";
import { initialData } from "./Educationdata";

const Education = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const [data, setData] = useState(initialData);
  const [showForm, setShowForm] = useState(false);
  const [error, setError] = useState(null);
  const [formError, setFormError] = useState({});

  const [filteredQualifications, setFilteredQualifications] =
    useState(qualifications);
  const [filteredOccupations, setFilteredOccupations] = useState(occupations);
  const qualificationRef = useRef(null);
  const occupationRef = useRef(null);

  const handleQualificationSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setFilteredQualifications(
      qualifications.filter((qualification) =>
        qualification.toLowerCase().includes(value)
      )
    );
  };

  const handleOccupationSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setFilteredOccupations(
      occupations.filter((occupation) =>
        occupation.toLowerCase().includes(value)
      )
    );
  };

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "https://matrimonyadmin.hola9.com/api/EducationDetails/educationDetail/",
        data
      );
      setShowForm(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        qualificationRef.current &&
        !qualificationRef.current.contains(event.target)
      ) {
        document
          .getElementById("qualification-list")
          .classList.remove("visible");
      }
      if (
        occupationRef.current &&
        !occupationRef.current.contains(event.target)
      ) {
        document.getElementById("occupation-list").classList.remove("visible");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = (dropdownId) => {
    document.getElementById(dropdownId).classList.toggle("visible");
  };

  return (
    <>
      <div className="page-wrapperEd">
        <div className="preloaderEd" style={{ display: "none" }} />
        <span className="header-spanEd" />
        <section
          className="page-title-Edu"
          style={{ backgroundColor: "black" }}
        >
          <div className="auto-containerEd">
            <h1 className="d-lg-blockEd d-xl-blockEd d-md-blockEd">
              Education
            </h1>
          </div>
        </section>
        <button onClick={goBack} className="back-button-pay">
          <i className="ri-arrow-left-line"></i>
        </button>
        <div className="edu-raw">
          <div className="rowEd clearfixEd">
            <div className="col-lg-3Ed col-md-5Ed col-sm-6Ed form-groupEd">
              <label className="labelEd">Qualification</label>
              <div className="searchable-dropdown" ref={qualificationRef}>
                <input
                  type="text"
                  className="select-qua"
                  placeholder="Select Qualification"
                  onClick={() => toggleDropdown("qualification-list")}
                  onChange={handleQualificationSearch}
                />
                <div id="qualification-list" className="dropdown-list">
                  <input
                    type="text"
                    className="search-search"
                    placeholder="Search..."
                    onChange={handleQualificationSearch}
                  />
                  {filteredQualifications.map((qualification, index) => (
                    <div
                      key={index}
                      onClick={() => setData({ ...data, qualification })}
                    >
                      {qualification}
                    </div>
                  ))}
                </div>
              </div>
              <span style={{ color: "red" }}>{formError.qualification}</span>
            </div>
            <div className="col-lg-3Ed col-md-5Ed col-sm-6Ed form-groupEd">
              <label className="labelEd">Occupation</label>
              <div className="searchable-dropdown" ref={occupationRef}>
                <input
                  type="text"
                  className="select-occu"
                  placeholder="Select Occupation"
                  onClick={() => toggleDropdown("occupation-list")}
                  onChange={handleOccupationSearch}
                />
                <div id="occupation-list" className="dropdown-list">
                  <input
                    type="text"
                    className="search-search"
                    placeholder="Search..."
                    onChange={handleOccupationSearch}
                  />
                  {filteredOccupations.map((occupation, index) => (
                    <div
                      key={index}
                      onClick={() => setData({ ...data, occupation })}
                    >
                      {occupation}
                    </div>
                  ))}
                </div>
              </div>
              <span style={{ color: "red" }}>{formError.occupation}</span>
            </div>
            <div className="col-lg-3Ed col-md-5Ed col-sm-6Ed form-groupEd">
              <label className="labelEd">Occupation Details</label>
              <input
                className="agile-ltextEd"
                placeholder="Enter Occupation Details"
                type="text"
                name="occupation_details"
                id="occupation_details"
                tabIndex={3}
                required
                value={data.occupation_details}
                onChange={handleChange}
              />
              <span style={{ color: "red" }}>
                {formError.occupation_details}
              </span>
            </div>
            <div className="col-lg-3Ed col-md-5Ed col-sm-6Ed form-groupEd">
              <label className="labelEd">Annual Income</label>
              <select className="annual-income">
                {annualIncomeOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <span style={{ color: "red" }}>{formError.annual_income}</span>
            </div>
            <div className="col-lg-3Ed col-md-5Ed col-sm-6Ed form-groupEd">
              <label className="labelEd">Employed In</label>
              <select
                className="agile-ltextEdd"
                name="employed_in"
                id="employed_in"
                tabIndex={5}
                required
                value={data.employed_in}
                onChange={handleChange}
              >
                {employmentOptions.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </option>
                ))}
              </select>
              <span style={{ color: "red" }}>{formError.employed_in}</span>
            </div>
            <div className="col-lg-3Ed col-md-5Ed col-sm-6Ed form-groupEd">
              <label className="labelEd">Working Location</label>
              <input
                className="agile-ltextEd"
                type="text"
                name="working_location"
                id="working_location"
                tabIndex={7}
                value={data.working_location}
                onChange={handleChange}
              />
              <span style={{ color: "red" }}>{formError.working_location}</span>
            </div>
          </div>
          <div className="col-lg-3Ed col-md-5Ed col-sm-6Ed form-groupEd">
            <label className="labelEd">
              <p className="special-case">Special Cases</p>
            </label>
            <select
              className="agile-ltextEd"
              name="special_cases"
              id="special_cases"
              tabIndex={6}
              value={data.special_cases}
              onChange={handleChange}
            >
              {challengeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="text-centerEd">
            <button type="submit" className="btnEd" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Education;
