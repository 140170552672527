import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faClone } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import './IdProofStyle.css';
import { FileUploader } from "react-drag-drop-files";

const fileTypes = ["JPG", "PNG", "GIF", "PDF"];

const IdProof = () => {
    const navigate = useNavigate();

    const [images1, setImages1] = useState([]);
    const [images2, setImages2] = useState([]);
    const [formData, setFormData] = useState({
        id_proof: null,
        passport_size_photo: null
    });
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showPopupIndex, setShowPopupIndex] = useState(null);

    const handleIconClick = (index) => {
        console.log("Icon clicked at index:", index);
        setShowPopupIndex(index);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event, setImages) => {
        event.preventDefault();
        event.stopPropagation();

        const files = Array.from(event.dataTransfer.files);
        console.log('Dropped files:', files);

        setImages(prevImages => [...prevImages, ...files]);
    };

    const handleFileChange = (file, fieldName) => {
        setFormData({
            ...formData,
            [fieldName]: file
        });
    };

    const handleSubmit = () => {
        if (!formData.id_proof || !formData.passport_size_photo) {
            setError('Please upload both ID proof and photo.');
            return;
        }

        setLoading(true);

        const formDataToSend = new FormData();
        formDataToSend.append('id_proof', formData.id_proof);
        formDataToSend.append('passport_size_photo', formData.passport_size_photo);

        axios.post('https://matrimonyadmin.hola9.com/api/crud/idproofapi/', formDataToSend)
            .then(response => {
                setLoading(false);
                setSubmitted(true);
                console.log('Form submitted successfully!');
                console.log('Response:', response.data);
            })
            .catch(error => {
                setLoading(false);
                setError('An error occurred while submitting the form. Please try again.');
                console.error('Error submitting form:', error);
            });
    };

    const goBack = () => {
        navigate(-1);
    };


    return (
        <div className='idmains'>
            <section className="page-title" style={{ backgroundColor: 'black', color: 'white', fontSize: '20px', fontWeight: '700', marginTop: '-40px' }}>
                <div className="auto-container-ID">
                    <h1 className="d-none d-lg-block d-xl-block d-md-block">Upload ID Proof & Photo</h1>
                    <ul className="bread-crumb clearfix">
                        <li>Upload ID Proof & Photo</li>
                    </ul>
                </div>
            </section>

            <button onClick={goBack} className='back-button-pay'><i className="ri-arrow-left-line"></i></button>
            <div className="auto-container-ID">
                {submitted ? (
                    <div>
                        <p>Form submitted successfully!</p>
                    </div>
                ) : (
                    <div className="out-box">
                        <div className="upload-file-id">Upload Files</div>
                        <div className='proof-new'>
                            <div
                                className="drop-zone"
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                                style={{ border: '2px dashed #ccc', padding: '20px', textAlign: 'center' }}
                            >
                                {formData.id_proof ? (
                                    <img
                                        src={URL.createObjectURL(formData.id_proof)}
                                        alt="ID Proof"
                                        width={100}
                                    />
                                ) : (

                                    <div>
                                        {/* <p>Drag and drop ID proof here / Select images from here</p> */}
                                        <span className="supported-types">
                                            (Only JPG, PNG, GIF, PDF files are supported)
                                        </span>
                                    </div>
                                )}
                                {formData.passport_size_photo ? (
                                    <img
                                        src={URL.createObjectURL(formData.passport_size_photo)}
                                        alt="Passport Size Photo"
                                        width={100}
                                    />
                                ) : (
                                    <div>
                                        {/* <p>Drag and drop photo here / Select images from here</p> */}
                                        <span className="supported-types">
                                            {/* (Only JPG, PNG, GIF, PDF files are supported) */}
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div className="second-box">
                                <form className="form-ID">
                                    <div>
                                        <label htmlFor="id_proof" className="label-ID">
                                            Click to upload Id Proof
                                            <FileUploader
                                                handleChange={(file) => handleFileChange(file, 'id_proof')}
                                                name="file"
                                                id="id_proof"
                                                className="upload-doc"
                                                label="Upload files here"
                                                types={fileTypes}
                                                dropMessageStyle={{ backgroundColor: "red" }}
                                            />
                                        </label>
                                    </div>
                                </form>
                                <div
                                    className="add-id-img-1"
                                    onClick={() => handleIconClick(1)}
                                    onDragOver={handleDragOver}
                                    onDrop={(event) => handleDrop(event, setImages1)}
                                    
                                >
                                    <p className='drag-drop-1'>
                                        <FontAwesomeIcon icon={faClone} />
                                        Drag and drop images here / Select images from here
                                    </p>
                                    {images1.length > 0 && (
                                        <div className='image-preview'>
                                            {images1.map((image, index) => (
                                                <div key={index} className='image-item'>
                                                    <img src={URL.createObjectURL(image)} alt={`Preview ${index}`}/>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <form className="form-ID">
                                    <div>
                                        <label htmlFor="passport_size_photo" className="label-ID">
                                            Click to upload Photo
                                            <FileUploader
                                                handleChange={(file) => handleFileChange(file, 'passport_size_photo')}
                                                name="file"
                                                id="passport_size_photo"
                                                className="upload-doc"
                                                label="Upload files here"
                                                types={fileTypes}
                                                dropMessageStyle={{ backgroundColor: "red" }}
                                            />
                                        </label>
                                    </div>
                                </form>
                                <div
                                    className="add-id-img-2"
                                    onClick={() => handleIconClick(2)}
                                    onDragOver={handleDragOver}
                                    onDrop={(event) => handleDrop(event, setImages2)}
                                >
                                    <p className='drag-drop-2'>
                                        <FontAwesomeIcon icon={faClone} />
                                        Drag and drop images here / Select images from here
                                    </p>
                                    {images2.length > 0 && (
                                        <div className='image-preview'>
                                            {images2.map((image, index) => (
                                                <div key={index} className='image-item'>
                                                    <img src={URL.createObjectURL(image)} alt={`Preview ${index}`} />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {loading ? (
                            <p>Loading...</p>
                        ) : (
                            <button type="button" className='btn-ID' onClick={handleSubmit}>
                                Submit
                            </button>
                        )}
                        {error && <p className="error-message">{error}</p>}
                    </div>
                )}
            </div>
        </div>
    );
};

export default IdProof;
