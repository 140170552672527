import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './FeedbackDetails.css';
import { Skeleton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserLarge } from '@fortawesome/free-solid-svg-icons';

const FeedbackDetails = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('https://matrimonyadmin.hola9.com/api/profileapi/feedback/')
      .then(response => response.json())
      .then(data => {
        setFeedbacks(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching feedback forms:', error);
        setLoading(false);
      });
  }, []);

  const renderFeedbackCard = (feedback) => (
    <div className="feedback-details-outercard" key={feedback.id}>
      <div className="feedback-details-card">
        <div className="feedback-details-info">
          <p className="feedback-details-name">{feedback.name}</p>
          <div className='faUserLarge-icon'>
            <FontAwesomeIcon icon={faUserLarge} />
          </div>
          <p className="feedback-details-email">Email: {feedback.email}</p>
          <div className="line-container">
            <h2 className="tittle-line"></h2>
          </div>
          <p className="feedback-details-subject">Subject: {feedback.Subject}</p>
          <p className="feedback-details-message">Message: Lorem Ipsum is simply dummy
            text of the printing and typesetting industry. {feedback.Message}</p>
          <Link  to={`/feedback/${feedback.id}`}> View More</Link>
        </div>
      </div>
    </div>
  );

  const renderSkeletonLoader = () => (
    <div className="feedback-details-card">
      <div className="feedback-details-skeleton">
        {[...Array(5)].map((_, index) => (
          <Skeleton key={index} animation="wave" variant="text" width={200} />
        ))}
      </div>
    </div>
  );

  return (
    <div className="feedback-details-container-center">

      <div className="FeedbackFormDetailsh1">
        <h1>Feedback Details cards</h1>
      </div>
      <div className="feedback-details-container">
        {loading ? (
          <>
            {Array.from({ length: 12 }).map((_, index) => (
              <div key={index}>{renderSkeletonLoader()}</div>
            ))}
          </>
        ) : (
          feedbacks.map(feedback => renderFeedbackCard(feedback))
        )}
      </div>
    </div>
  );
};

export default FeedbackDetails;
