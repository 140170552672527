import React from 'react'
import "./Notification.css"
import { useNavigate } from 'react-router-dom';

const Notification = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
   
     <div className='container' 
       
     style={{marginLeft:'12rem',width:"100%",backgroundColor:"white",padding:"1.5rem 1rem",borderradius:".5rem",marginTop:"50px",boxShadow:"none"}}>


     <button onClick={goBack} className='back-button-pay'>Go Back</button>
       <header 
       style={{display:'flex',alignItems:"center",justifyContent:"space-between",marginBottom:"1rem",marginTop:"7%"}}>

         <div className='notify_box'
         style={{display:'flex',alignItems:"center"}}>
           <h2 className='titleN' style={{color:" #007bff",marginTop:"-25%",marginBottom:"5%",fontFamily:"inherit"}}>Notifications🕭</h2>
           <span id='notification'
            style={{marginLeft:".5rem",width:"35px",display:'flex',alignItems:"center",justifyContent:"center",
            height:"30px",color:"var(--white)",fontWeight:"800",borderRadius:".5rem"}}></span>

        </div>
  
       </header>



   <main style={{display:"flex",flexDirection:"column",gap:"1rem"}}>
      <div className='notify_card unread' style={{display:"flex",alignItems:"center",boxShadow:"1px 1px 2px 1px black",}}>
           <img  className="img_source"  src='https://www.pngitem.com/pimgs/m/404-4042710_circle-profile-picture-png-transparent-png.png'  alt="avatar" style={{width:"120px"}}></img>
           <div className='description' style={{marginLeft:"1 rem",display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
             <p className='user_activity'>
               <strong style={{color:"#e83e8c" ,marginLeft:'20px'}}>John Doe </strong>reacted to your post
               <p style={{color:"black",fontFamily:"inherit",fontWeight:"bold",marginLeft:'20px'}}>lorem ipsum dolor sit amat consectetur,adipiscing elit.Unde,dolorem.</p>

            </p>
             <p className='time' style={{fontWeight:"bold",marginLeft:"20px"}}>10m ago</p>

           </div>

        </div>
        
       
       

        <div className='notify_card unread' style={{display:"flex",alignItems:"center",boxShadow:"1px 1px 2px 1px black"}}>
          <img src='https://www.pngitem.com/pimgs/m/75-758282_walter-circle-person-photo-in-circle-hd-png.png' alt="notification" style={{width:"120px"}}></img>
           <div className='description'style={{marginLeft:"1 rem",display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
            <p className='user_activity'>
               <strong style={{color:"#e83e8c" ,marginLeft:'20px'}}>Richard Miles </strong>reacted to your post
               <p style={{color:"black",fontFamily:"inherit",fontWeight:"bold",marginLeft:'20px'}}>lorem ipsum dolor sit amat consectetur,adipiscing elit.Unde,dolorem.</p>

           </p>
             <p className='time' style={{fontWeight:"bold",marginLeft:"20px"}}>10m ago</p>

         </div>

        </div>
    
        <div className='notify_card unread' style={{display:"flex",alignItems:"center",boxShadow:"1px 1px 2px 1px black",marginBottom:'30px'}}>
          <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUtcO4YmGkZhf8rEs8DdPZYnLlPCpOF1pTMZMYf1lDHzaQFAqjUKPzRFdZaqDRuBuYKHo&usqp=CAU' alt="notification" style={{width:"120px"}}></img>
           <div className='description'style={{marginLeft:"1 rem",display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
            <p className='user_activity'>
               <strong style={{color:"#e83e8c" ,marginLeft:'20px'}}>Brian Cumin </strong>reacted to your post
               <p style={{color:"black",fontFamily:"inherit",fontWeight:"bold",marginLeft:'20px'}}>lorem ipsum dolor sit amat consectetur,adipiscing elit.Unde,dolorem.</p>

           </p>
             <p className='time' style={{fontWeight:"bold",marginLeft:"20px"}}>10m ago</p>

         </div>

        </div>
    



</main>
</div>
   
  )
}

export default Notification