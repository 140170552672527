import React, { useState } from 'react';
import axios from 'axios'; // Import Axios
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './HobbyDetails.css';
import { useNavigate } from 'react-router-dom';

const HobbyDetails = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const [hobbies, setHobbies] = useState({
    cooking: false,
    cooking_text: '',
    music: false,
    music_text: '',
    writing: false,
    writing_text: '',
    gaming: false,
    gaming_text: '',
    gardening: false,
    gardening_text: ''
  });

  const handleChange = (field, value) => {
    setHobbies(prevState => ({
      ...prevState,
      [field]: value
    }));
  };
  

  const handleSubmit = () => {
  
    axios.post('https://matrimonyadmin.hola9.com/api/profileapi/hobbies/', hobbies)
      .then(response => {
        console.log(response.data);
        toast.success('Form submitted successfully');
      })
      .catch(error => {
        console.error('Error:', error);
        toast.error('Failed to submit form');
      });
  };

  return (
    <div className="hobby-details">

      <div className='hobby-form-h2'>
      <h2 >Hobby Details</h2>
      </div>
      <button onClick={goBack} className='back-button-pay'><i className="ri-arrow-left-line"></i></button>
      
      <div className='main-hobby'>
        
        <div className="hobby-item">
          <label className='hobby-lable'><h10>Cooking:</h10></label>
          <div className="radio-group">
            <label className='hobby-lable'>
              <input
                className='radio-btn'
                type="radio"
                name="cooking"
                checked={hobbies.cooking}
                onChange={() => handleChange('cooking', true)}
              />
              Yes
            </label>
            <label className='hobby-lable'>
              <input
                className='radio-btn'
                type="radio"
                name="cooking"
                checked={!hobbies.cooking}
                onChange={() => handleChange('cooking', false)}
              />
              No
              <div className='hobby-text-form'>
              {hobbies.cooking && <input className='hobby-text' type="text" placeholder="Enter your cooking details" name="cooking_text" value={hobbies.cooking_text} onChange={(e) => handleChange('cooking_text', e.target.value)} />}
              </div>

            </label>

          </div>

        </div>

        <div className="hobby-item">
          <label className='hobby-lable'><h10>Music:</h10></label>
          <div className="radio-group">
            <label className='hobby-lable'>
              <input
                className='radio-btn'
                type="radio"
                name="music"
                checked={hobbies.music}
                onChange={() => handleChange('music', true)}
              />
              Yes
            </label>
            <label className='hobby-lable'>
              <input
                className='radio-btn'
                type="radio"
                name="music"
                checked={!hobbies.music}
                onChange={() => handleChange('music', false)}
              />
              No
              <div className='hobby-text-form' >
          {hobbies.music && <input className='hobby-text' type="text" placeholder="Enter your music details" name="music_text" value={hobbies.music_text} onChange={(e) => handleChange('music_text', e.target.value)} />}

          </div>
            </label>
          </div>
         
        </div>

        <div className="hobby-item">
          <label className='hobby-lable'><h10>Writing:</h10></label>
          <div className="radio-group">
            <label className='hobby-lable'>
              <input
                className='radio-btn'
                type="radio"
                name="writing"
                checked={hobbies.writing}
                onChange={() => handleChange('writing', true)}
              />
              Yes
            </label>
            <label className='hobby-lable'>
              <input
                className='radio-btn'
                type="radio"
                name="writing"
                checked={!hobbies.writing}
                onChange={() => handleChange('writing', false)}
              />
              No
              <div className='hobby-text-form'>
          {hobbies.writing && <input className='hobby-text' type="text" placeholder="Enter your writing details" name="writing_text" value={hobbies.writing_text} onChange={(e) => handleChange('writing_text', e.target.value)} />}

          </div>
            </label>
          </div>
        
        </div>

        <div className="hobby-item">
          <label className='hobby-lable'><h10>Gaming:</h10></label>
          <div className="radio-group">
            <label className='hobby-lable'>
              <input
                className='radio-btn'
                type="radio"
                name="gaming"
                checked={hobbies.gaming}
                onChange={() => handleChange('gaming', true)}
              />
              Yes
            </label>
            <label className='hobby-lable'>
              <input
                className='radio-btn'
                type="radio"
                name="gaming"
                checked={!hobbies.gaming}
                onChange={() => handleChange('gaming', false)}
              />
              No
              <div className='hobby-text-form'>
          {hobbies.gaming && <input className='hobby-text' type="text" placeholder="Enter your gaming details" name="gaming_text" value={hobbies.gaming_text} onChange={(e) => handleChange('gaming_text', e.target.value)} />}

          </div>
            </label>
          </div>
         
        </div>

        <div className="hobby-item">
          <label className='hobby-lable'><h10>Gardening:</h10></label>
          <div className="radio-group">
            <label className='hobby-lable'>
              <input
                className='radio-btn'
                type="radio"
                name="gardening"
                checked={hobbies.gardening}
                onChange={() => handleChange('gardening', true)}
              />
              Yes
            </label>
            <label className='hobby-lable'>
              <input
                className='radio-btn'
                type="radio"
                name="gardening"
                checked={!hobbies.gardening}
                onChange={() => handleChange('gardening', false)}
              />
              No
              <div className='hobby-text-form'>
          {hobbies.gardening && <input className='hobby-text' type="text" placeholder="Enter your gardening details" name="gardening_text" value={hobbies.gardening_text} onChange={(e) => handleChange('gardening_text', e.target.value)} />}

          </div>
            </label>
          </div>
          

        </div>


        <button className='hobby-btn' onClick={handleSubmit}>Submit</button>

      </div>

      <ToastContainer />
    </div>
  );
};

export default HobbyDetails;
