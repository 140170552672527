import React, { useState, useEffect } from 'react';
import {
  BsFillPencilFill,

} from "react-icons/bs";
import { BarLoader } from 'react-spinners';

const Newhabbits = () => {
  const [dataHabit, setDataHabit] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [smoking, setSmoking] = useState('');
  const [drinking, setDrinking] = useState('');
  const [exercise, setExercise] = useState('');
  const [eating, setEating] = useState('');
  const [sleeping, setSleeping] = useState('');

  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://matrimonyadmin.hola9.com/api/profileapi/habits/`);
        const jsonData = await response.json();
        setDataHabit(jsonData);
        setIsLoading(false);
        if (jsonData.length > 0) {
          const latestData = jsonData[jsonData.length - 1];
          setSelectedId(latestData.id);
          setSmoking(latestData.smoking);
          setDrinking(latestData.drinking);
          setExercise(latestData.exercise);
          setEating(latestData.eating);
          setSleeping(latestData.sleeping);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://matrimonyadmin.hola9.com/api/profileapi/habits/${selectedId}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: selectedId,
          smoking,
          drinking,
          exercise,
          eating,
          sleeping
        }),
      });
      if (response.ok) {
        setDataHabit(prevData => {
          const newData = [...prevData];
          const index = newData.findIndex(item => item.id === selectedId);
          if (index !== -1) {
            newData[index] = {
              id: selectedId,
              smoking,
              drinking,
              exercise,
              eating,
              sleeping
            };
          }
          return newData;
        });
        closeModal();
      } else {
        console.error('Failed to update data:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  const handleSmokingChange = (e) => {
    setSmoking(e.target.value);
  };

  const handleDrinkingChange = (e) => {
    setDrinking(e.target.value);
  };

  const handleExerciseChange = (e) => {
    setExercise(e.target.value);
  };

  const handleEatingChange = (e) => {
    setEating(e.target.value);
  };

  const handleSleepingChange = (e) => {
    setSmoking(e.target.value);
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <BarLoader color={'#123abc'} loading={isLoading} /> {/* Display BarLoader while data is being fetched */}
      </div>
    );
  }
  return (
    <div>
      {/* <div>NewInterest</div> */}
      {dataHabit.length > 0 && (
        <div className="feature-block-twop col-lg-4p col-md-6p col-sm-6p wow fadeInUp animated" style={{ visibility: "visible", animationName: "fadeInUp" }}>
          <div className="inner-boxp">
            <div className="icon-boxp">
              <img src="https://www.matrimonysoftware.in/icon/education.png" alt="" />
            </div>
            <h4>
              <a href="education">
                Habbits  Details {" "}
                <i style={{ fontSize: 24 }} className="fa">
                  
                </i>
                </a>
         
            </h4>
            <BsFillPencilFill className="editpro"   onClick={openModal}/>
            <div className="textpro"><span>Smoking:</span> {smoking}</div>
            <div className="textpro"><span>Drinking:</span> {drinking}</div>
            <div className="textpro"><span>Exercise:</span> {exercise}</div>
            <div className="textpro"><span>Eating:</span> {eating}</div>
            <div className="textpro"><span>Sleeping:</span>span {sleeping}</div>
            {/* <button className="btn btn-primary" onClick={openModal}>Edit</button> */}
          </div>
        </div>
      )}

      <div className={`modal ${isModalOpen ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: isModalOpen ? 'block' : 'none' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Details</h5>
              <button type="button" className="close" onClick={closeModal}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>

              {/* Smoking habits */}
                <div>
                  <label className='habit-label'><h4 className='habit4'>Smoking habits:</h4></label>
                  <div>
                    <label className='habit-label'>
                      <input
                        className='radio-btn'
                        type="radio"
                        value="Non-smoker"
                        checked={smoking === 'Non-smoker'}
                        onChange={handleSmokingChange}
                        style={{marginLeft:"10%"}}
                      />
                      <span className='s1'  style={{color:"white"}}> Non-smoker</span>
                    </label>
                    <label className='habit-label'>
                      <input
                        className='radio-btn'
                        type="radio"
                        value="Occasionally"
                        checked={smoking === 'Occasionally'}
                        onChange={handleSmokingChange}
                        style={{marginLeft:"10%"}}
                      />
                      <span className='s1'  style={{color:"white"}}> Occasionally</span>
                    </label>
                    <label className='habit-label'>
                      <input
                        className='radio-btn'
                        type="radio"
                        value="Regularly"
                        checked={smoking === 'Regularly'}
                        onChange={handleSmokingChange}
                        style={{marginLeft:"11%"}}
                      />
                      <span className='s1'  style={{color:"white"}}> Regularly</span>
                    </label>
                  </div>
                </div>

                {/* {drinking} */}
                <div>
        <label className='habit-label'><h4 className='habit4'>Drinking habits:</h4></label>
        <div>
          <label className='habit-label'>
            <input
              className='radio-btn'
              type="radio"
              value="Non-drinker"
              checked={drinking === 'Non-drinker'}
              onChange={handleDrinkingChange}
              style={{marginLeft:"9%"}}
            />
            <span className='s1'  style={{color:"white"}}> Non-drinker</span>
          </label>
          <label className='habit-label'>
            <input
              className='radio-btn'
              type="radio"
              value="Occasionally"
              checked={drinking=== 'Occasionally'}
              onChange={handleDrinkingChange}
              style={{marginLeft:"9%"}}
            />
            <span className='s1'  style={{color:"white"}}> Occasionally</span>
          </label>
          <label className='habit-label'>
            <input
              className='radio-btn'
              type="radio"
              value="Regularly"
              checked={drinking === 'Regularly'}
              onChange={handleDrinkingChange}
              style={{marginLeft:"10%"}}
            />
            <span className='s1'  style={{color:"white"}}> Regularly</span>
          </label>
        </div>
      </div>

        {/* {exercise} */}
        <div>
        <label className='habit-label'><h4 className='habit4'>Exercise habits:</h4></label>
        <div>
          <label className='habit-label'>
            <input
              className='radio-btn'
              type="radio"
              value="Sedentary"
              checked={exercise === 'Sedentary'}
              onChange={handleExerciseChange}
              style={{marginLeft:"9%"}}
            />
            <span className='s1'  style={{color:"white"}}>Sedentary</span>
          </label>
          <label className='habit-label'>
            <input
              className='radio-btn'
              type="radio"
              value="Occasionally"
              checked={exercise === 'Occasionally'}
              onChange={handleExerciseChange}
              style={{marginLeft:"8%"}}
            />
            <span className='s1'  style={{color:"white"}}> Occasionally</span>
          </label>
          <label className='habit-label'>
            <input
              className='radio-btn'
              type="radio"
              value="Regularly"
              checked={exercise === 'Regularly'}
              onChange={handleExerciseChange}
              style={{marginLeft:"8%"}}
            />
            <span className='s1'  style={{color:"white"}}> Regularly</span>
          </label>
        </div>
      </div>

       {/* {eating} */}
       <div>
        <label className='habit-label'><h4 className='habit4'>Eating habits:</h4></label>
        <div>
          <label className='habit-label'>
            <input
              className='radio-btn'
              type="radio"
              value="Vegetarian"
              checked={eating === 'Vegetarian'}
              onChange={handleEatingChange}
              style={{marginLeft:"9%"}}
            />
            <span className='s1'  style={{color:"white"}}> Vegetarian</span>
          </label>
          <label className='habit-label'>
            <input
              className='radio-btn'
              type="radio"
              value="Non-vegetarian"
              checked={eating === 'Non-vegetarian'}
              onChange={handleEatingChange}
              style={{marginLeft:"8%"}}
            />
            <span className='s1'  style={{color:"white"}}> Non-vegetarian</span>
          </label>
          <label className='habit-label'>
            <input
              className='radio-btn'
              type="radio"
              value="vegan"
              checked={eating === 'vegan'}
              onChange={handleEatingChange}
              style={{marginLeft:"11%"}}
            />
            <span className='s1'  style={{color:"white"}}> vegan</span>
          </label>
        </div>
      </div>

         {/* {sleeping} */}
      <div>
        <label className='habit-label'><h4 className='habit4'>Sleeping habits:</h4></label>
        <div>
          <label className='habit-label'>
            <input
              className='radio-btn'
              type="radio"
              value="Early bird"
              checked={sleeping === 'Early bird'}
              onChange={handleSleepingChange}
              style={{marginLeft:"10%"}}
            />
            <span className='s1'  style={{color:"white"}}> Early bird</span>
          </label>
          <label className='habit-label'>
            <input
              className='radio-btn'
              type="radio"
              value="Night owl"
              checked={sleeping=== 'Night owl'}
              onChange={handleSleepingChange}
              style={{marginLeft:"10%"}}
            />
            <span className='s1'  style={{color:"white"}}> Night owl</span>
          </label>
          <label className='habit-label'>
            <input
              className='radio-btn'
              type="radio"
              value="Balanced"
              checked={sleeping === 'Balanced'}
              onChange={handleSleepingChange}
              style={{marginLeft:"10%"}}
            />
            <span className='s1'  style={{color:"white"}}> Balanced</span>
          </label>
        </div>
      </div>


      <div className="form-group">
                <button type="submit" >Submit</button>
                </div>
                </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newhabbits;
