import React from 'react'
import "./NewViewStyle.css"
import Header from '../Homepage/Header'
import Footer from '../Footer/Footer'
const NewViewe = () => {
  return (
    <>
  <Header />
  <div className="page-wrapper">
    {/* Preloader */}
    <div className="preloader" style={{ display: "none" }} />
   
    <span className="header-span" />
   
    <div id="search-popup" className="search-popup">
      <div className="close-search theme-btn">
        <span className="fas fa-window-close" />
      </div>
      <div className="popup-inner">
        <div className="overlay-layer" />
      
      </div>
    </div>
    <section
      className="page-title"
      style={{ backgroundColor:"black",marginTop:"1px" }}
    >
      <div className="auto-container">
        <h1 className="d-none d-lg-block d-xl-block d-md-block">
          {" "}
          Who Viewed My Profile
        </h1>
        <ul className="bread-crumb clearfix">
          <li>
            <a href="index_dashboard">Home</a>
          </li>
          <li>Who Viewed My Profile</li>
        </ul>
      </div>
    </section>
    <section className="error-section">
      <div className="anim-icons full-width">
        <span
          className="icon icon-circle-blue wow fadeIn"
          style={{ visibility: "hidden", animationName: "none" }}
        />
        <span
          className="icon icon-dots wow fadeInleft animated"
          style={{ visibility: "visible" }}
        />
        <span
          className="icon icon-line-1 wow zoomIn"
          style={{ visibility: "hidden", animationName: "none" }}
        />
        <span
          className="icon icon-circle-1 wow zoomIn animated"
          style={{ visibility: "visible", animationName: "zoomIn" }}
        />
      </div>
      <div className="auto-container">
        <div className="error-title">OOP'S</div>
        <h4>Sorry Result Not Found</h4>
        <div className="text">Your profile is yet to be viewed.</div>
        <a href="smart_search" className="theme-btn btn-style-three">
          <span className="btn-title">Search</span>
        </a>
        {/*<a href="contact.html" class="theme-btn btn-style-two"><span class="btn-title">Contact Us</span></a>*/}
      </div>
    </section>
    {/* Main Footer */}
    
  </div>
  <div className="scroll-to-top scroll-to-target" data-target="html">
    <span className="fa fa-angle-double-up" />
  </div>
  <Footer />
</>

  )
}

export default NewViewe;