import React from 'react';
import "./SaveSearch.css";
import { Link } from 'react-router-dom';

function SaveSearch() {

  return (
    <div>

      <section>
        <div className='save_search_container'>
          <h1 className='save_search_title'>Save Search Page</h1>
          <h3 className='save_search_text'>There is no saved profiles</h3>
          <div className='cards-save'>
            <div className='card-save'>
              <p className='card_search'>Saved Search</p>
              <p className='search_content'>Select your filters and create a Saved Search</p>
              <Link to="/GlobalSearch"><button className='search_button'><span>Search</span></button></Link>
            </div>

            <div className='card-save'>
              <p className='card_search'>Recent Search</p>
              <p className='search_content'>Your last five Searches will be here for quick access</p>
              <Link to="/GlobalSearch"><button className='search_button'><span>Search</span></button></Link>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SaveSearch;
