import React from "react";
import ScrollToTopButton from './ScrollToTopButton';
import Register from "./Register";
import HomeImg from "./HomeImg";
import HomeAbout from "./HomeAbout";
import Features from "./Features";
import AppDownload from "./AppDownload";
import HappyStory from "./HappyStory";
import Description from "../Descriptions/Description";
import Specialsomeone from "./Specialsomeone";
import './HomePage.css';
import WelcomeModal from "../UserDashBoard/UserProfilee/WelcomeModal";

const HomePage = () => {
 
  return (
    <div>
      <HomeImg />
      <WelcomeModal />
      <Register />
      <HomeAbout />
      <Features />
      <Description />
      <AppDownload />
      <HappyStory />
      <Specialsomeone />
      <ScrollToTopButton />
    </div>
  );
};

export default HomePage;