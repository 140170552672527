import React, { useState, useEffect } from 'react';
import { BsFillPencilFill } from "react-icons/bs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';

const NewIdProof = () => {
  const [database, setDatabase] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [id_proof, setIdProof] = useState(null);
  const [passport_size_photo, setPassportSizePhoto] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://matrimonyadmin.hola9.com/api/crud/idproofapi/`);
        const jsonData = await response.json();
        setDatabase(jsonData.Msg);
        setIsLoading(false);
        if (jsonData.length > 0) {
          const latestData = jsonData[jsonData.length - 1];
          setSelectedId(latestData.id);
          // Construct complete image URLs
          const idProofImageUrl = `https://matrimonyadmin.hola9.com${latestData.id_proof}`;
          const passportSizePhotoUrl = `https://matrimonyadmin.hola9.com${latestData.passport_size_photo}`;
          setIdProof(idProofImageUrl);
          setPassportSizePhoto( passportSizePhotoUrl);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://matrimonyadmin.hola9.com/api/crud/idproofapi/${selectedId}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: selectedId,
          id_proof,
          passport_size_photo,
        }),
      });
      if (response.ok) {
        setDatabase(prevData => {
          const newData = [...prevData];
          const index = newData.findIndex(item => item.id === selectedId);
          if (index !== -1) {
            newData[index] = {
              id: selectedId,
              id_proof,
              passport_size_photo,
            };
          }
          return newData;
        });
        closeModal();
      } else {
        console.error('Failed to update data:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  return (
    <div>
  
      <div className="feature-block-twop col-lg-4p col-md-6p col-sm-6p wow fadeInUp animated">
        {database.length > 0 && (
          <div className="inner-boxp">
            <div className="icon-boxp">
              <img src="https://www.matrimonysoftware.in/icon/basic.png" alt="" />
            </div>
            <h4>
              <a href="Basicdetails">Id Proof <i style={{ fontSize: 24 }} className="fa"></i></a>
            </h4>
            <BsFillPencilFill className="editpro" onClick={openModal} />
            <div>Id Proof: <img src={`https://matrimonyadmin.hola9.com${id_proof}`} alt="ID Proof" width="200px" height="150px" />

</div>
            <div>Passport Size Photo: <img src={`https://matrimonyadmin.hola9.com${passport_size_photo}`} alt="Passport Size Photo" width="200px" height="150px" /></div>
          </div>
        )}
      </div>

      <div className={`modal ${isModalOpen ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: isModalOpen ? 'block' : 'none' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Details</h5>
              <button type="button" className="close" onClick={closeModal}>
                <span>&times;</span>
              </button>
            </div>
            <form className="form-ID" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="id_proof" className="label-ID">
                  Click to upload Id Proof
                  <FontAwesomeIcon icon={faFile} />
                  <input
                    className="label-input"
                    type="file"
                    id="id_proof"
                    accept=".jpg, .jpeg, .png, .pdf"
                    onChange={(e) => setIdProof(e.target.files[0])}
                  />
                </label>
              </div>
              <div>
                <label htmlFor="passport_size_photo" className="label-ID">
                  Click to upload Photo
                  <FontAwesomeIcon icon={faFile} />
                  <input
                    className="label-input"
                    type="file"
                    id="passport_size_photo"
                    accept=".jpg, .jpeg, .png, .pdf"
                    onChange={(e) => setPassportSizePhoto(e.target.files[0])}
                  />
                </label>
              </div>
              <button type="submit" className='btn-ID' onClick={handleSubmit}>
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewIdProof;
