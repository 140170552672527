import React from 'react'
import "./profileihaveignore.css"

const ProfileIHaveIgnore = () => {
  return (
    <div>

        <section className="page-title" style={{ backgroundColor: "black",color:"white",fontSize:"20px", fontWeight:"700",marginTop:"-40px"}}>
        <div className="auto-container">
          {/* <h1 className="d-none d-lg-block d-xl-block d-md-block">ProfileIHaveIgnore </h1> */}
          <ul className="bread-crumb clearfix">
           <li style={{textAlign:'center'}}> ProfileIHaveIgnore</li>
          </ul>
        </div>


      </section>
      <div>
      <br/>
      <center>


        

<div className="profile-have-container">
      <h1 className="profile-have-heading">Who Profile I Have Ignore</h1>
      <div className="profile-have-input-container-view">
        <input type="text" className="profile-have-input" placeholder="Name"  />
        <input type="text" className="profile-have-input" placeholder="Profile ID" />
        <input type="text" className="profile-have-input" placeholder="Viewed Date" />
      </div>
    </div>

    </center>
  </div>
    </div>
  )
}

export default ProfileIHaveIgnore;
