export const initialState = {
  family_values: "",
  family_type: "",
  family_status: "",
  mother_tounge: "",
  no_of_brothers: "",
  no_of_brothers_married: "",
  no_of_sisters: "",
  no_of_sisters_married: "",
  father_name: "",
  father_occupation: "",
  mother_name: "",
  mother_occupation: "",
  family_wealth: "",
  about_family: "",
  options: "",
};
export const stateOptions = [
  { value: "Assamese", label: "Assamese" },
  { value: "Bengali", label: "Bengali" },
  { value: "Bodo", label: "Bodo" },
  { value: "Dogri", label: "Dogri" },
  { value: "Gujarati", label: "Gujarati" },
  { value: "Hindi", label: "Hindi" },
  { value: "Kannada", label: "Kannada" },
  { value: "Kashmiri", label: "Kashmiri" },
  { value: "Konkani", label: "Konkani" },
  { value: "Maithili", label: "Maithili" },
  { value: "Malayalam", label: "Malayalam" },
  { value: "Manipuri", label: "Manipuri" },
  { value: "Marathi", label: "Marathi" },
  { value: "Nepali", label: "Nepali" },
  { value: "Odia", label: "Odia" },
  { value: "Punjabi", label: "Punjabi" },
  { value: "Sanskrit", label: "Sanskrit" },
  { value: "Santali", label: "Santali" },
  { value: "Sindhi", label: "Sindhi" },
  { value: "Tamil", label: "Tamil" },
  { value: "Telugu", label: "Telugu" },
  { value: "Urdu", label: "Urdu" },
];
export const validationMessages = {
  family_values: "Please provide your family values.",
  family_type: "Please specify your family type.",
  family_status: "Please indicate your family status.",
  mother_tounge: "Please enter your mother tongue.",
  no_of_brothers: "Please state the number of brothers.",
  no_of_brothers_married: "Please specify the number of married brothers.",
  no_of_sisters: "Please state the number of sisters.",
  no_of_sisters_married: "Please specify the number of married sisters.",
  father_name: "Father's name is required.",
  father_occupation: "Father's occupation is required.",
  mother_name: "Mother's name is required.",
  mother_occupation: "Mother's occupation is required.",
  family_wealth: "Please provide information about family wealth.",
  options: "Please select relative information.",
  about_family: "Please provide details about your family.",
};
export const familyValuesOptions = [
  { value: "Family values", label: "Family Values" },
  { value: "Traditional", label: "Traditional" },
  { value: "Orthodox", label: "Orthodox" },
  { value: "Liberal", label: "Liberal" },
  { value: "Moderate", label: "Moderate" },
];
export const familyTypeOptions = [
  { value: "Family-Type", label: "Family-Type" },
  { value: "Nuclear Family", label: "Nuclear Family" },
  { value: "Joint Family", label: "Joint Family" },
];
export const familyStatusOptions = [
  { value: "Family Status", label: "Family Status", selected: true },
  { value: "Rich", label: "Rich" },
  { value: "High Class", label: "High Class" },
  { value: "Upper Middle Class", label: "Upper Middle Class" },
  { value: "Middle Class", label: "Middle Class" },
  {
    value: "Do not want to tell at this time",
    label: "Do not want to tell at this time",
  },
];
export const numberOfBrothersOptions = [
  { value: "", label: "Select No.of Brothers", selected: true },
  { value: "No", label: "No" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: "5+", label: "5+" },
];
export const noOfBrothersMarriedOptions = [
  { value: "", label: "Select No.of Brothers Married", selected: true },
  { value: "No", label: "No" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: "5+", label: "5+" },
];
export const noOfSistersOptions = [
  { value: "", label: "Select No. of Sisters" },
  { value: "No", label: "No" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: "5+", label: "5+" },
];
export const noOfSistersMarriedOptions = [
  { value: "", label: "No. of Sisters Married" },
  { value: "No", label: "No" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: "5+", label: "5+" },
];
export const familyWealthOptions = [
  { value: "Family_Wealth", label: "Family_Wealth" },
  { value: "Bungalow", label: "Bungalow" },
  { value: "House", label: "House" },
  { value: "Flat", label: "Flat" },
  { value: "Plot", label: "Plot" },
  { value: "Agri Land", label: "Agri Land" },
  { value: "Car", label: "Car" },
];
