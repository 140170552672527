import React, { useRef } from 'react';
import "./Specialsomeone.css";

const Specialsomeone = () => {
  const steps = [
{
  id: 1,
  icon: 'https://th.bing.com/th/id/OIP.eTmdQap0PmhJNWQ5ze-_HAHaEK?rs=1&pid=ImgDetMain',
  title: 'Define Your Partner Preference',
  description: 'Specify your preferences and what you\'re looking for in a partner.'
},
{
  id: 2,
  icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjFqwD5sSx0gswNgo4Tx84RIRNIP2-5mjUaA&usqp=CAU',
  title: 'Browse Profiles',
  description: 'Explore profiles that match your criteria and interests.'
},
{
  id: 3,
  icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOwsC10FWB5_YfXb1_nTHfKHI8_O32Ltdpug&usqp=CAU',
  title: 'Connect',
  description: 'Initiate contact and connect with potential matches.'
},
{
  id: 4,
  icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOwsC10FWB5_YfXb1_nTHfKHI8_O32Ltdpug&usqp=CAU',
  title: 'Connect',
  description: 'Initiate contact and connect with potential matches.'
},
{
  id: 5,
  icon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOwsC10FWB5_YfXb1_nTHfKHI8_O32Ltdpug&usqp=CAU',
  title: 'Connect',
  description: 'Initiate contact and connect with potential matches.'
},{
  id: 6,
  icon: 'https://th.bing.com/th/id/OIP.eTmdQap0PmhJNWQ5ze-_HAHaEK?rs=1&pid=ImgDetMain',
  title: 'Define Your Partner Preference',
  description: 'Specify your preferences and what you\'re looking for in a partner.'
}
  ];

  const scrollRef = useRef(null);

  const scroll = (scrollOffset) => {
    scrollRef.current.scrollBy({ left: scrollOffset, behavior: 'smooth' });
  };

  return (
    <div className='find-couple'>
      <div className="card-heading">
        <h2 className="some-s">Three simple steps to</h2>
        <h2 className='find-your'>Find Your Special Someone</h2>
      </div>

      <section className="find-couple-section" ref={scrollRef}>
        {steps.map((step) => (
          <div key={step.id} className='couple-container1'>
            <div className="step-card">
              <div className="icon-some">
                <img src={step.icon} alt="Icon" />
              </div>
              <h5 className='some-h3'>{step.title}</h5>
              <p className='some-p'>{step.description}</p>
            </div>
          </div>
        ))}
      </section>

      <button className="arrow-button-register left" onClick={() => scroll(-400)}>
        &#9664; 
      </button>
      <button className="arrow-button-register right" onClick={() => scroll(400)}>
        &#9654;
      </button>
    </div>
  );
};

export default Specialsomeone;




