  import React, { useState, useEffect } from 'react';
  import {
    BsFillPencilFill,
  
  } from "react-icons/bs";

  const Newpartner = () => {
  const [datapatner, setDatapatner] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedId, setSelectedId] = useState(null);
  const [looking_for, setlooking_for] = useState("");
  const [age_from, setage_from] = useState("");
  const [age_to, setage_to] = useState("");
  const [height_from, setheight_from] = useState("");
  const [height_to, setheight_to] = useState("");
  const [relligion, setrelligion] = useState("");
  const [state, setstate] = useState("");
  const [caste, setcaste] = useState("");
  const [complexion, setcomplexion] = useState("");
  const [residency_status, setresidency_status] = useState("");
  const [country, setcountry] = useState("");
  const [education, seteducation] = useState("");
  const [occupation, setoccupation] = useState("");
  const [partner_expectations, setpartner_expectations] = useState("");
  
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://matrimonyadmin.hola9.com/api/partnerapi/partnerprefarence/`);
        const jsonData = await response.json();
        setDatapatner(jsonData);
        setIsLoading(false);
        if (jsonData.length > 0) {
          const latestData = jsonData[jsonData.length - 1];
          setSelectedId(latestData.id);
          setlooking_for(latestData.looking_for);
          setage_from(latestData.age_from);
          setage_to(latestData.age_to);
          setheight_from(latestData.height_from);
          setheight_to(latestData.height_to);
          setrelligion(latestData.relligion);
          setstate(latestData.state);
          setcaste(latestData.caste);
          setcomplexion(latestData.complexion);
          setresidency_status(latestData.residency_status);
          setcountry(latestData.country);
          seteducation(latestData.education);
          setoccupation(latestData.occupation);
          setpartner_expectations(latestData.partner_expectations);
          
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://matrimonyadmin.hola9.com/api/partnerapi/partnerprefarence/${selectedId}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            id: selectedId,
            looking_for,  
            age_from,
            age_to,
            height_from,
            height_to,
            relligion,
            state,
            caste,
            complexion,
            residency_status,
            country,
            education,
            occupation,
            partner_expectations,
         
        }),
      });
      if (response.ok) {
        setDatapatner(prevData => {
          const newData = [...prevData];
          const index = newData.findIndex(item => item.id === selectedId);
          if (index !== -1) {
            newData[index] = {
              id: selectedId,
              looking_for,  
              age_from,
              age_to,
              height_from,
              height_to,
              relligion,
              state,
              caste,
              complexion,
              residency_status,
              country,
              education,
              occupation,
              partner_expectations,
            };
          }
          return newData;
        });
        closeModal();
      } else {
        console.error('Failed to update data:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
    {/* <div>Newpartner</div> */}
    
    <div
        className="feature-block-twop col-lg-4p col-md-6p col-sm-6p wow fadeInUp animated"
        style={{ visibility: "visible", animationName: "fadeInUp" }}
      >
        {datapatner.length > 0 && (
          <div className="inner-boxp">
            <div className="icon-boxp">
              <img
                src="https://www.matrimonysoftware.in/icon/matches.png"
                alt=""
              />
            </div>
            <h4>
              <a href="partner_prefrence">
                Partner Prefrence{" "}
                <i style={{ fontSize: 24 }} className="fa">
                  
                </i>
              </a>
            
            </h4>
            <BsFillPencilFill className="editpro"   onClick={openModal}/>
         
        <div className="textpro"><span>looking_for: </span>{looking_for}</div>
        <div className="textpro"><span>age_from: </span>{age_from}</div>
        <div className="textpro"><span>age_to:</span> {age_to}</div>
        <div className="textpro"><span>height_from: </span>{height_from}</div>
        <div className="textpro"><span>height_to:</span> {height_to}</div>
        <div className="textpro"><span>relligion:</span> {relligion}</div>
        <div className="textpro"><span>state:</span> {state}</div>
        <div className="textpro"><span>caste:</span> {caste}</div>
        <div className="textpro"><span>complexion:</span> {complexion}</div>
        <div className="textpro"><span>residency_status:</span> {residency_status}</div>
        <div className="textpro"><span>country:</span> {country}</div>
        <div className="textpro"><span>education:</span> {education}</div>
        <div className="textpro"><span>occupation:</span> {occupation}</div>
        <div className="textpro"><span>partner_expectations: </span>{partner_expectations}</div>
       

            {/* <button className="btn btn-primary" onClick={openModal}>Edit</button> */}
          </div>
        )}
      </div>

      <div className={`modal ${isModalOpen ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: isModalOpen ? 'block' : 'none' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Details</h5>
              <button type="button" className="close" onClick={closeModal}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="looking_for"  style={{color:"white"}}>looking_for:</label>
                  <input type="text" className="form-control" id="looking_for" value={looking_for} onChange={(e) => setlooking_for(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="age_from"  style={{color:"white"}}>age_from:</label>
                  <input type="text" className="form-control" id="age_from" value={age_from} onChange={(e) => setage_from(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="age_to"  style={{color:"white"}}>age_to:</label>
                  <input type="text" className="form-control" id="age_to" value={age_to} onChange={(e) => setage_to(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="height_from"  style={{color:"white"}}>height_from:</label>
                  <input type="text" className="form-control" id="height_from" value={height_from} onChange={(e) => setheight_from(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="height_to"  style={{color:"white"}}>height_to:</label>
                  <input type="text" className="form-control" id="height_to" value={height_to} onChange={(e) => setheight_to(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="relligion"  style={{color:"white"}}>relligion:</label>
                  <input type="text" className="form-control" id="relligion" value={relligion} onChange={(e) => setrelligion(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="state"  style={{color:"white"}}>state:</label>
                  <input type="text" className="form-control" id="state" value={state} onChange={(e) => setstate(e.target.value)} />
                 
                </div>
                <div className="form-group">
                  <label htmlFor="caste"  style={{color:"white"}}>caste:</label>
                  <input type="text" className="form-control" id="caste" value={caste} onChange={(e) => setcaste(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="complexion"  style={{color:"white"}}>complexion:</label>
                  <input type="text" className="form-control" id="complexion" value={complexion} onChange={(e) => setcomplexion(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="residency_status"  style={{color:"white"}}>residency_status:</label>
                  <input type="text" className="form-control" id="residency_status" value={residency_status} onChange={(e) => setresidency_status(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="country"  style={{color:"white"}}>country:</label>
                  <input type="text" className="form-control" id="country" value={country} onChange={(e) => setcountry(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="education"  style={{color:"white"}}>education:</label>
                  <input type="text" className="form-control" id="education" value={education} onChange={(e) => seteducation(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="occupation"  style={{color:"white"}}>occupation:</label>
                  <input type="text" className="form-control" id="occupation" value={occupation} onChange={(e) => setoccupation(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="partner_expectations"  style={{color:"white"}}>partner_expectations:</label>
                  <input type="text" className="form-control" id="partner_expectations" value={partner_expectations} onChange={(e) => setpartner_expectations(e.target.value)} />
                </div>
                <div className="form-group">
                <button type="submit" >Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Newpartner