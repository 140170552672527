import React, { useState } from 'react';
import './ChatBox.css'; 
import { FaCog } from 'react-icons/fa';

const ChatBox = () => {
    const [showInformation, setShowInformation] = useState(false);
    const [showSidebar, setShowSidebar] = useState(true);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [showAwaitingResponse, setShowAwaitingResponse] = useState(true);
    const [showCalls, setShowCalls] = useState(false);

    const toggleInformation = () => {
        setShowInformation(true);
        setShowAwaitingResponse(false);
        setShowCalls(false);
        setShowSidebar(false);
    };

    const toggleAwaitingResponse = () => {
        setShowInformation(false);
        setShowAwaitingResponse(true);
        setShowCalls(false);
        setShowSidebar(false);
    };

    const toggleCalls = () => {
        setShowInformation(false);
        setShowAwaitingResponse(false);
        setShowCalls(true);
        setShowSidebar(false);
    };

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    const toggleSearchModal = () => {
        setShowSearchModal(!showSearchModal);
    };

    return (
        <div className="chatbox-container">

            <div className="chat-container-1">
                <div className="search-container" onClick={toggleSearchModal}>
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAXdJREFUSEu11s1KFmEYxvGfO7cuzI8oTSXdBC6zTW4VpBMIatHCM5BOIHThAQhCkEfgxn2KtXAhiBtRK78QLToFe+94Xphi5p1hxvdZDcPM9X/u+7nua6ZHl1dPl/XlAV5hGVMd4HtYwE3ZBvMAl3hY9iKO8LIMkge4S+JF7evHdqrwG150gtQBBD8gXzCBjpC6gIAMYqcM0gTQhnzFaFElTQEBeZTOJCCHeJY1yH0AQi/Ev+eZoyog+r2O50lkF+/wM7PbXPdVAYT4fjrUbPUXmMbvdLM2YAOvsYn3SWwVc1jDYlPAFYYxgNskNoZTnGOkKaAdHeGWuI71GGf4lYYu7tVu0Ue8TS1aagVhLz5gHp9a7nnTtIKIhfD3g5wAPE6Bd92kgtAdSjadQR9iegM43jLACWYz7fvHmVVsWpTcceiRqk/TkD1pMmidIJ8xmXmgtIJwR7ikzvqBdiV/3y/6ZK78t6sqsIM0iFtlYVdFrPIzXf+r+AODIlEZAgUfMAAAAABJRU5ErkJggg=="/>
                    <span>Search</span>
                </div>
                <div className="heading-container">
                    <div className="heading-1" id="received" onClick={() => {toggleInformation(); toggleSidebar();}}>Received</div>
                    <div className="heading-1" id="awaiting-response" onClick={toggleAwaitingResponse}>Awaiting Response</div>
                    <div className="heading-1" id="calls" onClick={toggleCalls}>Calls</div>
                </div>
                {showSidebar && (
                    <div className="side-bar">
                        <div className="sidebar-item">All</div>
                        <div className="sidebar-item">Messages</div>
                        <div className="sidebar-item">Interests</div>
                    </div>
                )}
                {showInformation && (
                    <div className="conversations">
                        <h2>Incoming conversations from matches</h2>
                        <img className='conversation-img' src="https://th.bing.com/th/id/OIP.-E4Lvi7nRKVLrwgfJv-N2AAAAA?w=199&h=160&rs=1&pid=ImgDetMain" alt="Conversation Image" />
                        <p className='till-now'>No conversations till now</p>
                        <p className='incoming'>All incoming</p>
                        <p className='or-or'>messages/interests and</p>
                        <p className='responses'>responses will be shown here</p>
                        <button className="explore-matches">Explore Matches</button>
                        {/* Conversations content goes here */}
                    </div>
                )}
                {showAwaitingResponse && (
                    <div className="conversations">
                        <h2>Matches yet to respond</h2>
                        <img className='conversation-img' src="https://th.bing.com/th/id/OIP.-E4Lvi7nRKVLrwgfJv-N2AAAAA?w=199&h=160&rs=1&pid=ImgDetMain" alt="Conversation Image" />
                        <p className='till-now-1'>No pending conversations till now</p>
                        <p className='or-or'>All interests/messages awaiting</p>
                        <p className='responses-1'>response from matches will be</p>
                        <p className='shown-here'> shown here</p>
                        <button className="explore-matches-1">Explore Matches</button>
                        {/* Conversations content goes here */}
                    </div>
                )}
                {showCalls && (
                    <div className="conversations">
                        <div className='side-bar-1'>
                            <button className="sidebar-item-1">Contacted you</button>
                            <button className="sidebar-item-1">Contacted by you</button>
                        </div>
                        <h2>Matches who tried contacting you</h2>
                        <div className="top-bar">
                            <span className="privacy-settings">Privacy Settings</span>
                            <FaCog className="settings-icon" />
                        </div>
                        <img className='conversation-img' src="https://th.bing.com/th/id/OIP.-E4Lvi7nRKVLrwgfJv-N2AAAAA?w=199&h=160&rs=1&pid=ImgDetMain" alt="Conversation Image" />
                        <p className='contact-you'>No Members have attempted to contact you.</p>
                        <p className='comeback'>Comeback later or initiate new</p>
                        <p className='convo-matches'>conversations with matches</p>
                        <p className='shown-here'> online</p>
                        <button className="explore-matches-2">Explore Matches</button>
                        {/* Conversations content goes here */}
                    </div>
                )}
                {showSearchModal && (
                    <div className="search-modal">
                        <h2>Search by name or ID (Eg: k123456)</h2>
                        <hr className="modal-divider" />
                        <img className='modal-image' src="https://static.vecteezy.com/system/resources/previews/009/007/126/original/document-file-not-found-search-no-result-concept-illustration-flat-design-eps10-modern-graphic-element-for-landing-page-empty-state-ui-infographic-icon-vector.jpg" alt="Search Image" />
                        <p>Oops ! No matches found based</p>
                        <p className='modal-p'>on your search</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ChatBox;

