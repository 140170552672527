




import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./HappyStoryStyle.css";

const data = [
  {
    imgurl1: 'https://www.thesecondshaadi.com/images/s1.jpg',
    imgurl2: 'https://www.thesecondshaadi.com/images/sep-1.png',
    name: 'Deepak & Shalini',
    year: 'March 28, 2017'
  },
  {
    imgurl1: 'https://www.thesecondshaadi.com/images/s2.jpg',
    imgurl2: 'https://www.thesecondshaadi.com/images/sep-1.png',
    name: 'Rachana & Arun',
    year: 'April 13, 2018'
  },
  {
    imgurl1: 'https://www.thesecondshaadi.com/images/s3.jpg',
    imgurl2: 'https://www.thesecondshaadi.com/images/sep-1.png',
    name: 'Varun & Mahima',
    year: 'June 23, 2019'
  },
  {
    imgurl1: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCI8yqpcmg6Oa9HWm_mzv_IjSZNkhQoNdlqA&usqp=CAU',
    imgurl2: 'https://www.thesecondshaadi.com/images/sep-1.png',
    name: 'Rahul & Sneha',
    year: 'March 19, 2020'
  },
];

const HappyStory = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, // Remove slick arrows
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true, // Center mode for responsive view
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true, // Center mode for responsive view
        },
      },
    ],
  };

  return (
    <>
    <div className="happy-main">
      <div
        style={{
          width: "100%",
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <h2 className="happy-story-head">Top Inspiring Story</h2>
        <p style={{ fontSize: "20px", fontWeight: "500" }}>
          Our some success story{" "}
        </p>
      </div>
      <Slider {...settings}>
        {data.map((jan, index) => (
          <div key={index} className="slide">
            <div className="content">
              <img src={jan.imgurl1} alt="Story" width="300px" />
              <div className="info">
                <h6>{jan.name}</h6>
                <Link to="#">{jan.year}</Link>
                <br />
                <img src={jan.imgurl2} alt="icon" />
              </div>
            </div>
          </div>
        ))}
      </Slider>
      
      </div>
    </>
  );
};

export default HappyStory;


