import React, { useState, useEffect } from 'react';
import {
  BsFillPencilFill,

} from "react-icons/bs";

const Newhoroscope = () => {
  const [datahoro, setDatahoro] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedId, setSelectedId] = useState(null);
  const [moon_sign, setmoon_sign] = useState("");
  const [star, setstar] = useState("");
  const [gotra, setgotra] = useState("");
  const [manglik, setmanglik] = useState("");
  const [shani, setshani] = useState("");
  const [hororscope_match, sethororscope_match] = useState("");
  const [place_of_birth, setplace_of_birth] = useState("");
  const [place_of_country, setplace_of_country] = useState("");
  const [hours, sethours] = useState("");
  const [minitues, setminitues] = useState("");
  const [seconds, setseconds] = useState("");
  const [am_pm, setam_pm] = useState("");
  
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://matrimonyadmin.hola9.com/api/Horoscopeapi/horoscopeList/`);
        const jsonData = await response.json();
        setDatahoro(jsonData);
        setIsLoading(false);
        if (jsonData.length > 0) {
          const latestData = jsonData[jsonData.length - 1];
          setSelectedId(latestData.id);
          setmoon_sign(latestData.moon_sign);
          setstar(latestData.star);
          setgotra(latestData.gotra);
          setmanglik(latestData.manglik);
          setshani(latestData.shani);
          sethororscope_match(latestData.hororscope_match);
          setplace_of_birth(latestData.place_of_birth);
          setplace_of_country(latestData.place_of_country);
          sethours(latestData.hours);
          setminitues(latestData.minitues);
          setseconds(latestData.seconds);
          setam_pm(latestData.am_pm);
          
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://matrimonyadmin.hola9.com/api/Horoscopeapi/horoscopeList/${selectedId}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: selectedId,
          moon_sign,
          star,
          gotra,
          manglik,
          shani,
          hororscope_match,
          place_of_birth,
          place_of_country,
          hours,
          minitues,
          seconds,
          am_pm,
         
        }),
      });
      if (response.ok) {
        setDatahoro(prevData => {
          const newData = [...prevData];
          const index = newData.findIndex(item => item.id === selectedId);
          if (index !== -1) {
            newData[index] = {
              id: selectedId,
              moon_sign,
              star,
              gotra,
              manglik,
              shani,
              hororscope_match,
              place_of_birth,
              place_of_country,
              hours,
              minitues,
              seconds,
              am_pm,
            };
          }
          return newData;
        });
        closeModal();
      } else {
        console.error('Failed to update data:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div>
    {/* <div>Newhoroscope</div> */}
    
    <div
        className="feature-block-twop col-lg-4p col-md-6p col-sm-6p wow fadeInUp animated"
        style={{ visibility: "visible", animationName: "fadeInUp" }}
      >
        {datahoro.length > 0 && (
          <div className="inner-boxp">
            <div className="icon-boxp">
              <img
                src="https://www.matrimonysoftware.in/icon/horocope.png"
                alt=""
              />
            </div>
            <h4>
              <a href="Horoscope">
                Horoscope Details{" "}
                <i style={{ fontSize: 24 }} className="fa">
                  
                </i>
              </a>
            </h4>
            <BsFillPencilFill className="editpro"   onClick={openModal}/>

        <div className="textpro"><span>Moon_sign:</span> {moon_sign}</div>
        <div className="textpro"><span>Star:</span> {star}</div>
        <div className="textpro"><span>Gotra: </span>{gotra}</div>
        <div className="textpro"><span>Manglik:</span> {manglik}</div>
        <div className="textpro"><span>Shani:</span> {shani}</div>
        <div className="textpro"><span>Hororscope_match:</span> {hororscope_match}</div>
        <div className="textpro"><span>Place_of_birth: </span>{place_of_birth}</div>
        <div className="textpro"><span>Place_of_country:</span> {place_of_country}</div>
        <div className="textpro"><span>Hours:</span> {hours}</div>
        <div className="textpro"><span>Minitues:</span> {minitues}</div>
        <div className="textpro"><span>Seconds:</span> {seconds}</div>
        <div className="textpro"><span>Am_Pm:</span> {am_pm}</div>
       

            {/* <button className="btn btn-primary" onClick={openModal}>Edit</button> */}
          </div>
        )}
      </div>

      <div className={`modal ${isModalOpen ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: isModalOpen ? 'block' : 'none' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Details</h5>
              <button type="button" className="close" onClick={closeModal}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="moon_sign"  style={{color:"white"}}>Moon_sign:</label>
                  <input type="text" className="form-control" id="moon_sign" value={moon_sign} onChange={(e) => setmoon_sign(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="star"  style={{color:"white"}}>Star:</label>
                  <input type="text" className="form-control" id="star" value={star} onChange={(e) => setstar(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="gotra"  style={{color:"white"}}>Gotra:</label>
                  <input type="text" className="form-control" id="gotra" value={gotra} onChange={(e) => setgotra(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="manglik"  style={{color:"white"}}>Manglik:</label>
                  <input type="text" className="form-control" id="manglik" value={manglik} onChange={(e) => setmanglik(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="shani"  style={{color:"white"}}>Shani:</label>
                  <input type="text" className="form-control" id="shani" value={shani} onChange={(e) => setshani(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="hororscope_match"  style={{color:"white"}}>Hororscope_match:</label>
                  <input type="text" className="form-control" id="hororscope_match" value={hororscope_match} onChange={(e) => sethororscope_match(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="place_of_birth"  style={{color:"white"}}>Place_of_birth:</label>
                  <input type="text" className="form-control" id="place_of_birth" value={place_of_birth} onChange={(e) => setplace_of_birth(e.target.value)} />
                 
                </div>
                <div className="form-group">
                  <label htmlFor="place_of_country"  style={{color:"white"}}>Place_of_country:</label>
                  <input type="text" className="form-control" id="place_of_country" value={place_of_country} onChange={(e) => setplace_of_country(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="hours"  style={{color:"white"}}>Hours:</label>
                  <input type="text" className="form-control" id="hours" value={hours} onChange={(e) => sethours(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="minitues"  style={{color:"white"}}>Minitues:</label>
                  <input type="text" className="form-control" id="minitues" value={minitues} onChange={(e) => setminitues(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="seconds"  style={{color:"white"}}>Seconds:</label>
                  <input type="text" className="form-control" id="seconds" value={seconds} onChange={(e) => setseconds(e.target.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="am_pm"  style={{color:"white"}}>Am_Pm:</label>
                  <input type="text" className="form-control" id="am_pm" value={am_pm} onChange={(e) => setam_pm(e.target.value)} />
                </div>
                <div className="form-group">
                <button type="submit" >Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Newhoroscope