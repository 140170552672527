import React from 'react'
import './Refund.css';
import { useNavigate } from 'react-router-dom';

const Refund = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
   <section className="page-title-return">
       
      </section>
      <button onClick={goBack} className='back-button-pay'><i class="ri-arrow-left-line"></i></button>
      <section className="about-section-refund">
  <div className="auto-container-refund">
    <div className="row-refund">
      {/* Content Column */}
      <div className="content-column-refund col-lg-12-refund col-md-12-refund col-sm-12-refund">
        <div className="inner-column-refund">
          <div className="sec-title_refund" >
          <span className="title_refund" >Returns And Cancellation</span>
  <div className="text-one">
              <p className='text_refund'
              style={{ textAlign: "justify" }}>

                <span
                 style={{ fontSize: 16 }}>
                  <strong >Readymatrimonial.in</strong>&nbsp;believes in helping
                  its customers as far as possible!
                </span>
              </p>
              <p 
              className='text_refund'
              style={{ textAlign: "justify" }}>

                <span
                style={{ fontSize: 16 }}>
                  Please read and understand the details and accept the
                  cancellation and refund policy as it is part of the Terms and
                  Conditions / Terms of Use. Under no circumstances there will
                  be a refund. We may cancel a membership in case we find the
                  member is not following the terms and conditions. The member
                  can cancel his/her membership due to their engagement or other
                  reasons. On any cancellation, there will be no refund. We take
                  customer feedback very seriously and use it to constantly to
                  improve our quality of service. Please send us a mail or reach
                  us through this website to provide feedback.
                </span>
              </p>
              <p className='text_refund'
               style={{ textAlign: "justify" }}>

                <span
                 style={{ fontSize: 16 }}>
                  We do not entertain Return, Refund, and Cancellation in any
                  Circumstances.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </div>
  )
}

export default Refund
