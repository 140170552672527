import React from 'react'
import './Couplegallery.css'
const Couplegallery = () => {


 const gallery = [
{
"id" : "1",
"img" :'https://images.pexels.com/photos/2174662/pexels-photo-2174662.jpeg?auto=compress&cs=tinysrgb&w=600',
},

{
"id" : "2",
"img" : 'https://images.pexels.com/photos/11762791/pexels-photo-11762791.jpeg?cs=srgb&dl=pexels-the-shaan-photography-11762791.jpg&fm=jpg',
},

{  
    "id" : "3",
    "img" : 'https://images.pexels.com/photos/3865895/pexels-photo-3865895.jpeg',
},  

{
    "id" : "4",
    "img" :'https://images.pexels.com/photos/2060240/pexels-photo-2060240.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
}, 

{
    "id" : "5",
    "img" :'https://images.pexels.com/photos/3872614/pexels-photo-3872614.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
}, 

{
    "id" : "6",
    "img" :'https://images.pexels.com/photos/730055/pexels-photo-730055.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
},

{
    "id" : "7",
    "img" :'https://images.pexels.com/photos/3455741/pexels-photo-3455741.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
}, 

{
    "id" : "8",
    "img" :'https://images.pexels.com/photos/1024975/pexels-photo-1024975.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
}, 

{
    "id" : "9",
    "img" :'https://images.pexels.com/photos/8476226/pexels-photo-8476226.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
}, 

{
    "id" : "10",
    "img" :'https://images.pexels.com/photos/7184081/pexels-photo-7184081.jpeg?auto=compress&cs=tinysrgb&w=600',
}, 

{
    "id" : "11",
    "img" :'https://images.pexels.com/photos/12634423/pexels-photo-12634423.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
}, 

{
    "id" : "12",
    "img" :'https://images.pexels.com/photos/8817899/pexels-photo-8817899.jpeg',
},
{
    "id" : "13",
    "img" :'https://images.pexels.com/photos/15698723/pexels-photo-15698723/free-photo-of-newlywed-couple-during-a-wedding-ceremony.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
},
{
    "id" : "14",
    "img" :'https://images.pexels.com/photos/13123607/pexels-photo-13123607.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
},
]
  return (
    <>
    <div style={{display:'flex',justifyContent:'center',justifyContent:'center',alignItems:'center',backgroundColor:'black',color:'white'}}>
        <h1>Couple Gallery</h1>
    </div>

    <div style={{width:'95%', margin:'auto', display:'flex', justifyContent:'space-evenly', marginTop:'20px'}} className='gallery-colmns'>
        <div style={{width:'31%'}}>
                      {gallery.slice(0, 7).map((data) => (
                <img src= {data.img} style={{marginBottom:'15px'}}/>
                ))}
        </div>

        <div style={{width:'31%'}}>
                                  {gallery.slice(5, 12,).map((data) => (
                <img src= {data.img} style={{marginBottom:'15px'}}/>
                ))}
        </div>

        <div style={{width:'31%'}}>
        {gallery.slice(6, 14).map((data) => (
                <img src= {data.img} style={{marginBottom:'15px'}}/>
                ))}
        </div>
    </div>
    </>
  )
}

export default Couplegallery
