import React, { useState } from "react";
import "./userstyle.css";
import {
  BsPencilSquare,
  BsPersonHeart,
  BsFillStarFill,
//   BsPencilSquare
} from "react-icons/bs";
import { MdFamilyRestroom } from "react-icons/md";
import { GiLovers } from "react-icons/gi";
// import Complete_profile from "./Complete_profile.jsx";
// import Bas from "./Bas";
// import Edu from "./Edu";
// import Fam from "./Fam";
// import Horo from "./Horo";
// import Part from "./Part";


const UserProfiles = () => {
  const [toggle, setToggle] = useState(true);
  const [active, setActive] = useState("Bas");
  const [profilePicture, setProfilePicture] = useState(
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREB7FC1k3nxOMlqRHPWG-j6mXUcysRIgm1GA&usqp=CAU"
  );

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicture(reader.result);
      };
      reader.readAsDataURL(file); 
    }
  };

    return (
        <div>
  
                <div className="rowUP mt-1 rounded-lgUP " style={{width:"100%"}}>
                    <div className="col-lg-3UP text-center" style={{ height: "fit-content", marginRight: "5px", marginLeft: "5px",  border: "1px solid #6c757d", borderRadius: "10px", backgroundColor:"#AED6F1 ",padding:"5px"  }}>
                        <div className="cardUP text-center" style={{ color: "black" }}>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item" >
                                    <button
                                        onClick={() => {
                                            setActive("Bas");
                                            setToggle(!toggle);

                                        }}
                                        style={{ fontSize: "18px", display: "flex", background: "none",  }}
                                    >
                                        <BsPersonHeart className="mt-1" /> &nbsp;Basic Details&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<BsPencilSquare className="mt-1" />&nbsp;edit
                                    </button>
                                    
                                </li>
                                <hr style={{ color: "#80808017" }} />
                                <li className="list-group-item p-4">
                                    {" "}
                                    <button
                                        onClick={() => {
                                            setActive("Edu");
                                            setToggle(!toggle);
                                        }}
                                        style={{ fontSize: "18px", display: "flex", background: "none",  }}
                                    >
                                        <BsPencilSquare className="mt-1" />
                                        &nbsp; Education details&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;<BsPencilSquare className="mt-1" />&nbsp;edit
                                    </button>
                                </li>
                                <hr style={{ color: "#80808017" }} />
                                <li className="list-group-item p-4">
                                    {" "}
                                    <button
                                        onClick={() => {
                                            setActive("Fam");
                                            setToggle(!toggle);
                                        }}
                                        style={{ fontSize: "18px", display: "flex", background: "none",  }}
                                    >
                                        <MdFamilyRestroom className="mt-1" />
                                        &nbsp; family details&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;<BsPencilSquare className="mt-1" />&nbsp;edit
                                    </button>
                                </li>
                                <hr style={{ color: "#80808017" }} />
                                <li className="list-group-item p-4">
                                    {" "}
                                    <button
                                        onClick={() => {
                                            setActive("Horo");
                                            setToggle(!toggle);
                                        }}
                                        style={{ fontSize: "18px", display: "flex", background: "none",  }}
                                    >
                                        <BsFillStarFill className="mt-1" />
                                        &nbsp; horoscope Details&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <BsPencilSquare className="mt-1" />&nbsp;edit
                                    </button>

                                </li>
                                <hr style={{ color: "#80808017" }} />
                                <li className="list-group-item p-4">
                                    {" "}
                                    <button
                                        onClick={() => {
                                            setActive("Part");
                                            setToggle(!toggle);
                                        }}
                                        style={{ fontSize: "18px", display: "flex", background: "none",  }}
                                    >
                                        <GiLovers className="mt-1" />
                                        &nbsp; Preference Details&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <BsPencilSquare className="mt-1" />&nbsp;edit
                                    </button>

                                </li>
                            </ul>

                        </div>
                    </div>
                    {/* <Complete_profile/> */}
                    <div style={{ border: "1px solid gray", borderRadius:"10px" , width:"960px" }}>
                        {active === "Bas" && (
                            <div className="row">
                                {/* <div className="col-lg-2"></div> */}
                                <div className="col-lg-7">
                                    {/* <p
                                        // className="mb-2 text-center font-bold text-decoration-underline"
                                        style={{ fontSize: "38px", paddingTop: "20px", color: "#FF709A", textAlign: "center", fontFamily: 'areal', }}

                                    >
                                        <ins>Basic Details</ins>
                                    </p> */}
                                    {/* <Bas/> */}
                                </div>
                            </div>
                        )}

          {active === "Edu" && (
            <div className="row">
              <div className="col-lg-2"></div>
              <div className="col-lg-7 md-5">
                <p
                  style={{
                    fontSize: "38px",
                    paddingTop: "20px",
                    color: "#FF709A",
                    textAlign: "center",
                    fontFamily: "areal",
                  }}
                >
                  <ins>Education Details</ins>
                </p>
                {/* <Edu /> */}
              </div>
            </div>
          )}

          {active === "Fam" && (
            <div className="row">
              <div className="col-lg-2"></div>
              <div className="col-lg-8">
                <p
                  style={{
                    fontSize: "38px",
                    paddingTop: "20px",
                    color: "#FF709A",
                    textAlign: "center",
                    fontFamily: "areal",
                  }}
                >
                  <ins>Family Details</ins>
                </p>
                {/* <Fam/> */}
              </div>
            </div>
          )}

          {active === "Horo" && (
            <div className="row">
              <div className="col-lg-2"></div>
              <div className="col-lg-7">
                <p
                  style={{
                    fontSize: "38px",
                    paddingTop: "20px",
                    color: "#FF709A",
                    textAlign: "center",
                    fontFamily: "areal",
                  }}
                >
                  <ins>Horoscope Details</ins>
                </p>
                {/* <Horo /> */}
              </div>
            </div>
          )}

          {active === "Part" && (
            <div className="row">
              <div className="col-lg-2"></div>
              <div className="col-lg-7">
                <p
                  style={{
                    fontSize: "38px",
                    paddingTop: "20px",
                    color: "#FF709A",
                    textAlign: "center",
                    fontFamily: "areal",
                  }}
                >
                  <ins>Partner Preference Details</ins>
                </p>
                {/* <Part /> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfiles;