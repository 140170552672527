import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Compatabilitydetails.css";

const Compatabilitydetails = () => {
  const navigate = useNavigate();
  const [compatibilityData, setCompatibilityData] = useState(null);

  useEffect(() => {
    // Fetch compatibility data here
    axios
      .get("https://matrimonyadmin.hola9.com/api/crud/compatibilityapi/")
      .then((response) => {
        setCompatibilityData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching compatibility data:", error);
      });
  }, []);

  // If data is not yet fetched, show loading or any other indication
  if (!compatibilityData) {
    return <div>Loading...</div>;
  }

  // Render compatibility data
  return (
    <div className="container-compatibility-details">
      <div
        style={{
          width: "100%",
          height: "60px",
          display: "flex",
          justifyContent: "center",
          color: "#ec167f",
        }}
      >
        <h1 className="title-compatability-details">Compatability Details</h1>
      </div>

      <div className="compatibility-details">
        <table className="compatibility-table">
          <tr>
            <th>Question</th>
            <th>Answer</th>
          </tr>

          <tr>
            <td>
              <h6>How often do you go out:</h6>
            </td>
            <td>
              <h6>{compatibilityData.How_often_do_you_go_out}</h6>
            </td>
          </tr>
          <tr>
            <td>
              <h6>How would you describe your clothes:</h6>
            </td>
            <td>
              <h6>{compatibilityData.How_would_you_describe_your_clothes}</h6>
            </td>
          </tr>
          <tr>
            <td>
              <h6>How do you spend your free time:</h6>
            </td>
            <td>
              <h6>{compatibilityData.How_do_you_spend_your_free_time}</h6>
            </td>
          </tr>
          <tr>
            <td>
              <h6>How many times do you visit salon or beauty parlour:</h6>
            </td>
            <td>
              <h6>
                {
                  compatibilityData.How_many_times_do_you_visit_salon_or_beauty_parlour
                }
              </h6>
            </td>
          </tr>
          <tr>
            <td>
              <h6>How many times do you go out drinking or in a pub:</h6>
            </td>
            <td>
              <h6>
                {
                  compatibilityData.How_many_times_do_you_go_out_drinking_or_in_a_pub
                }
              </h6>
            </td>
          </tr>
          <tr>
            <td>
              <h6>Which social platform do you use most:</h6>
            </td>
            <td>
              <h6>{compatibilityData.Which_social_platform_do_you_use_Most}</h6>
            </td>
          </tr>
          <tr>
            <td>
              <h6>Do you like shopping:</h6>
            </td>
            <td>
              <h6>{compatibilityData.Do_you_like_shopping}</h6>
            </td>
          </tr>
          <tr>
            <td>
              <h6>Preferences while traveling:</h6>
            </td>
            <td>
              <h6>{compatibilityData.Preferences_while_traveling}</h6>
            </td>
          </tr>
          <tr>
            <td>
              <h6>Which personality are you:</h6>
            </td>
            <td>
              <h6>{compatibilityData.Which_personality_are_you}</h6>
            </td>
          </tr>
        </table>
      </div>

      <button onClick={() => navigate(-1)} className="back-button-pay">
        <i className="ri-arrow-left-line"></i>
      </button>
    </div>
  );
};

export default Compatabilitydetails;
