import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./basicdetailsStyle.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { casteOptions } from "./Basicdetailsdata";
import { requiredFields } from "./Basicdetailsdata";
import { validationMessages } from "./Basicdetailsdata";
import { initialData } from "./Basicdetailsdata";
import { genderOptions } from "./Basicdetailsdata";
import { bloodGroupOptions } from "./Basicdetailsdata";
import { religionOptions } from "./Basicdetailsdata";
import { profileCreatedByOptions } from "./Basicdetailsdata";
import { maritalStatusOptions } from "./Basicdetailsdata";

const Basicdetails = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const [data, setData] = useState(initialData);
  const [casteSearchTerm, setCasteSearchTerm] = useState("");
  const [selectedCaste, setSelectedCaste] = useState("");
  const casteDropdownRef = useRef(null);
  const [isCasteDropdownOpen, setIsCasteDropdownOpen] = useState(false);

  const handleCasteInputChange = ({ target: { value } }) =>
    setCasteSearchTerm(value);

  const handleSelectChange = (value) => {
    setSelectedCaste(value);
    setData({ ...data, your_caste: value });
    setIsCasteDropdownOpen(false);
  };

  const filteredCasteOptions = casteOptions.filter((option) =>
    option.label.toLowerCase().includes(casteSearchTerm.toLowerCase())
  );

  const toggleDropdown = () => setIsCasteDropdownOpen((prev) => !prev);

  const handleClickOutside = (event) => {
    if (!casteDropdownRef.current?.contains(event.target)) {
      setIsCasteDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const showToastErrorMessage = () => {
    toast.error("Please fill all fields", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const [error, setError] = useState(null);
  const [formError, setFormError] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const ChangeHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const validateForm = (data) => {
    let err = {};
    // Check if any required field is empty
    const isAnyFieldEmpty = requiredFields.some((field) => !data[field]);
    if (isAnyFieldEmpty) {
      showToastErrorMessage();
    }
    // Validate fields
    for (const [field, message] of Object.entries(validationMessages)) {
      if (field === "D_O_B" && calculateAge(data.D_O_B) < 21) {
        err[field] = message;
      } else if (data[field] === "" || data[field] === undefined) {
        err[field] = message;
      }
    }
    // Set form errors
    setFormError(err);
    // Return true if no errors, otherwise false
    return Object.keys(err).length === 0;
  };

  const calculateAge = (D_O_B) => {
    const birthDate = new Date(D_O_B);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    return today.setFullYear(1970) < birthDate.setFullYear(1970) ? --age : age;
  };

  const ageOptions = (Gender) =>
    Gender === "Male"
      ? Array.from({ length: 26 }, (_, i) => i + 21)
      : Gender === "Female"
      ? Array.from({ length: 28 }, (_, i) => i + 18)
      : [];

  const showData = async () => {
    try {
      if (!validateForm(data)) return;

      const {
        data: { id },
      } = await axios.post(
        "https://matrimonyadmin.hola9.com/api/BasicinformationPageapi/BasicInformationList/",
        data
      );

      setData((prev) => ({ ...prev, id }));
      setShowForm(true);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <>
        <div className="page-wrapperb">
          <section className="page-titleb" style={{ backgroundColor: "black" }}>
            <div className="auto-containerb">
              <h1 className="d-noneb d-lg-blockb d-xl-blockb d-md-blockb">
                Basic Details
              </h1>
            </div>
          </section>
          <button onClick={goBack} className="back-button-pay">
            <i class="ri-arrow-left-line"></i>
          </button>
          <section className="newsletter-sectionb contact-page-sectionb">
            <div className="auto-containerb">
              <div className="alertmesb"></div>
              <div className="rowb clearfixb mt-3b">
                <div className="form-columnb col-lg-9b col-md-12b col-sm-12b">
                  <div className="envelope-imageb" />
                  <div className="form-innerb">
                    <div className="contact-formb ">
                      <div className="rowb clearfixb  mt-2b ">
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Name </label>
                          <input
                            type="text"
                            className="agile-ltextb"
                            placeholder="Enter your Name"
                            name="Name"
                            value={data.Name}
                            onChange={ChangeHandler}
                            readOnly=""
                          />
                          <span style={{ color: "red" }}>{formError.Name}</span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Surname </label>
                          <input
                            type="text"
                            className="agile-ltextb"
                            placeholder="Enter your Name"
                            name="Surname"
                            value={data.Surname}
                            onChange={ChangeHandler}
                            readOnly=""
                          />
                          <span style={{ color: "red" }}>
                            {formError.Surname}
                          </span>
                        </div>

                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Email </label>
                          <input
                            type="text"
                            placeholder="Email"
                            className="agile-ltextb"
                            name="Email"
                            value={data.Email}
                            onChange={ChangeHandler}
                          />
                          <span style={{ color: "red" }}>
                            {formError.Email}
                          </span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Mobile Number </label>
                          <input
                            type="text"
                            name="Mobile_Number"
                            placeholder="Mobile Number"
                            className="agile-ltextb"
                            maxLength={14}
                            onChange={ChangeHandler}
                            value={data.Mobile_Number}
                            readOnly=""
                          />
                          <span style={{ color: "red" }}>
                            {formError.Mobile_Number}
                          </span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb">Date Of Birth </label>
                          <input
                            type="datetime-local"
                            className="agile-ltextb"
                            placeholder="Date of birth"
                            name="D_O_B"
                            value={data.D_O_B}
                            onChange={ChangeHandler}
                            readOnly=""
                            fdprocessedid="tgf31"
                          />
                          <span style={{ color: "red" }}>
                            {formError.D_O_B}
                          </span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Age </label>
                          <select
                            type="text"
                            className="agile-ltextb"
                            placeholder="Enter your age"
                            name="Age"
                            onChange={ChangeHandler}
                            value={data.Age}
                          >
                            <option value="" selected="">
                              Select your Age
                            </option>
                            {ageOptions(data.Gender).map((age) => (
                              <option key={age} value={age}>
                                {age}
                              </option>
                            ))}
                          </select>
                          <span style={{ color: "red" }}>{formError.Age}</span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Height </label>
                          <select
                            type="text"
                            className="agile-ltextb"
                            placeholder="Select Height"
                            name="Height"
                            onChange={ChangeHandler}
                            value={data.Height}
                            readOnly=""
                            fdprocessedid="jdvgst"
                          >
                            <option value="">Select Height</option>
                            {Array.from({ length: 37 }, (_, i) => {
                              const feet = Math.floor(i / 12) + 4;
                              const inches = i % 12;
                              const label =
                                inches === 0
                                  ? `${feet}Ft`
                                  : `${feet}Ft ${inches} inch`;
                              return (
                                <option key={i} value={i + 1}>
                                  {label}
                                </option>
                              );
                            })}
                          </select>
                          <span style={{ color: "red" }}>
                            {formError.Height}
                          </span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Blood_Group </label>
                          <select
                            type="text"
                            className="agile-ltextb"
                            placeholder="Blood_Group "
                            name="Blood_Group"
                            value={data.Blood_Group}
                            onChange={ChangeHandler}
                            readOnly=""
                            fdprocessedid="bwswso"
                          >
                            <option value="">Select Your Blood Group</option>
                            {bloodGroupOptions.map((group) => (
                              <option key={group} value={group.toLowerCase()}>
                                {group}
                              </option>
                            ))}
                          </select>
                          <span style={{ color: "red" }}>
                            {formError.Gender}
                          </span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Gender </label>
                          <select
                            type="text"
                            className="agile-ltextb"
                            placeholder="Gender "
                            name="Gender"
                            value={data.Gender}
                            onChange={ChangeHandler}
                            readOnly=""
                            fdprocessedid="bwswso"
                          >
                            <option value="">Select Your Gender</option>
                            {genderOptions.map((gender) => (
                              <option key={gender} value={gender}>
                                {gender}
                              </option>
                            ))}
                          </select>
                          <span style={{ color: "red" }}>
                            {formError.Gender}
                          </span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb">Religion </label>
                          <select
                            type="text"
                            className="agile-ltextb"
                            placeholder="Religion "
                            name="your_religion"
                            value={data.your_religion}
                            onChange={ChangeHandler}
                            readOnly=""
                            fdprocessedid="0eqdt9"
                          >
                            <option value="">Select Religion</option>
                            {religionOptions.map((your_religion) => (
                              <option key={your_religion} value={your_religion}>
                                {your_religion}
                              </option>
                            ))}
                          </select>
                          <span style={{ color: "red" }}>
                            {formError.your_religion}
                          </span>
                        </div>

                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb">Profile Created By </label>
                          <select
                            type="text"
                            className="agile-ltextb"
                            placeholder=" Profile Created By"
                            name="profile_created_By"
                            value={data.profile_created_By}
                            onChange={ChangeHandler}
                            readOnly=""
                            fdprocessedid="tgf31"
                          >
                            {profileCreatedByOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          <span style={{ color: "red" }}>
                            {formError.profile_created_By}
                          </span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Marital_Status </label>
                          <select
                            type="text"
                            className="agile-ltextb"
                            placeholder="Enter your Name"
                            name="marital_status"
                            value={data.marital_status}
                            onChange={ChangeHandler}
                            readOnly=""
                            fdprocessedid="j85x88"
                          >
                            {maritalStatusOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          <span style={{ color: "red" }}>
                            {formError.marital_status}
                          </span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb">Caste</label>
                          <div
                            className="custom-dropdown-basic"
                            ref={casteDropdownRef}
                          >
                            <div
                              className="selected-option-basic"
                              onClick={toggleDropdown}
                            >
                              {selectedCaste ? selectedCaste : "Select Caste"}
                            </div>
                            {isCasteDropdownOpen && (
                              <div className="dropdown-menu-basic">
                                <input
                                  type="text"
                                  className="custom-select-box-basic"
                                  placeholder="Search Caste"
                                  value={casteSearchTerm}
                                  onChange={handleCasteInputChange}
                                  autoFocus
                                />
                                <div className="dropdown-options-basic">
                                  {filteredCasteOptions.map((option, index) => (
                                    <div
                                      key={index}
                                      className="dropdown-option-basic"
                                      onClick={() =>
                                        handleSelectChange(option.label)
                                      }
                                    >
                                      {option.label}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                          <span style={{ color: "red" }}>
                            {formError.your_caste}
                          </span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Subcaste </label>
                          <input
                            type="text"
                            className="agile-ltextb"
                            placeholder="Subcaste"
                            name="sub_caste"
                            value={data.sub_caste}
                            onChange={ChangeHandler}
                            readOnly=""
                            fdprocessedid="gk0h8"
                          />
                          <span style={{ color: "red" }}>
                            {formError.sub_caste}
                          </span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> About Yourself</label>
                          <textarea
                            type="textarea"
                            className="agile-ltextba"
                            name="about_yourself"
                            value={data.about_yourself}
                            onChange={ChangeHandler}
                            placeholder="Say Something About You"
                            rows={4}
                            maxLength={250}
                            tabIndex={10}
                            defaultValue={""}
                          />
                          <span style={{ color: "red" }}>
                            {formError.about_yourself}
                          </span>
                        </div>
                        <div className="col-lg-10b col-md-10b col-sm-6b form-groupb">
                          {!showForm && (
                            <div>
                              {!isSubmitted && (
                                <button
                                  className="theme-btnb btnb btn-style-oneb"
                                  fdprocessedid="jqtd0p"
                                  onClick={showData}
                                >
                                  <span className="btn-title" tabIndex={17}>
                                    Submit
                                  </span>
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <ToastContainer style={{ marginTop: "100px" }} />
                      {error ? (
                        <div
                          className="theme-text -style-one mt-4"
                          style={{ color: "red" }}
                        >
                          <h4>{error}</h4>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    </div>
  );
};
export default Basicdetails;
