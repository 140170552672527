import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import Footer from '../Footer/Footer';
import Header from '../Homepage/Header';
import './Privacy.css';

const Privacy = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  // State to track whether full text is shown
  const [showFullText, setShowFullText] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  // Function to toggle showing full text
  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
  };

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <Header />
      <section className="page-title-privacy"></section>
      <button onClick={goBack} className="back-button-pay">
        <i className="ri-arrow-left-line"></i>
      </button>
      <section className="about-section_privacy">
        <div className="auto-container_privacy">
          <div className="row-privacy" style={{ paddingLeft: '30px' }}>
            {/* Content Column */}
            <div className="content-column-privacy col-lg-12-privacy col-md-12-privacy col-sm-12-privacy">
              <div className="inner-column-privacy">
                <div className="sec-title_privacy">
                  <span className="title_privacy">Privacy policy</span>

                  {/* Render text with toggle based on state and screen size */}
                  <p className="text_privacy_one">
                    This electronic website is being operated and owned by www.readymatrimonial.in. This
                    PRIVACY POLICY STATEMENT is made/published in the internet web site to protect the user’s
                    privacy and it is connected to our terms and conditions.
                  </p>
                  <p className="text_privacy_one">
                    A user/member, when he is entering our web site after accepting our full terms
                    and conditions of www.readymatrimonial.in should provide the mandatory information, he has
                    the option of not providing the information which is not mandatory. User/Member is solely
                    responsible for maintaining confidentiality of the User Name/Identity and User Password
                    and all activities and transmission/transactions performed by the User through his/her
                    user identity/name and shall be solely responsible for carrying out any online or off-line
                    transactions involving credit/debit cards or such other forms of instruments or documents
                    for making such transactions. As such, while doing so any negligence of your act,
                    www.readymatrimonial.in assumes no responsibility / liability for their improper use of
                    information relating to such usage of credit/debit cards used by the subscriber online/offline.
                  </p>
                  <p className="text_privacy_one">
                    www.readymatrimonial.in is connected / link to service partners, such as servers/administrators.
                    We may use your IP address and other information provided by like Email address, Contact name,
                    User-created password, Address , Pin code, Telephone number or other contact number etc; to
                    help diagnose problems with our server, and to manage our Web site. Your IP address may be
                    also used to gather broad demographic information. And the information will be used by us to
                    contact you and to deliver information to you that, in some cases, are targeted to your interests,
                    such as targeted banner advertisements, administrative notices, product offerings, and communications
                    relevant to your use of the web site. To receive such information, you accept for our terms and
                    condition and privacy policy.
                  </p>

                  {/* Render additional content based on state and screen size */}
                  {showFullText || !isSmallScreen ? (
                    <>
                      <p className="text_privacy_one">
                        Unless otherwise you give your consent, it doesn’t sell, rent, share, trade or give away or share
                        with any third party. The users who enter into site such as Builders, Agents/Brokers or any individual
                        has provided their contact information for advertisement on our portal then users can contact them at
                        their request through us.
                      </p>
                      <p className="text_privacy_one">
                        Any changes in the privacy policy will be changed without any prior notice to any type of users,
                        of our web site. We suggest you to review our privacy policy from time to time/ periodically, so
                        as to see if any changes are made.
                      </p>
                      <p className="text_privacy_one">
                        www.readymatrimonial.in cannot be held liable for any errors or inconsistencies. But we take every
                        care to give you accuracy and clarity of the information.
                      </p>
                      <p className="text_privacy_one">
                        www.readymatrimonial.in disclaims any and all responsibility or liability for the accuracy, content,
                        completeness, legality, reliability, or operability or availability of information or material displayed
                        on this web site by the third parties...
                      </p>
                      <p className="text_privacy_one">Contact : info@readymatrimonial.in for further clarifications.</p>
                    </>
                  ) : null}

                  {isSmallScreen && (
                    <button className="read-more" onClick={toggleShowFullText}>
                      {showFullText ? 'Show less' : 'Show more'}
                    </button>
                  )}

                  <div className={`text_privacy ${showFullText ? 'expanded' : ''}`}>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer /> */}
    </div>
  );
};

export default Privacy;
