import React, { useState, useRef } from 'react';
import Newbasic from './Newbasic';
import Newfamily from './Newfamily';
import Newhabbits from './Newhabbits';
import Newhobby from './Newhobby';
import Newhoroscope from './Newhoroscope';
import Newinterest from './Newinterest';
import Newpartner from './Newpartner';
import Newyourprofile from './Newyourprofile';
import NewEdu from './NewEdu';
import Newcontact from './Newcontact';
import './Myprofilefile.css'
import { useNavigate } from 'react-router-dom';
import Newidproof from './Newidproof';



const Myprofilefile = () => {
  const navigate = useNavigate();

  const [profilePicture, setProfilePicture] = useState(
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREB7FC1k3nxOMlqRHPWG-j6mXUcysRIgm1GA&usqp=CAU'
  );

  const fileInputRef = useRef(null);

  const handlePhotoIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const [showNewBasic, setShowNewBasic] = useState(false); // State to control the visibility of Newbasic component
  const [showNewhoroscope, setShowNewhoroscope] = useState(false);
  const [showNewhabbits, setShowNewhabbits] = useState(false);
  const [showNewhobby, setShowNewhobby] = useState(false);
  const [showNewfamily, setShowNewfamily] = useState(false);
  const [showNewinterest, setShowNewinterest] = useState(false);
  const [showNewpartner, setShowNewpartner] = useState(false);
  const [showNewyourprofile, setShowNewyourprofile] = useState(false);
  const [showNewEdu, setShowNewEdu] = useState(false);
  const [showNewcontact, setShowNewcontact] = useState(false);
  const [showIdProof, setShowIdProof] = useState(false);

  const goBack = () => {
    navigate(-1);


  };
  return (
    <div>

      <button onClick={goBack} className='back-button-paypro1'><i class="ri-arrow-left-line"></i></button>

      <div className="main-container-one">
        <div className="main-profile-one">
          <div id="header-profile">
            <div className="Image-profile-one" >
              <div onClick={handlePhotoIconClick} style={{ cursor: 'pointer' }}>
                <div>
                  <img
                    id="user-picture"
                    src={profilePicture}
                    alt="User"
                    style={{ zIndex: 1 }}
                  />

                </div>
              </div>
              <div className="Name-employe-one">
                <div id="user-name">
                  <b>Krishna Murthy</b>
                </div>
                <div id="user-email">krishna090@example.com</div>
                <div id="user-profession">Web Developer</div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <section className="about-section-two-indexpro mt-4pro mt-4pro d-lg-blockpro d-xl-blockpro d-md-blockpro">

        <div className="anim-iconspro full-widthpro">
          <span
            className="icon icon-circle-1 wow zoomIn animated"
            style={{ visibility: "visible", animationName: "zoomIn" }}
          />
        </div>

        <div className="schedule-tabspro tabs-boxpro">
          <div className="btns-box">
            {/*Tabs Box*/}
            <ul className="tab-buttonspro clearfixpro">

              <li className=" active-btnpro spcespro">
                <span className="day">Mutual Matches</span>
                <a href='/Mutualmatches2'>
                  <span className="datepro">0</span>
                </a>
              </li>


              <a href="mutual_matches"> </a>

              <li className="active-btnpro specspro" data-tab="#tab-1">
                <a href="/NewShortList">
                  <span className="day">New Shortlists</span>

                  <span className="datepro">0</span>
                </a>
              </li>


              <li className="active-btnpro specspro" data-tab="#tab-2">
                <span className="day">New Interest</span>
                <a href="/Newintrest">
                  <span className="datepro">0</span>
                </a>
              </li>


              <li className="active-btnpro specspro" data-tab="#tab-3">
                <span className="day">New Viewed</span>
                <a href="/NewViewe">
                  <span className="datepro">0</span>
                </a>
              </li>

              <li className="active-btnpro specspro" data-tab="#tab-4">
                <span className="day">Message</span>
                <a href="/Receivedmessage">
                  <span className="datepro">0</span>
                </a>
              </li>

            </ul>
          </div>
        </div>
      </section>


      <div className="auto-containerp" style={{ backgroundColor: "#6c757d", marginBottom: "5%", padding: "0.5%" }}>

        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
                onClick={() => setShowNewBasic(!showNewBasic)} // Toggle the visibility of Newbasic component
                style={{ fontSize: "25px", color: " #f20487" }}
              >
                Basic Details
              </button>
            </h2>

            <div
              id="flush-collapseOne"
              class={`accordion-collapse collapse ${showNewBasic ? 'show' : ''}`} // Conditionally apply 'show' class based on state
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <Newbasic /> {/* Render Newbasic component here */}
              </div>
            </div>
          </div>


          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
                onClick={() => setShowNewhoroscope(!showNewhoroscope)} // Toggle the visibility of Newbasic component
                style={{ fontSize: "25px", color: " #f20487" }}
              >
                Horoscope Details
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class={`accordion-collapse collapse ${showNewhoroscope ? 'show' : ''}`} // Conditionally apply 'show' class based on state
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <Newhoroscope />
              </div>
            </div>
          </div>


          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
                onClick={() => setShowNewhabbits(!showNewhabbits)} // Toggle the visibility of Newbasic component
                style={{ fontSize: "25px", color: " #f20487" }}
              >
                Habbits Details
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class={`accordion-collapse collapse ${showNewhabbits ? 'show' : ''}`} // Conditionally apply 'show' class based on state
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <Newhabbits />
              </div>
            </div>
          </div>


          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
                onClick={() => setShowNewhobby(!showNewhobby)} // Toggle the visibility of Newbasic component
                style={{ fontSize: "25px", color: " #f20487" }}
              >
                Hobby Details
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class={`accordion-collapse collapse ${showNewhobby ? 'show' : ''}`} // Conditionally apply 'show' class based on state
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <Newhobby />
              </div>
            </div>
          </div>


          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
                onClick={() => setShowNewfamily(!showNewfamily)} // Toggle the visibility of Newbasic component
                style={{ fontSize: "25px", color: " #f20487" }}
              >
                Family Details
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class={`accordion-collapse collapse ${showNewfamily ? 'show' : ''}`} // Conditionally apply 'show' class based on state
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <Newfamily />
              </div>
            </div>
          </div>


          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
                onClick={() => setShowNewinterest(!showNewinterest)} // Toggle the visibility of Newbasic component
                style={{ fontSize: "25px", color: " #f20487" }}
              >
                Interest Details
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class={`accordion-collapse collapse ${showNewinterest ? 'show' : ''}`} // Conditionally apply 'show' class based on state
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <Newinterest />
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
                onClick={() => setShowNewpartner(!showNewpartner)} // Toggle the visibility of Newbasic component
                style={{ fontSize: "25px", color: " #f20487" }}
              >
                Partner Details
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class={`accordion-collapse collapse ${showNewpartner ? 'show' : ''}`} // Conditionally apply 'show' class based on state
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <Newpartner />
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
                onClick={() => setShowNewyourprofile(!showNewyourprofile)} // Toggle the visibility of Newbasic component
                style={{ fontSize: "25px", color: " #f20487" }}
              >
                Yourprofile Details
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class={`accordion-collapse collapse ${showNewyourprofile ? 'show' : ''}`} // Conditionally apply 'show' class based on state
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <Newyourprofile />
              </div>
            </div>
          </div>


          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
                onClick={() => setShowNewEdu(!showNewEdu)} // Toggle the visibility of Newbasic component
                style={{ fontSize: "25px", color: " #f20487" }}
              >
                Education Details
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class={`accordion-collapse collapse ${showNewEdu ? 'show' : ''}`} // Conditionally apply 'show' class based on state
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <NewEdu />
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
                onClick={() => setShowNewcontact(!showNewcontact)} // Toggle the visibility of Newbasic component
                style={{ fontSize: "25px", color: " #f20487" }}
              >
                Contact Details
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class={`accordion-collapse collapse ${showNewcontact ? 'show' : ''}`} // Conditionally apply 'show' class based on state
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <Newcontact />
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
                onClick={() => setShowIdProof(!showIdProof)} // Toggle the visibility of Newbasic component
                style={{ fontSize: "25px", color: " #f20487" }}
              >
                Id proof
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class={`accordion-collapse collapse ${showIdProof ? 'show' : ''}`} // Conditionally apply 'show' class based on state
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                {/* <Newidproof />  */}
              </div>
            </div>
          </div>

          {/* <div className="rowp">
          <Newbasic />
          <Newhoroscope />
          <Newhabbits />
          <Newhobby />
          <Newfamily />
          <Newinterest />
          <Newpartner />
          <Newyourprofile />
          <NewEdu />
          <Newcontact />
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Myprofilefile;
