import React from "react";
import "./AppDownloadStyle.css";
const AppDownload = () => {
  return (
    <div>
      <section className="app-sectionapp">
        <div className="auto-containerapp">
          <div className="rowapp">
            <div className="content-column col-lg-6 col-md-6 col-sm-6">
              <div className="inner-columnapp">
                <div className="sec-titleapp">
                  <span className="title-App">App Download</span>
                  <h2 className="App-title">made4eachother.hola9.com</h2>
                </div>
                <div className="text-boxapp">
                  Connect with your partner for FREE from safety of your home
                  Download our Mobile App which are the Best way to find your
                  Perfect Match. which is very easy, simple, fast, Convenient,
                  and safe &amp; secure.
                  <p className="subtext-boxapp">
                    Thousands of happy marriages happened through our Matrimony.
                    <b>Yours could be next... </b>Download Now..!!
                  </p>
                </div>
              </div>
            </div>
            <div className="image-column col-lg-6 col-md-6 col-sm-6">
              <div className="inner-columnapp">
                <div className="image-boxapp">
                  <figure
                    className="image wow fadeInRight animated"
                    style={{
                      visibility: "visible",
                      animationName: "fadeInRight",
                    }}
                  >
                    <img
                      src="https://images.unsplash.com/photo-1607252650355-f7fd0460ccdb?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YW5kcm9pZCUyMGFwcHxlbnwwfHwwfHx8MA%3D%3D"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default AppDownload;
