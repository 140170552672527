import React, { useState } from 'react';
import axios from 'axios';
import './ContactusStyle.css';
import { useNavigate } from 'react-router-dom';

function Contactus() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const [formData, setFormData] = useState({
    Email: '',
    Mobile_Number: '',
    Alternative_Mobile_Number: '',
    Convinient_Time_To_Call: '',
    Show_Working_Address: '',
    Show_Permanent_Address: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://matrimonyadmin.hola9.com/api/Contackdetailsapi/Contactdetails/', formData);
      console.log('Response:', response.data);
      // Reset form after successful submission if needed
      setFormData({
        Email: '',
        Mobile_Number: '',
        Alternative_Mobile_Number: '',
        Convinient_Time_To_Call: '',
        Show_Working_Address: '',
        Show_Permanent_Address: ''
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className='contactus-container'>
      {/* Back Button */}
      <div className='contact-main'>
        <button onClick={goBack} className='back-button-pay'>
          <i className="ri-arrow-left-line"></i>
        </button>
      </div>
      {/* Heading */}
      <div className='contact-heading'>
        Contact Form
      </div>
      {/* Form */}
      <form onSubmit={handleSubmit} class="colorful-form">
        <div class="form-group-contact">
          <label class="form-label-contact" >Email:</label>
          <input type="email" name="Email" placeholder="Enter your email" class="form-input-contact" value={formData.Email} onChange={handleChange} required />

        </div>
        <div class="form-group-contact">
          <label class="form-label-contact" >Mobile Number:</label>
          <input type="tel" name="Mobile_Number" placeholder="Enter your mobile number" class="form-input-contact" value={formData.Mobile_Number} onChange={handleChange} required />

        </div>

        <div class="form-group-contact">
          <label class="form-label-contact" >Alternative Mobile Number:</label>
          <input type="tel" name="Alternative_Mobile_Number" placeholder="Enter your Alt mobile number" class="form-input-contact" value={formData.Alternative_Mobile_Number} onChange={handleChange} required />
        </div>

        <div class="form-group-contact">
          <label class="form-label-contact" >Convinient Time To Call:</label>
          <input type="text" name="Convinient_Time_To_Call" placeholder="Convinient time to call" class="form-input-contact" value={formData.Convinient_Time_To_Call} onChange={handleChange} required />
        </div>

        <div class="form-group-contact">
          <label class="form-label-contact" >Show Working Address:</label>
          <textarea type="text" name="Show_Working_Address" placeholder="Show_Working_Address" class="form-input-contact" value={formData.Show_Working_Address} onChange={handleChange} required ></textarea>
        </div>


        <div class="form-group-contact">
          <label class="form-label-contact">Show Permanent Address:</label>
          <textarea type="text" name="Show_Permanent_Address" placeholder="Show_Permanent_Address" class="form-input-contact" value={formData.Show_Permanent_Address} onChange={handleChange} required ></textarea>
        </div>



        <button class="form-button-contact" type="submit">Submit</button>
      </form>
    </div>
  );
}

export default Contactus;
