import React, { useState, useRef, useEffect } from "react";
import "./SearchProfilee.css";
import { useNavigate } from "react-router-dom";
import { educationOptions } from "./SearchProfiledata";
import { occupationOptions } from "./SearchProfiledata";
 
const SearchProfile = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
 
  const [educationSearchTerm, setEducationSearchTerm] = useState("");
  const [selectedEducation, setSelectedEducation] = useState("");
  const [occupationSearchTerm, setOccupationSearchTerm] = useState("");
  const [selectedOccupation, setSelectedOccupation] = useState("");
  const [formError, setFormError] = useState({ education: "", occupation: "" });
  const educationDropdownRef = useRef(null);
  const occupationDropdownRef = useRef(null);
  const [isEducationDropdownOpen, setIsEducationDropdownOpen] = useState(false);
  const [isOccupationDropdownOpen, setIsOccupationDropdownOpen] =
    useState(false);
 
  const ageRange = Array.from({ length: 48 }, (_, i) => i + 18);
 
  const handleEducationInputChange = (event) => {
    setEducationSearchTerm(event.target.value);
  };
 
  const handleOccupationInputChange = (event) => {
    setOccupationSearchTerm(event.target.value);
  };
 
  const handleSelectChange = (value, type) => {
    if (type === "Education") {
      setSelectedEducation(value);
      setIsEducationDropdownOpen(false);
    } else if (type === "Occupation") {
      setSelectedOccupation(value);
      setIsOccupationDropdownOpen(false);
    }
  };
 
  const filteredEducationOptions = educationOptions.filter((option) =>
    option.label.toLowerCase().includes(educationSearchTerm.toLowerCase())
  );
 
  const filteredOccupationOptions = occupationOptions.filter((option) =>
    option.label.toLowerCase().includes(occupationSearchTerm.toLowerCase())
  );
 
  const toggleDropdown = (type) => {
    if (type === "Education") {
      setIsEducationDropdownOpen(!isEducationDropdownOpen);
    } else if (type === "Occupation") {
      setIsOccupationDropdownOpen(!isOccupationDropdownOpen);
    }
  };
 
  const handleClickOutside = (event) => {
    if (
      educationDropdownRef.current &&
      !educationDropdownRef.current.contains(event.target)
    ) {
      setIsEducationDropdownOpen(false);
    }
    if (
      occupationDropdownRef.current &&
      !occupationDropdownRef.current.contains(event.target)
    ) {
      setIsOccupationDropdownOpen(false);
    }
  };
 
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
 
  return (
    <div>
      <>
        <section
          className="page-title-SP"
          style={{ backgroundColor: "black", marginTop: "-40px" }}
        >
          <div className="auto-container-SP">
            <h1 className="d-none-SP d-lg-block-SP d-xl-block-SP d-md-block-SP">
              Search Profile
            </h1>
          </div>
        </section>
        <button onClick={goBack} className="back-button-pay">
          <i class="ri-arrow-left-line"></i>
        </button>
        <section className="newsletter-section-SP contact-page-section-SP">
          <div className="auto-container-SP">
            <div className="contact-form-SP">
              <div className="col-lg-6-SP mt-3-SP">
                <form
                  className="form-horizontal-SP"
                  action="#"
                  method="post"
                  name="form1"
                >
                  <section className="newsletter-section-SP contact-page-section-SP">
                    <div className="auto-container-SP">
                      <div className="alertmes-SP"></div>
                      <div className="rowSP clearfix-SP mt-3-SP">
                        {/* Form Column */}
                        <div className="form-column-SP col-lg-12-SP col-md-12-SP col-sm-12-SP">
                          <div className="envelope-image-SP" />
                          <div className="inner-column-SP">
                            <div className="contact-form-SP">
                              <div className="rowSP clearfix-SP">
                                {" "}
                                <div className="col-lg-3-SP col-md-5-SP col-sm-6-SP form-group-SP">
                                  <label className="label-SP">
                                    {" "}
                                    Select Gender
                                  </label>
                                  <select
                                    className="custom-select-box-SP"
                                    name="Select_Gender"
                                    required=""
                                    tabIndex={8}
                                  >
                                    <option value="" selected="">
                                      Select Gender
                                    </option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                  </select>
                                  <span style={{ color: "red" }}></span>
                                </div>
                                <div className="col-lg-3-SP col-md-5-SP col-sm-6-SP form-group-SP">
                                  <label className="label-SP"> Age from</label>
                                  <select
                                    className="custom-select-box-SP valid-SP"
                                    autofocus=""
                                    name="Age_From"
                                    required=""
                                    tabIndex={5}
                                  >
                                    <option value="" selected="">
                                      Select Age From
                                    </option>
                                    {ageRange.map((age) => (
                                      <option key={age} value={age}>
                                        {age}
                                      </option>
                                    ))}{" "}
                                  </select>
                                  <span style={{ color: "red" }}></span>
                                </div>
                                <div className="col-lg-3-SP col-md-5-SP col-sm-6-SP form-group-SP">
                                  <label className="label-SP"> Age to </label>
                                  <select
                                    className="custom-select-box-SP"
                                    name="Age_To"
                                    required=""
                                    tabIndex={6}
                                  >
                                    <option value="" selected="">
                                      Select Age To
                                    </option>
                                    {ageRange.map((age) => (
                                      <option key={age} value={age}>
                                        {age}
                                      </option>
                                    ))}
                                  </select>
                                  <span style={{ color: "red" }}></span>
                                </div>
                                <div className="col-lg-3-SP col-md-5-SP col-sm-6-SP form-group-SP">
                                  <label className="label-SP">
                                    {" "}
                                    Select Marital Status
                                  </label>
                                  <select
                                    className="custom-select-box-SP"
                                    name="Marital_Status"
                                    required=""
                                    tabIndex={8}
                                  >
                                    <option value="" selected="">
                                      Select Marital Status
                                    </option>
                                    <option value="Unmarried">Unmarried</option>
                                    <option value="Divorced">Divorced</option>
                                    <option value="Widower ">Widower</option>
                                  </select>
                                  <span style={{ color: "red" }}></span>
                                </div>
                                <div className="col-lg-6-SP col-md-6-SP col-sm-6-SP form-group-SP">
                                  <label className="label-SP">Religion</label>
                                  <select
                                    className="custom-select-box-SP"
                                    name="Religion"
                                    required=""
                                    tabIndex={8}
                                  >
                                    <option value="" selected="">
                                      select religion
                                    </option>
                                    <option value="Any Religion">
                                      Any Religion
                                    </option>
                                    <option value="Christian">Christian</option>
                                    <option value="Hindu">Hindu</option>
                                    <option value="hindu Barahsaini">
                                      hindu Barahsaini
                                    </option>
                                    <option value="Inter-Religion">
                                      Inter-Religion
                                    </option>
                                    <option value="Jain">Jain</option>
                                    <option value="Muslim">Muslim</option>
                                    <option value="Sikh">Sikh</option>{" "}
                                  </select>
                                  <span style={{ color: "red" }}></span>
                                </div>
                                <div className="col-lg-3-SP col-md-5-SP col-sm-6-SP form-group-SP">
                                  <label className="label-SP">Education</label>
                                  <div
                                    className="custom-dropdown-SP"
                                    ref={educationDropdownRef}
                                  >
                                    <div
                                      className="selected-option-SP"
                                      onClick={() =>
                                        toggleDropdown("Education")
                                      }
                                    >
                                      {selectedEducation
                                        ? educationOptions.find(
                                            (option) =>
                                              option.value === selectedEducation
                                          ).label
                                        : "Select Education"}
                                    </div>
                                    {isEducationDropdownOpen && (
                                      <div className="dropdown-menu-SP">
                                        <input
                                          type="text"
                                          className="custom-select-box-SP"
                                          placeholder="Search Education"
                                          value={educationSearchTerm}
                                          onChange={handleEducationInputChange}
                                          autoFocus
                                        />
                                        <div className="dropdown-options-SP">
                                          {filteredEducationOptions.map(
                                            (option, index) => (
                                              <div
                                                key={index}
                                                className="dropdown-option-SP"
                                                onClick={() =>
                                                  handleSelectChange(
                                                    option.value,
                                                    "Education"
                                                  )
                                                }
                                              >
                                                {option.label}
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {formError.education}
                                  </span>
                                </div>
                                <div className="col-lg-3-SP col-md-5-SP col-sm-6-SP form-group-SP">
                                  <label className="label-SP">Occupation</label>
                                  <div
                                    className="custom-dropdown-SP"
                                    ref={occupationDropdownRef}
                                  >
                                    <div
                                      className="selected-option-SP"
                                      onClick={() =>
                                        toggleDropdown("Occupation")
                                      }
                                    >
                                      {selectedOccupation
                                        ? occupationOptions.find(
                                            (option) =>
                                              option.value ===
                                              selectedOccupation
                                          ).label
                                        : "Select Occupation"}
                                    </div>
                                    {isOccupationDropdownOpen && (
                                      <div className="dropdown-menu-SP">
                                        <input
                                          type="text"
                                          className="custom-select-box-SP"
                                          placeholder="Search Occupation"
                                          value={occupationSearchTerm}
                                          onChange={handleOccupationInputChange}
                                          autoFocus
                                        />
                                        <div className="dropdown-options-SP">
                                          {filteredOccupationOptions.map(
                                            (option, index) => (
                                              <div
                                                key={index}
                                                className="dropdown-option-SP"
                                                onClick={() =>
                                                  handleSelectChange(
                                                    option.value,
                                                    "Occupation"
                                                  )
                                                }
                                              >
                                                {option.label}
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <span style={{ color: "red" }}>
                                    {formError.occupation}
                                  </span>
                                </div>
                                <div className="col-lg-3-SP col-md-5-SP col-sm-6-SP form-group-SP">
                                  <label className="label-SP">
                                    {" "}
                                    Photo Preference
                                  </label>
                                  <select
                                    className="custom-select-box-SP"
                                    name="Photo_Preference"
                                    required=""
                                    tabIndex={8}
                                  >
                                    <option value="" selected="">
                                      Any
                                    </option>
                                    <option value="Whith Photo">
                                      With Photo
                                    </option>
                                    <option value="Without Photo">
                                      Whithout Photo
                                    </option>
                                  </select>
                                  <span style={{ color: "red" }}></span>
                                </div>
                                <div className="col-lg-3-SP col-md-5-SP col-sm-6-SP form-group-SP">
                                  <input className="input-SP" type="hidden" />
                                  <span />
                                  <button
                                    className="theme-btn-SP btn-style-two-SP"
                                    type="submit"
                                    name="Search"
                                  >
                                    <span className="btn-title-SP">
                                      {" "}
                                      Search
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </form>
              </div>
            </div>
          </div>
        </section>
      </>
    </div>
  );
};
 
export default SearchProfile;
 
//  1004 line code converted to 388 line