import React, { useState, useRef, useEffect } from "react";
import "./PrefrenceStyle.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Prefrencedata from "./Prefrencedata";
import { casteOptions } from "./Prefrencedata";
import { occupationOptions } from "./Prefrencedata";
import { educationOptions } from "./Prefrencedata";

const Prefrence = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const [occupationSearchTerm, setOccupationSearchTerm] = useState("");
  const [selectedOccupation, setSelectedOccupation] = useState("");
  const [educationSearchTerm, setEducationSearchTerm] = useState("");
  const [selectedEducation, setSelectedEducation] = useState("");
  const [casteSearchTerm, setCasteSearchTerm] = useState("");
  const [selectedCaste, setSelectedCaste] = useState("");
  const [stateSearchTerm, setStateSearchTerm] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [formError, setFormError] = useState({
    occupation: "",
    education: "",
    caste: "",
    state: "",
  });
  const states = [
    "Delhi",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Kerala",
    "Lakshadweep",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Karnataka",
    "Nagaland",
    "Orissa",
    "Pondicherry",
    "Punjab",
    "Rajasthan",
    "Tamil Nadu",
    "Tripura",
    "Bihar",
    "West Bengal",
    "Sikkim",
    "Mizoram",
    "Arunachal Pradesh",
    "Daman and Diu",
    "Goa",
    "Madhya Pradesh",
    "Uttar Pradesh",
    "Chhattisgarh",
    "Jharkhand",
    "Uttaranchal",
    "Out of India",
    "Andhra Pradesh"
  ];
  const occupationDropdownRef = useRef(null);
  const educationDropdownRef = useRef(null);
  const casteDropdownRef = useRef(null);
  const stateDropdownRef = useRef(null);
  const [isOccupationDropdownOpen, setIsOccupationDropdownOpen] =
    useState(false);
  const [isEducationDropdownOpen, setIsEducationDropdownOpen] = useState(false);
  const [isCasteDropdownOpen, setIsCasteDropdownOpen] = useState(false);
  const [isStateDropdownOpen, setIsStateDropdownOpen] = useState(false);
  const [error, setError] = useState(null);
  const ageOptions = Array.from(
    {
      length: 44,
    },
    (_, i) => i + 2
  );
  const heights = Array.from({ length: 50 }, (_, i) => `${i + 1} ft`);

  const handleOccupationInputChange = (event) => {
    setOccupationSearchTerm(event.target.value);
  };

  const handleEducationInputChange = (event) => {
    setEducationSearchTerm(event.target.value);
  };

  const handleCasteInputChange = (event) => {
    setCasteSearchTerm(event.target.value);
  };

  const handleStateInputChange = (event) => {
    setStateSearchTerm(event.target.value);
  };

  const handleSelectChange = (value, type) => {
    if (type === "Occupation") {
      setSelectedOccupation(value);
      setIsOccupationDropdownOpen(false);
    } else if (type === "Education") {
      setSelectedEducation(value);
      setIsEducationDropdownOpen(false);
    } else if (type === "Caste") {
      setSelectedCaste(value);
      setIsCasteDropdownOpen(false);
    } else if (type === "State") {
      setSelectedState(value);
      setIsStateDropdownOpen(false);
    }
  };

  const filteredOccupationOptions = occupationOptions.filter((option) =>
    option.label.toLowerCase().includes(occupationSearchTerm.toLowerCase())
  );

  const filteredEducationOptions = educationOptions.filter((option) =>
    option.label.toLowerCase().includes(educationSearchTerm.toLowerCase())
  );

  const filteredCasteOptions = casteOptions.filter((option) =>
    option.label.toLowerCase().includes(casteSearchTerm.toLowerCase())
  );

  const filteredStateOptions = states.filter((option) =>
    option.label.toLowerCase().includes(stateSearchTerm.toLowerCase())
  );

  const toggleDropdown = (type) => {
    switch (type) {
      case "Occupation":
        setIsOccupationDropdownOpen(!isOccupationDropdownOpen);
        break;
      case "Education":
        setIsEducationDropdownOpen(!isEducationDropdownOpen);
        break;
      case "Caste":
        setIsCasteDropdownOpen(!isCasteDropdownOpen);
        break;
      case "State":
        setIsStateDropdownOpen(!isStateDropdownOpen);
        break;
      default:
        break;
    }
  };

  const handleClickOutside = (event) => {
    if (
      occupationDropdownRef.current &&
      !occupationDropdownRef.current.contains(event.target)
    ) {
      setIsOccupationDropdownOpen(false);
    }
    if (
      educationDropdownRef.current &&
      !educationDropdownRef.current.contains(event.target)
    ) {
      setIsEducationDropdownOpen(false);
    }
    if (
      casteDropdownRef.current &&
      !casteDropdownRef.current.contains(event.target)
    ) {
      setIsCasteDropdownOpen(false);
    }
    if (
      stateDropdownRef.current &&
      !stateDropdownRef.current.contains(event.target)
    ) {
      setIsStateDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const showToastErrorMessage = () => {
    toast.error("please fill the All fields", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const [data, setPrefrenceInfo] = useState({
    looking_for: "",
    age_from: "",
    age_to: "",
    height_from: "",
    height_to: "",
    relligion: "",
    state: "",
    caste: "",
    complexion: "",
    residency_status: "",
    country: "",
    education: "",
    occupation: "",
    partner_expectations: "",
  });

  const [showForm, setShowForm] = useState(false);

  const inputChangeHandler = (e) => {
    setPrefrenceInfo({ ...data, [e.target.name]: e.target.value });
  };
  const validateForm = () => {
    let err = {};
    if (
      data.looking_for === "" &&
      data.age_from === "" &&
      data.age_to === "" &&
      data.height_from === "" &&
      data.height_to === "" &&
      data.relligion === "" &&
      data.state === "" &&
      data.caste === "" &&
      data.complexion === "" &&
      data.residency_status === "" &&
      data.country === "" &&
      data.education === "" &&
      data.occupation === "" &&
      data.partner_expectations === ""
    ) {
      setFormError(showToastErrorMessage);
    }

    if (data.age_from === "") {
      err.Age_1 = "Age_1 Required!..";
    }
    if (data.age_to === "") {
      err.Age_2 = "Age_2 Required!..";
    }
    if (data.height_from === "") {
      err.Height_1 = "Height_1 Required!..";
    }
    if (data.height_to === "") {
      err.Height_2 = "Height_2 Required!..";
    }
    if (data.relligion === "") {
      err.Relligion = "Relligion Required!..";
    }
    if (data.state === "") {
      err.State = "State Required!..";
    }
    if (data.caste === "") {
      err.Caste = "Caste Required!..";
    }
    if (data.complexion === "") {
      err.Complexion = "Complexion Required!..";
    }
    if (data.residency_status === "") {
      err.Residency_Status = "Residency_Status Required!..";
    }
    if (data.country === "") {
      err.Country = "Country name Required!..";
    }
    if (data.education === "") {
      err.Education = "Education Required!..";
    }
    if (data.occupation === "") {
      err.Occupation = "Occupation Required!..";
    }
    if (data.partner_expectations === "") {
      err.Partner_Expectations = "Partner_Expectations Required!..";
    }
    if (data.looking_for === "") {
      err.Looking_for = "Looking_for!..";
    }
    setFormError({ ...err });
    return Object.keys(err).length < 1;
  };

  const showData = async (e) => {
    e.preventDefault();
    try {
      if (validateForm) {
        const response = await axios.post(
          "https://matrimonyadmin.hola9.com/api/partnerapi/partnerprefarence/",
          data
        );
        const id = response.data.id; // Assuming the response contains the ID of the newly created resource

        setPrefrenceInfo({ ...data, id });
        console.log(response.data); // You can handle the response here
        setShowForm(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Prefrencedata />
      <section
        className="page-title"
        style={{ backgroundColor: "black", marginTop: "-40px" }}
      >
        <div className="auto-container-pre">
          <h1
            style={{ color: "white", marginLeft: "-600px" }}
            className="d-nonepart d-lg-blockpart d-xl-blockpart d-md-blockpart"
          >
            Partner Preference
          </h1>
        </div>
      </section>
      <button onClick={goBack} className="back-button-pay">
        <i class="ri-arrow-left-line"></i>
      </button>
      <section className="newsletter-section-pre contact-page-section-pre">
        <div className="auto-container-pre">
          <div className="alertmes-pre"></div>
          <div className="row-pre clearfix-pre mt-3-pre">
            {/* Form Column */}
            <div className="form-column-pre col-lg-12-pre col-md-12-pre col-sm-12-pre">
              <div className="envelope-image" />
              <div className="inner-column-pre">
                <div className="contact-form-pre">
                  <div className="row-pre clearfix-pre mt-3-pre">
                    <div className="col-lg-3-pre col-md-5-pre col-sm-6-pre form-group-pre">
                      <label className="labp" style={{ color: "white" }}>
                        Looking For
                      </label>

                      <select
                        className="custom-select-box-pre"
                        name="looking_for"
                        onChange={inputChangeHandler}
                        value={data.looking_for}
                        required=""
                        tabIndex={8}
                      >
                        <option value="" selected="">
                          Looking For
                        </option>
                        <option value="Unmarried">Unmarried</option>
                        <option value="Divorced">Divorced</option>
                        <option value="Widower ">Widower</option>
                      </select>
                      <span style={{ color: "red" }}>
                        {formError.looking_for}
                      </span>
                    </div>

                    <div className="col-lg-3-pre col-md-5-pre form-group-pre Partner_Pref_form-pre">
                      <label style={{ color: "white" }} className="labp">
                        {" "}
                        Age from
                      </label>
                      <select
                        className="custom-select-box-pre valid-pre"
                        autofocus=""
                        name="age_from"
                        onChange={inputChangeHandler}
                        value={data.age_from}
                        required=""
                        tabIndex={5}
                      >
                        <option value="" selected="">
                          Select Age From
                        </option>
                        {ageOptions.map((age) => (
                          <option key={age} value={age}>
                            {age}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>{formError.age_from}</span>
                    </div>

                    <div className="col-lg-3-pre col-md-5-pre col-sm-6-pre form-group-pre">
                      <label style={{ color: "white" }} className="labp">
                        {" "}
                        Age to{" "}
                      </label>
                      <select
                        className="custom-select-box-pre"
                        name="age_to"
                        onChange={inputChangeHandler}
                        value={data.age_to}
                        required=""
                        tabIndex={6}
                      >
                        <option value="" selected="">
                          Select Age From
                        </option>
                        {ageOptions.map((age) => (
                          <option key={age} value={age}>
                            {age}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>{formError.age_to}</span>
                    </div>
                    <div className="col-lg-3-pre col-md-5-pre col-sm-6-pre form-group-pre">
                      <label style={{ color: "white" }} className="labp">
                        Height form
                      </label>
                      <select
                        className="custom-select-box-pre"
                        name="height_to"
                        onChange={inputChangeHandler}
                        value={data.height_to}
                        required=""
                        tabIndex={9}
                      >
                        <option value="" selected="">
                          Select Height To
                        </option>
                        {heights.map((height, index) => (   <option key={index} value={height}>    {height}  </option> ))}
 
                      </select>
                      <span style={{ color: "red" }}>
                        {formError.height_from}
                      </span>
                    </div>
                    <div className="col-lg-3-pre col-md-5-pre col-sm-6-pre form-group-pre">
                      <label style={{ color: "white" }} className="labp">
                        Height to
                      </label>
                      <select
                        className="custom-select-box-pre"
                        name="height_to"
                        onChange={inputChangeHandler}
                        value={data.height_to}
                        required=""
                        tabIndex={9}
                      >
                        <option value="" selected="">
                          Select Height To
                        </option>
                        {heights.map((height, index) => (   <option key={index} value={height}>    {height}  </option> ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {formError.height_to}
                      </span>
                    </div>

                    <div className="col-lg-3-pre col-md-5-pre col-sm-6-pre form-group-pre">
                      <label className="labp" style={{ color: "white" }}>
                        Religion
                      </label>
                      <select
                        className="custom-select-box-pre"
                        name="relligion"
                        onChange={inputChangeHandler}
                        value={data.relligion}
                        required=""
                        tabIndex={8}
                      >
                        <option value="" selected="">
                          select religion
                        </option>
                        <option value="Christian">Christian</option>
                        <option value="Hindu">Hindu</option>
                        <option value="hindu Barahsaini">
                          hindu Barahsaini
                        </option>
                        <option value="Inter-Religion">Inter-Religion</option>
                        <option value="Jain">Jain</option>
                        <option value="Muslim">Muslim</option>
                        <option value="Sikh">Sikh</option>{" "}
                      </select>
                      <span style={{ color: "red" }}>
                        {formError.relligion}
                      </span>
                    </div>

                    <div className="col-lg-3-pre col-md-5-pre col-sm-6-pre form-group-pre">
                      <label className="labp" style={{ color: "white" }}>
                        Caste
                      </label>
                      <div
                        className="custom-dropdown-cat"
                        ref={casteDropdownRef}
                      >
                        <div
                          className="selected-option-cat"
                          onClick={() => toggleDropdown("Caste")}
                        >
                          {selectedCaste ? selectedCaste : "Select Caste"}
                        </div>
                        {isCasteDropdownOpen && (
                          <div className="dropdown-menu-cat">
                            <input
                              type="text"
                              className="custom-select-box-cat"
                              placeholder="Search Caste"
                              value={casteSearchTerm}
                              onChange={handleCasteInputChange}
                              autoFocus
                            />
                            <div className="dropdown-options-cat">
                              {filteredCasteOptions.map((option, index) => (
                                <div
                                  key={index}
                                  className="dropdown-option-cat"
                                  onClick={() =>
                                    handleSelectChange(option.label, "Caste")
                                  }
                                >
                                  {option.label}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                      <span style={{ color: "red" }}>{formError.caste}</span>
                    </div>

                    <div className="col-lg-3-pre col-md-5-pre col-sm-6-pre form-group-pre ">
                      <label className="labp" style={{ color: "white" }}>
                        {" "}
                        Complexion
                      </label>
                      <select
                        className="custom-select-box-pre"
                        name="complexion"
                        onChange={inputChangeHandler}
                        value={data.complexion}
                        tabIndex={8}
                      >
                        <option value="" selected="">
                          Select Complexion
                        </option>

                        <option value="Very Fair">Very Fair</option>
                        <option value="Fair">Fair</option>
                        <option value="Wheatish">Wheatish</option>
                        <option value="Wheatish Medium">Wheatish Medium</option>
                        <option value="Wheatish Brown">Wheatish Brown</option>
                        <option value="Dark">Dark</option>
                      </select>
                      <span style={{ color: "red" }}>
                        {formError.complexion}
                      </span>
                    </div>
                    <div className="col-lg-3-pre col-md-5-pre col-sm-6-pre form-group-pre ">
                      <label className="labp" style={{ color: "white" }}>
                        {" "}
                        Residency Status
                      </label>
                      <select
                        className="custom-select-box-pre"
                        name="residency_status"
                        onChange={inputChangeHandler}
                        value={data.residency_status}
                        required=""
                        tabIndex={8}
                      >
                        <option value="" selected="">
                          Select Residency Status
                        </option>
                        <option value="Permanent Resident">
                          Permanent Resident
                        </option>
                        <option value="Student Visa">Student Visa</option>
                        <option value="Temporary Visa">Temporary Visa</option>
                        <option value="Work Permit">Work Permit</option>{" "}
                      </select>
                      <span style={{ color: "red" }}>
                        {formError.residency_status}
                      </span>
                    </div>
                    <div className="col-lg-3-pre col-md-5-pre col-sm-6-pre form-group-pre">
                      <label className="labp" style={{ color: "white" }}>
                        {" "}
                        Country
                      </label>

                      <select
                        className="custom-select-box-pre"
                        name="country"
                        onChange={inputChangeHandler}
                        value={data.country}
                        required=""
                        tabIndex={8}
                      >
                        <option value="" selected="">
                          Select Country
                        </option>
                        <option value="India" selected="">
                          India
                        </option>
                      </select>
                      <span style={{ color: "red" }}>{formError.country}</span>
                      <div className="btn-group-pre"></div>
                    </div>
                    <div className="col-lg-3-pre col-md-5-pre form-group-pre">
                      <label className="labp" style={{ color: "white" }}>
                        State
                      </label>

                      <div
                        className="custom-dropdown-cat"
                        ref={stateDropdownRef}
                      >
                        <div
                          className="selected-option-cat"
                          onClick={() => toggleDropdown("State")}
                        >
                          {selectedState ? selectedState : "Select State"}
                        </div>
                        {isStateDropdownOpen && (
                          <div className="dropdown-menu-cat">
                            <input
                              className="custom-select-box-pre"
                              type="text"
                              placeholder="Search State"
                              onChange={handleStateInputChange}
                              value={stateSearchTerm}
                              required=""
                              autoFocus
                            />

                            <div className="dropdown-options-cat">
                              {filteredStateOptions.map((option, index) => (
                                <div
                                  key={index}
                                  className="dropdown-option-cat"
                                  onClick={() =>
                                    handleSelectChange(option.label, "State")
                                  }
                                >
                                  {option.label}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                      <span style={{ color: "red" }}>{formError.state}</span>
                    </div>

                    <div className="col-lg-3-pre col-md-5-pre col-sm-6-pre form-group-pre">
                      <label className="labp" style={{ color: "white" }}>
                        Education
                      </label>
                      <div
                        className="custom-dropdown-edu"
                        ref={educationDropdownRef}
                      >
                        <div
                          className="selected-option-edu"
                          onClick={() => toggleDropdown("Education")}
                        >
                          {selectedEducation
                            ? selectedEducation
                            : "Select Education"}
                        </div>
                        {isEducationDropdownOpen && (
                          <div className="dropdown-menu-edu">
                            <input
                              type="text"
                              className="custom-select-box-edu"
                              placeholder="Search Education"
                              value={educationSearchTerm}
                              onChange={handleEducationInputChange}
                              autoFocus
                            />
                            <div className="dropdown-options-edu">
                              {filteredEducationOptions.map((option, index) => (
                                <div
                                  key={index}
                                  className="dropdown-option-edu"
                                  onClick={() =>
                                    handleSelectChange(
                                      option.label,
                                      "Education"
                                    )
                                  }
                                >
                                  {option.label}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                      <span style={{ color: "red" }}>
                        {formError.education}
                      </span>
                    </div>

                    <div className="col-lg-3-pre col-md-5-pre col-sm-6-pre form-group-pre">
                      <label className="labp" style={{ color: "white" }}>
                        Occupation
                      </label>
                      <div
                        className="custom-dropdown-occ"
                        ref={occupationDropdownRef}
                      >
                        <div
                          className="selected-option-occ"
                          onClick={() => toggleDropdown("Occupation")}
                        >
                          {selectedOccupation
                            ? selectedOccupation
                            : "Select Occupation"}
                        </div>
                        {isOccupationDropdownOpen && (
                          <div className="dropdown-menu-occ">
                            <input
                              type="text"
                              className="custom-select-box-occ"
                              placeholder="Search Occupation"
                              value={occupationSearchTerm}
                              onChange={handleOccupationInputChange}
                              autoFocus
                            />
                            <div className="dropdown-options-occ">
                              {filteredOccupationOptions.map(
                                (option, index) => (
                                  <div
                                    key={index}
                                    className="dropdown-option-occ"
                                    onClick={() =>
                                      handleSelectChange(
                                        option.label,
                                        "Occupation"
                                      )
                                    }
                                  >
                                    {option.label}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      <span style={{ color: "red" }}>
                        {formError.occupation}
                      </span>
                    </div>

                    <div className="col-lg-8-pre col-md-5-pre col-sm-6-pre form-group-pre">
                      <label className="labp" style={{ color: "white" }}>
                        Partner Expectations
                      </label>
                      <textarea
                        type="text"
                        name="partner_expectations"
                        onChange={inputChangeHandler}
                        value={data.partner_expectations}
                        className="agile-ltext-pre"
                        placeholder="Enter Your Partener Expectation"
                        maxLength={600}
                        defaultvalue=""
                        tabIndex={25}
                        defaultValue={""}
                      />
                      <div className="col-lg-12-pre col-md-12-pre col-sm-6-pre ">
                        <span style={{ color: "white" }}>
                          {formError.partner_expectations}
                        </span>
                        <span
                          style={{
                            fontSize: 15,
                            color: "white",
                            fontWeight: "bold",
                          }}
                          className="abtp"
                        >
                          Enter few lines about Your Partner Expectation
                        </span>
                      </div>
                    </div>
                    <div
                      className="col-lg-10-pre col-md-10-pre col-sm-6-pre"
                      // style={{ marginTop: "50px" }}
                    >
                      <br></br>
                      <center style={{ paddingLeft: "20%" }}>
                        {!showForm && (
                          <div>
                            <button
                              className="theme-btn-pre btn-pre btn-style-one-pre mt-4-pre"
                              type="submit"
                              name="submit"
                              style={{ width: "30%" }}
                              onClick={showData}
                            >
                              <span className="btn-title-pre" tabIndex={26}>
                                Submit
                              </span>
                            </button>
                          </div>
                        )}
                      </center>
                    </div>
                  </div>
                  <ToastContainer style={{ marginTop: "100px" }} />
                  {error ? (
                    <div
                      className="theme-text -style-one-pre mt-4-pre"
                      style={{ color: "red" }}
                    >
                      <h4>{error}</h4>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Prefrence;

//  1300 line code converted to 799
