import React from 'react'

export const states = [
    "Delhi",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Kerala",
    "Lakshadweep",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Karnataka",
    "Nagaland",
    "Orissa",
    "Pondicherry",
    "Punjab",
    "Rajasthan",
    "Tamil Nadu",
    "Tripura",
    "Bihar",
    "West Bengal",
    "Sikkim",
    "Mizoram",
    "Arunachal Pradesh",
    "Daman and Diu",
    "Goa",
    "Madhya Pradesh",
    "Uttar Pradesh",
    "Chhattisgarh",
    "Jharkhand",
    "Uttaranchal",
    "Out of India",
    "Andhra Pradesh"
  ];

   export const castes = [
    "Select Caste",
  "96K Kokanastha",
  "Ad Dharmi",
  "Adi Andhra",
  "Adi Dravida",
  "Adi Karnataka",
  "Agarwal",
  "Agnikula Kshatriya",
  "Agri",
  "Ahom",
  "Ambalavasi",
  "Amil Sindhi",
  "Anglo Indian",
  "Araya",
  "Arekatica",
  "Arora",
  "Arunthathiyar",
  "Arya Vysya",
  "Aryasamaj",
  "Ayyaraka",
  "Badaga",
  "Bahi",
  "Baibhand Sindhi",
  "Baidya",
  "Baishnab",
  "Baishya",
  "Balija",
  "Banik",
  "Baniya",
  "Banjara",
  "Barai",
  "Bari",
  "Barujibi",
  "Bengali",
  "Besta",
  "Bhandari",
  "Bhanusali Sindhi",
  "Bhatia",
  "Bhatia Sindhi",
  "Bhatraju",
  "Bhavasar Kshatriya",
  "Bhavsar",
  "Bhovi",
  "Bhumihar Brahmin",
  "Billava",
  "Bishnoi/Vishnoi",
  "Boyer",
  "Brahmbatt",
  "Bunt",
  "Bunt (Shetty)",
  "Chambhar",
  "Chandraseniya Kayastha Prab",
  "Chandravanshi Kahar",
  "Chasa",
  "Chattada Sri Vaishnava",
  "Chaudary",
  "Chaurasia",
  "Chettiar",
  "Chhapru Sindhi",
  "Chhetri",
  "Chippolu (Mera)",
  "CKP",
  "Coorgi",
  "Dadu Sindhi",
  "Darji",
  "Dawoodi Bhora",
  "Devadiga",
  "Devandra Kula Vellalar",
  "Devang Koshthi",
  "Devanga",
  "Devar/Thevar/Mukkulathor",
  "Devendra Kula Vellalar",
  "Dhaneshawat Vaish",
  "Dhangar",
  "Dheevara",
  "Dhiman",
  "Dhoba",
  "Dhobi",
  "Dumal",
  "Dusadh (Paswan)",
  "Ediga",
  "Ezhava",
  "Ezhuthachan",
  "Gabit",
  "Ganda",
  "Gandla",
  "Ganiga",
  "Ganigashetty",
  "Garhwali",
  "Garhwali Rajput",
  "Gavali",
  "Gavara",
  "Gawali",
  "Ghisadi",
  "Ghumar",
  "Goala",
  "Goan",
  "Gomantak",
  "Gomantak Maratha",
  "Gondhali",
  "Goswami",
  "Goswami/Gosavi Brahmin",
  "Goud",
  "Gounder",
  "Gowda",
  "Gramani",
  "Gudia",
  "Gujarati",
  "Gujjar",
  "Gupta",
  "Guptan",
  "Gurav",
  "Gurjar",
  "Hegde",
  "Himachali",
  "Hindu-Others",
  "Hugar (Jeer)",
  "Hyderabadi Sindhi",
  "Intercaste",
  "Irani",
  "Iyengar",
  "Iyer",
  "Jaalari",
  "Jaiswal",
  "Jandra",
  "Jangam",
  "Jat",
  "Jatav",
  "Jeer",
  "Jogi (Nath)",
  "Kachara",
  "Kadava Patel",
  "Kahar",
  "Kaibarta",
  "Kalal",
  "Kalar",
  "Kalinga",
  "Kalinga Vysya",
  "Kalita",
  "Kalwar",
  "Kamboj",
  "Kamma",
  "Kannada Mogaveera",
  "Kansari",
  "Kapu",
  "Kapu Naidu",
  "Karana",
  "Karmakar",
  "Karuneegar",
  "Karuneekar",
  "Kasar",
  "Kashyap",
  "Katiya",
  "Kayastha",
  "Keralite",
  "Khandayat",
  "Khandelwal",
  "Kharwar",
  "Khatik",
  "Khatri",
  "Khukhrain",
  "Koiri",
  "Kokanastha Maratha",
  "Koli",
  "Koli Mahadev",
  "Kongu Vellala Gounder",
  "Konkani",
  "Kori",
  "Koshti",
  "Kshatriya",
  "Kudumbi",
  "Kulal",
  "Kulalar",
  "Kulita",
  "Kumaoni",
  "Kumaoni Rajput",
  "Kumawat",
  "Kumbara",
  "Kumbhakar",
  "Kumbhar",
  "Kumhar",
  "Kummari",
  "Kunbi",
  "Kuravan",
  "Kurmi",
  "Kurmi Kshatriya",
  "Kuruba",
  "Kuruhina Shetty",
  "Kurumbar",
  "Kushwaha",
  "Kushwaha (Koiri)",
  "Kutchi",
  "Lambadi",
  "Lambani",
  "Larai Sindhi",
  "Larkana Sindhi",
  "Leva patel",
  "Leva Patidar",
  "Leva patil",
  "Lingayath",
  "Lodhi Rajput",
  "Lohana",
  "Lohana Sindhi",
  "Lohar",
  "Lubana",
  "Madiga",
  "Mahajan",
  "Mahar",
  "Maharashtrian",
  "Mahendra",
  "Maheshwari",
  "Mahishya",
  "Mahisya",
  "Majabi",
  "Mala",
  "Malayalee",
  "Malayalee Namboodiri",
  "Mali",
  "Mallah",
  "Mangalorean",
  "Manipuri",
  "Mannuru Kapu",
  "Mapila",
  "Maratha",
  "Maruthuvar",
  "Marvar",
  "Marwari",
  "Matang",
  "Mathur",
  "Maurya",
  "Meena",
  "Meenavar",
  "Mehra",
  "Menon",
  "Mera",
  "Meru",
  "Meru Darji",
  "Mochi",
  "Modak",
  "Mogaveera",
  "Monchi",
  "Mudaliar",
  "Mudaliar - Senguntha",
  "Mudaliar Arcot",
  "Mudaliar Saiva",
  "Mudaliyar",
  "Mudiraj",
  "Mukkulathor",
  "Mukulathur",
  "Munnuru Kapu",
  "Muthuraja",
  "Naagavamsam",
  "Nadar",
  "Nagaralu",
  "Nai",
  "Naicker",
  "Naidu",
  "Naik",
  "Naik/Nayaka",
  "Naika",
  "Nair",
  "Nair Vaniya",
  "Nair Vilakkithala",
  "Namasudra",
  "Nambiar",
  "Namboodiri",
  "Namosudra",
  "Napit",
  "Nath",
  "Nayaka",
  "Neeli",
  "Nepali",
  "Nhavi",
  "OBC/Barber/Naayee",
  "Oriya",
  "Oswal",
  "Otari",
  "Padmasali",
  "Padmashali",
  "Pal",
  "Panchal",
  "Pandaram",
  "Panicker",
  "Parkava Kulam",
  "Parsi",
  "Partraj",
  "Pasi",
  "Paswan",
  "Patel",
  "Patel Desai",
  "Patel Dodia",
  "Patel Kadva",
  "Patel Leva",
  "Pathare Prabhu",
  "Patil",
  "Patil Leva",
  "Patnaick",
  "Patnaick/Sistakaranam",
  "Patra",
  "Perika",
  "Pillai",
  "Poosala",
  "Porwal",
  "Prajapati",
  "Punjabi",
  "Raigar",
  "Rajaka",
  "Rajastani",
  "Rajbhar",
  "Rajbonshi",
  "Rajput",
  "Rajput Rohella/Tank",
  "Ramdasia",
  "Ramgariah",
  "Ravidasia",
  "Rawat",
  "Reddy",
  "Relli",
  "Rohiri Sindhi",
  "Sadgope",
  "Saha",
  "Sahiti Sindhi",
  "Sahu",
  "Saini",
  "Sakkhar Sindhi",
  "Saliya",
  "Saurashtra",
  "Savji",
  "Scheduled Caste",
  "Scheduled Tribe",
  "Sehwani Sindhi",
  "Senai Thalaivar",
  "Senguntha Mudaliyar",
  "Sepahia",
  "Setti Balija",
  "Settibalija",
  "Shah",
  "Shetty",
  "Shikarpuri Sindhi",
  "Shimpi",
  "Sindhi",
  "Sindhi-Amil",
  "Sindhi-Baibhand",
  "Sindhi-Bhanusali",
  "Sindhi-Bhatia",
  "Sindhi-Chhapru",
  "Sindhi-Dadu",
  "Sindhi-Hyderabadi",
  "Sindhi-Larai",
  "Sindhi-Larkana",
  "Sindhi-Lohana",
  "Sindhi-Rohiri",
  "Sindhi-Sahiti",
  "Sindhi-Sakkhar",
  "Sindhi-Sehwani",
  "Sindhi-Shikarpuri",
  "Sindhi-Thatai",
  "SKP",
  "Somvanshi",
  "Sonam",
  "Sonar",
  "Soni",
  "Sourashtra",
  "Sozhiya Vellalar",
  "Srisayana",
  "Srisayani",
  "Sugali (Naika)",
  "Sunari",
  "Sundhi",
  "Sutar",
  "Suthar",
  "Swakula Sali",
  "Swarnakar",
  "Tamboli",
  "Tamil",
  "Tamil Yadava",
  "Tanti",
  "Tantubai",
  "Tantuway",
  "Telaga",
  "Teli",
  "Telugu",
  "Thakkar",
  "Thakore",
  "Thakur",
  "Thatai Sindhi",
  "Thigala",
  "Thiyya",
  "Tili",
  "Togata",
  "Tonk Kshatriya",
  "Turupu Kapu",
  "Udayar",
  "Uppara",
  "Urali Gounder",
  "Urs",
  "Vada Balija",
  "Vadagalai",
  "Vaddera",
  "Vaish",
  "Vaishnav",
  "Vaishnav Bhatia",
  "Vaishnav Vania",
  "Vaishnav Vanik",
  "Vaishnava",
  "Vaishya",
  "Vaishya Vani",
  "Valluvan",
  "Valmiki",
  "Vania",
  "Vaniya",
  "Vanjara",
  "Vanjari",
  "Vankar",
  "Vannar",
  "Vannia Kula Kshatriyar",
  "Vanniyar",
  "Variar",
  "Varshney",
  "Veera Saivam",
  "Veerashaiva",
  "Velaan/Vellalar",
  "Velama",
  "Vellalar",
  "Vellalar Devandra Kula",
  "Veluthedathu Nair",
  "Vettuva Gounder",
  "Vilakkithala Nair",
  "Vishwakarma",
  "Viswabrahmin",
  "Vokkaliga",
  "Vysya",
  "Yadav",
  "Yellapu"
  ];

 export  const educationOptions = [
    "", "12th", "B.A. (Arts)", "B.Com (Commerce)", "B.Sc (Science)", "B.Arch (Architecture)",
  "B.Ed (Education)", "B.El.Ed (Elementary Education)", "B.Lib.Sc (Library Sciences)",
  "B.P.Ed. (Physical Education)", "B.Plan (Planning)", "Bachelor of Fashion Technology",
  "BBA/BBM/BBS", "BCA (Computer Application)", "BE B.Tech (Engineering)", "BFA (Fine Arts)",
  "BHM (Hotel Management)", "BL/LLB/BGL (Law)", "BSW (Social Work)", "B.Pharm (Pharmacy)",
  "B.V.Sc. (Veterinary Science)", "BDS (Dental Surgery)", "BHMS (Homeopathy)",
  "CA (Chartered Accountant)", "CFA (Chartered Financial Analyst)", "CS (Company Secretary)",
  "ICWA", "Integrated PG", "Engineering", "Fashion/ Design", "Languages", "Pilot Licenses",
  "M.Arch. (Architecture)", "M.Ed. (Education)", "M.Lib.Sc. (Library Sciences)",
  "M.Plan. (Planning)", "Master of Fashion Technology", "Master of Health Administration",
  "Master of Hospital Administration", "MBA/PGDM", "MCA PGDCA part time", "MCA/PGDCA",
  "ME/M.Tech/MS (Engg/Sciences)", "MFA (Fine Arts)", "ML/LLM (Law)", "MSW (Social Work)",
  "PG Diploma", "M.Com. (Commerce)", "M.Sc. (Science)", "MA (Arts)", "M.Pharm. (Pharmacy)",
  "M.V.Sc. (Veterinary Science)", "MBBS", "MD/ MS (Medicine)", "MDS (Master of Dental Surgery)",
  "BPT (Physiotherapy", "MPT (Physiotherapy)", "M.Phil. (Philosophy)", "Ph.D. (Doctorate)",
  "Other Doctorate", "Other Diploma", "Agriculture", "10th"
  ];

 
  
  function Profiledetailsdata() {
    return (
      <div>
        
      </div>
    )
  }
  
  export default Profiledetailsdata
  