import React, { useState } from 'react';
import './Review.css'; 
import { useNavigate } from 'react-router-dom';

const Review = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const [rating, setRating] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [post, setPost] = useState({
    name: '',
    email: '',
    rating: rating,
    descriptions: '',
    profile_image: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPost((prevPost) => ({
      ...prevPost,
      [name]: value,
    }));
  };

  const handleRatingChange = (newRating) => {
    setPost((prevPost) => ({
      ...prevPost,
      rating: newRating,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Get the first selected file
    setPost((prevPost) => ({
      ...prevPost,
      profile_image: file,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('name', post.name);
      formData.append('email', post.email);
      formData.append('rating', post.rating);
      formData.append('descriptions', post.descriptions);
      formData.append('profile_image', post.profile_image);

      const response = await fetch('https://matrimonyadmin.hola9.com/api/crud/reviewapi/', {
        method: 'POST',
        body: formData,
      });
      if (response.ok) {
        setShowPopup(true);
        setPost({
          name: '',
          email: '',
          rating: 0,
          descriptions: '',
          profile_image: null,
        });
      } else {
        alert('Failed to submit review');
      }
    } catch (error) {
      console.error(error);
      alert('Failed to submit review');
    }
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  return (
    <div>

      <button onClick={goBack} className='back-button-pay'><i className="ri-arrow-left-line"></i></button>
      <div className="review-container">
        <h2 className='review-h2'>How Was your experience?</h2>
        <p className='review-p'>
          Please feel free to share with us your experience working with our
          support staff. Our customer service is improved with your feedback.
        </p>
        <div className="name-email-container">
          <div className="name-input-container">
            <label className='review-label'>Name:</label>
            <input
              className='name-input'
              type="text"
              name='name'
              value={post.name}
              onChange={handleChange}
              placeholder="Your Name"
            />
            <div style={{marginTop:'10px',display:'flex',flexDirection:'column',gap:'2px'}}>
              <label>Profile Image :</label>
            <input 
            style={{marginBottom:'10px'}}
            type="file"
            name="profile_image"
            onChange={handleImageChange}
            accept="image/*" // Accept only image files
          />
            </div>
             
          </div>
          <div className="email-input-container" >
            <label className='review-label'>Email:</label>
            <input
              className='email-input'
              type="email"
              name='email'
              value={post.email}
              onChange={handleChange}
              placeholder="Your Email"
            />
          </div>
        </div>
        <div className="rating-container">
          <label className='review-label'>Rating:</label>
          <div>
            {Array.from({ length: 5 }).map((_, index) => (
              <span
                key={index}
                onClick={() => handleRatingChange(index + 1)}
                className={index < post.rating ? 'filled-star' : 'empty-star'}
              >
                &#9733;
              </span>
            ))}
          </div>
        </div>
        <div className="review-input-container">
        
          <label className='review-label'>Review:</label>
          <textarea
            className='review-textarea'
            style={{marginTop:'10px'}}
            name='descriptions'
            value={post.descriptions}
            onChange={handleChange}
            placeholder="Write your review here..."
          />
        </div>
        <button className="review-button" onClick={handleSubmit} style={{marginLeft:'1px'}}>
          Submit
        </button>
      </div>

      {/* Pop-up message */}
      {showPopup && (
        <div className="popup">
          <p>Your review has been submitted successfully!</p>
          <button onClick={handlePopupClose}>Close</button>
        </div>
      )}
    </div>
  );
};

export default Review;
