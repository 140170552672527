import React from 'react'
import "./shortlistedbyme.css"

const ShortlistedByMe = () => {
  return (
    <div>

                 <section className="page-title" style={{ backgroundColor: "black",color:"white",fontSize:"20px", fontWeight:"700",marginTop:"-40px"}}>
        <div className="auto-container">
          {/* <h1 className="d-none d-lg-block d-xl-block d-md-block">Liked Profile </h1> */}
          <ul className="bread-crumb clearfix">
           <li style={{textAlign:'center'}}> Liked Profile</li>
          </ul>
        </div>


      </section>
      <div>
      <br/>
      <center>

<div className="liked-profile-container">
      <h1 className="liked-profile-heading">Who Liked By Me</h1>
      <div className="liked-profile-input-container-view">
        <input type="text" className="liked-profile-input" placeholder="Name"  />
        <input type="text" className="liked-profile-input" placeholder="Profile ID" />
        <input type="text" className="liked-profile-input" placeholder="Viewed Date" />
      </div>
    </div>

    </center>
  </div>
    </div>
  )
}

export default ShortlistedByMe
