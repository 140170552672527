import React, { useState } from 'react';
import Basicdetails from '../Basicdetails/Basicdetails';
import Education from '../EducationDetails/Education';
import Family from '../FamilyDetails/Family';
import './Stepper.css'
import Horoscope from '../Horoscopepage/Horoscope';
import Prefrence from '../Partner Prefrence/Prefrence';
import HabitsForm from '../Habits/HabitsForm';
import HobbyDetails from '../UserDashBoard/HobbyDetails';
import Interestslink from '../UserDashBoard/UserProfilee/Interestslink';
import Addphoto from '../UserDashBoard/UserProfilee/Addphoto';

const Stepper = () => {
    const componentList = [
        <Basicdetails />, <Education />, <Family />, <Horoscope />, <Prefrence />, <HabitsForm />, <HobbyDetails />, <Interestslink />, <Addphoto />
    ];

    const n = componentList.length;
    const [step, setStep] = useState(0);

    const onPreviousClick = () => {
        if (step > 0) setStep(step - 1);
    };

    const onNextClick = () => {
        if (step < n - 1) setStep(step + 1);
    };

    const StepElements = ({ currentStep }) => {
        const elements = [];
        for (let i = 0; i < n; i++) {
            elements.push(
                <span id="step" className={currentStep >= i ? "active" : ""}><h6 className='num'>{i + 1}</h6> </span>
            );
        }
        return elements;
    }

    return (
        <div style={{ marginTop: "100px" }}>
            <div id="step-wrapper">
                <StepElements currentStep={step} />
                <div
                    id="progress"
                    style={{ width: `${(step / (n - 1)) * 100}%` }}
                >

                </div>
            </div>
            <div className='stepper-btn'>
                <button className='previous' onClick={onPreviousClick}><h4 className="stepper-text">Previous</h4></button>
                <button className="next" onClick={onNextClick}><h4 className="stepper-text">Next</h4></button>
            </div>
            <div style={{ marginTop: "-120px" }}>{componentList[step]}</div>

        </div>
    )
}
export default Stepper;