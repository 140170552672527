import React from "react";
import "./FooterStyle.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhoenixSquadron, fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUserPlus,
  faSignInAlt,
  faInfoCircle,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { AiFillInstagram } from "react-icons/ai";
import { BsFillPlayBtnFill } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";
import { FaMap, FaMapMarked, FaTwitter } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";

library.add(fab, faHome, faUserPlus, faSignInAlt, faInfoCircle, faEnvelope,faPhone);

const Foot = () => {
  return (
    <>
   
     <div className="footer-card">


        <img className="footer-logo" src="https://cdn.cdnparenting.com/articles/2021/09/14191221/08938f86cbffaa4d3027c4c45067a2a8.webp"></img>


      <div className="company-links-footer company-links-footer-1">
        <h5 className="quicklinks" >Quick Links</h5>

        <div style={{height:'1px', background:'whitesmoke', margin:'10px 0', width:'50%'}}></div>

<Link to="/About">  <p>About Us</p></Link>
      <Link to="/Signup">    <p>Signup</p></Link>
    <Link to="/Contact"> <p>Contact Us</p></Link>
       
      </div>

      <div className="company-links-footer">
      <h5 className="quicklinks">Explore services</h5>

<div style={{height:'1px', background:'whitesmoke', margin:'10px 0', width:'50%'}}></div>

<Link> <p>Carrers</p></Link>
       <Link ><p>Happy story</p></Link>
        <Link>  <p>Blogs</p></Link>
      
      </div>

     <div className="address-links-footer-main">
      <div className="address-links-footer">
        <div style={{display:'flex'}}>
        <span className="address-links-icon-footer"><FontAwesomeIcon icon="envelope"/></span>
        <span className="footer-gmail">made4foreachother.hola9.@gmail.com</span>
        </div>

        <div>
        <span className="address-links-icon-footer"><FontAwesomeIcon icon="phone"/></span>
        <span className="address-links-phn-footer ">08772232254</span>
        </div>

        <div style={{display:'flex'}}>
        <div className="address-links-icon-footer"><FaMapMarked/></div>
        <div className="address-links-text-footer">#12 Second Floor 3rd cross,
          <br/>
         Patel Narayana Reddy Layout
         <br/>
         
         6th Block Koramangala,
         <br/>
          Bangaluru - 560095.</div>
        </div>
      </div>



<div style={{height:'1px', background:'whitesmoke', margin:'10px 0'}}></div>



      <div className="footer-icons">
      <Link to="https://www.facebook.com/login/" className="footer-icon-links"><FaFacebookSquare className="icons"/></Link> 
      <Link to="https://twitter.com/i/flow/login"  className="footer-icon-links"><FaTwitter className="icons" /></Link>
      <Link to="https://www.youtube.com/"  className="footer-icon-links"><BsFillPlayBtnFill className="icons" /></Link>
      <Link to="https://www.whatsapp.com/"  className="footer-icon-links"><BsWhatsapp className="icons" /></Link> 
      <Link to="https://www.instagram.com/"  className="footer-icon-links"><AiFillInstagram className="icons" /></Link> 
      </div>
     </div>
     </div>


     <p style={{color:"white",fontWeight:'500',fontSize:'90%', textAlign:'center',background:'gray', padding:'10px 0', marginBottom:'0'}}>2024 ©  &nbsp; All Rights Reserved : Made4Eachother</p>

    </>
  );
};

export default Foot;
