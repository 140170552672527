import React from "react";
import { Link } from "react-router-dom";
import "./FeaturesStyle.css";

const Features = () => {
  return (
    <div>
      <section
        className="features-section-twofea"
        style={{ width: "100%", padding: "0" }}
      >
        <div
          className="auto-containerfea"
          style={{ width: "100%", padding: "0", margin: "0" }}
        >
          <div
            className="anim-iconsfea"
            style={{ width: "100%", padding: "0", margin: "0" }}
          >
            <span
              className="iconfea twist-line-1 wow zoomIn animated"
              style={{ visibility: "visible", animationName: "zoomIn" }}
            />
            <span
              className="iconfea twist-line-2 wow zoomIn animated"
              data-wow-delay="1s"
              style={{
                visibility: "visible",
                animationDelay: "1s",
                animationName: "zoomIn",
              }}
            />
            <span
              className="iconfea twist-line-3 wow zoomIn animated"
              data-wow-delay="2s"
              style={{
                visibility: "visible",
                animationDelay: "2s",
                animationName: "zoomIn",
              }}
            />
          </div>
          <div
            className="rowfea"
            style={{ width: "100%", padding: "0", margin: "0" }}
          >
            {/* Title Block */}
            <div
              className="title-blockfea col-lg-4fea col-md-4fea col-sm-4fea wow fadeInUp animated"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              <div
                className="inner-boxfea"
                style={{ width: "100%", padding: "0", margin: "0" }}
              >
                <div
                  className="sec-titlefea"
                  style={{ width: "100%", padding: "0", margin: "0" }}
                >
                  <h2
                    className="title-feature"
                    style={{ width: "100%", padding: "0", margin: "0" }}
                  >
                    Matrimony App Features
                  </h2>
                </div>
              </div>
            </div>
            {/* Feature Block */}
            <div
              className="feature-block-twofea col-lg-4fea col-md-4fea col-sm-4fea wow fadeInUp animated"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              <div className="inner-boxfea">
                <div className="icon-boxfea">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="50"
                    color="black"
                    fill="currentColor"
                    class="bi bi-chat-dots"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                    <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z" />
                  </svg>
                </div>
                <h4>
                  <Link to="login">Free SMS &amp; Chat</Link>
                </h4>
                <div className="textfea">
                  An important facility such as chat is available in our site,
                  so that people can interact with each other.
                </div>
              </div>
            </div>
            {/* Feature Block */}
            <div
              className="feature-block-twofea col-lg-4fea col-md-4fea col-sm-4fea wow fadeInUp animated"
              style={{ visibility: "visible", animationName: "fadeInUp" }}
            >
              <div className="inner-boxfea">
                <div className="icon-boxfea">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="50"
                    color="black"
                    fill="currentColor"
                    class="bi bi-search-heart"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 4.482c1.664-1.673 5.825 1.254 0 5.018-5.825-3.764-1.664-6.69 0-5.018Z" />
                    <path d="M13 6.5a6.471 6.471 0 0 1-1.258 3.844c.04.03.078.062.115.098l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1.007 1.007 0 0 1-.1-.115h.002A6.5 6.5 0 1 1 13 6.5ZM6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11Z" />
                  </svg>
                </div>
                <h4>
                  <Link to="login">Auto Match Maker</Link>
                </h4>
                <div className="textfea">
                  Some new members get registered daily. This matrimony system
                  updates and shows.
                </div>
              </div>
            </div>
            {/* Feature Block */}
            <div
              className="feature-block-twofea col-lg-4fea col-md-4fea col-sm-4fea wow fadeInUp animated"
              data-wow-delay="400ms"
              style={{
                visibility: "visible",
                animationDelay: "400ms",
                animationName: "fadeInUp",
              }}
            >
              <div className="inner-boxfea">
                <div className="icon-boxfea">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="50"
                    color="black"
                    fill="currentColor"
                    class="bi bi-gem"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.1.7a.5.5 0 0 1 .4-.2h9a.5.5 0 0 1 .4.2l2.976 3.974c.149.185.156.45.01.644L8.4 15.3a.5.5 0 0 1-.8 0L.1 5.3a.5.5 0 0 1 0-.6l3-4zm11.386 3.785-1.806-2.41-.776 2.413 2.582-.003zm-3.633.004.961-2.989H4.186l.963 2.995 5.704-.006zM5.47 5.495 8 13.366l2.532-7.876-5.062.005zm-1.371-.999-.78-2.422-1.818 2.425 2.598-.003zM1.499 5.5l5.113 6.817-2.192-6.82L1.5 5.5zm7.889 6.817 5.123-6.83-2.928.002-2.195 6.828z" />
                  </svg>
                </div>
                <h4>
                  <Link to="login">Recommend Profile</Link>
                </h4>
                <div className="textfea">
                  when you follow some-one, you can see the updates of people
                  whom you follow.
                </div>
              </div>
            </div>
            {/* Feature Block */}
            <div
              className="feature-block-twofea col-lg-4fea col-md-4fea col-sm-fea wow fadeInUp animated"
              data-wow-delay="800ms"
              style={{
                visibility: "visible",
                animationDelay: "800ms",
                animationName: "fadeInUp",
              }}
            >
              <div className="inner-boxfea">
                <div className="icon-boxfea">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="50"
                    color="black"
                    fill="currentColor"
                    class="bi bi-bell"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                  </svg>
                </div>
                <h4>
                  <Link to="login">Notification Alerts</Link>
                </h4>
                <div className="textfea">
                  The activities such as changing the display picture, birthday
                  notification, photo requests.
                </div>
              </div>
            </div>
            {/* Feature Block */}
            <div
              className="feature-block-twofea col-lg-4fea col-md-4fea col-sm-4fea wow fadeInUp animated"
              data-wow-delay="1200ms"
              style={{
                visibility: "visible",
                animationDelay: "1200ms",
                animationName: "fadeInUp",
              }}
            >
              <div className="inner-boxfea">
                <div className="icon-boxfea">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="50"
                    color="black"
                    fill="currentColor"
                    class="bi bi-sign-do-not-enter"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.584 6V4h.69c.596 0 .886.355.886.998S4.867 6 4.274 6h-.69Zm.653-1.72h-.32v1.44h.32c.396 0 .582-.239.582-.718 0-.481-.188-.722-.582-.722Zm2.729.585v.272c0 .566-.318.903-.83.903-.513 0-.833-.337-.833-.903v-.272c0-.569.32-.904.832-.904.513 0 .83.337.83.904Zm-.337.274v-.277c0-.413-.211-.617-.494-.617-.285 0-.495.204-.495.617v.277c0 .414.21.618.495.618.283 0 .494-.204.494-.618Zm1.358-.579V6h-.319V4h.293l.933 1.436h.015V4h.319v2h-.291L8 4.56h-.013Zm3.142.305v.272c0 .566-.318.903-.83.903-.513 0-.833-.337-.833-.903v-.272c0-.569.32-.904.832-.904.513 0 .83.337.83.904Zm-.337.274v-.277c0-.413-.211-.617-.494-.617-.285 0-.495.204-.495.617v.277c0 .414.21.618.495.618.283 0 .494-.204.494-.618Zm1.236-.854V6h-.333V4.285h-.584V4h1.503v.285h-.586ZM4.496 11.72h.917V12H4.165v-2h1.248v.28h-.917v.57h.862v.268h-.862v.602Zm1.489-1.16V12h-.32v-2h.294l.933 1.436h.014v-1.435h.32V12h-.292l-.936-1.44h-.013Zm2.279-.275V12H7.93v-1.715h-.584V10H8.85v.284h-.586ZM9.3 11.72h.917V12H8.97v-2h1.248v.28H9.3v.57h.863v.268H9.3v.602ZM10.47 10h.765c.42 0 .674.244.674.616a.575.575 0 0 1-.368.56l.404.824h-.373l-.36-.769h-.414V12h-.328v-2Zm.328.273v.694h.381c.245 0 .387-.115.387-.34 0-.228-.147-.354-.378-.354h-.39ZM3.5 7a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-9Z" />
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Zm0-1A7 7 0 1 1 8 1a7 7 0 0 1 0 14Z" />
                  </svg>
                </div>
                <h4>
                  <Link to="login">Restrictions Setting</Link>
                </h4>
                <div className="textfea">
                  Restrictions Enable Copying Photos &amp; Video Disable Anti
                  Spam System Provided.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Features;
