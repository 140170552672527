import React from 'react'
import './Common.css'

const InterstedRecived = () => {
  return (
    <div>

      <section className="page-title" >
        <div className="auto-container">
          <ul className="bread-crumb clearfix">
            <li style={{ textAlign: 'center' }}> InterstedRecived</li>
          </ul>
        </div>


      </section>
      <div>
        <br />
        <center>

          <h1 style={{ backgroundcolor: " #ec167f", color: " #ec167f", marginTop: "100px" }}>Oop's</h1>
          <h4>Sorry Result Not Found</h4>
          <p>You have not yet connected profiles.</p>
          <a href="smart_search" className="theme-btn btn-style-three">
            <span className="btn-title">Search</span>
          </a>
          <div className="anim-icons full-width" style={{ marginTop: "100px" }}>
            <span
              className="icon icon-circle-blue wow fadeIn animated"
              style={{ visibility: "visible", animationName: "fa-spin", marginTop: "300px ", marginLeft: "-790px" }}
            />
            <span
              className="icon icon-dots wow fadeInleft animated"
              style={{ visibility: "visible", marginTop: "290px ", marginLeft: "-390px" }}
            />
            <span
              className="icon icon-line-1 wow fadeIn animated"
              style={{ visibility: "visible", animationName: "fa-spin", marginTop: "320px ", marginLeft: "400px" }}
            />

            <span
              className="icon icon-circle-1 wow zoomIn animated"
              style={{ visibility: "visible", animationName: "zoomIn", marginTop: "-150px ", marginLeft: "400px" }}
            />
          </div>


        </center>
      </div>

    </div>
  )
}

export default InterstedRecived
