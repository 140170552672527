import React from 'react'
import "./NewintrestStyle.css"
const Newintrest = () => {
  return (
    <>

  <div className="page-wrapper">
  
    <div className="preloader" style={{ display: "none" }} />
   
    <span className="header-span" />
    
    
    <section
      className="page-title"
      style={{ backgroundColor:"black",marginTop:"1px" }}
    >
      <div className="auto-container">
        <h1 className="d-none d-lg-block d-xl-block d-md-block">
          Interest Received
        </h1>
        <ul className="bread-crumb clearfix">
          <li>
            <a href="index_dashboard">Home</a>
          </li>
          <li>Interest Received</li>
        </ul>
      </div>
    </section>
    <section className="error-section">
      <div className="anim-icons full-width">
        <span
          className="icon icon-circle-blue wow fadeIn animated"
          style={{ visibility: "visible", animationName: "fa-spin" }}
        />
        <span
          className="icon icon-dots wow fadeInleft animated"
          style={{ visibility: "visible" }}
        />
        <span
          className="icon icon-line-1 wow zoomIn animated"
          style={{ visibility: "visible", animationName: "fa-spin" }}
        />
        <span
          className="icon icon-circle-1 wow zoomIn animated"
          style={{ visibility: "visible", animationName: "zoomIn" }}
        />
      </div>
      <div className="auto-container">
        <div className="error-title">OOP'S</div>
        <h4>Sorry Result Not Found</h4>
        <div className="text">You have not yet connected profiles.</div>
        <a href="smart_search" className="theme-btn btn-style-three">
          <span className="btn-title">Search</span>
        </a>
       
      </div>
    </section> 
  </div>
  <div
    className="scroll-to-top scroll-to-target"
    data-target="html"
    style={{ display: "block" }}
  >
    <span className="fa fa-angle-double-up" />
  </div>
</>

  )
}

export default Newintrest