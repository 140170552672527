import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';// Import useParams from React Router
import { set } from 'store';


const Feedbackviewmore = () => {
  const { id } = useParams(); // Get the ID parameter from the URL
  
  const [feedbackviewmore, setFeedbackviewmore] = useState([]);


  useEffect(() => {
    fetch(`https://matrimonyadmin.hola9.com/api/profileapi/${id}/`)
      .then(response => response.json())
      .then(data => setFeedbackviewmore(data))
      .catch(error => console.error('Error fetching feedback forms:', error));
  }, []);



 return (
  
    <div>
      <h1>Feedback Details</h1>
      <p>Name: Feedback {id}</p>
      <p>ID: {id}</p>
    </div>
    
  );
};


export default Feedbackviewmore;