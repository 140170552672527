




import React, { useState, useEffect } from 'react';
import { BsFillPencilFill } from "react-icons/bs";
import { BarLoader } from 'react-spinners'; // Import BarLoader from react-spinners

const Newhobby = () => {
  const [datahobbies, setDatahobbies] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [cooking, setCooking] = useState(false);
  const [cooking_text, setCooking_text] = useState("");
  const [music, setMusic] = useState(false);
  const [music_text, setMusic_text] = useState("");
  const [writing, setWriting] = useState(false);
  const [writing_text, setWriting_text] = useState("");
  const [gaming, setGaming] = useState(false);
  const [gaming_text, setGaming_text] = useState("");
  const [gardening, setGardening] = useState(false);
  const [gardening_text, setGardening_text] = useState("");
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://matrimonyadmin.hola9.com/api/profileapi/hobbies/`);
        const jsonData = await response.json();
        setDatahobbies(jsonData);
        setIsLoading(false);
        if (jsonData.length > 0) {
          const latestData = jsonData[jsonData.length - 1];
          setSelectedId(latestData.id);
          setCooking(latestData.cooking);
          setCooking_text(latestData.cooking_text);
          setMusic(latestData.music);
          setMusic_text(latestData.music_text);
          setWriting(latestData.writing);
          setWriting_text(latestData.writing_text);
          setGaming(latestData.gaming);
          setGaming_text(latestData.gaming_text);
          setGardening(latestData.gardening);
          setGardening_text(latestData.gardening_text);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://matrimonyadmin.hola9.com/api/profileapi/hobbies/${selectedId}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: selectedId,
          cooking,
          cooking_text,
          music,
          music_text,
          writing,
          writing_text,
          gaming,
          gaming_text,
          gardening,
          gardening_text
        }),
      });
      if (response.ok) {
        setDatahobbies(prevData => {
          const newData = [...prevData];
          const index = newData.findIndex(item => item.id === selectedId);
          if (index !== -1) {
            newData[index] = {
              id: selectedId,
              cooking,
              cooking_text,
              music,
              music_text,
              writing,
              writing_text,
              gaming,
              gaming_text,
              gardening,
              gardening_text
            };
          }
          return newData;
        });
        closeModal();
      } else {
        console.error('Failed to update data:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <BarLoader color={'#123abc'} loading={isLoading} /> {/* Display BarLoader while data is being fetched */}
      </div>
    );
  }

  return (
    <div>
      {/* <div>NewHobbies</div> */}
      <div
        className="feature-block-twop col-lg-4p col-md-6p col-sm-6p wow fadeInUp animated"
        style={{ visibility: "visible", animationName: "fadeInUp" }}
      >
        {datahobbies.length > 0 && (
          <div className="inner-boxp">
            <div className="icon-boxp">
              <img
                src="https://www.matrimonysoftware.in/icon/education.png"
                alt=""
              />
            </div>
            <h4>
              <a href="education">
                Hobbies Details{" "}
                <i style={{ fontSize: 24 }} className="fa">
                  
                </i>
              </a>
          
            </h4>
            <BsFillPencilFill className="editpro"   onClick={openModal}/>
            <div className="textpro"><span>Cooking:</span> {cooking ? 'Yes' : 'No'}</div>
            {cooking && <div className="textpro"><span>Cooking text:</span> {cooking_text}</div>}
            <div className="textpro"><span>Music:</span> {music ? 'Yes' : 'No'}</div>
            {music && <div className="textpro"><span>Music text:</span> {music_text}</div>}
            <div className="textpro"><span>Writing:</span> {writing ? 'Yes' : 'No'}</div>
            {writing && <div className="textpro"><span>Writing text:</span> {writing_text}</div>}
            <div className="textpro"><span>Gaming:</span> {gaming ? 'Yes' : 'No'}</div>
            {gaming && <div className="textpro"><span>Gaming text:</span> {gaming_text}</div>}
            <div className="textpro"><span>Gardening:</span> {gardening ? 'Yes' : 'No'}</div>
            {gardening && <div className="textpro"><span>Gardening text: </span>{gardening_text}</div>}

            {/* <button className="btn btn-primary" onClick={openModal}>Edit</button> */}
          </div>
        )}
      </div>

      <div className={`modal ${isModalOpen ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: isModalOpen ? 'block' : 'none' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Details</h5>
              <button type="button" className="close" onClick={closeModal}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="hobby-item">
                  <label className='hobby-label'  style={{color:"white"}}><h10>Cooking:</h10></label>
                  <div className="radio-group" style={{width:"90%",height:"60px",marginTop:"2%"}}>
                    <label className='hobby-label'>
                      <input
                        className='radio-btn'
                        type="radio"
                        name="cooking"
                        checked={cooking}
                        onChange={() => setCooking(true)}
                      />
                      Yes
                      </label>
                    <label className='hobby-label'>
                      <input
                        className='radio-btn'
                        type="radio"
                        name="cooking"
                        checked={!cooking}
                        onChange={() => setCooking(false)}
                      />
                      No
                    </label>
                  </div>
                  {cooking && <input className='hobby-text' type="text" placeholder="Enter your cooking details" name="cooking_text" value={cooking_text} onChange={(e) => setCooking_text(e.target.value)} />}
                </div>

                <div className="hobby-item">
                  <label className='hobby-label'  style={{color:"white"}}><h10>Music:</h10></label>
                  <div className="radio-group" style={{width:"90%",height:"60px",marginTop:"2%"}}>
                    <label className='hobby-label'>
                      <input
                        className='radio-btn'
                        type="radio"
                        name="music"
                        checked={music}
                        onChange={() => setMusic(true)}
                      />
                      Yes
                    </label>
                    <label className='hobby-label'>
                      <input
                        className='radio-btn'
                        type="radio"
                        name="music"
                        checked={!music}
                        onChange={() => setMusic(false)}
                      />
                      No
                    </label>
                  </div>
                  {music && <input className='hobby-text' type="text" placeholder="Enter your music details" name="music_text" value={music_text} onChange={(e) => setMusic_text(e.target.value)} />}
                </div>

                <div className="hobby-item">
                  <label className='hobby-label'  style={{color:"white"}}><h10>Writing:</h10></label>
                  <div className="radio-group" style={{width:"90%",height:"60px",marginTop:"2%"}}>
                    <label className='hobby-label'>
                      <input
                        className='radio-btn'
                        type="radio"
                        name="writing"
                        checked={writing}
                        onChange={() => setWriting(true)}
                      />
                      Yes
                    </label>
                    <label className='hobby-label'>
                      <input
                        className='radio-btn'
                        type="radio"
                        name="writing"
                        checked={!writing}
                        onChange={() => setWriting(false)}
                      />
                      No
                    </label>
                  </div>
                  {writing && <input className='hobby-text' type="text" placeholder="Enter your writing details" name="writing_text" value={writing_text} onChange={(e) => setWriting_text(e.target.value)} />}
                </div>

                <div className="hobby-item">
                  <label className='hobby-label'  style={{color:"white"}}><h10>Gaming:</h10></label>
                  <div className="radio-group" style={{width:"90%",height:"60px",marginTop:"2%"}}>
                    <label className='hobby-label'>
                      <input
                        className='radio-btn'
                        type="radio"
                        name="gaming"
                        checked={gaming}
                        onChange={() => setGaming(true)}
                      />
                      Yes
                    </label>
                    <label className='hobby-label'>
                      <input
                        className='radio-btn'
                        type="radio"
                        name="gaming"
                        checked={!gaming}
                        onChange={() => setGaming(false)}
                      />
                      No
                    </label>
                  </div>
                  {gaming && <input className='hobby-text' type="text" placeholder="Enter your gaming details" name="gaming_text" value={gaming_text} onChange={(e) => setGaming_text(e.target.value)} />}
                </div>

                <div className="hobby-item">
                  <label className='hobby-label' style={{color:"white"}} ><h10>Gardening:</h10></label>
                  <div className="radio-group" style={{width:"90%",height:"60px",marginTop:"2%"}}>
                    <label className='hobby-label'>
                      <input
                        className='radio-btn'
                        type="radio"
                        name="gardening"
                        checked={gardening}
                        onChange={() => setGardening(true)}
                        
                      />
                      Yes
                    </label>
                    <label className='hobby-label'>
                      <input
                        className='radio-btn'
                        type="radio"
                        name="gardening"
                        checked={!gardening}
                        onChange={() => setGardening(false)}
                        
                      />
                      No
                    </label>
                  </div>
                  {gardening && <input className='hobby-text' type="text" placeholder="Enter your gardening details" name="gardening_text" value={gardening_text} onChange={(e) => setGardening_text(e.target.value)} />}
                </div>
                <div className="form-group">
                <button type="submit" >Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newhobby;
